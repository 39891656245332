import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Spinner } from 'reactstrap';

import Loader from '../assets/svg/loader.svg';

export default class Stats extends React.Component {
    constructor(props) {
        super(props);
        this.props.t.updateState(this.props.t, 'Partner', false, 'currentStat');
        this.props.t.updateState(this.props.t, 'Partner', false, 'stat');
    }

    componentDidMount() {
        if (this.props.t.state.userID) {
            this.props.t.Interface.PartnerGetCode(this.props.t);
            this.props.t.Interface.PartnerGetCampgains(this.props.t);
            this.props.t.Interface.PartnerGetStats(this.props.t, true);
        }
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'partner', 'stat')}
                </div>
                <div className={this.props.t.styles['stats']}>
                    <div className={[this.props.t.styles['campgainsSelector'], this.props.t.state.Partner.process ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                        <button type="button" className={[this.props.t.styles['btn'], this.props.t.state.Partner.currentStat ? this.props.t.styles['btnLight'] : ''].join(' ')} onClick={(ev) => { this.props.t.state.Partner.currentStat && this.props.t.Interface.PartnerGetStats(this.props.t, true); this.props.t.updateState(this.props.t, 'Partner', false, 'currentStat') }}>{this.props.t.Interface.getText(this.props.t, 'partner', 'totalStat')}</button>
                        {this.props.t.state.form.partner.code.data && <button type="button" className={[this.props.t.styles['btn'], this.props.t.state.Partner.currentStat !== this.props.t.state.form.partner.code.data ? this.props.t.styles['btnLight'] : ''].join(' ')} onClick={(ev) => { this.props.t.state.Partner.currentStat !== this.props.t.state.form.partner.code.data && this.props.t.Interface.PartnerGetStats(this.props.t, true, this.props.t.state.form.partner.code.data); this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.form.partner.code.data, 'currentStat') }}>{this.props.t.Interface.getText(this.props.t, 'partner', 'mainLink')}</button>}

                        {this.props.t.state.Partner.campgains.list.map((campgain, index) =>
                            <button key={index} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Partner.currentStat !== campgain.code ? this.props.t.styles['btnLight'] : ''].join(' ')} onClick={(ev) => { this.props.t.state.Partner.currentStat !== campgain.code && this.props.t.Interface.PartnerGetStats(this.props.t, true, campgain.code); this.props.t.updateState(this.props.t, 'Partner', campgain.code, 'currentStat') }}>{campgain.name}</button>)}


                    </div>

                    {this.props.t.state.Partner.process || !this.props.t.state.Partner.stat ?
                        <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                        :
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                title: {
                                    text: undefined
                                },
                                series: [{
                                    data: this.props.t.state.Partner.stat.length ? this.props.t.state.Partner.stat.map(item => item.clicks) : [0],
                                    name: this.props.t.Interface.getText(this.props.t, 'partner', 'clicks')
                                },
                                {
                                    data: this.props.t.state.Partner.stat.length ? this.props.t.state.Partner.stat.map(item => item.regs) : [0],
                                    name: this.props.t.Interface.getText(this.props.t, 'partner', 'registrationsCount')
                                },
                                {
                                    data: this.props.t.state.Partner.stat.length ? this.props.t.state.Partner.stat.map(item => this.props.t.Interface.fixedFloat(item.bets, true)) : [this.props.t.Interface.fixedFloat(0, true)],
                                    name: this.props.t.Interface.getText(this.props.t, 'partner', 'bets')
                                },
                                {
                                    data: this.props.t.state.Partner.stat.length ? this.props.t.state.Partner.stat.map(item => this.props.t.Interface.fixedFloat(item.profit, true)) : [this.props.t.Interface.fixedFloat(0, true)],
                                    name: this.props.t.Interface.getText(this.props.t, 'partner', 'profit')
                                }],
                                chart: {
                                    backgroundColor: '#232833',
                                    borderRadius: '5px',
                                    spacingTop: 30
                                },
                                legend: {
                                    enabled: false
                                },
                                credits: {
                                    enabled: false
                                },
                                colorAxis: {
                                    gridLineColor: '#000'
                                },
                                yAxis: {
                                    title: {
                                        text: undefined
                                    },
                                    labels: {
                                        style: {
                                            color: '#a5acbb'
                                        }
                                    },
                                    gridLineColor: '#a5acbb'
                                },
                                xAxis: {
                                    categories: this.props.t.state.Partner.stat.length ? this.props.t.state.Partner.stat.map(item => this.props.t.Interface.localeDate(item.date, true, this.props.t)) : [this.props.t.Interface.localeDate((+new Date / 1000), true, this.props.t)],
                                    title: {
                                        style: {
                                            color: '#a5acbb'
                                        }
                                    },
                                    labels: {
                                        style: {
                                            color: '#a5acbb'
                                        }
                                    },
                                    gridLineColor: '#a5acbb'
                                }
                            }}
                        />
                    }
                </div >
            </>
        )
    }

}