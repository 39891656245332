import React from 'react';
import Icon from '../components/Icon';
import Svg from '../components/Svg';

import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import StaticLiveBets from './static/LiveBets';
import defaultPoster from '../assets/png/poster.png';

export default class LiveBets extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		let t = this;
		this.props.t.Interface.LiveGetList(this.props.t);
		this.updateTimeout = false;
		//this.liveInterval = setInterval(t.props.t.Interface.LiveGetList, t.props.t.config.timers.LiveBets.getList * 1000, this.props.t);
		this.pState = this.props.t.cloneDeep(this.props.t.state);
		setTimeout(() => { this.scrollLiveDown() }, 2000);
	}

	componentDidUpdate() {
		this.scrollLiveDown();
	}

	componentWillUnmount() {
		clearInterval(this.liveInterval);
		clearTimeout(this.updateTimeout);
	}

	shouldComponentUpdate() {
		let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.RightBar, this.props.t.state.LiveBets, this.props.t.state.lang], [this.pState.RightBar, this.pState.LiveBets, this.pState.lang]);

		if (needUpdate)
			this.pState = this.props.t.cloneDeep(this.props.t.state);

		return needUpdate;
	}

	scrollLiveDown() {
		//this._scrollBarRef.setAttribute("style", "scroll-behavior: smooth;");
		if (this._scrollBarRef)
			this._scrollBarRef.scrollTop = this._scrollBarRef.scrollHeight - this._scrollBarRef.offsetHeight;
		//this._scrollBarRef.setAttribute("style", "scroll-behavior: auto;");
	}

	render() {
		return (
			<div className={this.props.t.styles['liveStatBlock']}>
				<div className={this.props.t.styles['liveParams']}>
					<div className={this.props.t.styles['item']}>
						{this.props.t.Interface.getText(this.props.t, 'live', 'title')}
					</div>
					<div className={this.props.t.styles['item']}>
						<div className={this.props.t.styles['language']}>
							<div className={this.props.t.styles['dropdown']}>
								<button onClick={() => { this.props.t.updateState(this.props.t, 'LiveBets', !this.props.t.state.LiveBets.languageDropdown, 'languageDropdown') }} className={[this.props.t.styles['btnRgr'], this.props.t.styles['btnLang']].join(' ')} type="button">
									<div className={this.props.t.styles['btnContent']}>
										<Svg t={this.props.t} name={"flag-" + this.props.t.Interface.getText(this.props.t, 'system', 'code')} />
										<div className={this.props.t.state.LiveBets.languageDropdown ? [this.props.t.styles['opener'], this.props.t.styles['open']].join(' ') : this.props.t.styles['opener']}>
											<Icon t={this.props.t} name="down" />
										</div>
									</div>

								</button>
								<div className={this.props.t.state.LiveBets.languageDropdown ? [this.props.t.styles['menu'], this.props.t.styles['open']].join(' ') : this.props.t.styles['menu']}>
									{Object.keys(this.props.t.state.lang.loaded).map((key, index) =>
										this.props.t.Interface.getText(this.props.t, 'system', 'code') !== this.props.t.state.lang.loaded[key].system.code && <button key={index} onClick={() => { localStorage.setItem('interface.language', this.props.t.state.lang.loaded[key].system.code); this.props.t.updateState(this.props.t, 'LiveBets', !this.props.t.state.LiveBets.languageDropdown, 'languageDropdown'); window.location.reload(); }} className={this.props.t.styles['item']}><div className={this.props.t.styles['li']}><Svg t={this.props.t} name={"flag-" + this.props.t.state.lang.loaded[key].system.code} />{this.props.t.state.lang.loaded[key].system.name}</div></button>
									)}
								</div>
							</div>
						</div>
						<button onClick={() => { this.props.t.updateState(this.props.t, 'RightBar', !this.props.t.state.RightBar.open, 'open') }} className={this.props.t.styles['closeBtn']}><Icon t={this.props.t} name="close" /></button>
					</div>
				</div>
				<div className={this.props.t.styles['liveBlock']}>
					<PerfectScrollbar containerRef={(ref) => { this._scrollBarRef = ref; }} className={this.props.t.styles['liveBlockInner']}>
						{(!this.props.t.state.LiveBets.liveBets.length || !this.props.t.state.Header.gamesList.length) ? (
							<StaticLiveBets t={this.props.t} />
						) : this.props.t.state.LiveBets.liveBets.map((item, index) => (
							<div key={index} className={this.props.t.styles['item']}>
								<div className={this.props.t.styles['overlay']}>
									<button onClick={() => this.props.t.doRoute('/game/' + item.gameID + '/real')} className={this.props.t.styles['btn']} type="button">{this.props.t.Interface.getText(this.props.t, 'live', 'play')}</button><button data-id={item.gameID} onClick={() => this.props.t.doRoute('/game/' + item.gameID + '/demo')} className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'live', 'demo')}</button>
								</div>
								<div className={this.props.t.styles['image']}>
									<div style={{ backgroundImage: 'url(' + item.poster + '), url(' + defaultPoster + ')' }} className={this.props.t.styles['img']}></div>
								</div>
								<div className={this.props.t.styles['liveContent']}>
									<div>
										<div className={this.props.t.styles['gameName']}>
											{item.gameName}
										</div>
										<div className={this.props.t.styles['winAmount']}>
											{this.props.t.Interface.fixedFloat(item.amount)} <Icon t={this.props.t} name="rub" />
										</div>

										<div className={this.props.t.styles['userLink']}>
											{item.name}
										</div>
									</div>
								</div>
							</div>
						))}
					</PerfectScrollbar>
				</div>
			</div >
		)
	}
}


