import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default class Responsibility extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        document.title = 'Responsibility - ' + this.props.t.config.name;
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width], [this.pState.width]);
    }


    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['responsibilityContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'responsibility', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'responsibility', 'description')}</div></div>
                        <div className={this.props.t.styles['responsibilityBlock']}>
                            {ReactHtmlParser(this.props.t.Interface.getText(this.props.t, 'responsibility', 'content'))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}