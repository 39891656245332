import React from 'react';
import SideProfile from './SideProfile';
import LiveBets from './LiveBets';

export default class RightBar extends React.Component {
	constructor(props) {
		super(props);

		if (window.matchMedia('(display-mode: standalone)').matches)
			this.isStandalone = true;
		else
			this.isStandalone = false;
	}

	componentDidMount() {
		this.pState = this.props.t.cloneDeep(this.props.t.state);
	}

	componentDidUpdate() {
	}

	shouldComponentUpdate() {
		let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.userData, this.props.t.state.clipboard, this.props.t.state.RightBar, this.props.t.state.LiveBets, this.props.t.state.lang], [this.pState.userData, this.pState.clipboard, this.pState.RightBar, this.pState.LiveBets, this.pState.lang]);

		if (needUpdate)
			this.pState = this.props.t.cloneDeep(this.props.t.state);

		return needUpdate;
	}

	render() {
		return (
			<div className={[this.props.t.styles['rightWrapper'], this.props.t.state.RightBar.open ? this.props.t.styles['rightIsOpen'] : '', this.isStandalone ? this.props.t.styles['standalone'] : ''].join(' ')
			}>
				<div className={this.props.t.styles['rightContent']}>
					<div className={this.props.t.styles['rightContainer']}>
						{this.props.t.state.RightBar.module === 1 || !this.props.t.state.userID ?
							<LiveBets t={this.props.t} />
							:
							<SideProfile t={this.props.t} />
						}
					</div>
				</div>
			</div >
		)
	}
}