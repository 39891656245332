import React from 'react';
import { withRouter } from "react-router-dom";
import IconStatic from '../components/IconStatic';

import 'bootstrap';
import '../styles/bootstrap/scss/bootstrap.module.scss';
import { Modal } from 'reactstrap';

class ModalBox extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    onClosed() {
        if (this.props.t.state.modalSecond.className === this.props.t.styles['recoveryModal'])
            this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "recovery", "email")
    }

    render() {
        return (
            <Modal onClosed={() => { this.onClosed() }} zIndex={1051} contentClassName={this.props.t.styles['modal-content']} modalClassName={[this.props.t.styles['modal'], this.props.t.styles['fade'], this.props.t.styles['show']].join(' ')} className={[this.props.t.styles['modal-dialog'], this.props.t.state.modalSecond.className, this.props.t.styles['modal-dialog-centered']].join(' ')} isOpen={this.props.t.state.modalSecond.show} cssModule={this.props.t.bstyles} toggle={() => { this.props.t.updateState(this.props.t, 'modalSecond', false, 'show') }}>

                {this.props.t.state.modalSecond.className !== this.props.t.styles['walletModal'] ?
                    <button onClick={() => { this.props.t.updateState(this.props.t, 'modalSecond', false, 'show') }} className={this.props.t.styles['modal-close']} type="button"><IconStatic t={this.props.t} name="close" /></button>
                    : <></>}

                {this.props.t.state.modalSecond.content ?
                    <this.props.t.state.modalSecond.content t={this.props.t} />
                    : (<></>)
                }
            </Modal>
        );
    }
}

export default withRouter(ModalBox);
