import React from 'react';
import IconStatic from '../components/IconStatic';

import PromoImg1 from '../assets/jpg/Promotions/1.jpg';

import WalletModal from '../modules/modals/Wallet';

export default class Promotions extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        this.props.t.updateState(this.props.t, 'location', 'promotions');

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'promotions') + ' - ' + this.props.t.config.name;
    }

    shouldComponentUpdate() {
        let needUpdate = false;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Header.gamesList.length, this.props.t.state.width, this.props.t.state.lang], [this.pState.Header.gamesList.length, this.pState.width, this.pState.lang]);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'location', '/');
    }
    
    getPromotions() {
        return [{ name: this.props.t.Interface.getText(this.props.t, 'promotions', 'permanentBonus'), description: '10% ' + this.props.t.Interface.getText(this.props.t, 'promotions', 'forEveryDeposit'), image: PromoImg1, activation: true, date: 1612112399, min: 10000, gameID: false, wager: 0 }];
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['promotionsContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'promotions', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'promotions', 'description')}</div></div>
                        <div className={this.props.t.styles['promotionsBlock']}>
                            {this.getPromotions().map((promotion, index) => (
                                <div key={index} className={this.props.t.styles['item']}>
                                    <div className={this.props.t.styles['box']}>
                                        <div className={this.props.t.styles['image']}>
                                            <img src={promotion.image}></img>
                                        </div>
                                        <div className={this.props.t.styles['info']}>
                                            <div className={this.props.t.styles['col']}>
                                                <span>{promotion.name}</span>
                                            </div>
                                            <div className={this.props.t.styles['col']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'game')} <span><a href="#" onClick={() => !promotion.gameID ? this.props.t.doRoute('/lobby') : this.props.t.doRoute('/game/' + promotion.gameID + '/real/')}>{!promotion.gameID ? this.props.t.Interface.getText(this.props.t, 'promotions', 'any') : this.props.t.Interface.getGameByID(this.props.t, promotion.gameID) ? this.props.t.Interface.getGameByID(this.props.t, promotion.gameID).params.name : '...'}</a></span>
                                            </div>
                                            <div className={this.props.t.styles['col']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'minDeposit')} <span></span>
                                                <div className={this.props.t.styles['number']}>
                                                    <span>{this.props.t.Interface.fixedFloat(promotion.min)} <IconStatic t={this.props.t} name="rub" /></span>
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['col']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'bonus')} <span>{promotion.description}</span>
                                            </div>
                                            <div className={this.props.t.styles['col']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'addWager')} <span>x{promotion.wager}</span>
                                            </div>
                                            <div className={this.props.t.styles['col']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'validUntil')} <span className={promotion.date > +new Date ? this.props.t.styles['ended'] : this.props.t.styles['active']}>{this.props.t.Interface.localeDate(promotion.date, false, this.props.t)}</span>
                                            </div>
                                        </div>
                                        <div className={this.props.t.styles['date']}>
                                            <div className={this.props.t.styles['time']}>
                                                {this.props.t.Interface.getText(this.props.t, 'promotions', 'activation')} <span>{promotion.activation ? this.props.t.Interface.getText(this.props.t, 'promotions', 'unlimit') : this.props.t.Interface.getText(this.props.t, 'promotions', 'oneTime')}</span>
                                            </div><a onClick={() => { this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/promotions') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} href="#">{this.props.t.Interface.getText(this.props.t, 'promotions', 'get')}</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}