import React from 'react';
import IconStatic from '../../components/IconStatic';
import ConfirmWithdraw from './ConfirmWithdraw';

import SimpleBar from 'simplebar-react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Tooltip } from 'reactstrap';

import Loader from '../../assets/svg/loader.svg';

class WalletWithdraw extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.t.Interface.WalletGetTemplates(this.props.t);
        this.props.t.Interface.WalletGetPayways(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "wallet", "wallet");
        this.props.t.updateState(this.props.t, "form", { data: this.props.t.config.wallet.withdraw.defaultValue, error: false }, "wallet", "withdraw");
    }

    render() {
        return (
            <>
                {this.props.t.state.width > 900 &&
                    <div className={this.props.t.styles['contentLeft']}>
                        <div className={[this.props.t.styles['label'], this.props.t.styles['simpleLabel']].join(' ')}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'withdrawMethod')}
                        </div>
                        <SimpleBar style={{ maxHeight: '400px' }}>
                            {this.props.t.state.Wallet.process ? <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span> :
                                this.props.t.state.Wallet.payWays.map((method, index) => (
                                    !method.crypto && !method.mobile ?
                                        <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'withdraw', 'currentMethod'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={[this.props.t.styles['simpleMethod'], this.props.t.state.Wallet.withdraw.currentMethod === method.method || (method.method === "mobile" && this.props.t.Interface.inArray(this.props.t.state.Wallet.withdraw.currentMethod, this.props.t.state.Wallet.payWaysMobile)) || (method.method === "crypto" && this.props.t.Interface.inArray(this.props.t.state.Wallet.withdraw.currentMethod, this.props.t.state.Wallet.payWaysCrypto)) ? this.props.t.styles['active'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['image']}><img alt="" draggable="false" src={method.img}></img>
                                            </div>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}</button> : <></>
                                ))}
                        </SimpleBar>
                    </div>}

                <div className={[this.props.t.styles['contentRight'], this.props.t.state.width <= 900 ? this.props.t.styles['mobile'] : ''].join(' ')}>
                    <div className={this.props.t.styles['contentWrap']}>
                        {this.props.t.state.width <= 900 &&
                            <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                <div className={this.props.t.styles['label']}>
                                    {this.props.t.Interface.getText(this.props.t, 'wallet', 'selectMethod')}
                                </div>
                                <div className={this.props.t.styles['walletContentWrapper']}>
                                    <div className={this.props.t.styles['dropdown']}>
                                        <button onClick={() => this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.withdraw.open, 'withdraw', 'open')} className={[this.props.t.styles['btn'], this.props.t.state.Wallet.withdraw.open ? this.props.t.styles['open'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['btnContent']}>
                                                <div className={this.props.t.styles['selectedMethod']}>
                                                    <img draggable="false" src={this.props.t.Interface.getPaywayImage(this.props.t, this.props.t.state.Wallet.withdraw.currentMethod)}></img>{this.props.t.Interface.getText(this.props.t, 'wallet', this.props.t.state.Wallet.withdraw.currentMethod)}
                                                </div>
                                                <div className={[this.props.t.styles['opener'], this.props.t.state.Wallet.withdraw.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <IconStatic t={this.props.t} name='down' />
                                                </div>
                                            </div></button>
                                        <div className={[this.props.t.styles['menu'], this.props.t.state.Wallet.withdraw.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                        <PerfectScrollbar className={this.props.t.styles['scrollBar']}>
                                                <div className={this.props.t.styles['methodsBlock']}>
                                                    <div className={this.props.t.styles['blockName']}>
                                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'topMethods')}
                                                    </div>
                                                    <div className={this.props.t.styles['blockItems']}>
                                                        {this.props.t.state.Wallet.payWays.slice(0, 4).map((method, index) => (
                                                            !method.crypto && !method.mobile ?
                                                                <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'withdraw', 'currentMethod'), this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.withdraw.open, 'withdraw', 'open'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={this.props.t.styles['item']} type="button">
                                                                    <div className={this.props.t.styles['itemContent']}>
                                                                        <img alt="" draggable="false" src={method.img}></img>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}
                                                                        <div className={this.props.t.styles['comission']}>0%</div>
                                                                    </div></button> : <></>
                                                        ))}
                                                    </div>

                                                    <div className={this.props.t.styles['blockName']}>
                                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'allMethods')}
                                                    </div>
                                                    <div className={this.props.t.styles['blockItems']}>

                                                        {this.props.t.state.Wallet.payWays.slice(4).map((method, index) => (
                                                            !method.crypto && !method.mobile ?
                                                                <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'withdraw', 'currentMethod'), this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.withdraw.open, 'withdraw', 'open'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={this.props.t.styles['item']} type="button">
                                                                    <div className={this.props.t.styles['itemContent']}>
                                                                        <img alt="" draggable="false" src={method.img}></img>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}
                                                                        <div className={this.props.t.styles['comission']}>0%</div>
                                                                    </div></button> : <></>
                                                        ))}
                                                    </div>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {this.props.t.state.Wallet.withdraw.currentMethod === "mobile" || this.props.t.state.Wallet.withdraw.currentMethod === "crypto" || this.props.t.Interface.inArray(this.props.t.state.Wallet.withdraw.currentMethod, this.props.t.state.Wallet.payWaysMobile) || this.props.t.Interface.inArray(this.props.t.state.Wallet.withdraw.currentMethod, this.props.t.state.Wallet.payWaysCrypto) ?
                            <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                <div className={this.props.t.styles['label']}>
                                    {this.props.t.Interface.getText(this.props.t, 'wallet', 'selectOperator')}
                                </div>
                                <div className={this.props.t.styles['walletContentWrapper']}>
                                    <div className={this.props.t.styles['subButtons']}>
                                        {this.props.t.state.Wallet.withdraw.currentMethod === "mobile" || this.props.t.Interface.inArray(this.props.t.state.Wallet.withdraw.currentMethod, this.props.t.state.Wallet.payWaysMobile) ?
                                            this.props.t.state.Wallet.payWays.map((method, index) => (
                                                method.mobile ?
                                                    <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method, 'withdraw', 'currentMethod'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={this.props.t.state.Wallet.withdraw.currentMethod === method.method ? this.props.t.styles['active'] : ''} type="button">
                                                        <img alt="" src={method.img}></img><span>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method)}</span></button> : <></>
                                            )) :
                                            this.props.t.state.Wallet.payWays.map((method, index) => (
                                                method.crypto ?
                                                    <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method, 'withdraw', 'currentMethod'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={this.props.t.state.Wallet.withdraw.currentMethod === method.method ? this.props.t.styles['active'] : ''} type="button">
                                                        <img alt="" src={method.img}></img><span>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method)}</span></button> : <></>
                                            ))}
                                    </div>
                                </div>
                            </div> : <></>}
                        <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                            <div className={this.props.t.styles['label']}>
                                {this.props.t.Interface.getText(this.props.t, 'wallet', 'typePurse')}
                            </div>
                            <div className={this.props.t.styles['walletContentWrapper']}>

                                <div className={this.props.t.styles['formField']}>
                                    <input autoComplete="nope" placeholder={this.props.t.config.wallet.formats[this.props.t.state.Wallet.withdraw.currentMethod]} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'wallet', 'wallet')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'wallet', 'wallet') }} id="inputWallet" className={[this.props.t.styles['inputField'], this.props.t.styles['inputWithIcon'], this.props.t.styles['walletInput'], this.props.t.state.form.wallet.wallet.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="purse" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.wallet.wallet.error }, 'wallet', 'wallet'); this.props.t.Interface.checkForm(this.props.t, 'wallet', 'wallet') }} value={this.props.t.state.form.wallet.wallet.data}></input>
                                    <Tooltip isOpen={(this.props.t.state.width <= 900 || (this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show)) && !this.props.t.state.inputs.wallet.wallet && this.props.t.state.form.wallet.wallet.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputWallet">
                                        {this.props.t.state.form.wallet.wallet.error}
                                    </Tooltip>
                                    <div className={this.props.t.styles['inputIcon']}>
                                        <img draggable="false" src={this.props.t.Interface.getPaywayImage(this.props.t, this.props.t.state.Wallet.withdraw.currentMethod)}></img>
                                    </div>
                                </div>

                                {this.props.t.state.Wallet.withdraw.templatesData.length ? <button onClick={() => this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.withdraw.templates, 'withdraw', 'templates')} type="button" aria-label="dropdown" className={[this.props.t.bstyles['btn'], this.props.t.styles['btnDropdown']].join(' ')} aria-haspopup="true" aria-expanded="true"><div className={this.props.t.styles['btnContent']}> <IconStatic t={this.props.t} name='more' /></div></button> : <></>}
                                <div className={this.props.t.styles['dropdown']}>
                                    <div className={[this.props.t.styles['menu'], this.props.t.state.Wallet.withdraw.templates ? this.props.t.styles['open'] : ''].join(' ')}>
                                        <SimpleBar style={{ maxHeight: '250px' }}>
                                            <div className={this.props.t.styles['methodsBlock']}>
                                                <div className={this.props.t.styles['blockItems']}>

                                                    {this.props.t.state.Wallet.withdraw.templatesData.map((template, index) => (
                                                        <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', template.type, 'withdraw', 'currentMethod'), this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getFormattedWallet(template.wallet, template.type, this.props.t), error: this.props.t.state.form.wallet.wallet.error }, 'wallet', 'wallet'), this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.withdraw.templates, 'withdraw', 'templates'), this.props.t.state.form.wallet.withdraw.data < this.props.t.Interface.getPaymentLimits(this.props.t, template.type, 'withdraw').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, template.type, 'withdraw').min, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : this.props.t.state.form.wallet.withdraw.data > this.props.t.Interface.getPaymentLimits(this.props.t, template.type, 'withdraw').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, template.type, 'withdraw').max, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'))} className={this.props.t.styles['item']} type="button">
                                                            <div className={this.props.t.styles['itemContent']}>
                                                                <img alt="" draggable="false" src={this.props.t.Interface.getPaywayImage(this.props.t, template.type)}></img>{this.props.t.Interface.getFormattedWallet(template.wallet, template.type, this.props.t)}
                                                            </div>
                                                            <button type="button" className={[this.props.t.styles['btnRemove'], this.props.t.bstyles['d-none']].join(' ')}> <IconStatic t={this.props.t} name='close' /></button>
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </SimpleBar>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className={this.props.t.styles['formInputs']}>
                            <div className={this.props.t.styles['item']}>
                                <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                    <div className={this.props.t.styles['label']}>
                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'withdrawAmount')}
                                    </div>
                                    <div className={this.props.t.styles['walletContentWrapper']}>

                                        <div className={this.props.t.styles['formField']}>
                                            <input autoComplete="nope" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'wallet', 'withdraw')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'wallet', 'withdraw') }} id="inputWithdraw" className={[this.props.t.styles['inputField'], this.props.t.styles['inputWithIcon'], this.props.t.state.form.wallet.withdraw.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="amount" type="number" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.wallet.withdraw.error }, 'wallet', 'withdraw'); this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw') }} value={this.props.t.state.form.wallet.withdraw.data}></input>
                                            <Tooltip isOpen={(this.props.t.state.width <= 900 || (this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show)) && !this.props.t.state.Wallet.withdraw.templates && !this.props.t.state.inputs.wallet.withdraw && this.props.t.state.form.wallet.withdraw.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputWithdraw">
                                                {this.props.t.state.form.wallet.withdraw.error}
                                            </Tooltip>
                                            <div className={this.props.t.styles['inputIcon']}>
                                                <IconStatic t={this.props.t} name='rub' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.props.t.styles['item']}>
                                <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                    <div className={this.props.t.styles['label']}>
                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'toGet')}
                                    </div>
                                    <div className={this.props.t.styles['walletContentWrapper']}>
                                        <div className={this.props.t.styles['formField']}>
                                            <input className={[this.props.t.styles['inputField'], this.props.t.styles['inputWithIcon']].join(' ')} disabled name="total" value={(this.props.t.state.form.wallet.withdraw.data / 100 * (100 - this.props.t.config.wallet.withdraw.comission)).toFixed(2)}></input>
                                            <div className={this.props.t.styles['inputIcon']}>
                                                <IconStatic t={this.props.t} name='rub' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={this.props.t.styles['formSubmit']}>
                            <button onClick={() => { this.props.t.Interface.checkForm(this.props.t, 'wallet', 'withdraw'); this.props.t.Interface.checkForm(this.props.t, 'wallet', 'wallet'); !this.props.t.state.form.wallet.withdraw.error && this.props.t.Interface.WalletCheckCreateWithdraw(this.props.t, ConfirmWithdraw) }}>{this.props.t.Interface.getText(this.props.t, 'wallet', 'createWithdraw')}</button>
                        </div>
                    </div >
                    <div className={this.props.t.styles['contentFooter']}>
                        <div className={this.props.t.styles['comission']}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'commission')}: <span className={this.props.t.bstyles['text-white']}>{this.props.t.config.wallet.withdraw.comission}%</span>
                        </div>{this.props.t.state.width > 900 && <>&nbsp;/&nbsp;</>}
                        <div className={this.props.t.styles['details']}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'oneWithdrawLimit')}: <span className={this.props.t.styles['info']}>&nbsp;{this.props.t.config.wallet.withdraw.min}  <IconStatic t={this.props.t} name='rub' />&nbsp;−&nbsp;{this.props.t.config.wallet.withdraw.max}  <IconStatic t={this.props.t} name='rub' /></span>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default WalletWithdraw;