import React from 'react';
import IconStatic from '../../components/IconStatic';

import 'bootstrap';
import '../../styles/bootstrap/scss/bootstrap.module.scss';

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentDidUpdate() {

    }

    render() {
        return (
            <div className={this.props.t.styles['confirmContainer']}>
                <div className={this.props.t.styles['confirmIcon']}>
                    <IconStatic t={this.props.t} name="alert" />
                </div>
                <div className={this.props.t.styles['confirmText']}>
                {this.props.t.Interface.getText(this.props.t, 'wallet', 'firstWithdraw')}
                    <div className={this.props.t.styles['confirmPurse']}>{this.props.t.state.form.wallet.wallet.data}</div>
                </div>

                <div className={this.props.t.styles['confirmExtra']}>{this.props.t.Interface.getText(this.props.t, 'wallet', 'isDataCorrect')}</div>

                <div className={this.props.t.styles['confirmActions']}>
                    <button type="button" className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} onClick={(ev) => { this.props.t.updateState(this.props.t, 'modalSecond', false, 'show') }}>{this.props.t.Interface.getText(this.props.t, 'basic', 'no')}</button>
                    <button type="button" className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} onClick={(ev) => { this.props.t.state.modalSecond.callback(this.props.t); this.props.t.updateState(this.props.t, 'modalSecond', false, 'show'); }}>{this.props.t.Interface.getText(this.props.t, 'basic', 'yes')}</button>
                </div>
            </div>
        );
    }
}

export default ConfirmModal;
