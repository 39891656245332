import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const date = 1606770000;

export default class Aml extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        document.title = 'Privacy policy - ' + this.props.t.config.name;
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width], [this.pState.width]);
    }


    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['privacyContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'privacy', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'privacy', 'description')}</div></div>
                        <div className={this.props.t.styles['privacyBlock']}>
                            <p>{this.props.t.Interface.getText(this.props.t, 'privacy', 'redaction')}: {this.props.t.Interface.localeDate(date, false, this.props.t)}</p>
                            {ReactHtmlParser(this.props.t.Interface.getText(this.props.t, 'privacy', 'content'))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}