module.exports = {
    system: { code: 'hi', name: 'हिंदी' },
    aml: {
        title: 'मनी-लॉन्ड्रिंग का मुकाबला',
        description: 'सुरक्षा नियंत्रण',
        content: ''
    },
    auth: {
        accountCreated: 'अकाउंट रजिस्टर्ड',
        agreeText: 'मैं पुष्टि करता हूं कि मैं 18 साल का हूँ और मैंने पढ़ा है',
        agreeTos: 'सेवा की शर्तें',
        alreadyRegistered: 'पहले से पंजीकृत है?',
        byLogin: 'लॉगिन और पासवर्ड द्वारा',
        bySocialNetworks: 'सोशल नेटवर्क्स के माध्यम से',
        createAccount: 'एक खाता बनाएँ',
        forgotPassword: 'अपना पासवर्ड भूल गए हैं?',
        loading: 'लोड हो रहा है...',
        login: 'लॉगइन',
        loginTitle: 'साइट ऑथराइजेशन',
        maxLoginLength: 'अधिकतम लॉगिन लंबाई:',
        maxPasswordLength: 'अधिकतम पासवर्ड की लंबाई:',
        minLoginLength: 'न्यूनतम लॉगिन लंबाई:',
        minPasswordLength: 'न्यूनतम पासवर्ड की लंबाई:',
        passwordConfirmation: 'पासवर्ड दोहराएँ',
        passwordsMustBeSame: 'पासवर्डों को मेल खाना चाहिए',
        pleaseWait: 'कृपया प्रतीक्षा करें...',
        registration: 'रजिस्ट्रेशन',
        registrationTitle: 'साइट पर रजिस्टर करें',
        symbols: 'वर्ण',
        yourLogin: 'आपका लॉगइन',
        yourPassword: 'आपका पासवर्ड',
        yourEmail: 'आपका ईमेल',
        logoutConfirm: 'क्या आप वास्तव में बाहर निकलना चाहते हैं?',
        passwordRecovery: 'पासवर्ड रीसेट करें',
        newPassword: 'नया पासवर्ड',
        copied: 'कॉपी किया गया',
        logout: 'आउट'
    },
    basic: {
        yes: 'डीए',
        no: 'नहीं',
        confirm: 'पुष्टि',
        confirmed: 'अपवोटेड',
        cancel: 'निरस्त',
        sending: 'भेजा जा रहा है...',
        sent: 'सबमिट करें',
        save: 'बचाओ',
        create: 'बनाएँ',
        or: 'या',
        activate: 'सक्रिय करें',
        deactivate: 'निष्क्रिय करें',
        prevPage: 'पिछला पृष्ठ',
        nextPage: 'अगला पेज',
        of: 'बाहर',
        balance: 'बैलेंस',
        copy: 'कॉपी करें',
        copied: 'कॉपी किया गया'
    },
    bonus: {
        permanentBonus: 'परमानेंट बोनस',
        tenPercent: 'प्रत्येक जमा के लिए 10%!',
        more: 'और पढ़ें',
        fastRegistration: 'क्विक चेक-इन',
        noVerification: 'सत्यापन के बिना',
        fastWithdraws: 'तेजी से भुगतान',
        highLimits: 'उच्च सीमाएँ',
        loyaltyProgramm: 'लॉयल्टी प्रोग्राम',
        winCashback: 'प्रत्येक जीत पर 1.2% तक!',
        levelSystem: 'लेवल सिस्टम',
        bigPercents: 'बड़ा प्रतिशत',
        payForWin: 'जीत के लिए भुगतान करें',
        butNoBets: 'दांव के लिए नहीं',
        affiliateProgramm: 'एफिलिएट कार्यक्रम',
        join: 'शामिल हों',
        revenueShare: '40% रेवेन्यू शेयर',
        anytimeWithdraw: 'किसी भी क्षण में आउटपुट',
        affiliateCabinet: 'संबद्ध कैबिनेट',
        detailStat: 'विस्तृत आंकड़े',
        title: 'प्रोमो कोड',
        promoEveryDay: 'हमारे VK समूह में हर दिन प्रोमो कोड',
        enterPromoCode: 'प्रोमो कोड दर्ज करें'
    },
    fair: {
        title: 'लाइसेंस और ईमानदारी की गारंटी',
        description: 'हम सभी के लिए निष्पक्ष खेल की गारंटी देते हैं',
        fairDescription1: 'Brillx Casino समझता है कि सुरक्षा वातावरण बनाना कितना महत्वपूर्ण है ताकि उपयोगकर्ता हमारे खेल की ईमानदारी में आश्वस्त हों।',
        fairDescription2: 'इसलिए, हमारे कैसीनो में, हम ऑनलाइन कैसीनो के लिए सॉफ्टवेयर विकसित करने वाली शीर्ष कंपनियों द्वारा प्रदान किए गए गेम का उपयोग करते हैं। उनमें से कंपनियां जैसे',
        fairDescription3: 'इकोग्रा और आईजीसी के संस्थापक सदस्य के रूप में, मायक्रोगमिंग अन्य कैसीनो गेमिंग निर्माताओं से आगे एक प्रमुख है, जो जुआ उद्योग में उच्चतम मानकों को पूरी तरह से स्थापित करता है, विशेष रूप से ऑनलाइन गेमिंग की ईमानदारी के लिए, सबसे जिम्मेदार ऑपरेटरों का चयन करना, और हितों की रक्षा करना खिलाड़ियों।',
        andOther: 'और अन्य',
        also: 'हम कंपनी द्वारा बनाए गए खेल भी प्रदान करते हैं',
        defaultCasino: 'साधारण ऑनलाइन कैसीनो',
        brillxCasino: 'ब्रिल्क्स ईमानदारी नियंत्रण कैसीनो',
        defaultCasinoDescription1: 'आप एक शर्त बनाते हैं जो कैसीनो सर्वर पर लिखी जाती है',
        defaultCasinoDescription2: 'आपका डेटा कैसीनो सर्वर पर एकत्र और संग्रहीत किया जाता है',
        defaultCasinoDescription3: 'खेल ईमानदारी की जांच करना असंभव है - सभी एल्गोरिदम रिकॉर्ड किए जाते हैं और कैसीनो पक्ष में सहेजे जाते हैं',
        defaultCasinoDescription4: 'जीत पाने के लिए, आपको एक लंबी प्राधिकरण प्रक्रिया से गुजरना होगा',
        defaultCasinoDescription5: 'सवाल में कैसीनो की साल्वेंसी क्योंकि इसका संतुलन कहीं भी प्रकाशित नहीं होता है',
        brillxCasinoDescription1: 'खिलाड़ी सट्टेबाजी प्रदाताओं के डेटा की एक प्रति ब्लॉकचेन में सहेजी जाती है और इसे बदला नहीं जा सकता',
        brillxCasinoDescription2: 'मनुष्यों के लिए खेल के आंकड़े एन्क्रिप्टेड होते हैं और ब्लॉकचैन में रिकॉर्ड किए जाते हैं, खिलाड़ी व्यक्तिगत डेटा के लिए एकजुट होते हैं',
        brillxCasinoDescription3: 'अखंडता नियंत्रण ब्लॉकचेन प्रौद्योगिकी के साथ किया जाता है',
        brillxCasinoDescription4: 'निकासी के लिए केवाईसी मानक प्राधिकरण की आवश्यकता होती है, कोई स्थानांतरण शुल्क नहीं',
        brillxCasinoDescription5: 'खिलाड़ी भुगतान के लिए रिपोर्टिंग और विशाल पूल खोलें'
    },
    game: {
        loading: 'लोड हो रहा',
        lobby: 'लॉबी',
        fullScreen: 'फुल स्क्रीन',
        deposit: 'जमा',
        betsAmount: 'शर्त राशि',
        totalRTP: 'कुल आरटीपी',
        averageBet: 'औसत शर्त',
        topWin: 'शीर्ष जीत'
    },
    help: {
        title: 'सहायता एवं सहायता',
        description: 'ग्राहक सेवा के साथ सबसे आम प्रश्नों और संचार के जवाब।',
        connectTypes: 'हमारे साथ संवाद करने के तरीके',
        operatorsOnline: 'ऑनलाइन ऑपरेटर दिन में 24 घंटे',
        supportLive: 'समर्थन (लाइव)',
        onlineChat: 'लाइव चैट',
        averageTimeChat: 'औसत प्रतिक्रिया समय: 10 मिनट',
        supportEmail: 'ग्राहक सेवा (ई-मेल)',
        averageTimeEmail: 'औसत प्रतिक्रिया समय: 1 दिन',
        generalQuestions: 'सामान्य मुद्दे',
        aboutSite: 'साइट के बारे में',
        aboutSiteContent: 'ब्रिल्क्स अंतरराष्ट्रीय लाइसेंस और नाम के साथ एक ऑनलाइन कैसीनो है। हम कैटलॉग में 6,000 से अधिक गेम, स्पोर्ट्स इवेंट्स की एक विस्तृत श्रृंखला और बहुत सारे मिनी-गेम्स की पेशकश करते हैं। हम कई भुगतान प्रणालियों और अधिकांश लोकप्रिय क्रिप्टोकरेंसी का समर्थन करते हैं।',
        bonus: 'बोनस',
        bonuses: 'बोनस',
        onPage: 'पेज पर',
        manyFreeBonuses: 'आपके संतुलन के लिए पैसे पाने के कई तरीके हैं बिल्कुल मुफ्त!',
        bonus1: 'पंजीकरण बोनस। साइट पर पंजीकरण करने के बाद, आपके पास निमंत्रण कोड दर्ज करने और 10.00 आरयूबी तक पहुंचने के लिए 60 मिनट हैं।',
        bonus2: 'पंजीकरण बोनस। साइट पर पंजीकरण करने के बाद, आपके पास निमंत्रण कोड दर्ज करने और 10.00 आरयूबी तक पहुंचने के लिए 60 मिनट हैं।',
        bonus3: 'पंजीकरण बोनस। साइट पर पंजीकरण करने के बाद, आपके पास निमंत्रण कोड दर्ज करने और 10.00 आरयूबी तक पहुंचने के लिए 60 मिनट हैं।',
        bonus4: 'पंजीकरण बोनस। साइट पर पंजीकरण करने के बाद, आपके पास निमंत्रण कोड दर्ज करने और 10.00 आरयूबी तक पहुंचने के लिए 60 मिनट हैं।',
        promocodes: 'प्रोमो कोड',
        inviteFriend: 'एक दोस्त को आमंत्रित करें',
        balanceRefilling: 'बैलेंस पुनःपूर्ति',
        wallet: 'बटुआ',
        howToRefill: 'मैं शेष राशि की भरपाई कैसे करूँ?',
        commissions: 'दलाली',
        periodOfRefilling: 'नामांकन की शर्तें',
        withdrawal: 'निकासी',
        howToWithdraw: 'मैं फंड कैसे निकालूं?',
        periodOfWithdrawal: 'निकासी की समयरेखा',
        clickToRefill: 'शेष राशि को फिर से भरने के लिए बटन पर क्लिक करें',
        atRightCorner: 'साइट के ऊपरी दाएं कोने में। एक भुगतान विधि चुनें जो आपके लिए सुविधाजनक है, जमा राशि दर्ज करें, और भुगतान पर जाएं पर क्लिक करें।',
        minimalRefilling: 'न्यूनतम पुनःपूर्ति',
        commissionsOnPage: 'सभी भुगतान विधियों के कमीशन पृष्ठ पर सूचीबद्ध हैं',
        clickToWithdraw: 'अपने खाते से पैसे निकालने के लिए बटन पर क्लिक करें',
        averageTimeWithdraw: 'सभी निष्कर्षों को आवेदन के समय से एक घंटे के औसत में संसाधित किया जाता है।',
        allTransactions: 'सभी लेनदेन तुरंत संसाधित होते हैं। देरी केवल तभी हो सकती है जब आपको भुगतान विधि के साथ समस्या हो तो आप अपनी शेष राशि की भरपाई कर रहे हैं।',
        inCaseOfProblems: 'यदि समस्याएं हैं, तो कृपया हमारी ग्राहक सेवा से संपर्क करें और अपनी समस्या के बारे में सबसे विस्तृत जानकारी प्रदान करें।',
        atRightCornerWithdraw: 'साइट के ऊपरी दाएं कोने में। एक निकासी विधि चुनें जो आपके लिए सुविधाजनक हो, राशि दर्ज करें, और भुगतान करने के लिए जाओ पर क्लिक करें।',
        minimalWithdrawal: 'न्यूनतम निकासी',
        ifBigAmount: 'यदि निकासी राशि बड़ी है, तो निकासी थोड़ी देर तक जा सकती है, आमतौर पर एक घंटे तक। रात में (00 बजे से 09:00 एमएससी), निष्कर्ष कई घंटों तक जा सकते हैं, क्योंकि भुगतान एग्रीगेटर लंबे समय तक निकासी अनुरोधों को संसाधित करता है।',
        sometimes: 'बहुत दुर्लभ मामलों में, बैंक लोडिंग के कारण निकासी 24 घंटे तक जा सकती है। यदि उस समय के बाद पैसा नहीं आता है, तो कृपया हमारी ग्राहक सेवा से संपर्क करें और अपनी समस्या के बारे में सबसे विस्तृत जानकारी प्रदान करें।',
        checkCorrectPurse: 'यह भी सुनिश्चित करें कि आप आउटपुट के लिए विवरण सही ढंग से निर्दिष्ट करते हैं। आपके द्वारा निकासी की ओर इशारा करने वाले बटुए को आश्वस्त करने के लिए, आपको अपने लेनदेन इतिहास में जाना होगा और इच्छित लेनदेन पर क्लिक करके विवरण खोलना होगा।'
    },
    interface: {
        months: {
            jan: 'जन।',
            feb: 'फ़रवरी।',
            mar: 'मार्च।',
            apr: 'अप्रैल।',
            may: 'माई',
            jun: 'जून।',
            jul: 'जूल।',
            aug: 'अगस्त।',
            sep: 'सितम्बर।',
            oct: 'अक्टूबर',
            nov: 'नवम्बर।',
            dec: 'दिसम्बर।'
        },
        year: 'जी।',
        confirmPasswordRecovery: 'अपने ईमेल पर भेजे गए लिंक का पालन करके पासवर्ड रीसेट की पुष्टि करें',
        passwordWasChanged: 'पासवर्ड सफलतापूर्वक बदल गया है, आप नए डेटा का उपयोग करने के लिए लॉग इन कर सकते हैं',
        confirmEmail: 'लिंक द्वारा ईमेल पते की पुष्टि करें',
        emailWasConfirmed: 'ईमेल पते की सफलतापूर्वक पुष्टि की गई है',
        accountLinked: 'खाता सफलतापूर्वक लिंक किया गया था',
        bonusGranted: 'बोनस प्राप्त हुआ'
    },
    landing: {
        newGames: 'नए खेल',
        topGames: 'लोकप्रिय खेल',
        brillxSelect: 'ब्रिल्क्स च्वाइस',
        daylyLiveRtp: 'लाइव आरटीपी - 24 घंटे',
        brillxCasino: 'ब्रिल्क्स कैसीनो',
        casinoInfo1: 'Brillx.GG में रोमांचक खेलों की एक विस्तृत श्रृंखला देखें, एक स्लॉट मशीन साइट आपकी संतुष्टि के लिए दर्जी है। दुनिया भर के खिलाड़ियों के लिए हजारों प्रथम श्रेणी की स्लॉट मशीनें उपलब्ध हैं। चाहे वह विशाल जैकपॉट, पोकर स्लॉट या थीम्ड स्लॉट मशीनों की आवश्यकता हो, हमारे पास आपकी ज़रूरत की हर चीज है!',
        casinoInfo2: 'अभी चेक-इन बटन पर क्लिक करके एक त्वरित चेक-इन की आसानी का अनुभव करें! जब आप अग्रणी खेल प्रदाताओं से असली पैसे स्लॉट मशीनों को खेलते हैं तो क्षणों का आनंद लें।',
        casinoInfo3: 'तेजी से भुगतान के साथ हमारा कैसीनो और स्लॉट मशीन विविधताओं के सभी प्रकार का एक विशाल चयन आपके लिए 24/7 काम करता है - बस एक खेल चुनें और खेलना शुरू करें!',
        casinoInfo4: 'ऑनलाइन स्लॉट चलाएँ',
        casinoInfo5: 'हम कभी भी आपका मस्ती खराब नहीं करते हैं, इसलिए आप अपने कंप्यूटर पर कुछ भी डाउनलोड किए बिना हमारे सभी गेम खेल सकते हैं।',
        casinoInfo6: 'हजारों स्लॉट ब्राउज़ करें, अपनी पसंद के खेल का चयन करें, और “प्ले” पर क्लिक करें - यह इतना आसान है!',
        casinoInfo7: 'कैसीनो स्लॉट खेल सबसे लोकप्रिय खेलों में से एक हैं। न केवल विशाल प्रोत्साहनों के कारण आप प्राप्त कर सकते हैं, बल्कि रोमांचक ग्राफिक्स, अच्छे संगीत और खेल के समग्र सुखद दृश्य प्रभाव के कारण भी।',
        casinoInfo8: 'आप मनोरंजन मोड पर स्विच करके हमारी प्रत्येक स्लॉट मशीनों को भी आज़मा सकते हैं - एक अद्भुत विशेषता जो आपको बिना डाउनलोड किए और पंजीकरण के बिना हमारी सभी स्लॉट मशीनों को खेलने की सुविधा देती है! आप इसे अपने मोबाइल फोन, कंप्यूटर या टैबलेट के माध्यम से एक्सेस कर सकते हैं। आज की सबसे अच्छी स्लॉट मशीनों में से कुछ की खोज करें कि आप डाउनलोड करने की आवश्यकता के बिना मुफ्त में खेल सकते हैं, और दुनिया भर में सबसे अच्छे कैसीनो स्लॉट में एक अद्वितीय साहसिक अनुभव कर सकते हैं। आज अपना सबसे अच्छा शॉट लें, और भाग्य तय करें कि आपको क्या इंतजार है!',
        casinoInfo9: 'आप किसकी प्रतीक्षा कर रहे हैं? आज आपका भाग्यशाली दिन हो सकता है!',
        casinoInfo10: 'हजारों स्लॉट मशीनें',
        casinoInfo11: 'यहां विभिन्न प्रकार की स्लॉट मशीनें दी गई हैं जिन्हें आप हमारे कैसीनो में पा सकते हैं:',
        casinoInfo12: '3 रील स्लॉट',
        casinoInfo13: '3 रील स्लॉट को क्लासिक या पारंपरिक स्लॉट मशीनों के रूप में जाना जाता है। रील ऊर्ध्वाधर रेखाएं हैं जो आप खेल में पा सकते हैं जहां प्रतीकों को कैस्केड किया जाता है। यदि आप रोमांच की तलाश कर रहे हैं, तो 3 रील स्लॉट मशीन खेलने का प्रयास करें! कुछ खेल जो आप खेल सकते हैं वे बुक ऑफ कीमिया, वॉटफोर्ड एफसी स्लॉट और कई अन्य हैं।',
        casinoInfo14: '5 रील स्लॉट',
        casinoInfo15: '5 रील स्लॉट खेलने से संयोजनों को जीतने का एक बेहतर मौका प्राप्त करें! अधिकांश 5 रील स्लॉट हर बार 3, 4, या 5 अक्षर रीलों पर गिरते हैं। आप खेल के आधार पर जीत गुणक, बोनस गेम राउंड, या फ्री स्पिन भी प्राप्त कर सकते हैं। आप जो 5 रील खेल खेल सकते हैं उनमें से कुछ वाइकिंग्स गो बर्ज़र्क, लिगेसी ऑफ डेड, और यह रिच है।',
        casinoInfo16: 'प्रोग्रेसिव स्लॉट',
        casinoInfo17: 'प्रोग्रेसिव स्लॉट सभी प्रकार की स्लॉट मशीनों में एक रयूस हैं। जैकपोट का आकार बढ़ता है जैकपोट की प्रगति होती है। आपके लिए बहुत सारे लाखों इंतजार कर रहे हैं, और आप आज तक के सबसे अमीर लोगों में से एक बन सकते हैं!',
        casinoInfo18: 'रूले',
        casinoInfo19: 'रूले एक ऐसा खेल है जो एक टेबल, रंग, संख्या प्रकार या संयोजन पर व्यक्तिगत संख्याओं पर दांव लगाता है। अपनी किस्मत का परीक्षण करें और जीतें अगर आप पहिया को हिट करते हैं तो आप क्या शर्त लगाते हैं। अभी हमारे महान रीयल-टाइम रूले खेल खेलें!',
        casinoInfo20: 'पोकर',
        casinoInfo21: 'यदि आप नियमित कैसीनो में पोकर से परिचित हैं, तो आप ऑनलाइन वीडियो पोकर गेम के हमारे विस्तृत चयन का लाभ उठाकर अभी इसे खेलने की कोशिश कर सकते हैं। आज एक अविस्मरणीय खेल का अनुभव करने के लिए तैयार रहें! उच्च परिभाषा एनीमेशन और महान विशेष वीडियो राउंड के साथ, आप निश्चित रूप से एक नई जीत के लिए वापस आएंगे।',
        casinoInfo22: 'बैकारेट',
        casinoInfo23: 'बैकारेट दुनिया के सबसे लोकप्रिय खेलों में से एक है। 9 के सबसे नज़दीकी हाथ पर दांव लगाकर डीलर को हराएं। इसके अलावा, आप ड्रॉ होने पर भी जीत सकते हैं। रोमांचक लगता है, वास्तव में? अपने दांव अभी तैयार हो जाओ और विजेता पक्ष पर शर्त लगाओ!',
        casinoInfo24: 'डांडा',
        casinoInfo25: 'लाठी में, आप डीलर को हरा सकते हैं यदि आपके पास हाथ 21 के सबसे नज़दीकी है। जब आप हमारे राजसी लाठी स्लॉट मशीनों को खेलते हैं तो अपनी शर्त को दोगुना करने का मौका प्राप्त करें!',
        gamesProviders: 'खेल प्रदाता',
        showMore: 'और दिखाओ',
        hideInfo: 'जानकारी छिपाएँ',
        allGames: 'सभी खेल',
        providers: 'प्रोवाइडर',
        new: 'नया',
        tournaments: 'टूर्नामेंट',
        slots: 'स्लॉट्स',
        roulette: 'रूले',
        card: 'कार्ड्स',
        videopoker: 'वीडियो पोकर',
        lottery: 'लॉटरी'
    },
    live: { demo: 'डेमो', play: 'प्ले', title: 'Live' },
    lobby: {
        providers: 'प्रोवाइडर',
        recent: 'हाल ही में',
        top: 'पॉपुलर',
        new: 'नया',
        favorite: 'पसंदीदा',
        nothingFound: 'कुछ नहीं मिला',
        play: 'खेलना',
        demo: 'प्रदर्शन',
        forExample: 'उदाहरण के लिए'
    },
    log: { date: 'तारीख', type: 'ऑपरेशन', amount: 'रकम', id: 'आईडी' },
    loyalty: {
        title: 'लॉयल्टी प्रोग्राम',
        description: 'कैशबैक प्राप्त करें जो हर शर्त पर वफादारी स्तर पर निर्भर करता है',
        cabinet: 'कैबिनेट',
        myCabinet: 'मेरा कार्यालय',
        payLog: 'प्रोद्भवन लॉग',
        log: 'लोगू',
        level: 'स्तर',
        totalWins: 'जीतने की राशि',
        totalBets: 'बेट राशि',
        availableBalance: 'उपलब्ध बैलेंस',
        get: 'दूर ले जाओ',
        cashback: 'कैशबैक',
        emptyHistory: 'कहानी गायब है',
        operationID: 'ऑपरेशन आईडी',
        amount: 'रकम',
        date: 'तारीख',
        operationDate: 'ऑपरेशन की तारीख',
        operationType: 'ऑपरेशन',
        balanceBefore: 'बैलेंस अप',
        balanceAfter: 'संतुलन के बाद'
    },
    menu: {
        affProgramm: 'पार्टनर्स',
        fairGame: 'फेयर प्ले',
        free: 'फ्री',
        games: 'गेम्स',
        lobby: 'लॉबी',
        promo: 'प्रोमो',
        loyaltyProgramm: 'वफादारी',
        promotions: 'स्टॉक्स',
        support: 'सहायता केंद्र',
        settings: 'सेटिंग',
        history: 'खेल का इतिहास',
        more: 'और',
        profile: 'प्रोफिल',
        bonusCode: 'प्रोमो कोड'
    },
    noty: { error: 'एरर', success: 'सफल' },
    profile: {
        wallet: 'बटुआ',
        onSiteFrom: 'साइट के साथ',
        totalBets: 'बेट राशि',
        betsCount: 'बेटिंग',
        totalWins: 'जीतने की राशि',
        bestWin: 'सबसे अच्छी जीत',
        emptyHistory: 'कहानी गायब है',
        balanceAfter: 'संतुलन के बाद'
    },
    partner: {
        maxCodeLength: 'अधिकतम कोड की लंबाई:',
        minCodeLength: 'न्यूनतम कोड की लंबाई:',
        maxNameLength: 'अधिकतम नाम की लंबाई:',
        minNameLength: 'नाम की न्यूनतम लंबाई:',
        symbols: 'वर्ण',
        confirmDeleteCampgain: 'क्या आप वास्तव में अभियान को हटाना चाहते हैं?',
        campgains: 'अभियान',
        refresh: 'उन्नयन',
        export: 'एक्सपोर्ट',
        campgainName: 'अभियान का नाम',
        affiliateLink: 'एफिलिएट लिंक',
        name: 'टाइटल',
        creationDate: 'तारीख बनाई गई',
        clicks: 'संक्रमण',
        link: 'संदर्भ',
        manage: 'प्रबन्धन',
        noCampgains: 'कोई सक्रिय विज्ञापन अभियान नहीं',
        namePlaceholder: 'पैनल में प्रदर्शित करने के लिए नाम',
        general: 'साधारण',
        mainAffiliateLink: 'मुख्य सहबद्ध लिंक',
        registrations: 'रजिस्ट्रेशन',
        totalProfit: 'कॉमन प्रॉफिट',
        availableBalance: 'उपलब्ध बैलेंस',
        get: 'दूर ले जाओ',
        emptyHistory: 'कहानी गायब है',
        operationID: 'ऑपरेशन आईडी',
        amount: 'रकम',
        date: 'तारीख',
        operationDate: 'ऑपरेशन की तारीख',
        operationType: 'ऑपरेशन',
        playerID: 'प्लेयर आईडी',
        balanceAfter: 'संतुलन के बाद',
        balanceBefore: 'बैलेंस अप',
        log: 'प्रोद्भवन लॉग',
        players: 'खिलाड़ियों',
        registration: 'रजिस्ट्रेशन',
        registrationDate: 'पंजीकरण की तारीख',
        balance: 'बैलेंस',
        noPlayers: 'कोई अतिथि खिलाड़ी नहीं',
        totalBets: 'बेट राशि',
        totalDeps: 'जमा राशि',
        totalWithdraws: 'निष्कर्षों का योग',
        registrationSource: 'रजिस्ट्रेशन स्रोत',
        mainLink: 'मुख्य लिंक',
        player: 'खिलाड़ी',
        registrationsCount: 'रजिस्ट्रेशन',
        bets: 'बाज़ी लगाना',
        profit: 'प्रॉफिट',
        stat: 'सांख्यिकी',
        totalStat: 'समग्र आंकड़े',
        partnerCabinet: 'पार्टनर का कार्यालय',
        partnerID: 'पार्टनर आईडी'
    },
    privacy: {
        title: 'प्राइवेसी पॉलिसी',
        description: 'सामान्य प्रावधान और सूचना',
        redaction: 'संपादकीय द्वारा',
        content: ''
    },
    promo: {
        linkVk: 'बोनस पाने के लिए VK खाते को बाँध लें',
        completeAll: 'सभी शर्तों को फिट करें और प्राप्त करें',
        toBalanceForRepost: 'repost के लिए संतुलन पर!',
        link: 'बांधने वाला',
        join: 'एंट्री',
        allowNotifications: 'सूचनाओं की अनुमति दें',
        make: 'करते हैं',
        toProfileVkAccount: 'वीके खाता प्रोफ़ाइल में।',
        toOurVkGroup: 'हमारे वीकॉन्टैक्टे समूह के लिए।',
        fromOurGroup: 'हमारे समुदाय से।',
        repostToYourPage: 'अपने पृष्ठ पर प्रविष्टि को दोबारा पोस्ट करें।',
        mustBePublic: 'आपका VK पृष्ठ सार्वजनिक होना चाहिए या हम एक repost की जांच नहीं कर पाएंगे।',
        dontDelete: 'यदि आप पोस्ट के बाद 2 सप्ताह से पहले एक पोस्ट हटाते हैं, तो बोनस राशि शेष राशि से काट ली जाएगी।',
        joinAndGet: 'एक समूह में शामिल हों और प्राप्त करें',
        toBalance: 'संतुलन पर!',
        bonusGranted: 'बोनस सफलतापूर्वक प्राप्त हुआ!',
        momentalBonus: 'हमारे VK समूह में शामिल हों और अपने संतुलन पर एक त्वरित बोनस प्राप्त करें! VK प्रोफ़ाइल सार्वजनिक होनी चाहिए, या हम सदस्यता की जांच नहीं कर पाएंगे।',
        vkDoesntLinked: 'VK खाता बंधे नहीं',
        allowNotificationsAndGet: 'हमारे समुदाय से सूचनाओं की अनुमति दें और प्राप्त करें',
        sendAnyMessage: 'हमारे VK समूह को कोई भी संदेश भेजें, जिससे सूचनाएं भेजी जा सकें। एक अच्छा आश्चर्य - समय-समय पर हम प्रचार और विभिन्न बोनस के बारे में जानकारी भेजेंगे!',
        rollAndGetBonus: 'अपने रूले को क्रूज़ करें और बोनस प्राप्त करें!',
        rollOncePer: 'हर 15 मिनट में एक बार रूले को ट्विस्ट करें और तब तक पहुंचें',
        rollEveryDay: 'हर दिन प्रतिबंध के बिना पहिया स्पिन करें!',
        untilNextTry: 'अगले प्रयास के छोड़े जाने से पहले',
        subscribeTg: 'पहले नए प्रोमो कोड प्राप्त करने के लिए हमारे टेलीग्राम चैनल पर साइन अप करें!',
        beFirst: 'एक ताजा प्रोमो कोड प्राप्त करने वाले पहले व्यक्ति बनें, नए बोनस और नवाचारों के बारे में जानें!',
        referrals: 'रेफ़रल',
        getPercent: 'दोस्तों को आमंत्रित करें और कैसीनो लाभ का 40% कमाएं!',
        copyLink: 'लिंक कॉपी करें',
        inviteFriend: 'एक दोस्त को आमंत्रित करें',
        getBonus: 'एक बोनस प्राप्त करें',
        affiliateProgramm: 'एफिलिएट कार्यक्रम',
        sendReview: 'हमारे समूह में परियोजना पर प्रतिक्रिया फ़ीड करें और प्राप्त करें',
        bonusToDeposit: 'जमा बोनस',
        upTo: 'तक',
        makeReviews: 'मॉडरेटर सत्यापित और पोस्ट किए जाने के बाद, हमारे वीकॉन्टैक्टे समूह में फीडबैक छोड़ दें, बोनस स्वचालित रूप से आपके खाते पर सक्रिय हो जाएगा।',
        makeReview: 'फ़ीडबैक',
        ranks: 'रंगी',
        earnFromWin: 'रैंकों की कमाई आपको प्रत्येक जीत से कैशबैक बढ़ेगी।',
        loyaltyProgramm: 'लॉयल्टी प्रोग्राम',
        writeMessage: 'एक संदेश लिखें',
        goToGroup: 'Group पर जाएँ',
        refreshing: 'अपडेट करें...',
        refreshStatus: 'उन्नयन',
        roll: 'घुमाएँ',
        linkAccount: 'एक खाता बाँध लें',
        checking: 'जाँच कर रहा है...',
        get: 'प्राप्त करें'
    },
    promotions: {
        title: 'स्टॉक्स एंड ऑफर्स',
        description: 'हमारे मौजूदा शेयरों में बोनस पाने का अवसर न चूकें',
        game: 'खेल',
        minDeposit: 'मिन। जमा',
        bonus: 'बोनस',
        addWager: 'अतिरिक्त दांव',
        validUntil: 'अप करने के लिए कार्य करता है',
        activation: 'एक्टिवेशन',
        get: 'प्राप्त करें',
        unlimit: 'असीमित',
        oneTime: 'एक बार',
        forEveryDeposit: 'हर जमा के लिए',
        permanentBonus: 'परमानेंट बोनस',
        any: 'कोई भी'
    },
    responsibility: {
        title: 'जिम्मेदार खेल',
        description: 'जिम्मेदार खेल युक्तियाँ और नियम',
        content: ''
    },
    settings: {
        incorrectEmail: 'ईमेल पता गलत तरीके से',
        bigFileSize: 'फ़ाइल का आकार 5 एमबी से अधिक नहीं होना चाहिए।',
        genders: { man: 'पुरुषों के लिए', female: 'महिलाएं', other: 'दूसरा' },
        requiredField: 'आवश्यक क्षेत्र',
        dataSuccessChanged: 'डेटा को सफलतापूर्वक अपडेट किया गया है',
        tfaLoginRequired: 'प्रमाणक सेट करने के लिए लॉगिन और पासवर्ड सेट करें',
        general: 'साधारण',
        login: 'लॉगइन',
        vkProfile: 'VK प्रोफाइल',
        glProfile: 'गूगल प्रोफाइल',
        okProfile: 'प्रोफाइल सहपाठियों',
        fbProfile: 'फेसबुक प्रोफाइल',
        security: 'सुरक्षा',
        alterLogin: 'अपने खाते में लॉगिन और पासवर्ड की एक वैकल्पिक विधि जोड़ें।',
        newPassword: 'नया पासवर्ड',
        repeatPassword: 'पासवर्ड दोहराएँ',
        saveActions: 'एक्शन्स सेव करें',
        currentPassword: 'मौज़ूदा पासवर्ड',
        tfa: 'टू-फैक्टर ऑथेंटिकेशन',
        addTfa: 'अतिरिक्त सुरक्षा के साथ अपने Brillx खाते को सुरक्षित रखें',
        tfaSettings: '2FA सेटिंग',
        sessions: 'सत्र',
        device: 'डिवाइस',
        region: 'क्षेत्र',
        ipAddress: 'आईपी एड्रेस',
        date: 'तारीख',
        session: 'सत्र',
        active: 'एक्टिव',
        closed: 'बन्द',
        verification: 'सत्यापन',
        verificationDescription1: ' आपकी सेवा स्तर को बेहतर बनाने के लिए, हम दस्तावेजों की पहचान करने के लिए पूछते हैं। यह आपके खाते को बहाल करने पर सुरक्षित रखेगा, और भौतिक उपहारों की सटीक लक्षित वितरण प्रदान करेगा।',
        verificationDescription2: 'सत्यापन के स्वीकार्य तरीकों के लिए आपकी आईडी, ड्राइवर का लाइसेंस या पासपोर्ट की एक तस्वीर प्रदान करना है। इस प्रक्रिया के पूरा होने पर, हम आपको ईमेल द्वारा सूचित करेंगे।',
        frontPhoto: 'पहचान दस्तावेज के सामने का फोटो',
        selectFile: 'फ़ाइल का चयन करें',
        backPhoto: 'पहचान दस्तावेज की पिछली तस्वीर',
        fileIsNotSelected: 'फ़ाइल का चयन नहीं किया गया',
        name: 'नाम',
        surname: 'उपनाम',
        birthday: 'जन्म दिन',
        gender: 'लिंग',
        country: 'देश',
        city: 'शहर',
        yourName: 'आपका नाम',
        yourSurname: 'आपका अंतिम नाम',
        verifyStatus1: 'दस्तावेजों की जांच की जा रही है...',
        verifyStatus2: 'खाता सत्यापित',
        verifyStatus3: 'डॉक्यूमेंट चेक त्रुटि',
        verifyDescription1: 'दस्तावेज़ वर्तमान में समीक्षा के अधीन हैं, जैसे ही दस्तावेज सत्यापित होते हैं, आपका खाता सत्यापित हो जाएगा!',
        verifyDescription2: 'आपके डेटा को सुरक्षा कर्मियों द्वारा सत्यापित और पुष्टि की गई है।',
        verifyDescription3: 'कृपया विवरण के लिए तकनीकी सहायता से संपर्क करें।',
        registrationCity: 'सिटी ऑफ रजिस्ट्रेशन',
        sendDocs: 'निरीक्षण के लिए जमा करें',
        affiliateCabinet: 'पार्टनर का कार्यालय'
    },
    tos: {
        title: 'यूज़र एग्रीमेंट',
        description: 'नियम और उपयोग के नियम',
        redaction: 'संपादकीय द्वारा',
        content: ''
    },
    wallet: {
        deposit: 'जमा',
        withdraw: 'आउटपुट',
        history: 'इतिहास',
        minDepositAmount: 'न्यूनतम जमा के माध्यम से',
        minWithdrawAmount: 'न्यूनतम आउटपुट के माध्यम से',
        topMethods: 'पॉपुलर',
        allMethods: 'सभी विधियाँ',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'अन्य',
        success: 'सफल',
        canceled: 'रद्द',
        error: 'एरर',
        waiting: 'वेटिंग',
        processing: 'आवेदन संसाधित किया जा रहा है',
        incorrectWalletValue: 'वॉलेट नंबर गलत तरीके से दर्ज किया गया',
        incorrectCardValue: 'कार्ड नंबर गलत तरीके से दर्ज किया गया',
        selectTemplate: 'टेम्पलेट चुनें',
        withdrawCreated: 'पेआउट एप्लिकेशन बनाया गया है और 24 घंटे के भीतर संसाधित किया जाएगा',
        withdrawCanceled: 'पेआउट बोली रद्द कर दी गई है',
        firstWithdraw: 'यह आपका पहला निष्कर्ष है',
        isDataCorrect: 'क्या आपको विश्वास है कि डेटा प्रविष्टि सही है?',
        payMethod: 'भुगतान विधि',
        withdrawMethod: 'निकासी विधि',
        selectMethod: 'एक रास्ता चुनें',
        selectOperator: 'ऑपरेटर का चयन करें',
        refillAmount: 'पुनःपूर्ति',
        withdrawAmount: 'आहरण राशि',
        payTroubles: 'अगर आपको भुगतान करने में परेशानी होती है',
        payHere: 'यहां भुगतान करें',
        pay: 'पेमेंट पर जाएं',
        commission: 'दलाली',
        onePayLimit: 'एकल पुनःपूर्ति सीमा',
        oneWithdrawLimit: 'एकल आउटपुट सीमा',
        emptyHistory: 'कहानी गायब है',
        operationID: 'ऑपरेशन आईडी',
        deposits: 'पुनःपूर्ति',
        withdraws: 'जाँच - परिणाम',
        txAmount: 'ट्रांज़ैक्शन राशि',
        txDate: 'ऑपरेशन की तारीख',
        purse: 'स्कोर',
        typePurse: 'खाता संख्या',
        toGet: 'प्राप्त करने के लिए',
        createWithdraw: 'एक एप्लिकेशन बनाएँ'
    },
    tfa: {
        tfa: 'टू-फैक्टर ऑथेंटिकेशन',
        typeYourPassword: 'अपना पासवर्ड दर्ज करें',
        enterPin: 'ऐप से 6 अंकों का कोड दर्ज करें',
        downloadApp: 'ऐप डाउनलोड करें',
        downloadAndInstall: 'डाउनलोड करें और इंस्टॉल करें',
        forYourPhone: 'अपने फोन या टैबलेट के लिए',
        scanQR: 'क्यूआर कोड स्कैन करें',
        scanQRWithCamera: 'अपने फोन के कैमरे के साथ बाईं ओर क्यूआर स्कैन करें',
        openWithLink: 'लिंक से खोलें',
        openInApp: 'ऐप में खोलें',
        tfaManualEnter: '2FA कुंजी (मैनुअल इनपुट)'
    },
    पीडब्ल्यूए: {
        androidStep1: 'Chrome ब्राउज़र खोलें',
        androidStep1Description: 'यदि आप किसी अन्य ब्राउज़र में हैं, तो इस पेज को क्रोम में खोलें',
        androidStep2: 'ब्राउज़र मेनू पर क्लिक करें',
        androidStep2Description: 'ब्राउज़र मेनू आपके ब्राउज़र के ऊपरी दाएं कोने में पाया जाने वाला दीर्घवृत्त है',
        androidStep3: "होम स्क्रीन में जोड़ें' चुनें",
        androidStep3Description: "आपको जानकारी के साथ एक विंडो दिखाई देगी (\"होम स्क्रीन में जोड़ें\"), जिसमें आपको \"जोड़ें\" का चयन करना होगा, जिसके बाद एप्लिकेशन आपके डिवाइस के डेस्कटॉप पर जोड़ दिया जाएगा",
        androidAlert: 'ब्राउज़र संस्करण के आधार पर चरण भिन्न हो सकते हैं',
        iosStep1: 'सफ़ारी ब्राउज़र खोलें',
        iosStep1Description: 'यदि आप किसी अन्य ब्राउज़र में हैं, तो इस पेज को सफारी में खोलें',
        iosStep2: "शेयर करें' पर क्लिक करें",
        iosStep2Description: "स्क्रीन के नीचे \"शेयर\" बटन पर क्लिक करें, जिसके बाद पैरामीटर चयन विंडो खुल जाएगी",
        iosStep3: "होम स्क्रीन में जोड़ें' चुनें",
        iosStep3Description: "होम स्क्रीन में जोड़ें' चुनें और 'जोड़ें' पर क्लिक करें।",
        alert: 'ब्राउज़र संस्करण के आधार पर चरण भिन्न हो सकते हैं',
        installApp: 'Brillx ऐप इंस्टॉल करें',
        back: 'पीछे',
        app: 'मोबाइल एप्लिकेशन',
        installAppForMobile: 'iOS और Android के लिए नया सुविधाजनक Brillx ऐप इंस्टॉल करें',
        install: 'इंस्टॉल करें'
    },
    undefined: '???'
};