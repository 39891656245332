import React from 'react';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.lang], [this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <svg className={this.props.className || ''} style={this.props.style || {}}><use xlinkHref={`${this.props.t.icons}#` + this.props.name} /></svg>
        )
    }
}