import React from 'react';
import Slider from '../modules/LandingSlider';
import Menu from '../modules/LandingMenu';
import GamesNew from '../modules/LandingGamesNew';

export default class Landing extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.pState = this.props.t.cloneDeep(this.props.t.state);
    document.title = 'Online Casino - ' + this.props.t.config.name;
  }

  shouldComponentUpdate() {
    let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.providers.length, this.props.t.state.Landing.providersOpen, this.props.t.state.Landing.typesOpen, this.props.t.state.Landing.infoOpen, this.props.t.state.Landing.newGames.length, this.props.t.state.lang, this.props.t.state.width], [this.pState.Lobby.providers.length, this.pState.Landing.providersOpen, this.pState.Landing.typesOpen, this.pState.Landing.infoOpen, this.pState.Landing.newGames.length, this.pState.lang, this.pState.width]);

    if (needUpdate)
      this.pState = this.props.t.cloneDeep(this.props.t.state);

    return needUpdate;
  }

  render() {
    return (
      <div className={this.props.t.styles['landingWrapper']}>
        <Slider t={this.props.t} />
        <Menu t={this.props.t} />
        <GamesNew t={this.props.t} />
      </div>
    )
  }
}