import React from 'react';
import General from '../modules/LoyaltyGeneral';
import Log from '../modules/LoyaltyLog';

import DiamondBlue from '../assets/svg/Loyalty/diamond_blue.svg';
import DiamondYellow from '../assets/svg/Loyalty/diamond_yellow.svg';
import DiamondOrange from '../assets/svg/Loyalty/diamond_orange.svg';
import DiamondGreen from '../assets/svg/Loyalty/diamond_green.svg';
import DiamondRed from '../assets/svg/Loyalty/diamond_red.svg';
import DiamondPurple from '../assets/svg/Loyalty/diamond_purple.svg';
import DiamondBrown from '../assets/svg/Loyalty/diamond_brown.svg';
import DiamondBlack from '../assets/svg/Loyalty/diamond_black.svg';

const Levels = [{ level: 1, percent: 0.15, img: DiamondBlue }, { level: 2, percent: 0.30, img: DiamondYellow }, { level: 3, percent: 0.45, img: DiamondOrange }, { level: 4, percent: 0.60, img: DiamondGreen }, { level: 5, percent: 0.75, img: DiamondRed }, { level: 6, percent: 0.90, img: DiamondPurple }, { level: 7, percent: 1.05, img: DiamondBrown }, { level: 8, percent: 1.2, img: DiamondBlack }];

export default class Loyalty extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        this.props.t.updateState(this.props.t, 'location', 'loyalty');

        if (!this.props.t.state.userID)
            this.props.t.doRoute('/');
        else {
            this.props.t.Interface.LoyaltyGetStats(this.props.t);
            this.props.t.Interface.LoyaltyGetBalance(this.props.t);
        }

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'loyaltyProgramm') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'location', '/');
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Loyalty, this.props.t.state.lang, this.props.t.state.width], [this.pState.Loyalty, this.pState.lang, this.pState.width]);
    }

    getUserLevel(points) {
        points = parseInt(points / 10000);

        let level = 0, used = 1;

        while (points >= used * 2) {
            level++;
            points -= used * 2;
            used *= 2;
        }

        return { level, points, used };
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['loyaltyContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'loyalty', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'loyalty', 'description')}</div></div>
                        <div className={this.props.t.styles['navBlock']}>
                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Loyalty', 1, 'module'), localStorage.setItem('loyalty.module', 1))} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Loyalty.module === 1 ? this.props.t.styles['active'] : ''].join(' ')}>{this.props.t.state.width > 900 ? this.props.t.Interface.getText(this.props.t, 'loyalty', 'myCabinet') : this.props.t.Interface.getText(this.props.t, 'loyalty', 'cabinet')}</button>
                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Loyalty', 2, 'module'), localStorage.setItem('loyalty.module', 2))} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Loyalty.module === 2 ? this.props.t.styles['active'] : ''].join(' ')}>{this.props.t.state.width > 900 ? this.props.t.Interface.getText(this.props.t, 'loyalty', 'payLog') : this.props.t.Interface.getText(this.props.t, 'loyalty', 'log')}</button>
                        </div>
                        {this.props.t.state.Loyalty.module === 1 ? <General t={this.props.t} /> : <Log t={this.props.t} />}
                    </div>
                </div>
            </>
        )
    }
}