import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Socket from "socket.io-client";
import cloneDeep from 'lodash/cloneDeep';

import 'bootstrap';
import bstyles from '../styles/bootstrap/scss/bootstrap.module.scss';
import styles from '../styles/brillx.module.scss';
import config from '../config.js';
import icons from '../assets/svg/icons.svg';
import logo from '../assets/svg/logo.svg';
import logoMobile from '../assets/svg/logoMobile.svg';
import Loader from '../assets/svg/loader.svg';

import Interface from '../lib/Interface';

/* Modules */
import Header from '../modules/Header';
import Content from '../modules/Content';
import Modal from '../modules/Modal';
import ModalSecond from '../modules/ModalSecond';
import Noty from '../modules/Noty';
/* ------- */

/* Languages */
import ru from '../lib/lang/ru';
import en from '../lib/lang/en';
import uk from '../lib/lang/uk';
import de from '../lib/lang/de';
import es from '../lib/lang/es';
import pt from '../lib/lang/pt';
import pl from '../lib/lang/pl';
import ja from '../lib/lang/ja';
import zh from '../lib/lang/zh';
import tr from '../lib/lang/tr';
import fr from '../lib/lang/fr';
import vi from '../lib/lang/vi';
import ko from '../lib/lang/ko';
import id from '../lib/lang/id';
import hi from '../lib/lang/hi';
import fl from '../lib/lang/fl';
/* Languages */

/* Pages */
import Aml from '../pages/Aml';
import Auth from '../pages/Auth';
import Confirm from '../pages/Confirm';
import Game from '../pages/Game';
import Go from '../pages/Go';
import Fair from '../pages/Fair';
import Help from '../pages/Help';
import Landing from '../pages/Landing';
import Lobby from '../pages/Lobby';
import Profile from '../pages/Profile';
import Loyalty from '../pages/Loyalty';
import Partner from '../pages/Partner';
import Privacy from '../pages/Privacy';
import Promo from '../pages/Promo';
import Promotions from '../pages/Promotions';
import Pwa from '../pages/Pwa';
import Recovery from '../pages/Recovery';
import Responsibility from '../pages/Responsibility';
import Settings from '../pages/Settings';
import Tos from '../pages/Tos';
import Wallet from '../modules/modals/Wallet';
/* ------- */

/* Static */
import GameLoader from './static/GameLoader';
/* ------- */

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Dimensions } from 'react-native';

const windowSize = Dimensions.get('window');

class Index extends React.Component {
	state = {
		width: windowSize.width,
		height: windowSize.height,
		redirect: false,
		clipboard: false,
		clipboardModule: false,
		connected: false,
		location: '/',
		isWallet: false,
		lang: {
			current: localStorage.getItem('interface.language') || this.getUserLang() || config.defaultLanguage,
			loaded: { ru, en, uk, de, es, pt, pl, ja, zh, tr, fr, vi, ko, id, hi, fl }
		},
		auth: {
			process: false,
			link: false,
			redirect: localStorage.getItem('route.authRedirect') || false
		},
		confirm: {
			token: false
		},
		bonus: {
			process: false
		},
		recovery: {
			token: false,
			process: false
		},
		modal: {
			show: false,
			content: false,
			className: '',
			opened: false
		},
		modalSecond: {
			show: false,
			content: false,
			className: '',
			opened: false
		},
		form: {
			auth: {
				login: {
					data: '',
					error: false
				},
				password: {
					data: '',
					error: false
				},
			},
			registration: {
				login: {
					data: '',
					error: false
				},
				password: {
					data: '',
					error: false
				},
				passwordConfirm: {
					data: '',
					error: false
				}
			},
			bonus: {
				code: {
					data: '',
					error: false
				}
			},
			recovery: {
				email: {
					data: '',
					error: false
				},
				password: {
					data: '',
					error: false
				},
				passwordConfirm: {
					data: '',
					error: false
				}
			},
			partner: {
				code: {
					data: '',
					error: false
				},
				campgainName: {
					data: '',
					error: false
				},
				campgainCode: {
					data: Interface.randomString(config.limits.form.partner.campgains.code.default),
					error: false
				}
			},
			settings: {
				name: {
					data: '',
					error: false
				},
				email: {
					data: '',
					error: false
				},
				login: {
					data: '',
					error: false
				},
				password: {
					data: '',
					error: false
				},
				passwordConfirm: {
					data: '',
					error: false
				},
				passwordCurrent: {
					data: '',
					error: false
				},
				verifyFront: {
					data: false,
					error: false
				},
				verifyBack: {
					data: false,
					error: false
				},
				verifyName: {
					data: '',
					error: false
				},
				verifyLastName: {
					data: '',
					error: false
				},
				verifyDate: {
					data: '',
					error: false
				},
				verifyGender: {
					data: config.settings.verify.genders[0],
					error: false
				},
				verifyCountry: {
					data: 'RU',
					error: false
				},
				verifyCity: {
					data: '',
					error: false
				},
				tfaPassword: {
					data: '',
					error: false
				},
				tfaPin1: {
					data: '',
					disabled: false
				},
				tfaPin2: {
					data: '',
					disabled: true
				},
				tfaPin3: {
					data: '',
					disabled: true
				},
				tfaPin4: {
					data: '',
					disabled: true
				},
				tfaPin5: {
					data: '',
					disabled: true
				},
				tfaPin6: {
					data: '',
					disabled: true
				},

			},
			wallet: {
				deposit: {
					data: config.wallet.deposit.defaultValue,
					error: false
				},
				withdraw: {
					data: config.wallet.withdraw.defaultValue,
					error: false
				},
				wallet: {
					data: '',
					error: false
				},
			}
		},
		inputs: {
			auth: {
				tfaPin: false,
				login: false,
				password: false
			},
			settings: {
				login: false,
				password: false,
				passwordConfirm: false,
				passwordCurrent: false,
				tfaPassword: false,
				email: false
			},
			partner: {
				code: false,
				campgainCode: false
			},
			registration: {
				login: false,
				password: false,
				passwordConfirm: false
			},
			wallet: {
				deposit: false,
				withdraw: false,
				wallet: false
			}
		},
		noty: {
			currentID: 0,
			data: []
		},
		userRefer: localStorage.getItem('user.referer') || false,
		userID: localStorage.getItem('user.id') || false,
		authKey: localStorage.getItem('user.key') || false,
		authTempKey: false,
		userData: localStorage.getItem('user.data') ? JSON.parse(localStorage.getItem('user.data')) : false,
		Game: {
			data: false,
			id: 0,
			mode: false,
			iframe: false,
			providerData: false,
			stats: {
				bets: 0,
				win: 0,
				top: 0,
				count: 0
			},
			mobileOverlay: {
				open: false,
				liked: false
			}
		},
		Header: {
			block: Header,
			balance: localStorage.getItem('user.balance') || 0,
			balanceDiff: 0,
			balanceDiffLock: false,
			gamesList: [],
			menuOpen: false
		},
		HeaderProfile: {
			open: false
		},
		Fair: {
			fairBlockModule: true
		},
		Help: {
			open: {}
		},
		Landing: {
			newGames: [],
			typesOpen: false,
			providersOpen: false,
			infoOpen: false
		},
		LeftBar: {
			tipText: '',
			tipObject: false
		},
		LiveBets: {
			languageDropdown: false,
			liveBets: [],
			scroll: {
				heigth: 0,
				offset: 0,
				top: 0,
				items: 0,
				newItems: 0
			},
			loaded: [],
			updated: 0
		},
		Lobby: {
			categories: [],
			providers: [],
			loadedGames: [],
			hasMore: true,
			gamesList: [],
			providersOpen: false,
			providerSelected: false,
			categorySelected: false,
			filter: false,
			search: '',
			loading: false,
			recentGames: [],
			favoriteGames: []
		},
		Log: {
			data: false,
			type: parseInt(localStorage.getItem('log.type')) || 2,
			currentPage: 0,
			perPage: 0,
			total: 0,
			process: true,
			order: 'none'
		},
		Loyalty: {
			module: parseInt(localStorage.getItem('loyalty.module')) || 1,
			process: false,
			stat: false,
			balance: 0,
			log: {
				process: false,
				cancelClicked: false,
				data: false,
				currentPage: 0,
				perPage: 0,
				total: 0,
				open: {},
				order: 'none'
			}
		},
		MobileNav: {
			open: false
		},
		RightBar: {
			open: false,
			module: parseInt(localStorage.getItem('rightbar.module')) || 1
		},
		Partner: {
			module: parseInt(localStorage.getItem('partner.module')) || 1,
			balance: 0,
			open: false,
			process: false,
			stat: false,
			currentStat: false,
			campgains: {
				list: [],
				createOpen: false,
				process: false,
				delete: false,
				edit: false
			},
			usersList: {
				process: false,
				cancelClicked: false,
				data: false,
				currentPage: 0,
				perPage: 0,
				total: 0,
				open: {},
				order: 'none'
			},
			log: {
				process: false,
				cancelClicked: false,
				data: false,
				currentPage: 0,
				perPage: 0,
				total: 0,
				open: {},
				order: 'none'
			}
		},
		Promo: {
			vk: {
				account: null,
				group: false,
				like: false,
				repost: false,
				notifications: false
			},
			wheel: {
				available: false,
				timer: false,
				rotate: 0,
				transition: 10
			},
			repost: {
				gained: false
			},
			group: {
				gained: false
			},
			subscribe: {
				gained: false
			},
			captchaLoading: false,
			captchaModule: false,
			infoRefreshing: false,
			infoLoaded: 0
		},
		Profile: {
			process: false,
			stat: false
		},
		Pwa: {
			open: false,
			showed: parseInt(localStorage.getItem('notify.pwa')) || 0,
		},
		Settings: {
			changeName: false,
			regData: [],
			module: parseInt(localStorage.getItem('settings.module')) || 1,
			securityProcess: false,
			mailProcess: false,
			verifyFormFilled: false,
			verify: false,
			verifyProcess: false,
			open: false,
			tfa: false
		},
		Wallet: {
			module: parseInt(localStorage.getItem('wallet.module')) || 1,
			log: {
				data: false,
				type: parseInt(localStorage.getItem('wallet.log.type')) || 4,
				currentPage: 0,
				perPage: 0,
				total: 0,
				process: true,
				cancelClicked: false,
				open: {}
			},
			deposit: {
				open: false,
				amountButton: config.wallet.deposit.defaultValue,
				currentMethod: false,
				url: false
			},
			withdraw: {
				open: false,
				templates: false,
				templatesData: [],
				currentTemplate: false,
				currentMethod: false
			},
			prevLocation: false,
			openInModal: false,
			process: false,
			payWays: [],
			payWaysCrypto: [],
			payWaysMobile: []
		}
	};

	constructor(props) {
		super(props);
		this.socket = Socket(config.socketApiServlet.url, { path: config.socketApiServlet.path, transports: ['websocket'] });
		this.bstyles = bstyles;
		this.styles = styles;
		this.config = config;
		this.icons = icons;
		this.logo = logo;
		this.logoMobile = logoMobile;
		this.Interface = Interface;
		this.cloneDeep = cloneDeep;
		this.pingInterval = false;
	}

	componentDidMount() {
		document.getElementById('app').className = styles['app'];

		this.setState({ width: Dimensions.get('window').width, height: Dimensions.get('window').height });
		Dimensions.addEventListener('change', this.updateDimensions);

		Interface.handleSocketAPI(this);
		Interface.Ping(this);
		this.pingInterval = setInterval(() => { Interface.Ping(this) }, config.timers.Index.ping * 1000);
		this.targetElement = document.querySelector('body');
		disableBodyScroll(this.targetElement);

		if (window.matchMedia('(display-mode: standalone)').matches)
			document.querySelector('body').classList.add(styles['pwaPage']);
	}

	updateDimensions = (e) => {
		const { width, height } = e.window;
		this.setState({ width, height });
	}

	componentWillUnmount() {
		Dimensions.removeEventListener('change');
		this.socket.close();
		clearInterval(this.pingInterval);
		clearAllBodyScrollLocks();
	}

	componentDidUpdate(prevProps) {

		if (document.getElementById("scrollArea") && this.props.location !== prevProps.location)
			document.getElementById("scrollArea").scrollTo(0, 0)
	}

	updateState(t, key, value, key2, key3, key4, callback) {
		if (typeof key2 === "undefined" && typeof key3 === "undefined" && typeof key4 === "undefined")
			t.state[key] = value;
		else if (typeof key3 === "undefined" && typeof key4 === "undefined")
			t.state[key][key2] = value;
		else if (typeof key4 === "undefined")
			t.state[key][key2][key3] = value;
		else
			t.state[key][key2][key3][key4] = value;

		t.setState(t.state, typeof callback !== "undefined" ? callback : function () { });
	}

	updateStateOBJ(t, key, value, key2, key3, key4, callback) {

		if (typeof key2 === "undefined" && typeof key3 === "undefined" && typeof key4 === "undefined")
			t.state[key] = JSON.parse(JSON.stringify(value));
		else if (typeof key3 === "undefined" && typeof key4 === "undefined")
			t.state[key][key2] = JSON.parse(JSON.stringify(value));
		else if (typeof key4 === "undefined")
			t.state[key][key2][key3] = JSON.parse(JSON.stringify(value));
		else
			t.state[key][key2][key3][key4] = JSON.parse(JSON.stringify(value));

		t.setState(t.state, typeof callback !== "undefined" ? callback : function () { });
	}

	doRoute = (href) => {
		this.props.history.push(href);
		return true;
	}


	getUserLang() {
		let language = window.navigator.userLanguage || window.navigator.language;
		language = language.split('-')[0].toLowerCase();

		if (Interface.inArray(language, ['ru', 'en', 'uk', 'de', 'es', 'pt', 'pl', 'ja', 'zh', 'tr', 'fr', 'vi', 'ko', 'id', 'hi', 'fl'])) {
			console.log("Found user language: " + language);
			return language;
		} else {
			console.log("User language not found as available: " + language);
			return false;
		}
	}

	render() {
		return (
			<>
				{!this.state.connected ? <span className={[styles['loader'], styles['loaderFull'], styles['loaderCentred']].join(' ')}><img src={Loader} className={styles['rotating']} /></span> : <></>}

				<Switch>
					<Route exact path="/static/gameloader">
						<GameLoader t={this} />
					</Route>
					<>
						<div className={styles.content}>
							{this.state.width > 900 ?
								<Header t={this} /> : <></>
							}
							<Switch>
								<Route exact path="/">
									<Content page={Landing} wrapper="landing" t={this} />
								</Route>
								<Route path="/aml">
									<Content page={Aml} t={this} />
								</Route>
								<Route path="/go/:link">
									<Content page={Go} t={this} />
								</Route>
								<Route path="/auth/:type">
									<Content page={Auth} t={this} />
								</Route>
								<Route path="/confirm/:token">
									<Content page={Confirm} t={this} />
								</Route>
								<Route path="/fair">
									<Content page={Fair} t={this} />
								</Route>
								<Route path="/help">
									<Content page={Help} t={this} />
								</Route>
								<Route path="/lobby/:provider/:category">
									<Content page={Lobby} t={this} />
								</Route>
								<Route path="/lobby">
									<Content page={Lobby} t={this} />
								</Route>
								<Route path="/loyalty">
									<Content page={Loyalty} t={this} />
								</Route>
								<Route path="/partner">
									<Content page={Partner} t={this} />
								</Route>
								<Route path="/promo">
									<Content page={Promo} t={this} />
								</Route>
								<Route path="/recovery/:token">
									<Content page={Recovery} t={this} />
								</Route>
								<Route path="/responsibility">
									<Content page={Responsibility} t={this} />
								</Route>
								<Route path="/settings">
									<Content page={Settings} t={this} />
								</Route>
								<Route path="/privacy">
									<Content page={Privacy} t={this} />
								</Route>
								<Route path="/profile">
									<Content page={Profile} t={this} />
								</Route>
								<Route path="/promotions">
									<Content page={Promotions} t={this} />
								</Route>
								<Route path="/tos">
									<Content page={Tos} t={this} />
								</Route>
								<Route path="/wallet">
									<Content page={Wallet} t={this} />
								</Route>
								<Route path="/game/:id/:mode">
									<Content page={Game} wrapper="game" t={this} />
								</Route>
								<Route path="/pwa">
									<Pwa t={this} />
								</Route>
								<Redirect from="/*" to="/" />
							</Switch>

							<Modal t={this} />
							<ModalSecond t={this} />
							<Noty t={this} />
						</div>
					</>
				</Switch>
			</>
		)
	}
}

export default withRouter(Index);