import React from 'react';
import IconStatic from '../components/IconStatic';

import { isIOS } from "react-device-detect";

export default class Pwa extends React.Component {
    constructor(props) {
        super(props);
        this.type = typeof document.location.pathname.split('/')[2] !== "undefined" ? document.location.pathname.split('/')[2] : false;
        switch (this.type) {
            case 'vk':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1] : false;
                break;
            case 'fb':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1] : false;
                break;
            case 'ok':
                this.token = typeof document.location.href.split('#')[1] !== "undefined" && typeof document.location.href.split('#')[1].split('=')[1] !== "undefined" ? document.location.href.split('#')[1].split('=')[1].split('&')[0] : false;
                break;
            case 'gl':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1].split('&')[0] : false;
                break;
            default:
                this.token = false;
                break;
        }

        if (this.type && this.props.t.state.userID)
            this.props.t.updateState(this.props.t, 'auth', true, 'link');
    }

    componentDidMount() {
        if (this.props.t.state.userID && this.token && this.type)
            this.props.t.Interface.doSocialNetworkLink(this.props.t, this.type, this.token);
        else if (this.token && this.type)
            this.props.t.Interface.doSocialNetworkLogin(this.props.t, this.type, this.token);

        this.pState = this.props.t.cloneDeep(this.props.t.state);

        document.title = 'Mobile app - ' + this.props.t.config.name;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.userID, this.props.t.state.mainRedirect, this.props.t.state.auth, this.props.t.state.lang], [this.pState.userID, this.pState.mainRedirect, this.pState.auth, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['pwaContainer']}>
                    <div className={this.props.t.styles['caption']}>
                        <div className={this.props.t.styles['text']}>
                            <IconStatic t={this.props.t} name={isIOS ? "apple" : "android"} />{this.props.t.config.name} {isIOS ? 'IOS' : 'Android'}
                        </div>
                        <button onClick={() => {this.props.t.doRoute('/')}} className={this.props.t.styles['btnLeft']} type="button"><IconStatic t={this.props.t} name="left" />{this.props.t.Interface.getText(this.props.t, 'pwa', 'back')}</button>
                    </div>
                    <div className={this.props.t.styles['item']}>
                        <div className={this.props.t.styles['head']}>
                            <div className={this.props.t.styles['number']}>
                                1
			</div>{isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep1') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep1')}
                        </div>
                        <div className={this.props.t.styles['bottom']}>
                            {isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep1Description') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep1Description')}
                        </div>
                    </div>
                    <div className={this.props.t.styles['item']}>
                        <div className={this.props.t.styles['head']}>
                            <div className={this.props.t.styles['number']}>
                                2
			</div>{isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep2') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep2')}
                            <div className={this.props.t.styles['iconIos']}>
                                <IconStatic t={this.props.t} name={isIOS ? "share" : "dots"} />
                            </div>
                        </div>
                        <div className={this.props.t.styles['bottom']}>
                            {isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep2Description') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep2Description')}
                        </div>
                    </div>
                    <div className={this.props.t.styles['item']}>
                        <div className={this.props.t.styles['head']}>
                            <div className={this.props.t.styles['number']}>
                                3
			</div>{isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep3') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep3')}
                            {isIOS && <div className={this.props.t.styles['iconIos']}>
                                <IconStatic t={this.props.t} name="add" />
                            </div>}
                        </div>
                        <div className={this.props.t.styles['bottom']}>
                            {isIOS ? this.props.t.Interface.getText(this.props.t, 'pwa', 'iosStep3Description') : this.props.t.Interface.getText(this.props.t, 'pwa', 'androidStep3Description')}
                        </div>
                    </div>
                    {!isIOS && <div className={this.props.t.styles['alert']}>
                        {this.props.t.Interface.getText(this.props.t, 'pwa', 'alert')}
	                </div>}
                </div>
            </>
        )
    }
}