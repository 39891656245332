import React from 'react';
import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import Avatar from 'react-avatar';
import { Tooltip } from 'reactstrap';

import General from '../modules/SettingsGeneral';
import Security from '../modules/SettingsSecurity';
import Sessions from '../modules/SettingsSessions';
import Verification from '../modules/SettingsVerification';

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.firstEdit = true;
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        if (!this.props.t.state.userID)
            this.props.t.doRoute('/');

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'settings') + ' - ' + this.props.t.config.name;
    }

    shouldComponentUpdate() {
        let needUpdate = false, needCoreUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.userData, this.props.t.state.Settings.open, this.props.t.state.Settings.changeName, parseInt(this.props.t.state.Settings.module), this.props.t.state.width], [this.pState.userData, this.pState.Settings.open, this.pState.Settings.changeName, parseInt(this.pState.Settings.module), this.pState.width]);

        switch (parseInt(this.pState.Settings.module)) {
            case 1:
                needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Settings.mailProcess, this.props.t.state.form.settings.name, this.props.t.state.form.settings.email, this.props.t.state.lang], [this.pState.Settings.mailProcess, this.pState.form.settings.name, this.pState.form.settings.email, this.pState.lang]);
                break;
            case 2:
                needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.inputs.settings, this.props.t.state.form.settings, this.props.t.state.Settings.securityProcess, this.props.t.state.lang], [this.pState.inputs.settings, this.pState.form.settings, this.pState.Settings.securityProcess, this.pState.lang]);
                break;
            case 3:
                needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Settings.regData, this.props.t.state.Settings.securityProcess, this.props.t.state.lang], [this.pState.Settings.regData, this.pState.Settings.securityProcess, this.pState.lang]);
                break;
            case 4:
                needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.inputs.settings, this.props.t.state.Settings.verifyProcess, this.props.t.state.Settings.verifyFormFilled, this.props.t.state.Settings.verify, this.props.t.state.form.settings, this.props.t.state.lang], [this.pState.inputs.settings, this.pState.Settings.verifyProcess, this.pState.Settings.verifyFormFilled, this.pState.Settings.verify, this.pState.form.settings, this.pState.lang]);
                break;
        }

        if (needUpdate || needCoreUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || needCoreUpdate;
    }

    changeNameCheck() {
        if (this.firstEdit && !this.props.t.state.form.settings.name.data) {
            this.props.t.updateState(this.props.t, 'form', this.props.t.state.userData.settings.name, 'settings', 'name', 'data');
            this.firstEdit = false;
        }

        return true;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['settingsContainer']}>
                        <div className={this.props.t.styles['sidebar']}>
                            <div className={this.props.t.styles['head']}>
                                <div>
                                    <div className={this.props.t.styles['avatarBlock']}>
                                        <Avatar round="20px" className={this.props.t.styles['avatar']} name={!this.props.t.state.userID ? 'Guest' : this.props.t.state.userData ? this.props.t.state.userData.settings.name : '#' + this.props.t.state.userID} color={Avatar.getRandomColor(!this.props.t.state.userID ? 0 : this.props.t.state.userID.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} />
                                    </div>
                                </div>
                                <div className={[this.props.t.styles['name'], this.props.t.state.Settings.changeName ? this.props.t.styles['active'] : ''].join(' ')}>
                                    <button onClick={() => this.props.t.updateState(this.props.t, "Settings", !this.props.t.state.Settings.changeName, "changeName")} className={this.props.t.styles['btnName']} type="button">{this.props.t.state.userData && this.props.t.state.userData.settings.name ? this.props.t.state.userData.settings.name : this.props.t.state.userData ? this.props.t.state.userData.login : '...'} <IconStatic t={this.props.t} name='edit' /></button>
                                    {this.props.t.state.Settings.changeName && this.changeNameCheck() &&
                                        <div className={this.props.t.styles['change']}>
                                            <input className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.name.error ? this.props.t.styles['hasError'] : ''].join(' ')} onKeyPress={(event) => event.key === "Enter" && !this.props.t.state.form.settings.name.error && (this.props.t.Interface.SettingsChangeName(this.props.t), this.firstEdit = true, this.props.t.updateState(this.props.t, "Settings", !this.props.t.state.Settings.changeName, "changeName"), this.blockInputBloor = false)} id="inputName" onBlur={() => { !this.blockInputBloor && this.props.t.updateState(this.props.t, "Settings", !this.props.t.state.Settings.changeName, "changeName"); !this.blockInputBloor && this.props.t.updateState(this.props.t, 'form', { data: this.props.t.state.userData.settings.name, error: false }, 'settings', 'name'); }} autoFocus autocomplete="off" name="username" type="text" onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.name.error, }, "settings", "name"); this.props.t.Interface.checkForm(this.props.t, "settings", "name"); }} value={this.props.t.state.form.settings.name.data}></input>
                                            <Tooltip isOpen={this.props.t.state.Settings.changeName && this.props.t.state.form.settings.name.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputName">
                                                {this.props.t.state.form.settings.name.error}
                                            </Tooltip>
                                            <button onMouseEnter={() => { this.blockInputBloor = true }} onMouseLeave={() => { this.blockInputBloor = false }} onClick={() => !this.props.t.state.form.settings.name.error && (this.props.t.Interface.SettingsChangeName(this.props.t), this.firstEdit = true, this.props.t.updateState(this.props.t, "Settings", !this.props.t.state.Settings.changeName, "changeName"), this.blockInputBloor = false)} className={this.props.t.styles['submit']}> <IconStatic t={this.props.t} name='success' /></button>
                                        </div>}
                                </div>
                            </div>
                            <div className={this.props.t.state.width <= 1050 ? this.props.t.styles['settingsMobileNav'] : ''}>
                                <div className={this.props.t.styles['navLabel']}>
                                    {this.props.t.Interface.getText(this.props.t, 'menu', 'settings')}
                                </div>
                                {this.props.t.state.width > 1050 ?
                                    <>
                                        <a onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 1, 'module'), localStorage.setItem('settings.module', 1))} className={[this.props.t.styles['navItem'], this.props.t.state.Settings.module === 1 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='person' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'general')}</a><a onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 2, 'module'), localStorage.setItem('settings.module', 2))} className={[this.props.t.styles['navItem'], this.props.t.state.Settings.module === 2 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='fairness' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'security')}</a><a onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 3, 'module'), localStorage.setItem('settings.module', 3))} className={[this.props.t.styles['navItem'], this.props.t.state.Settings.module === 3 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='network' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'sessions')}</a><a onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 4, 'module'), localStorage.setItem('settings.module', 4))} className={[this.props.t.styles['navItem'], this.props.t.state.Settings.module === 4 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='shield' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'verification')}</a>
                                    </> :
                                    <div onBlur={() => !this.blockDropdownBloor && this.props.t.updateState(this.props.t, 'Settings', false, 'open')} className={this.props.t.styles['dropdown']}>
                                        <button onClick={() => this.props.t.updateState(this.props.t, 'Settings', !this.props.t.state.Settings.open, 'open')} className={[this.props.t.styles['btnDropdown'], this.props.t.state.Settings.open ? this.props.t.styles['open'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['btnContent']}>
                                                <div className={this.props.t.styles['navIcon']}>
                                                    <Icon t={this.props.t} name={this.props.t.state.Settings.module === 1 ? 'person' : this.props.t.state.Settings.module === 2 ? 'fairness' : this.props.t.state.Settings.module === 3 ? 'network' : this.props.t.state.Settings.module === 4 ? 'shield' : 'settings'} />
                                                </div>{this.props.t.state.Settings.module === 1 ? this.props.t.Interface.getText(this.props.t, 'settings', 'general') : this.props.t.state.Settings.module === 2 ? this.props.t.Interface.getText(this.props.t, 'settings', 'security') : this.props.t.state.Settings.module === 3 ? this.props.t.Interface.getText(this.props.t, 'settings', 'sessions') : this.props.t.Interface.getText(this.props.t, 'settings', 'verification')}
                                                <div className={[this.props.t.styles['opener'], this.props.t.state.Settings.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <IconStatic t={this.props.t} name='down' />
                                                </div>
                                            </div></button>
                                        <div onMouseEnter={() => { this.blockDropdownBloor = true }} onMouseLeave={() => { this.blockDropdownBloor = false }} className={[this.props.t.styles['menu'], this.props.t.state.Settings.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 1, 'module'), localStorage.setItem('settings.module', 1), this.props.t.updateState(this.props.t, 'Settings', !this.props.t.state.Settings.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'settings', 'general')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 2, 'module'), localStorage.setItem('settings.module', 2), this.props.t.updateState(this.props.t, 'Settings', !this.props.t.state.Settings.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'settings', 'security')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 3, 'module'), localStorage.setItem('settings.module', 3), this.props.t.updateState(this.props.t, 'Settings', !this.props.t.state.Settings.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'settings', 'sessions')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Settings', 4, 'module'), localStorage.setItem('settings.module', 4), this.props.t.updateState(this.props.t, 'Settings', !this.props.t.state.Settings.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'settings', 'verification')}</button>
                                        </div>
                                    </div>
                                }
                            </div >
                        </div >
                        <div className={this.props.t.styles['settingsContent']}>
                            {this.props.t.state.Settings.module === 1 ? <General t={this.props.t} /> : this.props.t.state.Settings.module === 2 ? <Security t={this.props.t} /> : this.props.t.state.Settings.module === 3 ? <Sessions t={this.props.t} /> : this.props.t.state.Settings.module === 4 ? <Verification t={this.props.t} /> : <></>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}