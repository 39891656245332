import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import WithAutoplay from 'react-awesome-slider/dist/autoplay';

import Svg from '../components/Svg';
import Revenue from '../assets/svg/Landing/revenue.svg';
import BigPercents from '../assets/svg/Landing/bigPercents.svg';
import Viking from '../assets/png/Landing/viking.png';
import Money from '../assets/png/Landing/money.png';
import Bomber from '../assets/png/Landing/bomber.png';
import Confetti from '../assets/png/Landing/confetti.png';
import Bars from '../assets/png/Landing/bars.png';

const Slider = WithAutoplay(AwesomeSlider);

export default class LandingSlider extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.pState = this.props.t.cloneDeep(this.props.t.state);
  }

  shouldComponentUpdate() {
    let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width, this.props.t.state.lang], [this.pState.width, this.pState.lang]);

    if (needUpdate)
      this.pState = this.props.t.cloneDeep(this.props.t.state);

    return needUpdate;
  }

  render() {
    return (<div className={[this.props.t.styles['section'], this.props.t.styles['landingSliderSection']].join(' ')}>
      <Slider
        play={true}
        cancelOnInteraction={true}
        interval={this.props.t.config.sliderTimeout * 1000}
        cssModule={this.props.t.styles}
        organicArrows={false}
      >
        <div className={this.props.t.styles['sliderItem']} style={{ backgroundColor: '#057bfb', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'right center', backgroundImage: `url(${Confetti})` }}>
          <div className={[this.props.t.styles['img'], this.props.t.styles['bonus']].join(' ')}>
            <img src={Money}></img>
          </div>
          <div className={this.props.t.styles['sliderContent']}>
            <span className={this.props.t.styles['title']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'permanentBonus')}</span>
            <span className={this.props.t.styles['description']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'tenPercent')}</span>
            <button onClick={() => this.props.t.doRoute('/promotions')} className={[this.props.t.styles['btn'], this.props.t.styles['blue']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'bonus', 'more')}</button>
            <div className={this.props.t.styles['bonusesList']}>
              <div className={this.props.t.styles['item']}>
                <img src={Revenue}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'fastRegistration')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'noVerification')}.</p>
                </div>
              </div>
              <div className={this.props.t.styles['item']}>
                <img src={BigPercents}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'fastWithdraws')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'highLimits')}.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className={this.props.t.styles['sliderItem']} style={{ backgroundColor: '#fb4f05', backgroundSize: this.props.t.state.width >= 600 ? 'contain' : 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'right center', backgroundImage: `url(${Bars})` }}>
          <div className={[this.props.t.styles['img'], this.props.t.styles['bonus']].join(' ')}>
            <img src={Bomber}></img>
          </div>
          <div className={this.props.t.styles['sliderContent']}>
            <span className={this.props.t.styles['title']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'loyaltyProgramm')}</span>
            <span className={this.props.t.styles['description']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'winCashback')}</span>
            <button onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/loyalty') : this.props.t.Interface.requireAuth(this.props.t, false, '/loyalty')} className={[this.props.t.styles['btn'], this.props.t.styles['orange']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'bonus', 'more')}</button>
            <div className={this.props.t.styles['bonusesList']}>
              <div className={this.props.t.styles['item']}>
                <img src={Revenue}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'levelSystem')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'bigPercents')}.</p>
                </div>
              </div>
              <div className={this.props.t.styles['item']}>
                <img alt="tech" src={BigPercents}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'payForWin')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'butNoBets')}.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className={this.props.t.styles['sliderItem']} style={{ backgroundColor: '#5600cb' }}>
          <Svg style={{ transform: 'rotate(50deg) scale(2)', right: '-20px', top: '95%' }} t={this.props.t} name={"landing-circle-1"} />
          <div className={[this.props.t.styles['img'], this.props.t.styles['partner']].join(' ')}>
            <img src={Viking}></img>
          </div>
          <div className={this.props.t.styles['sliderContent']}>
            <span className={this.props.t.styles['title']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'affiliateProgramm')}</span>
            <span className={this.props.t.styles['description']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'join')}!</span>
            <button onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/partner') : this.props.t.Interface.requireAuth(this.props.t, false, '/partner')} className={[this.props.t.styles['btn'], this.props.t.styles['purple']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'bonus', 'more')}</button>
            <div className={this.props.t.styles['bonusesList']}>
              <div className={this.props.t.styles['item']}>
                <img alt="tech" src={Revenue}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'revenueShare')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'anytimeWithdraw')}.</p>
                </div>
              </div>
              <div className={this.props.t.styles['item']}>
                <img alt="tech" src={BigPercents}></img>
                <div className={this.props.t.styles['features']}>
                  <div>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'affiliateCabinet')},
											</div>
                  <p>{this.props.t.Interface.getText(this.props.t, 'bonus', 'detailStat')}.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider >
    </div>)
  }
}