import React from 'react';
import { Spinner } from 'reactstrap';
import { Redirect } from "react-router-dom";

import PasswordRecoverySetModal from '../modules/modals/PasswordRecoverySet';

export default class Recovery extends React.Component {
    constructor(props) {
        super(props);
        this.token = typeof document.location.pathname.split('/')[2] !== "undefined" ? document.location.pathname.split('/')[2] : false;

        this.props.t.updateState(this.props.t, 'recovery', this.token, 'token');
    }

    componentDidMount() {
        if (this.token)
            this.props.t.updateState(this.props.t, 'modal', { show: true, content: PasswordRecoverySetModal, className: this.props.t.styles['recoveryModal'] });

        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.recovery.token, this.props.t.state.mainRedirect, this.props.t.state.recovery, this.props.t.state.lang], [this.pState.recovery.token, this.pState.mainRedirect, this.pState.recovery, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                {this.props.t.updateState(this.props.t, 'mainRedirect', false)}
                <Redirect from="/*" to="/" />
                <div className={this.props.t.styles['recoverySection']}>
                    <div className={this.props.t.styles['block']}>
                        <span className={this.props.t.styles['loader']}>
                            <Spinner cssModule={this.props.t.bstyles} style={{
                                width: '5rem',
                                height: '5rem',
                                color: '#4986f5'
                            }} />
                        </span>
                        <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'auth', 'pleaseWait')}</div>
                    </div>
                </div>
            </>
        )
    }
}