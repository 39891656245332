module.exports = {
    system: { code: 'de', name: 'Deutsch' },
    aml: {
        title: 'Geldwäsche entgegenwirken',
        description: 'Sicherheits-Steuerung',
        content: ''
    },
    auth: {
        accountCreated: 'Konto registriert',
        agreeText: 'Ich bestätige, dass ich 18 Jahre alt bin und gelesen habe',
        agreeTos: 'nutzungsbedingungen',
        alreadyRegistered: 'Schon registriert?',
        byLogin: 'Nach Login und Passwort',
        bySocialNetworks: 'Über soziale Netzwerke',
        createAccount: 'Erstellen Sie ein Konto',
        forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
        loading: 'Wird geladen...',
        login: 'Einloggen',
        loginTitle: 'Site-Autorisierung',
        maxLoginLength: 'Maximale Login-Länge:',
        maxPasswordLength: 'Maximale Passwortlänge:',
        minLoginLength: 'Minimale Login-Länge:',
        minPasswordLength: 'Länge des Minmal Passworts:',
        passwordConfirmation: 'Passwort wiederholen',
        passwordsMustBeSame: 'Passwörter müssen übereinstimmen',
        pleaseWait: 'Bitte warte...',
        registration: 'Anmeldung',
        registrationTitle: 'Registrieren Sie sich vor Ort',
        symbols: 'zeichen',
        yourLogin: 'Ihr Login',
        yourPassword: 'Dein Passwort',
        yourEmail: 'Deine E-Mail',
        logoutConfirm: 'Willst du wirklich raus?',
        passwordRecovery: 'Passwort zurücksetzen',
        newPassword: 'Neues Passwort',
        copied: 'Kopiert',
        logout: 'Out'
    },
    basic: {
        yes: 'Ja',
        no: 'Nee',
        confirm: 'Bestätigen',
        confirmed: 'Upvoted',
        cancel: 'Aufheben',
        sending: 'Sende...',
        sent: 'Einreichen',
        save: 'Sparen',
        create: 'Erstellen',
        or: 'oder',
        activate: 'Aktivieren',
        deactivate: 'Deaktivieren',
        prevPage: 'Vorherige Seite',
        nextPage: 'Nächste Seite',
        of: 'aus',
        balance: 'Guthaben',
        copy: 'Kopieren',
        copied: 'Kopiert'
    },
    bonus: {
        permanentBonus: 'Permanenter Bonus',
        tenPercent: '10% für jede Einzahlung!',
        more: 'Lesen Sie mehr',
        fastRegistration: 'Schneller Check-in',
        noVerification: 'ohne Überprüfung',
        fastWithdraws: 'Schnelle Auszahlungen',
        highLimits: 'hohe Grenzwerte',
        loyaltyProgramm: 'Treueprogramm',
        winCashback: 'Bis zu 1,2% bei jedem Sieg!',
        levelSystem: 'Level-System',
        bigPercents: 'Große Prozentsätze',
        payForWin: 'Zahlen Sie für den Gewinn',
        butNoBets: 'nicht für Wetten',
        affiliateProgramm: 'Affiliate-Programm',
        join: 'Beitreten',
        revenueShare: '40% Umsatzbeteiligung',
        anytimeWithdraw: 'jederzeit ausgeben',
        affiliateCabinet: 'Affiliate-Kabinett',
        detailStat: 'detaillierte Statistiken',
        title: 'Promo-code',
        promoEveryDay: 'Promo-codes jeden Tag in unserer VK gruppe',
        enterPromoCode: 'Promo-code eingeben'
    },
    fair: {
        title: 'Lizenz & Ehrlichkeitsgarantie',
        description: 'Wir garantieren Fairplay für jedermann',
        fairDescription1: 'Brillx Casino weiß, wie wichtig es ist, eine Sicherheitsumgebung zu schaffen, damit die Benutzer auf die Ehrlichkeit unserer Spiele vertrauen.',
        fairDescription2: 'Daher verwenden wir in unserem Casino Spiele von Top-Unternehmen, die Software für Online-Casinos entwickeln. Unter ihnen Unternehmen wie',
        fairDescription3: 'Als Gründungsmitglied von eCOGRA und IGC ist Microgaming anderen Casino-Gaming-Herstellern einen Vorsprung voraus und setzt die höchsten Standards in der Glücksspielbranche als Ganzes, insbesondere für die Ehrlichkeit des Online-Spiels, die Auswahl der verantwortungsvollsten Betreiber und den Schutz der Interessen der Spieler.',
        andOther: 'und andere',
        also: 'Wir bieten auch Spiele an, die vom Unternehmen erstellt wurden',
        defaultCasino: 'Gewöhnliches Online-Casino',
        brillxCasino: 'Brillx Ehrlichkeitskontrollkasino',
        defaultCasinoDescription1: 'Sie machen eine Wette, die auf den Casino-Server geschrieben wird',
        defaultCasinoDescription2: 'Ihre Daten werden auf Casino-Servern gesammelt und gespeichert',
        defaultCasinoDescription3: 'Es ist unmöglich, die Ehrlichkeit des Spiels zu überprüfen - alle Algorithmen werden auf Casinoseite aufgezeichnet und gespeichert',
        defaultCasinoDescription4: 'Um einen Gewinn zu erzielen, müssen Sie ein langwieriges Autorisierungsverfahren durchlaufen',
        defaultCasinoDescription5: 'Die Zahlungsfähigkeit des Casinos in Frage, da sein Guthaben nirgends veröffentlicht wird',
        brillxCasinoDescription1: 'Eine Kopie der Daten von Spieler-Wettanbietern wird in der Blockchain gespeichert und kann nicht geändert werden',
        brillxCasinoDescription2: 'Spielstatistiken für Menschen werden verschlüsselt und in Blockchain aufgezeichnet, die nicht an persönliche Daten des Spielers gebunden sind',
        brillxCasinoDescription3: 'Die Integritätskontrolle erfolgt mit der Blockchain-Technologie',
        brillxCasinoDescription4: 'Auszahlungen erfordern eine KYC-Standardautorisierung, keine Ablösesumme',
        brillxCasinoDescription5: 'Offene Berichterstattung und riesiger Pool für Spieler-Auszahlungen'
    },
    game: {
        loading: 'Wird geladen',
        lobby: 'Lobby',
        fullScreen: 'Vollbildmodus',
        deposit: 'Anzahlung',
        betsAmount: 'Wettbetrag',
        totalRTP: 'Gesamt-RTP',
        averageBet: 'Durchschnittswette',
        topWin: 'Top-Gewinn'
    },
    help: {
        title: 'Hilfe & Support',
        description: 'Antworten auf die häufigsten Fragen und Kommunikation mit dem Kundendienst.',
        connectTypes: 'Methoden zur Kommunikation mit uns',
        operatorsOnline: 'Online-Betreiber 24 Stunden am Tag',
        supportLive: 'Unterstützung (Live)',
        onlineChat: 'Live-Chat',
        averageTimeChat: 'Durchschnittliche Reaktionszeit: 10 Minuten',
        supportEmail: 'Kundendienst (E-Mail)',
        averageTimeEmail: 'Durchschnittliche Reaktionszeit: 1 Tag',
        generalQuestions: 'Allgemeine Probleme',
        aboutSite: 'Über die Seite',
        aboutSiteContent: 'Brillx ist ein Online-Casino mit internationalen Lizenzen und Namen. Wir bieten mehr als 6.000 Spiele im Katalog, eine große Auswahl an Sportveranstaltungen und viele Minispiele. Wir unterstützen viele Zahlungssysteme und die meisten gängigen Kryptowährungen.',
        bonus: 'Bonus',
        bonuses: 'Boni',
        onPage: 'Auf der Seite',
        manyFreeBonuses: 'es gibt mehrere Möglichkeiten, Geld für Ihr Guthaben absolut kostenlos zu bekommen!',
        bonus1: 'Anmeldebonus. Nach der Registrierung auf der Website haben Sie 60 Minuten Zeit, um den Einladungscode einzugeben und bis zu 10.00 RUB zu erhalten.',
        bonus2: 'Anmeldebonus. Nach der Registrierung auf der Website haben Sie 60 Minuten Zeit, um den Einladungscode einzugeben und bis zu 10.00 RUB zu erhalten.',
        bonus3: 'Anmeldebonus. Nach der Registrierung auf der Website haben Sie 60 Minuten Zeit, um den Einladungscode einzugeben und bis zu 10.00 RUB zu erhalten.',
        bonus4: 'Anmeldebonus. Nach der Registrierung auf der Website haben Sie 60 Minuten Zeit, um den Einladungscode einzugeben und bis zu 10.00 RUB zu erhalten.',
        promocodes: 'Aktionscodes',
        inviteFriend: 'Lade einen Freund ein',
        balanceRefilling: 'Wiederauffüllung des Saldos',
        wallet: 'Geldbörse',
        howToRefill: 'Wie führe ich das Guthaben auf?',
        commissions: 'Kommission',
        periodOfRefilling: 'Bedingungen der Einschreibung',
        withdrawal: 'Auszahlungen',
        howToWithdraw: 'Wie ziehe ich Geld ab?',
        periodOfWithdrawal: 'Zeitplan für Auszahlungen',
        clickToRefill: 'Klicken Sie auf den Button, um das Guthaben wieder aufzufüllen',
        atRightCorner: 'in der oberen rechten Ecke der Website. Wählen Sie eine für Sie geeignete Zahlungsmethode aus, geben Sie den Einzahlungsbetrag ein und klicken Sie auf Gehe zu Zahlung.',
        minimalRefilling: 'Minimale Nachschub',
        commissionsOnPage: 'Provisionen aller Zahlungsmethoden sind auf der Seite aufgeführt',
        clickToWithdraw: 'Klicken Sie auf die Schaltfläche, um Geld von Ihrem Konto abzuheben',
        averageTimeWithdraw: 'Alle Befunde werden in durchschnittlich einer Stunde ab dem Zeitpunkt der Bewerbung verarbeitet.',
        allTransactions: 'Alle Transaktionen werden sofort verarbeitet. Verzögerungen können nur auftreten, wenn Sie Probleme mit der Zahlungsmethode haben, mit der Sie Ihr Guthaben auffüllen.',
        inCaseOfProblems: 'Bei Problemen wenden Sie sich bitte an unseren Kundenservice und geben Sie die detailliertesten Informationen zu Ihrem Problem an.',
        atRightCornerWithdraw: 'in der oberen rechten Ecke der Website. Wählen Sie eine für Sie geeignete Auszahlungsmethode aus, geben Sie den Betrag ein und klicken Sie auf Gehe zu Zahlung.',
        minimalWithdrawal: 'Minimale Auszahlung',
        ifBigAmount: 'Wenn der Auszahlungsbetrag groß ist, kann die Auszahlung etwas länger dauern, normalerweise bis zu einer Stunde. In der Nacht (00:00 Uhr bis 09:00 MSC) können die Ergebnisse mehrere Stunden dauern, da der Zahlungsaggregator Auszahlungsanträge länger bearbeitet.',
        sometimes: 'In sehr seltenen Fällen kann die Auszahlung aufgrund von Bankbelastungen und so weiter bis zu 24 Stunden dauern. Wenn das Geld nach dieser Zeit nicht eintrifft, wenden Sie sich bitte an unseren Kundendienst und geben Sie die detailliertesten Informationen zu Ihrem Problem an.',
        checkCorrectPurse: 'Stellen Sie außerdem sicher, dass Sie die Details für die Ausgabe korrekt angeben. Um die Wallet zu beruhigen, auf die Sie auf die Auszahlung hingewiesen haben, müssen Sie in Ihren Transaktionshistorie eingehen und Details öffnen, indem Sie auf die gewünschte Transaktion klicken.'
    },
    interface: {
        months: {
            jan: 'jan.',
            feb: 'feb.',
            mar: 'mrz.',
            apr: 'apr.',
            may: 'mai',
            jun: 'jun.',
            jul: 'jul.',
            aug: 'aug.',
            sep: 'sep.',
            oct: 'okt.',
            nov: 'nov.',
            dec: 'dez.'
        },
        year: 'j.',
        confirmPasswordRecovery: 'Bestätigen Sie das Zurücksetzen des Passworts, indem Sie dem Link folgen, der',
        passwordWasChanged: 'Passwort wurde erfolgreich geändert, Sie können sich anmelden, um neue Daten zu verwenden',
        confirmEmail: 'Bestätigen Sie die E-Mail-Adresse per Link',
        emailWasConfirmed: 'Die E-Mail-Adresse wurde erfolgreich bestätigt',
        accountLinked: 'Konto wurde erfolgreich verknüpft',
        bonusGranted: 'Bonus erhalten'
    },
    landing: {
        newGames: 'Neue Spiele',
        topGames: 'Populäre Spiele',
        brillxSelect: "Brillx's Wahl",
        daylyLiveRtp: 'Live-RTP - 24 Stunden',
        brillxCasino: 'Brillx Casino',
        casinoInfo1: 'Schauen Sie sich eine große Auswahl an aufregenden Spielen hier bei Brillx.GG an, einer Spielautomatenseite, die auf Ihre Zufriedenheit zugeschnitten ist. Tausende von erstklassigen Spielautomaten stehen Spielern auf der ganzen Welt zur Verfügung. Ob es sich um die riesigen Jackpots, Poker-Slots oder Themenspielautomaten handelt, die Sie brauchen, wir haben alles was Sie brauchen!',
        casinoInfo2: 'Erleben Sie die Leichtigkeit eines schnellen Einchecken, indem Sie jetzt auf die Schaltfläche Einchecken klicken! Genieße Momente, in denen du Echtgeld-Spielautomaten führender Spielanbieter spielst.',
        casinoInfo3: 'Unser Casino mit schnellen Auszahlungen und einer riesigen Auswahl an Spielautomaten aller Art funktioniert rund um die Uhr für Sie - wählen Sie einfach ein Spiel aus und fangen Sie an zu spielen!',
        casinoInfo4: 'Spielautomaten online spielen',
        casinoInfo5: 'Wir verderben nie deinen Spaß, also kannst du alle unsere Spiele spielen, ohne etwas auf deinen Computer herunterzuladen.',
        casinoInfo6: 'Durchsuchen Sie Tausende von Slots, wählen Sie das gewünschte Spiel aus und klicken Sie auf „SPIELEN“ - es ist so einfach!',
        casinoInfo7: 'Casino-Slot-Spiele sind eines der beliebtesten Spiele. Nicht nur wegen der enormen Anreize, die Sie bekommen können, sondern auch wegen der aufregenden Grafik, der guten Musik und des insgesamt angenehmen visuellen Effekts des Spiels.',
        casinoInfo8: 'Sie können sogar jeden unserer Spielautomaten ausprobieren, indem Sie in den Unterhaltungsmodus wechseln - eine erstaunliche Funktion, mit der Sie alle unsere Spielautomaten ohne Download und ohne Registrierung spielen können! Sie können über Ihr Mobiltelefon, Computer oder Tablet darauf zugreifen. Entdecken Sie heute einige der besten Spielautomaten, die Sie kostenlos spielen können, ohne sie herunterladen zu müssen, und erleben Sie ein einzigartiges Abenteuer in den besten Casino-Slots weltweit. Machen Sie heute Ihren besten Schuss und lassen Sie das Schicksal entscheiden, was Sie erwartet!',
        casinoInfo9: 'Worauf wartest du? Heute könnte dein Glückstag sein!',
        casinoInfo10: 'Tausende von Spielautomaten',
        casinoInfo11: 'Hier sind die verschiedenen Arten von Spielautomaten, die Sie in unserem Casino finden können:',
        casinoInfo12: '3 Walzen-Slots',
        casinoInfo13: '3 Walzen-Slots sind am besten als klassische oder traditionelle Spielautomaten bekannt. Walzen sind vertikale Linien, die Sie im Spiel finden können, in dem die Symbole kaskadiert sind. Wenn Sie auf der Suche nach einem Nervenkitzel sind, versuchen Sie, einen 3-Walzen-Spielautomaten zu spielen! Einige der Spiele, die Sie spielen können, sind Book of Alchemy, Watford FC Slot und viele andere.',
        casinoInfo14: '5 Walzen-Slots',
        casinoInfo15: "Erhalten Sie bessere Chancen, Kombinationen zu gewinnen, wenn Sie einen 5-Walzen-Slot spielen! Die meisten 5-Walzen-Slots sind jedes Mal zahlbar, wenn 3, 4 oder 5 Zeichen auf die Walzen fallen. Je nach Spiel können Sie auch Gewinnmultiplikatoren, Bonusspielrunden oder Freispiele erhalten. Einige der 5-Walzen-Spiele, die Sie spielen können, sind Vikings Go Berzerk, Legacy of Dead und That's Rich.",
        casinoInfo16: 'Fortschrittliche Spielautomaten',
        casinoInfo17: 'Progressive Slots sind eine List unter allen Arten von Spielautomaten. Die Jackpot-Größe nimmt mit fortschreitender Spielentwicklung zu. Es warten riesige Millionen auf dich, und du könntest einer der reichsten Menschen werden!',
        casinoInfo18: 'Roulette',
        casinoInfo19: 'Roulette ist ein Spiel, das auf einzelne Zahlen auf Tisch, Farbe, Zahlentyp oder Kombinationen setzt. Testen Sie Ihr Glück und gewinnen Sie, wenn das, worauf Sie wetten, aufs Rad trifft. Spiele jetzt unsere großartigen Echtzeit-Roulette Spiele!',
        casinoInfo20: 'Poker',
        casinoInfo21: 'Wenn Sie mit Poker in einem normalen Casino vertraut sind, können Sie es jetzt versuchen, es zu spielen, indem Sie unsere große Auswahl an Online-Video-Pokerspielen nutzen. Sei bereit, noch heute ein unvergessliches Spiel zu erleben! Mit hochauflösenden Animationen und großartigen speziellen Videorunden werden Sie auf jeden Fall wiederkommen, um einen neuen Sieg zu erzielen.',
        casinoInfo22: 'Baccarat',
        casinoInfo23: 'Baccarat ist eines der beliebtesten Spiele der Welt. Besiege den Dealer, indem du auf die Hand setzt, die 9 am nächsten ist. Sie können auch gewinnen, wenn es ein Unentschieden gibt. Klingt wirklich aufregend? Bereiten Sie Ihre Wetten jetzt vor und setzen Sie auf die Gewinnerseite!',
        casinoInfo24: 'Blackjack',
        casinoInfo25: 'Im Blackjack kannst du den Dealer schlagen, wenn du die Hand am 21 am nächsten hast. Holen Sie sich die Chance, Ihren Einsatz zu verdoppeln, wenn Sie unsere majestätischen Blackjack-Spielautomaten spielen!',
        gamesProviders: 'Spiele-Anbieter',
        showMore: 'Zeig mehr',
        hideInfo: 'Informationen ausblenden',
        allGames: 'Alle Spiele',
        providers: 'Anbieter',
        new: 'Neu',
        tournaments: 'Turniere',
        slots: 'Spielautomaten',
        roulette: 'Roulette',
        card: 'Karten',
        videopoker: 'Videopoker',
        lottery: 'Lotterie'
    },
    live: { demo: 'Demo', play: 'Spielen', title: 'Live' },
    lobby: {
        providers: 'Anbieter',
        recent: 'Kürzlich',
        top: 'Beliebt',
        new: 'Neu',
        favorite: 'Favoriten',
        nothingFound: 'Nichts gefunden',
        play: 'SPIELEN',
        demo: 'DEMO',
        forExample: 'Zum Beispiel'
    },
    log: { date: 'Datum', type: 'Betrieb', amount: 'Betrag', id: 'ID' },
    loyalty: {
        title: 'Treueprogramm',
        description: 'Erhalten Sie Cashback, das von der Treuestufe bei jeder platzierten Wette',
        cabinet: 'Kabinett',
        myCabinet: 'Mein Büro',
        payLog: 'Abgrenzungsprotokoll',
        log: 'Logue',
        level: 'Stufe',
        totalWins: 'Gewinnbetrag',
        totalBets: 'Betrag der Wette',
        availableBalance: 'Verfügbarer Saldo',
        get: 'Nehmen Sie weg',
        cashback: 'Cashback',
        emptyHistory: 'Die Geschichte fehlt',
        operationID: 'Betriebs-ID',
        amount: 'Betrag',
        date: 'Datum',
        operationDate: 'Datum der Operation',
        operationType: 'Betrieb',
        balanceBefore: 'Balance nach oben',
        balanceAfter: 'Balance nach'
    },
    menu: {
        affProgramm: 'Partner',
        fairGame: 'Fairplay',
        free: 'Kostenlos',
        games: 'Spiele',
        lobby: 'Lobby',
        promo: 'Promo',
        loyaltyProgramm: 'Loyalität',
        promotions: 'Werbeaktionen',
        support: 'Beratungsstelle',
        settings: 'Einstellungen',
        history: 'Spielverlauf',
        more: 'Mehr',
        profile: 'Profil',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Fehler', success: 'Erfolgreich' },
    profile: {
        wallet: 'Geldbörse',
        onSiteFrom: 'Vor Ort mit',
        totalBets: 'Betrag der Wette',
        betsCount: 'Wetten',
        totalWins: 'Gewinnbetrag',
        bestWin: 'Bester Gewinn',
        emptyHistory: 'Die Geschichte fehlt',
        balanceAfter: 'Balance nach'
    },
    partner: {
        maxCodeLength: 'Maximale Codelänge:',
        minCodeLength: 'Minimale Codelänge:',
        maxNameLength: 'Maximale Namenslänge:',
        minNameLength: 'Mindestlänge des Namens:',
        symbols: 'zeichen',
        confirmDeleteCampgain: 'Möchten Sie die Kampagne wirklich entfernen?',
        campgains: 'Kampagnen',
        refresh: 'Aufrüsten',
        export: 'Exportieren',
        campgainName: 'Name der Kampagne',
        affiliateLink: 'Affiliate-Link',
        name: 'Titel',
        creationDate: 'Erstelldatum',
        clicks: 'Übergänge',
        link: 'Ref.',
        manage: 'Management',
        noCampgains: 'Keine aktiven Werbekampagnen',
        namePlaceholder: 'Name, der im Panel angezeigt werden soll',
        general: 'Allgemein',
        mainAffiliateLink: 'Haupt-Affiliate-Link',
        registrations: 'Anmeldungen',
        totalProfit: 'Gemeinsamer Gewinn',
        availableBalance: 'Verfügbarer Saldo',
        get: 'Nehmen Sie weg',
        emptyHistory: 'Die Geschichte fehlt',
        operationID: 'Betriebs-ID',
        amount: 'Betrag',
        date: 'Datum',
        operationDate: 'Datum der Operation',
        operationType: 'Betrieb',
        playerID: 'Spieler-ID',
        balanceAfter: 'Balance nach',
        balanceBefore: 'Balance nach oben',
        log: 'Abgrenzungsprotokoll',
        players: 'Spieler',
        registration: 'Anmeldung',
        registrationDate: 'Datum der Anmeldung',
        balance: 'Guthaben',
        noPlayers: 'Keine Gastspieler',
        totalBets: 'Betrag der Wette',
        totalDeps: 'Betrag der Einzahlung',
        totalWithdraws: 'Summe der Ergebnisse',
        registrationSource: 'Quelle der Registrierung',
        mainLink: 'Hauptlink',
        player: 'Spieler',
        registrationsCount: 'Anmeldung',
        bets: 'Staking',
        profit: 'Gewinn',
        stat: 'Statistik',
        totalStat: 'Statistik insgesamt',
        partnerCabinet: 'Büro des Partners',
        partnerID: 'Partner-ID'
    },
    privacy: {
        title: 'Datenschutzrichtlinie',
        description: 'Allgemeine Bestimmungen und Informationen',
        redaction: 'Editorial von',
        content: ''
    },
    promo: {
        linkVk: 'Binden Sie ein VK-Konto, um Boni zu erhalten',
        completeAll: 'Passen Sie alle Bedingungen an und erhalten Sie',
        toBalanceForRepost: 'auf Saldo für Repost!',
        link: 'Bindungen',
        join: 'Eintrag',
        allowNotifications: 'Benachrichtigungen zulassen',
        make: 'Tun',
        toProfileVkAccount: 'zum VK-Kontoprofil.',
        toOurVkGroup: 'zu unserer VK-Gruppe.',
        fromOurGroup: 'von unserer Community.',
        repostToYourPage: 'poste den Eintrag erneut auf seiner Seite.',
        mustBePublic: 'Ihre VK-Seite muss öffentlich sein, sonst können wir nicht nach einer erneuten Veröffentlichung suchen.',
        dontDelete: 'Wenn Sie einen Beitrag früher als 2 Wochen nach der erneuten Veröffentlichung löschen, wird der Bonusbetrag vom Guthaben abgezogen.',
        joinAndGet: 'Trete einer Gruppe bei und erhalte',
        toBalance: 'auf dem Gleichgewicht!',
        bonusGranted: 'Bonus wurde erfolgreich erhalten!',
        momentalBonus: 'Treten Sie unserer VK-Gruppe bei und erhalten Sie einen sofortigen Bonus auf Ihr Guthaben! Das VK-Profil muss öffentlich sein, sonst können wir nicht nach einem Abonnement suchen.',
        vkDoesntLinked: 'VK-Konto nicht gebunden',
        allowNotificationsAndGet: 'Erlaube Benachrichtigungen von unserer Community und erhalte',
        sendAnyMessage: 'Senden Sie eine Nachricht an unsere VK-Gruppe, damit Benachrichtigungen gesendet werden können. Eine nette Überraschung - in regelmäßigen Abständen senden wir Informationen über Aktionen und verschiedene Boni!',
        rollAndGetBonus: 'Fahren Sie mit Ihrem Roulette und erhalten Sie Boni!',
        rollOncePer: 'Drehen Sie das Roulette alle 15 Minuten und gehen Sie bis',
        rollEveryDay: 'Drehen Sie das Rad jeden Tag ohne Einschränkungen!',
        untilNextTry: 'Bevor der nächste Versuch übrig ist',
        subscribeTg: 'Melden Sie sich bei unserem Telegrammkanal an, um zuerst neue Promo-Codes zu erhalten!',
        beFirst: 'Seien Sie einer der Ersten, der einen neuen Promo-Code erhält, erfahren Sie mehr über neue Boni und Innovationen!',
        referrals: 'Empfehlungen',
        getPercent: 'Lade Freunde ein und verdiene 40% der Casino-Vorteile!',
        copyLink: 'Kopieren Sie den Link',
        inviteFriend: 'Lade einen Freund ein',
        getBonus: 'Holen Sie sich einen Bonus',
        affiliateProgramm: 'Affiliate-Programm',
        sendReview: 'Füttere Feedback zum Projekt in unserer Gruppe und erhalte',
        bonusToDeposit: 'einzahlungsbonus',
        upTo: 'bis zu',
        makeReviews: 'Hinterlassen Sie Feedback in unserer VK-Gruppe, nachdem der Moderator verifiziert und veröffentlicht wurde, wird der Bonus automatisch auf Ihrem Konto aktiviert.',
        makeReview: 'Feedback',
        ranks: 'Rangi',
        earnFromWin: 'Wenn Sie Ränge verdienen, erhalten Sie von jedem Gewinn einen erhöhten Cashback.',
        loyaltyProgramm: 'Treueprogramm',
        writeMessage: 'Schreibe eine Nachricht',
        goToGroup: 'Gehe zur Gruppe',
        refreshing: 'Aktualisieren...',
        refreshStatus: 'Aufrüsten',
        roll: 'Drehen',
        linkAccount: 'Binden Sie ein Konto',
        checking: 'Überprüfe...',
        get: 'Holen Sie sich'
    },
    promotions: {
        title: 'Aktien & Angebote',
        description: 'Verpassen Sie nicht die Gelegenheit, einen Bonus in unseren aktuellen Aktien zu erhalten',
        game: 'Spiel',
        minDeposit: 'Min. Einzahlung',
        bonus: 'Bonus',
        addWager: 'Zusätzliche Wette',
        validUntil: 'Handelt bis zu',
        activation: 'Aktivierung',
        get: 'Holen Sie sich',
        unlimit: 'illimitiert',
        oneTime: 'einmalig',
        forEveryDeposit: 'für jede Einzahlung',
        permanentBonus: 'Permanenter Bonus',
        any: 'irgendein'
    },
    responsibility: {
        title: 'Verantwortungsvolles Spiel',
        description: 'Tipps und Regeln für verantwortungsbewusstes Spiel',
        content: ''
    },
    settings: {
        incorrectEmail: 'E-Mail-Adresse falsch',
        bigFileSize: 'Die Dateigröße sollte 5 MB nicht überschreiten.',
        genders: { man: 'Herren', female: 'Womens', other: 'Andere' },
        requiredField: 'Erforderliches Feld',
        dataSuccessChanged: 'Die Daten wurden erfolgreich aktualisiert',
        tfaLoginRequired: 'Legen Sie Login und Passwort fest, um den Authentifikator einzur',
        general: 'Allgemein',
        login: 'Einloggen',
        vkProfile: 'VK-Profil',
        glProfile: 'Google-Profil',
        okProfile: 'Profil von Klassenkameraden',
        fbProfile: 'Facebook-Profil',
        security: 'Sicherheit',
        alterLogin: 'Fügen Sie Ihrem Konto eine alternative Login- und Passwortmethode hinzu.',
        newPassword: 'Neues Passwort',
        repeatPassword: 'Passwort wiederholen',
        saveActions: 'Aktionen speichern',
        currentPassword: 'Aktuelles Passwort',
        tfa: 'Zwei-Faktor-Authentifizierung',
        addTfa: 'Schützen Sie Ihr Brillx-Konto mit zusätzlicher Sicherheit',
        tfaSettings: '2FA-Einstellungen',
        sessions: 'Sitzungen',
        device: 'Gerät',
        region: 'Region',
        ipAddress: 'IP-Adresse',
        date: 'Datum',
        session: 'Sitzung',
        active: 'Aktiv',
        closed: 'Geschlossen',
        verification: 'Überprüfung',
        verificationDescription1: ' Um Ihr Service-Level zu verbessern, bitten wir um Identifizierung von Dokumenten. Dadurch wird Ihr Konto sicher, wenn es wiederhergestellt wird, und ermöglicht eine genaue gezielte Lieferung von physischen Geschenken.',
        verificationDescription2: 'Zu akzeptablen Möglichkeiten der Überprüfung gehört, ein Foto Ihres Ausweises, Führerscheins oder Reisepasses vorzulegen. Nach Abschluss dieses Vorgangs werden wir Sie per E-Mail benachrichtigen.',
        frontPhoto: 'Foto von der Vorderseite des Ausweisdokuments',
        selectFile: 'Datei wählen',
        backPhoto: 'Zurück Foto des Ausweisdokuments',
        fileIsNotSelected: 'Datei nicht ausgewählt',
        name: 'Nennen',
        surname: 'Nachname',
        birthday: 'Geburtsdatum',
        gender: 'Geschlecht',
        country: 'Land',
        city: 'Stadt',
        yourName: 'Dein Name',
        yourSurname: 'Dein Nachname',
        verifyStatus1: 'Dokumente werden überprüft...',
        verifyStatus2: 'Konto verifiziert',
        verifyStatus3: 'Fehler bei der Überprüfung von Dokumenten',
        verifyDescription1: 'Die Dokumente werden derzeit überprüft, sobald die Dokumente verifiziert sind, wird Ihr Konto überprüft!',
        verifyDescription2: 'Ihre Daten wurden vom Sicherheitspersonal überprüft und bestätigt.',
        verifyDescription3: 'Bitte wenden Sie sich für weitere Informationen an den technischen Support.',
        registrationCity: 'Stadt der Registrierung',
        sendDocs: 'Zur Besichtigung einreichen',
        affiliateCabinet: 'Büro des Partners'
    },
    tos: {
        title: 'Nutzer-Vereinbarung',
        description: 'Nutzungsbedingungen',
        redaction: 'Editorial von',
        content: ''
    },
    wallet: {
        deposit: 'Anzahlung',
        withdraw: 'Ausgang',
        history: 'Geschichte',
        minDepositAmount: 'Mindesteinzahlung per',
        minWithdrawAmount: 'Minimale Leistung durch',
        topMethods: 'Beliebt',
        allMethods: 'Alle Methoden',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Andere',
        success: 'Erfolgreich',
        canceled: 'Abgesagt',
        error: 'Fehler',
        waiting: 'Warten',
        processing: 'Bewerbung wird bearbeitet',
        incorrectWalletValue: 'Falsch eingegebene Wallet-Nummer',
        incorrectCardValue: 'Kartennummer wurde falsch eingegeben',
        selectTemplate: 'Vorlage wählen',
        withdrawCreated: 'Auszahlungsantrag erstellt und wird innerhalb von 24 Stunden bearbeitet',
        withdrawCanceled: 'Das Auszahlungsgebot wurde storniert',
        firstWithdraw: 'Dies ist Ihre erste Schlussfolgerung',
        isDataCorrect: 'Sind Sie zuversichtlich, dass die Dateneingabe korrekt ist?',
        payMethod: 'Zahlungsmethode',
        withdrawMethod: 'Auszahlungsmethode',
        selectMethod: 'Wähle einen Weg',
        selectOperator: 'Betreiber wählen',
        refillAmount: 'Nachschub',
        withdrawAmount: 'Auszahlungsbetrag',
        payTroubles: 'Wenn Sie Probleme beim Bezahlen haben, versuchen Sie',
        payHere: 'Zahlen Sie hier',
        pay: 'Gehe zu Zahlung',
        commission: 'Kommission',
        onePayLimit: 'Einzelne Nachschubgrenze',
        oneWithdrawLimit: 'Grenzwert für Einzelausgänge',
        emptyHistory: 'Die Geschichte fehlt',
        operationID: 'Betriebs-ID',
        deposits: 'Nachfüllungen',
        withdraws: 'Befunde',
        txAmount: 'Betrag der Transaktion',
        txDate: 'Datum der Operation',
        purse: 'Ergebnis',
        typePurse: 'Kontonummer',
        toGet: 'Um zu erhalten',
        createWithdraw: 'Erstellen Sie eine Anwendung'
    },
    tfa: {
        tfa: 'Zwei-Faktor-Authentifizierung',
        typeYourPassword: 'Geben Sie Ihr Passwort ein',
        enterPin: 'Geben Sie einen 6-stelligen Code aus der App ein',
        downloadApp: 'Laden Sie die App herunter',
        downloadAndInstall: 'Downloaden und installieren',
        forYourPhone: 'für Ihr Telefon oder Tablet',
        scanQR: 'qR-Code scannen',
        scanQRWithCamera: 'Scannen Sie QR auf der linken Seite mit der Kamera Ihres Telefons',
        openWithLink: 'Nach Link öffnen',
        openInApp: 'In App öffnen',
        tfaManualEnter: '2FA-Taste (manuelle Eingabe)'
    },
    pwa: {
        androidStep1: 'Chrom-Browser öffnen',
        androidStep1Description: 'Wenn Sie sich in einem anderen Browser befinden, öffnen Sie diese Seite in Chrome',
        androidStep2: 'Klicken Sie auf das Browsermenü',
        androidStep2Description: 'Das Browsermenü ist die Ellipse in der oberen rechten Ecke Ihres Browsers',
        androidStep3: 'Wähle "Zum Startbildschirm hinzufügen"',
        androidStep3Description: 'Sie sehen ein Fenster mit Informationen ("Zum Startbildschirm hinzufügen"), in dem Sie "Hinzufügen" auswählen müssen, wonach die Anwendung zum Desktop Ihres Geräts hinzugefügt wird.',
        androidAlert: 'Schritte können je nach Browserversion abweichen',
        iosStep1: 'Safari-Browser öffnen',
        iosStep1Description: 'Wenn Sie sich in einem anderen Browser befinden, öffnen Sie diese Seite in Safari',
        iosStep2: 'Klicke auf "Teilen"',
        iosStep2Description: 'Klicken Sie auf die Schaltfläche "Teilen" am unteren Bildschirmrand, woraufhin sich das Parameterauswahlfenster öffnet',
        iosStep3: 'Wählen Sie "Zum Startbildschirm hinzufügen"',
        iosStep3Description: 'Wählen Sie "Zum Startbildschirm hinzufügen" und klicken Sie auf "Hinzufügen"',
        app: 'Mobile Anwendung',
        installAppForMobile: 'Installieren Sie die neue praktische Brillx App für iOS und Android',
        install: 'Installieren'
    },
    undefined: '???'
};