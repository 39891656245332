module.exports = {
    system: { code: 'fl', name: 'Filippiiniläinen' },
    aml: {
        title: 'Rahanpesun torjuttaminen',
        description: 'Turvavalvonta',
        content: ''
    },
    auth: {
        accountCreated: 'Rekisteröity tili',
        agreeText: 'Vahvistan, että olen 18-vuotias ja olen lukenut',
        agreeTos: 'käyttöehdot',
        alreadyRegistered: 'Oletko jo rekisteröitynyt?',
        byLogin: 'Kirjaudu sisään ja salasanalla',
        bySocialNetworks: 'Sosiaalisten verkostojen kautta',
        createAccount: 'Luo tili',
        forgotPassword: 'Unohditko salasanasi?',
        loading: 'Ladataan...',
        login: 'Kirjaudu sisään',
        loginTitle: 'Sivuston valtuutus',
        maxLoginLength: 'Suurin kirjautumispituus:',
        maxPasswordLength: 'Salasanan enimmäispituus:',
        minLoginLength: 'Pienin kirjautumispituus:',
        minPasswordLength: 'Minmal Salasanan pituus:',
        passwordConfirmation: 'Toista salasana',
        passwordsMustBeSame: 'Salasanojen täytyy vastata',
        pleaseWait: 'Ole kiltti ja odota...',
        registration: 'Rekisteröinti',
        registrationTitle: 'Rekisteröidy paikan päällä',
        symbols: 'hahmot',
        yourLogin: 'Kirjautumistunnuksesi',
        yourPassword: 'Salasanasi',
        yourEmail: 'Sähköpostiosoitteesi',
        logoutConfirm: 'Haluatko todella ulos?',
        passwordRecovery: 'Palauta salasana',
        newPassword: 'Uusi salasana',
        copied: 'Kopioitu',
        logout: 'Ulos'
    },
    basic: {
        yes: 'Oo',
        no: 'Ehei',
        confirm: 'Vakuuttaa',
        confirmed: 'Upvotetettu',
        cancel: 'Kumoamisesta',
        sending: 'Lähettää...',
        sent: 'Lähetä',
        save: 'Tallentaa',
        create: 'Luo',
        or: 'tai',
        activate: 'Aktivoi',
        deactivate: 'Poista aktivointi',
        prevPage: 'Edellinen sivu',
        nextPage: 'Seuraava sivu',
        of: 'ulkona',
        balance: 'Balanssi',
        copy: 'Kopio',
        copied: 'Kopioitu'
    },
    bonus: {
        permanentBonus: 'Pysyvä bonus',
        tenPercent: '10% jokaisesta talletuksesta!',
        more: 'Lue lisää',
        fastRegistration: 'Nopea sisäänkirjautuminen',
        noVerification: 'ilman tarkistusta',
        fastWithdraws: 'Nopeat voitot',
        highLimits: 'korkeat rajat',
        loyaltyProgramm: 'Kanta-asiakasohjelma',
        winCashback: 'Jopa 1,2% jokaisesta voitosta!',
        levelSystem: 'Tasojärjestelmä',
        bigPercents: 'Suuret prosentit',
        payForWin: 'Maksa voitosta',
        butNoBets: 'ei vedoille',
        affiliateProgramm: 'Affiliate-ohjelma',
        join: 'Liity',
        revenueShare: '40% Tuoto-osuus',
        anytimeWithdraw: 'lähtö milloin tahansa',
        affiliateCabinet: 'Affiliate kaappi',
        detailStat: 'yksityiskohtaiset tilastot',
        title: 'Promo Code',
        promoEveryDay: 'Mga code ng promos araw-araw sa aming pangkat na VK',
        enterPromoCode: 'Ipasok ang promo code'
    },
    fair: {
        title: 'Lisenssin ja rehellisyyden takuu',
        description: 'Takaamme reilun pelin kaikille',
        fairDescription1: 'Brillx Casino ymmärtää, kuinka tärkeää on luoda turvallisuusympäristö, jotta käyttäjät luottavat peliemme rehellisyyteen.',
        fairDescription2: 'Siksi kasinoillamme käytämme huippuyritysten tarjoamia pelejä, jotka kehittävät verkkokasinoille tarkoitettuja ohjelmistoja. Joukossa yrityksiä, kuten',
        fairDescription3: 'Koska perustajajäsen eCOGRA ja HVC, Mycrogaming on pää edellä muita kasino pelaamista valmistajia, asettamalla korkeimmat standardit uhkapelialalla kokonaisuutena, erityisesti rehellisyys online-pelaamista, valinta vastuullinen operaattorit, ja suojella etuja pelaajia.',
        andOther: 'ja muut',
        also: 'Tarjoamme myös yrityksen luomia pelejä',
        defaultCasino: 'Tavallinen online-kasino',
        brillxCasino: 'Brillx Rehellisyys Control Casino',
        defaultCasinoDescription1: 'Teet veto, joka on kirjoitettu kasinon palvelimelle',
        defaultCasinoDescription2: 'Tietosi kerätään ja tallennetaan kasinon palvelimille',
        defaultCasinoDescription3: 'Pelin rehellisyyden tarkistaminen on mahdotonta — kaikki algoritmit tallennetaan ja tallennetaan kasinon puolelle',
        defaultCasinoDescription4: 'Saadaksesi voiton, sinun täytyy käydä läpi pitkä lupamenettely',
        defaultCasinoDescription5: 'Kasinon vakavaraisuus kyseessä, koska sen saldo ei ole julkaistu missään',
        brillxCasinoDescription1: 'Pelaajavedonlyönnin tarjoajien tietojen kopio tallennetaan blockchainiin, eikä sitä voi muuttaa',
        brillxCasinoDescription2: 'Ihmisten pelitilastot salataan ja tallennetaan lohkoketjuun, joka ei ole sidottu pelaajan henkilötietoihin',
        brillxCasinoDescription3: 'Eheyden hallinta tapahtuu lohkoketjutekniikalla',
        brillxCasinoDescription4: 'Nostoihin tarvitaan KYC-vakiolupa, ei siirtomaksua',
        brillxCasinoDescription5: 'Avoin raportointi ja valtava pooli pelaajan maksuja'
    },
    game: {
        loading: 'Ladataan',
        lobby: 'Aula',
        fullScreen: 'Koko näyttö',
        deposit: 'Talleta',
        betsAmount: 'Taya ng Halaga',
        totalRTP: 'Kabuuang RTP',
        averageBet: 'Average na taya',
        topWin: 'Nangungunang panalo'
    },
    help: {
        title: 'Ohje ja tuki',
        description: 'Vastaukset yleisimpiin kysymyksiin ja yhteydenpitoon asiakaspalveluun.',
        connectTypes: 'Menetelmät kommunikoida kanssamme',
        operatorsOnline: 'Verkkooperaattorit 24 tuntia vuorokaudessa',
        supportLive: 'Tuki (live)',
        onlineChat: 'Live chat',
        averageTimeChat: 'Keskimääräinen vasteaika: 10 minuuttia',
        supportEmail: 'Asiakaspalvelu (sähköposti)',
        averageTimeEmail: 'Keskimääräinen vasteaika: 1 päivä',
        generalQuestions: 'Yleisiä kysymyksiä',
        aboutSite: 'Tietoja sivustosta',
        aboutSiteContent: 'Brillx on online-kasino, jolla on kansainväliset lisenssit ja nimi. Tarjoamme yli 6 000 peliä luettelossa, laajan valikoiman urheilutapahtumia ja paljon minipelejä. Tuemme monia maksujärjestelmiä ja useimpia suosittuja kryptovaluuttoja.',
        bonus: 'Bonus',
        bonuses: 'Bonukset',
        onPage: 'Sivulla',
        manyFreeBonuses: 'on olemassa useita tapoja saada rahaa saldoosi täysin ilmainen!',
        bonus1: 'Rekisteröinti bonus. Kun olet rekisteröitynyt sivustolle, sinulla on 60 minuuttia aikaa syöttää kutsukoodi ja saada jopa 10.00 RUB.',
        bonus2: 'Rekisteröinti bonus. Kun olet rekisteröitynyt sivustolle, sinulla on 60 minuuttia aikaa syöttää kutsukoodi ja saada jopa 10.00 RUB.',
        bonus3: 'Rekisteröinti bonus. Kun olet rekisteröitynyt sivustolle, sinulla on 60 minuuttia aikaa syöttää kutsukoodi ja saada jopa 10.00 RUB.',
        bonus4: 'Rekisteröinti bonus. Kun olet rekisteröitynyt sivustolle, sinulla on 60 minuuttia aikaa syöttää kutsukoodi ja saada jopa 10.00 RUB.',
        promocodes: 'Tarjouskoodit',
        inviteFriend: 'Kutsu ystävä',
        balanceRefilling: 'Tasapainon täydennys',
        wallet: 'Lompakko',
        howToRefill: 'Miten täydennän tasapainoa?',
        commissions: 'Komissio',
        periodOfRefilling: 'Ilmoittautumisehdot',
        withdrawal: 'Nostot',
        howToWithdraw: 'Miten nostan varoja?',
        periodOfWithdrawal: 'Kotiutuksen aikajana',
        clickToRefill: 'Napsauta painiketta täydentääksesi tasapainoa',
        atRightCorner: 'sivuston oikeassa yläkulmassa. Valitse maksutapa, joka sopii sinulle, kirjoita talletussumma ja valitse Siirry maksuun.',
        minimalRefilling: 'Vähimmäistäydennys',
        commissionsOnPage: 'Kaikkien maksutapojen palkkiot on lueteltu sivulla',
        clickToWithdraw: 'Napsauta painiketta nostaaksesi rahaa tililtäsi',
        averageTimeWithdraw: 'Kaikki havainnot käsitellään keskimäärin tunnin kuluessa hakemuksen jättämisestä.',
        allTransactions: 'Kaikki tapahtumat käsitellään välittömästi. Viivästyksiä voi ilmetä vain, jos sinulla on ongelmia maksutavan kanssa, jota olet täydentämässä saldoasi.',
        inCaseOfProblems: 'Jos ongelmia ilmenee, ota yhteyttä asiakaspalveluumme ja anna tarkimmat tiedot ongelmastasi.',
        atRightCornerWithdraw: 'sivuston oikeassa yläkulmassa. Valitse sinulle sopiva nostotapa, kirjoita summa ja valitse Siirry maksuun.',
        minimalWithdrawal: 'Pienin vetäytyminen',
        ifBigAmount: 'Jos nostosumma on suuri, nosto voi mennä hieman pidempään, yleensä jopa tunnin. Yöllä (00:00am 09:00 MSC) havainnot voivat mennä useita tunteja, sillä milloin maksun aggregaattori käsittelee nostopyyntöjä pidempään.',
        sometimes: 'Hyvin harvoissa tapauksissa peruuttaminen voi kestää jopa 24 tuntia pankkikuormitusten ja niin edelleen vuoksi. Jos rahat eivät saavu sen jälkeen, ota yhteyttä asiakaspalveluumme ja anna tarkimmat tiedot ongelmastasi.',
        checkCorrectPurse: 'Varmista myös, että määrität tulosteen tiedot oikein. Jotta voit vakuuttaa lompakon, jonka viittasit peruuttamiseen, sinun on mentävä tapahtumahistoriaasi ja avattava tiedot klikkaamalla haluamaasi tapahtumaa.'
    },
    interface: {
        months: {
            jan: 'jan.',
            feb: 'hel.',
            mar: 'mar.',
            apr: 'huh.',
            may: 'mai',
            jun: 'jun.',
            jul: 'hei.',
            aug: 'elo.',
            sep: 'sep.',
            oct: 'lok.',
            nov: 'mar.',
            dec: 'dec.'
        },
        year: 'g.',
        confirmPasswordRecovery: 'Vahvista salasanan nollaus seuraamalla sähköpostiisi lähetettyä linkkiä',
        passwordWasChanged: 'Salasanaa muutettu onnistuneesti, voit kirjautua sisään käyttämään uusia tietoja',
        confirmEmail: 'Vahvista sähköpostiosoite linkillä',
        emailWasConfirmed: 'Sähköpostiosoite on vahvistettu onnistuneesti',
        accountLinked: 'Tili yhdistettiin onnistuneesti',
        bonusGranted: 'Saatu bonus'
    },
    landing: {
        newGames: 'Uusia pelejä',
        topGames: 'Suosittuja pelejä',
        brillxSelect: 'Brillxin valinta',
        daylyLiveRtp: 'Live RTP - 24 tuntia',
        brillxCasino: 'Brillx kasino',
        casinoInfo1: 'Tutustu monenlaisiin jännittäviin peleihin täällä Brillx.GG, hedelmäpeli sivusto räätälöity tyydytystä varten. Tuhannet ensiluokkaiset peliautomaatit ovat saatavilla pelaajille ympäri maailmaa. Olipa kyseessä valtavat jättipotteja, pokeripaikkoja tai teemalla peliautomaatteja, joita tarvitset, meillä on kaikki mitä tarvitset!',
        casinoInfo2: 'Koe nopean sisäänkirjautumisen helppous klikkaamalla sisäänkirjautumispainiketta nyt! Nauti hetkiä, kun pelaat oikealla rahalla peliautomaatteihin johtavilta pelintarjo',
        casinoInfo3: 'Kasinomme nopeilla maksuilla ja valtava valikoima kaikenlaisia hedelmäpeliautomaattien muunnelmia toimii sinulle 24/7 - valitse vain peli ja aloita pelaaminen!',
        casinoInfo4: 'Pelaa kolikkopelejä verkossa',
        casinoInfo5: 'Emme koskaan pilaa hauskanpitoa, joten voit pelata kaikkia pelejämme lataamatta mitään tietokoneellesi.',
        casinoInfo6: 'Selaa tuhansia lähtö, valitse peli haluat, ja klikkaa ”PLAY” - se on niin helppoa!',
        casinoInfo7: 'Kasinokolikkopelit ovat yksi suosituimmista peleistä. Ei vain siksi valtava kannustimia voit saada, mutta myös koska jännittävä grafiikka, hyvä musiikki, ja yleinen nautittavaa visuaalinen vaikutus pelin.',
        casinoInfo8: 'Voit jopa kokeilla jokaista peliautomaattiamme siirtymällä viihdetilaan - hämmästyttävä ominaisuus, jonka avulla voit pelata kaikkia peliautomaatteja lataamatta ja ilman rekisteröintiä on ilmainen! Voit käyttää sitä matkapuhelimen, tietokoneen tai tabletin kautta. Tutustu parhaisiin peliautomaatteihin tänään, joita voit pelata maksutta ilman lataamista, ja kokea ainutlaatuisen seikkailun parhaissa kasinopelissä maailmanlaajuisesti. Ota paras laukaus tänään, ja anna kohtalon päättää, mitä odottaa sinua!',
        casinoInfo9: 'Mitä odotat? Tänään voi olla onnenpäiväsi!',
        casinoInfo10: 'Tuhannet peliautomaatit',
        casinoInfo11: 'Tässä ovat erilaiset peliautomaatit, joita löydät kasinoiltamme:',
        casinoInfo12: '3 kiekon lähtö',
        casinoInfo13: '3 kiekon lähtö tunnetaan parhaiten klassisina tai perinteisinä peliautomaatteina. Kelat ovat pystysuoria viivoja, joita löydät pelistä, jossa symbolit ovat kaskadoituja. Jos etsit jännitystä, kokeile pelata 3 kiekon hedelmäpeli! Jotkut pelit voit pelata ovat Book of Alchemy, Watford FC Slot ja monet muut.',
        casinoInfo14: '5 kiekon lähtö',
        casinoInfo15: "Saat paremmat mahdollisuudet voittaa yhdistelmiä pelaamasta 5 kiekon kolikkopeliä! Useimmat 5 rullan lähtö maksetaan joka kerta, kun 3, 4 tai 5 merkkiä putoaa rullille. Voit myös saada voittokertoimia, bonuspelikierroksia tai ilmaiskierroksia riippuen pelistä. Jotkut 5 kiekon pelejä voit pelata ovat Vikings Go Berzerk, Legacy of Dead, ja That's Rich.",
        casinoInfo16: 'Progressiivinen lähtö',
        casinoInfo17: 'Progressiiviset paikat ovat juone kaikentyyppisten peliautomaattien joukossa. Jackpotin koko kasvaa pelin edetessä. Sinua odottaa valtavia miljoonia, ja sinusta voisi tulla yksi rikkaimmista ihmisistä tähän mennessä!',
        casinoInfo18: 'Ruletti',
        casinoInfo19: 'Ruletti on peli, joka panostaa yksittäisiä numeroita pöydälle, väri, numero tyyppi, tai yhdistelmiä. Testaa onneasi ja voita, jos panostat osuu pyörään. Pelaa upeita reaaliaikaisia ruletin juuri nyt!',
        casinoInfo20: 'Pokeria',
        casinoInfo21: 'Jos olet perehtynyt pokeriin tavallisella kasinolla, voit kokeilla pelaamista juuri nyt hyödyntämällä laajaa valikoimaa online-videopokeripelejä. Ole valmis kokemaan unohtumaton peli tänään! Teräväpiirtoanimaatiolla ja upeilla erikoisvideokierroksilla tulet varmasti takaisin hakemaan uutta voittoa.',
        casinoInfo22: 'Baccarat',
        casinoInfo23: 'Baccarat on yksi suosituimmista peleistä maailmassa. Päihitä jakaja vedonlyönti kädestä lähimpänä 9. Voit myös voittaa, jos on tasapeli. Kuulostaa jännittävältä, oikeasti? Saat vedot valmiiksi nyt ja panosta voittavan puolen!',
        casinoInfo24: 'Blackjack',
        casinoInfo25: 'Blackjack, voit voittaa jakajan, jos sinulla on käsi lähimpänä 21. Saat mahdollisuuden tuplata panoksesi, kun pelaat majesteettisia blackjack-peliautomaattejamme!',
        gamesProviders: 'Pelintarjoajat',
        showMore: 'Näytä lisää',
        hideInfo: 'Piilota tiedot',
        allGames: 'Kaikki pelit',
        providers: 'Tarjoajat',
        new: 'Uusi',
        tournaments: 'Bonus Buy',
        slots: 'Lähtö',
        roulette: 'Ruletti',
        card: 'Kortit',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Pelaa', title: 'Live' },
    lobby: {
        providers: 'Tarjoajat',
        recent: 'Viimeaikaiset',
        top: 'Suosittu',
        new: 'Uusi',
        favorite: 'Suosikit',
        nothingFound: 'Mitään ei löytynyt',
        play: 'PELATA',
        demo: 'DEMO',
        forExample: 'Esimerkiksi'
    },
    log: { date: 'Treffi', type: 'Toiminta', amount: 'Määrä', id: 'ID' },
    loyalty: {
        title: 'Kanta-asiakasohjelma',
        description: 'Vastaanota cashback, joka riippuu uskollisuustasosta jokaisen vedon',
        cabinet: 'Kaappi',
        myCabinet: 'Toimistoni',
        payLog: 'Jaksotusloki',
        log: 'Logue',
        level: 'Taso',
        totalWins: 'Voittomäärä',
        totalBets: 'Panoksen määrä',
        availableBalance: 'Käytettävissä oleva',
        get: 'Ota pois',
        cashback: 'Cashback',
        emptyHistory: 'Tarina puuttuu',
        operationID: 'Operaation tunnus',
        amount: 'Määrä',
        date: 'Treffi',
        operationDate: 'Toiminnan päivämäärä',
        operationType: 'Toiminta',
        balanceBefore: 'Tasapaino ylös',
        balanceAfter: 'Tasapaino jälkeen'
    },
    menu: {
        affProgramm: 'Kumppanit',
        fairGame: 'Reilu peli',
        free: 'Ilmainen',
        games: 'Pelit',
        lobby: 'Aula',
        promo: 'Mainos',
        loyaltyProgramm: 'Uskollisuus',
        promotions: 'Kannat',
        support: 'Tukipalvelu',
        settings: 'Asetukset',
        history: 'Pelihistoria',
        more: 'Lisää',
        profile: 'Profiili',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Virhe', success: 'Onnistunut' },
    profile: {
        wallet: 'Lompakko',
        onSiteFrom: 'Paikan päällä',
        totalBets: 'Panoksen määrä',
        betsCount: 'Veikkaus',
        totalWins: 'Voittomäärä',
        bestWin: 'Paras voitto',
        emptyHistory: 'Tarina puuttuu',
        balanceAfter: 'Tasapaino jälkeen'
    },
    partner: {
        maxCodeLength: 'Koodin enimmäispituus:',
        minCodeLength: 'Koodin vähimmäispituus:',
        maxNameLength: 'Nimen enimmäispituus:',
        minNameLength: 'Nimen vähimmäispituus:',
        symbols: 'hahmot',
        confirmDeleteCampgain: 'Haluatko todella poistaa kampanjan?',
        campgains: 'Kampanjat',
        refresh: 'Parantaa',
        export: 'Vienti',
        campgainName: 'Kampanjan nimi',
        affiliateLink: 'Affiliate-linkki',
        name: 'Nimike',
        creationDate: 'Luotu päivämäärä',
        clicks: 'Siirtymät',
        link: 'Viite.',
        manage: 'Johtaminen',
        noCampgains: 'Ei aktiivisia mainoskampanjoita',
        namePlaceholder: 'Paneelissa näytettävä nimi',
        general: 'Kenraali',
        mainAffiliateLink: 'Tärkein affiliate-linkki',
        registrations: 'Ilmoittautumiset',
        totalProfit: 'Yhteinen voitto',
        availableBalance: 'Käytettävissä oleva',
        get: 'Ota pois',
        emptyHistory: 'Tarina puuttuu',
        operationID: 'Operaation tunnus',
        amount: 'Määrä',
        date: 'Treffi',
        operationDate: 'Toiminnan päivämäärä',
        operationType: 'Toiminta',
        playerID: 'Pelaajan tunnus',
        balanceAfter: 'Tasapaino jälkeen',
        balanceBefore: 'Tasapaino ylös',
        log: 'Jaksotusloki',
        players: 'Pelaajat',
        registration: 'Rekisteröinti',
        registrationDate: 'Rekisteröintipäivä',
        balance: 'Balanssi',
        noPlayers: 'Ei vieraspelaajia',
        totalBets: 'Panoksen määrä',
        totalDeps: 'Talletuksen määrä',
        totalWithdraws: 'Havaintojen summa',
        registrationSource: 'Rekisteröinnin lähde',
        mainLink: 'Päälinkki',
        player: 'Pelaaja',
        registrationsCount: 'Rekisteröinti',
        bets: 'Seitoksia',
        profit: 'Voitto',
        stat: 'Tilastot',
        totalStat: 'Yleiset tilastot',
        partnerCabinet: 'Kumppanin toimisto',
        partnerID: 'Kumppanin tunnus'
    },
    privacy: {
        title: 'Tietosuojakäytäntö',
        description: 'Yleiset säännökset ja tiedotus',
        redaction: 'Pääkirjoitus:',
        content: ''
    },
    promo: {
        linkVk: 'Sido VK-tili saadaksesi bonuksia',
        completeAll: 'Sovita kaikki ehdot ja saat',
        toBalanceForRepost: 'tasapainossa repost!',
        link: 'Siteet',
        join: 'Pääsy',
        allowNotifications: 'Salli ilmoitukset',
        make: 'Tee',
        toProfileVkAccount: 'vK-tiliprofiiliin.',
        toOurVkGroup: 'VK-ryhmällemme.',
        fromOurGroup: 'yhteisöstämme.',
        repostToYourPage: 'repost merkintä hänen sivulleen.',
        mustBePublic: 'VK-sivusi on oltava julkinen, tai emme voi tarkistaa repost.',
        dontDelete: 'Jos poistat viestin aikaisintaan 2 viikkoa repostauksen jälkeen, bonussumma vähennetään saldosta.',
        joinAndGet: 'Liity ryhmään ja hanki',
        toBalance: 'tasapainossa!',
        bonusGranted: 'Bonus on saatu onnistuneesti!',
        momentalBonus: 'Liity VK-ryhmään ja saat välittömän bonuksen saldoosi! VK-profiilin on oltava julkinen, tai emme voi tarkistaa tilausta.',
        vkDoesntLinked: 'VK-tili ei ole sidottu',
        allowNotificationsAndGet: 'Salli ilmoitukset yhteisöstämme ja saat',
        sendAnyMessage: 'Lähetä kaikki viestit VK-ryhmällemme, jolloin ilmoitukset voidaan lähettää. Mukava yllätys - lähetämme säännöllisesti tietoa kampanjoista ja erilaisista bonuksista!',
        rollAndGetBonus: 'Cruise rulettia ja saada bonuksia!',
        rollOncePer: 'Twist rulettia kerran 15 minuutin välein ja saada asti',
        rollEveryDay: 'Pyöritä pyörää ilman rajoituksia joka päivä!',
        untilNextTry: 'Ennen kuin seuraava yritys on jäljellä',
        subscribeTg: 'Rekisteröidy sähkekanavallemme saadaksesi ensin uudet tarjouskoodit!',
        beFirst: 'Ole yksi ensimmäisistä saada tuore tarjouskoodi, oppia uusia bonuksia ja innovaatioita!',
        referrals: 'Suosittelut',
        getPercent: 'Kutsu ystäviä ja ansaita 40% kasinon eduista!',
        copyLink: 'Kopioi linkki',
        inviteFriend: 'Kutsu ystävä',
        getBonus: 'Hanki bonus',
        affiliateProgramm: 'Affiliate-ohjelma',
        sendReview: 'Syöttää palautetta projektista ryhmässämme ja saada',
        bonusToDeposit: 'bonus',
        upTo: 'aikeissa',
        makeReviews: 'Jätä palautetta VK-ryhmässämme, kun moderaattori on tarkistettu ja lähetetty, bonus aktivoituu automaattisesti tilillesi.',
        makeReview: 'Palaute',
        ranks: 'Rangi',
        earnFromWin: 'Ansaita riveissä saat lisää cashback jokaisesta voitosta.',
        loyaltyProgramm: 'Kanta-asiakasohjelma',
        writeMessage: 'Kirjoita viesti',
        goToGroup: 'Siirry ryhmään',
        refreshing: 'Päivitä...',
        refreshStatus: 'Parantaa',
        roll: 'Kierrä',
        linkAccount: 'Tilin sitominen',
        checking: 'Tarkistetaan...',
        get: 'Hanki'
    },
    promotions: {
        title: 'Varastot ja tarjoukset',
        description: 'Älä missaa mahdollisuutta saada bonusta nykyisissä osakkeissamme',
        game: 'Peli',
        minDeposit: 'Min. talletus',
        bonus: 'Bonus',
        addWager: 'Ylimääräinen panos',
        validUntil: 'Toimii jopa',
        activation: 'Aktivointi',
        get: 'Hanki',
        unlimit: 'rajoittamaton',
        oneTime: 'kertaluonteinen',
        forEveryDeposit: 'jokaisesta talletuksesta',
        permanentBonus: 'Pysyvä bonus',
        any: 'kahit ano'
    },
    responsibility: {
        title: 'Vastuullinen peli',
        description: 'Vastuullisen pelin vinkit ja säännöt',
        content: ''
    },
    settings: {
        incorrectEmail: 'Sähköpostiosoite väärin',
        bigFileSize: 'Tiedoston koko ei saa ylittää 5 Mt.',
        genders: { man: 'Miesten', female: 'Naiset', other: 'Muut' },
        requiredField: 'Pakollinen kenttä',
        dataSuccessChanged: 'Tiedot on päivitetty onnistuneesti',
        tfaLoginRequired: 'Määritä todentaminen kirjautumistunnuksen ja salasanan määrittäminen',
        general: 'Kenraali',
        login: 'Kirjaudu sisään',
        vkProfile: 'VK-profiili',
        glProfile: 'Google-profiili',
        okProfile: 'Profiili Luokka',
        fbProfile: 'Facebook-profiili',
        security: 'Turvallisuus',
        alterLogin: 'Lisää vaihtoehtoinen kirjautumistapa ja salasana tilillesi.',
        newPassword: 'Uusi salasana',
        repeatPassword: 'Toista salasana',
        saveActions: 'Tallenna toiminnot',
        currentPassword: 'Nykyinen salasana',
        tfa: 'Kaksiosainen todennus',
        addTfa: 'Suojaa Brillx-tilisi lisäsuojauksella',
        tfaSettings: '2FA-asetukset',
        sessions: 'Istunnot',
        device: 'Laite',
        region: 'Alue',
        ipAddress: 'IP-osoite',
        date: 'Treffi',
        session: 'Istunto',
        active: 'Aktiivinen',
        closed: 'Suljettu',
        verification: 'Tarkastus',
        verificationDescription1: ' Palvelutason parantamiseksi pyydämme tunnistamaan asiakirjoja. Tämä pitää tilisi turvassa, jos se palautetaan, ja antaa tarkkoja kohdennettuja fyysisiä lahjoja.',
        verificationDescription2: 'Hyväksyttäviin todentamistapoihin on antaa kuva henkilöllisyystodistuksesta, ajokortista tai passista. Kun tämä prosessi on valmis, ilmoitamme sinulle sähköpostitse.',
        frontPhoto: 'Kuva henkilöllisyystodistuksen etuosasta',
        selectFile: 'Valitse tiedosto',
        backPhoto: 'Takaisin kuva henkilöllisyystodistuksesta',
        fileIsNotSelected: 'Tiedostoa ei valittu',
        name: 'Nimi',
        surname: 'Sukunimi',
        birthday: 'Syntymäpäivä',
        gender: 'Sukupuoli',
        country: 'Maa',
        city: 'Kaupunki',
        yourName: 'Nimesi',
        yourSurname: 'Sukunimesi',
        verifyStatus1: 'Asiakirjoja tarkistetaan...',
        verifyStatus2: 'Tilin tarkistettu',
        verifyStatus3: 'Asiakirjan tarkistus',
        verifyDescription1: 'Asiakirjat ovat parhaillaan tarkistettavana, heti kun asiakirjat tarkistetaan, tilisi tarkistetaan!',
        verifyDescription2: 'Turvahenkilöstö on vahvistanut tietosi ja vahvistanut ne.',
        verifyDescription3: 'Ota yhteyttä tekniseen tukeen saadaksesi lisätietoja.',
        registrationCity: 'Ilmoittautumiskaupunki',
        sendDocs: 'Lähetä tarkastettavaksi',
        affiliateCabinet: 'Kumppanin toimisto'
    },
    tos: {
        title: 'Käyttäjäsopimus',
        description: 'Käyttöehdot ja käyttösäännöt',
        redaction: 'Pääkirjoitus:',
        content: ''
    },
    wallet: {
        deposit: 'Talleta',
        withdraw: 'Lähtö',
        history: 'Historia',
        minDepositAmount: 'Vähimmäistalletus',
        minWithdrawAmount: 'Vähimmäisteho kautta',
        topMethods: 'Suosittu',
        allMethods: 'Kaikki menetelmät',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Muut',
        success: 'Onnistunut',
        canceled: 'Peruttu',
        error: 'Virhe',
        waiting: 'Odottaminen',
        processing: 'Hakemusta käsitellään',
        incorrectWalletValue: 'Lompakko numero syötetty',
        incorrectCardValue: 'Kortin numero syötetty väärin',
        selectTemplate: 'Valitse malli',
        withdrawCreated: 'Voittosovellus luodaan ja käsitellään 24 tunnin kuluessa',
        withdrawCanceled: 'Voittotarjous on peruttu',
        firstWithdraw: 'Tämä on ensimmäinen johtopäätöksesi',
        isDataCorrect: 'Oletko varma, että tietojen syöttö on oikein?',
        payMethod: 'Maksutapa',
        withdrawMethod: 'Nostamismenetelmä',
        selectMethod: 'Valitse tapa',
        selectOperator: 'Valitse operaattori',
        refillAmount: 'Täydennys',
        withdrawAmount: 'Nostamisen määrä',
        payTroubles: 'Jos sinulla on vaikeuksia maksaa, yritä',
        payHere: 'Maksa tästä',
        pay: 'Siirry maksuun',
        commission: 'Komissio',
        onePayLimit: 'Yksittäinen täydennysraja',
        oneWithdrawLimit: 'Yhden tuotoksen raja',
        emptyHistory: 'Tarina puuttuu',
        operationID: 'Operaation tunnus',
        deposits: 'Täydennykset',
        withdraws: 'Havainnot',
        txAmount: 'Tapahtuman summa',
        txDate: 'Toiminnan päivämäärä',
        purse: 'Pisteet',
        typePurse: 'Tilinumero',
        toGet: 'Vastaanottaminen',
        createWithdraw: 'Sovelluksen luominen'
    },
    tfa: {
        tfa: 'Kaksiosainen todennus',
        typeYourPassword: 'Anna salasanasi',
        enterPin: 'Anna 6-numeroinen koodi sovelluksesta',
        downloadApp: 'Lataa sovellus',
        downloadAndInstall: 'Lataa ja asenna',
        forYourPhone: 'puhelimeesi tai tablet-laitteellesi',
        scanQR: 'skannaa QR-koodi',
        scanQRWithCamera: 'Skannaa QR vasemmalla puhelimen kameralla',
        openWithLink: 'Avaa linkillä',
        openInApp: 'Avaa sovelluksessa',
        tfaManualEnter: '2FA-näppäin (manuaalinen tulo)'
    },
    pwa: {
        androidStep1: 'Buksan ang Chrome browser',
        androidStep1Description: 'Kung nasa ibang browser ka, buksan ang pahinang ito sa Chrome',
        androidStep2: 'Mag-click sa menu ng browser',
        androidStep2Description: 'Ang menu ng browser ay ang ellipsis na matatagpuan sa kanang sulok sa itaas ng iyong browser',
        androidStep3: 'Piliin ang "Idagdag sa Home Screen"',
        androidStep3Description: 'Makakakita ka ng isang window na may impormasyon ("Idagdag sa Home Screen"), kung saan dapat mong piliin ang "Idagdag", pagkatapos na ang application ay idaragdag sa desktop ng iyong aparato',
        androidAlert: 'Ang mga hakbang ay maaaring magkakaiba depende sa bersyon ng browser',
        iosStep1: 'Buksan ang Safari browser',
        iosStep1Description: 'Kung nasa ibang browser ka, buksan ang pahinang ito sa Safari',
        iosStep2: 'I-click ang "Ibahagi"',
        iosStep2Description: 'Mag-click sa pindutang "Ibahagi" sa ilalim ng screen, at pagkatapos ay magbubukas ang window ng pagpili ng parameter',
        iosStep3: 'Piliin ang "Idagdag sa Home Screen"',
        iosStep3Description: 'Piliin ang "Idagdag sa Home Screen" at mag-click sa "Idagdag"',
        alert: 'Ang mga hakbang ay maaaring magkakaiba depende sa bersyon ng browser',
        installApp: 'I-install ang Brillx app',
        back: 'Bumalik',
        app: 'Mobile application',
        installAppForMobile: 'I-install ang bagong maginhawang Brillx app para sa iOS at Android',
        install: 'I-install'
    },
    undefined: '???'
};