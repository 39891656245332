module.exports = {
    system: { code: 'tr', name: 'Türk' },
    aml: {
        title: 'Kara para aklama ile karşı',
        description: 'Emniyet kontrolü',
        content: ''
    },
    auth: {
        accountCreated: 'Hesap Kayıtlı',
        agreeText: '18 yaşında olduğumu ve okuduğumu onaylıyorum',
        agreeTos: 'hizmet şartları',
        alreadyRegistered: 'Zaten kayıtlı mı?',
        byLogin: 'Giriş ve şifre ile',
        bySocialNetworks: 'Sosyal ağlar üzerinden',
        createAccount: 'Hesap oluştur',
        forgotPassword: 'Şifrenizi mi unuttunuz?',
        loading: 'Yükleniyor...',
        login: 'Giriş',
        loginTitle: 'Site Yetkilendirme',
        maxLoginLength: 'Maksimum giriş uzunluğu:',
        maxPasswordLength: 'Maksimum Parola Uzunluğu:',
        minLoginLength: 'Minimum giriş uzunluğu:',
        minPasswordLength: 'Minimum Parola Uzunluğu:',
        passwordConfirmation: 'Şifreyi tekrarla',
        passwordsMustBeSame: 'Parolalar eşleşmelidir',
        pleaseWait: 'Lütfen bekleyin...',
        registration: 'Kayıt',
        registrationTitle: 'Siteye kaydolun',
        symbols: 'karakterler',
        yourLogin: 'Oturum açma',
        yourPassword: 'Şifreniz',
        yourEmail: 'E-postanız',
        logoutConfirm: 'Gerçekten çıkmak istiyor musun?',
        passwordRecovery: 'Parolayı sıfırla',
        newPassword: 'Yeni parola',
        copied: 'Kopyalandı',
        logout: 'Dışarı'
    },
    basic: {
        yes: 'Evet',
        no: 'Hayır',
        confirm: 'Onaylamak',
        confirmed: 'Upvoted',
        cancel: 'Yürürlükten kaldırıyorum',
        sending: 'Gönderiliyor...',
        sent: 'Gönder',
        save: 'Kaydet',
        create: 'Oluştur',
        or: 'veya',
        activate: 'Etkinleştir',
        deactivate: 'Devre dışı bırak',
        prevPage: 'Önceki sayfa',
        nextPage: 'Sonraki sayfa',
        of: 'dışarı',
        balance: 'Bakiye',
        copy: 'Kopya',
        copied: 'Kopyalandı'
    },
    bonus: {
        permanentBonus: 'Kalıcı bonus',
        tenPercent: 'Her depozito için%10!',
        more: 'Devamını oku',
        fastRegistration: 'Hızlı check-in',
        noVerification: 'doğrulama olmadan',
        fastWithdraws: 'Hızlı ödemeler',
        highLimits: 'yüksek limitler',
        loyaltyProgramm: 'Sadakat Programı',
        winCashback: "Her galibiyette 1.2'ye kadar!",
        levelSystem: 'Seviye sistemi',
        bigPercents: 'Büyük yüzdeler',
        payForWin: 'Kazanç için ödeme',
        butNoBets: 'bahisler için değil',
        affiliateProgramm: 'Ortaklık Programı',
        join: 'Katıl',
        revenueShare: '%40 Gelir Paylaşımı',
        anytimeWithdraw: 'her an çıktı',
        affiliateCabinet: 'Ortaklık Kabinesi',
        detailStat: 'detaylı istatistikler',
        title: 'Promosyon Kodu',
        promoEveryDay: 'VK grubumuzda her gün promosyon kodları',
        enterPromoCode: 'Promosyon kodunu girin'
    },
    fair: {
        title: 'Lisans ve Dürüstlük Garantisi',
        description: 'Herkes için adil oyun garantisi',
        fairDescription1: 'Brillx Casino, kullanıcıların oyunlarımızın dürüstlüğünden emin olmaları için bir güvenlik ortamı yaratmanın ne kadar önemli olduğunu anlar.',
        fairDescription2: 'Bu nedenle, kumarhanemizde, online casinolar için yazılım geliştiren en iyi şirketler tarafından sağlanan oyunları kullanıyoruz. Gibi şirketler arasında',
        fairDescription3: "EcoGRA ve IGC'nin kurucu üyesi olan Microgaming, özellikle çevrimiçi oyun dürüstlüğü, en sorumlu operatörleri seçmek ve oyuncuların çıkarlarını korumak için kumar endüstrisinde bir bütün olarak en yüksek standartları belirleyen diğer casino oyun üreticilerinin önündedir.",
        andOther: 've diğerleri',
        also: 'Ayrıca şirket tarafından oluşturulan oyunlar sağlamak',
        defaultCasino: 'Sıradan online casino',
        brillxCasino: 'Brillx Dürüstlük Kontrol Casino',
        defaultCasinoDescription1: 'Sen casino sunucusuna yazılır bir bahis yapmak',
        defaultCasinoDescription2: 'Verileriniz toplanır ve kumarhane sunucularında saklanır',
        defaultCasinoDescription3: 'Oyun dürüstlüğünü kontrol etmek imkansızdır — tüm algoritmalar casino tarafında kaydedilir ve kaydedilir',
        defaultCasinoDescription4: 'Bir galibiyet elde etmek için, uzun bir yetkilendirme prosedüründen geçmelisiniz',
        defaultCasinoDescription5: "Dengeyi hiçbir yerde yayınlanmadığı gibi söz konusu Casino'nun ödeme gücü",
        brillxCasinoDescription1: 'Oyuncu bahis sağlayıcılarından alınan verilerin bir kopyası blok zincirine kaydedilir ve değiştirilemez',
        brillxCasinoDescription2: 'İnsanlar için oyun istatistikleri şifrelenir ve oyuncu kişisel verilerine bağlı olarak blok zincirinde kaydedilir',
        brillxCasinoDescription3: 'Bütünlük kontrolü blok zincir teknolojisi ile yapılır',
        brillxCasinoDescription4: 'Para çekme işlemleri KYC standart yetkilendirme gerektirir, transfer ücreti yoktur',
        brillxCasinoDescription5: 'Açık raporlama ve oyuncu ödemeleri için büyük havuz'
    },
    game: {
        loading: 'Yükleniyor',
        lobby: 'Lobi',
        fullScreen: 'Tam ekran',
        deposit: 'Mevduat',
        betsAmount: 'Bahis Tutarı',
        totalRTP: 'Toplam RTP',
        averageBet: 'Ortalama bahis',
        topWin: 'En iyi galibiyet'
    },
    help: {
        title: 'Yardım ve Destek',
        description: 'En sık sorulan soruların cevapları ve müşteri hizmetleri ile iletişim.',
        connectTypes: 'Bizimle iletişim kurma yöntemleri',
        operatorsOnline: 'Çevrimiçi operatörler günde 24 saat',
        supportLive: 'Destek (Canlı)',
        onlineChat: 'Canlı sohbet',
        averageTimeChat: 'Ortalama yanıt süresi: 10 dakika',
        supportEmail: 'Müşteri Hizmetleri (E-posta)',
        averageTimeEmail: 'Ortalama yanıt süresi: 1 gün',
        generalQuestions: 'Genel konular',
        aboutSite: 'Site hakkında',
        aboutSiteContent: "Brillx uluslararası lisans ve isim ile bir online casino. Katalogda 6.000'den fazla oyun, çok çeşitli spor etkinlikleri ve çok sayıda mini oyun sunuyoruz. Birçok ödeme sistemini ve popüler kripto para birimlerinin çoğunu destekliyoruz.",
        bonus: 'Bonus',
        bonuses: 'Bonuslar',
        onPage: 'Sayfada',
        manyFreeBonuses: 'tamamen ücretsiz bakiyeniz için para almak için çeşitli yollar vardır!',
        bonus1: "Kayıt bonusu. Siteye kayıt olduktan sonra, davetiye kodunu girmek ve 10.00 RUB'a kadar almak için 60 dakikanız var.",
        bonus2: "Kayıt bonusu. Siteye kayıt olduktan sonra, davetiye kodunu girmek ve 10.00 RUB'a kadar almak için 60 dakikanız var.",
        bonus3: "Kayıt bonusu. Siteye kayıt olduktan sonra, davetiye kodunu girmek ve 10.00 RUB'a kadar almak için 60 dakikanız var.",
        bonus4: "Kayıt bonusu. Siteye kayıt olduktan sonra, davetiye kodunu girmek ve 10.00 RUB'a kadar almak için 60 dakikanız var.",
        promocodes: 'Promosyon kodları',
        inviteFriend: 'Bir arkadaşını davet et',
        balanceRefilling: 'Denge ikmali',
        wallet: 'Cüzdan',
        howToRefill: 'Dengeyi nasıl yenileyebilirim?',
        commissions: 'Komisyon',
        periodOfRefilling: 'Kayıt Şartları',
        withdrawal: 'Para Çekme',
        howToWithdraw: 'Nasıl para çekebilirim?',
        periodOfWithdrawal: 'Para çekme zaman çizelgesi',
        clickToRefill: 'Dengeyi yenilemek için düğmeye tıklayın',
        atRightCorner: "sitenin sağ üst köşesinde. Sizin için uygun bir ödeme yöntemi seçin, para yatırma tutarını girin ve Ödemeye Git'i tıklayın.",
        minimalRefilling: 'Minimum ikmal',
        commissionsOnPage: 'Tüm ödeme yöntemlerinin komisyonları sayfada listelenmiştir',
        clickToWithdraw: 'Hesabınızdan para çekmek için düğmeye tıklayın',
        averageTimeWithdraw: 'Tüm bulgular başvuru zamanından itibaren ortalama bir saat içinde işlenir.',
        allTransactions: 'Tüm işlemler anında işlenir. Gecikmeler yalnızca bakiyenizi doldurduğunuz ödeme yöntemiyle ilgili sorun yaşarsanız oluşabilir.',
        inCaseOfProblems: 'Herhangi bir sorun varsa, lütfen müşteri hizmetlerimizle iletişime geçin ve sorununuz hakkında en ayrıntılı bilgileri sağlayın.',
        atRightCornerWithdraw: "sitenin sağ üst köşesinde. Sizin için uygun bir para çekme yöntemi seçin, tutarı girin ve Ödemeye Git'i tıklayın.",
        minimalWithdrawal: 'Minimum para çekme',
        ifBigAmount: 'Para çekme miktarı büyükse, para çekme genellikle bir saate kadar biraz daha uzun sürebilir. Geceleri (00:00 - 09:00 MSC), bulgular birkaç saat sürebilir, bu sırada ödeme toplayıcısı para çekme taleplerini daha uzun süre işler.',
        sometimes: 'Çok nadir durumlarda, banka yüklemeleri vb. Nedeniyle, para çekme 24 saate kadar sürebilir. Para bu saatten sonra gelmezse, lütfen müşteri hizmetlerimizle iletişime geçin ve sorununuz hakkında en ayrıntılı bilgileri sağlayın.',
        checkCorrectPurse: 'Ayrıca çıktının ayrıntılarını doğru belirttiğinizden emin olun. Para çekme işlemine işaret ettiğin cüzdanı rahatlatmak için işlem geçmişinize girmeniz ve istediğiniz işlemi tıklayarak ayrıntıları açmanız gerekir.'
    },
    interface: {
        months: {
            jan: 'jan.',
            feb: 'şub.',
            mar: 'mar.',
            apr: 'nis.',
            may: 'mai.',
            jun: 'jun.',
            jul: 'jul.',
            aug: 'ağu.',
            sep: 'eyl.',
            oct: 'eki.',
            nov: 'kas.',
            dec: 'ara.'
        },
        year: 'g.',
        confirmPasswordRecovery: 'E-postanıza gönderilen bağlantıyı takip ederek parola sıfırlamayı onaylayın',
        passwordWasChanged: 'Şifre başarıyla değiştirildi, yeni verileri kullanmak için giriş yapabilirsiniz',
        confirmEmail: 'E-posta adresini bağlantıya göre onayla',
        emailWasConfirmed: 'E-posta adresi başarıyla onaylandı',
        accountLinked: 'Hesap başarıyla bağlandı',
        bonusGranted: 'Bonus alındı'
    },
    landing: {
        newGames: 'Yeni oyunlar',
        topGames: 'Popüler oyunlar',
        brillxSelect: "Brillx'in Seçimi",
        daylyLiveRtp: 'Canlı RTP - 24 saat',
        brillxCasino: 'Brillx kumarhane',
        casinoInfo1: "Memnuniyetiniz için özel hazırlanmış bir slot makinesi sitesi olan Brillx.GG'da çok çeşitli heyecan verici oyunlara göz atın. Binlerce birinci sınıf slot makinesi dünya çapında oyuncular tarafından kullanılabilir. İhtiyacınız olan büyük ikramiyeler, poker yuvaları veya temalı slot makineleri olsun, ihtiyacınız olan her şeye sahibiz!",
        casinoInfo2: 'Hemen check-in butonuna tıklayarak hızlı check-in kolaylığını yaşayın! Eğer önde gelen oyun sağlayıcılarından gerçek para slot makineleri oynarken anların keyfini çıkarın.',
        casinoInfo3: 'Hızlı ödemelere sahip kumarhanemiz ve her türlü slot makinesi varyasyonundan oluşan büyük bir seçim sizin için 7/24 çalışır - sadece bir oyun seçin ve oynamaya başlayın!',
        casinoInfo4: 'Online slot oyna',
        casinoInfo5: 'Eğlencenizi asla bozmayız, böylece bilgisayarınızdaki herhangi bir şey indirmeden tüm oyunlarımızı oynayabilirsiniz.',
        casinoInfo6: 'Binlerce yuvaya göz atın, beğendiğiniz oyunu seçin ve “OYNA” ı tıklayın - bu kadar kolay!',
        casinoInfo7: 'Casino slot oyunları en popüler oyunlardan biridir. Sadece alabileceğiniz büyük teşvikler değil, aynı zamanda heyecan verici grafikler, iyi müzik ve oyunun genel keyifli görsel efekti nedeniyle değil.',
        casinoInfo8: 'Hatta eğlence moduna geçerek slot makinelerimizin her birini deneyebilirsiniz - indirmeden ve Kayıt olmadan tüm slot makinelerimizi oynamanızı sağlayan inanılmaz bir özellik ücretsizdir! Cep telefonunuzdan, bilgisayarınızdan veya tabletinizden erişebilirsiniz. Bugün indirmeye gerek kalmadan ücretsiz olarak oynayabileceğiniz en iyi slot makinelerinden bazılarını keşfedin ve dünya çapındaki en iyi casino yuvalarında benzersiz bir macera yaşayın. Bugün en iyi atışınızı yapın ve kaderin sizi neyin beklediğine karar vermesine izin verin!',
        casinoInfo9: 'Ne bekliyorsun? Bugün şanslı günün olabilir!',
        casinoInfo10: 'Binlerce slot makinesi',
        casinoInfo11: 'İşte kumarhanemizde bulabileceğiniz farklı slot makineleri türleri şunlardır:',
        casinoInfo12: '3 makaralı yuva',
        casinoInfo13: '3 makaralı slot en iyi klasik veya geleneksel slot makineleri olarak bilinir. Makaralar, sembollerin basamaklı olduğu oyunda bulabileceğiniz dikey çizgilerdir. Bir heyecan arıyorsanız, 3 makaralı bir slot makinesini oynamayı deneyin! Oynayabileceğiniz oyunların bazıları Simya Kitabıdır, Watford FC Yuvası ve diğerleri.',
        casinoInfo14: '5 makaralı yuva',
        casinoInfo15: "5 makaralı bir slot oyunundan kombinasyonları kazanma şansını daha yüksek yakalayın! Makaralara her 3, 4 veya 5 karakter düştüğünde 5 makaralı slotların çoğu ödenir. Ayrıca, oyuna bağlı olarak kazanç çarpanları, bonus oyun turları veya bedava dönüşler elde edebilirsiniz. Oynayabileceğiniz 5 makaralı oyunlardan bazıları Vikings Go Berzerk, Legacy of Dead ve That's Rich.",
        casinoInfo16: 'Aşamalı yuvaları',
        casinoInfo17: 'Aşamalı yuvaları slot makineleri her türlü arasında bir hile vardır. Oyun ilerledikçe Jackpot boyutu artar. Sizi bekleyen büyük milyonlar var ve bugüne kadarki en zengin insanlardan biri olabilirsiniz!',
        casinoInfo18: 'Rulet',
        casinoInfo19: 'Rulet bir masada tek tek sayılar bahis bir oyundur, renk, sayı türü, veya kombinasyonlar. Şansınızı test edin ve ne bahis tekerleği vurursa kazanmak. Şu anda büyük gerçek zamanlı rulet oyunlarımızı oynayın!',
        casinoInfo20: 'Poker',
        casinoInfo21: 'Normal bir kumarhanede poker biliyorsanız, geniş online video poker oyunlarından yararlanarak hemen oynamayı deneyebilirsiniz. Bugün unutulmaz bir oyun yaşamaya hazır olun! Yüksek çözünürlüklü animasyon ve harika özel video turlarıyla, kesinlikle yeni bir galibiyet için geri döneceksiniz.',
        casinoInfo22: 'Bakara',
        casinoInfo23: "Bakara dünyanın en popüler oyunlarından biridir. 9'a en yakın yandan bahis yaparak bayi yenmek. Ayrıca, beraberlik varsa da kazanabilirsiniz. Kulağa heyecanlı geliyor, gerçekten mi? Bahislerinizi şimdi hazırlayın ve kazanan tarafta bahis yapın!",
        casinoInfo24: 'Blackjack',
        casinoInfo25: 'Eğer en yakın eli varsa blackjack, Eğer bayi yenebilir 21. Görkemli blackjack slot makinelerimizi oynarken bahsinizi ikiye katlama şansını yakalayın!',
        gamesProviders: 'Oyun Sağlayıcılar',
        showMore: 'Daha fazla göster',
        hideInfo: 'Bilgileri gizle',
        allGames: 'Tüm oyunlar',
        providers: 'Sağlayıcılar',
        new: 'Yeni',
        tournaments: 'Bonus Buy',
        slots: 'Yuvaları',
        roulette: 'Rulet',
        card: 'Kartlar',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Oyna', title: 'Live' },
    lobby: {
        providers: 'Sağlayıcılar',
        recent: 'Son',
        top: 'Popüler',
        new: 'Yeni',
        favorite: 'Favoriler',
        nothingFound: 'Ничего не найдено',
        play: 'OYNAMAK',
        demo: 'GÖSTERİ',
        forExample: 'Örneğin'
    },
    log: { date: 'Tarih', type: 'Operasyon', amount: 'Miktar', id: 'İD' },
    loyalty: {
        title: 'Sadakat Programı',
        description: 'Yerleştirilen her bahiste sadakat seviyesine bağlı nakit para iadesi alın',
        cabinet: 'Dolap',
        myCabinet: 'Benim ofisim.',
        payLog: 'Tahakkuk Günlüğü',
        log: 'Logue',
        level: 'Seviye',
        totalWins: 'Kazanan miktar',
        totalBets: 'Bahis tutarı',
        availableBalance: 'Mevcut bakiye',
        get: 'Götürün',
        cashback: 'Cashback',
        emptyHistory: 'Hikaye eksik.',
        operationID: 'İşlem Kimliği',
        amount: 'Miktar',
        date: 'Tarih',
        operationDate: 'Çalışma tarihi',
        operationType: 'Operasyon',
        balanceBefore: 'Dengeleme',
        balanceAfter: 'Sonrasında denge'
    },
    menu: {
        affProgramm: 'İş Ortakları',
        fairGame: 'Adil oyun',
        free: 'Ücretsiz',
        games: 'Oyunlar',
        lobby: 'Lobi',
        promo: 'Promosyon',
        loyaltyProgramm: 'Sadakat',
        promotions: 'Hisse Senetleri',
        support: 'Yardım Masası',
        settings: 'Ayarlar',
        history: 'Oyun geçmişi',
        more: 'Daha',
        profile: 'Profil',
        bonusCode: 'Promo-kodu'
    },
    noty: { error: 'Hata', success: 'Muvaffak' },
    profile: {
        wallet: 'Cüzdan',
        onSiteFrom: 'Sitede',
        totalBets: 'Bahis tutarı',
        betsCount: 'Bahis',
        totalWins: 'Kazanan miktar',
        bestWin: 'En iyi kazanma',
        emptyHistory: 'Hikaye eksik.',
        balanceAfter: 'Sonrasında denge'
    },
    partner: {
        maxCodeLength: 'Maksimum kod uzunluğu:',
        minCodeLength: 'Minimum kod uzunluğu:',
        maxNameLength: 'Maksimum isim uzunluğu:',
        minNameLength: 'Minimum isim uzunluğu:',
        symbols: 'karakterler',
        confirmDeleteCampgain: 'Kampanyayı gerçekten kaldırmak istiyor musunuz?',
        campgains: 'Kampanyalar',
        refresh: 'Yükselt',
        export: 'Aktar',
        campgainName: 'Kampanya adı',
        affiliateLink: 'Satış Ortaklığı bağlantısı',
        name: 'Başlık',
        creationDate: 'Oluşturulma tarihi',
        clicks: 'Geçişler',
        link: 'İlgi',
        manage: 'Yönetim',
        noCampgains: 'Etkin reklam kampanyası yok',
        namePlaceholder: 'Panelde görüntülenecek ad',
        general: 'General',
        mainAffiliateLink: 'Ana bağlı kuruluş bağlantısı',
        registrations: 'Kayıtlar',
        totalProfit: 'Ortak kâr',
        availableBalance: 'Mevcut bakiye',
        get: 'Götürün',
        emptyHistory: 'Hikaye eksik.',
        operationID: 'İşlem Kimliği',
        amount: 'Miktar',
        date: 'Tarih',
        operationDate: 'Çalışma tarihi',
        operationType: 'Operasyon',
        playerID: 'Oyuncu Kimliği',
        balanceAfter: 'Sonrasında denge',
        balanceBefore: 'Dengeleme',
        log: 'Tahakkuk Günlüğü',
        players: 'Oyuncular',
        registration: 'Kayıt',
        registrationDate: 'Kayıt tarihi',
        balance: 'Bakiye',
        noPlayers: 'Misafir oyuncu yok',
        totalBets: 'Bahis tutarı',
        totalDeps: 'Mevduat tutarı',
        totalWithdraws: 'Bulgular toplamı',
        registrationSource: 'Kayıt Kaynağı',
        mainLink: 'Ana bağlantı',
        player: 'Oyuncu',
        registrationsCount: 'Kayıt',
        bets: 'Punta ile perçinleme',
        profit: 'Kar',
        stat: 'İstatistik',
        totalStat: 'Genel istatistikler',
        partnerCabinet: 'İş Ortağının ofisi',
        partnerID: 'Ortak Kimliği'
    },
    privacy: {
        title: 'Gizlilik Politikası',
        description: 'Genel Hükümler ve Bilgiler',
        redaction: 'Editoryal tarafından',
        content: ''
    },
    promo: {
        linkVk: 'Bonus almak için bir VK hesabı bağlayın',
        completeAll: 'Tüm koşullara uygun ve',
        toBalanceForRepost: 'repost için denge üzerinde!',
        link: 'Bağlar',
        join: 'Giriş',
        allowNotifications: 'Bildirimlere izin ver',
        make: 'Yap',
        toProfileVkAccount: 'vK hesap profiline.',
        toOurVkGroup: 'VK grubumuza.',
        fromOurGroup: 'topluluğumuzdan.',
        repostToYourPage: 'girişini sayfasına yeniden yayınlayın.',
        mustBePublic: 'VK sayfanız herkese açık olmalı, yoksa yeniden gönderi için kontrol edemeyiz.',
        dontDelete: 'Bir gönderiyi yeniden gönderdikten 2 hafta sonra silerseniz, bonus tutarı bakiyeden düşülür.',
        joinAndGet: 'Bir gruba katılın ve',
        toBalance: 'denge üzerinde!',
        bonusGranted: 'Bonus başarıyla alındı!',
        momentalBonus: 'VK grubumuza katılın ve bakiyenizde anında bonus kazanın! VK profili herkese açık olmalıdır, yoksa abonelik olup olmadığını kontrol edemeyiz.',
        vkDoesntLinked: 'VK Hesabı Bağlı Değil',
        allowNotificationsAndGet: 'Topluluğumuzdan bildirimlere izin verin ve',
        sendAnyMessage: 'Bildirimlerin gönderilmesine izin veren VK grubumuza herhangi bir mesaj gönderin. Güzel bir sürpriz - periyodik olarak promosyonlar ve çeşitli bonuslar hakkında bilgi göndereceğiz!',
        rollAndGetBonus: 'Rulet Cruise ve ikramiye almak!',
        rollOncePer: 'Ruleti her 15 dakikada bir bükün ve',
        rollEveryDay: 'Tekerleği her gün kısıtlama olmaksızın döndürün!',
        untilNextTry: 'Bir sonraki girişim bırakılmadan önce',
        subscribeTg: 'İlk önce yeni promosyon kodlarını almak için telgraf kanalımıza kaydolun!',
        beFirst: 'Yeni bir promosyon kodu alan ilk kişilerden biri olun, yeni bonuslar ve yenilikler hakkında bilgi edinin!',
        referrals: 'Tavsiyeller',
        getPercent: "Arkadaşlarınızı davet edin ve kumarhane faydalarının% 40'ını kazanın!",
        copyLink: 'Bağlantıyı kopyala',
        inviteFriend: 'Bir arkadaşını davet et',
        getBonus: 'Bir bonus alın',
        affiliateProgramm: 'Ortaklık Programı',
        sendReview: 'Grubumuzdaki proje hakkında geri bildirim alın ve',
        bonusToDeposit: 'yatırma bonusu',
        upTo: 'kadar',
        makeReviews: 'VK grubumuzda geri bildirim bırakın, moderatör doğrulandıktan ve yayınlandıktan sonra bonus hesabınızda otomatik olarak etkinleştirilecektir.',
        makeReview: 'Geribildirim',
        ranks: 'Rangi',
        earnFromWin: 'Kazanç rütbeleri her galibiyetten artan para iadesi alacaksınız.',
        loyaltyProgramm: 'Sadakat Programı',
        writeMessage: 'Mesaj yaz',
        goToGroup: 'Gruba git',
        refreshing: 'Güncelleme...',
        refreshStatus: 'Yükselt',
        roll: 'Döndür',
        linkAccount: 'Bir hesap bağlama',
        checking: 'Kontrol ediliyor...',
        get: 'Alın'
    },
    promotions: {
        title: 'Hisse Senetleri ve Teklifleri',
        description: 'Mevcut hisselerimizde bonus alma fırsatını kaçırmayın',
        game: 'Oyun',
        minDeposit: 'Min. depozito',
        bonus: 'Bonus',
        addWager: 'Ekstra bahis',
        validUntil: 'Kaya kadar davranır',
        activation: 'Etkinleştirme',
        get: 'Alın',
        unlimit: 'sınırsız',
        oneTime: 'tek seferlik',
        forEveryDeposit: 'her depozito için',
        permanentBonus: 'Kalıcı bonus',
        any: 'herhangi'
    },
    responsibility: {
        title: 'Sorumlu oyun',
        description: 'Sorumlu Oyun İpuçları ve Kuralları',
        content: ''
    },
    settings: {
        incorrectEmail: 'E-posta adresi yanlış',
        bigFileSize: "Dosya boyutu 5 MB'ı geçmemelidir.",
        genders: { man: 'Erkekler', female: 'Kadınlar', other: 'Diğer' },
        requiredField: 'Zorunlu alan',
        dataSuccessChanged: 'Veriler başarıyla güncellendi',
        tfaLoginRequired: 'Kimlik doğrulayıcıyı ayarlamak için oturum açma ve şifreyi ayarlama',
        general: 'General',
        login: 'Giriş',
        vkProfile: 'VK profili',
        glProfile: 'Google Profili',
        okProfile: 'Profil Sınıf Arkadaşları',
        fbProfile: 'Facebook profili',
        security: 'Emniyet',
        alterLogin: 'Hesabınıza alternatif bir giriş ve şifre yöntemi ekleyin.',
        newPassword: 'Yeni parola',
        repeatPassword: 'Şifreyi tekrarla',
        saveActions: 'Eylemleri Kaydet',
        currentPassword: 'Geçerli parola',
        tfa: 'İki Faktörlü Kimlik Doğrulama',
        addTfa: 'Brillx hesabınızı ekstra güvenlik ile koruyun',
        tfaSettings: '2FA Ayarları',
        sessions: 'Oturumlar',
        device: 'Aygıt',
        region: 'Bölge',
        ipAddress: 'IP adresi',
        date: 'Tarih',
        session: 'Oturum',
        active: 'Aktif',
        closed: 'Kapalı',
        verification: 'Doğrulama',
        verificationDescription1: ' Servis seviyenizi iyileştirmek için belgelerin tanımlanmasını isteriz. Bu, geri yüklenirse hesabınızı güvende tutacak ve fiziksel hediyelerin doğru hedeflenen teslimatı sağlayacaktır.',
        verificationDescription2: 'Kabul edilebilir doğrulama yollarına kimlik, ehliyet veya pasaport bir fotoğraf sağlamaktır. Bu işlemi tamamladıktan sonra sizi e-posta ile bilgilendireceğiz.',
        frontPhoto: 'Kimlik belgesinin ön fotoğrafı',
        selectFile: 'Dosya seç',
        backPhoto: 'Kimlik belgesinin arka fotoğrafı',
        fileIsNotSelected: 'Dosya seçilmedi',
        name: 'İsim',
        surname: 'Soyadı',
        birthday: 'Doğum tarihi',
        gender: 'Cinsiyet',
        country: 'Ülke',
        city: 'Kent',
        yourName: 'Senin adın',
        yourSurname: 'Soyadın',
        verifyStatus1: 'Belgeler kontrol ediliyor...',
        verifyStatus2: 'Hesap Doğrulanmış',
        verifyStatus3: 'Belge Denetimi Hatası',
        verifyDescription1: 'Belgeler şu anda gözden geçirilmektedir, belgeler doğrulanır doğrulanmaz, hesabınız doğrulanacaktır!',
        verifyDescription2: 'Verileriniz güvenlik personeli tarafından doğrulandı ve onaylandı.',
        verifyDescription3: 'Ayrıntılar için lütfen teknik desteğe başvurun.',
        registrationCity: 'Kayıt Şehri',
        sendDocs: 'İnceleme için gönderin',
        affiliateCabinet: 'İş Ortağının ofisi'
    },
    tos: {
        title: 'Kullanıcı Sözleşmesi',
        description: 'Kullanım Şartları ve Kuralları',
        redaction: 'Editoryal tarafından',
        content: ''
    },
    wallet: {
        deposit: 'Mevduat',
        withdraw: 'Çıktı',
        history: 'Tarih',
        minDepositAmount: 'Minimum depozito üzerinden',
        minWithdrawAmount: 'Minimum çıkış',
        topMethods: 'Popüler',
        allMethods: 'Tüm yöntemler',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Diğer',
        success: 'Muvaffak',
        canceled: 'İptal Edildi',
        error: 'Hata',
        waiting: 'Bekliyor',
        processing: 'Uygulama işleniyor',
        incorrectWalletValue: 'Cüzdan numarası yanlış girildi',
        incorrectCardValue: 'Kart numarası yanlış girildi',
        selectTemplate: 'Şablon seç',
        withdrawCreated: 'Ödeme başvurusu oluşturuldu ve 24 saat içinde işlenecek',
        withdrawCanceled: 'Ödeme teklifi iptal edildi',
        firstWithdraw: 'Bu senin ilk sonucun',
        isDataCorrect: 'Veri girişinin doğru olduğundan emin misiniz?',
        payMethod: 'Ödeme yöntemi',
        withdrawMethod: 'Para çekme yöntemi',
        selectMethod: 'Bir yol seçin',
        selectOperator: 'Operatör seçin',
        refillAmount: 'İkmal',
        withdrawAmount: 'Para çekme tutarı',
        payTroubles: 'Ödeme konusunda sorun yaşarsanız deneyin',
        payHere: 'Burada ödeme yapın',
        pay: 'Ödemeye git',
        commission: 'Komisyon',
        onePayLimit: 'Tek İkmal Limiti',
        oneWithdrawLimit: 'Tek çıkış sınırı',
        emptyHistory: 'Hikaye eksik.',
        operationID: 'İşlem Kimliği',
        deposits: 'İkmal',
        withdraws: 'Bulgular',
        txAmount: 'İşlem tutarı',
        txDate: 'Çalışma tarihi',
        purse: 'Skor',
        typePurse: 'Hesap numarası',
        toGet: 'Almak için',
        createWithdraw: 'Uygulama oluşturma'
    },
    tfa: {
        tfa: 'İki Faktörlü Kimlik Doğrulama',
        typeYourPassword: 'Parolanızı Girin',
        enterPin: 'Uygulamadan 6 haneli kod girin',
        downloadApp: 'Uygulamayı indirin',
        downloadAndInstall: 'İndirme ve yükleme',
        forYourPhone: 'telefonunuz veya tabletiniz için',
        scanQR: 'qR kodunu tara',
        scanQRWithCamera: "Telefonunuzun kamerasıyla soldaki QR'yi tarayın",
        openWithLink: 'Bağlantıya göre aç',
        openInApp: 'Uygulamada aç',
        tfaManualEnter: '2FA tuşu (manuel giriş)'
    },
    pwa: {
        androidStep1: 'Chrome tarayıcıyı aç',
        androidStep1Description: "Başka bir tarayıcı kullanıyorsanız, bu sayfayı Chrome'da açın",
        androidStep2: 'Tarayıcı menüsünü tıklayın',
        androidStep2Description: 'Tarayıcı menüsü, tarayıcınızın sağ üst köşesinde bulunan üç noktadır',
        androidStep3: '"Ana Ekrana Ekle"yi seçin',
        androidStep3Description: 'Bilgi içeren bir pencere göreceksiniz ("Ana Ekrana Ekle"), burada "Ekle"yi seçmeniz gerekir, ardından uygulama cihazınızın masaüstüne eklenecektir',
        androidAlert: 'Adımlar tarayıcı sürümüne göre farklılık gösterebilir',
        iosStep1: 'Safari tarayıcısını aç',
        iosStep1Description: "Başka bir tarayıcı kullanıyorsanız, bu sayfayı Safari'de açın",
        iosStep2: '"Paylaş"a tıklayın',
        iosStep2Description: 'Ekranın altındaki "Paylaş" düğmesine tıklayın, ardından parametre seçim penceresi açılacaktır',
        iosStep3: '"Ana Ekrana Ekle"yi seçin',
        iosStep3Description: '"Ana Ekrana Ekle"yi seçin ve "Ekle"ye tıklayın',
        alert: 'Adımlar tarayıcı sürümüne göre farklılık gösterebilir',
        installApp: 'Brillx uygulamasını yükleyin',
        back: 'Geri',
        app: 'Mobil uygulama',
        installAppForMobile: 'iOS ve Android için yeni kullanışlı Brillx uygulamasını yükleyin',
        install: 'Yükle'
    },
    undefined: '???'
};