import React from 'react';
import Loader from '../assets/svg/loader.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconStatic from '../components/IconStatic';
import { Redirect, withRouter } from "react-router-dom";
import defaultPoster from '../assets/png/poster.png';

import StaticLobbyGames from './static/LobbyGames';

class Games extends React.Component {
    constructor(props) {
        super(props);

        if (!this.props.t.state.Lobby.loadedGames.length && this.props.t.state.Header.gamesList.length)
            this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Header.gamesList.filter((item, index) => (index < this.props.t.state.Lobby.loadedGames.length + this.props.t.config.limits.Lobby.gamesPerPage && (!this.props.t.state.Lobby.providerSelected || this.props.t.state.Lobby.providerSelected === item.providerID) && (!this.props.t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => parseInt(category) === this.props.t.state.Lobby.categorySelected))) && (!this.props.t.state.Lobby.filter || (this.props.t.state.Lobby.filter === 2 && item.params.top) || (this.props.t.state.Lobby.filter === 3 && item.params.new) || (this.props.t.state.Lobby.filter === 1 && this.props.t.Interface.inArray(item.id, this.props.t.state.Lobby.recentGames)) || (this.props.t.state.Lobby.filter === 4 && this.props.t.Interface.inArray(item.id, this.props.t.state.Lobby.favoriteGames))))), 'loadedGames');

    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.favoriteGames, this.props.t.state.Lobby.recentGames, this.props.t.state.redirect, this.props.t.state.Lobby.loadedGames.length, this.props.t.state.Header.gamesList.length, this.props.t.state.Lobby, this.props.t.state.lang], [this.pState.Lobby.favoriteGames, this.pState.Lobby.recentGames, this.pState.redirect, this.pState.Lobby.loadedGames.length, this.pState.Header.gamesList.length, this.pState.Lobby, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    fetchMoreData = () => {
        setTimeout(() => {
            if (this.props.t.state.Header.gamesList.length) {

                let filteredGames = this.props.t.state.Header.gamesList.filter((item, index) => ((!this.props.t.state.Lobby.providerSelected || this.props.t.state.Lobby.providerSelected === item.providerID) && (!this.props.t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => parseInt(category) === this.props.t.state.Lobby.categorySelected))) && (!this.props.t.state.Lobby.search || item.params.name.toUpperCase().indexOf(this.props.t.state.Lobby.search.toUpperCase()) !== -1) && (!this.props.t.state.Lobby.filter || (this.props.t.state.Lobby.filter === 2 && item.params.top) || (this.props.t.state.Lobby.filter === 3 && item.params.new) || (this.props.t.state.Lobby.filter === 1 && this.props.t.Interface.inArray(item.id, this.props.t.state.Lobby.recentGames)) || (this.props.t.state.Lobby.filter === 4 && this.props.t.Interface.inArray(item.id, this.props.t.state.Lobby.favoriteGames)))));
                let tempFilteredGames = JSON.parse(JSON.stringify(this.props.t.state.Lobby.loadedGames));
                let tempCount = tempFilteredGames.length;



                filteredGames.forEach((game, index) => {
                    if (index >= tempCount && index < tempCount + this.props.t.config.limits.Lobby.gamesPerPage)
                        tempFilteredGames.push(game);
                });

                if (filteredGames.length > tempFilteredGames.length)
                    this.props.t.updateState(this.props.t, 'Lobby', true, 'hasMore');
                else
                    this.props.t.updateState(this.props.t, 'Lobby', false, 'hasMore');


                this.props.t.updateState(this.props.t, 'Lobby', tempFilteredGames, 'loadedGames');
            }
        }, 500);
    };

    render() {
        return (
            <>
                {this.props.t.state.redirect ? <Redirect to={this.props.t.state.redirect} /> : <></>}
                {!this.props.t.state.Header.gamesList.length || this.props.t.state.Lobby.loading ? (
                    <>
                        <StaticLobbyGames t={this.props.t} />
                    </>
                ) : !this.props.t.state.Lobby.loadedGames.length ? (
                    <div className={this.props.t.styles['nothingFound']}>
                        {this.props.t.Interface.getText(this.props.t, 'lobby', 'nothingFound')}
                    </div>
                ) : <InfiniteScroll
                    dataLength={this.props.t.state.Lobby.loadedGames.length}
                    next={this.fetchMoreData}
                    hasMore={this.props.t.state.Lobby.hasMore}
                    className={this.props.t.styles['gamesList']}
                    loader={<div className={this.props.t.styles['loaderBlock']}>
                        <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                    </div>}
                    scrollableTarget="scrollArea"
                >
                    {this.props.t.state.Lobby.loadedGames.map((game, index) =>
                        (!this.props.t.state.Lobby.providerSelected || this.props.t.state.Lobby.providerSelected === game.providerID) && (!this.props.t.state.Lobby.categorySelected || game.categoryID.split(',').some((category => parseInt(category) === this.props.t.state.Lobby.categorySelected))) && (!this.props.t.state.Lobby.filter || (this.props.t.state.Lobby.filter === 2 && game.params.top) || (this.props.t.state.Lobby.filter === 3 && game.params.new) || (this.props.t.state.Lobby.filter === 1 && this.props.t.Interface.inArray(game.id, this.props.t.state.Lobby.recentGames)) || (this.props.t.state.Lobby.filter === 4 && this.props.t.Interface.inArray(game.id, this.props.t.state.Lobby.favoriteGames))) ?
                            (<div key={index} className={this.props.t.styles['item']}>
                                <div className={this.props.t.styles['gameWrapper']}>
                                    <div className={this.props.t.styles['gameBlock']}>
                                        <div style={{ backgroundImage: 'url(' + game.params.poster + '), url(' + defaultPoster + ')' }} className={this.props.t.styles['imgBlock']}></div>
                                        <div className={this.props.t.styles['gameLabels']}>
                                            <div className={[this.props.t.styles['btn'], !game.params.new ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                                                New
                                            </div>
                                            <div className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen'], !game.params.top ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                                                Top
                                            </div>
                                        </div>
                                        <div className={this.props.t.styles['gameOverlay']}>
                                            <div className={this.props.t.styles['connected']}>
                                                <IconStatic t={this.props.t} name='person' /><span>{game.connected}</span>
                                            </div>
                                            <div onClick={() => { !this.props.t.state.userID ? this.props.t.Interface.requireAuth(this.props.t, false, '/lobby') : this.props.t.Interface.inArray(game.id, this.props.t.state.Lobby.favoriteGames) ? this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.favoriteGames.filter(gameID => gameID !== game.id), 'favoriteGames') : this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.favoriteGames.concat(game.id), 'favoriteGames'); this.props.t.state.userID && this.props.t.Interface.LobbyUpdateCData(this.props.t) }} className={[this.props.t.styles['favorite'], this.props.t.Interface.inArray(game.id, this.props.t.state.Lobby.favoriteGames) ? this.props.t.styles['active'] : ''].join(' ')}>
                                                <IconStatic t={this.props.t} name='heart' />
                                            </div>
                                            <button onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/game/' + game.id + '/real') : this.props.t.Interface.requireAuth(this.props.t, false, '/game/' + game.id + '/real')} className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen'], this.props.t.styles['playReal']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'lobby', 'play')}</button>
                                            <span onClick={() => this.props.t.doRoute('/game/' + game.id + '/demo')} className={this.props.t.styles['playDemo']}>{this.props.t.Interface.getText(this.props.t, 'lobby', 'demo')}</span>
                                        </div>
                                    </div>
                                    <div className={this.props.t.styles['gameDescription']}>
                                        <h2 className={this.props.t.styles['title']}><span>{game.params.name}</span></h2>
                                        <p className={this.props.t.styles['stats']}><span>RTP: {game.params.rtp}%</span> <span className={this.props.t.styles['volatility']}> / Volatility: {game.params.volatility}</span></p>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            (<></>)
                    )}
                </InfiniteScroll>}
            </>
        )
    }

}

export default withRouter(Games);