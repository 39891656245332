import React from 'react';
import Loader from '../../assets/svg/loader.svg';

class GameLoader extends React.Component {
    constructor(props) {
        super(props);
    }
    
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <div className={this.props.t.styles['staticGameLoader']}>
                <div className={this.props.t.styles['loaderBlock']}>
                    <img alt={this.props.t.config.name} className={this.props.t.styles['logo']} src={this.props.t.logo}></img>
                    <div className={this.props.t.styles['spinnerBlock']}>
                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default GameLoader;