import React from 'react';
import { Spinner } from 'reactstrap';
import IconStatic from '../components/IconStatic';
import defaultPosterPlaceholder from '../assets/png/Landing/posterPlaceholder.png';
import defaultPoster from '../assets/png/posterLight.png';
import LineGames from '../assets/svg/Landing/line.svg';
import TopGames from '../assets/svg/Landing/top.svg';
import BrillxGames from '../assets/svg/Landing/brillxchoice.svg';
import Providers from '../assets/svg/Landing/providers.svg';
import { isIOS, isAndroid } from "react-device-detect";

export default class LandingGamesNew extends React.Component {
    constructor(props) {
        super(props);
        this.gamesList = [];
        this.contentLoaded = false;
        if (!this.props.t.state.Landing.newGames.length) {
            this.gamesList = [];
            for (let i = 0; i <= 32; i++)
                this.gamesList.push({ id: i, providerID: 1, connected: 100, params: { "name": "...", "poster": defaultPosterPlaceholder } });
        } else {
            this.contentLoaded = true;
            this.gamesList = this.props.t.state.Landing.newGames;
        }

        if (window.matchMedia('(display-mode: standalone)').matches)
            this.isStandalone = true;
        else
            this.isStandalone = false;
    }

    componentDidUpdate() {
        if (!this.props.t.state.Landing.newGames.length) {
            this.gamesList = [];
            for (let i = 0; i <= 32; i++)
                this.gamesList.push({ id: i, providerID: 1, connected: 100, params: { "name": "...", "poster": defaultPosterPlaceholder } });
        } else {
            if (this.gamesList.length !== this.props.t.state.Landing.newGames.length)
                setTimeout(() => this.forceUpdate(), 100);

            this.contentLoaded = true;
            this.gamesList = this.props.t.state.Landing.newGames;
        }
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.providers.length, this.props.t.state.Landing.infoOpen, this.props.t.state.Landing.newGames.length, this.props.t.state.lang, this.props.t.state.width], [this.pState.Lobby.providers.length, this.pState.Landing.infoOpen, this.pState.Landing.newGames.length, this.pState.lang, this.pState.width]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={[this.props.t.styles['section'], this.props.t.styles['landingGamesSection']].join(' ')}>

                    <div className={this.props.t.styles['gamesBoxTitle']}>
                        <div className={this.props.t.styles['title']}>
                            <img src={LineGames}></img>
                            <h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'newGames')}</h4>
                        </div>
                    </div>

                    <div className={this.props.t.styles['gamesBox']}>

                        {!this.gamesList.length ? (
                            <>
                                <Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '0 auto' }} />
                            </>
                        ) : this.gamesList.slice(0, this.props.t.state.width > 1180 ? 5 : this.props.t.state.width > 700 ? 4 : 6).map(item => (
                            <div key={item.id} className={this.props.t.styles['item']}>
                                <a href="#" onClick={() => this.props.t.doRoute('/game/' + item.id + (this.props.t.state.userID ? '/real' : '/demo'))} className={this.props.t.styles['card']}>
                                    <div className={this.props.t.styles['thumb']}>
                                        {this.contentLoaded && <>
                                            <div className={this.props.t.styles['online']}>
                                                <IconStatic t={this.props.t} name="person" /> {item.connected}
                                            </div>
                                            <div className={[this.props.t.styles['badge'], this.props.t.styles['new']].join(' ')}>
                                                NEW
                                            </div></>}
                                        <div className={this.props.t.styles['img']} style={{ backgroundImage: 'url(' + item.params.poster + '), url(' + defaultPoster + ')' }}></div>
                                    </div>
                                    <div className={this.props.t.styles['bottom']}>
                                        <div className={this.props.t.styles['name']}>
                                            {item.params.name}
                                        </div>
                                        <div className={this.props.t.styles['provider']}>
                                            {this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID) ? this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID).name : '...'}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div className={this.props.t.styles['gamesBoxTitle']}>
                        <div className={this.props.t.styles['title']}>
                            <img src={BrillxGames}></img>
                            <h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'brillxSelect')}</h4>
                        </div>
                    </div>

                    <div className={this.props.t.styles['gamesBox']}>

                        {!this.gamesList.length ? (
                            <>
                                <Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '0 auto' }} />
                            </>
                        ) : this.gamesList.slice(6, 6 + (this.props.t.state.width > 1180 ? 5 : this.props.t.state.width > 700 ? 4 : 6)).map(item => (
                            <div key={item.id} className={this.props.t.styles['item']}>
                                <a href="#" onClick={() => this.props.t.doRoute('/game/' + item.id + (this.props.t.state.userID ? '/real' : '/demo'))} className={this.props.t.styles['card']}>
                                    <div className={this.props.t.styles['thumb']}>
                                        {this.contentLoaded && <>
                                            <div className={this.props.t.styles['online']}>
                                                <IconStatic t={this.props.t} name="person" /> {item.connected}
                                            </div>
                                            <div className={[this.props.t.styles['badge'], this.props.t.styles['new']].join(' ')}>
                                                NEW
                                            </div></>}
                                        <div className={this.props.t.styles['img']} style={{ backgroundImage: 'url(' + item.params.poster + '), url(' + defaultPoster + ')' }}></div>
                                    </div>
                                    <div className={this.props.t.styles['bottom']}>
                                        <div className={this.props.t.styles['name']}>
                                            {item.params.name}
                                        </div>
                                        <div className={this.props.t.styles['provider']}>
                                            {this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID) ? this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID).name : '...'}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div className={this.props.t.styles['gamesBoxTitle']}>
                        <div className={this.props.t.styles['title']}>
                            <img src={LineGames}></img>
                            <h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'daylyLiveRtp')}</h4>
                        </div>
                    </div>

                    <div className={this.props.t.styles['liveRtpGamesBox']}>

                        {!this.gamesList.length ? (
                            <>
                                <Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '0 auto' }} />
                            </>
                        ) : this.gamesList.slice(6, 10).map(item => (
                            <div key={item.id} className={this.props.t.styles['item']}>
                                <a href="#" onClick={() => this.props.t.doRoute('/game/' + item.id + (this.props.t.state.userID ? '/real' : '/demo'))} className={this.props.t.styles['card']}>
                                    <div className={this.props.t.styles['left']}>
                                        <div className={this.props.t.styles['thumb']}>
                                            <div className={this.props.t.styles['img']} style={{ backgroundImage: 'url(' + item.params.poster + '), url(' + defaultPoster + ')' }}></div>
                                        </div>
                                        <div className={this.props.t.styles['bottom']}>
                                            <div className={this.props.t.styles['name']}>
                                                {item.params.name}
                                            </div>
                                            <div className={this.props.t.styles['provider']}>
                                                {this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID) ? this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID).name : '...'}
                                            </div>
                                        </div>
                                    </div>
                                    {this.contentLoaded && <div className={this.props.t.styles['right']}><div className={[this.props.t.styles['badge'], this.props.t.styles['red']].join(' ')}>{item.params.rtp + item.connected}% <IconStatic t={this.props.t} name="arrow-pointer" /></div><div className={this.props.t.styles['badge']}>{item.params.rtp}%</div></div>}
                                </a>
                            </div>
                        ))}
                    </div>

                    <div className={this.props.t.styles['gamesBoxTitle']}>
                        <div className={this.props.t.styles['title']}>
                            <img src={TopGames}></img>
                            <h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'topGames')}</h4>
                        </div>
                    </div>

                    <div className={this.props.t.styles['gamesBox']}>

                        {!this.gamesList.length ? (
                            <>
                                <Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '0 auto' }} />
                            </>
                        ) : this.gamesList.slice(12, 12 + (this.props.t.state.width > 1180 ? 4 : this.props.t.state.width > 700 ? 6 : 6)).map(item => (
                            <div key={item.id} className={[this.props.t.styles['item'], this.props.t.styles['big']].join(' ')}>
                                <a href="#" onClick={() => this.props.t.doRoute('/game/' + item.id + (this.props.t.state.userID ? '/real' : '/demo'))} className={this.props.t.styles['card']}>
                                    <div className={this.props.t.styles['thumb']}>
                                        {this.contentLoaded && <>
                                            <div className={this.props.t.styles['online']}>
                                                <IconStatic t={this.props.t} name="person" /> {item.connected}
                                            </div>
                                            <div className={[this.props.t.styles['badge'], this.props.t.styles['new']].join(' ')}>
                                                NEW
                                            </div></>}
                                        <div className={this.props.t.styles['img']} style={{ backgroundImage: 'url(' + item.params.poster + '), url(' + defaultPoster + ')' }}></div>
                                    </div>
                                    <div className={this.props.t.styles['bottom']}>
                                        <div className={this.props.t.styles['name']}>
                                            {item.params.name}
                                        </div>
                                        <div className={this.props.t.styles['provider']}>
                                            {this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID) ? this.props.t.Interface.getGameProviderByID(this.props.t, item.providerID).name : '...'}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>

                    {(isIOS || isAndroid) && this.props.t.state.width <= 900 ? <button onClick={() => this.props.t.doRoute('/pwa')} type="button" className={[this.props.t.styles['mobileAppContainer'], this.isStandalone ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                        {this.props.t.Interface.getText(this.props.t, 'pwa', 'installApp')}
                        <div className={this.props.t.styles['icons']}>
                            <IconStatic t={this.props.t} name="apple" />
                            <IconStatic t={this.props.t} name="android" />
                        </div>
                    </button> : <></>}

                    <div className={this.props.t.styles['casinoInfo']}>
                        <div className={[this.props.t.styles['infoWrapper'], this.props.t.state.Landing.infoOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                            <div className={this.props.t.styles['twoColumns']}>
                                <div className={this.props.t.styles['info']}>
                                    <h1>{this.props.t.Interface.getText(this.props.t, 'landing', 'brillxCasino')}</h1>
                                    <p>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo1')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo2')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo3')}
                                    </p>
                                    <h1>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo4')}</h1>
                                    <p>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo5')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo6')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo7')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo8')}
                                        <br></br> <br></br>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo9')}
                                    </p>

                                </div>
                                <div className={this.props.t.styles['info']}>
                                    <h1>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo10')}</h1>
                                    <p>
                                        {this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo11')}
                                    </p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo12')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo13')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo14')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo15')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo16')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo17')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo18')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo19')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo20')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo21')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo22')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo23')}</p>
                                    <ul><li dir="ltr"><h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo24')}</h4></li></ul>
                                    <p dir="ltr">{this.props.t.Interface.getText(this.props.t, 'landing', 'casinoInfo25')}</p>
                                </div>
                            </div>
                        </div>
                        <div className={this.props.t.styles['showMore']}><button onClick={() => { this.props.t.updateState(this.props.t, 'Landing', !this.props.t.state.Landing.infoOpen, 'infoOpen') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button">{!this.props.t.state.Landing.infoOpen ? this.props.t.Interface.getText(this.props.t, 'landing', 'showMore') : this.props.t.Interface.getText(this.props.t, 'landing', 'hideInfo')}</button></div>
                    </div>

                    <div className={this.props.t.styles['gamesBoxTitle']}>
                        <div className={this.props.t.styles['title']}>
                            <img src={Providers}></img>
                            <h4>{this.props.t.Interface.getText(this.props.t, 'landing', 'gamesProviders')}</h4>
                        </div>
                    </div>

                    <div className={this.props.t.styles['providersBox']}>
                        {this.props.t.state.Lobby.providers.map((item, index) => (
                            <div key={index} className={this.props.t.styles['item']}><a onClick={() => this.props.t.doRoute('/lobby/' + item.id + '/0')} className={this.props.t.styles['card']}><div className={this.props.t.styles['thumb']}><img draggable="false" src={require("../assets/svg/Landing/providers/" + item.name.toLowerCase().split("'").join('').split(' ').join('') + ".svg")}></img></div></a></div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

}