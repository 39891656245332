// OLD BY 28.07.2021

import React from 'react';
import Icon from '../components/Icon';
import ConfirmModal from './modals/Confirm';
import Avatar from 'react-avatar';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class SideProfile extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.t.styles['sideUserProfile']}>
                <div className={this.props.t.styles['userBlock']}>
                    <div className={this.props.t.styles['userAvatar']}>
                        <button onClick={(ev) => { this.props.t.updateState(this.props.t, 'RightBar', !this.props.t.state.RightBar.open, 'open') }} className={this.props.t.styles['closeBtn']}><Icon t={this.props.t} name="close" /></button> <a className={this.props.t.styles['avatar']}>
                            <Avatar round="20px" className={this.props.t.styles['avatar']} name={this.props.t.state.userData ? this.props.t.state.userData.settings.name : '#' + this.props.t.state.userID} color={Avatar.getRandomColor(this.props.t.state.userID.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} />
                        </a>
                    </div>
                    <div className={this.props.t.styles['userName']}>
                        <div className={this.props.t.styles['nickname']}>
                            {this.props.t.state.userData ? this.props.t.state.userData.settings.name : '...'}
                        </div>
                        <CopyToClipboard text={'#' + this.props.t.state.userID || '0'}
                            onCopy={() => { this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard); this.props.t.updateState(this.props.t, 'clipboardModule', 'sideProfileID'); setTimeout(() => { this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard); this.props.t.updateState(this.props.t, 'clipboardModule', false) }, 500) }}>
                            <button className={this.props.t.styles['userId']} type="button"><span className={this.props.t.styles['yourId']}>Ваш ID:</span> # {this.props.t.state.userID || '...'}
                                <div className={this.props.t.styles['userIconCopy']}>
                                    <Icon t={this.props.t} name='copy' />
                                </div></button>
                        </CopyToClipboard>
                        <div className={[this.props.t.styles['copyTooltip'], this.props.t.state.clipboard && this.props.t.state.clipboardModule === "sideProfileID" ? this.props.t.styles['visible'] : ''].join(' ')}>
                            Скопировано
                                                 </div>
                    </div>
                </div>
                <ul className={this.props.t.styles['profileNav']}>
                    <li>
                        <a href="#" onClick={() => this.props.t.doRoute('/profile')}>
                            <div className={this.props.t.styles['itemIcon']}>
                                <Icon t={this.props.t} name='person' />
                            </div>Профиль</a>
                    </li>
                    <li>
                        <a href="#" onClick={() => this.props.t.doRoute('/settings')}>
                            <div className={this.props.t.styles['itemIcon']}>
                                <Icon t={this.props.t} name='settings' />
                            </div>Настройки</a>
                    </li>
                    <li>
                        <a href="#" onClick={() => this.props.t.doRoute('/fair')}>
                            <div className={this.props.t.styles['itemIcon']}>
                                <Icon t={this.props.t} name='fairness' />
                            </div>Честная игра</a>
                    </li>
                </ul><button onClick={() => this.props.t.updateState(this.props.t, 'modal', { show: true, content: ConfirmModal, className: this.props.t.styles['confirmModal'], callback: this.props.t.Interface.doLogOut, confirmText: 'Вы действительно хотите выйти с аккаунта?' })} className={[this.props.t.styles['btn'], this.props.t.styles['btn-logout']].join(' ')} type="button">
                    <div className={this.props.t.styles['itemIcon']}>
                        <Icon t={this.props.t} name='logout' />
                    </div>Выход</button>
            </div>
        )
    }
}


