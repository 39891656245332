import React from 'react';
import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import Loader from '../assets/svg/loader.svg';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Avatar from 'react-avatar';

export default class Landing extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.t.updateState(this.props.t, 'location', 'profile');

        if (!this.props.t.state.userID)
            this.props.t.doRoute('/');
        else {
            this.props.t.Interface.ProfileGetLog(this.props.t);
            this.props.t.Interface.ProfileGetStats(this.props.t);
        }

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'profile') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'Log', false, 'data');
        this.props.t.updateState(this.props.t, 'location', '/');
    }

    switchPage(page) {
        if (!isFinite(page))
            page = 0;
        else if (page < 1)
            page = 0;
        else if (page > Math.ceil(this.props.t.state.Log.total / this.props.t.state.Log.perPage))
            page = parseInt(Math.ceil(this.props.t.state.Log.total / this.props.t.state.Log.perPage) - 1);
        else
            page = parseInt(page - 1);

        this.props.t.updateState(this.props.t, 'Log', page, 'currentPage');
        this.props.t.Interface.ProfileGetLog(this.props.t);
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={[this.props.t.styles['container'], this.props.t.styles['logSection']].join(' ')}>

                        <div className={this.props.t.styles['profileHead']}>
                            <div className={this.props.t.styles['headBlock']}>
                                <div className={this.props.t.styles['avatar']}> <Avatar round="20px" className={this.props.t.styles['avatar']} name={!this.props.t.state.userID ? 'Guest' : this.props.t.state.userData ? this.props.t.state.userData.settings.name : '#' + this.props.t.state.userID} color={Avatar.getRandomColor(!this.props.t.state.userID ? 0 : this.props.t.state.userID.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} /></div>
                                <h2>{this.props.t.state.userData ? this.props.t.state.userData.settings.name : '...'}</h2>
                                <div className={this.props.t.styles['date']}>
                                    {this.props.t.Interface.getText(this.props.t, 'profile', 'onSiteFrom')} {this.props.t.state.userData ? this.props.t.Interface.localeDate(parseInt(this.props.t.state.userData.regdata.date / 1000), true, this.props.t) : '...'}
                                </div>
                            </div>
                            {this.props.t.state.Profile.process || !this.props.t.state.Profile.stat ?
                                <></>
                                : <>
                                    <div className={this.props.t.styles['statsBlock']}>
                                        <div className={this.props.t.styles['item']}>
                                            <div className={this.props.t.styles['label']}>
                                                {this.props.t.Interface.getText(this.props.t, 'profile', 'totalBets')}
                                            </div>
                                            <div className={[this.props.t.styles['value'], this.props.t.styles['neutral']].join(' ')}>
                                                <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(this.props.t.state.Profile.stat.bets)}
                                            </div>
                                        </div>
                                        <div className={this.props.t.styles['item']}>
                                            <div className={this.props.t.styles['label']}>
                                                {this.props.t.Interface.getText(this.props.t, 'profile', 'betsCount')}
                                            </div>
                                            <div className={[this.props.t.styles['value'], this.props.t.styles['neutral']].join(' ')}>
                                                {this.props.t.state.Profile.stat.betsCount}
                                            </div>
                                        </div>
                                        <div className={this.props.t.styles['item']}>
                                            <div className={this.props.t.styles['label']}>
                                                {this.props.t.Interface.getText(this.props.t, 'profile', 'totalWins')}
                                            </div>
                                            <div className={[this.props.t.styles['value'], this.props.t.styles['positive']].join(' ')}>
                                                <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(this.props.t.state.Profile.stat.wins)}
                                            </div>
                                        </div>
                                        <div className={this.props.t.styles['item']}>
                                            <div className={this.props.t.styles['label']}>
                                                {this.props.t.Interface.getText(this.props.t, 'profile', 'bestWin')}
                                            </div>
                                            <div className={[this.props.t.styles['value'], this.props.t.styles['positive']].join(' ')}>
                                                <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(this.props.t.state.Profile.stat.biggest)}
                                            </div>
                                        </div>
                                    </div></>}
                        </div>
                        <div className={this.props.t.styles['logContainer']}>
                            <div className={this.props.t.styles['logComponent']}>
                                <div className={this.props.t.styles['logWrapper']}>
                                    <div className={[this.props.t.styles['withPager'], this.props.t.state.Log.process ? this.props.t.styles['isLoading'] : ''].join(' ')}>
                                        <div className={[this.props.t.styles['list'], !this.props.t.state.Log.data ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                                            {this.props.t.state.Log.data && !this.props.t.state.Log.data.length ?
                                                <div className={this.props.t.styles['empty']}><h4>N/A</h4>{this.props.t.Interface.getText(this.props.t, 'profile', 'emptyHistory')}</div>
                                                : !this.props.t.state.Log.data ?
                                                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img alt="" src={Loader} className={this.props.t.styles['rotating']} /></span>
                                                    : this.props.t.state.width <= 900 ?
                                                        (
                                                            <PerfectScrollbar className={this.props.t.styles['logScroll']}>
                                                                <table className={this.props.t.styles['logTable']}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th> <span onClick={() => { this.props.t.state.Log.order === "nameDesc" ? this.props.t.updateState(this.props.t, 'Log', "nameAsc", "order") : this.props.t.state.Log.order === "nameAsc" ? this.props.t.updateState(this.props.t, 'Log', "none", "order") : this.props.t.updateState(this.props.t, 'Log', "nameDesc", "order"); this.props.t.Interface.ProfileGetLog(this.props.t); }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'id')} <Icon t={this.props.t} name={this.props.t.state.Log.order === "nameDesc" ? "sort-desc" : this.props.t.state.Log.order === "nameAsc" ? "sort-asc" : "sort-off"} /></span></th>
                                                                            <th> <span className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'type')}</span> </th>
                                                                            <th> <span onClick={() => { this.props.t.state.Log.order === "amountDesc" ? this.props.t.updateState(this.props.t, 'Log', "amountAsc", "order") : this.props.t.state.Log.order === "amountAsc" ? this.props.t.updateState(this.props.t, 'Log', "none", "order") : this.props.t.updateState(this.props.t, 'Log', "amountDesc", "order"); this.props.t.Interface.ProfileGetLog(this.props.t); }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'amount')} <Icon t={this.props.t} name={this.props.t.state.Log.order === "amountDesc" ? "sort-desc" : this.props.t.state.Log.order === "amountAsc" ? "sort-asc" : "sort-off"} /></span></th>
                                                                            <th> <span className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'date')}</span> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.props.t.state.Log.data.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td><span>#{item.id}</span></td>
                                                                                <td>
                                                                                    <div className={this.props.t.styles['system']}>{this.props.t.Interface.getOperationByType(item, this.props.t)}</div>
                                                                                </td>
                                                                                <td className={item.amount > 0 ? this.props.t.styles['positive'] : this.props.t.styles['default']}> <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(item.amount)}</td>
                                                                                <td>
                                                                                    <div className={this.props.t.styles['date']}>{this.props.t.Interface.localeDate(item.date, this.props.t.state.width <= 900 ? true : false, this.props.t)}</div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </PerfectScrollbar>)
                                                        :
                                                        (<table className={this.props.t.styles['logTable']}>
                                                            <thead>
                                                                <tr>
                                                                    <th> <span onClick={() => { this.props.t.state.Log.order === "nameDesc" ? this.props.t.updateState(this.props.t, 'Log', "nameAsc", "order") : this.props.t.state.Log.order === "nameAsc" ? this.props.t.updateState(this.props.t, 'Log', "none", "order") : this.props.t.updateState(this.props.t, 'Log', "nameDesc", "order"); this.props.t.Interface.ProfileGetLog(this.props.t); }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'id')} <Icon t={this.props.t} name={this.props.t.state.Log.order === "nameDesc" ? "sort-desc" : this.props.t.state.Log.order === "nameAsc" ? "sort-asc" : "sort-off"} /></span></th>
                                                                    <th> <span className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'type')}</span> </th>
                                                                    <th> <span onClick={() => { this.props.t.state.Log.order === "amountDesc" ? this.props.t.updateState(this.props.t, 'Log', "amountAsc", "order") : this.props.t.state.Log.order === "amountAsc" ? this.props.t.updateState(this.props.t, 'Log', "none", "order") : this.props.t.updateState(this.props.t, 'Log', "amountDesc", "order"); this.props.t.Interface.ProfileGetLog(this.props.t); }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'amount')} <Icon t={this.props.t} name={this.props.t.state.Log.order === "amountDesc" ? "sort-desc" : this.props.t.state.Log.order === "amountAsc" ? "sort-asc" : "sort-off"} /></span></th>
                                                                    <th> <span className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'profile', 'balanceAfter')}</span> </th>
                                                                    <th> <span className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'log', 'date')}</span> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.t.state.Log.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td><span>#{item.id}</span></td>
                                                                        <td>
                                                                            <div className={this.props.t.styles['system']}>{this.props.t.Interface.getOperationByType(item, this.props.t)}</div>
                                                                        </td>
                                                                        <td className={item.amount > 0 ? this.props.t.styles['positive'] : this.props.t.styles['default']}> <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(item.amount)}</td>
                                                                        <td className={this.props.t.styles['default']}> <IconStatic t={this.props.t} name='rub' />{this.props.t.Interface.fixedFloat(item.to)}</td>
                                                                        <td>
                                                                            <div className={this.props.t.styles['date']}>{this.props.t.Interface.localeDate(item.date, this.props.t.state.width <= 900 ? true : false, this.props.t)}</div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>)
                                            }
                                        </div>
                                    </div>
                                    {Math.ceil(this.props.t.state.Log.total / this.props.t.state.Log.perPage) > 1 ?
                                        (<div className={this.props.t.styles['pager']}>
                                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Log', this.props.t.state.Log.currentPage - 1, 'currentPage'); this.props.t.Interface.ProfileGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Log.currentPage + 1 > 1 ? false : true}><span><IconStatic t={this.props.t} name='left' />{this.props.t.state.width <= 900 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'prevPage')}</span></button>
                                            <div className={this.props.t.styles['inputBlock']}>
                                                <div className={this.props.t.styles['formField']}>
                                                    <input onChange={(a) => { this.switchPage(a.target.value) }} className={this.props.t.styles['inputField']} value={this.props.t.state.Log.currentPage + 1}></input>
                                                </div>{this.props.t.Interface.getText(this.props.t, 'basic', 'of')} {Math.ceil(this.props.t.state.Log.total / this.props.t.state.Log.perPage)}
                                            </div>
                                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Log', this.props.t.state.Log.currentPage + 1, 'currentPage'); this.props.t.Interface.ProfileGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Log.currentPage + 1 < Math.ceil(this.props.t.state.Log.total / this.props.t.state.Log.perPage) ? false : true}><span>{this.props.t.state.width <= 900 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'nextPage')}<IconStatic t={this.props.t} name='left' /></span></button>
                                        </div>) : (<></>)}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </>
        )
    }
}