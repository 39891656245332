module.exports = {
    system: { code: 'fr', name: 'Français' },
    aml: {
        title: "Lutte contre le blanchiment d'argent",
        description: 'Contrôle de sécurité',
        content: ''
    },
    auth: {
        accountCreated: 'Compte enregistré',
        agreeText: "Je confirme que j'ai 18 ans et j'ai lu",
        agreeTos: "conditions d'utilisation",
        alreadyRegistered: 'Vous êtes déjà inscrit ?',
        byLogin: 'Par identifiant et mot de passe',
        bySocialNetworks: 'Via les réseaux sociaux',
        createAccount: 'Créer un compte',
        forgotPassword: 'Vous avez oublié votre mot de passe ?',
        loading: 'Chargement...',
        login: 'Connexion',
        loginTitle: 'Autorisation de site',
        maxLoginLength: 'Longueur maximale de connexion :',
        maxPasswordLength: 'Longueur maximale du mot de passe :',
        minLoginLength: 'Longueur minimale de connexion :',
        minPasswordLength: 'Longueur minimale du mot de passe :',
        passwordConfirmation: 'Mot de passe',
        passwordsMustBeSame: 'Les mots de passe doivent correspondre',
        pleaseWait: 'Veuillez patienter...',
        registration: 'Inscription',
        registrationTitle: "S'inscrire sur place",
        symbols: 'caractères',
        yourLogin: 'Votre identifiant',
        yourPassword: 'Votre mot de passe',
        yourEmail: 'Votre e-mail',
        logoutConfirm: 'Voulez-vous vraiment sortir ?',
        passwordRecovery: 'Réinitialiser le mot',
        newPassword: 'Nouveau mot de passe',
        copied: 'Copié',
        logout: 'Out'
    },
    basic: {
        yes: 'Oui',
        no: 'Nan',
        confirm: 'Affirmer',
        confirmed: 'A voté',
        cancel: 'Abrogeant',
        sending: 'Envoi...',
        sent: 'Soumettre',
        save: 'Économisez',
        create: 'Créer',
        or: 'ou',
        activate: 'Activer',
        deactivate: 'Désactiver',
        prevPage: 'Page précédente',
        nextPage: 'Page suivante',
        of: 'en dehors',
        balance: 'Balance',
        copy: 'Copier',
        copied: 'Copié'
    },
    bonus: {
        permanentBonus: 'Bonus permanent',
        tenPercent: '10% pour chaque dépôt !',
        more: 'En savoir plus',
        fastRegistration: 'Enregistrement rapide',
        noVerification: 'sans vérification',
        fastWithdraws: 'Versements rapides',
        highLimits: 'limites élevées',
        loyaltyProgramm: 'Programme de fidélité',
        winCashback: "Jusqu'à 1,2 % à chaque victoire !",
        levelSystem: 'Système de niveau',
        bigPercents: 'Grands pourcentages',
        payForWin: 'Payer pour la victoire',
        butNoBets: 'pas pour les paris',
        affiliateProgramm: "Programme d'affiliation",
        join: 'Rejoignez',
        revenueShare: '40 % de part des revenus',
        anytimeWithdraw: 'sortie à tout moment',
        affiliateCabinet: 'Cabinet affilié',
        detailStat: 'statistiques détaillées',
        title: 'Code promotionnel',
        promoEveryDay: 'Codes promo tous les jours dans notre groupe VK',
        enterPromoCode: 'Entrez le code promotionnel'
    },
    fair: {
        title: "Garantie de licence et d'honnêteté",
        description: 'Nous garantissons le fair-play pour tous',
        fairDescription1: "Brillx Casino comprend à quel point il est important de créer un environnement de sécurité pour que les utilisateurs aient confiance en l'honnêteté de nos jeux.",
        fairDescription2: 'Par conséquent, dans notre casino, nous utilisons des jeux fournis par les meilleures entreprises développant des logiciels pour les casinos en ligne. Parmi eux, des entreprises telles que',
        fairDescription3: "En tant que membre fondateur d'eCOGRA et d'IGC, Mycrogaming devance les autres fabricants de jeux de casino, établissant les normes les plus élevées de l'industrie du jeu dans son ensemble, en particulier pour l'honnêteté des jeux en ligne, le choix des opérateurs les plus responsables et la protection des intérêts des joueurs.",
        andOther: 'et autres',
        also: "Nous proposons également des jeux créés par l'entreprise",
        defaultCasino: 'Casino en ligne ordinaire',
        brillxCasino: 'Casino Brillx Honesty Control',
        defaultCasinoDescription1: 'Vous faites un pari écrit sur le serveur du casino',
        defaultCasinoDescription2: 'Vos données sont collectées et stockées sur des serveurs de casino',
        defaultCasinoDescription3: "Il est impossible de vérifier l'honnêteté du jeu : tous les algorithmes sont enregistrés et enregistrés du côté du casino.",
        defaultCasinoDescription4: "Pour obtenir un gain, vous devez passer par une procédure d'autorisation longue.",
        defaultCasinoDescription5: "La solvabilité du casino en question car son solde n'est publié nulle part",
        brillxCasinoDescription1: 'Une copie des données des fournisseurs de paris joueurs est enregistrée dans la blockchain et ne peut pas être modifiée.',
        brillxCasinoDescription2: 'Les statistiques de jeu pour les humains sont cryptées et enregistrées dans la blockchain, sans lien avec les données personnelles des joueurs.',
        brillxCasinoDescription3: "Le contrôle de l'intégrité est effectué avec la technologie blockchain",
        brillxCasinoDescription4: 'Les retraits nécessitent une autorisation standard KYC, sans frais de transfert',
        brillxCasinoDescription5: 'Reporting ouvert et énorme pool pour les paiements des joueurs'
    },
    game: {
        loading: 'Chargement',
        lobby: 'Lobby',
        fullScreen: 'Plein écran',
        deposit: 'Dépôt',
        betsAmount: "Montant du pari",
        totalRTP: "Total RTP",
        averageBet: "Mise moyenne",
        topWin: "Meilleur gagnant"
    },
    help: {
        title: 'Aide et support',
        description: 'Réponses aux questions les plus courantes et communication avec le service client.',
        connectTypes: 'Méthodes de communication avec nous',
        operatorsOnline: 'Opérateurs en ligne 24 heures sur 24',
        supportLive: 'Assistance (Live)',
        onlineChat: 'Chat en direct',
        averageTimeChat: 'Temps de réponse moyen : 10 minutes',
        supportEmail: 'Service client (e-mail)',
        averageTimeEmail: 'Temps de réponse moyen : 1 jour',
        generalQuestions: 'Problèmes généraux',
        aboutSite: 'À propos du site',
        aboutSiteContent: "Brillx est un casino en ligne avec des licences et un nom internationaux. Nous proposons plus de 6 000 jeux dans le catalogue, un large éventail d'événements sportifs et de nombreux mini-jeux. Nous prenons en charge de nombreux systèmes de paiement et la plupart des cryptomonnaies populaires.",
        bonus: 'Bonus',
        bonuses: 'Bonus',
        onPage: 'Sur la page',
        manyFreeBonuses: "il y a plusieurs façons d'obtenir de l'argent pour votre solde absolument gratuit !",
        bonus1: "Bonus d'inscription. Après vous être inscrit sur le site, vous disposez de 60 minutes pour entrer le code d'invitation et obtenir jusqu'à 10 RUB.",
        bonus2: "Bonus d'inscription. Après vous être inscrit sur le site, vous disposez de 60 minutes pour entrer le code d'invitation et obtenir jusqu'à 10 RUB.",
        bonus3: "Bonus d'inscription. Après vous être inscrit sur le site, vous disposez de 60 minutes pour entrer le code d'invitation et obtenir jusqu'à 10 RUB.",
        bonus4: "Bonus d'inscription. Après vous être inscrit sur le site, vous disposez de 60 minutes pour entrer le code d'invitation et obtenir jusqu'à 10 RUB.",
        promocodes: 'Codes promotionnels',
        inviteFriend: 'Invitez un ami',
        balanceRefilling: 'Réapprovisionnement du solde',
        wallet: 'Portefeuille',
        howToRefill: 'Comment puis-je reconstituer le solde ?',
        commissions: 'Commission',
        periodOfRefilling: "Conditions d'inscription",
        withdrawal: 'Retraits',
        howToWithdraw: 'Comment puis-je retirer des fonds ?',
        periodOfWithdrawal: 'Chronologie de retrait',
        clickToRefill: 'Cliquez sur le bouton pour réapprovisionner le solde',
        atRightCorner: 'dans le coin supérieur droit du site. Sélectionnez un mode de paiement qui vous convient, entrez le montant du dépôt, puis cliquez sur Accéder au paiement.',
        minimalRefilling: 'Réapprovisionnement minimal',
        commissionsOnPage: 'Les commissions de tous les modes de paiement sont répertoriées sur la page',
        clickToWithdraw: "Cliquez sur le bouton pour retirer de l'argent de votre compte",
        averageTimeWithdraw: 'Tous les résultats sont traités en moyenne une heure à compter du moment de la demande.',
        allTransactions: 'Toutes les transactions sont traitées instantanément. Les retards ne peuvent survenir que si vous rencontrez des problèmes avec le mode de paiement que vous réapprovisionnez votre solde.',
        inCaseOfProblems: 'En cas de problème, veuillez contacter notre service client et fournir les informations les plus détaillées sur votre problème.',
        atRightCornerWithdraw: 'dans le coin supérieur droit du site. Sélectionnez un mode de retrait qui vous convient, entrez le montant et cliquez sur Accéder au paiement.',
        minimalWithdrawal: 'Retrait minimal',
        ifBigAmount: "Si le montant du retrait est important, le retrait peut durer un peu plus longtemps, généralement jusqu'à une heure. La nuit (00h00 à 9h00 MSC), les résultats peuvent durer plusieurs heures, car l'agrégateur de paiements traite les demandes de retrait plus longtemps.",
        sometimes: "Dans de très rares cas, le retrait peut aller jusqu'à 24 heures, en raison des charges bancaires, etc. Si l'argent n'arrive pas après ce délai, veuillez contacter notre service client et fournir les informations les plus détaillées sur votre problème.",
        checkCorrectPurse: "Assurez-vous également de spécifier correctement les détails de la sortie. Pour rassurer le portefeuille que vous avez indiqué sur le retrait, vous devez accéder à l'historique de vos transactions et ouvrir les détails en cliquant sur la transaction souhaitée."
    },
    interface: {
        months: {
            jan: 'jan.',
            feb: 'fév.',
            mar: 'mar.',
            apr: 'avr.',
            may: 'mai',
            jun: 'jui.',
            jul: 'jui.',
            aug: 'aoû.',
            sep: 'sep.',
            oct: 'oct.',
            nov: 'nov.',
            dec: 'déc.'
        },
        year: 'a.',
        confirmPasswordRecovery: 'Confirmez la réinitialisation du mot de passe en suivant le lien envoyé à votre e-mail',
        passwordWasChanged: 'Mot de passe modifié avec succès, vous pouvez vous connecter pour utiliser de nouvelles données',
        confirmEmail: "Confirmer l'adresse e-mail par lien",
        emailWasConfirmed: "L'adresse e-mail a été confirmée avec succès",
        accountLinked: 'Le compte a été correctement lié',
        bonusGranted: 'Bonus reçu'
    },
    landing: {
        newGames: 'Nouveaux jeux',
        topGames: 'Jeux populaires',
        brillxSelect: 'Le choix de Brillx',
        daylyLiveRtp: 'RTP en direct - 24 heures',
        brillxCasino: 'Casino Brillx',
        casinoInfo1: 'Découvrez un large éventail de jeux passionnants ici sur Brillx.GG, un site de machines à sous sur mesure pour votre satisfaction. Des milliers de machines à sous de première classe sont disponibles pour les joueurs du monde entier. Que ce soit les gros jackpots, les machines à sous de poker ou les machines à sous thématiques dont vous avez besoin, nous avons tout ce dont vous avez besoin !',
        casinoInfo2: "Découvrez la facilité d'enregistrement rapide en cliquant sur le bouton d'enregistrement dès maintenant ! Profitez de moments où vous jouez aux machines à sous en argent réel des principaux fournisseurs de jeux.",
        casinoInfo3: 'Notre casino avec des paiements rapides et une vaste sélection de toutes sortes de variantes de machines à sous fonctionne 24 heures sur 24, 7 jours sur 7. Il suffit de choisir un jeu et de commencer à jouer !',
        casinoInfo4: 'Jouer aux machines à sous',
        casinoInfo5: 'Nous ne gâchons jamais votre plaisir, pour que vous puissiez jouer à tous nos jeux sans rien télécharger sur votre ordinateur.',
        casinoInfo6: "Parcourez des milliers de machines à sous, sélectionnez le jeu que vous aimez et cliquez sur « JOUER » - c'est tellement simple !",
        casinoInfo7: "Les jeux de machines à sous de casino sont l'un des jeux les plus populaires. Non seulement à cause des énormes incitations que vous pouvez obtenir, mais aussi à cause des graphismes passionnants, de la bonne musique et de l'effet visuel global agréable du jeu.",
        casinoInfo8: "Vous pouvez même essayer chacune de nos machines à sous en passant en mode divertissement - une fonctionnalité étonnante qui vous permet de jouer à toutes nos machines à sous sans téléchargement et sans inscription est gratuite ! Vous pouvez y accéder via votre téléphone portable, votre ordinateur ou votre tablette. Découvrez aujourd'hui quelques-unes des meilleures machines à sous que vous pouvez jouer gratuitement sans avoir besoin de télécharger, et vivez une aventure unique dans les meilleures machines à sous de casino du monde entier. Prenez votre meilleur coup aujourd'hui et laissez le destin décider de ce qui vous attend !",
        casinoInfo9: "Qu'attendez-vous ? Aujourd'hui pourrait être votre jour de chance !",
        casinoInfo10: 'Des milliers de machines à sous',
        casinoInfo11: 'Voici les différents types de machines à sous que vous pouvez trouver dans notre casino :',
        casinoInfo12: '3 fentes à rouleaux',
        casinoInfo13: "Les machines à sous à 3 rouleaux sont surtout connues sous le nom de machines à sous classiques ou traditionnelles. Les rouleaux sont des lignes verticales que vous pouvez trouver dans le jeu où les symboles sont en cascade. Si vous êtes à la recherche d'un frisson, essayez de jouer à une machine à sous à 3 rouleaux ! Certains des jeux auxquels vous pouvez jouer sont Book of Alchemy, Watford FC Slot et bien d'autres.",
        casinoInfo14: '5 fentes à rouleaux',
        casinoInfo15: "Obtenez de meilleures chances de gagner des combinaisons en jouant à une machine à sous à 5 rouleaux ! La plupart des machines à sous 5 rouleaux sont payables chaque fois que 3, 4 ou 5 caractères tombent sur les rouleaux. Vous pouvez également obtenir des multiplicateurs de gains, des parties bonus ou des tours gratuits selon le jeu. Certains des jeux à 5 rouleaux auxquels vous pouvez jouer sont Vikings Go Berzerk, Legacy of Dead et Thato's Rich.",
        casinoInfo16: 'Fentes progressives',
        casinoInfo17: "Les machines à sous progressives sont une ruse parmi tous les types de machines à sous. La taille du jackpot augmente au fur et à mesure que le jeu progresse. Il y a d'énormes millions qui vous attendent, et vous pourriez devenir l'une des personnes les plus riches à ce jour !",
        casinoInfo18: 'Roulette',
        casinoInfo19: 'La roulette est un jeu qui parie sur des numéros individuels sur une table, une couleur, un type de numéro ou des combinaisons. Testez votre chance et gagnez si ce sur quoi vous pariez frappe le volant. Jouez à nos superbes jeux de roulette en temps réel dès maintenant !',
        casinoInfo20: 'Poker',
        casinoInfo21: "Si vous connaissez le poker dans un casino ordinaire, vous pouvez essayer de le jouer dès maintenant en profitant de notre large sélection de jeux de vidéo poker en ligne. Soyez prêt à vivre un jeu inoubliable aujourd'hui ! Avec une animation haute définition et de superbes tours vidéo spéciaux, vous reviendrez certainement pour une nouvelle victoire.",
        casinoInfo22: 'Baccarat',
        casinoInfo23: "Le Baccarat est l'un des jeux les plus populaires au monde. Vaincre le croupier en pariant sur la main la plus proche de 9. De plus, vous pouvez également gagner s'il y a un match nul. Ça a l'air vraiment excitant ? Préparez vos paris maintenant et pariez du côté gagnant !",
        casinoInfo24: 'Blackjack',
        casinoInfo25: "Au blackjack, vous pouvez battre le croupier si vous avez la main la plus proche de 21. Bénéficiez d'une chance de doubler votre pari lorsque vous jouez à nos majestueuses machines à sous blackjack !",
        gamesProviders: 'Fournisseurs de jeux',
        showMore: 'Afficher plus',
        hideInfo: 'Masquer les informations',
        allGames: 'Tous les jeux',
        providers: 'Fournisseurs',
        new: 'Nouveau',
        tournaments: 'Bonus Buy',
        slots: 'Machines à sous',
        roulette: 'Roulette',
        card: 'Cartes',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Démo', play: 'Jouer', title: 'Live' },
    lobby: {
        providers: 'Fournisseurs',
        recent: 'Récent',
        top: 'Populaire',
        new: 'Nouveau',
        favorite: 'Favoris',
        nothingFound: 'Rien trouvé',
        play: 'JOUER',
        demo: 'DÉMO',
        forExample: 'Par exemple,'
    },
    log: {
        date: 'Date',
        type: 'Opération',
        amount: 'Montant',
        id: 'IDENTIFIANT'
    },
    loyalty: {
        title: 'Programme de fidélité',
        description: 'Recevez un cashback qui dépend du niveau de fidélité pour chaque mise placée',
        cabinet: 'Armoire',
        myCabinet: 'Mon bureau',
        payLog: "Journal d'exercice",
        log: 'Logue',
        level: 'Niveau',
        totalWins: 'Montant gagnant',
        totalBets: 'Montant du pari',
        availableBalance: 'Solde disponible',
        get: 'À emporter',
        cashback: 'Cashback',
        emptyHistory: "L'histoire est manquante",
        operationID: "ID de l'opération",
        amount: 'Montant',
        date: 'Date',
        operationDate: "Date d'opération",
        operationType: 'Opération',
        balanceBefore: 'Bilan en hausse',
        balanceAfter: 'Équilibre après'
    },
    menu: {
        affProgramm: 'Partenaires',
        fairGame: 'Fair Play',
        free: 'Gratuit',
        games: 'Jeux',
        lobby: 'Lobby',
        promo: 'Promo',
        loyaltyProgramm: 'Loyauté',
        promotions: 'Actions',
        support: 'Support technique',
        settings: 'Paramètres',
        history: 'Historique du jeu',
        more: 'Plus',
        profile: 'Profil',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Erreur', success: 'Réussi' },
    profile: {
        wallet: 'Portefeuille',
        onSiteFrom: 'Sur place avec',
        totalBets: 'Montant du pari',
        betsCount: 'Pari',
        totalWins: 'Montant gagnant',
        bestWin: 'Meilleure victoire',
        emptyHistory: "L'histoire est manquante",
        balanceAfter: 'Équilibre après'
    },
    partner: {
        maxCodeLength: 'Longueur maximale du code :',
        minCodeLength: 'Longueur minimale du code :',
        maxNameLength: 'Longueur maximale du nom :',
        minNameLength: 'Longueur minimale du nom :',
        symbols: 'caractères',
        confirmDeleteCampgain: 'Voulez-vous vraiment supprimer la campagne ?',
        campgains: 'Campagnes',
        refresh: 'Mettre à niveau',
        export: 'Exporter',
        campgainName: 'Nom de la campagne',
        affiliateLink: "Lien d'affiliation",
        name: 'Titre',
        creationDate: 'Date de création',
        clicks: 'Transitions',
        link: 'Réf.',
        manage: 'Gestion',
        noCampgains: 'Aucune campagne publicitaire active',
        namePlaceholder: 'Nom à afficher dans le panneau',
        general: 'Général',
        mainAffiliateLink: "Lien principal d'affiliation",
        registrations: 'Inscriptions',
        totalProfit: 'Bénéfice commun',
        availableBalance: 'Solde disponible',
        get: 'À emporter',
        emptyHistory: "L'histoire est manquante",
        operationID: "ID de l'opération",
        amount: 'Montant',
        date: 'Date',
        operationDate: "Date d'opération",
        operationType: 'Opération',
        playerID: 'ID du joueur',
        balanceAfter: 'Équilibre après',
        balanceBefore: 'Bilan en hausse',
        log: "Journal d'exercice",
        players: 'Joueurs',
        registration: 'Inscription',
        registrationDate: "Date d'enregistrement",
        balance: 'Balance',
        noPlayers: 'Pas de joueurs invités',
        totalBets: 'Montant du pari',
        totalDeps: 'Montant du dépôt',
        totalWithdraws: 'Somme des résultats',
        registrationSource: "Source d'enregistrement",
        mainLink: 'Lien principal',
        player: 'Joueur',
        registrationsCount: 'Inscription',
        bets: 'Jalonnement',
        profit: 'Profit',
        stat: 'Statistiques',
        totalStat: 'Statistiques globales',
        partnerCabinet: 'Bureau des partenaires',
        partnerID: 'ID de partenaire'
    },
    privacy: {
        title: 'Politique de confidentialité',
        description: 'Dispositions générales et informations',
        redaction: 'Éditorial de',
        content: ''
    },
    promo: {
        linkVk: 'Liez un compte VK pour obtenir des bonus',
        completeAll: "S'adapte à toutes les conditions et obtenez",
        toBalanceForRepost: 'en équilibre pour reposter !',
        link: 'Fixations',
        join: 'Entrée',
        allowNotifications: 'Autoriser les notifications',
        make: 'Do',
        toProfileVkAccount: 'sur le profil de compte VK.',
        toOurVkGroup: 'à notre groupe VK.',
        fromOurGroup: 'de notre communauté.',
        repostToYourPage: "republiez l'entrée sur sa page.",
        mustBePublic: "Votre page VK doit être publique, sinon nous ne pourrons pas vérifier s'il y a une republication.",
        dontDelete: 'Si vous supprimez une publication plus de 2 semaines après la republication, le montant du bonus sera déduit du solde.',
        joinAndGet: 'Rejoignez un groupe et obtenez',
        toBalance: 'en équilibre !',
        bonusGranted: 'Bonus reçu avec succès !',
        momentalBonus: "Rejoignez notre groupe VK et obtenez un bonus instantané sur votre solde ! Le profil VK doit être public, sinon nous ne pourrons pas vérifier s'il y a un abonnement.",
        vkDoesntLinked: 'Compte VK non lié',
        allowNotificationsAndGet: 'Autorisez les notifications de notre communauté et recevez',
        sendAnyMessage: "Envoyez n'importe quel message à notre groupe VK, ce qui permet d'envoyer des notifications. Une belle surprise - nous vous enverrons périodiquement des informations sur les promotions et les différents bonus !",
        rollAndGetBonus: 'Croisière sur votre roulette et obtenez des bonus !',
        rollOncePer: "Tournez la roulette une fois toutes les 15 minutes et arrivez jusqu'à",
        rollEveryDay: 'Faites tourner la roue sans restrictions tous les jours !',
        untilNextTry: 'Avant que la prochaine tentative ne soit laissée',
        subscribeTg: "Inscrivez-vous à notre chaîne de télégrammes pour obtenir d'abord de nouveaux codes promotionnels !",
        beFirst: "Soyez l'un des premiers à obtenir un nouveau code promo, découvrez de nouveaux bonus et innovations !",
        referrals: 'Aiguillage',
        getPercent: 'Invitez des amis et gagnez 40 % des avantages du casino !',
        copyLink: 'Copier le lien',
        inviteFriend: 'Invitez un ami',
        getBonus: 'Obtenez un bonus',
        affiliateProgramm: "Programme d'affiliation",
        sendReview: 'Donnez des commentaires sur le projet dans notre groupe et recevez',
        bonusToDeposit: 'bonus de dépôt',
        upTo: "jusqu'à",
        makeReviews: 'Laissez des commentaires dans notre groupe VK, une fois que le modérateur a été vérifié et publié, le bonus sera automatiquement activé sur votre compte.',
        makeReview: 'Feedback',
        ranks: 'Rangi',
        earnFromWin: 'En gagnant des rangs, vous recevrez une remise en argent accrue à chaque victoire.',
        loyaltyProgramm: 'Programme de fidélité',
        writeMessage: 'Écrivez un message',
        goToGroup: 'Aller au groupe',
        refreshing: 'Mettre à jour...',
        refreshStatus: 'Mettre à niveau',
        roll: 'Rotation',
        linkAccount: 'Lier un compte',
        checking: 'Vérification...',
        get: 'Obtenez'
    },
    promotions: {
        title: 'Actions et offres',
        description: "Ne manquez pas l'occasion d'obtenir un bonus sur nos actions actuelles",
        game: 'Jeu',
        minDeposit: 'Dépôt min.',
        bonus: 'Bonus',
        addWager: 'Un pari supplémentaire',
        validUntil: "Agit jusqu'à",
        activation: 'Activation',
        get: 'Obtenez',
        unlimit: 'illimité',
        oneTime: 'une seule fois',
        forEveryDeposit: 'pour chaque dépôt',
        permanentBonus: 'Bonus permanent',
        any: 'toute'
    },
    responsibility: {
        title: 'Jeu responsable',
        description: 'Conseils et règles du jeu responsable',
        content: ''
    },
    settings: {
        incorrectEmail: 'Adresse e-mail incorrecte',
        bigFileSize: 'La taille du fichier ne doit pas dépasser 5 Mo.',
        genders: { man: 'Hommes', female: 'Femmes', other: 'Autre' },
        requiredField: 'Champ obligatoire',
        dataSuccessChanged: 'Les données ont été mises à jour avec succès',
        tfaLoginRequired: "Définir le login et le mot de passe pour configurer l'authentificateur",
        general: 'Général',
        login: 'Connexion',
        vkProfile: 'Profil de VK',
        glProfile: 'Profil Google',
        okProfile: 'Classmates de profil',
        fbProfile: 'Profil Facebook',
        security: 'Sécurité',
        alterLogin: 'Ajoutez une autre méthode de connexion et de mot de passe à votre compte.',
        newPassword: 'Nouveau mot de passe',
        repeatPassword: 'Mot de passe',
        saveActions: 'Enregistrer les actions',
        currentPassword: 'Mot de passe actuel',
        tfa: 'Authentification à deux facteurs',
        addTfa: 'Protégez votre compte Brillx avec une sécurité supplémentaire',
        tfaSettings: 'Paramètres 2FA',
        sessions: 'Sessions',
        device: 'Appareil',
        region: 'Région',
        ipAddress: 'Adresse IP',
        date: 'Date',
        session: 'Session',
        active: 'Actif',
        closed: 'Fermé',
        verification: 'Vérification',
        verificationDescription1: " Pour améliorer votre niveau de service, nous demandons des documents d'identification. Cela garantira la sécurité de votre compte s'il est restauré et permettra une livraison précise et ciblée de cadeaux physiques.",
        verificationDescription2: "Pour des moyens de vérification acceptables, il faut fournir une photo de votre pièce d'identité, de votre permis de conduire ou de votre passeport. Une fois ce processus terminé, nous vous informerons par e-mail.",
        frontPhoto: "Photo de la face avant de la pièce d'identité",
        selectFile: 'Sélectionner un fichier',
        backPhoto: "Photo arrière de la pièce d'identité",
        fileIsNotSelected: 'Fichier non sélectionné',
        name: 'Nom',
        surname: 'Prénom',
        birthday: 'Date de naissance',
        gender: 'Sexe',
        country: 'Pays',
        city: 'Ville',
        yourName: 'Votre nom',
        yourSurname: 'Votre nom de famille',
        verifyStatus1: 'Les documents sont en cours de vérification...',
        verifyStatus2: 'Compte vérifié',
        verifyStatus3: 'Erreur de vérification des documents',
        verifyDescription1: 'Les documents sont actuellement en cours de révision, dès que les documents sont vérifiés, votre compte sera vérifié !',
        verifyDescription2: 'Vos données ont été vérifiées et confirmées par le personnel de sécurité.',
        verifyDescription3: 'Veuillez contacter le support technique pour plus de détails.',
        registrationCity: "Ville d'enregistrement",
        sendDocs: 'Soumettre pour inspection',
        affiliateCabinet: 'Bureau des partenaires'
    },
    tos: {
        title: 'Contrat utilisateur',
        description: "Conditions et règles d'utilisation",
        redaction: 'Éditorial de',
        content: ''
    },
    wallet: {
        deposit: 'Dépôt',
        withdraw: 'Sortie',
        history: 'Histoire',
        minDepositAmount: 'Dépôt minimum via',
        minWithdrawAmount: 'Sortie minimale via',
        topMethods: 'Populaire',
        allMethods: 'Toutes les méthodes',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Autres',
        success: 'Réussi',
        canceled: 'Annulé',
        error: 'Erreur',
        waiting: 'En attente',
        processing: 'La demande est en cours de traitement',
        incorrectWalletValue: 'Numéro de portefeuille saisi incorrectement',
        incorrectCardValue: 'Numéro de carte entré incorrectement',
        selectTemplate: 'Choisir un modèle',
        withdrawCreated: 'Application de paiement créée et traitée dans les 24 heures',
        withdrawCanceled: "L'offre de paiement a été annulée",
        firstWithdraw: "C'est votre première conclusion.",
        isDataCorrect: 'Êtes-vous sûr que la saisie des données est correcte ?',
        payMethod: 'Mode de paiement',
        withdrawMethod: 'Méthode de retrait',
        selectMethod: 'Choisissez un moyen',
        selectOperator: 'Sélectionner un opérateur',
        refillAmount: 'Réapprovisionnement',
        withdrawAmount: 'Montant du retrait',
        payTroubles: 'Si vous rencontrez des difficultés à payer, essayez',
        payHere: 'Payez ici',
        pay: 'Aller au paiement',
        commission: 'Commission',
        onePayLimit: 'Limite de réapprovisionnement unique',
        oneWithdrawLimit: 'Limite de sortie unique',
        emptyHistory: "L'histoire est manquante",
        operationID: "ID de l'opération",
        deposits: 'Réapprovisionnements',
        withdraws: 'Résultats',
        txAmount: 'Montant du mouvement',
        txDate: "Date d'opération",
        purse: 'Score',
        typePurse: 'Numéro de compte',
        toGet: 'Pour recevoir',
        createWithdraw: "Création d'une application"
    },
    tfa: {
        tfa: 'Authentification à deux facteurs',
        typeYourPassword: 'Entrez votre mot de passe',
        enterPin: "Saisissez un code à 6 chiffres depuis l'application",
        downloadApp: "Téléchargez l'application",
        downloadAndInstall: 'Télécharger et installer',
        forYourPhone: 'pour votre téléphone ou tablette',
        scanQR: 'numériser le code QR',
        scanQRWithCamera: "Scannez QR sur la gauche avec l'appareil photo de votre téléphone",
        openWithLink: 'Ouvrir par lien',
        openInApp: "Ouvrir dans l'application",
        tfaManualEnter: 'Clé 2FA (entrée manuelle)'
    },
    pwa: {
        androidStep1: 'Ouvrir le navigateur Chrome',
        androidStep1Description: 'Si vous êtes dans un autre navigateur, ouvrez cette page dans Chrome',
        androidStep2: 'Cliquez sur le menu du navigateur',
        androidStep2Description: "Le menu du navigateur est l'ellipse qui se trouve dans le coin supérieur droit de votre navigateur",
        androidStep3: "Sélectionnez \"Ajouter à l'écran d'accueil\"",
        androidStep3Description: "Vous verrez une fenêtre avec des informations (\"Ajouter à l'écran d'accueil\"), dans laquelle vous devez sélectionner \"Ajouter\", après quoi l'application sera ajoutée au bureau de votre appareil",
        androidAlert: 'Les étapes peuvent différer selon la version du navigateur',
        iosStep1: 'Ouvrir le navigateur Safari',
        iosStep1Description: 'Si vous êtes dans un autre navigateur, ouvrez cette page dans Safari',
        iosStep2: 'Cliquez sur "Partager"',
        iosStep2Description: "Cliquez sur le bouton \"Partager\" en bas de l'écran, après quoi la fenêtre de sélection des paramètres s'ouvrira",
        iosStep3: "Sélectionnez \"Ajouter à l'écran d'accueil\"",
        iosStep3Description: "Sélectionnez \"Ajouter à l'écran d'accueil\" et cliquez sur \"Ajouter\"",
        alert: 'Les étapes peuvent différer selon la version du navigateur',
        installApp: "Installer l'application Brillx",
        back: 'Retour',
        app: 'Application mobile',
        installAppForMobile: "Installez la nouvelle application Brillx pratique pour iOS et Android",
        install: 'Installer'
    },
    undefined: '???'
};