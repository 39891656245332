import React from 'react';
import IconStatic from '../components/IconStatic';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'reactstrap';

import ConfirmModal from './modals/Confirm';

import Loader from '../assets/svg/loader.svg';

export default class Campgains extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.t.state.userID)
            this.props.t.Interface.PartnerGetCampgains(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.form.partner.campgainName.data, error: false }, "partner", "campgainName");
        this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.form.partner.campgainCode.data, error: false }, "partner", "campgainCode");
    }


    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'partner', 'campgains')}
                </div>
                <div className={this.props.t.styles['campgains']}>
                    <div className={[this.props.t.styles['panel'], this.props.t.state.Partner.campgains.process ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                        <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.campgains.createOpen, 'campgains', 'createOpen'); this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.form.partner.campgainName.data, error: false }, "partner", "campgainName"); this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.form.partner.campgainCode.data, error: false }, "partner", "campgainCode"); this.props.t.updateState(this.props.t, 'Partner', true, 'campgains', 'edit'); }} className={[this.props.t.styles['btn'], this.props.t.state.Partner.campgains.createOpen ? this.props.t.styles['btnRed'] : this.props.t.styles['btnGreen']].join(' ')} type="button">{this.props.t.state.Partner.campgains.createOpen ? this.props.t.Interface.getText(this.props.t, 'basic', 'cancel') : this.props.t.Interface.getText(this.props.t, 'basic', 'create')}</button>
                        <button onClick={() => this.props.t.Interface.PartnerGetCampgains(this.props.t)} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button"> {this.props.t.Interface.getText(this.props.t, 'partner', 'refresh')}</button>
                        <button onClick={() => prompt('JSON', JSON.stringify(this.props.t.state.Partner.campgains.list))} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button"> {this.props.t.Interface.getText(this.props.t, 'partner', 'export')}</button>
                    </div>

                    {this.props.t.state.Partner.campgains.createOpen && <div className={this.props.t.styles['newBlock']}>
                        <div className={this.props.t.styles['inputRow']}>
                            <label> {this.props.t.Interface.getText(this.props.t, 'partner', 'campgainName')}</label>

                            <input placeholder={this.props.t.Interface.getText(this.props.t, 'partner', 'namePlaceholder')} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.partner.campgainName.error, }, "partner", "campgainName"); this.props.t.Interface.checkForm(this.props.t, "partner", "campgainName") }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'partner', 'campgainName')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'partner', 'campgainName'); }} id="inputCampgainName" autoComplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.partner.campgainName.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="c_name" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'partner', 'campgainName'); this.props.t.Interface.checkForm(this.props.t, "partner", "campgainName"); }} value={this.props.t.state.form.partner.campgainName.data}></input>
                            <Tooltip isOpen={this.props.t.state.form.partner.campgainName.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputCampgainName">
                                {this.props.t.state.form.partner.campgainName.error}
                            </Tooltip>
                        </div>
                        <div className={this.props.t.styles['inputRow']}>
                            <label>{this.props.t.Interface.getText(this.props.t, 'partner', 'affiliateLink')}</label>
                            <div className={this.props.t.styles['inputAppend']}>
                                <input onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.partner.campgainCode.error, }, "partner", "campgainCode"); this.props.t.Interface.checkForm(this.props.t, "partner", "campgainCode") }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'partner', 'campgainCode')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'partner', 'campgainCode'); }} id="inputCampgainCode" autoComplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.partner.campgainCode.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="c_name" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'partner', 'campgainCode'); this.props.t.Interface.checkForm(this.props.t, "partner", "campgainCode"); }} value={this.props.t.state.inputs.partner.campgainCode ? this.props.t.state.form.partner.campgainCode.data : this.props.t.config.partnerURL + '/' + this.props.t.state.form.partner.campgainCode.data}></input>
                                <button onClick={() => { !this.props.t.state.form.partner.campgainName.error && !this.props.t.state.form.partner.campgainCode.error && this.props.t.state.form.partner.campgainName.data.length && this.props.t.state.form.partner.campgainCode.data.length ? (!this.props.t.state.Partner.campgains.edit ? this.props.t.Interface.PartnerCreateCampgain(this.props.t) : this.props.t.Interface.PartnerCreateCampgain(this.props.t)) : this.props.t.Interface.checkForm(this.props.t, "partner", "campgainName") && this.props.t.Interface.checkForm(this.props.t, "partner", "campgainCode") }} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'confirm')}</button>
                            </div>
                            <Tooltip isOpen={this.props.t.state.form.partner.campgainCode.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputCampgainCode">
                                {this.props.t.state.form.partner.campgainCode.error}
                            </Tooltip>
                        </div>
                    </div>}
                    <div className={this.props.t.styles['withPager']}>
                        <div className={this.props.t.styles['list']}>
                            <div className={[this.props.t.styles['responsive'], this.props.t.state.Partner.campgains.process ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                                <table data-count="5">
                                    <thead>
                                        <tr className={this.props.t.state.Partner.campgains.process ? this.props.t.bstyles['d-none'] : ''}>
                                            <th>{this.props.t.Interface.getText(this.props.t, 'partner', 'name')}</th>
                                            <th>{this.props.t.Interface.getText(this.props.t, 'partner', 'creationDate')}</th>
                                            <th>{this.props.t.Interface.getText(this.props.t, 'partner', 'clicks')}</th>
                                            <th>{this.props.t.Interface.getText(this.props.t, 'partner', 'link')}</th>
                                            <th>{this.props.t.Interface.getText(this.props.t, 'partner', 'manage')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.t.state.Partner.campgains.process ?
                                            <tr><td colSpan="5"> <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span></td></tr>
                                            : !this.props.t.state.Partner.campgains.list.length ?
                                                <tr><td colSpan="5" className={this.props.t.styles['noItems']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'noCampgains')}</td></tr>
                                                : this.props.t.state.Partner.campgains.list.map((campgain, index) =>
                                                    <tr key={index}>
                                                        <td>{campgain.name}</td>
                                                        <td>{this.props.t.Interface.localeDate(campgain.date, true, this.props.t)}</td>
                                                        <td>{campgain.clicks}</td>
                                                        <td>
                                                            <div className={this.props.t.styles['link']}>
                                                                <div><button type="button">{this.props.t.config.partnerURL + '/' + campgain.code}</button>
                                                                    <CopyToClipboard text={this.props.t.config.partnerURL + '/' + campgain.code}>
                                                                        <button type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'copy')}</button>
                                                                    </CopyToClipboard>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={this.props.t.styles['control']}>
                                                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', true, 'campgains', 'createOpen'); this.props.t.updateState(this.props.t, 'Partner', campgain.code, 'campgains', 'edit'); this.props.t.updateState(this.props.t, 'form', { data: campgain.name, error: false }, 'partner', 'campgainName'); this.props.t.updateState(this.props.t, 'form', { data: campgain.code, error: false }, 'partner', 'campgainCode') }} className={this.props.t.styles['btn']} type="button"> <IconStatic t={this.props.t} name="edit" /></button>
                                                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', campgain.code, 'campgains', 'delete'); this.props.t.updateState(this.props.t, 'modal', { show: true, content: ConfirmModal, className: this.props.t.styles['confirmModal'], callback: this.props.t.Interface.PartnerDeleteCampgain, confirmText: this.props.t.Interface.getText(this.props.t, 'partner', 'confirmDeleteCampgain') }) }} className={[this.props.t.styles['btn'], this.props.t.styles['btnRed']].join(' ')} type="button"> <IconStatic t={this.props.t} name="close" /></button>
                                                        </td>
                                                    </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }

}