import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Icon from '../components/Icon';

export default class LeftBar extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.pState = this.props.t.cloneDeep(this.props.t.state);
	}

	shouldComponentUpdate() {
		let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.location, this.props.t.state.LeftBar, this.props.t.state.width], [this.pState.location, this.pState.LeftBar, this.pState.width]);

		if (needUpdate)
			this.pState = this.props.t.cloneDeep(this.props.t.state);

		return needUpdate;
	}

	render() {
		return (
			<>
				<div className={this.props.t.styles['leftWrapper']}>
					<div className={this.props.t.styles['leftContainer']}>
						<ul className={this.props.t.styles['leftNav']}>
							{this.props.t.config.leftBar.menu.map((item, i) =>
								<li key={i}><button className={this.props.t.state.location === item.route ? this.props.t.styles['current'] : ''} onClick={() => !item.auth ? this.props.t.doRoute('/' + item.route) : this.props.t.state.userID ? this.props.t.doRoute('/' + item.route) : this.props.t.Interface.requireAuth(this.props.t, false, '/' + item.route)} type="button"><Icon t={this.props.t} name={item.icon} /><span className={this.props.t.styles['tipText']}>{this.props.t.Interface.getText(this.props.t, 'menu', item.title)}</span></button></li>
							)}

						</ul>
					</div>
				</div >
				{this.props.t.state.LeftBar.tipObject && <UncontrolledTooltip className={this.props.t.styles['tooltipBox']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="left" target={this.props.t.state.LeftBar.tipObject}>
					{this.props.t.state.LeftBar.tipText}
				</UncontrolledTooltip>}
			</>
		)
	}
}