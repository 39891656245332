import React from 'react';
import IconStatic from '../components/IconStatic';
import { Tooltip } from 'reactstrap';

export default class General extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.t.state.userID)
            setTimeout(() => { this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0" ? this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.userData.settings.email, error: this.props.t.state.form.settings.email.error }, "settings", "email") : this.props.t.state.userData && this.props.t.state.userData.tempData.email !== "0" && this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.userData.tempData.email, error: this.props.t.state.form.settings.email.error }, "settings", "email") }, 10);
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'general')}
                </div>
                {this.props.t.state.userData && this.props.t.state.userData.login && <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'login')}</label><input autocomplete="off" className={this.props.t.styles['inputControl']} disabled={true} value={this.props.t.state.userData.login}></input>
                </div>}
                {this.props.t.state.userData && this.props.t.state.userData.social.vk !== "0" && <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'vkProfile')}</label><input autocomplete="off" className={this.props.t.styles['inputControl']} disabled={true} value={"https://vk.com/id" + this.props.t.state.userData.social.vk}></input>
                </div>}
                {this.props.t.state.userData && this.props.t.state.userData.social.fb !== "0" && <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'fbProfile')}</label><input autocomplete="off" className={this.props.t.styles['inputControl']} disabled={true} value={"http://www.facebook.com/profile.php?id=" + this.props.t.state.userData.social.fb}></input>
                </div>}
                {this.props.t.state.userData && this.props.t.state.userData.social.ok !== "0" && <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'okProfile')}</label><input autocomplete="off" className={this.props.t.styles['inputControl']} disabled={true} value={"https://ok.ru/" + this.props.t.state.userData.social.ok}></input>
                </div>}
                {this.props.t.state.userData && this.props.t.state.userData.social.gl !== "0" && <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'glProfile')}</label><input autocomplete="off" className={this.props.t.styles['inputControl']} disabled={true} value={this.props.t.state.userData.social.gl}></input>
                </div>}
                <div className={this.props.t.styles['inputRow']}>
                    <label className={this.props.t.styles['inputLabel']}>E-Mail {this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0" && <IconStatic t={this.props.t} name='verified' />}</label>
                    <div className={this.props.t.styles['inputAppend']}>
                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourEmail')} id="inputEmail" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'email')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'email') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.email.error }, "settings", "email"); this.props.t.Interface.checkForm(this.props.t, "settings", "email"); }} autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.inputs.settings.email && this.props.t.state.form.settings.email.error ? this.props.t.styles['hasError'] : ''].join(' ')} disabled={this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0" ? true : false} name="email" value={this.props.t.state.form.settings.email.data}></input>
                        <button className={[this.props.t.styles['btnAppend'], this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0" ? this.props.t.styles['approved'] : ''].join(' ')} disabled={this.props.t.state.form.settings.email.error || (this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0") ? true : false} onClick={() => { !this.props.t.state.form.settings.email.error && (this.props.t.state.userData && this.props.t.state.userData.settings.email === "0") && this.props.t.Interface.SettingsSetEmail(this.props.t) }} type="button">{this.props.t.state.Settings.mailProcess ? this.props.t.Interface.getText(this.props.t, 'basic', 'sending') : this.props.t.state.userData && this.props.t.state.userData.settings.email !== "0" ? this.props.t.Interface.getText(this.props.t, 'basic', 'confirmed') : this.props.t.Interface.getText(this.props.t, 'basic', 'confirm')}</button>
                        <Tooltip isOpen={this.props.t.state.inputs.settings.email && this.props.t.state.form.settings.email.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputEmail">
                            {this.props.t.state.form.settings.email.error}
                        </Tooltip>
                    </div>
                </div>
                <div className={this.props.t.styles['socialContainer']}>

                    {this.props.t.state.userData && (this.props.t.state.userData.social.vk === "0" || this.props.t.state.userData.social.fb === "0" || this.props.t.state.userData.social.ok === "0" || this.props.t.state.userData.social.gl === "0") && <div className={this.props.t.styles['plus']}>
                        +
			        </div>}

                    {Object.keys(this.props.t.config.auth).map((item, i) => (
                        this.props.t.state.userData && this.props.t.state.userData.social[item] === "0" && <button className={[this.props.t.styles['btnSocial'], this.props.t.styles[item]].join(' ')} onClick={() => this.props.t.Interface.doSocialNetworkLink(this.props.t, item, false)} type="button" value={item}>{this.props.t.config.auth[item].name}</button>
                    ))}
                </div>
            </>
        )
    }

}