import React from 'react';
import IconStatic from '../../components/IconStatic';

import { Spinner, Tooltip } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QR from "react-qr-code";

class TFAModal extends React.Component {
    constructor(props) {
        super(props);
        this.tfaPinsArray = [];
    }

    componentDidMount() {
        this.props.t.Interface.SettingsGetTfa(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPassword");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin1");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin2");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin3");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin4");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin5");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "tfaPin6");
    }

    handlePinCode(code, pinNumber) {
        if (isFinite(String.fromCharCode(code))) {
            this.props.t.updateState(this.props.t, "form", String.fromCharCode(code), "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        } else if (isFinite(String.fromCharCode(code - 48))) {
            this.props.t.updateState(this.props.t, "form", String.fromCharCode(code - 48), "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        } else if (code === 8 || code === 127) {
            this.props.t.updateState(this.props.t, "form", '', "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        }
    }

    render() {
        return (
            <div className={this.props.t.styles['tfaContainer']}>
                <div className={this.props.t.styles['tfaHeader']}>
                    {this.props.t.Interface.getText(this.props.t, 'tfa', 'typeYourPassword')}
                </div>
                <div className={this.props.t.styles['tfaWrapper']}>
                    {!this.props.t.state.Settings.tfa ?
                        <div className={this.props.t.styles['loaderBlock']}><Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '100px auto', color: "#4986f5" }} /></div>
                        : !this.props.t.state.Settings.tfa.secret ?
                            <div className={this.props.t.styles['item']}>
                                <div className={this.props.t.styles['inputRow']}>
                                    <label>{this.props.t.Interface.getText(this.props.t, 'tfa', 'typeYourPassword')}</label>

                                    <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourPassword')} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'tfaPassword')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'tfaPassword') }} id="inputTfaPassword" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.tfaPassword.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" type="password" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.settings.tfaPassword.error }, 'settings', 'tfaPassword'); this.props.t.Interface.checkForm(this.props.t, 'settings', 'tfaPassword') }} value={this.props.t.state.form.settings.tfaPassword.data}></input>
                                    <Tooltip isOpen={this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show && !this.props.t.state.inputs.settings.tfaPassword && this.props.t.state.form.settings.tfaPassword.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputTfaPassword">
                                        {this.props.t.state.form.settings.tfaPassword.error}
                                    </Tooltip>
                                </div>
                                <div className={this.props.t.styles['codeRow']}>
                                    <label>{this.props.t.Interface.getText(this.props.t, 'tfa', 'enterPin')}</label>
                                    <div className={this.props.t.styles['tfaValidationWrapper']}>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[1] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 1)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin1.disabled} value={this.props.t.state.form.settings.tfaPin1.data}></input>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[2] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 2)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin2.disabled} value={this.props.t.state.form.settings.tfaPin2.data}></input>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[3] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 3)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin3.disabled} value={this.props.t.state.form.settings.tfaPin3.data}></input>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[4] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 4)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin4.disabled} value={this.props.t.state.form.settings.tfaPin4.data}></input>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[5] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 5)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin5.disabled} value={this.props.t.state.form.settings.tfaPin5.data}></input>
                                        <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[6] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 6)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin6.disabled} value={this.props.t.state.form.settings.tfaPin6.data}></input>
                                    </div>
                                </div><button onClick={() => this.props.t.Interface.SettingsSetTfa(this.props.t)} className={[this.props.t.styles['btn'], this.props.t.styles['submit']].join(' ')} disabled={this.props.t.state.form.settings.tfaPassword.data.length && !this.props.t.state.form.settings.tfaPassword.error && !this.props.t.state.form.settings.tfaPin1.disabled && !this.props.t.state.form.settings.tfaPin2.disabled && !this.props.t.state.form.settings.tfaPin3.disabled && !this.props.t.state.form.settings.tfaPin4.disabled && !this.props.t.state.form.settings.tfaPin5.disabled && !this.props.t.state.form.settings.tfaPin6.disabled && this.props.t.state.form.settings.tfaPin1.data.length && this.props.t.state.form.settings.tfaPin2.data.length && this.props.t.state.form.settings.tfaPin3.data.length && this.props.t.state.form.settings.tfaPin4.data.length && this.props.t.state.form.settings.tfaPin5.data.length && this.props.t.state.form.settings.tfaPin6.data.length ? false : true} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'deactivate')}</button>
                            </div>
                            : <>
                                <div className={this.props.t.styles['item']}>
                                    <div className={this.props.t.styles['title']}>
                                        {this.props.t.Interface.getText(this.props.t, 'tfa', 'downloadApp')}
                                    </div>
                                    <div>
                                        {this.props.t.Interface.getText(this.props.t, 'tfa', 'downloadAndInstall')} <a href="https://www.authy.com/" rel="noreferrer noopener" target="_blank">Authy</a> {this.props.t.Interface.getText(this.props.t, 'basic', 'or')} <a href="https://support.google.com/accounts/answer/1066447" rel="noreferrer noopener" target="_blank">Google 2FA</a> {this.props.t.Interface.getText(this.props.t, 'tfa', 'forYourPhone')}
                                    </div>
                                </div>
                                <div className={[this.props.t.styles['item'], this.props.t.styles['flexRow']].join(' ')}>
                                    {this.props.t.state.width > 900 && <div className={this.props.t.styles['image']}><QR value={this.props.t.state.Settings.tfa.uri.split('%3F').join('&').split('%3A').join('?')} size="130" /></div>}
                                    <div>
                                        {this.props.t.state.width > 900 ? <><div className={this.props.t.styles['title']}> {this.props.t.Interface.getText(this.props.t, 'tfa', 'scanQR')}
                                        </div>{this.props.t.Interface.getText(this.props.t, 'tfa', 'scanQRWithCamera')} </> : <><div className={this.props.t.styles['title']}>{this.props.t.Interface.getText(this.props.t, 'tfa', 'openWithLink')}</div><a href={this.props.t.state.Settings.tfa.uri.split('%3F').join('&').split('%3A').join('?')}>{this.props.t.Interface.getText(this.props.t, 'tfa', 'openInApp')}</a></>}
                                        <span className={this.props.t.styles['divider']}></span>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'tfa', 'tfaManualEnter')}
                                        </div>
                                        <div className={this.props.t.styles['secretCode']}>
                                            <div className={this.props.t.styles['value']}>
                                                {this.props.t.state.Settings.tfa.secret && this.props.t.state.Settings.tfa.secret.slice(0, 16).match(/.{1,4}/g).join(' ')}
                                            </div>
                                            <CopyToClipboard text={this.props.t.state.Settings.tfa.secret.slice(0, 16)}
                                                onCopy={() => { this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard); setTimeout(() => this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard), 500) }}>
                                                <button type="button"><IconStatic t={this.props.t} name="copy" /></button>
                                            </CopyToClipboard>
                                            <div className={[this.props.t.styles['copyTooltip'], this.props.t.state.clipboard ? this.props.t.styles['visible'] : ''].join(' ')}>
                                                {this.props.t.Interface.getText(this.props.t, 'basic', 'copied')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['item']}>
                                    <div className={this.props.t.styles['inputRow']}>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'tfa', 'typeYourPassword')}</label>

                                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourPassword')} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'tfaPassword')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'tfaPassword') }} id="inputTfaPassword" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.tfaPassword.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" type="password" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.settings.tfaPassword.error }, 'settings', 'tfaPassword'); this.props.t.Interface.checkForm(this.props.t, 'settings', 'tfaPassword') }} value={this.props.t.state.form.settings.tfaPassword.data}></input>
                                        <Tooltip isOpen={this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show && !this.props.t.state.inputs.settings.tfaPassword && this.props.t.state.form.settings.tfaPassword.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputTfaPassword">
                                            {this.props.t.state.form.settings.tfaPassword.error}
                                        </Tooltip>

                                    </div>
                                    <div className={this.props.t.styles['codeRow']}>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'tfa', 'enterPin')}</label>
                                        <div className={this.props.t.styles['tfaValidationWrapper']}>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[1] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 1)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin1.disabled} value={this.props.t.state.form.settings.tfaPin1.data}></input>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[2] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 2)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin2.disabled} value={this.props.t.state.form.settings.tfaPin2.data}></input>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[3] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 3)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin3.disabled} value={this.props.t.state.form.settings.tfaPin3.data}></input>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[4] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 4)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin4.disabled} value={this.props.t.state.form.settings.tfaPin4.data}></input>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[5] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 5)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin5.disabled} value={this.props.t.state.form.settings.tfaPin5.data}></input>
                                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[6] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 6)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin6.disabled} value={this.props.t.state.form.settings.tfaPin6.data}></input>
                                        </div>
                                    </div><button onClick={() => this.props.t.Interface.SettingsSetTfa(this.props.t)} className={[this.props.t.styles['btn'], this.props.t.styles['submit']].join(' ')} disabled={this.props.t.state.form.settings.tfaPassword.data.length && !this.props.t.state.form.settings.tfaPassword.error && !this.props.t.state.form.settings.tfaPin1.disabled && !this.props.t.state.form.settings.tfaPin2.disabled && !this.props.t.state.form.settings.tfaPin3.disabled && !this.props.t.state.form.settings.tfaPin4.disabled && !this.props.t.state.form.settings.tfaPin5.disabled && !this.props.t.state.form.settings.tfaPin6.disabled && this.props.t.state.form.settings.tfaPin1.data.length && this.props.t.state.form.settings.tfaPin2.data.length && this.props.t.state.form.settings.tfaPin3.data.length && this.props.t.state.form.settings.tfaPin4.data.length && this.props.t.state.form.settings.tfaPin5.data.length && this.props.t.state.form.settings.tfaPin6.data.length ? false : true} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'activate')}</button>
                                </div></>}
                </div>
            </div >
        );
    }
}

export default TFAModal;
