module.exports = {
    system: { code: 'pl', name: 'Polskie' },
    aml: {
        title: 'Przeciwdziałanie praniu brudnych pieniędzy',
        description: 'Kontrola bezpieczeństwa',
        content: ''
    },
    auth: {
        accountCreated: 'Zarejestrowane konto',
        agreeText: 'Potwierdzam, że mam 18 lat i przeczytałem',
        agreeTos: 'warunki świadczenia usług',
        alreadyRegistered: 'Już zarejestrowany?',
        byLogin: 'Login i hasło',
        bySocialNetworks: 'Za pośrednictwem sieci społecznościowych',
        createAccount: 'Utwórz konto',
        forgotPassword: 'Zapomniałeś hasła?',
        loading: 'Ładowanie...',
        login: 'Logowanie',
        loginTitle: 'Autoryzacja witryny',
        maxLoginLength: 'Maksymalna długość logowania:',
        maxPasswordLength: 'Maksymalna długość hasła:',
        minLoginLength: 'Minimalna długość logowania:',
        minPasswordLength: 'Minmal Długość hasła:',
        passwordConfirmation: 'Powtórz hasło',
        passwordsMustBeSame: 'Hasła muszą być zgodne',
        pleaseWait: 'Proszę zaczekać...',
        registration: 'Rejestracja',
        registrationTitle: 'Zarejestruj się na stronie',
        symbols: 'postacie',
        yourLogin: 'Twój login',
        yourPassword: 'Twoje hasło',
        yourEmail: 'Twój adres e-mail',
        logoutConfirm: 'Naprawdę chcesz się stąd wydostać?',
        passwordRecovery: 'Resetuj hasło',
        newPassword: 'Nowe hasło',
        copied: 'Skopiowane',
        logout: 'Out'
    },
    basic: {
        yes: 'Tak',
        no: 'Nie',
        confirm: 'Potwierdzać',
        confirmed: 'Oddany',
        cancel: 'Uchylenie',
        sending: 'Wysyłanie...',
        sent: 'Zatwierdź',
        save: 'Zapisz',
        create: 'Stwórz',
        or: 'albo',
        activate: 'Aktywuj',
        deactivate: 'Dezaktywuj',
        prevPage: 'Poprzednia strona',
        nextPage: 'Następna strona',
        of: 'wyjdź',
        balance: 'Bilans',
        copy: 'Kopiuj',
        copied: 'Skopiowane'
    },
    bonus: {
        permanentBonus: 'Premia stała',
        tenPercent: '10% za każdy depozyt!',
        more: 'Czytaj więcej',
        fastRegistration: 'Szybkie odprawy',
        noVerification: 'bez weryfikacji',
        fastWithdraws: 'Szybkie wypłaty',
        highLimits: 'wysokie limity',
        loyaltyProgramm: 'Program lojalnościowy',
        winCashback: 'Do 1,2% za każde zwycięstwo!',
        levelSystem: 'System poziomu',
        bigPercents: 'Duże wartości procentowe',
        payForWin: 'Zapłać za wygraną',
        butNoBets: 'nie dla zakładów',
        affiliateProgramm: 'Program partnerski',
        join: 'Dołącz',
        revenueShare: '40% Udział przychodów',
        anytimeWithdraw: 'wyjście w dowolnym momencie',
        affiliateCabinet: 'Gabinet afiliacyjny',
        detailStat: 'szczegółowe statystyki',
        title: 'Promo-kod',
        promoEveryDay: 'Promo-kody codziennie w naszej grupie VK',
        enterPromoCode: 'Wprowadź promo-kod'
    },
    fair: {
        title: 'Gwarancja licencji i uczciwości',
        description: 'Gwarantujemy uczciwą grę dla każdego',
        fairDescription1: 'Brillx Casino rozumie, jak ważne jest stworzenie środowiska bezpieczeństwa, aby użytkownicy byli pewni uczciwości naszych gier.',
        fairDescription2: 'Dlatego w naszym kasynie korzystamy z gier dostarczanych przez najlepsze firmy rozwijające oprogramowanie dla kasyn online. Wśród nich firm, takich jak',
        fairDescription3: 'Jako założyciel eCOGRA i IGC, Microgaming wyprzedza innych producentów gier w kasynie, wyznaczając najwyższe standardy w branży hazardowej jako całości, zwłaszcza uczciwości gier online, wybierając najbardziej odpowiedzialnych operatorów i chroniąc interesy graczy.',
        andOther: 'i inne',
        also: 'Zapewniamy również gry stworzone przez firmę',
        defaultCasino: 'Zwyczajne kasyno online',
        brillxCasino: 'Brillx Kontrola uczciwości Casino',
        defaultCasinoDescription1: 'Możesz postawić zakład, który jest napisany na serwerze kasyna',
        defaultCasinoDescription2: 'Twoje dane są zbierane i przechowywane na serwerach kasyn',
        defaultCasinoDescription3: 'Sprawdzanie uczciwości gry jest niemożliwe — wszystkie algorytmy są rejestrowane i zapisywane po stronie kasyna',
        defaultCasinoDescription4: 'Aby wygrać, musisz przejść długą procedurę autoryzacji',
        defaultCasinoDescription5: 'Wypłacalność kasyna w pytaniu, ponieważ jego bilans nie jest publikowany nigdzie',
        brillxCasinoDescription1: 'Kopia danych od dostawców zakładów gracza jest zapisywana w blockchain i nie może zostać zmieniona',
        brillxCasinoDescription2: 'Statystyki gry dla ludzi są szyfrowane i rejestrowane w blockchain odwiązane do danych osobowych gracza',
        brillxCasinoDescription3: 'Kontrola integralności odbywa się za pomocą technologii blockchain',
        brillxCasinoDescription4: 'Wypłaty wymagają standardowej autoryzacji KYC, bez opłaty za transfer',
        brillxCasinoDescription5: 'Otwarte raportowanie i ogromny basen dla wypłat graczy'
    },
    game: {
        loading: 'Ładowanie',
        lobby: 'Lobby',
        fullScreen: 'Pełny ekran',
        deposit: 'Depozyt',
        betsAmount: 'Kwota zakładu',
        totalRTP: 'Całkowity RTP',
        averageBet: 'Średni zakład',
        topWin: 'Najlepsza wygrana'
    },
    help: {
        title: 'Pomoc i wsparcie',
        description: 'Odpowiedzi na najczęstsze pytania i komunikacja z obsługą klienta.',
        connectTypes: 'Metody komunikowania się z nami',
        operatorsOnline: 'Operatorzy online 24 godziny na dobę',
        supportLive: 'Pomoc techniczna (Live)',
        onlineChat: 'Czat na żywo',
        averageTimeChat: 'Średni czas reakcji: 10 minut',
        supportEmail: 'Obsługa klienta (e-mail)',
        averageTimeEmail: 'Średni czas reakcji: 1 dzień',
        generalQuestions: 'Kwestie ogólne',
        aboutSite: 'O stronie',
        aboutSiteContent: 'Brillx to kasyno online z międzynarodowymi licencjami i nazwą. Oferujemy ponad 6 000 gier w katalogu, szeroką gamę imprez sportowych i wiele mini-gier. Obsługujemy wiele systemów płatniczych i większość popularnych kryptowalut.',
        bonus: 'Bonus',
        bonuses: 'Bonusy',
        onPage: 'Na stronie',
        manyFreeBonuses: 'istnieje kilka sposobów, aby uzyskać pieniądze na saldo całkowicie za darmo!',
        bonus1: 'Bonus rejestracyjny. Po zarejestrowaniu się na stronie masz 60 minut na wprowadzenie kodu zaproszenia i uzyskanie do 10.00 RUB.',
        bonus2: 'Bonus rejestracyjny. Po zarejestrowaniu się na stronie masz 60 minut na wprowadzenie kodu zaproszenia i uzyskanie do 10.00 RUB.',
        bonus3: 'Bonus rejestracyjny. Po zarejestrowaniu się na stronie masz 60 minut na wprowadzenie kodu zaproszenia i uzyskanie do 10.00 RUB.',
        bonus4: 'Bonus rejestracyjny. Po zarejestrowaniu się na stronie masz 60 minut na wprowadzenie kodu zaproszenia i uzyskanie do 10.00 RUB.',
        promocodes: 'Kody promocyjne',
        inviteFriend: 'Zaproś znajomego',
        balanceRefilling: 'Uzupełnianie bilansu',
        wallet: 'Portfel',
        howToRefill: 'Jak uzupełnić saldo?',
        commissions: 'Prowizja',
        periodOfRefilling: 'Warunki rejestracji',
        withdrawal: 'Wypłaty',
        howToWithdraw: 'Jak wypłacić środki?',
        periodOfWithdrawal: 'Oś czasu wypłaty',
        clickToRefill: 'Kliknij przycisk, aby uzupełnić saldo',
        atRightCorner: 'w prawym górnym rogu strony. Wybierz odpowiednią dla Ciebie metodę płatności, wprowadź kwotę wpłaty i kliknij przycisk Przejdź do Płatności.',
        minimalRefilling: 'Minimalne uzupełnienie',
        commissionsOnPage: 'Prowizje wszystkich metod płatności są wymienione na stronie',
        clickToWithdraw: 'Kliknij przycisk, aby wypłacić pieniądze z konta',
        averageTimeWithdraw: 'Wszystkie wyniki są przetwarzane średnio w ciągu jednej godziny od momentu złożenia wniosku.',
        allTransactions: 'Wszystkie transakcje są przetwarzane natychmiast. Opóźnienia mogą wystąpić tylko wtedy, gdy występują problemy z metodą płatności, którą uzupełniasz saldo.',
        inCaseOfProblems: 'Jeśli wystąpią problemy, skontaktuj się z naszym działem obsługi klienta i podaj najbardziej szczegółowe informacje na temat problemu.',
        atRightCornerWithdraw: 'w prawym górnym rogu strony. Wybierz odpowiednią dla Ciebie metodę wypłaty, wprowadź kwotę i kliknij Przejdź do Płatności.',
        minimalWithdrawal: 'Minimalne wycofanie',
        ifBigAmount: 'Jeśli kwota wypłaty jest duża, wypłata może trwać nieco dłużej, zwykle nawet do godziny. W nocy (00:00am do 09:00 MSC), ustalenia mogą trwać kilka godzin, ponieważ w tym czasie agregator płatności przetwarza wnioski o wypłatę na dłużej.',
        sometimes: 'W bardzo rzadkich przypadkach wycofanie może trwać do 24 godzin, ze względu na obciążenia bankowe i tak dalej. Jeśli pieniądze nie dotrą po tym czasie, skontaktuj się z naszym działem obsługi klienta i podaj najbardziej szczegółowe informacje na temat problemu.',
        checkCorrectPurse: 'Upewnij się również, że poprawnie określasz szczegóły dla wyjścia. Aby uspokoić portfel, który wskazałeś na wypłatę, musisz przejść do historii transakcji i otworzyć szczegóły, klikając żądaną transakcję.'
    },
    interface: {
        months: {
            jan: 'sty.',
            feb: 'lut.',
            mar: 'mar.',
            apr: 'kwi.',
            may: 'mai.',
            jun: 'cze.',
            jul: 'lip.',
            aug: 'sie.',
            sep: 'wrz.',
            oct: 'paź',
            nov: 'lis.',
            dec: 'gru.'
        },
        year: 'r.',
        confirmPasswordRecovery: 'Potwierdź zresetowanie hasła, klikając link wysłany na Twój adres e-mail',
        passwordWasChanged: 'Hasło zostało pomyślnie zmienione, możesz się zalogować, aby użyć nowych danych',
        confirmEmail: 'Potwierdź adres e-mail poprzez link',
        emailWasConfirmed: 'Adres e-mail został pomyślnie potwierdzony',
        accountLinked: 'Konto zostało pomyślnie połączone',
        bonusGranted: 'Bonus otrzymany'
    },
    landing: {
        newGames: 'Nowe gry',
        topGames: 'Popularne gry',
        brillxSelect: 'Wybór Brillx',
        daylyLiveRtp: 'RTP na żywo - 24 godziny',
        brillxCasino: 'Brillx kasyno',
        casinoInfo1: 'Sprawdź szeroki wachlarz ekscytujących gier tutaj w Brillx.GG, miejscu na automacie dostosowanym do Twojej satysfakcji. Tysiące najwyższej klasy automatów są dostępne dla graczy na całym świecie. Niezależnie od tego, czy jest to ogromne jackpoty, sloty pokera lub tematyczne automaty do gier, których potrzebujesz, mamy wszystko, czego potrzebujesz!',
        casinoInfo2: 'Doświadcz łatwości szybkiego zameldowania, klikając przycisk odprawy już teraz! Ciesz się chwilami, kiedy grasz na prawdziwe pieniądze automaty z wiodących dostawców gier.',
        casinoInfo3: 'Nasze kasyno z szybkimi wypłatami i ogromnym wyborem różnych odmian automatów działa 24/7 dla Ciebie - wystarczy wybrać grę i zacząć grać!',
        casinoInfo4: 'Zagraj szczeliny online',
        casinoInfo5: 'Nigdy nie psujemy Twojej zabawy, więc możesz grać we wszystkie nasze gry bez pobierania czegokolwiek na komputerze.',
        casinoInfo6: 'Przeglądaj tysiące slotów, wybierz grę, którą lubisz, i kliknij „PLAY” - to takie proste!',
        casinoInfo7: 'Kasyno gry wrzutowe są jedną z najbardziej popularnych gier. Nie tylko ze względu na ogromne zachęty można uzyskać, ale także ze względu na ekscytującą grafikę, dobrą muzykę i ogólny przyjemny efekt wizualny gry.',
        casinoInfo8: 'Możesz nawet wypróbować każdy z naszych automatów, przechodząc do trybu rozrywkowego - niesamowita funkcja, która pozwala grać na wszystkie nasze automaty bez pobierania i bez rejestracji jest darmowa! Dostęp do niego można uzyskać za pośrednictwem telefonu komórkowego, komputera lub tabletu. Odkryj jedne z najlepszych automatów dzisiaj, że można grać za darmo bez konieczności pobierania, i przeżyj wyjątkową przygodę w najlepszych automatach w kasynie na całym świecie. Zrób najlepszy strzał dzisiaj i niech los zdecyduje, co cię czeka!',
        casinoInfo9: 'Na co czekasz? Dziś może być twój szczęśliwy dzień!',
        casinoInfo10: 'Tysiące automatów',
        casinoInfo11: 'Oto różne rodzaje automatów do gier, które można znaleźć w naszym kasynie:',
        casinoInfo12: '3 gniazda na bębnach',
        casinoInfo13: '3 sloty na bębnach są najlepiej znane jako klasyczne lub tradycyjne automaty do gier. Kołowrotki to pionowe linie, które można znaleźć w grze, gdzie symbole są kaskadowo. Jeśli szukasz dreszczyku emocji, spróbuj zagrać na 3 bębnach automat! Niektóre z gier, w które można grać są Book of Alchemy, Watford FC Slot i wiele innych.',
        casinoInfo14: '5 gniazd na bębnach',
        casinoInfo15: "Zdobądź większą szansę na wygranie kombinacji, grając w 5 bębnów! Większość 5 slotów na bębnach jest płatna za każdym razem, gdy 3, 4 lub 5 znaków przypada na bębnach. Możesz także otrzymać mnożniki wygranych, rundy bonusowe lub darmowe spiny w zależności od gry. Niektóre z 5 gier na bębnie to Vikings Go Berzerk, Legacy of Dead i TH's Rich.",
        casinoInfo16: 'Progresywne',
        casinoInfo17: 'Progresywne szczeliny są podstępem wśród wszystkich typów automatów do gier. Wielkość jackpota wzrasta wraz z postępem gry. Czekają na ciebie ogromne miliony, a ty możesz stać się jednym z najbogatszych ludzi do tej pory!',
        casinoInfo18: 'Ruletka',
        casinoInfo19: 'Ruletka jest grą, która stawia na poszczególnych numerów na stole, kolor, typ liczby, lub kombinacji. Sprawdź swoje szczęście i wygrać, jeśli to, co postawić na uderza w koło. Zagraj w nasze wielkie gry w ruletkę w czasie rzeczywistym teraz!',
        casinoInfo20: 'Poker',
        casinoInfo21: 'Jeśli jesteś zaznajomiony z pokera w zwykłym kasynie, możesz spróbować grać go teraz, korzystając z naszego szerokiego wyboru gier wideo w pokera online. Przygotuj się na niezapomnianą grę już dziś! Dzięki animacji w wysokiej rozdzielczości i świetnym specjalnym rundom wideo na pewno wrócisz po nową wygraną.',
        casinoInfo22: 'Baccarat',
        casinoInfo23: 'Baccarat jest jedną z najpopularniejszych gier na świecie. Pokonaj krupiera, obstawiając na rękę najbliższą 9. Możesz także wygrać, jeśli istnieje remis. Brzmi ekscytująco, naprawdę? Przygotujcie zakłady i postawcie po zwycięskiej stronie!',
        casinoInfo24: 'Blackjack',
        casinoInfo25: 'W blackjacka, można pokonać krupiera, jeśli masz rękę najbliżej 21. Masz szansę podwoić swój zakład, gdy grasz w nasze majestatyczne automaty do gry w blackjacka!',
        gamesProviders: 'Dostawcy gier',
        showMore: 'Pokaż więcej',
        hideInfo: 'Ukryj informacje',
        allGames: 'Wszystkie gry',
        providers: 'Dostawcy',
        new: 'Nowość',
        tournaments: 'Bonus Buy',
        slots: 'Szczeliny',
        roulette: 'Ruletka',
        card: 'Karty',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Zagraj', title: 'Stawki na żywo' },
    lobby: {
        providers: 'Dostawcy',
        recent: 'Niedawne',
        top: 'Popularny',
        new: 'Nowość',
        favorite: 'Ulubione',
        nothingFound: 'Nic nie znaleziono',
        play: 'BAWIĆ SIĘ',
        demo: 'DEMO',
        forExample: 'Na przykład'
    },
    log: { date: 'Data', type: 'Operacja', amount: 'Kwota', id: 'ID' },
    loyalty: {
        title: 'Program lojalnościowy',
        description: 'Otrzymuj cashback, który zależy od poziomu lojalności na każdym wniesionym zakładzie',
        cabinet: 'Szafa',
        myCabinet: 'Moje biuro',
        payLog: 'Dziennik memoriałowy',
        log: 'Logi',
        level: 'Warstwa',
        totalWins: 'Kwota wygranej',
        totalBets: 'Kwota zakładu',
        availableBalance: 'Dostępne saldo',
        get: 'Zabrać',
        cashback: 'Cashback',
        emptyHistory: 'Historia zaginęła',
        operationID: 'ID operacji',
        amount: 'Kwota',
        date: 'Data',
        operationDate: 'Data operacji',
        operationType: 'Operacja',
        balanceBefore: 'Równowaga',
        balanceAfter: 'Saldo po'
    },
    menu: {
        affProgramm: 'Partnerzy',
        fairGame: 'Fair play',
        free: 'Bezpłatny',
        games: 'Gry',
        lobby: 'Lobby',
        promo: 'Promocja',
        loyaltyProgramm: 'Lojalność',
        promotions: 'Zapasy',
        support: 'Dział pomocy technicznej',
        settings: 'Ustawienia',
        history: 'Historia gry',
        more: 'Więcej',
        profile: 'Profil',
        bonusCode: 'Promo-kod'
    },
    noty: { error: 'Błąd', success: 'Powieść się' },
    profile: {
        wallet: 'Portfel',
        onSiteFrom: 'Na miejscu z',
        totalBets: 'Kwota zakładu',
        betsCount: 'Zakłady',
        totalWins: 'Kwota wygranej',
        bestWin: 'Najlepsza wygrana',
        emptyHistory: 'Historia zaginęła',
        balanceAfter: 'Saldo po'
    },
    partner: {
        maxCodeLength: 'Maksymalna długość kodu:',
        minCodeLength: 'Minimalna długość kodu:',
        maxNameLength: 'Maksymalna długość nazwy:',
        minNameLength: 'Minimalna długość nazwy:',
        symbols: 'postacie',
        confirmDeleteCampgain: 'Czy na pewno chcesz usunąć kampanię?',
        campgains: 'Kampanie',
        refresh: 'Aktualizacja',
        export: 'Eksport',
        campgainName: 'Nazwa kampanii',
        affiliateLink: 'Link partnerski',
        name: 'Tytuł',
        creationDate: 'Data utworzenia',
        clicks: 'Przejścia',
        link: 'Nr ref.',
        manage: 'Zarządzanie',
        noCampgains: 'Brak aktywnych kampanii reklamowych',
        namePlaceholder: 'Nazwa wyświetlana w panelu',
        general: 'Generał',
        mainAffiliateLink: 'Główny link partnerski',
        registrations: 'Rejestracje',
        totalProfit: 'Wspólny zysk',
        availableBalance: 'Dostępne saldo',
        get: 'Zabrać',
        emptyHistory: 'Historia zaginęła',
        operationID: 'ID operacji',
        amount: 'Kwota',
        date: 'Data',
        operationDate: 'Data operacji',
        operationType: 'Operacja',
        playerID: 'ID gracza',
        balanceAfter: 'Saldo po',
        balanceBefore: 'Równowaga',
        log: 'Dziennik memoriałowy',
        players: 'Gracze',
        registration: 'Rejestracja',
        registrationDate: 'Data rejestracji',
        balance: 'Bilans',
        noPlayers: 'Brak graczy gości',
        totalBets: 'Kwota zakładu',
        totalDeps: 'Kwota depozytu',
        totalWithdraws: 'Suma ustaleń',
        registrationSource: 'Źródło rejestracji',
        mainLink: 'Łącze główne',
        player: 'Gracz',
        registrationsCount: 'Rejestracja',
        bets: 'Wytyczanie',
        profit: 'Zysk',
        stat: 'Statystyka',
        totalStat: 'Ogólna statystyka',
        partnerCabinet: 'Biuro Partnera',
        partnerID: 'ID partnera'
    },
    privacy: {
        title: 'Polityka prywatności',
        description: 'Przepisy ogólne i informacje',
        redaction: 'Redakcja:',
        content: ''
    },
    promo: {
        linkVk: 'Zwiąż konto VK, aby uzyskać bonusy',
        completeAll: 'Dopasuj wszystkie warunki i uzyskaj',
        toBalanceForRepost: 'na bilans dla repost!',
        link: 'Wiązania',
        join: 'Wejście',
        allowNotifications: 'Pozwól na powiadomienia',
        make: 'Czy',
        toProfileVkAccount: 'do profilu konta VK.',
        toOurVkGroup: 'do naszej grupy VK.',
        fromOurGroup: 'od naszej społeczności.',
        repostToYourPage: 'odświeżyć wpis na jego stronie.',
        mustBePublic: 'Twoja strona VK musi być publiczna, inaczej nie będziemy w stanie sprawdzić, czy nie ma odświeżenia.',
        dontDelete: 'Jeśli usuniesz post wcześniej niż 2 tygodnie po odświeżeniu, kwota bonusu zostanie potrącona z salda.',
        joinAndGet: 'Dołącz do grupy i zdobądź',
        toBalance: 'na bilansie!',
        bonusGranted: 'Bonus otrzymany pomyślnie!',
        momentalBonus: 'Dołącz do naszej grupy VK i uzyskaj natychmiastowy bonus na swoje saldo! Profil VK musi być publiczny lub nie będziemy mogli sprawdzić subskrypcji.',
        vkDoesntLinked: 'Konto VK nie jest powiązane',
        allowNotificationsAndGet: 'Zezwalaj na powiadomienia ze strony naszej społeczności i',
        sendAnyMessage: 'Wyślij dowolną wiadomość do naszej grupy VK, umożliwiając wysyłanie powiadomień. Miła niespodzianka - okresowo będziemy wysyłać informacje o promocjach i różnych bonusach!',
        rollAndGetBonus: 'Rejs ruletkę i uzyskać premie!',
        rollOncePer: 'Twist ruletkę raz na 15 minut i dostać się do',
        rollEveryDay: 'Obracaj kołem bez ograniczeń każdego dnia!',
        untilNextTry: 'Zanim następna próba zostanie pozostawiona',
        subscribeTg: 'Zarejestruj się na naszym kanale telegramowym, aby uzyskać nowe kody promocyjne!',
        beFirst: 'Bądź jednym z pierwszych, który zdobędziesz świeży kod promocyjny, poznaj nowe bonusy i innowacje!',
        referrals: 'Polecenia',
        getPercent: 'Zaproś znajomych i zarobić 40% korzyści w kasynie!',
        copyLink: 'Skopiuj link',
        inviteFriend: 'Zaproś znajomego',
        getBonus: 'Zdobądź bonus',
        affiliateProgramm: 'Program partnerski',
        sendReview: 'Zgłoś opinię na temat projektu w naszej grupie i uzyskaj',
        bonusToDeposit: 'bonus depozytowy',
        upTo: 'do',
        makeReviews: 'Zostaw opinię w naszej grupie VK, po zweryfikowaniu i opublikowaniu moderatora, premia zostanie automatycznie aktywowana na Twoim koncie.',
        makeReview: 'Opinie',
        ranks: 'Rangi',
        earnFromWin: 'Zdobywając rangi otrzymasz zwiększony cashback za każdą wygraną.',
        loyaltyProgramm: 'Program lojalnościowy',
        writeMessage: 'Napisz wiadomość',
        goToGroup: 'Przejdź do grupy',
        refreshing: 'Uaktualnij...',
        refreshStatus: 'Aktualizacja',
        roll: 'Obróć',
        linkAccount: 'Zwiąż konto',
        checking: 'Sprawdzanie...',
        get: 'Pobierz'
    },
    promotions: {
        title: 'Akcje i oferty',
        description: 'Nie przegap okazji, aby otrzymać bonus w naszych aktualnych akcjach',
        game: 'Gra',
        minDeposit: 'Min. depozyt',
        bonus: 'Bonus',
        addWager: 'Dodatkowy zakład',
        validUntil: 'Działa do',
        activation: 'Aktywacja',
        get: 'Pobierz',
        unlimit: 'nieograniczony',
        oneTime: 'jednorazowy',
        forEveryDeposit: 'za każdy depozyt',
        permanentBonus: 'Premia stała',
        any: 'każdy'
    },
    responsibility: {
        title: 'Odpowiedzialna gra',
        description: 'Wskazówki i zasady dotyczące odpowiedzialnej gry',
        content: ''
    },
    settings: {
        incorrectEmail: 'Adres e-mail nieprawidłowo',
        bigFileSize: 'Rozmiar pliku nie powinien przekraczać 5 MB.',
        genders: { man: 'Męskie', female: 'Kobiece', other: 'Inny' },
        requiredField: 'Wymagane pole',
        dataSuccessChanged: 'Dane zostały pomyślnie zaktualizowane',
        tfaLoginRequired: 'Ustaw login i hasło, aby skonfigurować uwierzytelniacz',
        general: 'Generał',
        login: 'Logowanie',
        vkProfile: 'Profil VK',
        glProfile: 'Profil Google',
        okProfile: 'Profil koledzy z klasy',
        fbProfile: 'Profil Facebook',
        security: 'Bezpieczeństwo',
        alterLogin: 'Dodaj alternatywną metodę logowania i hasła do swojego konta.',
        newPassword: 'Nowe hasło',
        repeatPassword: 'Powtórz hasło',
        saveActions: 'Zapisz operacje',
        currentPassword: 'Bieżące hasło',
        tfa: 'Uwierzytelnianie dwu',
        addTfa: 'Chroń swoje konto Brillx z dodatkowymi zabezpieczeniami',
        tfaSettings: 'Ustawienia 2FA',
        sessions: 'Sesje',
        device: 'Urządzenie',
        region: 'Region',
        ipAddress: 'Adres IP',
        date: 'Data',
        session: 'Sesja',
        active: 'Aktywny',
        closed: 'Zamknięte',
        verification: 'Weryfikacja',
        verificationDescription1: ' Aby poprawić poziom usług, prosimy o identyfikację dokumentów. Dzięki temu Twoje konto będzie bezpieczne, jeśli zostanie przywrócone, i zapewni dokładne ukierunkowane dostarczanie fizycznych prezentów.',
        verificationDescription2: 'Dopuszczalnym sposobem weryfikacji jest dostarczenie zdjęcia dowodu osobistego, prawa jazdy lub paszportu. Po zakończeniu tego procesu poinformujemy Cię pocztą elektroniczną.',
        frontPhoto: 'Zdjęcie przedniej części dokumentu tożsamości',
        selectFile: 'Wybierz plik',
        backPhoto: 'Zdjęcie tylne dokumentu tożsamości',
        fileIsNotSelected: 'Plik nie został wybrany',
        name: 'Nazwa',
        surname: 'Nazwisko',
        birthday: 'Data urodzin',
        gender: 'Płeć',
        country: 'Kraj',
        city: 'Miasto',
        yourName: 'Twoje imię',
        yourSurname: 'Nazwisko',
        verifyStatus1: 'Dokumenty są sprawdzane...',
        verifyStatus2: 'Zweryfikowane konto',
        verifyStatus3: 'Błąd sprawdzania dokumentu',
        verifyDescription1: 'Dokumenty są obecnie rozpatrywane, gdy tylko dokumenty zostaną zweryfikowane, Twoje konto zostanie zweryfikowane!',
        verifyDescription2: 'Twoje dane zostały zweryfikowane i potwierdzone przez personel ochrony.',
        verifyDescription3: 'Aby uzyskać szczegółowe informacje, skontaktuj się z pomocą techniczną',
        registrationCity: 'Miasto rejestracji',
        sendDocs: 'Prześlij do inspekcji',
        affiliateCabinet: 'Biuro Partnera'
    },
    tos: {
        title: 'Umowa z użytkownikiem',
        description: 'Warunki i zasady użytkowania',
        redaction: 'Redakcja:',
        content: ''
    },
    wallet: {
        deposit: 'Depozyt',
        withdraw: 'Wyjście',
        history: 'Historia',
        minDepositAmount: 'Minimalny depozyt',
        minWithdrawAmount: 'Minimalna wydajność przez',
        topMethods: 'Popularny',
        allMethods: 'Wszystkie metody',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Inne',
        success: 'Powieść się',
        canceled: 'Anulowane',
        error: 'Błąd',
        waiting: 'Czekanie',
        processing: 'Aplikacja jest przetwarzana',
        incorrectWalletValue: 'Numer portfela został wprowadzony',
        incorrectCardValue: 'Numer karty wprowadzony niepoprawnie',
        selectTemplate: 'Wybierz szablon',
        withdrawCreated: 'Aplikacja wypłat utworzona i będzie przetwarzana w ciągu 24 godzin',
        withdrawCanceled: 'Oferta wypłaty została anulowana',
        firstWithdraw: 'To twój pierwszy wniosek.',
        isDataCorrect: 'Czy jesteś pewien, że wprowadzanie danych jest poprawne?',
        payMethod: 'Metoda płatności',
        withdrawMethod: 'Metoda wycofania',
        selectMethod: 'Wybierz sposób',
        selectOperator: 'Wybierz operatora',
        refillAmount: 'Uzupełnianie',
        withdrawAmount: 'Kwota wypłaty',
        payTroubles: 'Jeśli masz problemy z płaceniem, spróbuj',
        payHere: 'Zapłać tutaj',
        pay: 'Przejdź do płatności',
        commission: 'Prowizja',
        onePayLimit: 'Limit jednorazowego uzupełniania',
        oneWithdrawLimit: 'Limit pojedynczego wyjścia',
        emptyHistory: 'Historia zaginęła',
        operationID: 'ID operacji',
        deposits: 'Uzupełnienia',
        withdraws: 'Wyniki',
        txAmount: 'Kwota transakcji',
        txDate: 'Data operacji',
        purse: 'Wynik',
        typePurse: 'Numer konta',
        toGet: 'Aby otrzymać',
        createWithdraw: 'Tworzenie aplikacji'
    },
    tfa: {
        tfa: 'Uwierzytelnianie dwu',
        typeYourPassword: 'Wprowadź swoje hasło',
        enterPin: 'Wprowadź 6-cyfrowy kod z aplikacji',
        downloadApp: 'Pobierz aplikację',
        downloadAndInstall: 'Pobierz i zainstaluj',
        forYourPhone: 'dla telefonu lub tabletu',
        scanQR: 'zeskanuj kod QR',
        scanQRWithCamera: 'Zeskanuj QR po lewej stronie za pomocą aparatu w telefonie',
        openWithLink: 'Otwórz przez link',
        openInApp: 'Otwórz w aplikacji',
        tfaManualEnter: 'Klawisz 2FA (wejście ręczne)'
    },
    pwa: {
        androidStep1: 'Otwórz przeglądarkę Chrome',
        androidStep1Description: 'Jeśli korzystasz z innej przeglądarki, otwórz tę stronę w Chrome',
        androidStep2: 'Kliknij menu przeglądarki',
        androidStep2Description: 'Menu przeglądarki to wielokropek znajdujący się w prawym górnym rogu przeglądarki',
        androidStep3: 'Wybierz "Dodaj do ekranu głównego"',
        androidStep3Description: 'Zobaczysz okno z informacją ("Dodaj do ekranu głównego"), w którym należy wybrać "Dodaj", po czym aplikacja zostanie dodana na pulpit Twojego urządzenia',
        androidAlert: 'Kroki mogą się różnić w zależności od wersji przeglądarki',
        iosStep1: 'Otwórz przeglądarkę Safari',
        iosStep1Description: 'Jeśli korzystasz z innej przeglądarki, otwórz tę stronę w Safari',
        iosStep2: 'Kliknij "Udostępnij"',
        iosStep2Description: 'Kliknij przycisk "Udostępnij" u dołu ekranu, po czym otworzy się okno wyboru parametrów',
        iosStep3: 'Wybierz "Dodaj do ekranu głównego"',
        iosStep3Description: 'Wybierz "Dodaj do ekranu głównego" i kliknij "Dodaj"',
        alert: 'Kroki mogą się różnić w zależności od wersji przeglądarki',
        installApp: 'Zainstaluj aplikację Brillx',
        back: 'Wstecz',
        app: 'Aplikacja mobilna',
        installAppForMobile: "Zainstaluj nową wygodną aplikację Brillx na iOS i Androida",
        install: 'Zainstaluj'
    },
    undefined: '???'
};