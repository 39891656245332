import React from 'react';
import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import Avatar from 'react-avatar';

import Loader from '../assets/svg/loader.svg';

export default class PartnerPlayers extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.t.state.userID)
            this.props.t.Interface.PartnerGetUsersList(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'Partner', false, 'usersList', 'data');
        this.props.t.updateState(this.props.t, 'Partner', {}, 'usersList', 'open');
        this.props.t.updateState(this.props.t, 'Partner', 0, 'usersList', 'total');
    }

    switchPage(page) {
        if (!isFinite(page))
            page = 0;
        else if (page < 1)
            page = 0;
        else if (page > Math.ceil(this.props.t.state.Partner.usersList.total / this.props.t.state.Partner.usersList.perPage))
            page = parseInt(Math.ceil(this.props.t.state.Partner.usersList.total / this.props.t.state.Partner.usersList.perPage) - 1);
        else
            page = parseInt(page - 1);

        this.props.t.updateState(this.props.t, 'Partner', page, 'usersList', 'currentPage');
        this.props.t.Interface.PartnerGetUsersList(this.props.t);
    }

    showMore(id) {
        if (typeof this.props.t.state.Partner.usersList.open[id] === "undefined" || !this.props.t.state.Partner.usersList.open[id])
            this.props.t.state.Partner.usersList.open[id] = true;
        else
            this.props.t.state.Partner.usersList.open[id] = false;

        this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.usersList.open, 'usersList', 'open');
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'partner', 'players')}
                </div>
                <div className={this.props.t.styles['players']}>
                    <div className={[this.props.t.styles['withPager'], this.props.t.state.Partner.usersList.process && this.props.t.state.Partner.usersList.data ? this.props.t.styles['isLoading'] : ''].join(' ')}>
                        <div className={[this.props.t.styles['list'], !this.props.t.state.Partner.usersList.data ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                            {this.props.t.state.Partner.usersList.data && !this.props.t.state.Partner.usersList.data.length ?
                                <div className={this.props.t.styles['empty']}><h4>N/A</h4>{this.props.t.Interface.getText(this.props.t, 'partner', 'noPlayers')}</div>
                                : !this.props.t.state.Partner.usersList.data ?
                                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img alt="" src={Loader} className={this.props.t.styles['rotating']} /></span>
                                    : <>
                                        <div className={[this.props.t.styles['item'], this.props.t.styles['headItem']].join(' ')}>
                                            <div className={this.props.t.styles['itemHeader']} role="button">
                                                <div className={this.props.t.styles['itemRight']}>

                                                    <div className={this.props.t.styles['itemInfo']}>
                                                        <span onClick={() => { this.props.t.state.Partner.usersList.order === "nameDesc" ? this.props.t.updateState(this.props.t, 'Partner', "nameAsc", 'usersList', 'order') : this.props.t.state.Partner.usersList.order === "nameAsc" ? this.props.t.updateState(this.props.t, 'Partner', "none", 'usersList', 'order') : this.props.t.updateState(this.props.t, 'Partner', "nameDesc", 'usersList', 'order'); this.props.t.Interface.PartnerGetUsersList(this.props.t) }} className={this.props.t.styles['infoCaption']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'playerID')} <Icon t={this.props.t} name={this.props.t.state.Partner.usersList.order === "nameDesc" ? "sort-desc" : this.props.t.state.Partner.usersList.order === "nameAsc" ? "sort-asc" : "sort-off"} /></span>
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemLeft']}>
                                                    <div className={this.props.t.styles['itemAmount']}>
                                                        <span onClick={() => { this.props.t.state.Partner.usersList.order === "balanceDesc" ? this.props.t.updateState(this.props.t, 'Partner', "balanceAsc", 'usersList', 'order') : this.props.t.state.Partner.usersList.order === "balanceAsc" ? this.props.t.updateState(this.props.t, 'Partner', "none", 'usersList', 'order') : this.props.t.updateState(this.props.t, 'Partner', "balanceDesc", 'usersList', 'order'); this.props.t.Interface.PartnerGetUsersList(this.props.t) }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'balance')} <Icon t={this.props.t} name={this.props.t.state.Partner.usersList.order === "balanceDesc" ? "sort-desc" : this.props.t.state.Partner.usersList.order === "balanceAsc" ? "sort-asc" : "sort-off"} /></span>
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemDate']}>
                                                    <span>{this.props.t.Interface.getText(this.props.t, 'partner', 'registration')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.t.state.Partner.usersList.data.map((item, index) => (
                                            <div key={index} className={this.props.t.styles['item']}>
                                                <div onClick={() => !this.props.t.state.Partner.usersList.cancelClicked ? this.showMore(item.id) : {}} className={this.props.t.styles['itemHeader']} role="button">
                                                    <div className={this.props.t.styles['itemRight']}>
                                                        <div className={this.props.t.styles['itemAvatar']}><Avatar size="40px" round="5px" className={this.props.t.styles['avatar']} name={item.settings.name} color={Avatar.getRandomColor(item.id.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} /></div>
                                                        <div className={this.props.t.styles['itemInfo']}>
                                                            <span className={this.props.t.styles['infoCaption']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'player') + ' #' + item.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className={this.props.t.styles['itemLeft']}>
                                                        <div className={this.props.t.styles['itemAmount']}>
                                                            <span className={[this.props.t.styles['value'], item.balance > 0 ? this.props.t.styles['win'] : ''].join(' ')}>{this.props.t.Interface.fixedFloat(item.balance)} <IconStatic t={this.props.t} name='rub' /></span>
                                                        </div>
                                                    </div>
                                                    <div className={this.props.t.styles['itemDate']}>
                                                        <span>{this.props.t.Interface.localeDate(item.date / 1000, true, this.props.t)}</span>
                                                    </div>
                                                </div>
                                                <div className={[this.props.t.styles['itemContent'], typeof this.props.t.state.Partner.usersList.open[item.id] !== "undefined" && this.props.t.state.Partner.usersList.open[item.id] ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <ul>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'totalBets')}:</span><span>{this.props.t.Interface.fixedFloat(item.stats.bets)} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'totalDeps')}:</span><span>{this.props.t.Interface.fixedFloat(item.stats.deposits)} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'totalWithdraws')}:</span><span>{this.props.t.Interface.fixedFloat(item.stats.withdraws)} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'registrationDate')}:</span><span>{this.props.t.Interface.localeDate(item.date / 1000, false, this.props.t)}</span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'registrationSource')}:</span><span>{item.campgain === "m" ? this.props.t.Interface.getText(this.props.t, 'partner', 'mainLink') : "/" + item.campgain}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}</>

                            }
                        </div>
                    </div>
                    {Math.ceil(this.props.t.state.Partner.usersList.total / this.props.t.state.Partner.usersList.perPage) > 1 ?
                        (<div className={this.props.t.styles['pager']}>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.usersList.currentPage - 1, 'usersList', 'currentPage'); this.props.t.Interface.PartnerGetUsersList(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Partner.usersList.currentPage + 1 > 1 ? false : true}><span><IconStatic t={this.props.t} name='left' />{this.props.t.state.width <= 1300 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'prevPage')}</span></button>
                            <div className={this.props.t.styles['inputBlock']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onChange={(a) => { this.switchPage(a.target.value) }} className={this.props.t.styles['inputField']} value={this.props.t.state.Partner.usersList.currentPage + 1}></input>
                                </div>{this.props.t.Interface.getText(this.props.t, 'basic', 'of')} {Math.ceil(this.props.t.state.Partner.usersList.total / this.props.t.state.Partner.usersList.perPage)}
                            </div>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.usersList.currentPage + 1, 'usersList', 'currentPage'); this.props.t.Interface.PartnerGetUsersList(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Partner.usersList.currentPage + 1 < Math.ceil(this.props.t.state.Partner.usersList.total / this.props.t.state.Partner.usersList.perPage) ? false : true}><span>{this.props.t.state.width <= 1300 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'nextPage')}<IconStatic t={this.props.t} name='left' /></span></button>
                        </div>) : (<></>)}
                </div>
            </>
        )
    }
}