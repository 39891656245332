import React from 'react';
import Icon from '../components/Icon';

export default class LandingSlider extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Landing.providersOpen, this.props.t.state.Landing.typesOpen, this.props.t.state.width, this.props.t.state.lang], [this.pState.Landing.providersOpen, this.pState.Landing.typesOpen, this.pState.width, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <div className={[this.props.t.styles['section'], this.props.t.styles['landingMenuSection']].join(' ')}>
                <div className={this.props.t.styles['placeholdersBlock']}>
                    <div onClick={() => { this.props.t.updateState(this.props.t, 'Landing', false, 'providersOpen'); this.props.t.updateState(this.props.t, 'Landing', !this.props.t.state.Landing.typesOpen, 'typesOpen') }} className={[this.props.t.styles['menuPlaceholder'], this.props.t.state.Landing.typesOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                        <div className={this.props.t.styles['placeholderIcon']}>
                            <Icon t={this.props.t} name="stars" />
                        </div>

                        <div className={this.props.t.styles['placeholderTitle']}>
                            {this.props.t.Interface.getText(this.props.t, 'landing', 'allGames')}
                        </div>

                        <div className={this.props.t.styles['arrow']}>
                            <Icon t={this.props.t} name="down" />
                        </div>
                    </div>
                    <div onClick={() => { this.props.t.updateState(this.props.t, 'Landing', false, 'typesOpen'); this.props.t.updateState(this.props.t, 'Landing', !this.props.t.state.Landing.providersOpen, 'providersOpen') }} className={[this.props.t.styles['menuPlaceholder'], this.props.t.state.Landing.providersOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                        <div className={this.props.t.styles['placeholderIcon']}>
                            <Icon t={this.props.t} name="providers" />
                        </div>

                        <div className={this.props.t.styles['placeholderTitle']}>
                            {this.props.t.Interface.getText(this.props.t, 'landing', 'providers')}
                        </div>

                        <div className={this.props.t.styles['arrow']}>
                            <Icon t={this.props.t} name="down" />
                        </div>
                    </div>
                </div>

                <div className={this.props.t.styles['menuBlock']}>
                    <div className={[this.props.t.styles['menuWrapper'], this.props.t.state.Landing.typesOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/0')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="new" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'new')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/6')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="tournaments" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'tournaments')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/2')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="live" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>Live
                        </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/1')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="slots" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'slots')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/3')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="roulette" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'roulette')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/4')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="cards" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'card')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/8')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="videopoker" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'videopoker')}
                            </span>
                        </a>
                        <a href="#" onClick={() => this.props.t.doRoute('/lobby/0/7')}>
                            <span className={this.props.t.styles['menuIcon']}>
                                <Icon t={this.props.t} name="sweepstake" />
                            </span>
                            <span className={this.props.t.styles['menuTitle']}>{this.props.t.Interface.getText(this.props.t, 'landing', 'lottery')}
                            </span>
                        </a>

                    </div>
                    <div onClick={() => { this.props.t.updateState(this.props.t, 'Landing', false, 'typesOpen'); this.props.t.updateState(this.props.t, 'Landing', !this.props.t.state.Landing.providersOpen, 'providersOpen') }} className={[this.props.t.styles['menuPlaceholder'], this.props.t.state.Landing.providersOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                        <div className={this.props.t.styles['placeholderTitle']}>
                            {this.props.t.Interface.getText(this.props.t, 'landing', 'providers')}
                        </div>

                        <div className={this.props.t.styles['arrow']}>
                            <Icon t={this.props.t} name="down" />
                        </div>
                    </div>
                    <div className={[this.props.t.styles['menuWrapper'], this.props.t.styles['providers'], this.props.t.state.Landing.providersOpen ? this.props.t.styles['open'] : ''].join(' ')}>
                        {this.props.t.state.Lobby.providers.map((item, index) => (
                            !this.props.t.Interface.inArray(item.name.toLowerCase().split("'").join('').split(' ').join(''), this.props.t.config.casino.lockedProviders) && <a key={index} onClick={() => this.props.t.doRoute('/lobby/' + item.id + '/0')}>
                                <span className={this.props.t.styles['menuTitle']}>{item.name}</span>
                            </a>
                        ))}
                    </div>
                </div>

            </div>)
    }
}