import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
//import {whyDidYouUpdate} from 'why-did-you-update';
import Index from './modules/Index';
//whyDidYouUpdate(React);

export default class AppCore extends React.Component {
    render() {
        return (
            <>
            <Index/>
            </>
        );
    }
}

ReactDOM.render(
    <Router>
    <AppCore />
    </Router>,
    document.getElementById('app')
    );