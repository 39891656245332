import React from 'react';
import Avatar from 'react-avatar';

import WalletModal from './modals/Wallet';
import ConfirmModal from './modals/Confirm';
import BonusCodeModal from './modals/BonusCode';

import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import ClickAwayListener from 'react-click-away-listener-brillx';

export default class Header extends React.Component {

	constructor(props) {
		super(props);
		//this.props.cookie.save(config.name + '-authKey', '222', { path: '/' })
	}

	componentDidMount() {
		this.props.t.Interface.checkAuth(this.props.t);

		if (this.props.t.state.userID)
			this.props.t.Interface.HeaderGetBalance(this.props.t);

		setInterval(() => { if (this.props.t.state.userID) this.props.t.Interface.HeaderGetBalance(this.props.t) }, 10000);

		//if (!this.props.t.state.Header.gamesList.length || (localStorage.getItem('games.list') && +new Date() - localStorage.getItem('games.updated') >= 1000 * 60 * 60 * 1)) 
		this.props.t.Interface.HeaderGetGamesList(this.props.t);


		if (!this.props.t.state.Lobby.providers.length)
			this.props.t.Interface.LobbyGetProvidersList(this.props.t);

		this.pState = this.props.t.cloneDeep(this.props.t.state);
		this.pState.pathname = window.location.pathname;

	}

	shouldComponentUpdate() {
		let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.location, this.props.t.state.HeaderProfile.open, this.props.t.state.Header.balanceDiff, this.props.t.state.Header.balanceDiffLock, this.props.t.state.Header.balance, this.props.t.state.Header.menuOpen, this.props.t.state.userID, this.props.t.state.RightBar.module, this.props.t.state.lang, window.location.pathname], [this.pState.location, this.pState.HeaderProfile.open, this.pState.Header.balanceDiff, this.pState.Header.balanceDiffLock, this.pState.Header.balance, this.pState.Header.menuOpen, this.pState.userID, this.pState.RightBar.module, this.pState.lang, this.pState.pathname]);

		if (needUpdate) {
			this.pState = this.props.t.cloneDeep(this.props.t.state);
			this.pState.pathname = window.location.pathname;
		}

		return needUpdate;
	}

	render() {
		return (
			<div className={this.props.t.styles['header']} id="header">
				<a onClick={() => this.props.t.doRoute('/')} className={this.props.t.styles['logo']}><img alt={this.props.t.config.name} src={this.props.t.state.width <= 900 ? this.props.t.logoMobile : this.props.t.logo}></img></a>
				<div className={this.props.t.styles['header-inner']}>
					<div className={this.props.t.styles['header-block']}>
						<div className={this.props.t.styles['top-nav-wrapper']}>
							<button onClick={() => { this.props.t.updateState(this.props.t, 'Header', !this.props.t.state.Header.menuOpen, 'menuOpen') }} className={[this.props.t.styles['opener'], this.props.t.state.Header.menuOpen ? this.props.t.styles['open'] : ''].join(' ')} type="button">
								<div className={this.props.t.styles['bar']}></div>
								<div className={this.props.t.styles['bar']}></div>
								<div className={this.props.t.styles['bar']}></div></button>
							<ul className={[this.props.t.styles['top-nav'], this.props.t.state.Header.menuOpen ? this.props.t.styles['show'] : ''].join(' ')} role="navigation">
								<li>
									<a className={window.location.pathname === '/lobby' || window.location.pathname === '/lobby/0/0' ? this.props.t.styles['active'] : ''} onClick={() => this.props.t.doRoute('/lobby/0/0')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'games')}</span></a>
								</li>
								<li>
									<a className={window.location.pathname === '/promotions' ? this.props.t.styles['active'] : ''} onClick={() => this.props.t.doRoute('/promotions')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'promotions')}</span></a>
								</li>
								<li>
									<a className={window.location.pathname === '/partner' ? this.props.t.styles['active'] : ''} onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/partner') : this.props.t.Interface.requireAuth(this.props.t, false, '/partner')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'affProgramm')}</span></a>
								</li>
								<li>
									<a className={window.location.pathname === '/loyalty' ? this.props.t.styles['active'] : ''} onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/loyalty') : this.props.t.Interface.requireAuth(this.props.t, false, '/loyalty')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'loyaltyProgramm')}</span></a>
								</li>
								<li>
									<a className={window.location.pathname === '/promo' ? this.props.t.styles['active'] : ''} onClick={() => this.props.t.state.userID ? this.props.t.doRoute('/promo') : this.props.t.Interface.requireAuth(this.props.t, false, '/promo')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'free')}</span></a>
								</li>
								<li>
									<a onClick={() => this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: BonusCodeModal, className: this.props.t.styles['bonusModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/promo')}><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'bonusCode')}</span></a>
								</li>


							</ul>
						</div>
					</div>
					{this.props.t.state.userID ? (
						<div className={this.props.t.styles['headerWalletContainer']}>
							<div className={this.props.t.styles['headerWalletWrapper']}>
								<div className={this.props.t.styles['headerWalletDepositBlock']}>
									<div className={[this.props.t.styles['headerWalletCurrencyInput'], this.props.t.state.location === "game" ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
										<button type="button" className={this.props.t.styles['btnCurrency']}>
											<div className={this.props.t.styles['currencyContent']}>
												<div className={this.props.t.styles['balanceLabel']}>{this.props.t.Interface.getText(this.props.t, 'basic', 'balance')}</div>
												<div className={this.props.t.styles['balanceInput']}>
													<span className={[this.props.t.styles['animatedNumber'], this.props.t.styles['isAnimate'], this.props.t.state.Header.balanceDiff > 0 ? this.props.t.styles['isPositive'] : ''].join(' ')}>{this.props.t.Interface.fixedFloat(this.props.t.state.Header.balance)}<div className={this.props.t.styles['balanceIcon']}>{this.props.t.Interface.getCurrency(this.props.t)}</div><div className={!this.props.t.state.Header.balanceDiffLock && this.props.t.state.Header.balanceDiff !== 0 ? this.props.t.styles['animatedBlock'] : this.props.t.bstyles['d-none']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Header.balanceDiff)}</div></span>
												</div>
											</div>
										</button>
									</div>

									<button onClick={() => { this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} type="button" className={[this.props.t.styles['btn'], this.props.t.styles['btnWallet'], this.props.t.state.width <= 900 ? this.props.t.styles['isMobile'] : ''].join(' ')}>{this.props.t.Interface.getText(this.props.t, 'profile', 'wallet')}</button></div></div>
							<div className={this.props.t.styles['headerWalletTabs']}>
								<ClickAwayListener onClickAway={() => { this.props.t.state.HeaderProfile.open && this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open') }}>
									<div className={[this.props.t.styles['dropdown'], this.props.t.state.HeaderProfile.open ? this.props.t.styles['open'] : ''].join(' ')}>


										<button onClick={() => { this.props.t.updateState(this.props.t, 'HeaderProfile', !this.props.t.state.HeaderProfile.open, 'open') }} type="button" className={[this.props.t.styles['btnTab'], this.props.t.state.width <= 900 ? this.props.t.styles['isMobile'] : ''].join(' ')}>
											<div className={this.props.t.styles['btnContent']}>
												<div className={this.props.t.styles['iconWrapper']}>
													<IconStatic t={this.props.t} name='person' />
												</div>
												<div className={this.props.t.styles['iconSecond']}>
													<Icon t={this.props.t} name={this.props.t.state.HeaderProfile.open ? 'close' : 'dots'} />
												</div>
											</div>
										</button>


										<div className={this.props.t.styles['menu']}>
											<div className={this.props.t.styles['btnUser']}>
												<a href="#" onClick={() => { this.props.t.doRoute('/profile'); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }}>
													<Avatar round="20px" className={this.props.t.styles['avatar']} name={this.props.t.state.userData ? this.props.t.state.userData.settings.name : '#' + this.props.t.state.userID} color={Avatar.getRandomColor(this.props.t.state.userID.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} />

													<div className={this.props.t.styles['name']}>
														{this.props.t.state.userData ? this.props.t.state.userData.settings.name : '#' + this.props.t.state.userID}
													</div></a>
											</div>

											<button onClick={() => { this.props.t.doRoute('/settings'); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} className={[this.props.t.styles['item'], this.props.t.styles['first']].join(' ')} type="button">
												<div className={this.props.t.styles['iconWrapper']}>
													<IconStatic t={this.props.t} name='settings' />
												</div>{this.props.t.Interface.getText(this.props.t, 'menu', 'settings')}
											</button>

											<button onClick={() => { this.props.t.doRoute('/profile'); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} className={this.props.t.styles['item']} type="button">
												<div className={this.props.t.styles['iconWrapper']}>
													<IconStatic t={this.props.t} name='history' />
												</div>{this.props.t.Interface.getText(this.props.t, 'menu', 'history')}
											</button>

											<button onClick={() => { this.props.t.doRoute('/fair'); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} className={this.props.t.styles['item']} type="button">
												<div className={this.props.t.styles['iconWrapper']}>
													<IconStatic t={this.props.t} name='fairness' />
												</div>{this.props.t.Interface.getText(this.props.t, 'menu', 'fairGame')}
											</button>

											<button onClick={() => { this.props.t.doRoute('/help'); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} className={this.props.t.styles['item']} type="button">
												<div className={this.props.t.styles['iconWrapper']}>
													<IconStatic t={this.props.t} name='help-circle' />
												</div>{this.props.t.Interface.getText(this.props.t, 'menu', 'support')}
											</button>

											<div onClick={() => { this.props.t.updateState(this.props.t, 'modal', { show: true, content: ConfirmModal, className: this.props.t.styles['confirmModal'], callback: this.props.t.Interface.doLogOut, confirmText: this.props.t.Interface.getText(this.props.t, 'auth', 'logoutConfirm') }); this.props.t.updateState(this.props.t, 'HeaderProfile', false, 'open'); }} className={[this.props.t.styles['item'], this.props.t.styles['btnLogout'], this.props.t.styles['last']].join(' ')}>
												<button type="button">
													<div className={this.props.t.styles['iconWrapper']}>
														<IconStatic t={this.props.t} name='logout' />
													</div>{this.props.t.Interface.getText(this.props.t, 'auth', 'logout')}</button>
											</div>
										</div>
									</div>
								</ClickAwayListener>
							</div></div>
					) :

						(<div className={this.props.t.styles['auth-buttons']}>
							<button className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button" onClick={() => this.props.t.Interface.requireAuth(this.props.t)}>{this.props.t.Interface.getText(this.props.t, 'auth', 'login')}</button>
							<button className={this.props.t.styles['btn']} type="button" onClick={() => this.props.t.Interface.requireAuth(this.props.t, true)}>{this.props.t.Interface.getText(this.props.t, 'auth', 'registration')}</button>
						</div>)
					}
				</div>
			</div>
		)
	}
}