import React from 'react';

import { Redirect, withRouter } from "react-router-dom";

class Go extends React.Component {
    constructor(props) {
        super(props);
        this.props.t.updateState(this.props.t, 'userRefer', this.props.match.params.link || false);
        localStorage.setItem('user.referer', this.props.match.params.link || false)
        this.props.t.Interface.GoClickCode(this.props.t);
    }

    render() {
        return (
            <>
                <Redirect from="/*" to="/" />
            </>
        )
    }
}

export default withRouter(Go);