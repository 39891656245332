module.exports = {
  system: { code: 'uk', name: 'Українська' },
  aml: {
    title: 'Протидія відмиванню грошей',
    description: 'Контроль безпеки',
    content: ''
  },
  auth: {
    accountCreated: 'Обліковий запис зареєстровано',
    agreeText: 'Я підтверджую, що мені 18 років, і я прочитав',
    agreeTos: 'умови надання послуг',
    alreadyRegistered: 'Вже зареєстровані?',
    byLogin: 'За логіном і паролем',
    bySocialNetworks: 'Через соціальні мережі',
    createAccount: 'Створити обліковий запис',
    forgotPassword: 'Забули свій пароль?',
    loading: 'Завантаження...',
    login: 'Увійти',
    loginTitle: 'Авторизація сайту',
    maxLoginLength: 'Максимальна довжина входу:',
    maxPasswordLength: 'Максимальна довжина пароля:',
    minLoginLength: 'Мінімальна довжина входу:',
    minPasswordLength: 'Немірна довжина пароля:',
    passwordConfirmation: 'Повторіть пароль',
    passwordsMustBeSame: 'Паролі повинні збігатися',
    pleaseWait: 'Будь ласка, зачекайте',
    registration: 'Реєстрація',
    registrationTitle: 'Зареєструватися на сайті',
    symbols: 'персонажів',
    yourLogin: 'Ваш логін',
    yourPassword: 'Ваш пароль',
    yourEmail: 'Ваш email',
    logoutConfirm: 'Ви дійсно хочете вибратися?',
    passwordRecovery: 'Скинути пароль',
    newPassword: 'Новий пароль',
    copied: 'Копіюється',
    logout: 'Вихід'
  },
  basic: {
    yes: 'Так',
    no: 'Ні',
    confirm: 'Стверджую',
    confirmed: 'Вгору',
    cancel: 'Відміняючи',
    sending: 'Надсилання...',
    sent: 'Подати',
    save: 'Зберегти',
    create: 'Створити',
    or: 'або',
    activate: 'Увімкнути',
    deactivate: 'Деактивувати',
    prevPage: 'Попередня сторінка',
    nextPage: 'Наступна сторінка',
    of: 'з',
    balance: 'Баланс',
    copy: 'Копіювати',
    copied: 'Копіюється'
  },
  bonus: {
    permanentBonus: 'Постійний бонус',
    tenPercent: '10% за кожен депозит!',
    more: 'Читати далі',
    fastRegistration: 'Швидка реєстрація',
    noVerification: 'без перевірки',
    fastWithdraws: 'Швидкі виплати',
    highLimits: 'високі межі',
    loyaltyProgramm: 'Програма лояльності',
    winCashback: 'До 1,2% на кожну перемогу!',
    levelSystem: 'Система рівня',
    bigPercents: 'Великі відсотки',
    payForWin: 'Сплатити за виграш',
    butNoBets: 'не для ставок',
    affiliateProgramm: 'Партнерська програма',
    join: 'Вступити',
    revenueShare: 'Дохідна частка 40%',
    anytimeWithdraw: 'вивід в будь-який момент',
    affiliateCabinet: 'Партнерський кабінет',
    detailStat: 'детальна статистика',
    title: 'Промо-код',
    promoEveryDay: 'Промо-коди кожен день в нашій групі ВКонтакте',
    enterPromoCode: 'Введіть промо-код'
  },
  fair: {
    title: 'Гарантія ліцензії та чесності',
    description: 'Гарантуємо чесну гру для всіх',
    fairDescription1: 'Казино Brillx розуміє, наскільки важливо створити середовище безпеки, щоб користувачі були впевнені в чесності наших ігор.',
    fairDescription2: 'Тому в нашому казино ми використовуємо ігри, надані топовими компаніями, що розробляють програмне забезпечення для онлайн-казино. Серед них такі компанії, як',
    fairDescription3: 'Будучи членом-засновником EcoGra та IGC, Microgaming випереджає інших виробників ігор казино, встановлюючи найвищі стандарти в індустрії азартних ігор в цілому, особливо для чесності онлайн-ігор, вибору найбільш відповідальних операторів і захисту інтересів гравців.',
    andOther: 'та інші',
    also: 'Ми також надаємо ігри, створені компанією',
    defaultCasino: 'Звичайне онлайн-казино',
    brillxCasino: 'Brillx контроль чесності казино',
    defaultCasinoDescription1: 'Ви робите ставку, яка написана на сервер казино',
    defaultCasinoDescription2: 'Ваші дані збираються та зберігаються на серверах казино',
    defaultCasinoDescription3: 'Перевірка чесності гри неможлива — всі алгоритми записуються і зберігаються на стороні казино',
    defaultCasinoDescription4: 'Щоб отримати виграш, необхідно пройти тривалу процедуру авторизації',
    defaultCasinoDescription5: 'Платоспроможність казино під питанням, оскільки його баланс ніде не публікується',
    brillxCasinoDescription1: 'Копія даних від постачальників ставок гравців зберігається в blockchain і не може бути змінена',
    brillxCasinoDescription2: "Ігрова статистика для людей зашифрована і записана в блокчейн, відв'язана до персональних даних гравця",
    brillxCasinoDescription3: 'Контроль цілісності здійснюється за допомогою технології блокчейн',
    brillxCasinoDescription4: 'Виведення коштів вимагає стандартної авторизації KYC, без комісії за переказ',
    brillxCasinoDescription5: 'Відкрита звітність і величезний пул для виплат гравців'
  },
  game: {
    loading: 'Завантаження',
    lobby: 'Лобі',
    fullScreen: 'На весь екран',
    deposit: 'Депозит',
    betsAmount: 'Сума ставок',
    totalRTP: 'Підсумковий RTP',
    averageBet: 'Середня ставка',
    topWin: 'Топ виграш'
  },
  help: {
    title: 'Довідка та підтримка',
    description: 'Відповіді на найпоширеніші питання і спілкування з обслуговуванням клієнтів.',
    connectTypes: 'Методи спілкування з нами',
    operatorsOnline: 'Онлайн-оператори 24 години на добу',
    supportLive: 'Підтримка (Live)',
    onlineChat: 'Живий чат',
    averageTimeChat: 'Середній час відгуку: 10 хвилин',
    supportEmail: 'Обслуговування клієнтів (E-mail)',
    averageTimeEmail: 'Середній час відгуку: 1 день',
    generalQuestions: 'Загальні питання',
    aboutSite: 'Про сайт',
    aboutSiteContent: 'Brillx - це онлайн-казино з міжнародними ліцензіями та назвою. Ми пропонуємо понад 6 000 ігор в каталозі, широкий вибір спортивних заходів, а також безліч міні-ігор. Ми підтримуємо багато платіжних систем і більшість популярних криптовалют.',
    bonus: 'Бонус',
    bonuses: 'Бонуси',
    onPage: 'На сторінці',
    manyFreeBonuses: 'є кілька способів отримати гроші на свій баланс абсолютно безкоштовно!',
    bonus1: 'Бонус реєстрації. Після реєстрації на сайті у вас є 60 хвилин, щоб ввести код запрошення і отримати до 10.00 RUB.',
    bonus2: 'Бонус реєстрації. Після реєстрації на сайті у вас є 60 хвилин, щоб ввести код запрошення і отримати до 10.00 RUB.',
    bonus3: 'Бонус реєстрації. Після реєстрації на сайті у вас є 60 хвилин, щоб ввести код запрошення і отримати до 10.00 RUB.',
    bonus4: 'Бонус реєстрації. Після реєстрації на сайті у вас є 60 хвилин, щоб ввести код запрошення і отримати до 10.00 RUB.',
    promocodes: 'Промокоди',
    inviteFriend: 'Запросити друга',
    balanceRefilling: 'Поповнення балансу',
    wallet: 'Гаманець',
    howToRefill: 'Як мені поповнити баланс?',
    commissions: 'Комісія',
    periodOfRefilling: 'Умови зарахування',
    withdrawal: 'Зняття коштів',
    howToWithdraw: 'Як вивести кошти?',
    periodOfWithdrawal: 'Шкала зняття',
    clickToRefill: 'Натисніть на кнопку поповнити баланс',
    atRightCorner: 'у верхньому правому куті сайту. Виберіть зручний для вас спосіб оплати, введіть суму депозиту і натисніть Перейти до оплати.',
    minimalRefilling: 'Мінімальне поповнення',
    commissionsOnPage: 'Комісії всіх способів оплати вказані на сторінці',
    clickToWithdraw: 'Натисніть кнопку, щоб вивести гроші з рахунку',
    averageTimeWithdraw: 'Всі отримані дані обробляються в середньому за одну годину з моменту подачі заявки.',
    allTransactions: 'Всі транзакції обробляються миттєво. Затримки можуть виникнути тільки в тому випадку, якщо у вас виникли проблеми з способом оплати, який ви поповнюєте баланс.',
    inCaseOfProblems: 'Якщо виникли проблеми, зверніться в нашу службу турботи і надайте максимально детальну інформацію про вашу проблему.',
    atRightCornerWithdraw: 'у верхньому правому куті сайту. Виберіть зручний для вас спосіб виведення коштів, введіть суму і натисніть Перейти до оплати.',
    minimalWithdrawal: 'Мінімальне зняття',
    ifBigAmount: 'Якщо сума виведення велика, висновок може йти трохи довше, як правило, до години. Вночі (з 00:00 до 09:00 MSC) висновки можуть йти протягом декількох годин, так як в цей час агрегатор платежів обробляє запити на виведення довше.',
    sometimes: "У дуже рідкісних випадках зняття коштів може йти до 24 годин, через банківські навантаження і так далі. Якщо гроші не надійдуть після цього часу, будь ласка, зв'яжіться з нашою службою підтримки клієнтів і надайте найбільш детальну інформацію про вашу проблему.",
    checkCorrectPurse: 'Також переконайтеся, що ви правильно вказуєте деталі для виводу. Щоб заспокоїти гаманець, який ви вказали на виведення коштів, вам потрібно зайти в історію транзакцій і відкрити деталі, натиснувши на потрібну транзакцію.'
  },
  interface: {
    months: {
      jan: 'січ.',
      feb: 'лют.',
      mar: 'бер.',
      apr: 'apr.',
      may: 'май',
      jun: 'чер.',
      jul: 'лип.',
      aug: 'сер.',
      sep: 'вер.',
      oct: 'жов.',
      nov: 'лис.',
      dec: 'гру.'
    },
    year: 'р.',
    confirmPasswordRecovery: 'Підтвердіть скидання пароля, перейшовши за посиланням, надісланим на вашу електронну пошту',
    passwordWasChanged: 'Пароль успішно змінений, ви можете увійти в систему для використання нових даних',
    confirmEmail: 'Підтвердити адресу електронної пошти за посиланням',
    emailWasConfirmed: 'Адреса електронної пошти успішно підтверджена',
    accountLinked: "Обліковий запис успішно зв'язано",
    bonusGranted: 'Отримано бонус'
  },
  landing: {
    newGames: 'Нові ігри',
    topGames: 'Популярні ігри',
    brillxSelect: 'Вибір Брілкса',
    daylyLiveRtp: 'Live RTP - 24 години',
    brillxCasino: 'Казино Brillx',
    casinoInfo1: 'Перевірте широкий спектр захоплюючих ігор тут, в Brillx.GG, ігровий автомат сайт розроблений для вашого задоволення. Тисячі першокласних ігрових автоматів доступні гравцям по всьому світу. Будь то величезні джекпоти, слоти в покер або тематичні ігрові автомати вам потрібно, у нас є все, що вам потрібно!',
    casinoInfo2: 'Відчуйте легкість швидкої реєстрації, натиснувши кнопку реєстрації зараз! Насолоджуйтесь моментами, коли ви граєте на реальні гроші ігрових автоматів від провідних постачальників ігор.',
    casinoInfo3: 'Наше казино з швидкими виплатами і величезним вибором всіляких варіацій ігрових автоматів працює 24/7 для вас - просто вибрати гру і почати грати!',
    casinoInfo4: 'Грати в слоти онлайн',
    casinoInfo5: "Ми ніколи не псуємо ваше задоволення, тому ви можете грати в усі наші ігри, не завантажуючи нічого на свій комп'ютер.",
    casinoInfo6: 'Перегляньте тисячі слотів, виберіть вподобаний гру і натисніть «ГРАТИ» - це так просто!',
    casinoInfo7: 'Казино ігрові автомати є однією з найпопулярніших ігор. Не тільки з-за величезних стимулів ви можете отримати, але і з-за захоплюючої графіки, гарної музики, і загального приємного візуального ефекту гри.',
    casinoInfo8: "Ви навіть можете спробувати кожен з наших ігрових автоматів, перейшовши в розважальний режим - дивовижна функція, яка дозволяє грати всі наші ігрові автомати без скачування і без реєстрації безкоштовно! Доступ до нього можна отримати через мобільний телефон, комп'ютер або планшет. Відкрийте для себе деякі з кращих ігрових автоматів сьогодні, які ви можете грати безкоштовно без необхідності завантажувати, і випробувати унікальну пригоду в кращих слотах казино по всьому світу. Візьміть свій кращий постріл сьогодні, і нехай доля вирішить, що вас чекає!",
    casinoInfo9: 'Чого ви чекаєте? Сьогодні може стати вашим щасливим днем!',
    casinoInfo10: 'Тисячі ігрових автоматів',
    casinoInfo11: 'Ось різні типи ігрових автоматів, які ви можете знайти в нашому казино:',
    casinoInfo12: '3 слоти барабана',
    casinoInfo13: '3 слоти барабанами найбільш відомі як класичні або традиційні ігрові автомати. Котушки вертикальні лінії ви можете знайти в грі, де символи каскадні. Якщо ви шукаєте гострих відчуттів, спробуйте грати в ігровий автомат 3 барабанами! Деякі з ігор, які ви можете грати є Книга Алхімії, Уотфорд FC слот і багато інших.',
    casinoInfo14: '5 слотів для барабанів',
    casinoInfo15: 'Отримайте більше шансів на виграшні комбінації від гри слот 5 барабанами! Більшість 5 слотів барабанів оплачуються кожен раз, коли 3, 4 або 5 символів падають на барабанах. Ви також можете отримати виграш мультиплікатори, бонусні ігрові раунди або безкоштовні спини в залежності від гри. Деякі з 5 барабанами ігор ви можете грати є вікінги Go Berzerk, спадщина мертвих, і це багате.',
    casinoInfo16: 'Прогресивні',
    casinoInfo17: 'Прогресивні слоти є тріслом серед всіх типів ігрових автоматів. Розмір джекпоту збільшується в міру прогресування гри. На вас чекають величезні мільйони, і ви могли б стати одним з найбагатших людей на сьогоднішній день!',
    casinoInfo18: 'Рулетка',
    casinoInfo19: 'Рулетка - це гра, яка ставки на окремі номери на столі, колір, тип номера або комбінації. Перевірте свою удачу і виграти, якщо те, що ви робите ставку на хіти колеса. Грайте в наші чудові ігри в рулетку в режимі реального часу прямо зараз!',
    casinoInfo20: 'Покер',
    casinoInfo21: "Якщо ви знайомі з покером в звичайному казино, ви можете спробувати грати в це прямо зараз, скориставшись нашим широким вибором онлайн-ігор в відео-покер. Будьте готові випробувати незабутню гру вже сьогодні! З високою чіткістю анімації та чудовими спеціальними відеораундами, ви обов'язково повернетеся до нової перемоги.",
    casinoInfo22: 'Баккара',
    casinoInfo23: 'Баккара - одна з найпопулярніших ігор в світі. Поразка дилера, ставши на руку найближчу до 9. Також ви також можете виграти, якщо є нічия. Звучить захоплююче, правда? Отримайте ваші ставки готові зараз і робіть ставку на виграшну сторону!',
    casinoInfo24: 'Блекджек',
    casinoInfo25: 'У блекджек ви можете бити дилера, якщо у вас є рука найближча до 21. Отримайте шанс подвоїти свою ставку, коли ви граєте в наші величні блекджек ігрові автомати!',
    gamesProviders: 'Постачальники ігор',
    showMore: 'Показати ще',
    hideInfo: 'Сховати інформацію',
    allGames: 'Всі ігри',
    providers: 'Провайдери',
    new: 'Новий',
    tournaments: 'Bonus Buy',
    slots: 'Слоти',
    roulette: 'Рулетка',
    card: 'Картки',
    videopoker: 'Instant Win',
    lottery: 'Джекпот'
  },
  live: { demo: 'Демо', play: 'Грати', title: 'Live-виграші' },
  lobby: {
    providers: 'Провайдери',
    recent: 'Останні',
    top: 'Популярні',
    new: 'Новий',
    favorite: 'Вибране',
    nothingFound: 'Нічого не знайдено',
    play: 'ГРАТИ',
    demo: 'ДЕМО',
    forExample: 'Наприклад,'
  },
  log: { date: 'Дата', type: 'Операція', amount: 'Сума', id: 'ID' },
  loyalty: {
    title: 'Програма лояльності',
    description: 'Отримуйте Кешбек, який залежить від рівня лояльності на кожній ставці',
    cabinet: 'Кабінет',
    myCabinet: 'Мій офіс',
    payLog: 'Журнал нарахування',
    log: 'Лог',
    level: 'Ярус',
    totalWins: 'Сума виграшу',
    totalBets: 'Сума ставки',
    availableBalance: 'Наявний баланс',
    get: 'Забрайте',
    cashback: 'Кешбек',
    emptyHistory: 'Історія відсутня',
    operationID: 'Операція ID',
    amount: 'Сума',
    date: 'Дата',
    operationDate: 'Дата операції',
    operationType: 'Операція',
    balanceBefore: 'Баланс вгору',
    balanceAfter: 'Баланс після'
  },
  menu: {
    affProgramm: 'Партнери',
    fairGame: 'Чесна гра',
    free: 'Безкоштовно',
    games: 'Ігри',
    lobby: 'Лобі',
    promo: 'Промо',
    loyaltyProgramm: 'Вірність',
    promotions: 'Акції',
    support: 'Довідкової',
    settings: 'Налаштування',
    history: 'Історія ігор',
    more: 'Більше',
    profile: 'Профіл',
    bonusCode: 'Промо-код'
  },
  noty: { error: 'Помилка', success: 'Успішний' },
  profile: {
    wallet: 'Гаманець',
    onSiteFrom: 'На сайті з',
    totalBets: 'Сума ставки',
    betsCount: 'Ставки',
    totalWins: 'Сума виграшу',
    bestWin: 'Кращий виграш',
    emptyHistory: 'Історія відсутня',
    balanceAfter: 'Баланс після'
  },
  partner: {
    maxCodeLength: 'Максимальна довжина коду:',
    minCodeLength: 'Мінімальна довжина коду:',
    maxNameLength: 'Максимальна довжина назви:',
    minNameLength: 'Мінімальна довжина назви:',
    symbols: 'персонажів',
    confirmDeleteCampgain: 'Ви дійсно хочете видалити кампанію?',
    campgains: 'Кампаній',
    refresh: 'Оновлення',
    export: 'Експорт',
    campgainName: 'Назва кампанії',
    affiliateLink: 'Партнерське посилання',
    name: 'Назва',
    creationDate: 'Дата створення',
    clicks: 'Переходи',
    link: 'Реф.',
    manage: 'Управління',
    noCampgains: 'Немає активних рекламних кампаній',
    namePlaceholder: 'Назва для відображення на панелі',
    general: 'Загальний',
    mainAffiliateLink: 'Основне партнерське посилання',
    registrations: 'Реєстрації',
    totalProfit: 'Загальний прибуток',
    availableBalance: 'Наявний баланс',
    get: 'Забрайте',
    emptyHistory: 'Історія відсутня',
    operationID: 'Операція ID',
    amount: 'Сума',
    date: 'Дата',
    operationDate: 'Дата операції',
    operationType: 'Операція',
    playerID: 'ІД гравця',
    balanceAfter: 'Баланс після',
    balanceBefore: 'Баланс вгору',
    log: 'Журнал нарахування',
    players: 'Гравці',
    registration: 'Реєстрація',
    registrationDate: 'Дата реєстрації',
    balance: 'Баланс',
    noPlayers: 'Немає гостьових гравців',
    totalBets: 'Сума ставки',
    totalDeps: 'Сума депозиту',
    totalWithdraws: 'Сума результатів',
    registrationSource: 'Джерело реєстрації',
    mainLink: 'Основне посилання',
    player: 'Гравець',
    registrationsCount: 'Реєстрація',
    bets: 'Ставка',
    profit: 'Прибуток',
    stat: 'Статистика',
    totalStat: 'Загальна статистика',
    partnerCabinet: 'Офіс партнера',
    partnerID: 'Код партнера'
  },
  privacy: {
    title: 'Політика конфіденційності',
    description: 'Загальні положення та інформація',
    redaction: 'Редакція за',
    content: ''
  },
  promo: {
    linkVk: "Прив'яжіть аккаунт ВКонтакте, щоб отримати бонуси",
    completeAll: 'Підписати всі умови і отримати',
    toBalanceForRepost: 'на баланс для репоста!',
    link: "Прив'язки",
    join: 'Запис',
    allowNotifications: 'Вiдправте повідомлення',
    make: 'Робити',
    toProfileVkAccount: 'до профілю облікового запису VK.',
    toOurVkGroup: 'до нашої групи ВКонтакте.',
    fromOurGroup: 'нашої спільноти.',
    repostToYourPage: 'репост запису на свою сторінку.',
    mustBePublic: 'Ваша сторінка ВКонтакте повинна бути загальнодоступною або ми не зможемо перевірити наявність репоста.',
    dontDelete: 'Якщо видалити пост раніше, ніж через 2 тижні після репоста, сума бонусу буде списано з балансу.',
    joinAndGet: 'Приєднуйтесь до групи та отримайте',
    toBalance: 'на балансі!',
    bonusGranted: 'Бонус успішно отримано!',
    momentalBonus: 'Приєднуйтесь до нашої групи ВКонтакте і отримуйте миттєвий бонус на свій баланс! Профіль ВКонтакте має бути публічним, інакше ми не зможемо перевірити наявність підписки.',
    vkDoesntLinked: 'Обліковий запис ВКонтакте не',
    allowNotificationsAndGet: 'Напиши повідомлення нашої спільноти і отримай',
    sendAnyMessage: 'Відправте будь-яке повідомлення в нашу групу ВКонтакте, дозволяючи відправляти повідомлення. Приємний сюрприз - періодично ми відправляємо інформацію про акції та різні бонуси!',
    rollAndGetBonus: 'Круїз рулетку і отримуйте бонуси!',
    rollOncePer: 'Перекручувати рулетку раз в 15 хвилин і добиратися до',
    rollEveryDay: 'Крутіть колесо без обмежень кожен день!',
    untilNextTry: 'Перед наступною спробою залишається',
    subscribeTg: 'Підпишіться на наш Telegram канал, щоб отримати нові промокоди першими!',
    beFirst: 'Будьте одними з перших, хто отримає свіжий промокод, дізнавайтеся про нові бонуси та інновації!',
    referrals: 'Реферали',
    getPercent: 'Запрошуйте друзів і заробляйте 40% переваг казино!',
    copyLink: 'Скопіюйте посилання',
    inviteFriend: 'Запросити друга',
    getBonus: 'Отримайте бонус',
    affiliateProgramm: 'Партнерська програма',
    sendReview: 'Подаємо відгуки про проект в нашій групі і отримуємо',
    bonusToDeposit: 'депозитний бонус',
    upTo: 'аж до',
    makeReviews: 'Залиште відгук в нашій групі ВКонтакте, після того, як модератор буде перевірений і розміщений, бонус буде автоматично активований на вашому акаунті.',
    makeReview: 'Відгуки',
    ranks: 'Рангі',
    earnFromWin: 'Заробивши ранги ви отримаєте підвищений Кешбек з кожного виграшу.',
    loyaltyProgramm: 'Програма лояльності',
    writeMessage: 'Написати повідомлення',
    goToGroup: 'Перейти до групи',
    refreshing: 'Оновити...',
    refreshStatus: 'Оновлення',
    roll: 'Обертати',
    linkAccount: "Прив'язати обліковий запис",
    checking: 'Перевірка...',
    get: 'Отримати'
  },
  promotions: {
    title: 'Акції та пропозиції',
    description: 'Не пропустіть можливість отримати бонус в наших поточних акціях',
    game: 'Гра',
    minDeposit: 'Мін. депозит',
    bonus: 'Бонус',
    addWager: 'Додаткова ставка',
    validUntil: 'Дії до',
    activation: 'Активація',
    get: 'Отримати',
    unlimit: 'необмежений',
    oneTime: 'одноразовий',
    forEveryDeposit: 'за кожен депозит',
    permanentBonus: 'Постійний бонус',
    any: 'будь-яка'
  },
  responsibility: {
    title: 'Відповідальна гра',
    description: 'Відповідальні ігрові поради та правила',
    content: ''
  },
  settings: {
    incorrectEmail: 'Адреса електронної пошти неправильно',
    bigFileSize: 'Розмір файлу не повинен перевищувати 5 Мб.',
    genders: { man: 'Чоловічі', female: 'Жіночі', other: 'Інші' },
    requiredField: "Обов'язкове поле",
    dataSuccessChanged: 'Дані успішно оновлені',
    tfaLoginRequired: 'Встановіть логін і пароль для налаштування аутентифікатора',
    general: 'Загальний',
    login: 'Увійти',
    vkProfile: 'Профіль ВКонтакте',
    glProfile: 'Профіль Google',
    okProfile: 'Профіль Однокласники',
    fbProfile: 'Профіль Facebook',
    security: 'Безпека',
    alterLogin: 'Додайте альтернативний метод логіна і пароля в свій аккаунт.',
    newPassword: 'Новий пароль',
    repeatPassword: 'Повторіть пароль',
    saveActions: 'Зберегти дії',
    currentPassword: 'Поточний пароль',
    tfa: 'Двофакторна автентифіка',
    addTfa: 'Захистіть свій обліковий запис Brillx з додатковою безпекою',
    tfaSettings: 'Параметри 2FA',
    sessions: 'Сесії',
    device: 'Пристрій',
    region: 'Регіон',
    ipAddress: 'IP-адреса',
    date: 'Дата',
    session: 'Сесія',
    active: 'Активний',
    closed: 'Закрито',
    verification: 'Перевірка',
    verificationDescription1: ' Щоб підвищити рівень обслуговування, ми просимо ідентифікувати документи. Це дозволить зберегти ваш обліковий запис в безпеці, якщо він буде відновлений, і забезпечить точну адресну доставку фізичних подарунків.',
    verificationDescription2: 'До прийнятних способів перевірки варто надати фотографію свого посвідчення особи, водійського посвідчення або паспорта. По завершенні цього процесу ми повідомимо вас електронною поштою.',
    frontPhoto: 'Фото лицьового документа, що посвідчує особу',
    selectFile: 'Виберіть файл',
    backPhoto: 'Поверне фото документа, що посвідчує',
    fileIsNotSelected: 'Файл не вибрано',
    name: 'Назва',
    surname: 'Прізвище',
    birthday: 'Дата народження',
    gender: 'Рід',
    country: 'Країна',
    city: 'Місто',
    yourName: "Ваше ім'я",
    yourSurname: 'Ваше прізвище',
    verifyStatus1: 'Документи перевіряються...',
    verifyStatus2: 'Обліковий запис перевірен',
    verifyStatus3: 'Помилка перевірки документа',
    verifyDescription1: 'Документи на даний момент знаходяться в стадії розгляду, як тільки документи будуть перевірені, ваш аккаунт буде перевірений!',
    verifyDescription2: 'Ваші дані були перевірені і підтверджені співробітниками служби безпеки.',
    verifyDescription3: 'За детальною інформацією звертайтеся до служби технічної підтримки.',
    registrationCity: 'Місто реєстрації',
    sendDocs: 'Подати на перевірку',
    affiliateCabinet: 'Офіс партнера'
  },
  tos: {
    title: 'Угода користувача',
    description: 'Умови та правила використання',
    redaction: 'Редакція за',
    content: ''
  },
  wallet: {
    deposit: 'Депозит',
    withdraw: 'Вихід',
    history: 'Історія',
    minDepositAmount: 'Мінімальний депозит через',
    minWithdrawAmount: 'Мінімальний вихід через',
    topMethods: 'Популярні',
    allMethods: 'Всі методи',
    qiwi: 'QIWI',
    ym: 'ЮMoney',
    card: 'Карта',
    payeer: 'Payeer',
    crypto: 'Криптовалюта',
    bitcoin: 'Bitcoin',
    usdt: 'Tether',
    mobile: 'Моб. платежi',
    megafon: 'Megafon',
    mts: 'MTS',
    beeline: 'Beeline',
    tele2: 'Tele 2',
    pm: 'Perfect Money',
    terminal: 'Terminal (RF)',
    advcash: 'AdvCash',
    fk: 'FK Wallet',
    steam: 'Steam',
    litecoin: 'Litecoin',
    ethereum: 'Ethereum',
    bitcoin_cash: 'BCH',
    dash: 'Dash',
    dogecoin: 'Dogecoin',
    monero: 'Monero',
    zcash: 'ZCash',
    usdt_erc: 'ERC20',
    usdt_trc: 'TRC20',
    bnb: 'BNB',
    ripple: 'Ripple',
    waves: 'Waves',
    other: 'Інше',
    success: 'Успішний',
    canceled: 'Відмінено',
    error: 'Помилка',
    waiting: 'Очікування',
    processing: 'Заявка обробляється',
    incorrectWalletValue: 'Номер торбинки введено неправильно',
    incorrectCardValue: 'Номер картки введено неправильно',
    selectTemplate: 'Оберіть шаблон',
    withdrawCreated: 'Заявка Payout створена і буде оброблена протягом 24 годин',
    withdrawCanceled: 'Ставку на виплату скасовано',
    firstWithdraw: 'Це ваш перший висновок',
    isDataCorrect: 'Ви впевнені, що введення даних є правильним?',
    payMethod: 'Спосіб оплати',
    withdrawMethod: 'Спосіб виведення',
    selectMethod: 'Виберіть спосіб',
    selectOperator: 'Виберіть оператора',
    refillAmount: 'Поповнення',
    withdrawAmount: 'Сума зняття',
    payTroubles: 'Якщо у вас виникли проблеми з оплатою спробуйте',
    payHere: 'Оплатити тут',
    pay: 'Перейти до оплати',
    commission: 'Комісія',
    onePayLimit: 'Ліміт одноразового поповнення',
    oneWithdrawLimit: 'Обмеження одного виходу',
    emptyHistory: 'Історія відсутня',
    operationID: 'Операція ID',
    deposits: 'Поповнення',
    withdraws: 'Висновки',
    txAmount: 'Сума транзакції',
    txDate: 'Дата операції',
    purse: 'Оцінка',
    typePurse: 'Номер рахунку',
    toGet: 'Щоб отримати',
    createWithdraw: 'Створити додаток'
  },
  tfa: {
    tfa: 'Двофакторна автентифіка',
    typeYourPassword: 'Введіть Ваш пароль',
    enterPin: 'Введіть 6-значний код із додатка',
    downloadApp: 'Завантажити додаток',
    downloadAndInstall: 'Завантаження та інсталяція',
    forYourPhone: 'для телефону або планшета',
    scanQR: 'сканування QR-коду',
    scanQRWithCamera: 'Сканування QR ліворуч за допомогою камери телефону',
    openWithLink: 'Відкрити за посиланням',
    openInApp: 'Відкрити у програмі',
    tfaManualEnter: 'Клавіша 2FA (ручне введення)'
  },
  pwa: {
    androidStep1: 'Відкрити браузер Chrome',
    androidStep1Description: 'Якщо ви перебуваєте в будь -якому іншому браузері, відкрийте цю сторінку в Chrome',
    androidStep2: 'Натисніть на меню браузера',
    androidStep2Description: 'Меню браузера - це еліпсис, розташований у верхньому правому куті браузера',
    androidStep3: 'Виберіть "Додати на головний екран"',
    androidStep3Description: 'Ви побачите вікно з інформацією ("Додати на головний екран"), у якому потрібно вибрати "Додати", після чого додаток буде додано на робочий стіл вашого пристрою',
    androidAlert: 'Кроки можуть відрізнятися залежно від версії браузера',
    iosStep1: 'Відкрити браузер Safari',
    iosStep1Description: 'Якщо ви перебуваєте в будь -якому іншому браузері, відкрийте цю сторінку в Safari',
    iosStep2: 'Натисніть "Поділитися"',
    iosStep2Description: 'Натисніть кнопку "Поділитися" внизу екрана, після чого відкриється вікно вибору параметрів',
    iosStep3: 'Виберіть "Додати на головний екран"',
    iosStep3Description: 'Виберіть "Додати на головний екран" і натисніть "Додати"',
    alert: 'Кроки можуть відрізнятися залежно від версії браузера',
    installApp: 'Встановити додаток Brillx',
    back: 'Назад',
    app: 'Мобільний додаток',
    installAppForMobile: 'Встановлюйте нове зручне додаток Brillx для iOS і Android',
    install: 'Встановити'
  },
  undefined: '????'
};