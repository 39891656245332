import React from 'react';
import StaticLobbyProviders from './static/LobbyProviders';

export default class Providers extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.providers.length, this.props.t.state.Lobby.providersOpen, this.props.t.state.Lobby.providerSelected, this.props.t.state.lang], [this.pState.Lobby.providers.length, this.pState.Lobby.providersOpen, this.pState.Lobby.providerSelected, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={[this.props.t.styles['providersList'], !this.props.t.state.Lobby.providersOpen ? this.props.t.bstyles['d-none'] : ''].join(' ')}>
                    {!this.props.t.state.Lobby.providers.length ? (
                        <>
                            <StaticLobbyProviders t={this.props.t} />
                        </>
                    ) : this.props.t.state.Lobby.providers.map(provider => (
                        <div key={provider.id} className={this.props.t.styles['item']}>
                            <div className={this.props.t.styles['btnWrapper']}>
                                <button onClick={() => { this.props.t.state.Lobby.providerSelected === provider.id ? this.props.t.doRoute('/lobby/0/' + (!this.props.t.state.Lobby.categorySelected ? '0' : this.props.t.state.Lobby.categorySelected)) : this.props.t.doRoute('/lobby/' + provider.id + '/' + (!this.props.t.state.Lobby.categorySelected ? '0' : this.props.t.state.Lobby.categorySelected)) }} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Lobby.providerSelected === provider.id ? this.props.t.styles['active'] : ''].join(' ')}>{provider.name}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

}