module.exports = {
    system: { code: 'ko', name: '한국어' },
    aml: { title: '자금 세탁 대응', description: '안전 제어', content: '' },
    auth: {
        accountCreated: '등록된 계정',
        agreeText: '나는 18 세이고 읽었다는 것을 확인했습니다.',
        agreeTos: '서비스 약관',
        alreadyRegistered: '이미 등록되어 있습니까?',
        byLogin: '로그인 및 비밀번호',
        bySocialNetworks: '소셜 네트워크를 통해',
        createAccount: '계정 만들기',
        forgotPassword: '비밀번호를 잊으셨나요?',
        loading: '로딩 중...',
        login: '로그인',
        loginTitle: '사이트 권한 부여',
        maxLoginLength: '최대 로그인 길이:',
        maxPasswordLength: '최대 암호 길이:',
        minLoginLength: '최소 로그인 길이:',
        minPasswordLength: '최소 암호 길이:',
        passwordConfirmation: '암호 반복',
        passwordsMustBeSame: '비밀번호가 일치해야 함',
        pleaseWait: '잠시 기다려 주십시오...',
        registration: '등록',
        registrationTitle: '사이트에 등록하기',
        symbols: '문자',
        yourLogin: '로그인',
        yourPassword: '비밀번호',
        yourEmail: '귀하의 이메일',
        logoutConfirm: '정말 나가고 싶니?',
        passwordRecovery: '비밀번호 재설정',
        newPassword: '새 암호',
        copied: '복사됨',
        logout: '아웃'
    },
    basic: {
        yes: '다',
        no: '아니',
        confirm: '확인하다',
        confirmed: '상향',
        cancel: '폐지',
        sending: '전송 중...',
        sent: '제출합니다',
        save: '저장',
        create: '만들기',
        or: '또는',
        activate: '활성화',
        deactivate: '비활성화',
        prevPage: '이전 페이지',
        nextPage: '다음 페이지',
        of: '밖으로',
        balance: '밸런스',
        copy: '복사',
        copied: '복사됨'
    },
    bonus: {
        permanentBonus: '영구 보너스',
        tenPercent: '모든 예금에 대해 10%!',
        more: '더 읽기',
        fastRegistration: '빠른 체크인',
        noVerification: '검증 없이',
        fastWithdraws: '빠른 지불금',
        highLimits: '높은 한계',
        loyaltyProgramm: '로열티 프로그램',
        winCashback: '승리할 때마다 최대 1.2%!',
        levelSystem: '레벨 시스템',
        bigPercents: '큰 백분율',
        payForWin: '승리에 대한 지불',
        butNoBets: '베팅 용이 아님',
        affiliateProgramm: '제휴 프로그램',
        join: '가입하기',
        revenueShare: '40% 매출 점유율',
        anytimeWithdraw: '언제든지 출력',
        affiliateCabinet: '제휴 캐비닛',
        detailStat: '자세한 통계',
        title: '프로모션 코드',
        promoEveryDay: '우리 VK 그룹에서 매일 프로모션 코드',
        enterPromoCode: '프로모션 코드 입력'
    },
    fair: {
        title: '라이선스 및 정직 보증',
        description: '모두에게 공정한 플레이를 보장합니다.',
        fairDescription1: 'Brillx Casino는 사용자가 게임의 정직성을 확신할 수 있도록 보안 환경을 만드는 것이 얼마나 중요한지 잘 알고 있습니다.',
        fairDescription2: '따라서 카지노에서는 온라인 카지노 용 소프트웨어를 개발하는 최고의 회사가 제공하는 게임을 사용합니다.그 중에서도 다음과 같은 회사',
        fairDescription3: 'ECOGRA와 IGC의 창립 멤버 인 Microgaming은 다른 카지노 게임 제조업체보다 앞서고 있으며, 특히 온라인 게임의 정직성, 가장 책임있는 운영자 선택 및 플레이어의 이익을 보호하기 위해 도박 업계에서 가장 높은 기준을 설정합니다.',
        andOther: '및 기타',
        also: '또한 회사에서 만든 게임도 제공합니다.',
        defaultCasino: '일반 온라인 카지노',
        brillxCasino: 'Brillx 정직 컨트롤 카지노',
        defaultCasinoDescription1: '카지노 서버에 쓰여진 내기를합니다.',
        defaultCasinoDescription2: '귀하의 데이터는 카지노 서버에 수집되어 저장됩니다.',
        defaultCasinoDescription3: '게임 정직성을 확인하는 것은 불가능합니다. 모든 알고리즘이 기록되어 카지노 측에 저장됩니다.',
        defaultCasinoDescription4: '승리를 얻으려면 긴 인증 절차를 거쳐야 합니다.',
        defaultCasinoDescription5: '균형이 어디에도 게시되지 않기 때문에 문제의 카지노의 지급 능력',
        brillxCasinoDescription1: '플레이어 베팅 제공업체의 데이터 사본은 블록체인에 저장되며 변경할 수 없습니다.',
        brillxCasinoDescription2: '인간에 대한 게임 통계는 플레이어 개인 데이터에 연결되어 있지 않은 블록체인에 암호화되고 기록됩니다.',
        brillxCasinoDescription3: '무결성 제어는 블록체인 기술로 이루어집니다.',
        brillxCasinoDescription4: '인출에는 KYC 표준 승인이 필요하며 송금 수수료 없음',
        brillxCasinoDescription5: '공개 보고 및 플레이어 지불금을 위한 대규모 풀'
    },
    game: {
        loading: '로딩 중',
        lobby: '로비',
        fullScreen: '전체 화면',
        deposit: '보증금',
        betsAmount: '베팅 금액',
        totalRTP: '총 RTP',
        averageBet: '평균 베팅',
        topWin: '최고의 승리'
    },
    help: {
        title: '도움말 및 지원',
        description: '가장 일반적인 질문에 대한 답변 및 고객 서비스와의 커뮤니케이션.',
        connectTypes: '당사와 소통하는 방법',
        operatorsOnline: '하루 24시간 온라인 운영자',
        supportLive: '지원 (라이브)',
        onlineChat: '라이브 채팅',
        averageTimeChat: '평균 응답 시간: 10분',
        supportEmail: '고객 서비스 (이메일)',
        averageTimeEmail: '평균 응답 시간: 1일',
        generalQuestions: '일반 이슈',
        aboutSite: '사이트 정보',
        aboutSiteContent: 'Brillx는 국제 라이센스와 이름을 가진 온라인 카지노입니다.카탈로그에 6,000 개 이상의 게임, 다양한 스포츠 이벤트 및 많은 미니 게임을 제공합니다.저희는 많은 결제 시스템과 대부분의 인기 있는 암호화폐를 지원합니다.',
        bonus: '보너스',
        bonuses: '보너스',
        onPage: '페이지에서',
        manyFreeBonuses: '잔액에 대한 돈을 절대적으로 무료로 얻는 방법에는 여러 가지가 있습니다!',
        bonus1: '등록 보너스.사이트에 등록한 후 초대 코드를 입력하고 최대 10.00 RUB까지 60 분이 소요됩니다.',
        bonus2: '등록 보너스.사이트에 등록한 후 초대 코드를 입력하고 최대 10.00 RUB까지 60 분이 소요됩니다.',
        bonus3: '등록 보너스.사이트에 등록한 후 초대 코드를 입력하고 최대 10.00 RUB까지 60 분이 소요됩니다.',
        bonus4: '등록 보너스.사이트에 등록한 후 초대 코드를 입력하고 최대 10.00 RUB까지 60 분이 소요됩니다.',
        promocodes: '프로모션 코드',
        inviteFriend: '친구 초대',
        balanceRefilling: '잔액 보충',
        wallet: '지갑',
        howToRefill: '잔액을 보충하려면 어떻게 해야 합니까?',
        commissions: '위원회',
        periodOfRefilling: '등록 약관',
        withdrawal: '인출',
        howToWithdraw: '자금을 인출하려면 어떻게 해야 합니까?',
        periodOfWithdrawal: '탈퇴 타임라인',
        clickToRefill: '버튼을 클릭하여 잔액을 보충하십시오.',
        atRightCorner: '사이트의 오른쪽 상단 모서리에 있습니다.편리한 결제 방법을 선택하고 입금액을 입력한 후 결제로 이동을 클릭합니다.',
        minimalRefilling: '최소 보충',
        commissionsOnPage: '모든 지불 방법의 수수료가 페이지에 나와 있습니다.',
        clickToWithdraw: '버튼을 클릭하여 계정에서 돈을 인출합니다.',
        averageTimeWithdraw: '모든 결과는 적용 시기로부터 평균 1시간 이내에 처리됩니다.',
        allTransactions: '모든 거래는 즉시 처리됩니다.잔액을 보충하는 결제 방법에 문제가 있는 경우에만 지연이 발생할 수 있습니다.',
        inCaseOfProblems: '문제가 있으면 고객 서비스에 문의하여 문제에 대한 가장 자세한 정보를 제공하십시오.',
        atRightCornerWithdraw: '사이트의 오른쪽 상단 모서리에 있습니다.편리한 인출 방법을 선택하고 금액을 입력한 다음 결제로 이동을 클릭합니다.',
        minimalWithdrawal: '최소 출금',
        ifBigAmount: '인출 금액이 많으면 인출이 조금 더 오래 걸릴 수 있습니다. 보통 최대 1 시간까지 걸릴 수 있습니다.밤 (00:00am ~ 09:00 MSC) 에는 조사 결과가 몇 시간 동안 진행될 수 있습니다. 이때 결제 애그리 게이터는 인출 요청을 더 오래 처리합니다.',
        sometimes: '매우 드문 경우이지만 은행 적재 등으로 인출은 최대 24 시간까지 걸릴 수 있습니다.그 시간 이후에 돈이 도착하지 않으면 고객 서비스에 문의하여 문제에 대한 가장 자세한 정보를 제공하십시오.',
        checkCorrectPurse: '또한 출력에 대한 세부 정보를 올바르게 지정해야 합니다.출금을 지적한 지갑을 안심하려면 원하는 거래를 클릭하여 거래 내역과 세부 정보를 열어야합니다.'
    },
    interface: {
        months: {
            jan: '1월.',
            feb: '2월.',
            mar: '3월.',
            apr: '4월.',
            may: '마이',
            jun: '6월.',
            jul: '7월.',
            aug: '8월.',
            sep: '9월',
            oct: '10월',
            nov: '11월',
            dec: '12월.'
        },
        year: '년도',
        confirmPasswordRecovery: '이메일로 전송된 링크를 따라 비밀번호 재설정 확인',
        passwordWasChanged: '암호가 성공적으로 변경되었습니다. 로그인하여 새 데이터를 사용할 수 있습니다.',
        confirmEmail: '링크로 이메일 주소 확인',
        emailWasConfirmed: '이메일 주소가 성공적으로 확인되었습니다.',
        accountLinked: '계정이 성공적으로 연결되었습니다.',
        bonusGranted: '보너스 접수'
    },
    landing: {
        newGames: '새로운 게임',
        topGames: '인기 게임',
        brillxSelect: '브릴크스 초이스',
        daylyLiveRtp: '라이브 RTP - 24시간',
        brillxCasino: '브릴스 카지노',
        casinoInfo1: '귀하의 만족을 위해 맞춤형 슬롯 머신 사이트인 Brillx.GG에서 다양한 흥미 진진한 게임을 확인하십시오.전 세계 플레이어들이 수천 개의 일류 슬롯 머신을 이용할 수 있습니다.거대한 잭팟, 포커 슬롯 또는 테마 슬롯 머신이든, 필요한 모든 것을 갖추고 있습니다!',
        casinoInfo2: '지금 체크인 버튼을 클릭하여 빠른 체크인을 쉽게 경험하세요!주요 게임 제공 업체의 리얼 머니 슬롯 머신을 플레이 할 때 순간을 즐기십시오.',
        casinoInfo3: '빠른 지불금과 다양한 종류의 슬롯 머신 변형을 갖춘 카지노는 연중 무휴로 작동합니다. 게임을 선택하고 게임을 시작하십시오!',
        casinoInfo4: '온라인 슬롯 플레이',
        casinoInfo5: '우리는 당신의 재미를 망칠 수 없으므로 컴퓨터에서 아무 것도 다운로드하지 않고도 모든 게임을 즐길 수 있습니다.',
        casinoInfo6: '수천 개의 슬롯을 탐색하고 원하는 게임을 선택한 다음 “PLAY”를 클릭하십시오.',
        casinoInfo7: '카지노 슬롯 게임은 가장 인기있는 게임 중 하나입니다.당신이 얻을 수있는 엄청난 인센티브 때문에뿐만 아니라 흥미 진진한 그래픽, 좋은 음악, 게임의 전반적인 즐거운 시각 효과 때문에.',
        casinoInfo8: '엔터테인먼트 모드로 전환하여 각 슬롯 머신을 시험해 볼 수도 있습니다. 다운로드하지 않고 등록하지 않고도 모든 슬롯 머신을 재생할 수있는 놀라운 기능은 무료입니다!휴대 전화, 컴퓨터 또는 태블릿을 통해 액세스 할 수 있습니다.다운로드 할 필요없이 무료로 플레이 할 수있는 최고의 슬롯 머신을 발견하고 전 세계 최고의 카지노 슬롯에서 독특한 모험을 경험하십시오.오늘 최고의 샷을 찍고 운명이 당신을 기다리고있는 것을 결정하십시오!',
        casinoInfo9: '무엇을 기다리고 있니?오늘은 운이 좋은 날이 될 수 있습니다!',
        casinoInfo10: '수천 개의 슬롯 머신',
        casinoInfo11: '카지노에서 찾을 수있는 다양한 유형의 슬롯 머신은 다음과 같습니다.',
        casinoInfo12: '릴 슬롯 3개',
        casinoInfo13: '3 릴 슬롯은 클래식 또는 전통적인 슬롯 머신으로 가장 잘 알려져 있습니다.릴은 심볼이 계단식으로 연결된 게임에서 찾을 수있는 수직선입니다.스릴을 찾고 있다면 3 릴 슬롯 머신을 연주하십시오!플레이 할 수있는 게임 중 일부는 연금술서, 왓포드 FC 슬롯 및 기타 여러 게임입니다.',
        casinoInfo14: '릴 슬롯 5개',
        casinoInfo15: "5 릴 슬롯을 플레이하여 조합에서 우승 할 수있는 더 나은 기회를 얻으십시오!대부분의 5 릴 슬롯은 3, 4 또는 5 문자가 릴에 떨어질 때마다 지불해야합니다.게임에 따라 승수, 보너스 게임 라운드 또는 무료 스핀을 얻을 수도 있습니다.플레이 할 수있는 5 릴 게임 중 일부는 바이킹스 고 버저크, 레거시 오브 데드, 그 리치 (That's Rich) 입니다.",
        casinoInfo16: '점진적 슬롯',
        casinoInfo17: '프로그레시브 슬롯은 모든 유형의 슬롯 머신 중 하나입니다.게임이 진행됨에 따라 잭팟 크기가 증가합니다.거대한 수백만 명이 당신을 기다리고 있으며, 지금까지 가장 부유 한 사람들 중 하나가 될 수 있습니다!',
        casinoInfo18: '룰렛',
        casinoInfo19: '룰렛은 테이블, 색상, 숫자 유형 또는 조합에 개별 번호를 베팅하는 게임입니다.행운을 시험하고 베팅하는 것이 휠에 닿으면 승리하십시오.지금 바로 훌륭한 실시간 룰렛 게임을 플레이하세요!',
        casinoInfo20: '포커',
        casinoInfo21: '일반 카지노에서 포커에 익숙하다면 다양한 온라인 비디오 포커 게임을 활용하여 지금 당장 플레이해 볼 수 있습니다.오늘 잊지 못할 게임을 경험할 준비를하십시오!고화질 애니메이션과 훌륭한 특별 비디오 라운드를 통해 새로운 승리를 위해 확실히 돌아올 것입니다.',
        casinoInfo22: '바카라',
        casinoInfo23: '바카라는 세계에서 가장 인기있는 게임 중 하나입니다.9에 가장 가까운 핸드에 베팅하여 딜러를 처치하십시오.또한 무승부가 있으면 이길 수도 있습니다.정말 흥미롭게 들리나요?지금 베팅을 준비하고 승리 한 편으로 베팅하십시오!',
        casinoInfo24: '곤봉',
        casinoInfo25: '블랙 잭에서는 21에 가장 가까운 손이 있으면 딜러를 이길 수 있습니다.장엄한 블랙 잭 슬롯 머신을 플레이하면 내기를 두 배로 늘릴 수있는 기회를 얻으십시오!',
        gamesProviders: '게임 제공업체',
        showMore: '더 보기',
        hideInfo: '정보 숨기기',
        allGames: '모든 게임',
        providers: '제공자',
        new: '새로운 기능',
        tournaments: '토너먼트',
        slots: '슬롯',
        roulette: '룰렛',
        card: '카드',
        videopoker: '비디오 포커',
        lottery: '운'
    },
    live: { demo: '데모', play: '놀이', title: 'Live' },
    lobby: {
        providers: '제공자',
        recent: '최근',
        top: '인기',
        new: '새로운 기능',
        favorite: '즐겨찾기',
        nothingFound: '찾을 수 없음',
        play: '놀이',
        demo: '데모',
        forExample: '예를 들어'
    },
    log: { date: '날짜', type: '운영', amount: '금액', id: 'ID' },
    loyalty: {
        title: '로열티 프로그램',
        description: '베팅할 때마다 로열티 레벨에 따라 달라지는 캐쉬백 받기',
        cabinet: '캐비닛',
        myCabinet: '내 사무실',
        payLog: '적치휴가 로그',
        log: '로그',
        level: '계층',
        totalWins: '당첨 금액',
        totalBets: '베팅 금액',
        availableBalance: '가용 잔액',
        get: '테이크 어',
        cashback: '캐쉬백',
        emptyHistory: '스토리가 사라졌습니다.',
        operationID: '작업 ID',
        amount: '금액',
        date: '날짜',
        operationDate: '운영 날짜',
        operationType: '운영',
        balanceBefore: '밸런스 업',
        balanceAfter: '밸런스 이후'
    },
    menu: {
        affProgramm: '파트너',
        fairGame: '페어 플레이',
        free: '무료',
        games: '게임',
        lobby: '로비',
        promo: '프로모션',
        loyaltyProgramm: '충성도',
        promotions: '주식',
        support: '안내 데스크',
        settings: '설정',
        history: '게임 히스토리',
        more: '더 보기',
        profile: '프로필',
        bonusCode: '프로모션 코드'
    },
    noty: { error: '오류', success: '성공한' },
    profile: {
        wallet: '지갑',
        onSiteFrom: '사이트 내',
        totalBets: '베팅 금액',
        betsCount: '베팅',
        totalWins: '당첨 금액',
        bestWin: '최고의 승리',
        emptyHistory: '스토리가 사라졌습니다.',
        balanceAfter: '밸런스 이후'
    },
    partner: {
        maxCodeLength: '최대 코드 길이:',
        minCodeLength: '최소 코드 길이:',
        maxNameLength: '최대 이름 길이:',
        minNameLength: '최소 이름 길이:',
        symbols: '문자',
        confirmDeleteCampgain: '캠페인을 삭제하시겠습니까?',
        campgains: '캠페인',
        refresh: '업그레이드',
        export: '내보내기',
        campgainName: '캠페인 이름',
        affiliateLink: '제휴 링크',
        name: '제목',
        creationDate: '생성된 날짜',
        clicks: '전환',
        link: 'Ref.',
        manage: '관리',
        noCampgains: '활성 광고 캠페인 없음',
        namePlaceholder: '패널에 표시할 이름',
        general: '일반',
        mainAffiliateLink: '메인 제휴 링크',
        registrations: '등록',
        totalProfit: '공통의 이익',
        availableBalance: '가용 잔액',
        get: '테이크 어',
        emptyHistory: '스토리가 사라졌습니다.',
        operationID: '작업 ID',
        amount: '금액',
        date: '날짜',
        operationDate: '운영 날짜',
        operationType: '운영',
        playerID: '플레이어 ID',
        balanceAfter: '밸런스 이후',
        balanceBefore: '밸런스 업',
        log: '적치휴가 로그',
        players: '플레이어',
        registration: '등록',
        registrationDate: '등록 날짜',
        balance: '밸런스',
        noPlayers: '게스트 플레이어 없음',
        totalBets: '베팅 금액',
        totalDeps: '입금 금액',
        totalWithdraws: '결과 합계',
        registrationSource: '등록 소스',
        mainLink: '메인 링크',
        player: '플레이어',
        registrationsCount: '등록',
        bets: '판돈이 걸린',
        profit: '이익',
        stat: '통계',
        totalStat: '전체 통계',
        partnerCabinet: '파트너 사무실',
        partnerID: '파트너 ID'
    },
    privacy: {
        title: '개인정보 보호정책',
        description: '일반 조항 및 정보',
        redaction: '에디토리얼 by',
        content: ''
    },
    promo: {
        linkVk: 'VK 계정을 바인딩하여 보너스를 받으십시오.',
        completeAll: '모든 조건에 맞추고',
        toBalanceForRepost: '재게시를 위한 균형 유지!',
        link: '바인딩',
        join: '엔트리',
        allowNotifications: '알림 허용',
        make: '할',
        toProfileVkAccount: 'vK 계정 프로필로 이동합니다.',
        toOurVkGroup: 'VK 그룹에 연락하십시오.',
        fromOurGroup: '우리 커뮤니티에서',
        repostToYourPage: '항목을 자신의 페이지에 다시 게시합니다.',
        mustBePublic: 'VK 페이지가 공개되어 있어야 합니다. 그렇지 않으면 재게시를 확인할 수 없습니다.',
        dontDelete: '재게시 후 2주 이내에 게시물을 삭제하면 보너스 금액이 잔액에서 차감됩니다.',
        joinAndGet: '그룹에 가입하여',
        toBalance: '밸런스!',
        bonusGranted: '보너스가 성공적으로 받았습니다!',
        momentalBonus: 'VK 그룹에 가입하고 잔액에 즉시 보너스를 받으십시오!VK 프로필은 공개여야 합니다. 그렇지 않으면 구독을 확인할 수 없습니다.',
        vkDoesntLinked: '명 VK 계정이 연결되지 않음',
        allowNotificationsAndGet: '커뮤니티의 알림 허용 및 받기',
        sendAnyMessage: 'VK 그룹에 메시지를 보내면 알림을 보낼 수 있습니다.멋진 놀라움 - 정기적으로 프로모션 및 다양한 보너스에 대한 정보를 보내드립니다!',
        rollAndGetBonus: '룰렛을 크루즈하고 보너스를 받으세요!',
        rollOncePer: '15 분마다 한 번 룰렛을 비틀고',
        rollEveryDay: '매일 제한 없이 휠을 돌리십시오!',
        untilNextTry: '다음 시도가 끝나기 전에',
        subscribeTg: '텔레그램 채널에 가입하여 새로운 프로모션 코드를 먼저 받으세요!',
        beFirst: '새로운 프로모션 코드를 가장 먼저 받고 새로운 보너스와 혁신에 대해 알아보십시오!',
        referrals: '추천',
        getPercent: '친구를 초대하고 40% 의 카지노 혜택을 받으십시오!',
        copyLink: '링크 복사',
        inviteFriend: '친구 초대',
        getBonus: '보너스 받기',
        affiliateProgramm: '제휴 프로그램',
        sendReview: '우리 그룹의 프로젝트에 대한 피드백을 제공하고',
        bonusToDeposit: '입금 보너스',
        upTo: '까지',
        makeReviews: 'VK 그룹에 피드백을 남기십시오. 중재자가 확인되고 게시되면 보너스가 귀하의 계정에서 자동으로 활성화됩니다.',
        makeReview: '피드백',
        ranks: '랑기',
        earnFromWin: '랭크를 획득하면 승리할 때마다 캐쉬백이 증가합니다.',
        loyaltyProgramm: '로열티 프로그램',
        writeMessage: '메시지 작성',
        goToGroup: '그룹으로 이동',
        refreshing: '업데이트...',
        refreshStatus: '업그레이드',
        roll: '회전',
        linkAccount: '계정 바인딩',
        checking: '확인 중...',
        get: 'Get'
    },
    promotions: {
        title: '주식 & 오퍼',
        description: '현재 주식에 보너스를 받을 수 있는 기회를 놓치지 마세요',
        game: '게임',
        minDeposit: '최소 보증금',
        bonus: '보너스',
        addWager: '추가 베팅',
        validUntil: '최대 행동',
        activation: '활성화',
        get: 'Get',
        unlimit: '무제한',
        oneTime: '일회성',
        forEveryDeposit: '모든 예금에 대해',
        permanentBonus: '영구 보너스',
        any: '어느'
    },
    responsibility: { title: '책임 있는 게임', description: '책임감 있는 게임 팁 및 규칙', content: '' },
    settings: {
        incorrectEmail: '이메일 주소가 잘못되었습니다.',
        bigFileSize: '파일 크기는 5MB를 초과해서는 안 됩니다.',
        genders: { man: '남성', female: '여자', other: '기타' },
        requiredField: '필수 필드',
        dataSuccessChanged: '데이터가 성공적으로 업데이트되었습니다.',
        tfaLoginRequired: '인증자를 설정하기 위해 로그인 및 암호 설정',
        general: '일반',
        login: '로그인',
        vkProfile: '명 VK 프로필',
        glProfile: '구글 프로필',
        okProfile: '프로필 급우',
        fbProfile: '페이스 북 프로필',
        security: '안전성',
        alterLogin: '계정에 다른 로그인 및 암호 방법을 추가합니다.',
        newPassword: '새 암호',
        repeatPassword: '암호 반복',
        saveActions: '액션 저장',
        currentPassword: '현재 암호',
        tfa: '2단계 인증',
        addTfa: '추가 보안으로 Brillx 계정 보호',
        tfaSettings: '2FA 설정',
        sessions: '세션',
        device: '디바이스',
        region: '지역',
        ipAddress: 'IP 주소',
        date: '날짜',
        session: '세션',
        active: '액티브',
        closed: '휴무',
        verification: '검증',
        verificationDescription1: ' 서비스 수준을 향상시키기 위해 문서를 식별하도록 요청합니다.이렇게 하면 계정이 복원될 경우 계정이 안전하게 보호되고 실제 선물을 정확하게 타겟팅할 수 있습니다.',
        verificationDescription2: '허용 가능한 확인 방법은 신분증, 운전 면허증 또는 여권 사진을 제공하는 것입니다.이 프로세스가 완료되면 이메일로 통지합니다.',
        frontPhoto: '신분 증명 문서 앞면 사진',
        selectFile: '파일 선택',
        backPhoto: '신분증 뒷면 사진',
        fileIsNotSelected: '파일이 선택되지 않음',
        name: '이름',
        surname: '성',
        birthday: '생년월일',
        gender: '성별',
        country: '국가',
        city: '시티',
        yourName: '사용자 이름',
        yourSurname: '사용자 성',
        verifyStatus1: '문서를 검사하는 중입니다...',
        verifyStatus2: '계정 확인됨',
        verifyStatus3: '문서 검사 오류',
        verifyDescription1: '현재 문서를 검토 중입니다. 문서가 확인되면 계정이 확인됩니다!',
        verifyDescription2: '보안 담당자가 데이터를 확인하고 확인했습니다.',
        verifyDescription3: '자세한 내용은 기술 지원부에 문의하십시오.',
        registrationCity: '등록 도시',
        sendDocs: '검사를 위해 제출',
        affiliateCabinet: '파트너 사무실'
    },
    tos: {
        title: '사용자 계약',
        description: '약관 및 사용 규칙',
        redaction: '에디토리얼 by',
        content: ''
    },
    wallet: {
        deposit: '보증금',
        withdraw: '출력',
        history: '역사',
        minDepositAmount: '최소 예치금',
        minWithdrawAmount: '최소 출력',
        topMethods: '인기',
        allMethods: '모든 메소드',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: '기타',
        success: '성공한',
        canceled: '취소됨',
        error: '오류',
        waiting: '대기',
        processing: '응용 프로그램이 처리 중입니다.',
        incorrectWalletValue: '지갑 번호가 잘못 입력되었습니다.',
        incorrectCardValue: '카드 번호가 잘못 입력되었습니다.',
        selectTemplate: '템플릿 선택',
        withdrawCreated: '지불금 신청서가 생성되어 24시간 이내에 처리됩니다.',
        withdrawCanceled: '지불금 입찰이 취소되었습니다.',
        firstWithdraw: '이것이 첫 번째 결론입니다.',
        isDataCorrect: '데이터 입력이 정확하다고 확신하십니까?',
        payMethod: '결제 방법',
        withdrawMethod: '출금 방법',
        selectMethod: '방법 선택',
        selectOperator: '연산자 선택',
        refillAmount: '보충',
        withdrawAmount: '출금 금액',
        payTroubles: '지불에 문제가 있는 경우',
        payHere: '여기에서 지불하십시오',
        pay: '결제로 이동',
        commission: '위원회',
        onePayLimit: '단일 보충 한도',
        oneWithdrawLimit: '단일 출력 제한',
        emptyHistory: '스토리가 사라졌습니다.',
        operationID: '작업 ID',
        deposits: '보충',
        withdraws: '발견된 사항',
        txAmount: '거래 금액',
        txDate: '운영 날짜',
        purse: '점수',
        typePurse: '계정 번호',
        toGet: '수신하려면',
        createWithdraw: '애플리케이션 생성'
    },
    tfa: {
        tfa: '2단계 인증',
        typeYourPassword: '비밀번호 입력',
        enterPin: '앱에서 6자리 코드 입력',
        downloadApp: '앱 다운로드',
        downloadAndInstall: '다운로드 및 설치',
        forYourPhone: '휴대폰 또는 태블릿용',
        scanQR: 'qR 코드 스캔',
        scanQRWithCamera: '휴대폰의 카메라로 왼쪽의 QR을 스캔합니다.',
        openWithLink: '링크로 열기',
        openInApp: '앱에서 열기',
        tfaManualEnter: '2FA 키 (수동 입력)'
    },
    pwa: {
        androidStep1: 'Chrome 브라우저 열기',
        androidStep1Description: '다른 브라우저를 사용 중인 경우 Chrome에서 이 페이지를 엽니다',
        androidStep2: '브라우저 메뉴 클릭',
        androidStep2Description: '브라우저 메뉴는 브라우저의 오른쪽 상단에 있는 줄임표입니다',
        androidStep3: '"홈 화면에 추가" 선택',
        androidStep3Description: '정보가 포함된 창이 표시되며("홈 화면에 추가") "추가"를 선택해야 합니다. 그런 다음 응용 프로그램이 장치의 바탕 화면에 추가됩니다.',
        androidAlert: '브라우저 버전에 따라 단계가 다를 수 있습니다',
        iosStep1: 'Safari 브라우저 열기',
        iosStep1Description: '다른 브라우저를 사용 중인 경우 Safari에서 이 페이지를 엽니다',
        iosStep2: "'공유' 클릭",
        iosStep2Description: "화면 하단의 '공유' 버튼을 클릭하면 매개변수 선택 창이 열립니다",
        iosStep3: '"홈 화면에 추가" 선택',
        iosStep3Description: "'홈 화면에 추가'를 선택하고 '추가'를 클릭하세요.",
        alert: '브라우저 버전에 따라 단계가 다를 수 있습니다',
        installApp: 'Brillx 앱 설치',
        back: '뒤로',
        app: '모바일 애플리케이션',
        installAppForMobile: '새로운 편리한 iOS 및 Android용 Brillx 앱 설치',
        install: '설치'
    },
    undefined: '???'
};