import React from 'react';

import Money from '../assets/svg/Promo/money.svg';
import User from '../assets/svg/Promo/user.svg';
import Link from '../assets/svg/Promo/link.svg';
import Check from '../assets/svg/Promo/check.svg';

import Loader from '../assets/svg/loader.svg';

export default class Partner extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        recaptcha: {
            ready: false,
            token: false
        }
    };

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        this.props.t.updateState(this.props.t, 'location', 'promo');

        if (!this.props.t.state.userID)
            this.props.t.doRoute('/');
        else {
            this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'account', 0);
            this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'group');
            this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'like');
            this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'repost');
            this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'notifications');

            this.props.t.Interface.PromoCheckStatus(this.props.t, 'wheel', 0);
            this.props.t.Interface.PromoCheckStatus(this.props.t, 'repost', 0);
            this.props.t.Interface.PromoCheckStatus(this.props.t, 'group', 0);
            this.props.t.Interface.PromoCheckStatus(this.props.t, 'subscribe', 0);

            window.captchaOnLoad = this.captchaOnLoad.bind(this);

            const script = document.createElement("script");
            script.async = true;
            script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";
            this.recaptchaLoader.appendChild(script);
        }
    
        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'promo') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        if (this.props.t.state.userID)
            document.getElementById('g-recaptcha').parentNode.removeChild(document.getElementById('g-recaptcha'));

        this.props.t.updateState(this.props.t, 'location', '/');
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width, this.props.t.state.Promo], [this.pState.width, this.pState.Promo]);
    }

    captchaOnLoad() {
        const widget = document.createElement('div');
        widget.id = 'g-recaptcha';
        widget.style.visibility = 'hidden';
        document.body.appendChild(widget);

        window.grecaptcha.render('g-recaptcha', {
            sitekey: '6Lfg4PAUAAAAABijupZzyCKqOoZ4JyHtQLXq_oa_',
            size: 'invisible'
        });

        window.grecaptcha.ready(() => {
            this.setState({ recaptcha: { token: this.state.recaptcha.token, ready: true } });
        })
    }

    getCaptcha(t, type, callback) {
        if (t.state.recaptcha.ready)
            window.grecaptcha.execute({ action: 'promo/get/' + type }).then(token => { t.setState({ recaptcha: { token, ready: t.state.recaptcha.ready } }); callback(true); });
    }

    updateVkInfo() {
        this.props.t.updateState(this.props.t, 'Promo', true, 'infoRefreshing');

        this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'account');
        this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'group');
        this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'like');
        this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'repost');
        this.props.t.Interface.PromoCheckVkInfo(this.props.t, 'notifications');
    }

    getBonus(type) {
        switch (type) {
            case 'wheel':
                this.props.t.updateState(this.props.t, 'Promo', false, 'wheel', 'available');
                this.props.t.updateState(this.props.t, 'Promo', true, 'captchaLoading');
                this.props.t.updateState(this.props.t, 'Promo', 0, 'wheel', 'rotate');
                this.props.t.updateState(this.props.t, 'Promo', 0, 'wheel', 'transition');
                this.props.t.updateState(this.props.t, 'Promo', type, 'captchaModule');
                this.getCaptcha(this, type, () => {
                    this.props.t.updateState(this.props.t, 'Promo', false, 'captchaLoading');
                    this.props.t.Interface.PromoGetBonus(this.props.t, type, this.state.recaptcha.token, 0);
                    this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
                });
                break;
            case 'repost':
                this.props.t.updateState(this.props.t, 'Promo', true, 'captchaLoading');
                this.props.t.updateState(this.props.t, 'Promo', type, 'captchaModule');
                this.getCaptcha(this, type, () => {
                    this.props.t.updateState(this.props.t, 'Promo', false, 'captchaLoading');
                    this.props.t.Interface.PromoGetBonus(this.props.t, type, this.state.recaptcha.token, 0);
                    this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
                });
                break;
            case 'group':
                this.props.t.updateState(this.props.t, 'Promo', true, 'captchaLoading');
                this.props.t.updateState(this.props.t, 'Promo', type, 'captchaModule');
                this.getCaptcha(this, type, () => {
                    this.props.t.updateState(this.props.t, 'Promo', false, 'captchaLoading');
                    this.props.t.Interface.PromoGetBonus(this.props.t, type, this.state.recaptcha.token, 0);
                    this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
                });
                break;
            case 'subscribe':
                this.props.t.updateState(this.props.t, 'Promo', true, 'captchaLoading');
                this.props.t.updateState(this.props.t, 'Promo', type, 'captchaModule');
                this.getCaptcha(this, type, () => {
                    this.props.t.updateState(this.props.t, 'Promo', false, 'captchaLoading');
                    this.props.t.Interface.PromoGetBonus(this.props.t, type, this.state.recaptcha.token, 0);
                    this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
                });
                break;
        }
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={[this.props.t.styles['promoContainer'], this.props.t.state.Promo.vk.account === null ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                        {this.props.t.state.Promo.vk.account === null ?
                            <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                            : <>
                                {!this.props.t.state.Promo.vk.account && <div className={[this.props.t.bstyles['col-12'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['infoLinkBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'linkVk')}
                                        </div>
                                        <button onClick={() => this.props.t.doRoute('/settings')} className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen']].join(' ')}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'linkAccount')}</span></button>
                                    </div>
                                </div>}
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['repostPromoBlock'], this.props.t.state.Promo.repost.gained ? this.props.t.styles['gainedBlock'] : ''].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'completeAll')} <strong>50 RUB.</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toBalanceForRepost')}
                                        </div>
                                        <div className={this.props.t.styles['steps']}>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.state.Promo.vk.account ? this.props.t.styles['success'] : ''].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    <strong>{this.props.t.Interface.getText(this.props.t, 'promo', 'link')}</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toProfileVkAccount')}
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.state.Promo.vk.group ? this.props.t.styles['success'] : ''].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    <strong>{this.props.t.Interface.getText(this.props.t, 'promo', 'join')}</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toOurVkGroup')}
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.state.Promo.vk.notifications ? this.props.t.styles['success'] : ''].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    <strong>{this.props.t.Interface.getText(this.props.t, 'promo', 'allowNotifications')}</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'fromOurGroup')}
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.state.Promo.vk.repost ? this.props.t.styles['success'] : ''].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    <strong>{this.props.t.Interface.getText(this.props.t, 'promo', 'make')} <a href={this.props.t.config.social.vk.repost} target="_blank">{this.props.t.Interface.getText(this.props.t, 'promo', 'repostToYourPage')}</a></strong> 
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.styles['info']].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'mustBePublic')}
                                                </div>
                                            </div>
                                            <div className={this.props.t.styles['step']}>
                                                <div className={[this.props.t.styles['status'], this.props.t.styles['info']].join(' ')}></div>
                                                <div className={this.props.t.styles['text']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'dontDelete')}
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.t.state.Promo.repost.gained ? <div className={this.props.t.styles['gained']}><img src={Check}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'bonusGranted')}</span></div> : this.props.t.state.Promo.vk.repost && this.props.t.state.Promo.vk.group && this.props.t.state.Promo.vk.notifications ? <button disabled={this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "repost" ? true : false} onClick={() => { this.getBonus('repost') }} className={this.props.t.styles['btn']}><span>{this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "repost" ? this.props.t.Interface.getText(this.props.t, 'promo', 'checking') : this.props.t.Interface.getText(this.props.t, 'promo', 'get')}</span></button> : <button disabled={this.props.t.state.Promo.infoRefreshing ? true : false} onClick={() => { this.updateVkInfo() }} className={this.props.t.styles['btn']}><span>{this.props.t.state.Promo.infoRefreshing ? this.props.t.Interface.getText(this.props.t, 'promo', 'refreshing') : this.props.t.Interface.getText(this.props.t, 'promo', 'refreshStatus')}</span></button>}
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['order-0'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['groupPromoBlock'], !this.props.t.state.Promo.vk.account ? this.props.t.styles['noAccountBlock'] : this.props.t.state.Promo.group.gained ? this.props.t.styles['gainedBlock'] : ''].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'joinAndGet')} <strong>5 RUB.</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toBalance')}
                                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'momentalBonus')}
                                        </div>
                                        {!this.props.t.state.Promo.vk.account ? <div className={this.props.t.styles['noAccount']}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'vkDoesntLinked')}</span></div> : this.props.t.state.Promo.group.gained ? <div className={this.props.t.styles['gained']}><img src={Check}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'bonusGranted')}</span></div> : this.props.t.state.Promo.vk.group ? <button disabled={this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "group" ? true : false} onClick={() => { this.getBonus('group') }} className={this.props.t.styles['btn']}><span>{this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "group" ? this.props.t.Interface.getText(this.props.t, 'promo', 'checking') : this.props.t.Interface.getText(this.props.t, 'promo', 'get')}</span></button> : <a target="_blank" href={this.props.t.config.social.vk.link} className={this.props.t.styles['btn']}><span> {this.props.t.Interface.getText(this.props.t, 'promo', 'goToGroup')}</span></a>}
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['order-1'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['subscribePromoBlock'], !this.props.t.state.Promo.vk.account ? this.props.t.styles['noAccountBlock'] : this.props.t.state.Promo.subscribe.gained ? this.props.t.styles['gainedBlock'] : ''].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'allowNotificationsAndGet')} <strong>5 RUB.</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toBalance')}
                                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'sendAnyMessage')}
                                        </div>
                                        {!this.props.t.state.Promo.vk.account ? <div className={this.props.t.styles['noAccount']}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'vkDoesntLinked')}</span></div> : this.props.t.state.Promo.subscribe.gained ? <div className={this.props.t.styles['gained']}><img src={Check}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'bonusGranted')}</span></div> : this.props.t.state.Promo.vk.notifications ? <button disabled={this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "subscribe" ? true : false} onClick={() => { this.getBonus('subscribe') }} className={this.props.t.styles['btn']}><span>{this.props.t.state.Promo.captchaLoading && this.props.t.state.Promo.captchaModule === "subscribe" ? this.props.t.Interface.getText(this.props.t, 'promo', 'checking') : this.props.t.Interface.getText(this.props.t, 'promo', 'get')}</span></button> : <a target="_blank" href={this.props.t.config.social.vk.message} className={this.props.t.styles['btn']}><span> {this.props.t.Interface.getText(this.props.t, 'promo', 'writeMessage')}</span></a>}
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-lg-8'], this.props.t.bstyles['order-2'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['wheelPromoBlock']].join(' ')}>
                                        <div className={[this.props.t.bstyles['row'], this.props.t.bstyles['align-items-center']].join(' ')}>
                                            <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-7'], this.props.t.bstyles['d-flex'], this.props.t.bstyles['align-items-center'], this.props.t.bstyles['justify-content-center']].join(' ')}>
                                                <div className={this.props.t.styles['wheel']}>
                                                    <div className={this.props.t.styles['size']}></div>
                                                    <div className={this.props.t.styles['image']} style={{ transition: 'transform ' + this.props.t.state.Promo.wheel.transition + 's', transform: 'rotate(' + this.props.t.state.Promo.wheel.rotate + 'deg)' }}></div>
                                                    <button disabled={!this.props.t.state.Promo.wheel.available} onClick={() => { this.getBonus('wheel') }}><span> {this.props.t.Interface.getText(this.props.t, 'promo', 'roll')}</span></button>
                                                </div>
                                            </div>
                                            <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-5'], this.props.t.bstyles['mt-4'], this.props.t.bstyles['mt-sm-0']].join(' ')}>
                                                <div className={this.props.t.styles['title']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'rollAndGetBonus')}
                                                </div>
                                                <div className={this.props.t.styles['text']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'rollOncePer')} <strong>3 RUB.</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'toBalance')}
                                                </div>
                                                <div className={this.props.t.styles['timer']}>
                                                    <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'promo', 'untilNextTry')}:</div>
                                                    {this.props.t.state.Promo.wheel.timer &&
                                                        <div className={this.props.t.styles['countdown']}>
                                                            <span className={this.props.t.styles['digit']}>{this.props.t.state.Promo.wheel.timer.hours}</span> <span>:</span> <span className={this.props.t.styles['digit']}>{this.props.t.state.Promo.wheel.timer.minutes}</span> <span>:</span> <span className={this.props.t.styles['digit']}>{this.props.t.state.Promo.wheel.timer.seconds}</span>
                                                        </div>}
                                                </div>
                                                <div className={[this.props.t.styles['text'], this.props.t.styles['small']].join(' ')}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'rollEveryDay')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-lg-4'], this.props.t.bstyles['order-3'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['tgPromoBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'subscribeTg')}
                                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'beFirst')}
                                        </div>
                                        <a className={[this.props.t.styles['btn'], this.props.t.styles['btnGray']].join(' ')} target="_blank" href={this.props.t.config.social.tg.link}><span>Telegram</span></a>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-lg-6'], this.props.t.bstyles['order-5'], this.props.t.bstyles['order-lg-4'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['partnerPromoBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'referrals')}
                                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'promo', 'getPercent')}
                                        </div>
                                        <div className={this.props.t.styles['steps']}>
                                            <div className={this.props.t.styles['step']} data-step="1">
                                                <img alt="Link" src={Link}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'copyLink')}</span>
                                            </div>
                                            <div className={this.props.t.styles['step']} data-step="2">
                                                <img alt="Users" src={User}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'inviteFriend')}</span>
                                            </div>
                                            <div className={this.props.t.styles['step']} data-step="3">
                                                <img alt="Money" src={Money}></img> <span>{this.props.t.Interface.getText(this.props.t, 'promo', 'getBonus')}</span>
                                            </div>
                                        </div>
                                        <button className={this.props.t.styles['btn']} onClick={() => this.props.t.doRoute('/partner')}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'affiliateProgramm')}</span></button>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-lg-6'], this.props.t.bstyles['order-4'], this.props.t.bstyles['order-lg-5']].join(' ')}>
                                    <div className={this.props.t.bstyles['row']}>
                                        <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['order-0'], this.props.t.bstyles['col-lg-12'], this.props.t.styles['mb-grid']].join(' ')}>
                                            <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['reviewPromoBlock']].join(' ')}>
                                                <div className={this.props.t.styles['title']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'sendReview')} <strong>{this.props.t.Interface.getText(this.props.t, 'promo', 'bonusToDeposit')}</strong> {this.props.t.Interface.getText(this.props.t, 'promo', 'upTo')} 20%!
				                        </div>
                                                <div className={this.props.t.styles['text']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'makeReviews')}
                                                </div>
                                                <a className={[this.props.t.styles['btn'], this.props.t.styles['btnGray2']].join(' ')} target="_blank" href={this.props.t.config.social.vk.review}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'makeReview')}</span></a>
                                            </div>
                                        </div>
                                        <div className={[this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['order-1'], this.props.t.bstyles['col-lg-12'], this.props.t.styles['mb-grid']].join(' ')}>
                                            <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['loyaltyPromoBlock']].join(' ')}>
                                                <div className={this.props.t.styles['title']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'ranks')}
                                                </div>
                                                <div className={this.props.t.styles['text']}>
                                                    {this.props.t.Interface.getText(this.props.t, 'promo', 'earnFromWin')}
                                                </div>
                                                <button className={this.props.t.styles['btn']} onClick={() => this.props.t.doRoute('/loyalty')}><span>{this.props.t.Interface.getText(this.props.t, 'promo', 'loyaltyProgramm')}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['d-none'], this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['col-md-4'], this.props.t.bstyles['order-6'], this.props.t.bstyles['order-lg-5'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['achievementsPromoBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            Ежедневные задания
		                            </div>
                                        <div className={this.props.t.styles['text']}>
                                            Выполняй ежедневные задания и открывай Квест-кейс с дропом до <strong>1500 RUB.</strong>!
		                            </div>
                                        <a className={[this.props.t.styles['btn'], this.props.t.styles['btnGray2']].join(' ')} href="https://tgtg.su/joinchat/AAAAAFEu97sGdsnFi9fObA" target="_blank"><span>Перейти к заданиям</span></a>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['d-none'], this.props.t.bstyles['col-12'], this.props.t.bstyles['col-sm-6'], this.props.t.bstyles['col-md-4'], this.props.t.bstyles['order-7'], this.props.t.bstyles['order-lg-5'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['tournamentsPromoBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            Турниры
		                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            Принимай участие в турнирах, зарабатывай очки в играх из списка, поднимайся выше по рейтингу участников и выигрывай до <strong>100 000 RUB.</strong>!
		                        </div>
                                        <a className={[this.props.t.styles['btn'], this.props.t.styles['btnGray2']].join(' ')} href="https://tgtg.su/joinchat/AAAAAFEu97sGdsnFi9fObA" target="_blank"><span>Перейти к турнирам</span></a>
                                    </div>
                                </div>
                                <div className={[this.props.t.bstyles['d-none'], this.props.t.bstyles['col-12'], this.props.t.bstyles['col-md-4'], this.props.t.bstyles['order-8'], this.props.t.bstyles['order-lg-5'], this.props.t.styles['mb-grid']].join(' ')}>
                                    <div className={[this.props.t.styles['promoBlock'], this.props.t.styles['remainsPromoBlock']].join(' ')}>
                                        <div className={this.props.t.styles['title']}>
                                            Бонус на остаток
		                        </div>
                                        <div className={this.props.t.styles['text']}>
                                            Получай до 1% на остаток по счету каждый день в полночь! Чем больше баланс - тем выше процент.
		                        </div>
                                        <button className={[this.props.t.styles['btn'], this.props.t.styles['btnGray2']].join(' ')}><span>Подробнее</span></button>
                                    </div>
                                </div>
                            </>}
                    </div>
                    <div ref={div => (this.recaptchaLoader = div)}></div>
                </div>
            </>
        )
    }
}