module.exports = {
    system: { code: 'vi', name: 'Tiếng Việt' },
    aml: {
        title: 'Chống rửa tiền',
        description: 'Kiểm soát an toàn',
        content: ''
    },
    auth: {
        accountCreated: 'Tài khoản đã đăng ký',
        agreeText: 'Tôi xác nhận rằng tôi 18 tuổi và tôi đã đọc',
        agreeTos: 'các điều khoản dịch vụ',
        alreadyRegistered: 'Đã đăng ký chưa?',
        byLogin: 'Bằng cách đăng nhập và mật khẩu',
        bySocialNetworks: 'Qua mạng xã hội',
        createAccount: 'Tạo một tài khoản',
        forgotPassword: 'Quên mật khẩu?',
        loading: 'Đang tải...',
        login: 'Đăng nhập',
        loginTitle: 'Ủy quyền trang',
        maxLoginLength: 'Độ dài đăng nhập tối đa:',
        maxPasswordLength: 'Độ dài mật khẩu tối đa:',
        minLoginLength: 'Độ dài đăng nhập tối thiểu:',
        minPasswordLength: 'Độ dài mật khẩu Minmal:',
        passwordConfirmation: 'Lặp lại mật khẩu',
        passwordsMustBeSame: 'Mật khẩu phải khớp',
        pleaseWait: 'Vui lòng đợi...',
        registration: 'Đăng ký',
        registrationTitle: 'Đăng ký trên trang web',
        symbols: 'ký tự',
        yourLogin: 'Đăng nhập của bạn',
        yourPassword: 'Mật khẩu của bạn',
        yourEmail: 'Email của bạn',
        logoutConfirm: 'Anh có thực sự muốn ra ngoài không?',
        passwordRecovery: 'Đặt lại mật khẩu',
        newPassword: 'Mật khẩu mới',
        copied: 'Đã sao chép',
        logout: 'Ra'
    },
    basic: {
        yes: 'Dúng',
        no: 'Không',
        confirm: 'Xác nhận',
        confirmed: 'Lên bình chọn',
        cancel: 'Bãi bỏ',
        sending: 'Đang gửi...',
        sent: 'Gửi đi',
        save: 'Lưu',
        create: 'Tạo',
        or: 'hoặc là',
        activate: 'Kích hoạt',
        deactivate: 'Hủy kích hoạt',
        prevPage: 'Trang trước',
        nextPage: 'Trang tiếp theo',
        of: 'ngoài',
        balance: 'Cân',
        copy: 'Sao chép',
        copied: 'Đã sao chép'
    },
    bonus: {
        permanentBonus: 'Tiền thưởng vĩnh viễn',
        tenPercent: '10% cho mỗi khoản tiền gửi!',
        more: 'Đọc thêm',
        fastRegistration: 'Nhận phòng nhanh',
        noVerification: 'mà không cần xác minh',
        fastWithdraws: 'Payouts nhanh',
        highLimits: 'giới hạn cao',
        loyaltyProgramm: 'Chương trình Khách hàng thân thiết',
        winCashback: 'Lên đến 1.2% trên mỗi trận thắng!',
        levelSystem: 'Hệ thống cấp',
        bigPercents: 'Tỷ lệ phần trăm lớn',
        payForWin: 'Trả tiền cho chiến thắng',
        butNoBets: 'không cho cược',
        affiliateProgramm: 'Chương trình liên kết',
        join: 'Tham gia',
        revenueShare: '40% Chia sẻ doanh thu',
        anytimeWithdraw: 'đầu ra bất cứ lúc nào',
        affiliateCabinet: 'Tủ liên kết',
        detailStat: 'thống kê chi tiết',
        title: 'Mã khuyến mại',
        promoEveryDay: 'Mã khuyến mãi mỗi ngày trong nhóm VKontakte của chúng tôi',
        enterPromoCode: 'Nhập mã khuyến mãi'
    },
    fair: {
        title: 'Giấy phép & Bảo hành Trung thực',
        description: 'Chúng tôi đảm bảo chơi công bằng cho tất cả mọi người',
        fairDescription1: 'Brillx Casino hiểu tầm quan trọng của việc tạo ra một môi trường bảo mật để người dùng tự tin vào sự trung thực của trò chơi của chúng tôi.',
        fairDescription2: 'Do đó, trong sòng bạc của chúng tôi, chúng tôi sử dụng trò chơi được cung cấp bởi các công ty hàng đầu phát triển phần mềm cho sòng bạc trực tuyến. Trong số đó các công ty như',
        fairDescription3: 'Là một thành viên sáng lập của eCOGRA và IGC, Microgaming là một người đứng đầu trước các nhà sản xuất game casino khác, thiết lập các tiêu chuẩn cao nhất trong ngành công nghiệp cờ bạc nói chung, đặc biệt là đối với sự trung thực của game trực tuyến, lựa chọn các nhà khai thác có trách nhiệm nhất, và bảo vệ lợi ích của người chơi.',
        andOther: 'và những người khác',
        also: 'Chúng tôi cũng cung cấp các trò chơi được tạo ra bởi công ty',
        defaultCasino: 'Sòng bạc trực tuyến thông thường',
        brillxCasino: 'Brillx Trung thực kiểm soát Casino',
        defaultCasinoDescription1: 'Bạn thực hiện một đặt cược được ghi vào máy chủ casino',
        defaultCasinoDescription2: 'Dữ liệu của bạn được thu thập và lưu trữ trên các máy chủ casino',
        defaultCasinoDescription3: 'Kiểm tra sự trung thực của trò chơi là không thể - tất cả các thuật toán được ghi lại và lưu ở phía casino',
        defaultCasinoDescription4: 'Để có được một chiến thắng, bạn phải trải qua một thủ tục ủy quyền dài',
        defaultCasinoDescription5: 'Khả năng thanh toán của Casino trong câu hỏi như sự cân bằng của nó không được công bố bất cứ nơi nào',
        brillxCasinoDescription1: 'Một bản sao dữ liệu từ các nhà cung cấp cá cược người chơi được lưu trong blockchain và không thể thay đổi',
        brillxCasinoDescription2: 'Thống kê trò chơi cho con người được mã hóa và ghi lại trong blockchain không gắn với dữ liệu cá nhân của người chơi',
        brillxCasinoDescription3: 'Kiểm soát tính toàn vẹn được thực hiện với công nghệ blockchain',
        brillxCasinoDescription4: 'Rút tiền yêu cầu ủy quyền chuẩn KYC, không có phí chuyển khoản',
        brillxCasinoDescription5: 'Mở báo cáo và hồ bơi khổng lồ cho Payouts người chơi'
    },
    game: {
        loading: 'Đang tải',
        lobby: 'Sảnh',
        fullScreen: 'Toàn màn hình',
        deposit: 'Tiền gửi',
        betsAmount: 'Số tiền đặt cược',
        totalRTP: 'Tổng RTP',
        averageBet: 'Đặt cược trung bình',
        topWin: 'Chiến thắng hàng đầu'
    },
    help: {
        title: 'Trợ giúp & Hỗ trợ',
        description: 'Câu trả lời cho các câu hỏi phổ biến nhất và giao tiếp với dịch vụ khách hàng.',
        connectTypes: 'Phương pháp giao tiếp với chúng tôi',
        operatorsOnline: 'Các nhà khai thác trực tuyến 24 giờ một ngày',
        supportLive: 'Hỗ trợ (Live)',
        onlineChat: 'Chat trực tuyến',
        averageTimeChat: 'Thời gian đáp ứng trung bình: 10 phút',
        supportEmail: 'Dịch vụ khách hàng (E-mail)',
        averageTimeEmail: 'Thời gian phản hồi trung bình: 1 ngày',
        generalQuestions: 'Các vấn đề chung',
        aboutSite: 'Giới thiệu về trang web',
        aboutSiteContent: 'Brillx là một sòng bạc trực tuyến với giấy phép quốc tế và tên. Chúng tôi cung cấp hơn 6.000 trò chơi trong danh mục, một loạt các sự kiện thể thao, và rất nhiều mini-game. Chúng tôi hỗ trợ nhiều hệ thống thanh toán và hầu hết các loại tiền điện tử phổ biến.',
        bonus: 'Tiền thưởng',
        bonuses: 'Tiền thưởng',
        onPage: 'Trên trang',
        manyFreeBonuses: 'có một số cách để có được tiền cho số dư của bạn hoàn toàn miễn phí!',
        bonus1: 'Tiền thưởng đăng ký. Sau khi đăng ký trên trang web, bạn có 60 phút để nhập mã mời và nhận được lên đến 10.00 RUB.',
        bonus2: 'Tiền thưởng đăng ký. Sau khi đăng ký trên trang web, bạn có 60 phút để nhập mã mời và nhận được lên đến 10.00 RUB.',
        bonus3: 'Tiền thưởng đăng ký. Sau khi đăng ký trên trang web, bạn có 60 phút để nhập mã mời và nhận được lên đến 10.00 RUB.',
        bonus4: 'Tiền thưởng đăng ký. Sau khi đăng ký trên trang web, bạn có 60 phút để nhập mã mời và nhận được lên đến 10.00 RUB.',
        promocodes: 'Mã khuyến mãi',
        inviteFriend: 'Mời một người bạn',
        balanceRefilling: 'Bổ sung cân bằng',
        wallet: 'Ví',
        howToRefill: 'Làm thế nào để bổ sung số dư?',
        commissions: 'Uỷ ban',
        periodOfRefilling: 'Điều khoản đăng ký',
        withdrawal: 'Rút tiền',
        howToWithdraw: 'Làm thế nào để rút tiền?',
        periodOfWithdrawal: 'Thời gian rút tiền',
        clickToRefill: 'Nhấp vào nút để bổ sung số dư',
        atRightCorner: 'ở góc trên cùng bên phải của trang web. Chọn phương thức thanh toán thuận tiện cho bạn, nhập số tiền gửi và nhấp vào Chuyển đến Thanh toán.',
        minimalRefilling: 'Bổ sung tối thiểu',
        commissionsOnPage: 'Hoa hồng của tất cả các phương thức thanh toán được liệt kê trên trang',
        clickToWithdraw: 'Nhấp vào nút để rút tiền từ tài khoản của bạn',
        averageTimeWithdraw: 'Tất cả các phát hiện được xử lý trong trung bình một giờ kể từ thời điểm nộp đơn.',
        allTransactions: 'Tất cả các giao dịch được xử lý ngay lập tức. Sự chậm trễ chỉ có thể xảy ra nếu bạn gặp vấn đề với phương thức thanh toán bạn đang bổ sung số dư của mình.',
        inCaseOfProblems: 'Nếu có vấn đề, xin vui lòng liên hệ với dịch vụ khách hàng của chúng tôi và cung cấp thông tin chi tiết nhất về vấn đề của bạn.',
        atRightCornerWithdraw: 'ở góc trên cùng bên phải của trang web. Chọn phương thức rút tiền thuận tiện cho bạn, nhập số tiền và nhấp vào Chuyển đến Thanh toán.',
        minimalWithdrawal: 'Rút tiền tối thiểu',
        ifBigAmount: 'Nếu số tiền rút lớn, việc rút tiền có thể kéo dài hơn một chút, thường lên đến một giờ. Vào ban đêm (00:00 sáng đến 09:00 MSC), những phát hiện có thể diễn ra trong vài giờ, vì lúc đó tập hợp thanh toán xử lý yêu cầu rút tiền lâu hơn.',
        sometimes: 'Trong trường hợp rất hiếm, rút tiền có thể lên đến 24 giờ, do tải ngân hàng và như vậy. Nếu tiền không đến sau thời gian đó, xin vui lòng liên hệ với dịch vụ khách hàng của chúng tôi và cung cấp thông tin chi tiết nhất về vấn đề của bạn.',
        checkCorrectPurse: 'Cũng đảm bảo rằng bạn chỉ định các chi tiết cho đầu ra một cách chính xác. Để trấn an ví bạn đã chỉ vào việc rút tiền, bạn cần phải đi vào lịch sử giao dịch của bạn và mở chi tiết bằng cách nhấp vào giao dịch bạn muốn.'
    },
    interface: {
        months: {
            jan: 'tháng Một',
            feb: 'tháng 2.',
            mar: 'bước đều',
            apr: 'tháng tư',
            may: 'tháng năm',
            jun: 'tháng Sáu',
            jul: 'tháng Bảy.',
            aug: 'tháng Tám',
            sep: 'tháng Chín.',
            oct: 'tháng Mười',
            nov: 'tháng mười một.',
            dec: 'tháng 12.'
        },
        year: 'n.',
        confirmPasswordRecovery: 'Xác nhận đặt lại mật khẩu bằng cách theo liên kết được gửi đến email của bạn',
        passwordWasChanged: 'Mật khẩu đã thay đổi thành công, bạn có thể đăng nhập để sử dụng dữ liệu mới',
        confirmEmail: 'Xác nhận địa chỉ email bằng liên kết',
        emailWasConfirmed: 'Địa chỉ email đã được xác nhận thành công',
        accountLinked: 'Tài khoản đã được liên kết thành công',
        bonusGranted: 'Tiền thưởng đã nhận'
    },
    landing: {
        newGames: 'Trò chơi mới',
        topGames: 'Trò chơi phổ biến',
        brillxSelect: 'Sự lựa chọn của Brillx',
        daylyLiveRtp: 'Trực tiếp RTP - 24 giờ',
        brillxCasino: 'Brillx sòng bạc',
        casinoInfo1: 'Kiểm tra một loạt các trò chơi thú vị ở đây tại Brillx.GG, một trang web máy đánh bạc được thiết kế riêng cho sự hài lòng của bạn. Hàng ngàn máy khe hạng nhất có sẵn cho người chơi trên toàn thế giới. Cho dù đó là giải đặc biệt lớn, khe poker hoặc khe máy theo chủ đề bạn cần, chúng tôi có mọi thứ bạn cần!',
        casinoInfo2: 'Trải nghiệm sự dễ dàng của một check-in nhanh chóng bằng cách nhấp vào nút check-in ngay bây giờ! Thưởng thức những khoảnh khắc khi bạn chơi máy khe tiền thật từ các nhà cung cấp trò chơi hàng đầu.',
        casinoInfo3: 'Casino của chúng tôi với Payouts nhanh và một lựa chọn rất lớn của tất cả các loại biến thể máy đánh bạc hoạt động 24/7 cho bạn - chỉ cần chọn một trò chơi và bắt đầu chơi!',
        casinoInfo4: 'Chơi khe cắm trực tuyến',
        casinoInfo5: 'Chúng tôi không bao giờ làm hỏng niềm vui của bạn, vì vậy bạn có thể chơi tất cả các trò chơi của chúng tôi mà không cần tải bất cứ điều gì trên máy tính',
        casinoInfo6: 'Duyệt hàng ngàn khe, chọn trò chơi bạn thích và nhấp vào “CHƠI” - thật dễ dàng như vậy!',
        casinoInfo7: 'Trò chơi khe casino là một trong những trò chơi phổ biến nhất. Không chỉ vì những ưu đãi lớn mà bạn có thể nhận được, mà còn vì đồ họa thú vị, âm nhạc tốt, và hiệu ứng hình ảnh thú vị tổng thể của trò chơi.',
        casinoInfo8: 'Bạn thậm chí có thể thử mỗi máy khe của chúng tôi bằng cách chuyển sang chế độ giải trí - một tính năng tuyệt vời cho phép bạn chơi tất cả các máy khe của chúng tôi mà không cần tải về và không có Đăng ký là miễn phí! Bạn có thể truy cập nó thông qua điện thoại di động, máy tính hoặc máy tính bảng của bạn. Khám phá một số các khe máy tốt nhất hiện nay mà bạn có thể chơi miễn phí mà không cần phải tải về, và trải nghiệm một cuộc phiêu lưu độc đáo trong các khe casino tốt nhất trên toàn thế giới. Hãy bắn tốt nhất của bạn ngay hôm nay, và để cho số phận quyết định những gì đang chờ bạn!',
        casinoInfo9: 'Anh còn chờ gì nữa? Hôm nay có thể là ngày may mắn của bạn!',
        casinoInfo10: 'Hàng ngàn máy đánh bạc',
        casinoInfo11: 'Dưới đây là các loại khác nhau của khe máy bạn có thể tìm thấy trong sòng bạc của chúng tôi:',
        casinoInfo12: '3 khe reel',
        casinoInfo13: '3 khe reel được biết đến nhiều nhất là máy khe cắm cổ điển hoặc truyền thống. Reels là những đường thẳng đứng bạn có thể tìm thấy trong trò chơi nơi các biểu tượng được xếp tầng. Nếu bạn đang tìm kiếm một cảm giác hồi hộp, hãy thử chơi một máy đánh bạc 3 reel! Một số trò chơi bạn có thể chơi là Book of Alchemy, Watford FC Khe cắm và nhiều người khác.',
        casinoInfo14: '5 khe reel',
        casinoInfo15: "Có được một cơ hội tốt hơn để chiến thắng kết hợp từ chơi một khe cắm 5 reel! Hầu hết 5 khe reel được trả mỗi lần 3, 4 hoặc 5 ký tự rơi trên cuộn. Bạn cũng có thể nhận được nhân chiến thắng, vòng trò chơi tiền thưởng, hoặc quay miễn phí tùy thuộc vào trò chơi. Một số trong 5 trò chơi reel bạn có thể chơi là Vikings Go Berzerk, Legacy of Dead, và Than's Rich.",
        casinoInfo16: 'Khe Progressive',
        casinoInfo17: 'Khe Progressive là một mưu mẹo trong tất cả các loại máy đánh bạc. Kích thước jackpot tăng khi trò chơi tiến triển. Có hàng triệu người đang chờ đợi bạn, và bạn có thể trở thành một trong những người giàu nhất cho đến nay!',
        casinoInfo18: 'Bàn đánh bạc',
        casinoInfo19: 'Roulette là một trò chơi mà cược vào số cá nhân trên một bảng, màu sắc, loại số, hoặc kết hợp. Kiểm tra vận may của bạn và giành chiến thắng nếu những gì bạn đặt cược vào lượt truy cập bánh xe. Chơi trò chơi roulette thời gian thực tuyệt vời của chúng tôi ngay bây giờ!',
        casinoInfo20: 'Xì phé',
        casinoInfo21: 'Nếu bạn đã quen thuộc với poker trong một sòng bạc thông thường, bạn có thể thử chơi nó ngay bây giờ bằng cách tận dụng nhiều lựa chọn của chúng tôi về trò chơi poker video trực tuyến. Hãy sẵn sàng để trải nghiệm một trò chơi không thể nào quên ngày hôm nay! Với hoạt hình độ nét cao và vòng quay video đặc biệt tuyệt vời, bạn chắc chắn sẽ trở lại cho một chiến thắng mới.',
        casinoInfo22: 'Baccarat',
        casinoInfo23: 'Baccarat là một trong những trò chơi phổ biến nhất trên thế giới. Đánh bại các đại lý bằng cách đặt cược vào tay gần nhất với 9. Ngoài ra, bạn cũng có thể giành chiến thắng nếu có hòa. Nghe có vẻ thú vị, thật không? Hãy đặt cược của bạn sẵn sàng ngay bây giờ và đặt cược vào bên chiến thắng!',
        casinoInfo24: 'Xì dách',
        casinoInfo25: 'Trong blackjack, bạn có thể đánh bại các đại lý nếu bạn có tay gần nhất để 21. Có cơ hội tăng gấp đôi đặt cược của bạn khi bạn chơi máy khe blackjack hùng vĩ của chúng tôi!',
        gamesProviders: 'Nhà cung cấp trò chơi',
        showMore: 'Xem thêm',
        hideInfo: 'Ẩn thông tin',
        allGames: 'Tất cả các trò chơi',
        providers: 'Nhà cung cấp',
        new: 'Mới',
        tournaments: 'Bonus Buy',
        slots: 'Slots',
        roulette: 'Bàn đánh bạc',
        card: 'Thẻ',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Chơi', title: 'Live' },
    lobby: {
        providers: 'Nhà cung cấp',
        recent: 'Gần đây',
        top: 'Phổ biến',
        new: 'Mới',
        favorite: 'Mục yêu thích',
        nothingFound: 'Không tìm thấy gì',
        play: 'CHƠI',
        demo: 'BẢN GIỚI THIỆU',
        forExample: 'Ví dụ'
    },
    log: { date: 'Ngày', type: 'Hoạt động', amount: 'Số tiền', id: 'ID' },
    loyalty: {
        title: 'Chương trình Khách hàng thân thiết',
        description: 'Nhận hoàn tiền phụ thuộc vào mức độ trung thành trên mỗi cược đặt',
        cabinet: 'Tủ',
        myCabinet: 'Văn phòng của tôi',
        payLog: 'Bản ghi dồn tích',
        log: 'Logue',
        level: 'Tầng',
        totalWins: 'Số tiền trúng thưởng',
        totalBets: 'Số tiền cược',
        availableBalance: 'Số dư có sẵn',
        get: 'Lấy đi',
        cashback: 'Hoàn tiền',
        emptyHistory: 'Câu chuyện bị thiếu',
        operationID: 'ID hoạt động',
        amount: 'Số tiền',
        date: 'Ngày',
        operationDate: 'Ngày hoạt động',
        operationType: 'Hoạt động',
        balanceBefore: 'Cân bằng lên',
        balanceAfter: 'Số dư sau'
    },
    menu: {
        affProgramm: 'Đối tác',
        fairGame: 'Chơi công bằng',
        free: 'Miễn phí',
        games: 'Trò chơi',
        lobby: 'Sảnh',
        promo: 'Quảng cáo',
        loyaltyProgramm: 'Lòng trung thành',
        promotions: 'Cổ phiếu',
        support: 'Bộ phận trợ giúp',
        settings: 'Thiết đặt',
        history: 'Lịch sử trò chơi',
        more: 'Thêm',
        profile: 'Profil',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Lỗi', success: 'Thành công' },
    profile: {
        wallet: 'Ví',
        onSiteFrom: 'Trên trang web với',
        totalBets: 'Số tiền cược',
        betsCount: 'Cược',
        totalWins: 'Số tiền trúng thưởng',
        bestWin: 'Thắng tốt nhất',
        emptyHistory: 'Câu chuyện bị thiếu',
        balanceAfter: 'Số dư sau'
    },
    partner: {
        maxCodeLength: 'Độ dài tối đa mã:',
        minCodeLength: 'Độ dài mã tối thiểu:',
        maxNameLength: 'Độ dài tên tối đa:',
        minNameLength: 'Độ dài tối thiểu của tên:',
        symbols: 'ký tự',
        confirmDeleteCampgain: 'Bạn có thực sự muốn loại bỏ chiến dịch?',
        campgains: 'Chiến dịch',
        refresh: 'Nâng cấp',
        export: 'Xuất',
        campgainName: 'Tên chiến dịch',
        affiliateLink: 'Liên kết liên kết',
        name: 'Tiêu đề',
        creationDate: 'Ngày tạo',
        clicks: 'Chuyển tiếp',
        link: 'Trọng tài',
        manage: 'Quản lý',
        noCampgains: 'Không có chiến dịch quảng cáo hoạt động',
        namePlaceholder: 'Tên để hiển thị trong bảng điều khiển',
        general: 'Chung',
        mainAffiliateLink: 'Liên kết liên kết chính',
        registrations: 'Đăng ký',
        totalProfit: 'Lợi nhuận chung',
        availableBalance: 'Số dư có sẵn',
        get: 'Lấy đi',
        emptyHistory: 'Câu chuyện bị thiếu',
        operationID: 'ID hoạt động',
        amount: 'Số tiền',
        date: 'Ngày',
        operationDate: 'Ngày hoạt động',
        operationType: 'Hoạt động',
        playerID: 'ID người chơi',
        balanceAfter: 'Số dư sau',
        balanceBefore: 'Cân bằng lên',
        log: 'Bản ghi dồn tích',
        players: 'Người chơi',
        registration: 'Đăng ký',
        registrationDate: 'Ngày đăng ký',
        balance: 'Cân',
        noPlayers: 'Không có người chơi khách',
        totalBets: 'Số tiền cược',
        totalDeps: 'Số tiền nạp',
        totalWithdraws: 'Tổng kết quả',
        registrationSource: 'Nguồn đăng ký',
        mainLink: 'Liên kết chính',
        player: 'Người chơi',
        registrationsCount: 'Đăng ký',
        bets: 'Buộc vào cọc',
        profit: 'Lợi nhuận',
        stat: 'Thống kê',
        totalStat: 'Thống kê tổng thể',
        partnerCabinet: 'Văn phòng đối tác',
        partnerID: 'ID đối tác'
    },
    privacy: {
        title: 'Chính sách bảo mật',
        description: 'Quy định và Thông tin chung',
        redaction: 'Biên tập bởi',
        content: ''
    },
    promo: {
        linkVk: 'Liên kết tài khoản VK để nhận tiền thưởng',
        completeAll: 'Phù hợp với tất cả các điều kiện và nhận được',
        toBalanceForRepost: 'trên cân bằng cho repost!',
        link: 'Ràng buộc',
        join: 'Mục nhập',
        allowNotifications: 'Cho phép thông báo',
        make: 'Làm',
        toProfileVkAccount: 'vào hồ sơ tài khoản VK.',
        toOurVkGroup: 'cho nhóm VK của chúng tôi.',
        fromOurGroup: 'từ cộng đồng của chúng tôi.',
        repostToYourPage: 'repost các mục nhập vào trang của mình.',
        mustBePublic: 'Trang VK của bạn phải công khai hoặc chúng tôi sẽ không thể kiểm tra đăng lại.',
        dontDelete: 'Nếu bạn xóa một bài đăng sớm hơn 2 tuần sau khi đăng lại, số tiền thưởng sẽ được khấu trừ từ số dư.',
        joinAndGet: 'Tham gia một nhóm và nhận',
        toBalance: 'trên sự cân bằng!',
        bonusGranted: 'Tiền thưởng đã nhận được thành công!',
        momentalBonus: 'Tham gia nhóm VK của chúng tôi và nhận được một khoản tiền thưởng ngay lập tức trên số dư của bạn! Hồ sơ VK phải công khai hoặc chúng tôi sẽ không thể kiểm tra đăng ký.',
        vkDoesntLinked: 'Tài khoản VK không bị ràng buộc',
        allowNotificationsAndGet: 'Cho phép thông báo từ cộng đồng của chúng tôi và nhận',
        sendAnyMessage: 'Gửi bất kỳ tin nhắn nào đến nhóm VK của chúng tôi, cho phép gửi thông báo. Một bất ngờ tốt đẹp - định kỳ chúng tôi sẽ gửi thông tin về chương trình khuyến mãi và tiền thưởng khác nhau!',
        rollAndGetBonus: 'Cruise roulette của bạn và nhận được tiền thưởng!',
        rollOncePer: 'Xoay roulette mỗi 15 phút một lần và nhận được cho đến khi',
        rollEveryDay: 'Quay bánh xe mà không có hạn chế mỗi ngày!',
        untilNextTry: 'Trước khi nỗ lực tiếp theo còn lại',
        subscribeTg: 'Đăng ký kênh điện tín của chúng tôi để nhận mã khuyến mãi mới đầu tiên!',
        beFirst: 'Hãy là một trong những người đầu tiên nhận được một mã khuyến mãi mới, tìm hiểu về tiền thưởng và đổi mới mới!',
        referrals: 'Giới thiệu',
        getPercent: 'Mời bạn bè và kiếm được 40% lợi ích casino!',
        copyLink: 'Sao chép liên kết',
        inviteFriend: 'Mời một người bạn',
        getBonus: 'Nhận tiền thưởng',
        affiliateProgramm: 'Chương trình liên kết',
        sendReview: 'Feed phản hồi về dự án trong nhóm của chúng tôi và nhận',
        bonusToDeposit: 'tiền thưởng nạp tiền',
        upTo: 'lên đến',
        makeReviews: 'Để lại phản hồi trong nhóm VK của chúng tôi, sau khi người điều hành đã được xác minh và đăng, tiền thưởng sẽ được tự động kích hoạt trên tài khoản của bạn.',
        makeReview: 'Phản hồi',
        ranks: 'Rangi',
        earnFromWin: 'Kiếm được cấp bậc bạn sẽ nhận được tiền hoàn lại tăng từ mỗi chiến thắng.',
        loyaltyProgramm: 'Chương trình Khách hàng thân thiết',
        writeMessage: 'Viết một tin nhắn',
        goToGroup: 'Đi tới nhóm',
        refreshing: 'Cập nhật...',
        refreshStatus: 'Nâng cấp',
        roll: 'Xoay',
        linkAccount: 'Liên kết một tài khoản',
        checking: 'Đang kiểm tra...',
        get: 'Nhận'
    },
    promotions: {
        title: 'Cổ phiếu & Ưu đãi',
        description: 'Đừng bỏ lỡ cơ hội nhận được tiền thưởng trong cổ phiếu hiện tại của chúng tôi',
        game: 'Trò chơi',
        minDeposit: 'Tiền đặt cọc tối thiểu',
        bonus: 'Tiền thưởng',
        addWager: 'Cược thêm',
        validUntil: 'Hành động lên đến',
        activation: 'Kích hoạt',
        get: 'Nhận',
        unlimit: 'vô hạn',
        oneTime: 'một lần',
        forEveryDeposit: 'cho mỗi khoản tiền gửi',
        permanentBonus: 'Tiền thưởng vĩnh viễn',
        any: 'không tí nào'
    },
    responsibility: {
        title: 'Trò chơi có trách nhiệm',
        description: 'Mẹo trò chơi có trách nhiệm và quy tắc',
        content: ''
    },
    settings: {
        incorrectEmail: 'Địa chỉ email không chính xác',
        bigFileSize: 'Kích thước tệp không được vượt quá 5 MB.',
        genders: { man: 'Nam', female: 'Nữ', other: 'Khác' },
        requiredField: 'Trường bắt buộc',
        dataSuccessChanged: 'Dữ liệu đã được cập nhật thành công',
        tfaLoginRequired: 'Đặt đăng nhập và mật khẩu để thiết lập trình xác thực',
        general: 'Chung',
        login: 'Đăng nhập',
        vkProfile: 'Hồ sơ VK',
        glProfile: 'Hồ sơ Google',
        okProfile: 'Bạn cùng lớp hồ sơ',
        fbProfile: 'Hồ sơ Facebook',
        security: 'An toàn',
        alterLogin: 'Thêm một phương pháp đăng nhập và mật khẩu thay thế vào tài khoản của bạn.',
        newPassword: 'Mật khẩu mới',
        repeatPassword: 'Lặp lại mật khẩu',
        saveActions: 'Lưu hành động',
        currentPassword: 'Mật khẩu hiện tại',
        tfa: 'Xác thực hai yếu tố',
        addTfa: 'Bảo vệ tài khoản Brillx của bạn với bảo mật bổ sung',
        tfaSettings: 'Cài đặt 2FA',
        sessions: 'Các phiên',
        device: 'Thiết bị',
        region: 'Vùng',
        ipAddress: 'Địa chỉ IP',
        date: 'Ngày',
        session: 'Phiên',
        active: 'Đang hoạt động',
        closed: 'Đã đóng',
        verification: 'Xác minh',
        verificationDescription1: ' Để cải thiện mức độ dịch vụ của bạn, chúng tôi yêu cầu xác định tài liệu. Điều này sẽ giữ an toàn cho tài khoản của bạn nếu tài khoản đó được khôi phục và sẽ cung cấp các món quà vật lý được nhắm mục tiêu chính xác.',
        verificationDescription2: 'Để có thể chấp nhận được cách xác minh là cung cấp một bức ảnh của ID, bằng lái xe hoặc hộ chiếu của bạn. Sau khi hoàn thành quy trình này, chúng tôi sẽ thông báo cho bạn qua email.',
        frontPhoto: 'Ảnh mặt trước của giấy tờ tùy thân',
        selectFile: 'Chọn tập tin',
        backPhoto: 'Ảnh sau của giấy tờ tùy thân',
        fileIsNotSelected: 'Tệp không được chọn',
        name: 'Tên',
        surname: 'Họ',
        birthday: 'Ngày sinh',
        gender: 'Giới tính',
        country: 'Quốc gia',
        city: 'Thành phố',
        yourName: 'Tên của bạn',
        yourSurname: 'Họ của bạn',
        verifyStatus1: 'Tài liệu đang được kiểm tra...',
        verifyStatus2: 'Đã xác minh tài khoản',
        verifyStatus3: 'Lỗi kiểm tra tài liệu',
        verifyDescription1: 'Tài liệu hiện đang được xem xét, ngay sau khi các tài liệu được xác minh, tài khoản của bạn sẽ được xác minh!',
        verifyDescription2: 'Dữ liệu của bạn đã được xác nhận và xác nhận bởi nhân viên an ninh.',
        verifyDescription3: 'Vui lòng liên hệ bộ phận hỗ trợ kỹ thuật để biết chi tiết.',
        registrationCity: 'Thành phố đăng ký',
        sendDocs: 'Nộp hồ sơ kiểm tra',
        affiliateCabinet: 'Văn phòng đối tác'
    },
    tos: {
        title: 'Thỏa thuận người dùng',
        description: 'Điều khoản và Quy tắc Sử dụng',
        redaction: 'Biên tập bởi',
        content: ''
    },
    wallet: {
        deposit: 'Tiền gửi',
        withdraw: 'Đầu ra',
        history: 'Lịch sử',
        minDepositAmount: 'Tiền gửi tối thiểu qua',
        minWithdrawAmount: 'Đầu ra tối thiểu qua',
        topMethods: 'Phổ biến',
        allMethods: 'Tất cả các phương pháp',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Khác',
        success: 'Thành công',
        canceled: 'Hủy',
        error: 'Lỗi',
        waiting: 'Đang chờ',
        processing: 'Ứng dụng đang được xử lý',
        incorrectWalletValue: 'Số ví được nhập không chính xác',
        incorrectCardValue: 'Số thẻ được nhập sai',
        selectTemplate: 'Chọn mẫu',
        withdrawCreated: 'Đơn thanh toán được tạo ra và sẽ được xử lý trong vòng 24 giờ',
        withdrawCanceled: 'Giá thầu thanh toán đã bị hủy',
        firstWithdraw: 'Đây là kết luận đầu tiên của bạn',
        isDataCorrect: 'Bạn có tự tin rằng nhập dữ liệu là chính xác?',
        payMethod: 'Phương thức thanh toán',
        withdrawMethod: 'Phương thức rút tiền',
        selectMethod: 'Chọn một cách',
        selectOperator: 'Chọn toán tử',
        refillAmount: 'Bổ sung',
        withdrawAmount: 'Số tiền rút tiền',
        payTroubles: 'Nếu bạn gặp khó khăn khi trả tiền thử',
        payHere: 'Thanh toán tại đây',
        pay: 'Chuyển đến thanh toán',
        commission: 'Uỷ ban',
        onePayLimit: 'Giới hạn Bổ sung đơn',
        oneWithdrawLimit: 'Giới hạn đầu ra đơn',
        emptyHistory: 'Câu chuyện bị thiếu',
        operationID: 'ID hoạt động',
        deposits: 'Bổ sung',
        withdraws: 'Những phát hiện',
        txAmount: 'Số tiền giao dịch',
        txDate: 'Ngày hoạt động',
        purse: 'Ghi bàn',
        typePurse: 'Số tài khoản',
        toGet: 'Để nhận',
        createWithdraw: 'Tạo một ứng dụng'
    },
    tfa: {
        tfa: 'Xác thực hai yếu tố',
        typeYourPassword: 'Nhập Mật khẩu của bạn',
        enterPin: 'Nhập mã 6 chữ số từ ứng dụng',
        downloadApp: 'Tải xuống ứng dụng',
        downloadAndInstall: 'Tải xuống và cài đặt',
        forYourPhone: 'dành cho điện thoại hoặc máy tính bảng',
        scanQR: 'quét mã QR',
        scanQRWithCamera: 'Quét QR ở bên trái bằng camera của điện thoại',
        openWithLink: 'Mở bằng liên kết',
        openInApp: 'Mở trong ứng dụng',
        tfaManualEnter: 'Phím 2FA (nhập thủ công)'
    },
    pwa: {
        androidStep1: 'Mở trình duyệt Chrome',
        androidStep1Description: 'Nếu bạn đang sử dụng bất kỳ trình duyệt nào khác, hãy mở trang này trong Chrome',
        androidStep2: 'Nhấp vào menu trình duyệt',
        androidStep2Description: 'Menu trình duyệt là dấu chấm lửng ở góc trên bên phải trình duyệt của bạn',
        androidStep3: 'Chọn "Thêm vào Màn hình chính"',
        androidStep3Description: 'Bạn sẽ thấy một cửa sổ có thông tin ("Thêm vào Màn hình chính"), trong đó bạn phải chọn "Thêm", sau đó ứng dụng sẽ được thêm vào màn hình của thiết bị của bạn',
        androidAlert: 'Các bước có thể khác nhau tùy thuộc vào phiên bản trình duyệt',
        iosStep1: 'Mở trình duyệt Safari',
        iosStep1Description: 'Nếu bạn đang sử dụng bất kỳ trình duyệt nào khác, hãy mở trang này trong Safari',
        iosStep2: 'Nhấp vào "Chia sẻ"',
        iosStep2Description: 'Nhấp vào nút "Chia sẻ" ở cuối màn hình, sau đó cửa sổ lựa chọn tham số sẽ mở ra',
        iosStep3: 'Chọn "Thêm vào Màn hình chính"',
        iosStep3Description: 'Chọn "Thêm vào Màn hình chính" và nhấp vào "Thêm"',
        alert: 'Các bước có thể khác nhau tùy thuộc vào phiên bản trình duyệt',
        installApp: 'Cài đặt ứng dụng Brillx',
        back: 'Lại',
        app: 'Ứng dụng di động',
        installAppForMobile: 'Cài đặt ứng dụng Brillx tiện lợi mới cho iOS và Android',
        install: 'Cài đặt'
    },
    undefined: '???'
};