import React from 'react';

export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.searchTimeout = false;
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.search, this.props.t.state.lang], [this.pState.Lobby.search, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    componentWillUnmount() {
        clearTimeout(this.searchTimeout);
    }

    handleSearch(event) {
        if (event.key === "Enter") {
            clearTimeout(this.searchTimeout);
            this.props.build(true);
        }
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['searchBlock']}>
                    <input onKeyPress={(event) => { this.handleSearch(event) }} className={this.props.t.styles['inputField']} placeholder={this.props.t.Interface.getText(this.props.t, 'lobby', 'forExample') + ': Book Of Ra'} value={this.props.t.state.Lobby.search} onChange={a => { this.props.t.updateState(this.props.t, 'Lobby', a.target.value, 'search'); clearTimeout(this.searchTimeout); this.searchTimeout = setTimeout(() => { this.props.build(true) }, 500); }}></input>
                </div>
            </>
        )
    }

}