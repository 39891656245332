import React from 'react';

import FairMedium from '../assets/jpg/Fair/fairMedium.jpg';
import FairBig from '../assets/jpg/Fair/fairBig.jpg';
import FairMin1 from '../assets/jpg/Fair/fairMin1.jpg';
import FairMin2 from '../assets/jpg/Fair/fairMin1.jpg'

export default class Fair extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'fairGame') + ' - ' + this.props.t.config.name;
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width], [this.pState.width]);
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['fairContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'fair', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'description')}</div></div>
                        <div className={this.props.t.styles['fairBlock']}>
                            <p>{this.props.t.Interface.getText(this.props.t, 'fair', 'fairDescription1')}</p>

                            <p>{this.props.t.Interface.getText(this.props.t, 'fair', 'fairDescription2')}: <a href="#" onClick={() => this.props.t.doRoute('/lobby/1/0')}>NetEnt</a>, <a href="#" onClick={() => this.props.t.doRoute('/lobby/3/0')}>Yggdrasil</a>, <a href="#" onClick={() => this.props.t.doRoute('/lobby/13/0')}>Play'n GO</a>, <a href="#" onClick={() => this.props.t.doRoute('/lobby/12/0')}>Playtech</a> {this.props.t.Interface.getText(this.props.t, 'fair', 'andOther')}. </p>
                            <p>{this.props.t.Interface.getText(this.props.t, 'fair', 'also')} <a href="#" onClick={() => this.props.t.doRoute('/lobby/12/0')}>Microgaming</a>. {this.props.t.Interface.getText(this.props.t, 'fair', 'fairDescription3')}.</p>

                            <div className={this.props.t.styles['fairCheckBlock']} style={{ backgroundImage: this.props.t.state.width >= 1100 ? `url(${FairBig})` : this.props.t.state.width > 520 ? `url(${FairMedium})` : this.props.t.state.Fair.fairBlockModule ? `url(${FairMin2})` : `url(${FairMin1})`, backgroundSize: 'cover', backgroundPosition: 'center 0%' }}>
                                <div className={this.props.t.styles['col']}>
                                    <div className={this.props.t.styles['title']}>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasino')}
                                        </div>
                                    </div>
                                    <button onClick={() => { this.props.t.updateState(this.props.t, 'Fair', false, 'fairBlockModule') }} className={[this.props.t.styles['btnTitle'], !this.props.t.state.Fair.fairBlockModule ? this.props.t.styles['active'] : ''].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasino')}</button>
                                    <ul className={[this.props.t.styles['list'], !this.props.t.state.Fair.fairBlockModule ? this.props.t.styles['active'] : ''].join(' ')}>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasinoDescription1')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasinoDescription2')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasinoDescription3')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasinoDescription4')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'defaultCasinoDescription5')}</li>
                                    </ul>
                                </div>
                                <div className={this.props.t.styles['col']}>
                                    <div className={this.props.t.styles['title']}>
                                        <div className={this.props.t.styles['text']}>
                                            {this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasino')}
                                        </div>
                                    </div>
                                    <button onClick={() => { this.props.t.updateState(this.props.t, 'Fair', true, 'fairBlockModule') }} className={[this.props.t.styles['btnTitle'], this.props.t.state.Fair.fairBlockModule ? this.props.t.styles['active'] : ''].join(' ')} type="button">Brillx</button>
                                    <ul className={[this.props.t.styles['list'], this.props.t.state.Fair.fairBlockModule ? this.props.t.styles['active'] : ''].join(' ')}>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasinoDescription1')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasinoDescription2')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasinoDescription3')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasinoDescription4')}</li>
                                        <li className={this.props.t.styles['item']}>{this.props.t.Interface.getText(this.props.t, 'fair', 'brillxCasinoDescription5')}</li>
                                    </ul>
                                </div>
                            </div>

                            <p className={this.props.t.styles['license']}>This site’s operations are regulated by the Gambling Commission and is operated by ElsiKora Holding Ltd, Suite 1, Second Floor, Sound & Vision House, Francis Rachel Str., Victoria Mahe, Seychelles, under the gaming license issued by the Gambling Comission (license number CUK/16289) issued on 1 December 2020.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}