import React from 'react';
import { Tooltip } from 'reactstrap';

import 'bootstrap';
import '../../styles/bootstrap/scss/bootstrap.module.scss';

class PasswordRecoverySet extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        recaptcha: {
            ready: false,
            token: false
        }
    };

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        window.captchaOnLoad = this.captchaOnLoad.bind(this);

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";
        this.recaptchaLoader.appendChild(script);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "inputs", false, "auth", "tfaPin");
        this.props.t.updateState(this.props.t, "authTempKey", false);
        this.props.t.updateState(this.props.t, 'mainRedirect', true);
        document.getElementById('g-recaptcha').parentNode.removeChild(document.getElementById('g-recaptcha'));
    }

    shouldComponentUpdate() {
        console.log(this.props.t.state);
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.recovery, this.props.t.state.form.recovery, this.props.t.state.lang], [this.pState.recovery, this.pState.form.recovery, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    captchaOnLoad() {
        const widget = document.createElement('div');
        widget.id = 'g-recaptcha';
        widget.style.visibility = 'hidden';
        document.body.appendChild(widget);

        window.grecaptcha.render('g-recaptcha', {
            sitekey: '6Lfg4PAUAAAAABijupZzyCKqOoZ4JyHtQLXq_oa_',
            size: 'invisible'
        });

        window.grecaptcha.ready(() => {
            this.setState({ recaptcha: { token: this.state.recaptcha.token, ready: true } });
            this.getCaptcha(this);
            setTimeout(this.getCaptcha, 120000, this);
        })
    }

    getCaptcha(t) {
        if (t.state.recaptcha.ready)
            window.grecaptcha.execute({ action: 'user/auth/recovery' }).then(token => { t.setState({ recaptcha: { token, ready: t.state.recaptcha.ready } }); });
    }

    render() {
        return (
            <div className={this.props.t.styles['recoveryContainer']}>
                <div className={this.props.t.styles['recoveryHeader']}>
                    {this.props.t.Interface.getText(this.props.t, 'auth', 'passwordRecovery')}
                </div>
                <div className={this.props.t.styles['recoveryContent']}>
                    <div className={this.props.t.styles['inputRow']}>
                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'newPassword')} id="inputRecoveryPassword" onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.recovery.password.error }, "recovery", "password"); this.props.t.Interface.checkForm(this.props.t, "recovery", "password"); }} autocomplete="none" className={[this.props.t.styles['inputField'], this.props.t.state.form.recovery.password.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" type="password" value={this.props.t.state.form.recovery.password.data}></input>
                        <Tooltip isOpen={this.props.t.state.form.recovery.password.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputRecoveryPassword">
                            {this.props.t.state.form.recovery.password.error}
                        </Tooltip>
                    </div>
                    <div className={this.props.t.styles['inputRow']}>
                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'passwordConfirmation')} id="inputRecoveryPasswordConfirm" onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.recovery.passwordConfirm.error }, "recovery", "passwordConfirm"); this.props.t.Interface.checkForm(this.props.t, "recovery", "passwordConfirm"); }} autocomplete="none" className={[this.props.t.styles['inputField'], this.props.t.state.form.recovery.passwordConfirm.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="passwordConfirm" type="password" value={this.props.t.state.form.recovery.passwordConfirm.data}></input>
                        <Tooltip isOpen={this.props.t.state.form.recovery.passwordConfirm.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputRecoveryPasswordConfirm">
                            {this.props.t.state.form.recovery.passwordConfirm.error}
                        </Tooltip>
                    </div>
                    <div className={this.props.t.styles['recoveryBottom']}>
                        <button onClick={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'cancel')}</button>
                        <button onClick={() => { this.props.t.Interface.isFormCorrect(this.props.t, 'recovery', this.props.t.Interface.doRecoverySet, { recaptchaToken: this.state.recaptcha.token }); this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } }); this.getCaptcha(this); }} disabled={this.props.t.state.recovery.process} className={this.props.t.styles['btn']} type="button">{this.props.t.state.recovery.process ? this.props.t.Interface.getText(this.props.t, 'basic', 'sending') : this.props.t.Interface.getText(this.props.t, 'basic', 'sent')}</button>
                    </div>
                </div>
                <div ref={div => (this.recaptchaLoader = div)}></div>
            </div>
        );
    }
}

export default PasswordRecoverySet;
