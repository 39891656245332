import React from 'react';
import TwoFactor from '../assets/png/Settings/twoFactor.png';
import { Spinner, Tooltip } from 'reactstrap';
import TFAModal from './modals/TFA';

export default class Security extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "login");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "password");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "passwordCurrent");
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "settings", "passwordConfirm");
    }

    handleEnter(event, type) {
        if (event.key === "Enter")
            switch (type) {
                case 1:
                    this.props.t.Interface.isFormCorrectSome(this.props.t, 'settings', this.props.t.Interface.SettingsSetUserInfo, ['password', 'passwordConfirm', 'login']);
                    break;
                case 2:
                    this.props.t.Interface.isFormCorrectSome(this.props.t, 'settings', this.props.t.Interface.SettingsSetUserInfo, ['password', 'passwordConfirm', 'passwordCurrent']);
                    break;
            }
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'security')}
                </div>
                {!this.props.t.state.userData.login && <div className={this.props.t.styles['alert']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'alterLogin')}
                </div>}
                <div className={this.props.t.styles['securityContainer']}>
                    {this.props.t.state.Settings.securityProcess ?
                        <div className={this.props.t.styles['loaderBlock']}><Spinner cssModule={this.props.t.bstyles} style={{ width: '5rem', height: '5rem', margin: '100px auto', color: "#4986f5" }} /></div>
                        : !this.props.t.state.userData.login ?
                            <>
                                <div className={this.props.t.styles['inputHalf']}>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'login')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 1) }} id="inputLogin" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'login')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'login') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.login.error }, "settings", "login"); this.props.t.Interface.checkForm(this.props.t, "settings", "login") }} autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.login.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="login" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'login')} value={this.props.t.state.form.settings.login.data}></input>
                                        <Tooltip isOpen={this.props.t.state.form.settings.login.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputLogin">
                                            {this.props.t.state.form.settings.login.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'newPassword')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 1) }} id="inputPassword" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'password')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'password') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.password.error }, "settings", "password"); this.props.t.Interface.checkForm(this.props.t, "settings", "password") }} autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.password.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" type="password" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'newPassword')} value={this.props.t.state.form.settings.password.data}></input>
                                        <Tooltip isOpen={this.props.t.state.form.settings.password.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputPassword">
                                            {this.props.t.state.form.settings.password.error}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['inputHalf']}>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'repeatPassword')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 1) }} id="inputPasswordConfirm" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'passwordConfirm')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'passwordConfirm') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.passwordConfirm.error }, "settings", "passwordConfirm"); this.props.t.Interface.checkForm(this.props.t, "settings", "passwordConfirm") }} autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.passwordConfirm.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password_confirmation" type="password" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'repeatPassword')} value={this.props.t.state.form.settings.passwordConfirm.data}></input>
                                        <Tooltip isOpen={this.props.t.state.form.settings.passwordConfirm.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputPasswordConfirm">
                                            {this.props.t.state.form.settings.passwordConfirm.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'saveActions')}</label><input onClick={() => { this.props.t.Interface.isFormCorrectSome(this.props.t, 'settings', this.props.t.Interface.SettingsSetUserInfo, ['password', 'passwordConfirm', 'login']) }} disabled={this.props.t.state.form.settings.login.data.length && this.props.t.state.form.settings.password.data.length && this.props.t.state.form.settings.passwordConfirm.data.length && !this.props.t.state.form.settings.login.error && !this.props.t.state.form.settings.password.error && !this.props.t.state.form.settings.passwordConfirm.error ? false : true} autocomplete="off" className={this.props.t.styles['inputControl']} type="submit" value={this.props.t.Interface.getText(this.props.t, 'basic', 'save')}></input>
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className={this.props.t.styles['inputHalf']}>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'newPassword')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 2) }} id="inputNewPassword" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'password')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'password') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.password.error, }, "settings", "password"); this.props.t.Interface.checkForm(this.props.t, "settings", "password") }} autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.password.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" type="password" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'newPassword')} value={this.props.t.state.form.settings.password.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.Settings.securityProcess && this.props.t.state.form.settings.password.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputNewPassword">
                                            {this.props.t.state.form.settings.password.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'repeatPassword')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 2) }} id="inputNewPasswordConfirm" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'passwordConfirm')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'passwordConfirm') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.passwordConfirm.error }, "settings", "passwordConfirm"); this.props.t.Interface.checkForm(this.props.t, "settings", "passwordConfirm") }} autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.passwordConfirm.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password_confirmation" type="password" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'repeatPassword')} value={this.props.t.state.form.settings.passwordConfirm.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.Settings.securityProcess && this.props.t.state.form.settings.passwordConfirm.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputNewPasswordConfirm">
                                            {this.props.t.state.form.settings.passwordConfirm.error}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['inputHalf']}>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'currentPassword')}</label>
                                        <input onKeyPress={(event) => { this.handleEnter(event, 2) }} id="inputNewPasswordCurrent" onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'passwordCurrent')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'passwordCurrent') }} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.settings.passwordCurrent.error }, "settings", "passwordCurrent"); this.props.t.Interface.checkForm(this.props.t, "settings", "passwordCurrent") }} autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.passwordCurrent.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password_current" type="password" placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'currentPassword')} value={this.props.t.state.form.settings.passwordCurrent.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.Settings.securityProcess && this.props.t.state.form.settings.passwordCurrent.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputNewPasswordCurrent">
                                            {this.props.t.state.form.settings.passwordCurrent.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label className={this.props.t.styles['inputLabel']}>{this.props.t.Interface.getText(this.props.t, 'settings', 'saveActions')}</label><input onClick={() => { this.props.t.Interface.isFormCorrectSome(this.props.t, 'settings', this.props.t.Interface.SettingsSetUserInfo, ['password', 'passwordConfirm', 'passwordCurrent']) }} disabled={this.props.t.state.form.settings.passwordCurrent.data.length && this.props.t.state.form.settings.password.data.length && this.props.t.state.form.settings.passwordConfirm.data.length && !this.props.t.state.form.settings.passwordCurrent.error && !this.props.t.state.form.settings.password.error && !this.props.t.state.form.settings.passwordConfirm.error ? false : true} autocomplete="off" className={this.props.t.styles['inputControl']} type="submit" value={this.props.t.Interface.getText(this.props.t, 'basic', 'save')}></input>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'tfa')}
                </div>
                <div className={this.props.t.styles['twoFactor']}>
                    <div className={this.props.t.styles['text']}>
                        {this.props.t.Interface.getText(this.props.t, 'settings', 'addTfa')}
                    </div><button onClick={(ev) => { this.props.t.state.userData.login && this.props.t.updateState(this.props.t, 'Settings', false, 'tfa'); this.props.t.state.userData.login ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: TFAModal, className: this.props.t.styles['tfaModal'] }) : this.props.t.Interface.createNoty(this.props.t, { title: this.props.t.Interface.getText(this.props.t, 'noty', 'error'), message: this.props.t.Interface.getText(this.props.t, 'settings', 'tfaLoginRequired'), type: 'error', timer: 5 }) }} className={this.props.t.styles['btn']} type="button"> {this.props.t.Interface.getText(this.props.t, 'settings', 'tfaSettings')}</button><img src={TwoFactor}></img></div>
            </>
        )
    }

}