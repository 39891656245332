import React from 'react';

import { Tooltip } from 'reactstrap';

import Loader from '../assets/svg/loader.svg';

export default class Verification extends React.Component {
    constructor(props) {
        super(props);
    }

    detectCountry() {
        let t = this;
        fetch(this.props.t.config.ipApi, {
            method: 'GET'
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data.status && typeof data.countryCode !== "undefined" && typeof t.props.t.config.settings.verify.countries[data.countryCode] !== "undefined")
                    t.props.t.updateState(t.props.t, 'form', data.countryCode, 'settings', 'verifyCountry', 'data');
            });
    }

    loadImg(file, type) {
        let reader = new FileReader(), t = this;

        if (file.size > this.props.t.config.limits.form.settings.verifyImg.more) {
            this.props.t.Interface.createNoty(this.props.t, { title: this.props.t.Interface.getText(this.props.t, 'noty', 'error'), message: this.props.t.Interface.getText(this.props.t, 'settings', 'bigFileSize'), type: 'error', timer: 5 });
            if (typeof type !== "undefined" && type)
                this.props.t.updateState(this.props.t, 'form', { data: false, error: false }, 'settings', 'verifyBack');
            else
                this.props.t.updateState(this.props.t, 'form', { data: false, error: false }, 'settings', 'verifyFront');
        } else {
            reader.onloadend = function () {
                let b64 = reader.result.replace(/^data:.+;base64,/, '');

                if (typeof type !== "undefined" && type)
                    t.props.t.updateState(t.props.t, 'form', { data: { b64, name: file.name }, error: false }, 'settings', 'verifyBack');
                else
                    t.props.t.updateState(t.props.t, 'form', { data: { b64, name: file.name }, error: false }, 'settings', 'verifyFront');
            };

            reader.readAsDataURL(file);
        }
    }

    componentDidMount() {
        if (this.props.t.state.userID) {
            this.detectCountry();
            this.props.t.Interface.SettingsGetVerifyData(this.props.t);
        }
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verification')}
                </div>
                <div className={this.props.t.styles['verifyDescription']}>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verificationDescription1')}<br></br><br></br>
                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verificationDescription2')}
                </div>
                <div className={[this.props.t.styles['verifyForm'], !this.props.t.state.Settings.verify || this.props.t.state.Settings.verifyProcess ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                    {!this.props.t.state.Settings.verify || this.props.t.state.Settings.verifyProcess ?
                        <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                        : !this.props.t.state.Settings.verify.status ?
                            <div autocomplete="off">
                                <div className={this.props.t.styles['inputFile']}>
                                    <div className={this.props.t.styles['inputLabel']}>
                                        {this.props.t.Interface.getText(this.props.t, 'settings', 'frontPhoto')}
                                    </div>
                                    <div className={[this.props.t.styles['validationWrapper']]}>
                                        <label for="documentFront">{this.props.t.state.form.settings.verifyFront.data ? this.props.t.state.form.settings.verifyFront.data.name : this.props.t.Interface.getText(this.props.t, 'settings', 'fileIsNotSelected')}<input id="documentFront" name="documentFront" onChange={(ev) => this.loadImg(ev.target.files[0])} type="file" value=""></input>
                                            <div id="documentFrontButton" className={this.props.t.styles['btnAppend']}>
                                                {this.props.t.Interface.getText(this.props.t, 'settings', 'selectFile')}
                                            </div>
                                        </label>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyFront && this.props.t.state.form.settings.verifyFront.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="documentFrontButton">
                                            {this.props.t.state.form.settings.verifyFront.error}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['inputFile']}>
                                    <div className={this.props.t.styles['inputLabel']}>
                                        {this.props.t.Interface.getText(this.props.t, 'settings', 'backPhoto')}
                                    </div>
                                    <div className={[this.props.t.styles['validationWrapper'], this.props.t.state.form.settings.verifyBack.error ? this.props.t.styles['hasError'] : ''].join(' ')}>
                                        <label for="documentBack">{this.props.t.state.form.settings.verifyBack.data ? this.props.t.state.form.settings.verifyBack.data.name : this.props.t.Interface.getText(this.props.t, 'settings', 'fileIsNotSelected')}<input id="documentBack" name="documentBack" onChange={(ev) => this.loadImg(ev.target.files[0], true)} type="file" value=""></input>
                                            <div id="documentBackButton" className={this.props.t.styles['btnAppend']}>
                                                {this.props.t.Interface.getText(this.props.t, 'settings', 'selectFile')}
                                            </div>
                                        </label>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyBack && this.props.t.state.form.settings.verifyBack.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="documentBackButton">
                                            {this.props.t.state.form.settings.verifyBack.error}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['colHalf']}>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'name')}</label>

                                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'yourName')} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'verifyName')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'verifyName') }} id="inputFirstName" autocomplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.verifyName.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="firstname" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'settings', 'verifyName') }} value={this.props.t.state.form.settings.verifyName.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyName && this.props.t.state.form.settings.verifyName.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputFirstName">
                                            {this.props.t.state.form.settings.verifyName.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'surname')}</label>

                                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'yourSurname')} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'verifyLastName')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'verifyLastName') }} id="inputLastName" autocomplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.verifyLastName.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="lastname" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'settings', 'verifyLastName') }} value={this.props.t.state.form.settings.verifyLastName.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyLastName && this.props.t.state.form.settings.verifyLastName.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputLastName">
                                            {this.props.t.state.form.settings.verifyLastName.error}
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['colHalf']}>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'birthday')}</label>
                                        <input onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'verifyDate')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'verifyDate') }} id="inputDate" autocomplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.verifyDate.error ? this.props.t.styles['hasError'] : ''].join(' ')} max="2004-01-01" name="birthday" type="date" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'settings', 'verifyDate') }} value={this.props.t.state.form.settings.verifyDate.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyDate && this.props.t.state.form.settings.verifyDate.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputDate">
                                            {this.props.t.state.form.settings.verifyDate.error}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'gender')}</label>
                                        <div className={this.props.t.styles['inputSelect']}>
                                            <select onChange={(a) => this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: false }, "settings", "verifyGender")} className={this.props.t.styles['inputControl']} value={this.props.t.state.form.settings.verifyGender.data} name="gender">
                                                {this.props.t.config.settings.verify.genders.map((gender, index) =>
                                                    <option key={index} value={gender}>{this.props.t.Interface.getText(this.props.t, 'settings', 'genders', gender)}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['colHalf']}>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'country')}</label>
                                        <div className={this.props.t.styles['inputSelect']}>
                                            <select onChange={(a) => this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: false }, "settings", "verifyCountry")} className={this.props.t.styles['inputControl']} value={this.props.t.state.form.settings.verifyCountry.data} name="country">
                                                {Object.keys(this.props.t.config.settings.verify.countries).map((key, index) =>
                                                    <option key={index} value={key}>{this.props.t.config.settings.verify.countries[key]}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label>{this.props.t.Interface.getText(this.props.t, 'settings', 'city')}</label>

                                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'settings', 'registrationCity')} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'settings', 'verifyCity')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'settings', 'verifyCity') }} id="inputCity" autocomplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.settings.verifyCity.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="city" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'settings', 'verifyCity') }} value={this.props.t.state.form.settings.verifyCity.data}></input>
                                        <Tooltip isOpen={!this.props.t.state.inputs.settings.verifyCity && this.props.t.state.form.settings.verifyCity.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputCity">
                                            {this.props.t.state.form.settings.verifyCity.error}
                                        </Tooltip>
                                    </div>
                                </div><button onClick={() => { this.props.t.Interface.checkForm(this.props.t, "settings", "verify"); this.props.t.state.Settings.verifyFormFilled && this.props.t.Interface.SettingsSendVerifyData(this.props.t) }} className={this.props.t.styles['submit']} type="submit">{this.props.t.Interface.getText(this.props.t, 'settings', 'sendDocs')}</button>
                            </div> : this.props.t.state.Settings.verify.status === 1 ? <div className={[this.props.t.styles['message'], this.props.t.styles['process']].join(' ')}>
                                <div className={this.props.t.styles['caption']}>
                                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verifyStatus1')}
                                </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'verifyDescription1')}
                            </div> : this.props.t.state.Settings.verify.status === 2 ? <div className={[this.props.t.styles['message'], this.props.t.styles['success']].join(' ')}>
                                <div className={this.props.t.styles['caption']}>
                                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verifyStatus2')}
                                </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'verifyDescription2')}
                            </div> : <div className={[this.props.t.styles['message'], this.props.t.styles['failed']].join(' ')}>
                                <div className={this.props.t.styles['caption']}>
                                    {this.props.t.Interface.getText(this.props.t, 'settings', 'verifyStatus3')}
                                </div>{this.props.t.Interface.getText(this.props.t, 'settings', 'verifyDescription3')}
                            </div>}
                </div>
            </>
        )
    }

}