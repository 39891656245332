import React from 'react';
import IconStatic from '../../components/IconStatic';

import { Tooltip } from 'reactstrap';

class RegistrationModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        recaptcha: {
            ready: false,
            token: false
        }
    };

    componentDidMount() {
        window.captchaOnLoad = this.captchaOnLoad.bind(this);

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";
        this.recaptchaLoader.appendChild(script);

    }

    componentWillUnmount() {
        document.getElementById('g-recaptcha').parentNode.removeChild(document.getElementById('g-recaptcha'));
        this.props.t.Interface.clearForm(this.props.t, 'registration');
    }

    captchaOnLoad() {
        const widget = document.createElement('div');
        widget.id = 'g-recaptcha';
        widget.style.visibility = 'hidden';
        document.body.appendChild(widget);

        window.grecaptcha.render('g-recaptcha', {
            sitekey: '6Lfg4PAUAAAAABijupZzyCKqOoZ4JyHtQLXq_oa_',
            size: 'invisible'
        });

        window.grecaptcha.ready(() => {
            this.setState({ recaptcha: { token: this.state.recaptcha.token, ready: true } });
            this.getCaptcha(this);
            setTimeout(this.getCaptcha, 120000, this);
        })
    }

    getCaptcha(t) {
        if (t.state.recaptcha.ready)
            window.grecaptcha.execute({ action: 'user/auth/registration' }).then(token => { t.setState({ recaptcha: { token, ready: t.state.recaptcha.ready } }); });
    }

    handleAuth(event) {
        if (event.key === "Enter") {
            this.props.t.Interface.isFormCorrect(this.props.t, 'registration', this.props.t.Interface.doRegistration, { recaptchaToken: this.state.recaptcha.token });
            this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
            this.getCaptcha(this);
        }
    }

    render() {
        return (
            <div className={this.props.t.styles['authContainer']}>
                {this.props.t.state.width > 900 && <div className={this.props.t.styles['authLeft']}></div>}
                <div className={this.props.t.styles['authContent']}>
                    <div className={this.props.t.styles['authContentContainer']}>
                        <div className={this.props.t.styles['authTabs']}>
                            <button className={this.props.t.styles['active']} type="button">{this.props.t.Interface.getText(this.props.t, 'auth', 'registration')}</button><button onClick={() => this.props.t.Interface.requireAuth(this.props.t)} type="button" value="login">{this.props.t.Interface.getText(this.props.t, 'auth', 'login')}</button>
                        </div>
                        <div className={this.props.t.styles['socialContainer']}>
                            <button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'vk', false)} className={this.props.t.styles['btnSocial']} type="button" value="vkontakte"><IconStatic t={this.props.t} name="vk" /></button>
                            <button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'fb', false)} className={this.props.t.styles['btnSocial']} type="button" value="facebook"><IconStatic t={this.props.t} name="facebook" /></button>
                            <button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'ok', false)} className={this.props.t.styles['btnSocial']} type="button" value="odnoklassniki"><IconStatic t={this.props.t} name="odnoklassniki" /></button>
                            <button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'gl', false)} className={this.props.t.styles['btnSocial']} type="button" value="google"><IconStatic t={this.props.t} name="google" /></button>
                        </div>
                        <div className={this.props.t.styles['captionLine']}>
                            {this.props.t.Interface.getText(this.props.t, 'basic', 'or')}
                        </div>
                        <div className={this.props.t.styles['authForm']}>
                            <div className={this.props.t.styles['formRow']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onKeyPress={(event) => { this.handleAuth(event) }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'registration', 'login')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'registration', 'login') }} id="inputLogin" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.registration.login.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="username" placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourLogin')} type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.registration.login.error }, 'registration', 'login'); this.props.t.Interface.checkForm(this.props.t, 'registration', 'login') }} value={this.props.t.state.form.registration.login.data}></input>
                                    <Tooltip isOpen={!this.props.t.state.inputs.registration.login && this.props.t.state.form.registration.login.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputLogin">
                                        {this.props.t.state.form.registration.login.error}
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={this.props.t.styles['formRow']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onKeyPress={(event) => { this.handleAuth(event) }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'registration', 'password')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'registration', 'password') }} id="inputPassword" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.registration.password.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourPassword')} type="password" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.registration.password.error }, 'registration', 'password'); this.props.t.Interface.checkForm(this.props.t, 'registration', 'password') }} value={this.props.t.state.form.registration.password.data}></input>
                                    <Tooltip isOpen={!this.props.t.state.inputs.registration.password && this.props.t.state.form.registration.password.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputPassword">
                                        {this.props.t.state.form.registration.password.error}
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={this.props.t.styles['formRow']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onKeyPress={(event) => { this.handleAuth(event) }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'registration', 'passwordConfirm')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'registration', 'passwordConfirm') }} id="inputPasswordConfirm" autocomplete="new-password" className={[this.props.t.styles['inputField'], this.props.t.state.form.registration.passwordConfirm.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="passwordConfirm" placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'passwordConfirmation')} type="password" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.registration.passwordConfirm.error }, 'registration', 'passwordConfirm'); this.props.t.Interface.checkForm(this.props.t, 'registration', 'passwordConfirm') }} value={this.props.t.state.form.registration.passwordConfirm.data}></input>
                                    <Tooltip isOpen={!this.props.t.state.inputs.registration.passwordConfirm && this.props.t.state.form.registration.passwordConfirm.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputPasswordConfirm">
                                        {this.props.t.state.form.registration.passwordConfirm.error}
                                    </Tooltip>
                                </div>
                            </div>

                            <button onClick={() => { this.props.t.Interface.isFormCorrect(this.props.t, 'registration', this.props.t.Interface.doRegistration, { recaptchaToken: this.state.recaptcha.token }); this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } }); this.getCaptcha(this); }} className={[this.props.t.styles['btn'], this.props.t.styles['btnAuth']].join(' ')} type="submit">{this.props.t.Interface.getText(this.props.t, 'auth', 'registration')}</button>
                            <div className={this.props.t.styles['confirmAge']}>
                                {this.props.t.Interface.getText(this.props.t, 'auth', 'agreeText')} <a href="#" onClick={() => { this.props.t.doRoute('/tos'); this.props.t.updateState(this.props.t, 'modal', false, 'show'); }}>{this.props.t.Interface.getText(this.props.t, 'auth', 'agreeTos')}</a>
                            </div>
                            <div>
                                <div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={div => (this.recaptchaLoader = div)}></div>
            </div>
        );
    }
}

export default RegistrationModal;
