import React from 'react';
import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import Avatar from 'react-avatar';

import General from '../modules/PartnerGeneral';
import Campgains from '../modules/PartnerCampgains';
import Stats from '../modules/PartnerStats';
import Players from '../modules/PartnerPlayers';
import Log from '../modules/PartnerLog';

export default class Partner extends React.Component {
    constructor(props) {
        super(props);

        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    componentDidMount() {
        this.props.t.updateState(this.props.t, 'location', 'partner');


        if (!this.props.t.state.userID)
            this.props.t.doRoute('/');

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'affProgramm') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'location', '/');
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Partner.campgains.process, this.props.t.state.Partner, this.props.t.state.form.partner, this.props.t.state.inputs.partner, this.props.t.state.lang, this.props.t.state.width], [this.pState.Partner.campgains.process, this.pState.Partner, this.pState.form.partner, this.pState.inputs.partner, this.pState.lang, this.pState.width]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['partnerContainer']}>
                        <div className={this.props.t.styles['sidebar']}>
                            <div className={this.props.t.styles['head']}>
                                <div>
                                    <div className={this.props.t.styles['avatarBlock']}>
                                        <Avatar round="20px" className={this.props.t.styles['avatar']} name={!this.props.t.state.userID ? 'Guest' : this.props.t.state.userData ? this.props.t.state.userData.settings.name : '# ' + this.props.t.state.userID} color={Avatar.getRandomColor(!this.props.t.state.userID ? 0 : this.props.t.state.userID.toString(), ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#fd7e14', '#ffc107', '#28a745', '#28a745', '#20c997', '#17a2b8'])} />
                                    </div>
                                </div>
                                <div className={this.props.t.styles['name']}>
                                    <button className={this.props.t.styles['btnName']} type="button">{this.props.t.state.userID && this.props.t.Interface.getText(this.props.t, 'partner', 'partnerID') + ': #' + this.props.t.state.userID}</button>
                                </div>
                            </div>
                            <div className={this.props.t.state.width <= 1050 ? this.props.t.styles['partnerMobileNav'] : ''}>
                                <div className={this.props.t.styles['navLabel']}>
                                    {this.props.t.Interface.getText(this.props.t, 'partner', 'partnerCabinet')}
                                </div>
                                {this.props.t.state.width > 1050 ?
                                    <>
                                        <a onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 1, 'module'), localStorage.setItem('partner.module', 1))} className={[this.props.t.styles['navItem'], this.props.t.state.Partner.module === 1 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='person' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'partner', 'general')}</a><a onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 2, 'module'), localStorage.setItem('partner.module', 2))} className={[this.props.t.styles['navItem'], this.props.t.state.Partner.module === 2 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='fairness' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'partner', 'campgains')}</a><a onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 3, 'module'), localStorage.setItem('partner.module', 3))} className={[this.props.t.styles['navItem'], this.props.t.state.Partner.module === 3 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='stats' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'partner', 'stat')}</a>
                                        <a onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 4, 'module'), localStorage.setItem('partner.module', 4))} className={[this.props.t.styles['navItem'], this.props.t.state.Partner.module === 4 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='network' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'partner', 'players')}</a>
                                        <a onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 5, 'module'), localStorage.setItem('partner.module', 5))} className={[this.props.t.styles['navItem'], this.props.t.state.Partner.module === 5 ? this.props.t.styles['active'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['navIcon']}>
                                                <IconStatic t={this.props.t} name='history' />
                                            </div>{this.props.t.Interface.getText(this.props.t, 'partner', 'log')}</a>
                                    </> :
                                    <div onBlur={() => !this.blockDropdownBloor && this.props.t.updateState(this.props.t, 'Partner', false, 'open')} className={this.props.t.styles['dropdown']}>
                                        <button onClick={() => this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open')} className={[this.props.t.styles['btnDropdown'], this.props.t.state.Partner.open ? this.props.t.styles['open'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['btnContent']}>
                                                <div className={this.props.t.styles['navIcon']}>
                                                    <Icon t={this.props.t} name={this.props.t.state.Partner.module === 1 ? 'person' : this.props.t.state.Partner.module === 2 ? 'fairness' : this.props.t.state.Partner.module === 3 ? 'stats' : this.props.t.state.Partner.module === 4 ? 'network' : 'history'} />
                                                </div>{this.props.t.state.Partner.module === 1 ? this.props.t.Interface.getText(this.props.t, 'partner', 'general') : this.props.t.state.Partner.module === 2 ? this.props.t.Interface.getText(this.props.t, 'partner', 'campgains') : this.props.t.state.Partner.module === 3 ? this.props.t.Interface.getText(this.props.t, 'partner', 'stat') : this.props.t.state.Partner.module === 4 ? this.props.t.Interface.getText(this.props.t, 'partner', 'players') : this.props.t.Interface.getText(this.props.t, 'partner', 'log')}
                                                <div className={[this.props.t.styles['opener'], this.props.t.state.Partner.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <IconStatic t={this.props.t} name='down' />
                                                </div>
                                            </div></button>
                                        <div onMouseEnter={() => { this.blockDropdownBloor = true }} onMouseLeave={() => { this.blockDropdownBloor = false }} className={[this.props.t.styles['menu'], this.props.t.state.Partner.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 1, 'module'), localStorage.setItem('partner.module', 1), this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'partner', 'general')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 2, 'module'), localStorage.setItem('partner.module', 2), this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'partner', 'campgains')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 3, 'module'), localStorage.setItem('partner.module', 3), this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'partner', 'stat')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 4, 'module'), localStorage.setItem('partner.module', 4), this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'partner', 'players')}</button>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Partner', 5, 'module'), localStorage.setItem('partner.module', 5), this.props.t.updateState(this.props.t, 'Partner', !this.props.t.state.Partner.open, 'open'), document.getElementById('scrollArea').scrollTo({ top: 0, behavior: 'smooth' }), this.blockDropdownBloor = false)} className={this.props.t.styles['item']} type="button">{this.props.t.Interface.getText(this.props.t, 'partner', 'log')}</button>
                                        </div>
                                    </div>
                                }
                            </div >
                        </div >
                        <div className={this.props.t.styles['partnerContent']}>
                            {this.props.t.state.Partner.module === 1 ? <General t={this.props.t} /> : this.props.t.state.Partner.module === 2 ? <Campgains t={this.props.t} /> : this.props.t.state.Partner.module === 3 ? <Stats t={this.props.t} /> : this.props.t.state.Partner.module === 4 ? <Players t={this.props.t} /> : <Log t={this.props.t} />}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}