import React from 'react';
import IconStatic from '../../components/IconStatic';

import { Redirect, withRouter } from "react-router-dom";

import Deposit from './WalletDeposit';
import Withdraw from './WalletWIthdraw';
import History from './WalletHistory';

class WalletModal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
        this.props.t.updateState(this.props.t, 'isWallet', true);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Wallet, this.props.t.state.form.wallet, this.props.t.state.inputs.wallet, this.props.t.state.width, this.props.t.state.lang], [this.pState.Wallet, this.pState.form.wallet, this.pState.inputs.wallet, this.pState.width, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    componentWillUnmount() {
        if (this.props.t.state.width <= 900 && !this.props.t.state.Wallet.openInModal) {
            this.props.t.updateState(this.props.t, 'modal', false, 'show');
            this.props.t.updateState(this.props.t, 'modal', '', 'className');
        }

        this.props.t.updateState(this.props.t, 'Wallet', false, 'openInModal');
        this.props.t.updateState(this.props.t, 'isWallet', false);
    }

    render() {
        if (this.props.t.state.width > 900 && window.location.pathname === "/wallet")
            if (this.props.t.state.Wallet.prevLocation)
                return (<Redirect to={this.props.t.state.Wallet.prevLocation} />);
            else
                return (<Redirect to="/" />);
        else
            return (
                <div className={[this.props.t.styles['walletContainer'], this.props.t.state.width > 900 ? this.props.t.styles['isModal'] : ''].join(' ')} >
                    <div className={this.props.t.styles['walletWrapper']}>
                        <div className={this.props.t.styles['tabsContainer']}>
                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', 1, 'module'), localStorage.setItem('wallet.module', 1))} className={[this.props.t.styles['tabsLink'], this.props.t.state.Wallet.module === 1 ? this.props.t.styles['active'] : ''].join(' ')} type="button" value="deposit"><IconStatic t={this.props.t} name='plus' />{this.props.t.Interface.getText(this.props.t, 'wallet', 'deposit')}</button>
                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', 2, 'module'), localStorage.setItem('wallet.module', 2))} className={[this.props.t.styles['tabsLink'], this.props.t.state.Wallet.module === 2 ? this.props.t.styles['active'] : ''].join(' ')} type="button" value="withdraw"><IconStatic t={this.props.t} name='minus' />{this.props.t.Interface.getText(this.props.t, 'wallet', 'withdraw')}</button>
                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', 3, 'module'), localStorage.setItem('wallet.module', 3))} className={[this.props.t.styles['tabsLink'], this.props.t.state.Wallet.module === 3 ? this.props.t.styles['active'] : ''].join(' ')} type="button" value="history"><IconStatic t={this.props.t} name='time' />{this.props.t.Interface.getText(this.props.t, 'wallet', 'history')}</button>
                            {this.props.t.state.width > 900 ? <button onClick={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }} className={[this.props.t.styles['tabsLink'], this.props.t.styles['tabsClose']].join(' ')} type="button"><IconStatic t={this.props.t} name='close' /></button> : <></>}
                        </div>
                        <div className={[this.props.t.styles['walletContent'], this.props.t.state.Wallet.module === 3 ? this.props.t.styles['hasWrap'] : ''].join(' ')}>
                            {this.props.t.state.Wallet.module === 1 ?
                                <Deposit t={this.props.t} />
                                : this.props.t.state.Wallet.module === 2 ? <Withdraw t={this.props.t} /> : <History t={this.props.t} />
                            }
                        </div>
                    </div>
                </div>
            );
    }
}

export default withRouter(WalletModal);
