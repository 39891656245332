import React from 'react';
import Icon from '../components/Icon';
import IconStatic from '../components/IconStatic';

import Loader from '../assets/svg/loader.svg';


export default class PartnerLog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.t.state.userID)
            this.props.t.Interface.PartnerGetLog(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'Partner', false, 'log', 'data');
        this.props.t.updateState(this.props.t, 'Partner', {}, 'log', 'open');
        this.props.t.updateState(this.props.t, 'Partner', 0, 'log', 'total');

    }

    switchPage(page) {
        if (!isFinite(page))
            page = 0;
        else if (page < 1)
            page = 0;
        else if (page > Math.ceil(this.props.t.state.Partner.log.total / this.props.t.state.Partner.log.perPage))
            page = parseInt(Math.ceil(this.props.t.state.Partner.log.total / this.props.t.state.Partner.log.perPage) - 1);
        else
            page = parseInt(page - 1);

        this.props.t.updateState(this.props.t, 'Partner', page, 'log', 'currentPage');
        this.props.t.Interface.PartnerGetLog(this.props.t);
    }

    showMore(id) {
        if (typeof this.props.t.state.Partner.log.open[id] === "undefined" || !this.props.t.state.Partner.log.open[id])
            this.props.t.state.Partner.log.open[id] = true;
        else
            this.props.t.state.Partner.log.open[id] = false;

        this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.log.open, 'log', 'open');
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'partner', 'log')}
                </div>
                <div className={this.props.t.styles['players']}>
                    <div className={[this.props.t.styles['withPager'], this.props.t.state.Partner.log.process && this.props.t.state.Partner.log.data ? this.props.t.styles['isLoading'] : ''].join(' ')}>
                        <div className={[this.props.t.styles['list'], !this.props.t.state.Partner.log.data ? this.props.t.styles['isLoader'] : ''].join(' ')}>
                            {this.props.t.state.Partner.log.data && !this.props.t.state.Partner.log.data.length ?
                                <div className={this.props.t.styles['empty']}><h4>N/A</h4>{this.props.t.Interface.getText(this.props.t, 'partner', 'emptyHistory')}</div>
                                : !this.props.t.state.Partner.log.data ?
                                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                                    : <>
                                        <div className={[this.props.t.styles['item'], this.props.t.styles['headItem']].join(' ')}>
                                            <div className={this.props.t.styles['itemHeader']} role="button">
                                                <div className={this.props.t.styles['itemRight']}>

                                                    <div className={this.props.t.styles['itemInfo']}>
                                                        <span onClick={() => { this.props.t.state.Partner.log.order === "nameDesc" ? this.props.t.updateState(this.props.t, 'Partner', "nameAsc", 'log', 'order') : this.props.t.state.Partner.log.order === "nameAsc" ? this.props.t.updateState(this.props.t, 'Partner', "none", 'log', 'order') : this.props.t.updateState(this.props.t, 'Partner', "nameDesc", 'log', 'order'); this.props.t.Interface.PartnerGetLog(this.props.t) }} className={this.props.t.styles['infoCaption']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'operationID')} <Icon t={this.props.t} name={this.props.t.state.Partner.log.order === "nameDesc" ? "sort-desc" : this.props.t.state.Partner.log.order === "nameAsc" ? "sort-asc" : "sort-off"} /></span>
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemLeft']}>
                                                    <div className={this.props.t.styles['itemAmount']}>
                                                        <span onClick={() => { this.props.t.state.Partner.log.order === "amountDesc" ? this.props.t.updateState(this.props.t, 'Partner', "amountAsc", 'log', 'order') : this.props.t.state.Partner.log.order === "amountAsc" ? this.props.t.updateState(this.props.t, 'Partner', "none", 'log', 'order') : this.props.t.updateState(this.props.t, 'Partner', "amountDesc", 'log', 'order'); this.props.t.Interface.PartnerGetLog(this.props.t) }} className={this.props.t.styles['value']}>{this.props.t.Interface.getText(this.props.t, 'partner', 'amount')} <Icon t={this.props.t} name={this.props.t.state.Partner.log.order === "amountDesc" ? "sort-desc" : this.props.t.state.Partner.log.order === "amountAsc" ? "sort-asc" : "sort-off"} /></span>
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemDate']}>
                                                    <span>{this.props.t.Interface.getText(this.props.t, 'partner', 'date')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.t.state.Partner.log.data.map((item, index) => (
                                            <div key={index} className={[this.props.t.styles['item'], this.props.t.styles['logItem']].join(' ')}>
                                                <div onClick={() => !this.props.t.state.Partner.log.cancelClicked ? this.showMore(item.id) : {}} className={this.props.t.styles['itemHeader']} role="button">
                                                    <div className={this.props.t.styles['itemRight']}>
                                                        <div className={this.props.t.styles['itemInfo']}>
                                                            <span className={this.props.t.styles['infoCaption']}>{'#' + item.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className={this.props.t.styles['itemLeft']}>
                                                        <div className={this.props.t.styles['itemAmount']}>
                                                            <span className={[this.props.t.styles['value'], item.amount > 0 ? this.props.t.styles['win'] : ''].join(' ')}>{this.props.t.Interface.fixedFloat(item.amount)} <IconStatic t={this.props.t} name='rub' /></span>
                                                        </div>
                                                    </div>
                                                    <div className={this.props.t.styles['itemDate']}>
                                                        <span>{this.props.t.Interface.localeDate(item.date, true, this.props.t)}</span>
                                                    </div>
                                                </div>
                                                <div className={[this.props.t.styles['itemContent'], typeof this.props.t.state.Partner.log.open[item.id] !== "undefined" && this.props.t.state.Partner.log.open[item.id] ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <ul>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'operationDate')}:</span><span>{this.props.t.Interface.localeDate(item.date, false, this.props.t)}</span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'operationType')}:</span><span>{this.props.t.Interface.getOperationByType(item, this.props.t)}</span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'playerID')}:</span><span>#{item.userID}</span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'balanceBefore')}:</span><span>{this.props.t.Interface.fixedFloat(item.from)} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                        <li><span>{this.props.t.Interface.getText(this.props.t, 'partner', 'balanceAfter')}:</span><span>{this.props.t.Interface.fixedFloat(item.to)} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}</>

                            }
                        </div>
                    </div>
                    {Math.ceil(this.props.t.state.Partner.log.total / this.props.t.state.Partner.log.perPage) > 1 ?
                        (<div className={this.props.t.styles['pager']}>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.log.currentPage - 1, 'log', 'currentPage'); this.props.t.Interface.PartnerGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Partner.log.currentPage + 1 > 1 ? false : true}><span><IconStatic t={this.props.t} name='left' />{this.props.t.state.width <= 1300 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'prevPage')}</span></button>
                            <div className={this.props.t.styles['inputBlock']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onChange={(a) => { this.switchPage(a.target.value) }} className={this.props.t.styles['inputField']} value={this.props.t.state.Partner.log.currentPage + 1}></input>
                                </div>{this.props.t.Interface.getText(this.props.t, 'basic', 'of')} {Math.ceil(this.props.t.state.Partner.log.total / this.props.t.state.Partner.log.perPage)}
                            </div>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Partner', this.props.t.state.Partner.log.currentPage + 1, 'log', 'currentPage'); this.props.t.Interface.PartnerGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Partner.log.currentPage + 1 < Math.ceil(this.props.t.state.Partner.log.total / this.props.t.state.Partner.log.perPage) ? false : true}><span>{this.props.t.state.width <= 1300 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'nextPage')}<IconStatic t={this.props.t} name='left' /></span></button>
                        </div>) : (<></>)}
                </div>
            </>
        )
    }
}