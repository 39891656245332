import React from 'react';
import Categories from '../modules/LobbyCategories';
import Providers from '../modules/LobbyProviders';
import ControlPanel from '../modules/LobbyControlPanel';
import Search from '../modules/LobbySearch';
import Games from '../modules/LobbyGames';

import { withRouter } from "react-router-dom";

class Lobby extends React.Component {
    constructor(props) {
        super(props);

        this.buildControlBlock();
    }

    componentDidMount() {
        this.props.t.Interface.LobbyGetCategoriesList(this.props.t);
        this.props.t.Interface.LobbyGetProvidersList(this.props.t);

        if (this.props.t.state.userID)
            this.props.t.Interface.LobbyGetCData(this.props.t);

        this.props.t.updateState(this.props.t, 'Lobby', 0, 'filter');
        this.props.t.updateState(this.props.t, 'location', 'lobby');
        this.buildList();
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'lobby') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        let t = this.props.t;
        this.props.t.updateState(this.props.t, 'Lobby', {
            categories: this.props.t.state.Lobby.categories,
            providers: this.props.t.state.Lobby.providers,
            loadedGames: [],
            gamesList: [],
            hasMore: true,
            providersOpen: false,
            providerSelected: 0,
            categorySelected: 0,
            filter: false,
            search: '',
            loading: false,
            recentGames: [],
            favoriteGames: []
        });

        if (this.props.t.state.Header.gamesList.length)
            this.props.t.state.Header.gamesList.forEach(function (item) {
                if (!t.Interface.inArray(item.providerID, t.config.casino.lockedProviders)) {
                if (t.state.Landing.newGames.length < t.config.limits.Landing.newGames && typeof item.params.new !== "undefined" && item.params.new)
                    t.state.Landing.newGames.push(item);

                //if ((!t.state.Lobby.providerSelected || t.state.Lobby.providerSelected === item.providerID) && (!t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.state.Lobby.categorySelected))) && (!t.state.Lobby.filter || (t.state.Lobby.filter === 2 && item.params.top) || (t.state.Lobby.filter === 3 && item.params.new) || (t.state.Lobby.filter === 1 && t.Interface.inArray(item.id, t.state.Lobby.recentGames)) || (t.state.Lobby.filter === 4 && t.Interface.inArray(item.id, this.props.t.state.Lobby.favoriteGames))))
                //   t.state.Lobby.gamesList.push(item);

                if (t.state.Lobby.loadedGames.length < t.config.limits.Lobby.gamesPerPage && (!t.state.Lobby.providerSelected || t.state.Lobby.providerSelected === item.providerID) && (!t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.state.Lobby.categorySelected))) && (!t.state.Lobby.filter || (t.state.Lobby.filter === 2 && item.params.top) || (t.state.Lobby.filter === 3 && item.params.new) || (t.state.Lobby.filter === 1 && t.Interface.inArray(item.id, t.state.Lobby.recentGames)) || (t.state.Lobby.filter === 4 && t.Interface.inArray(item.id, this.props.t.state.Lobby.favoriteGames))))
                    t.state.Lobby.loadedGames.push(item);
                }
            });

        this.props.t.updateState(this.props.t, 'location', '/');
    }

    componentDidUpdate() {
        this.buildList();
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby, this.props.t.state.lang, this.props.t.width], [this.pState.Lobby, this.pState.lang, this.pState.width]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    buildControlBlock() {
        let needBuild = false;


        if (typeof this.props.match.params.provider !== "undefined" && parseInt(this.props.match.params.provider) && this.props.match.params.provider !== parseInt(this.props.t.state.Lobby.providerSelected))
            needBuild = true;
        else {
            this.props.t.updateState(this.props.t, 'Lobby', false, 'providersOpen');
            this.props.t.updateState(this.props.t, 'Lobby', false, 'providerSelected');
        }

        if (typeof this.props.match.params.category !== "undefined" && parseInt(this.props.match.params.category) && this.props.match.params.category !== parseInt(this.props.t.state.Lobby.categorySelected))
            needBuild = true;
        else {
            this.props.t.updateState(this.props.t, 'Lobby', false, 'categoriesOpen');
            this.props.t.updateState(this.props.t, 'Lobby', false, 'categorySelected');
        }

        if (needBuild)
            this.buildList();
    }

    buildList = (intent) => {
        let t = this;

        if (t.props.t.state.Header.gamesList.length && (intent || typeof t.props.match.params.provider !== "undefined" && parseInt(t.props.match.params.provider) != t.props.t.state.Lobby.providerSelected || typeof t.props.match.params.category !== "undefined" && parseInt(t.props.match.params.category) != t.props.t.state.Lobby.categorySelected)) {

            if (typeof t.props.match.params.provider !== "undefined") {
                if (!parseInt(t.props.match.params.provider))
                    t.props.t.updateState(t.props.t, 'Lobby', false, 'providerSelected');
                else
                    t.props.t.updateState(t.props.t, 'Lobby', parseInt(t.props.match.params.provider), 'providerSelected');
            }

            if (typeof t.props.match.params.category !== "undefined") {
                if (!parseInt(t.props.match.params.category))
                    t.props.t.updateState(t.props.t, 'Lobby', false, 'categorySelected');
                else
                    t.props.t.updateState(t.props.t, 'Lobby', parseInt(t.props.match.params.category), 'categorySelected');
            }

            //t.props.t.updateState(t.props.t, 'Lobby', [], 'gamesList');
            t.props.t.updateState(t.props.t, 'Lobby', [], 'loadedGames');
            t.props.t.updateState(t.props.t, 'Lobby', true, 'loading');

            setTimeout(() => {
                t.props.t.updateState(t.props.t, 'Lobby', false, 'loading');
            }, 500);

            t.props.t.state.Header.gamesList.forEach(function (item) {
                if (!t.props.t.Interface.inArray(item.providerID, t.props.t.config.casino.lockedProviders)) {
                    // if ((!t.props.t.state.Lobby.providerSelected || t.props.t.state.Lobby.providerSelected === item.providerID) && (!t.props.t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.props.t.state.Lobby.categorySelected))) && (!t.props.t.state.Lobby.search || item.params.name.toUpperCase().indexOf(t.props.t.state.Lobby.search.toUpperCase()) !== -1) && (!t.props.t.state.Lobby.filter || (t.props.t.state.Lobby.filter === 2 && item.params.top) || (t.props.t.state.Lobby.filter === 3 && item.params.new) || (t.props.t.state.Lobby.filter === 1 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.recentGames)) || (t.props.t.state.Lobby.filter === 4 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.favoriteGames))))
                    //     t.props.t.state.Lobby.gamesList.push(item);

                    if (t.props.t.state.Lobby.loadedGames.length < t.props.t.config.limits.Lobby.gamesPerPage && (!t.props.t.state.Lobby.providerSelected || t.props.t.state.Lobby.providerSelected === item.providerID) && (!t.props.t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => parseInt(category) === t.props.t.state.Lobby.categorySelected))) && (!t.props.t.state.Lobby.search || item.params.name.toUpperCase().indexOf(t.props.t.state.Lobby.search.toUpperCase()) !== -1) && (!t.props.t.state.Lobby.filter || (t.props.t.state.Lobby.filter === 2 && item.params.top) || (t.props.t.state.Lobby.filter === 3 && item.params.new) || (t.props.t.state.Lobby.filter === 1 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.recentGames)) || (t.props.t.state.Lobby.filter === 4 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.favoriteGames))))
                        t.props.t.state.Lobby.loadedGames.push(item);
                    else if (!t.props.t.state.Lobby.hasMore && (!t.props.t.state.Lobby.providerSelected || t.props.t.state.Lobby.providerSelected === item.providerID) && (!t.props.t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => parseInt(category) === t.props.t.state.Lobby.categorySelected))) && (!t.props.t.state.Lobby.search || item.params.name.toUpperCase().indexOf(t.props.t.state.Lobby.search.toUpperCase()) !== -1) && (!t.props.t.state.Lobby.filter || (t.props.t.state.Lobby.filter === 2 && item.params.top) || (t.props.t.state.Lobby.filter === 3 && item.params.new) || (t.props.t.state.Lobby.filter === 1 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.recentGames)) || (t.props.t.state.Lobby.filter === 4 && t.props.t.Interface.inArray(item.id, t.props.t.state.Lobby.favoriteGames))))
                        t.props.t.updateState(t.props.t, 'Lobby', true, 'hasMore');
                }
            });

            // t.props.t.updateState(t.props.t, 'Lobby', t.props.t.state.Lobby.gamesList, 'gamesList');
            t.props.t.updateState(t.props.t, 'Lobby', t.props.t.state.Lobby.loadedGames, 'loadedGames');

        }
    }

    render() {
        return (
            <>
                <div className={[this.props.t.styles['section'], this.props.t.styles['lobbySection']].join(' ')}>
                    <div className={this.props.t.styles['container']}>
                        <Categories t={this.props.t} build={this.buildList} />
                        <ControlPanel t={this.props.t} build={this.buildList} />
                        <Providers t={this.props.t} />
                        <Search t={this.props.t} build={this.buildList} />
                        <Games t={this.props.t} />
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Lobby);