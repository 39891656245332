import React from 'react';
import Icon from '../components/Icon';

export default class ControlPanel extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.pState = this.props.t.cloneDeep(this.props.t.state);
	}

	shouldComponentUpdate() {
		let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.userID, this.props.t.state.Lobby.providersOpen, this.props.t.state.Lobby.filter, this.props.t.state.lang], [this.pState.userID, this.pState.Lobby.providersOpen, this.pState.Lobby.filter, this.pState.lang]);

		if (needUpdate)
			this.pState = this.props.t.cloneDeep(this.props.t.state);

		return needUpdate;
	}

	render() {
		return (
			<>
				<div className={this.props.t.styles['controlPanel']}>
					<div className={this.props.t.styles['item']}>
						<div className={this.props.t.styles['btnWrapper']}>
							<button onClick={() => this.props.t.updateState(this.props.t, 'Lobby', !this.props.t.state.Lobby.providersOpen, 'providersOpen')} className={[this.props.t.styles['btn'], this.props.t.state.Lobby.providersOpen ? this.props.t.styles['active'] : ''].join(' ')} type="button"><Icon t={this.props.t} name='bets' />{this.props.t.Interface.getText(this.props.t, 'lobby', 'providers')}</button>
						</div>
					</div>
					<div className={this.props.t.styles['userButtons']}>
						<div className={this.props.t.styles['btnItem']}>
							<button onClick={() => { !this.props.t.state.userID ? this.props.t.Interface.requireAuth(this.props.t) : this.props.t.state.Lobby.filter === 1 ? this.props.t.updateState(this.props.t, 'Lobby', false, 'filter') : this.props.t.updateState(this.props.t, 'Lobby', 1, 'filter'); this.props.t.state.userID && this.props.build(true) }} className={[this.props.t.styles['btn'], this.props.t.styles['history'], this.props.t.state.Lobby.filter === 1 ? this.props.t.styles['active'] : ''].join(' ')} data-action="last"><Icon t={this.props.t} name='history' />{this.props.t.Interface.getText(this.props.t, 'lobby', 'recent')}</button>
						</div>
						<div className={this.props.t.styles['btnItem']}>
							<button onClick={() => { this.props.t.state.Lobby.filter === 2 ? this.props.t.updateState(this.props.t, 'Lobby', false, 'filter') : this.props.t.updateState(this.props.t, 'Lobby', 2, 'filter'); this.props.build(true) }} className={[this.props.t.styles['btn'], this.props.t.styles['top'], this.props.t.state.Lobby.filter === 2 ? this.props.t.styles['active'] : ''].join(' ')} data-action="top"><Icon t={this.props.t} name='star' />{this.props.t.Interface.getText(this.props.t, 'lobby', 'top')}</button>
						</div>
						<div className={this.props.t.styles['btnItem']}>
							<button onClick={() => { this.props.t.state.Lobby.filter === 3 ? this.props.t.updateState(this.props.t, 'Lobby', false, 'filter') : this.props.t.updateState(this.props.t, 'Lobby', 3, 'filter'); this.props.build(true) }} className={[this.props.t.styles['btn'], this.props.t.styles['new'], this.props.t.state.Lobby.filter === 3 ? this.props.t.styles['active'] : ''].join(' ')} data-action="new"><Icon t={this.props.t} name='diamond' />{this.props.t.Interface.getText(this.props.t, 'lobby', 'new')}</button>
						</div>
						<div className={this.props.t.styles['btnItem']}>
							<button onClick={() => { !this.props.t.state.userID ? this.props.t.Interface.requireAuth(this.props.t) : this.props.t.state.Lobby.filter === 4 ? this.props.t.updateState(this.props.t, 'Lobby', false, 'filter') : this.props.t.updateState(this.props.t, 'Lobby', 4, 'filter'); this.props.t.state.userID && this.props.build(true) }} className={[this.props.t.styles['btn'], this.props.t.styles['favorite'], this.props.t.state.Lobby.filter === 4 ? this.props.t.styles['active'] : ''].join(' ')} data-action="favorite"><Icon t={this.props.t} name='heart' />{this.props.t.Interface.getText(this.props.t, 'lobby', 'favorite')}</button>
						</div>
					</div>
				</div>
			</>
		)
	}

}