import React from 'react';
import LeftBar from '../modules/LeftBar';
import Footer from '../modules/Footer';
import RightBar from '../modules/RightBar';
import MobileNav from '../modules/MobileNav';

import PWAImage from '../assets/png/pwa.png';
import IconStatic from '../components/IconStatic';

import { isMobile } from "react-device-detect";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import ClickAwayListener from 'react-click-away-listener-brillx';

export default class Content extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.props.t.state.width <= 900 && (!this.props.t.state.Pwa.showed || parseInt(+new Date()) - this.props.t.state.Pwa.showed > 1000 * 60 * 60 * 24 * this.props.t.config.limits.notify.pwa) && isMobile && !window.matchMedia('(display-mode: standalone)').matches)
				this.props.t.updateState(this.props.t, 'Pwa', true, 'open');
		}, 2000);
	}

	shouldComponentUpdate() {
		return true;
	}

	render() {
		return (<>
			<div id="pullechan" className={[this.props.t.styles['wrapper'], this.props.t.styles[this.props.wrapper + 'ContentWrapper'], isMobile ? this.props.t.styles['mobile'] : ''].join(' ')}>
				{this.props.t.state.width <= 900 ? <this.props.t.state.Header.block t={this.props.t} /> : <></>}
				{this.props.t.state.width > 900 ? <LeftBar t={this.props.t} /> : <></>}
				<div className={this.props.t.styles['contentWrapper']}>
					<PerfectScrollbar options={{ wheelSpeed: 0.75, swipeEasing: true }} id="scrollArea" className={this.props.t.styles['contentScroll']}>
						<div className={this.props.t.styles['mainContainer']}>
							<div>
								<this.props.page t={this.props.t} />
							</div>
							<Footer wrapper={this.props.wrapper} t={this.props.t} />
						</div>
					</PerfectScrollbar>
					{this.props.t.state.width <= 900 ? <MobileNav t={this.props.t} /> : <></>}
				</div>
				{this.props.t.state.width <= 900 && this.props.t.state.Pwa.open &&
					<>
						<div className={this.props.t.styles['pwaBackground']}></div>
						<ClickAwayListener onClickAway={() => { this.props.t.updateState(this.props.t, 'Pwa', false, 'open'); this.props.t.updateState(this.props.t, 'Pwa', +new Date(), 'showed'); localStorage.setItem('notify.pwa', +new Date()); }}>
							<div className={this.props.t.styles['pwaModal']}>
								<div className={this.props.t.styles['wrapper']}>
									<button onClick={() => { this.props.t.updateState(this.props.t, 'Pwa', false, 'open'); this.props.t.updateState(this.props.t, 'Pwa', +new Date(), 'showed'); localStorage.setItem('notify.pwa', +new Date()); }} className={this.props.t.styles['close']} type="button"><IconStatic t={this.props.t} name='close' /></button>
									<div className={this.props.t.styles['image']}>
										<div className={this.props.t.styles['spacer']}></div><img alt="" src={PWAImage} /></div>
									<div className={this.props.t.styles['title']}>
										{this.props.t.Interface.getText(this.props.t, 'pwa', 'app')}
									</div>
									<div className={this.props.t.styles['text']}>
										{this.props.t.Interface.getText(this.props.t, 'pwa', 'installAppForMobile')}
									</div>
									<div className={this.props.t.styles['button']}>
										<button onClick={() => { this.props.t.updateState(this.props.t, 'Pwa', false, 'open'); this.props.t.updateState(this.props.t, 'Pwa', +new Date(), 'showed'); localStorage.setItem('notify.pwa', +new Date()); this.props.t.doRoute('/pwa'); }} type="button">{this.props.t.Interface.getText(this.props.t, 'pwa', 'install')}</button>
									</div>
								</div>
							</div>
						</ClickAwayListener></>}
				<RightBar t={this.props.t} />
			</div>
		</>
		)
	}
}