import React from 'react';
import IconStatic from '../components/IconStatic';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'reactstrap';

import Loader from '../assets/svg/loader.svg';

export default class General extends React.Component {
    constructor(props) {
        super(props);
        this.props.t.updateState(this.props.t, 'Partner', false, 'stat');
    }

    componentDidMount() {
        if (this.props.t.state.userID) {
            this.props.t.Interface.PartnerGetCode(this.props.t);
            this.props.t.Interface.PartnerGetStats(this.props.t, false);
            this.props.t.Interface.PartnerGetBalance(this.props.t);
        }

        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "partner", "code");
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Partner, this.props.t.state.form.partner, this.props.t.state.inputs.partner, this.props.t.state.lang, this.props.t.state.width], [this.pState.Partner, this.pState.form.partner, this.pState.inputs.partner, this.pState.lang, this.pState.width]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['caption']}>
                    {this.props.t.Interface.getText(this.props.t, 'partner', 'general')}
                </div>
                {this.props.t.state.Partner.process || !this.props.t.state.Partner.stat ?
                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                    :
                    <div className={this.props.t.styles['general']}>
                        <div className={this.props.t.styles['linkForm']}>
                            <div className={this.props.t.styles['text']}>
                                {this.props.t.Interface.getText(this.props.t, 'partner', 'mainAffiliateLink')}:
	                        </div>
                            <div className={this.props.t.styles['form']}>
                                <div className={this.props.t.styles['formRow']}>
                                    <div className={[this.props.t.styles['formField'], this.props.t.styles['inputGroup']].join(' ')}>
                                        <input onKeyPress={(ev) => this.props.t.state.inputs.partner.code && ev.charCode === 13 && !this.props.t.state.form.partner.code.error && (this.props.t.Interface.PartnerSetCode(this.props.t), ev.target.blur(), this.blockInputBloor = false)} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'partner', 'code')} onBlur={() => { !this.blockInputBloor && this.props.t.updateState(this.props.t, "inputs", false, 'partner', 'code'); if (this.props.t.state.form.partner.code.error && !this.blockInputBloor) { this.props.t.updateState(this.props.t, "form", { data: this.props.t.state.form.partner.code.data, error: false }, "partner", "code"); this.props.t.Interface.PartnerGetCode(this.props.t); } }} id="inputCode" autoComplete="nope" className={[this.props.t.styles['inputField'], this.props.t.state.form.partner.code.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="code" type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: false }, 'partner', 'code'); this.props.t.Interface.checkForm(this.props.t, "partner", "code"); }} value={this.props.t.state.inputs.partner.code ? this.props.t.state.form.partner.code.data : this.props.t.config.partnerURL + '/' + this.props.t.state.form.partner.code.data}></input>
                                        <Tooltip isOpen={this.props.t.state.inputs.partner.code && this.props.t.state.form.partner.code.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputCode">
                                            {this.props.t.state.form.partner.code.error}
                                        </Tooltip>

                                        <div className={this.props.t.styles['inputGroupAppend']}>
                                            {!this.props.t.state.inputs.partner.code ?
                                                <><CopyToClipboard text={this.props.t.config.partnerURL + '/' + this.props.t.state.form.partner.code.data}
                                                    onCopy={() => { this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard); this.props.t.updateState(this.props.t, 'clipboardModule', 'partnerLink'); setTimeout(() => { this.props.t.updateState(this.props.t, 'clipboard', !this.props.t.state.clipboard); this.props.t.updateState(this.props.t, 'clipboardModule', false) }, 500) }}>
                                                    <button className={this.props.t.styles['btn']} type="button"><IconStatic t={this.props.t} name='copy' /></button>
                                                </CopyToClipboard>
                                                    <div className={[this.props.t.styles['copyTooltip'], this.props.t.state.clipboard && this.props.t.state.clipboardModule === "partnerLink" ? this.props.t.styles['visible'] : ''].join(' ')}>
                                                        {this.props.t.Interface.getText(this.props.t, 'basic', 'copied')}
                                                    </div></>
                                                :
                                                <button onMouseEnter={() => { this.blockInputBloor = true }} onMouseLeave={() => { this.blockInputBloor = false }} onClick={() => { !this.props.t.state.form.partner.code.error && this.props.t.Interface.PartnerSetCode(this.props.t); this.blockInputBloor = false }} className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen']].join(' ')} type="button"><IconStatic t={this.props.t} name='success' /></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={this.props.t.styles['statsForm']}>
                            <div className={this.props.t.styles['left']}>
                                <div className={this.props.t.styles['item']}>
                                    <div className={[this.props.t.styles['wrap'], this.props.t.styles['borderRight']].join(' ')}>
                                        <div className={this.props.t.styles['block']}>
                                            <IconStatic t={this.props.t} name='stats' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.state.Partner.stat.clicks}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'partner', 'clicks')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <IconStatic t={this.props.t} name='person' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.state.Partner.stat.regs}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'partner', 'registrations')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={[this.props.t.styles['item'], this.props.t.styles['borderTop']].join(' ')}>
                                    <div className={[this.props.t.styles['wrap'], this.props.t.styles['borderRight']].join(' ')}>
                                        <div className={this.props.t.styles['block']}>
                                            <IconStatic t={this.props.t} name='network' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Partner.stat.bets) + this.props.t.Interface.getCurrency(this.props.t)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'partner', 'totalBets')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <IconStatic t={this.props.t} name='briefcase' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Partner.stat.profit) + this.props.t.Interface.getCurrency(this.props.t)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'partner', 'totalProfit')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className={this.props.t.styles['right']}>
                                <div className={[this.props.t.styles['item'], this.props.t.styles['full']].join(' ')}>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <IconStatic t={this.props.t} name='rub' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Partner.balance)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'partner', 'availableBalance')}
                                            </div>
                                            <span className={this.props.t.styles['btnBlock']}>
                                                <button onClick={() => { this.props.t.Interface.PartnerGetProfit(this.props.t) }} className={this.props.t.styles['btn']} disabled={this.props.t.state.Partner.balance > 0 ? false : true} type="button"><span id="withdraw-button">{this.props.t.Interface.getText(this.props.t, 'partner', 'get')}</span></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >}
            </>
        )
    }

}