import React from 'react';
import IconStatic from '../../components/IconStatic';

import Loader from '../../assets/svg/loader.svg';

class WalletDeposit extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.t.Interface.WalletGetLog(this.props.t);
        this.props.t.Interface.WalletGetPayways(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'Wallet', false, 'log', 'data');
        this.props.t.updateState(this.props.t, 'Wallet', {}, 'log', 'open');
    }

    getOperationByType(item) {
        switch (item.type) {
            case 1:
                return 'Deposit';
            case 2:
                return 'Withdraw';
            case 99:
                return 'Casino';
            default:
                return 'Undefined';
        }
    }

    switchPage(page) {
        if (!isFinite(page))
            page = 0;
        else if (page < 1)
            page = 0;
        else if (page > Math.ceil(this.props.t.state.Wallet.log.total / this.props.t.state.Wallet.log.perPage))
            page = parseInt(Math.ceil(this.props.t.state.Wallet.log.total / this.props.t.state.Wallet.log.perPage) - 1);
        else
            page = parseInt(page - 1);

        this.props.t.updateState(this.props.t, 'Wallet', page, 'log', 'currentPage');
        this.props.t.Interface.WalletGetLog(this.props.t);
    }

    showMore(id) {
        if (typeof this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + id] === "undefined" || !this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + id])
            this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + id] = true;
        else
            this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + id] = false;

        this.props.t.updateState(this.props.t, 'Wallet', this.props.t.state.Wallet.log.open, 'log', 'open');
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['historyNav']}>
                    <button onClick={() => { this.props.t.updateState(this.props.t, 'Wallet', 3, 'log', 'type'); this.props.t.updateState(this.props.t, 'Wallet', 0, 'log', 'currentPage'); localStorage.setItem('wallet.log.type', 3); this.props.t.Interface.WalletGetLog(this.props.t) }} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Wallet.log.type === 3 ? this.props.t.styles['active'] : ''].join(' ')}> {this.props.t.Interface.getText(this.props.t, 'wallet', 'deposits')}</button>
                    <button onClick={() => { this.props.t.updateState(this.props.t, 'Wallet', 4, 'log', 'type'); this.props.t.updateState(this.props.t, 'Wallet', 0, 'log', 'currentPage'); localStorage.setItem('wallet.log.type', 4); this.props.t.Interface.WalletGetLog(this.props.t) }} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Wallet.log.type === 4 ? this.props.t.styles['active'] : ''].join(' ')}> {this.props.t.Interface.getText(this.props.t, 'wallet', 'withdraws')}</button>
                </div>
                <div className={this.props.t.styles['historyWrapper']}>
                    <div className={[this.props.t.styles['withPager'], this.props.t.state.Wallet.log.process && this.props.t.state.Wallet.log.data ? this.props.t.styles['isLoading'] : ''].join(' ')}>
                        <div className={this.props.t.styles['list']}>
                            {this.props.t.state.Wallet.log.data && !this.props.t.state.Wallet.log.data.length ?
                                <div className={this.props.t.styles['empty']}><h4>N/A</h4>{this.props.t.Interface.getText(this.props.t, 'wallet', 'emptyHistory')}</div>
                                : !this.props.t.state.Wallet.log.data ?
                                    <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                                    : this.props.t.state.Wallet.log.data.map((item, index) => (
                                        <div key={index} className={this.props.t.styles['item']}>
                                            <div onClick={() => !this.props.t.state.Wallet.log.cancelClicked ? this.showMore(item.id) : {}} className={this.props.t.styles['itemHeader']} role="button">
                                                <div className={this.props.t.styles['itemRight']}>
                                                    <div className={this.props.t.styles['itemLogo']}><img src={this.props.t.Interface.getPaywayImage(this.props.t, item.type)}></img></div>
                                                    <div className={this.props.t.styles['itemInfo']}>
                                                        <span className={this.props.t.styles['infoCaption']}>{this.props.t.Interface.getText(this.props.t, 'wallet', item.type.replace(/[0-9]/g, ''))} {item.type.match(/\d+/) !== null && item.type.match(/\d+/)[0] !== "" ? '№' + item.type.match(/\d+/)[0] : ''}</span><span className={this.props.t.styles['infoComment']}><span> {this.props.t.Interface.getText(this.props.t, 'wallet', 'operationID')}:&nbsp;</span>{item.id}</span>
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemLeft']}>
                                                    <div className={this.props.t.styles['itemAmount']}>
                                                        <span className={this.props.t.styles['value']}>{item.amount} <IconStatic t={this.props.t} name='rub' /></span>
                                                    </div>
                                                    <div className={this.props.t.styles['itemStatus']}>
                                                        {this.props.t.state.Wallet.log.type === 4 && item.status === "waiting" ?
                                                            (<div className={this.props.t.styles['btnStatus']}>
                                                                <button onClick={() => { this.props.t.updateState(this.props.t, 'Wallet', true, 'log', 'cancelClicked'); this.props.t.Interface.WalletCancelWithdraw(this.props.t, item.id) }} type="button" className={this.props.t.styles['cancel']}><IconStatic t={this.props.t} name='close' /> {this.props.t.Interface.getText(this.props.t, 'basic', 'cancel')}</button>
                                                            </div>) :
                                                            (<div className={this.props.t.styles['btnStatus']}>
                                                                <button type="button" className={this.props.t.styles[item.status]}>{this.props.t.Interface.getText(this.props.t, 'wallet', item.status)}</button>
                                                            </div>)}
                                                    </div>
                                                </div>
                                                <div className={this.props.t.styles['itemDate']}>
                                                    <span>{this.props.t.Interface.localeDate(item.date, true, this.props.t)}</span>
                                                </div>
                                            </div>
                                            <div className={[this.props.t.styles['itemContent'], typeof this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + item.id] !== "undefined" && this.props.t.state.Wallet.log.open[this.props.t.state.Wallet.log.type + '_' + item.id] ? this.props.t.styles['open'] : ''].join(' ')}>
                                                <ul>
                                                    <li><span>{this.props.t.Interface.getText(this.props.t, 'wallet', 'txAmount')} :</span><span>{item.amount} <IconStatic t={this.props.t} name='rub' /></span></li>
                                                    <li><span>{this.props.t.Interface.getText(this.props.t, 'wallet', 'txDate')} :</span><span>{this.props.t.Interface.localeDate(item.date, false, this.props.t)}</span></li>
                                                    {this.props.t.state.Wallet.log.type === 4 ? (<li><span>{this.props.t.Interface.getText(this.props.t, 'wallet', 'purse')}: </span><span>{this.props.t.Interface.getFormattedWallet(item.wallet, item.type, this.props.t)}</span></li>) : (<></>)}
                                                </ul>
                                            </div>
                                        </div>
                                    ))

                            }
                        </div>
                    </div>
                    {Math.ceil(this.props.t.state.Wallet.log.total / this.props.t.state.Wallet.log.perPage) > 1 ?
                        (<div className={this.props.t.styles['pager']}>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Wallet', this.props.t.state.Wallet.log.currentPage - 1, 'log', 'currentPage'); this.props.t.Interface.WalletGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Wallet.log.currentPage + 1 > 1 ? false : true}><span><IconStatic t={this.props.t} name='left' />{this.props.t.state.width <= 900 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'prevPage')}</span></button>
                            <div className={this.props.t.styles['inputBlock']}>
                                <div className={this.props.t.styles['formField']}>
                                    <input onChange={(a) => { this.switchPage(a.target.value) }} className={this.props.t.styles['inputField']} value={this.props.t.state.Wallet.log.currentPage + 1}></input>
                                </div>{this.props.t.Interface.getText(this.props.t, 'basic', 'of')} {Math.ceil(this.props.t.state.Wallet.log.total / this.props.t.state.Wallet.log.perPage)}
                            </div>
                            <button onClick={() => { this.props.t.updateState(this.props.t, 'Wallet', this.props.t.state.Wallet.log.currentPage + 1, 'log', 'currentPage'); this.props.t.Interface.WalletGetLog(this.props.t) }} type="button" className={this.props.t.styles['pagerBtn']} disabled={this.props.t.state.Wallet.log.currentPage + 1 < Math.ceil(this.props.t.state.Wallet.log.total / this.props.t.state.Wallet.log.perPage) ? false : true}><span>{this.props.t.state.width <= 900 ? "" : this.props.t.Interface.getText(this.props.t, 'basic', 'nextPage')} <IconStatic t={this.props.t} name='left' /></span></button>
                        </div>) : (<></>)}
                </div>
            </>
        )
    }
}

export default WalletDeposit;