import React from 'react';
import Icon from '../components/Icon';
import Svg from '../components/Svg';

export default class Noty extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.noty.data], [this.pState.noty.data]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        let t = this, offset = [];
        this.props.t.state.noty.data.forEach(function (item, i) {
            offset[i] = 0;

            t.props.t.state.noty.data.forEach(function (item, j) {
                if (j < i)
                    offset[i] += parseInt(item.message.length / 20) ? parseInt(item.message.length / 20) - 1 : 0;
            });
        });

        return (
            <div className={this.props.t.styles['notyBlock']}>
                {this.props.t.state.noty.data.map((item, i) => (
                    <div key={item.id} className={[this.props.t.styles['notyItem'], item.visible ? this.props.t.styles['show'] : ''].join(' ')} style={{ transform: 'translateY(' + (5 + 5 * i + 53 * i + offset[i] * 14) + 'px) translateX(-50%)' }}>
                        <div className={this.props.t.styles['notyWrap']}>
                            <aside className={this.props.t.styles['notyAside']}>
                                <Svg style={{ width: 20 + 'px', heigth: 20 + 'px' }} t={this.props.t} name={"noty-" + item.type} />
                            </aside>
                            <div className={this.props.t.styles['notyTitle']}>
                                {item.title}
                            </div>
                            <div className={this.props.t.styles['notyMessage']}>
                                {item.message}
                            </div><button onClick={(ev) => (this.props.t.Interface.closeNoty(this.props.t, item.id))} className={this.props.t.styles['notyClose']} type="button"><Icon t={this.props.t} name="close" /></button>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}