import React from 'react';
import defaultPosterPlaceholder from '../../assets/png/Landing/posterPlaceholder.png';

class StaticLobbyGames extends React.Component {
    constructor(props) {
        super(props);
        this.staticItems = [];

        for (let i = this.props.t.config.static.lobby.games; i > 0; i--) {
            this.staticItems.push(true)
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <div className={[this.props.t.styles['gamesList'], this.props.t.styles['static']].join(' ')}>
                {this.staticItems.map((id, index) => (
                    <div key={index} className={this.props.t.styles['item']}>
                        <div className={this.props.t.styles['gameWrapper']}>
                            <div className={this.props.t.styles['gameBlock']}>
                                <div style={{ backgroundImage: ' url(' + defaultPosterPlaceholder + ')' }} className={this.props.t.styles['imgBlock']}></div>

                            </div>
                            <div className={this.props.t.styles['gameDescription']}>
                                <h2 className={this.props.t.styles['title']}><span></span></h2>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default StaticLobbyGames;