import React from 'react';
import IconStatic from '../../components/IconStatic';

import Code from '../../assets/png/Modal/code.png'
import { Tooltip } from 'reactstrap';

class Passwordbonus extends React.Component {
    constructor(props) {
        super(props);
        this.inputID = "inputBonus-" + this.props.t.Interface.randomString(16);
    }

    state = {
        recaptcha: {
            ready: false,
            token: false
        }
    };

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        window.captchaOnLoad = this.captchaOnLoad.bind(this);

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";
        this.recaptchaLoader.appendChild(script);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.bonus, this.props.t.state.form.bonus, this.props.t.state.lang], [this.pState.bonus, this.pState.form.bonus, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    componentWillUnmount() {
        document.getElementById('gb-recaptcha').parentNode.removeChild(document.getElementById('gb-recaptcha'));

        this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "bonus", "code");
    }

    captchaOnLoad() {
        const widget = document.createElement('div');
        widget.id = 'gb-recaptcha';
        widget.style.visibility = 'hidden';
        document.body.appendChild(widget);

        window.grecaptcha.render('gb-recaptcha', {
            sitekey: '6Lfg4PAUAAAAABijupZzyCKqOoZ4JyHtQLXq_oa_',
            size: 'invisible'
        });

        window.grecaptcha.ready(() => {
            this.setState({ recaptcha: { token: this.state.recaptcha.token, ready: true } });
        })
    }

    getCaptcha(t, callback) {
        if (t.state.recaptcha.ready)
            window.grecaptcha.execute({ action: 'promo/get/code' }).then(token => { t.setState({ recaptcha: { token, ready: t.state.recaptcha.ready } }); callback(true); });
    }

    useCode() {
        this.getCaptcha(this, () => {
            this.props.t.Interface.ModalBonusCodeUse(this.props.t, this.state.recaptcha.token);
            this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
        });
    }

    handleUseCode(event) {
		if (event.key === "Enter" && !this.props.t.state.form.bonus.code.error && this.props.t.state.form.bonus.code.data.length) 
			this.useCode();
	}

    render() {
        return (
            <div className={this.props.t.styles['bonusContainer']}>
                <div className={this.props.t.styles['bonusHeader']}>
                    {this.props.t.Interface.getText(this.props.t, 'bonus', 'title')}
                </div>
                <div className={this.props.t.styles['bonusContent']}>

                    <div className={this.props.t.styles['alert']}>
                        <div className={this.props.t.styles['alertWrapper']}>
                            <div className={this.props.t.styles['emoji']}>
                                <img src={Code} width="32" height="32" alt="bonuscode" draggable="false" />
                            </div>
                            <div className={this.props.t.styles['textWrap']}>{this.props.t.Interface.getText(this.props.t, 'bonus', 'promoEveryDay')}<span className={this.props.t.styles['link']}>
                                <IconStatic t={this.props.t} name="vk" /><a href={this.props.t.config.social.vk.link} rel="noopener noreferrer nofollow" target="_blank">{this.props.t.config.social.vk.name}</a></span></div>
                        </div>
                    </div>

                    <div className={this.props.t.styles['inputRow']}>
                        <input onKeyPress={(event) => { this.handleUseCode(event) }} placeholder={this.props.t.Interface.getText(this.props.t, 'bonus', 'enterPromoCode')} id={this.inputID} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.bonus.code.error }, "bonus", "code"); this.props.t.Interface.checkForm(this.props.t, "bonus", "code"); }} autoComplete="none" className={[this.props.t.styles['inputField'], this.props.t.state.form.bonus.code.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="code" value={this.props.t.state.form.bonus.code.data}></input>
                        <Tooltip isOpen={this.props.t.state.form.bonus.code.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target={this.inputID}>
                            {this.props.t.state.form.bonus.code.error}
                        </Tooltip>
                    </div>
                    <div className={this.props.t.styles['bonusBottom']}>
                        <button onClick={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'cancel')}</button>
                        <button disabled={this.props.t.state.bonus.process || this.props.t.state.form.bonus.code.error || !this.props.t.state.form.bonus.code.data.length} onClick={() => { this.useCode() }} className={this.props.t.styles['btn']} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'activate')}</button>
                    </div>
                </div>
                <div ref={div => (this.recaptchaLoader = div)}></div>
            </div>
        );
    }
}

export default Passwordbonus;
