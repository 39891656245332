import React from 'react';

import { Redirect } from "react-router-dom";

import Loader from '../assets/svg/loader.svg';

export default class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.type = typeof document.location.pathname.split('/')[2] !== "undefined" ? document.location.pathname.split('/')[2] : false;
        switch (this.type) {
            case 'vk':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1] : false;
                break;
            case 'fb':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1] : false;
                break;
            case 'ok':
                this.token = typeof document.location.href.split('#')[1] !== "undefined" && typeof document.location.href.split('#')[1].split('=')[1] !== "undefined" ? document.location.href.split('#')[1].split('=')[1].split('&')[0] : false;
                break;
            case 'gl':
                this.token = typeof document.location.href.split('?')[1] !== "undefined" && typeof document.location.href.split('?')[1].split('=')[1] !== "undefined" ? document.location.href.split('?')[1].split('=')[1].split('&')[0] : false;
                break;
            default:
                this.token = false;
                break;
        }

        if (this.type && this.props.t.state.userID)
            this.props.t.updateState(this.props.t, 'auth', true, 'link');
    }

    componentDidMount() {
        if (this.props.t.state.userID && this.token && this.type)
            this.props.t.Interface.doSocialNetworkLink(this.props.t, this.type, this.token);
        else if (this.token && this.type)
            this.props.t.Interface.doSocialNetworkLogin(this.props.t, this.type, this.token);

        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate(nextProps, nextState) {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.userID, this.props.t.state.mainRedirect, this.props.t.state.auth, this.props.t.state.lang], [this.pState.userID, this.pState.mainRedirect, this.pState.auth, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                {!this.type || !this.token || (this.props.t.state.userID && !this.props.t.state.auth.link) || this.props.t.state.mainRedirect ? (this.props.t.updateState(this.props.t, 'mainRedirect', false), <Redirect from="/*" to={this.props.t.state.auth.redirect} />) : <></>}
                <div className={this.props.t.styles['socialAuthSection']}>
                    <div className={this.props.t.styles['block']}>
                        <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
                        <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'auth', 'pleaseWait')}</div>
                    </div>
                </div>
            </>
        )
    }
}