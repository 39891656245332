module.exports = {
  system: { code: 'zh', name: '中国人' },
  aml: { title: '打击洗钱', description: '安全控制', content: '' },
  auth: {
    accountCreated: '账户已注册',
    agreeText: '我确认我已经 18 岁了我已经阅读',
    agreeTos: '服务条款',
    alreadyRegistered: '已经注册了？',
    byLogin: '通过登录名和密码',
    bySocialNetworks: '通过社交网络',
    createAccount: '创建账户',
    forgotPassword: '忘记密码？',
    loading: '正在加载...',
    login: '登录',
    loginTitle: '站点授权',
    maxLoginLength: '最大登录长度：',
    maxPasswordLength: '最大密码长度：',
    minLoginLength: '最小登录长度：',
    minPasswordLength: '最小密码长度：',
    passwordConfirmation: '重复密码',
    passwordsMustBeSame: '密码必须匹配',
    pleaseWait: '请稍等...',
    registration: '注册',
    registrationTitle: '现场注册',
    symbols: '人物',
    yourLogin: '你的登录',
    yourPassword: '你的密码',
    yourEmail: '你的邮箱',
    logoutConfirm: '你真的想出去吗？',
    passwordRecovery: '重置密码',
    newPassword: '新密码',
    copied: '已复制',
    logout: '出'
  },
  basic: {
    yes: 'Da',
    no: '不行',
    confirm: '肯定',
    confirmed: '被提高',
    cancel: '废除',
    sending: '正在发送...',
    sent: '提交',
    save: '保存',
    create: '创建',
    or: '要么',
    activate: '激活',
    deactivate: '停用',
    prevPage: '上一页',
    nextPage: '下一页',
    of: '出',
    balance: '余额',
    copy: '复制',
    copied: '已复制'
  },
  bonus: {
    permanentBonus: '永久奖金',
    tenPercent: '每笔存款 10％！',
    more: '阅读更多',
    fastRegistration: '快速入住',
    noVerification: '没有验证',
    fastWithdraws: '快速支付',
    highLimits: '高限制',
    loyaltyProgramm: '忠诚度计划',
    winCashback: '每次胜利最多可获得 1.2％！',
    levelSystem: '关卡系统',
    bigPercents: '大百分比',
    payForWin: '为胜利付钱',
    butNoBets: '不是为了投注',
    affiliateProgramm: '联盟计划',
    join: '加入',
    revenueShare: '40％ 的收入份额',
    anytimeWithdraw: '随时输出',
    affiliateCabinet: '加盟机构柜',
    detailStat: '详细的统计',
    title: '促銷代碼',
    promoEveryDay: '我們 VK 組中每天的促銷代碼',
    enterPromoCode: '輸入促銷代碼'
  },
  fair: {
    title: '许可证和诚实保证',
    description: '我们保证每个人都能公平竞争',
    fairDescription1: 'Brillx Casino 了解创建安全环境以便用户对我们游戏的诚实性有信心是多么重要。',
    fairDescription2: '因此，在我们的赌场，我们使用由开发在线赌场软件的顶级公司提供的游戏。其中包括这样的公司',
    fairDescription3: '作为 ecoGRAA 和 IGC 的创始成员，Microgaming 领先于其他赌场游戏制造商，为整个赌博行业设定了最高标准，尤其是在线游戏诚实、选择最负责任的运营商以及保护玩家利益方面。',
    andOther: '和其他',
    also: '我们还提供公司创造的游戏',
    defaultCasino: '普通的在线赌场',
    brillxCasino: 'Brillx 诚实控制赌场',
    defaultCasinoDescription1: '你下注写给赌场服务器',
    defaultCasinoDescription2: '您的数据被收集并存储在赌场服务器上',
    defaultCasinoDescription3: '检查游戏诚实是不可能的-所有算法都被记录并保存在赌场边',
    defaultCasinoDescription4: '要获得胜利，你必须经过漫长的授权程序',
    defaultCasinoDescription5: '赌场的偿付能力存在问题，因为其余额没有在任何地方公布',
    brillxCasinoDescription1: '玩家博彩提供商的数据副本保存在区块链中，无法更改',
    brillxCasinoDescription2: '人类的游戏统计数据经过加密并记录在区块链中，不受玩家个人数',
    brillxCasinoDescription3: '完整性控制通过区块链技术完成',
    brillxCasinoDescription4: '提款需要 KYC 标准授权，无转账费',
    brillxCasinoDescription5: '开放报告和巨大的玩家支出池'
  },
  game: {
    loading: '加载',
    lobby: '大厅',
    fullScreen: '全屏',
    deposit: '存款',
    betsAmount: '投注金額',
    totalRTP: '總 RTP',
    averageBet: '平均投注',
    topWin: '頂級勝利'
  },
  help: {
    title: '帮助和支持',
    description: '最常见问题的答案以及与客户服务沟通。',
    connectTypes: '与我们沟通的方法',
    operatorsOnline: '每天 24 小时在线运营商',
    supportLive: '支持（实时）',
    onlineChat: '在线聊天',
    averageTimeChat: '平均响应时间：10 分钟',
    supportEmail: '客户服务（电子邮件）',
    averageTimeEmail: '平均响应时间：1 天',
    generalQuestions: '一般问题',
    aboutSite: '关于该网站',
    aboutSiteContent: 'Brillx 是一家拥有国际许可证和名称的在线赌场。我们在目录中提供 6,000 多款游戏、各种体育赛事和许多迷你游戏。我们支持许多支付系统和大多数流行的加密货币。',
    bonus: '奖金',
    bonuses: '奖金',
    onPage: '在页面上',
    manyFreeBonuses: '有几种方法可以完全免费获得余额！',
    bonus1: '注册奖金。在网站上注册后，您有 60 分钟的时间输入邀请码并获得最多 10.00 卢布。',
    bonus2: '注册奖金。在网站上注册后，您有 60 分钟的时间输入邀请码并获得最多 10.00 卢布。',
    bonus3: '注册奖金。在网站上注册后，您有 60 分钟的时间输入邀请码并获得最多 10.00 卢布。',
    bonus4: '注册奖金。在网站上注册后，您有 60 分钟的时间输入邀请码并获得最多 10.00 卢布。',
    promocodes: '促销代码',
    inviteFriend: '邀请朋友',
    balanceRefilling: '余额补充',
    wallet: '钱包',
    howToRefill: '我该如何补充余额？',
    commissions: '佣金',
    periodOfRefilling: '注册条款',
    withdrawal: '取款',
    howToWithdraw: '我该如何提取资金？',
    periodOfWithdrawal: '提款时间表',
    clickToRefill: '点击按钮补充余额',
    atRightCorner: '位于网站的右上角。选择方便您的付款方式，输入存款金额，然后点击转到付款。',
    minimalRefilling: '最低补货',
    commissionsOnPage: '页面上列出了所有付款方式的佣金',
    clickToWithdraw: '点击按钮从您的账户中提取资金',
    averageTimeWithdraw: '所有调查结果都会在申请之日起平均一小时内处理完毕。',
    allTransactions: '所有交易都会立即处理。只有在补充余额的付款方式有问题时，才会出现延迟。',
    inCaseOfProblems: '如果有问题，请联系我们的客户服务，并提供有关您的问题的最详细信息。',
    atRightCornerWithdraw: '位于网站的右上角。选择方便您的提款方式，输入金额，然后点击转到付款。',
    minimalWithdrawal: '最低提款',
    ifBigAmount: '如果提款金额很大，提款可以持续一点时间，通常长达一个小时。晚上（MSC 上午 00:00 至 09:00），调查结果可能会持续几个小时，付款汇总商处理更长时间的提款请求。',
    sometimes: '在极少数情况下，由于银行负载等原因，提款可能会长达 24 小时。如果款项在此时间之后没有到达，请联系我们的客户服务，并提供有关您问题的最详细信息。',
    checkCorrectPurse: '还要确保正确指定输出的详细信息。为了让你指向提款的钱包放心，你需要点击你想要的交易进入交易历史记录和打开详细信息。'
  },
  interface: {
    months: {
      jan: '1 月。',
      feb: '2 月',
      mar: '三月。',
      apr: '4 月。',
      may: 'mai',
      jun: '君。',
      jul: '七月。',
      aug: '八月。',
      sep: '9 月。',
      oct: '10 月。',
      nov: '十一月。',
      dec: '十二月。'
    },
    year: '年',
    confirmPasswordRecovery: '按照发送到您的电子邮件的链接确认密码重置',
    passwordWasChanged: '密码已成功更改，您可以登录以使用新数据',
    confirmEmail: '用链接确认电子邮件地址',
    emailWasConfirmed: '电子邮件地址已成功确认',
    accountLinked: '账户已成功关联',
    bonusGranted: '已收到奖金'
  },
  landing: {
    newGames: '新游戏',
    topGames: '热门游戏',
    brillxSelect: 'Brillx 的选择',
    daylyLiveRtp: '实时 RTP-24 小时',
    brillxCasino: 'Brillx 赌场',
    casinoInfo1: '在这里查看各种激动人心的游戏 Brillx.GG，这是一个为您满意度量身定制的老虎机网站。世界各地的玩家都可以使用数以千计的一流老虎机。无论是您需要的巨额累积奖金、扑克老虎机还是主题老虎机，我们都能为您提供所需的一切！',
    casinoInfo2: '立即点击办理入住按钮，体验快速办理登机手续的轻松！当你玩来自领先游戏提供商的真钱老虎机时，享受时刻。',
    casinoInfo3: '我们的赌场拥有快速的支出和各种各样的老虎机变体供您 24/7 全天候工作-只需选择一款游戏然后开始玩！',
    casinoInfo4: '在线玩老虎机',
    casinoInfo5: '我们永远不会破坏你的乐趣，所以你无需在电脑上下载任何东西就可以玩我们的所有游戏。',
    casinoInfo6: '浏览数千个老虎机，选择你喜欢的游戏，然后点击 “播放”-太简单了！',
    casinoInfo7: '赌场老虎机游戏是最受欢迎的游戏之一。不仅是因为你可以获得巨大的激励，还因为令人兴奋的图形、良好的音乐以及游戏的整体愉快的视觉效果。',
    casinoInfo8: '你甚至可以通过切换到娱乐模式来尝试我们的每台老虎机-这是一个惊人的功能，它让你无需下载而且无需注册即可免费玩我们的所有老虎机！你可以通过手机、电脑或平板电脑访问它。今天发现一些最好的老虎机，无需下载即可免费玩，并在全球最好的赌场老虎机中体验独特的冒险。今天就拿你最好的镜头，让命运决定在等着你的什么！',
    casinoInfo9: '你在等什么？今天可能是你的幸运日！',
    casinoInfo10: '成千上万的老虎机',
    casinoInfo11: '以下是你可以在我们的赌场找到的不同类型的老虎机：',
    casinoInfo12: '3 个卷轴插槽',
    casinoInfo13: '3 个卷轴插槽最有名的是经典或传统的老虎机。卷轴是你可以在游戏中找到的垂直线条，其中符号是级联的。如果你正在寻找快感，请尝试玩 3 卷轴老虎机！你可以玩的一些游戏是炼金术之书、沃特福德 FC 老虎机和许多其他游戏。',
    casinoInfo14: '5 个卷轴插槽',
    casinoInfo15: '通过玩 5 卷轴插槽获得更好的机会赢取组合！每当 3 个、4 个或 5 个字符落在卷轴上时，大多数 5 个卷轴插槽都需要支付。您还可以根据游戏获得胜利乘数、奖金游戏回合或免费旋转。你可以玩的 5 个卷轴游戏中的一些是维京人 Go Berzerk、死亡的遗产和那是富人。',
    casinoInfo16: '渐进式插槽',
    casinoInfo17: '渐进式老虎机在所有类型的老虎机中都很重要。随着游戏的进展，累积奖金大小增加。有数百万人在等着你，你可以成为迄今为止最富有的人之一！',
    casinoInfo18: '轮盘赌',
    casinoInfo19: '轮盘赌是一款在桌子、颜色、数字类型或组合上的个人数字下注的游戏。测试你的运气，如果你投注的东西击中了轮子，就赢了。立即玩我们精彩的实时轮盘赌游戏！',
    casinoInfo20: '扑克',
    casinoInfo21: '如果你熟悉普通赌场的扑克，你可以通过利用我们广泛的在线视频扑克游戏现在尝试玩它。准备好今天体验难忘的游戏吧！有了高清动画和精彩的特殊视频回合，你一定会回来赢得新的胜利。',
    casinoInfo22: '百家乐',
    casinoInfo23: '百家乐是世界上最受欢迎的游戏之一。通过投注最接近 9 的手牌来击败庄家。此外，如果有平局，你也可以赢。听起来令人兴奋，真的？现在准备好你的赌注并投注在赢钱方面！',
    casinoInfo24: '二十一点',
    casinoInfo25: '在二十一点中，如果你的手牌接近 21 点，你可以击败庄家。当你玩我们雄伟的二十一点老虎机时，有机会加倍赌注！',
    gamesProviders: '游戏提供商',
    showMore: '显示更多',
    hideInfo: '隐藏信息',
    allGames: '所有游戏',
    providers: '供应商',
    new: '新',
    tournaments: '锦标赛',
    slots: '老虎机',
    roulette: '轮盘赌',
    card: '卡',
    videopoker: '视频扑克',
    lottery: '乐透'
  },
  live: { demo: '演示', play: '播放', title: 'Live' },
  lobby: {
    providers: '供应商',
    recent: '最近',
    top: '受欢迎',
    new: '新',
    favorite: '收藏夹',
    nothingFound: '找不到',
    play: '播放',
    demo: '演示',
    forExample: '例如'
  },
  log: { date: '约会', type: '操作', amount: '金额', id: 'ID' },
  loyalty: {
    title: '忠诚度计划',
    description: '获得现金返还，这取决于每次投注的忠诚度水平',
    cabinet: '内阁',
    myCabinet: '我的办公室',
    payLog: '累积日志',
    log: 'Logue',
    level: '等级',
    totalWins: '中奖金额',
    totalBets: '投注金额',
    availableBalance: '可用余额',
    get: '带走',
    cashback: '现金返还',
    emptyHistory: '故事丢失了',
    operationID: '操作 ID',
    amount: '金额',
    date: '约会',
    operationDate: '运行日期',
    operationType: '操作',
    balanceBefore: '平衡',
    balanceAfter: '之后的余额'
  },
  menu: {
    affProgramm: '伙伴',
    fairGame: '公平竞争',
    free: '免费',
    games: '游戏',
    lobby: '大厅',
    promo: '促销',
    loyaltyProgramm: '忠诚度',
    promotions: '股票',
    support: '服务台',
    settings: '设置',
    history: '游戏历史',
    more: '更多',
    profile: '普罗菲尔',
    bonusCode: '促銷代碼'
  },
  noty: { error: '错误', success: '成功的' },
  profile: {
    wallet: '钱包',
    onSiteFrom: '在现场使用',
    totalBets: '投注金额',
    betsCount: '投注',
    totalWins: '中奖金额',
    bestWin: '最好的胜利',
    emptyHistory: '故事丢失了',
    balanceAfter: '之后的余额'
  },
  partner: {
    maxCodeLength: '最大代码长度：',
    minCodeLength: '最小代码长度：',
    maxNameLength: '最大名称长度：',
    minNameLength: '名字的最小长度：',
    symbols: '人物',
    confirmDeleteCampgain: '你真的想删除广告活动吗？',
    campgains: '活动',
    refresh: '升级',
    export: '出口',
    campgainName: '活动名称',
    affiliateLink: '加盟链接',
    name: '标题',
    creationDate: '创建日期',
    clicks: '过渡',
    link: '参考',
    manage: '管理',
    noCampgains: '没有活跃的广告活动',
    namePlaceholder: '要在面板中显示的名称',
    general: '普通的',
    mainAffiliateLink: '主要会员链接',
    registrations: '注册',
    totalProfit: '普通利润',
    availableBalance: '可用余额',
    get: '带走',
    emptyHistory: '故事丢失了',
    operationID: '操作 ID',
    amount: '金额',
    date: '约会',
    operationDate: '运行日期',
    operationType: '操作',
    playerID: '玩家 ID',
    balanceAfter: '之后的余额',
    balanceBefore: '平衡',
    log: '累积日志',
    players: '玩家',
    registration: '注册',
    registrationDate: '注册日期',
    balance: '余额',
    noPlayers: '没有来宾玩家',
    totalBets: '投注金额',
    totalDeps: '存款金额',
    totalWithdraws: '调查结果总',
    registrationSource: '注册来源',
    mainLink: '主链接',
    player: '玩家',
    registrationsCount: '注册',
    bets: '标桩',
    profit: '利润',
    stat: '统计',
    totalStat: '总体统计',
    partnerCabinet: '合作伙伴办公室',
    partnerID: '合作伙伴 ID'
  },
  privacy: {
    title: '隐私政策',
    description: '一般规定和信息',
    redaction: '编辑者',
    content: ''
  },
  promo: {
    linkVk: '绑定 VK 账户以获得奖金',
    completeAll: '适合所有条件并获得',
    toBalanceForRepost: '平衡地重新发布！',
    link: '绑定',
    join: '进入',
    allowNotifications: '允许通知',
    make: '做',
    toProfileVkAccount: '转到 VK 账户资料。',
    toOurVkGroup: '加入我们的 VK 组。',
    fromOurGroup: '来自我们的社区。',
    repostToYourPage: '将条目重新发布到他的页面。',
    mustBePublic: '你的 VK 页面必须是公开的，否则我们将无法检查是否重新发布。',
    dontDelete: '如果您在重新发布后 2 周内删除帖子，奖金金额将从余额中扣除。',
    joinAndGet: '加入群组然后获得',
    toBalance: '平衡地说！',
    bonusGranted: '成功收到奖金！',
    momentalBonus: '加入我们的 VK 群组，即时获得余额奖金！VK 个人资料必须是公开的，否则我们将无法检查订阅。',
    vkDoesntLinked: 'VK 账户未绑定',
    allowNotificationsAndGet: '允许来自我们社区的通知并获取',
    sendAnyMessage: '向我们的 VK 群组发送任何消息，允许发送通知。一个不错的惊喜-我们会定期发送有关促销和各种奖金的信息！',
    rollAndGetBonus: '巡游你的轮盘赌并获得奖金！',
    rollOncePer: '每 15 分钟旋转轮盘一次然后直到',
    rollEveryDay: '每天不受限制地旋转轮子！',
    untilNextTry: '在下一次尝试结束之前',
    subscribeTg: '注册我们的电报频道，首先获得新的促销代码！',
    beFirst: '成为第一批获得新促销代码的人之一，了解新的奖金和创新！',
    referrals: '推荐',
    getPercent: '邀请朋友并赚取 40％ 的赌场福利！',
    copyLink: '复制链接',
    inviteFriend: '邀请朋友',
    getBonus: '获得奖金',
    affiliateProgramm: '联盟计划',
    sendReview: '提供关于我们小组中项目的反馈并获取',
    bonusToDeposit: '存款奖金',
    upTo: '高达',
    makeReviews: '在我们的 VK 群组中留下反馈，主持人验证并发布后，奖金将自动在您的账户上激活。',
    makeReview: '反馈',
    ranks: '然伊',
    earnFromWin: '赚取等级你将从每次获胜中获得更多的现金返还。',
    loyaltyProgramm: '忠诚度计划',
    writeMessage: '写一条消息',
    goToGroup: '转到组',
    refreshing: '更新...',
    refreshStatus: '升级',
    roll: '旋转',
    linkAccount: '绑定账户',
    checking: '正在检查...',
    get: '获取'
  },
  promotions: {
    title: '股票和优惠',
    description: '千万不要错过获得我们当前股票奖金的机会',
    game: '游戏',
    minDeposit: '分。存款',
    bonus: '奖金',
    addWager: '额外的赌注',
    validUntil: '最多行动',
    activation: '激活',
    get: '获取',
    unlimit: '无限的',
    oneTime: '一次性',
    forEveryDeposit: '每笔存款',
    permanentBonus: '永久奖金',
    any: '任何'
  },
  responsibility: { title: '责任的游戏', description: '负责任的游戏提示和规则', content: '' },
  settings: {
    incorrectEmail: '邮件地址错误',
    bigFileSize: '文件大小不应超过 5 MB。',
    genders: { man: '男装', female: '女士的', other: '其他' },
    requiredField: '必填字段',
    dataSuccessChanged: '数据已成功更新',
    tfaLoginRequired: '设置登录名和密码以设置身份验证器',
    general: '普通的',
    login: '登录',
    vkProfile: 'VK 个人资料',
    glProfile: '谷歌资料',
    okProfile: '个人资料同学',
    fbProfile: 'Facebook 个人资料',
    security: '安全',
    alterLogin: '向您的账户添加另一种登录和密码方法。',
    newPassword: '新密码',
    repeatPassword: '重复密码',
    saveActions: '保存操作',
    currentPassword: '当前密码',
    tfa: '双因素身份验证',
    addTfa: '通过额外的安全保护你的 Brillx 帐户',
    tfaSettings: '2FA 设置',
    sessions: '会话',
    device: '设备',
    region: '地区',
    ipAddress: 'IP 地址',
    date: '约会',
    session: '会话',
    active: '活跃',
    closed: '已关闭',
    verification: '验证',
    verificationDescription1: ' 为了提高您的服务级别，我们要求识别文件。如果您的账户被恢复，这将确保账户的安全，并将提供准确的有针对性的实物礼物交付。',
    verificationDescription2: '可接受的验证方法是提供身份证、驾驶执照或护照的照片。完成此过程后，我们将通过电子邮件通知您。',
    frontPhoto: '身份证件正面的照片',
    selectFile: '选择文件',
    backPhoto: '身份证件的背面照片',
    fileIsNotSelected: '未选择文件',
    name: '姓名',
    surname: '姓',
    birthday: '出生日期',
    gender: '性别',
    country: '国家/地区',
    city: '城市',
    yourName: '你的名字',
    yourSurname: '你的姓',
    verifyStatus1: '正在检查文件...',
    verifyStatus2: '账户已验证',
    verifyStatus3: '文档检查错误',
    verifyDescription1: '文件目前正在审查中，一旦文件经过验证，您的账户就会被验证！',
    verifyDescription2: '您的数据已经由安全人员验证和确认。',
    verifyDescription3: '请联系技术支持以了解详情。',
    registrationCity: '注册城市',
    sendDocs: '提交以供检查',
    affiliateCabinet: '合作伙伴办公室'
  },
  tos: {
    title: '用户协议',
    description: '使用条款和规则',
    redaction: '编辑者',
    content: ''
  },
  wallet: {
    deposit: '存款',
    withdraw: '输出',
    history: '历史',
    minDepositAmount: '最低存款通过',
    minWithdrawAmount: '通过的最低输出',
    topMethods: '受欢迎',
    allMethods: '所有方法',
    qiwi: 'QIWI',
    ym: 'Yoomoney',
    card: 'Card',
    payeer: 'Payeer',
    crypto: 'Crypto',
    bitcoin: 'Bitcoin',
    usdt: 'Tether',
    mobile: 'Mobile',
    megafon: 'Megafon',
    mts: 'MTS',
    beeline: 'Beeline',
    tele2: 'Tele 2',
    pm: 'Perfect Money',
    terminal: 'Terminal (RF)',
    advcash: 'AdvCash',
    fk: 'FK Wallet',
    steam: 'Steam',
    litecoin: 'Litecoin',
    ethereum: 'Ethereum',
    bitcoin_cash: 'BCH',
    dash: 'Dash',
    dogecoin: 'Dogecoin',
    monero: 'Monero',
    zcash: 'ZCash',
    usdt_erc: 'ERC20',
    usdt_trc: 'TRC20',
    bnb: 'BNB',
    ripple: 'Ripple',
    waves: 'Waves',
    other: '其他',
    success: '成功的',
    canceled: '已取消',
    error: '错误',
    waiting: '等着',
    processing: '申请正在处理中',
    incorrectWalletValue: '钱包编号输入错误',
    incorrectCardValue: '信用卡号输入错误',
    selectTemplate: '选择模板',
    withdrawCreated: '已创建支付应用程序，将在 24 小时内处理',
    withdrawCanceled: '支付出价已被取消',
    firstWithdraw: '这是你的第一个结论',
    isDataCorrect: '你有信心输入数据是正确的吗？',
    payMethod: '付款方式',
    withdrawMethod: '提款方法',
    selectMethod: '选择一种方法',
    selectOperator: '选择运营商',
    refillAmount: '补货',
    withdrawAmount: '提款金额',
    payTroubles: '如果你在付款时遇到困难试试',
    payHere: '在这里支付',
    pay: '前往付款',
    commission: '佣金',
    onePayLimit: '单次补货限额',
    oneWithdrawLimit: '单个输出限制',
    emptyHistory: '故事丢失了',
    operationID: '操作 ID',
    deposits: '补充',
    withdraws: '发现',
    txAmount: '交易金额',
    txDate: '运行日期',
    purse: '得分',
    typePurse: '账号',
    toGet: '要接收',
    createWithdraw: '创建应用程序'
  },
  tfa: {
    tfa: '双因素身份验证',
    typeYourPassword: '输入你的密码',
    enterPin: '从应用程序输入 6 位数代码',
    downloadApp: '下载应用',
    downloadAndInstall: '下载并安装',
    forYourPhone: '为你的手机或平板电脑',
    scanQR: '扫描二维码',
    scanQRWithCamera: '用手机的相机扫描左侧的 QR',
    openWithLink: '通过链接打开',
    openInApp: '在应用中打开',
    tfaManualEnter: '2FA 键（手动输入）'
  },
  pwa: {
    androidStep1: '打開 Chrome 瀏覽器',
    androidStep1Description: '如果您使用任何其他瀏覽器，請在 Chrome 中打開此頁面',
    androidStep2: '點擊瀏覽器菜單',
    androidStep2Description: '瀏覽器菜單是瀏覽器右上角的省略號',
    androidStep3: '選擇“添加到主屏幕”',
    androidStep3Description: '您將看到一個包含信息的窗口（“添加到主屏幕”），您必須在其中選擇“添加”，然後應用程序將被添加到您設備的桌面',
    androidAlert: '步驟可能因瀏覽器版本而異',
    iosStep1: '打開 Safari 瀏覽器',
    iosStep1Description: '如果您使用任何其他瀏覽器，請在 Safari 中打開此頁面',
    iosStep2: '點擊“分享”',
    iosStep2Description: '點擊屏幕下方的“分享”按鈕，打開參數選擇窗口',
    iosStep3: '選擇“添加到主屏幕”',
    iosStep3Description: '選擇“添加到主屏幕”並點擊“添加”',
    alert: '步驟可能因瀏覽器版本而異',
    installApp: '安裝 Brillx 應用程序',
    back: '返回',
    app: '移動應用程序',
    installAppForMobile: '為 iOS 和 Android 安裝新的方便的 Brillx 應用程序',
    install: '安裝'
  },
  undefined: '???'
};