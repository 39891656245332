import React from 'react';
import IconStatic from '../components/IconStatic';

import BonusCodeModal from './modals/BonusCode';

export default class MobileNav extends React.Component {

    constructor(props) {
        super(props);

        if (window.matchMedia('(display-mode: standalone)').matches)
            this.isStandalone = true;
        else
            this.isStandalone = false;
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.location, this.props.t.state.RightBar.open, this.props.t.state.MobileNav, this.props.t.state.width], [this.pState.location, this.pState.RightBar.open, this.pState.MobileNav, this.pState.width]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['mobileNav']}>
                    <div className={[this.props.t.styles['mobileNavPullOut'], this.props.t.state.MobileNav.open ? this.props.t.styles['open'] : ''].join(' ')}>
                        <button type="button" className={this.props.t.styles['close']} onClick={() => { this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open') }}><IconStatic t={this.props.t} name="close" /></button>
                        <ul className={this.props.t.styles['mobileNavPullOutNav']}>
                            <li className={window.location.pathname === "/promotions" || this.props.t.state.location === "promotions" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.doRoute('/promotions'); this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open'); }}><div><IconStatic t={this.props.t} name="star" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'promotions')}</button></li>
                            <li><button type="button" onClick={() => this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: BonusCodeModal, className: this.props.t.styles['bonusModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/promo')}><div><IconStatic t={this.props.t} name="tasks" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'bonusCode')}</button></li>
                            <li className={window.location.pathname === "/help" || this.props.t.state.location === "help" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.doRoute('/help'); this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open'); }}><div><IconStatic t={this.props.t} name="help-circle" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'support')}</button></li>
                            <li className={window.location.pathname === "/fair" || this.props.t.state.location === "fair" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.doRoute('/fair'); this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open'); }}><div><IconStatic t={this.props.t} name="fairness" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'fairGame')}</button></li>
                            <li className={window.location.pathname === "/loyalty" || this.props.t.state.location === "loyalty" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.state.userID ? this.props.t.doRoute('/loyalty') && this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open') : this.props.t.Interface.requireAuth(this.props.t, false, '/loyalty'); }}><div><IconStatic t={this.props.t} name="briefcase" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'loyaltyProgramm')}</button></li>
                            <li className={window.location.pathname === "/partner" || this.props.t.state.location === "partner" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.state.userID ? this.props.t.doRoute('/partner') && this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open') : this.props.t.Interface.requireAuth(this.props.t, false, '/partner'); }}><div><IconStatic t={this.props.t} name="network" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'affProgramm')}</button></li>
                        </ul>
                    </div>
                    <div className={this.props.t.styles['mobileNavWrapper']}>
                        <ul className={[this.props.t.styles['mobileNavBlock'], this.isStandalone ? this.props.t.styles['standalone'] : ''].join(' ')}>
                            <li className={window.location.pathname === "/lobby" || this.props.t.state.location === "lobby" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.updateState(this.props.t, 'RightBar', false, 'open'); this.props.t.doRoute('/lobby'); }}><div><IconStatic t={this.props.t} name="gamepad" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'lobby')}</button></li>
                            <li className={window.location.pathname === "/promo" || this.props.t.state.location === "promo" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.updateState(this.props.t, 'RightBar', false, 'open'); this.props.t.state.userID ? this.props.t.doRoute('/promo') : this.props.t.Interface.requireAuth(this.props.t, false, '/promo'); }}><div><IconStatic t={this.props.t} name="faucet" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'promo')}</button></li>
                            <li className={window.location.pathname === "/profile" || this.props.t.state.location === "profile" ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.updateState(this.props.t, 'RightBar', false, 'open'); this.props.t.state.userID ? this.props.t.doRoute('/profile') : this.props.t.Interface.requireAuth(this.props.t, false, '/profile'); }}><div><IconStatic t={this.props.t} name="bets" /></div>{this.props.t.Interface.getText(this.props.t, 'menu', 'profile')}</button></li>
                            <li className={this.props.t.state.RightBar.open ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.updateState(this.props.t, 'MobileNav', false, 'open'); this.props.t.updateState(this.props.t, 'RightBar', !this.props.t.state.RightBar.open, 'open') }} data-action="chat"><div><IconStatic t={this.props.t} name="stats" /></div>Live</button></li>
                            <li className={this.props.t.state.MobileNav.open ? this.props.t.styles['active'] : ''}><button type="button" onClick={() => { this.props.t.updateState(this.props.t, 'RightBar', false, 'open'); this.props.t.updateState(this.props.t, 'MobileNav', !this.props.t.state.MobileNav.open, 'open') }}><div><IconStatic t={this.props.t} name="more" /></div><span>{this.props.t.Interface.getText(this.props.t, 'menu', 'more')}</span></button></li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

}