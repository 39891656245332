import React from 'react';
import { Tooltip } from 'reactstrap';

class PasswordRecovery extends React.Component {
    constructor(props) {
        super(props);
        this.inputID = "inputRecovery-" + this.props.t.Interface.randomString(16);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.recovery, this.props.t.state.form.recovery, this.props.t.state.lang], [this.pState.recovery, this.pState.form.recovery, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <div className={this.props.t.styles['recoveryContainer']}>
                <div className={this.props.t.styles['recoveryHeader']}>
                    {this.props.t.Interface.getText(this.props.t, 'auth', 'passwordRecovery')}
                </div>
                <div className={this.props.t.styles['recoveryContent']}>
                    <div className={this.props.t.styles['inputRow']}>
                        <input placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourEmail')} id={this.inputID} onChange={(a) => { this.props.t.updateState(this.props.t, "form", { data: a.target.value, error: this.props.t.state.form.recovery.email.error }, "recovery", "email"); this.props.t.Interface.checkForm(this.props.t, "recovery", "email"); }} autocomplete="none" className={[this.props.t.styles['inputField'], this.props.t.state.form.recovery.email.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="email" value={this.props.t.state.form.recovery.email.data}></input>
                        <Tooltip isOpen={this.props.t.state.modalSecond.show && this.props.t.state.form.recovery.email.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target={this.inputID}>
                            {this.props.t.state.form.recovery.email.error}
                        </Tooltip>
                    </div>
                    <div className={this.props.t.styles['recoveryBottom']}>
                        <button onClick={() => { this.props.t.updateState(this.props.t, 'modalSecond', false, 'show') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'basic', 'cancel')}</button>
                        <button disabled={this.props.t.state.recovery.process} onClick={() => { this.props.t.Interface.ModalRecoveryPassword(this.props.t) }} className={this.props.t.styles['btn']} type="button">{this.props.t.state.recovery.process ? this.props.t.Interface.getText(this.props.t, 'basic', 'sending') : this.props.t.Interface.getText(this.props.t, 'basic', 'sent')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordRecovery;
