module.exports = {
    system: { code: 'id', name: 'Bahasa Indonesia' },
    aml: {
        title: 'Menanggung pencucian uang',
        description: 'Kontrol keselamatan',
        content: ''
    },
    auth: {
        accountCreated: 'Akun terdaftar',
        agreeText: 'Saya mengkonfirmasi bahwa saya berusia 18 tahun dan saya telah membaca',
        agreeTos: 'persyaratan layanan',
        alreadyRegistered: 'Sudah terdaftar?',
        byLogin: 'Dengan login dan kata sandi',
        bySocialNetworks: 'Melalui jejaring sosial',
        createAccount: 'Buat akun',
        forgotPassword: 'Lupa kata sandi Anda?',
        loading: 'Memuat...',
        login: 'Login',
        loginTitle: 'Otorisasi Situs',
        maxLoginLength: 'Panjang login maksimum:',
        maxPasswordLength: 'Panjang Kata Sandi Maksimum:',
        minLoginLength: 'Panjang login minimum:',
        minPasswordLength: 'Panjang Kata Sandi Minmal:',
        passwordConfirmation: 'Ulangi kata sandi',
        passwordsMustBeSame: 'Sandi harus cocok',
        pleaseWait: 'Mohon tunggu...',
        registration: 'Registrasi',
        registrationTitle: 'Mendaftar di situs',
        symbols: 'karakter',
        yourLogin: 'Login Anda',
        yourPassword: 'Kata sandi Anda',
        yourEmail: 'Email Anda',
        logoutConfirm: 'Apakah Anda benar-benar ingin keluar?',
        passwordRecovery: 'Setel ulang kata sandi',
        newPassword: 'Kata sandi baru',
        copied: 'Disalin',
        logout: 'Keluar'
    },
    basic: {
        yes: 'Ya',
        no: 'Nope',
        confirm: 'Menegaskan',
        confirmed: 'Upvoted',
        cancel: 'Membatalkan',
        sending: 'Mengirim...',
        sent: 'Mengajukan',
        save: 'Simpan',
        create: 'Buat',
        or: 'atau',
        activate: 'Aktifkan',
        deactivate: 'Nonaktifkan',
        prevPage: 'Halaman sebelumnya',
        nextPage: 'Halaman berikutnya',
        of: 'keluar',
        balance: 'Saldo',
        copy: 'Salin',
        copied: 'Disalin'
    },
    bonus: {
        permanentBonus: 'Bonus permanen',
        tenPercent: '10% untuk setiap deposit!',
        more: 'Baca lebih lanjut',
        fastRegistration: 'Check-in cepat',
        noVerification: 'tanpa verifikasi',
        fastWithdraws: 'Pembayaran cepat',
        highLimits: 'batas tinggi',
        loyaltyProgramm: 'Program Loyalitas',
        winCashback: 'Hingga 1,2% pada setiap kemenangan!',
        levelSystem: 'Sistem tingkat',
        bigPercents: 'Persentase besar',
        payForWin: 'Bayar untuk menang',
        butNoBets: 'bukan untuk taruhan',
        affiliateProgramm: 'Program Afiliasi',
        join: 'Bergabung',
        revenueShare: '40% Bagi Pendapatan',
        anytimeWithdraw: 'output setiap saat',
        affiliateCabinet: 'Kabinet Afiliasi',
        detailStat: 'statistik terperinci',
        title: 'Kode Promo',
        promoEveryDay: 'Kode promo setiap hari di grup VK kami',
        enterPromoCode: 'Masukkan kode promo'
    },
    fair: {
        title: 'Lisensi & Jaminan Kejujuran',
        description: 'Kami menjamin fair play untuk semua orang',
        fairDescription1: 'Brillx Casino memahami betapa pentingnya menciptakan lingkungan keamanan sehingga pengguna yakin akan kejujuran permainan kami.',
        fairDescription2: 'Oleh karena itu, di kasino kami, kami menggunakan permainan yang disediakan oleh perusahaan top mengembangkan perangkat lunak untuk kasino online. Diantaranya perusahaan seperti',
        fairDescription3: 'Sebagai anggota pendiri eCogra dan IGC, Mycrogaming adalah kepala di depan produsen game kasino lainnya, menetapkan standar tertinggi dalam industri perjudian secara keseluruhan, terutama untuk kejujuran game online, memilih operator yang paling bertanggung jawab, dan melindungi kepentingan pemain.',
        andOther: 'dan lain-lain',
        also: 'Kami juga menyediakan game yang dibuat oleh perusahaan',
        defaultCasino: 'Kasino online biasa',
        brillxCasino: 'Brillx Kejujuran Kontrol Casino',
        defaultCasinoDescription1: 'Anda membuat taruhan yang ditulis ke server kasino',
        defaultCasinoDescription2: 'Data Anda dikumpulkan dan disimpan di server kasino',
        defaultCasinoDescription3: 'Memeriksa kejujuran permainan tidak mungkin — semua algoritma dicatat dan disimpan di sisi kasino',
        defaultCasinoDescription4: 'Untuk mendapatkan kemenangan, Anda harus melalui prosedur otorisasi yang panjang',
        defaultCasinoDescription5: 'Solvabilitas kasino yang dimaksud sebagai keseimbangan tidak dipublikasikan di mana saja',
        brillxCasinoDescription1: 'Salinan data dari penyedia taruhan pemain disimpan di blockchain dan tidak dapat diubah',
        brillxCasinoDescription2: 'Statistik permainan untuk manusia dienkripsi dan dicatat dalam blockchain yang dilepaskan ke data pribadi pemain',
        brillxCasinoDescription3: 'Kontrol integritas dilakukan dengan teknologi blockchain',
        brillxCasinoDescription4: 'Penarikan memerlukan otorisasi standar KYC, tanpa biaya transfer',
        brillxCasinoDescription5: 'Pelaporan terbuka dan kolam besar untuk pembayaran pemain'
    },
    game: {
        loading: 'Memuat',
        lobby: 'Lobi',
        fullScreen: 'Layar penuh',
        deposit: 'Deposit',
        betsAmount: 'Jumlah Taruhan',
        totalRTP: 'Total RTP',
        averageBet: 'Taruhan rata-rata',
        topWin: 'Kemenangan teratas'
    },
    help: {
        title: 'Bantuan & Dukungan',
        description: 'Jawaban atas pertanyaan dan komunikasi yang paling umum dengan layanan pelanggan.',
        connectTypes: 'Metode untuk berkomunikasi dengan kami',
        operatorsOnline: 'Operator online 24 jam sehari',
        supportLive: 'Dukungan (Live)',
        onlineChat: 'Chat langsung',
        averageTimeChat: 'Waktu respons rata-rata: 10 menit',
        supportEmail: 'Layanan Pelanggan (E-Mail)',
        averageTimeEmail: 'Waktu respon rata-rata: 1 hari',
        generalQuestions: 'Masalah umum',
        aboutSite: 'Tentang situs',
        aboutSiteContent: 'Brillx adalah sebuah kasino online dengan lisensi internasional dan nama. Kami menawarkan lebih dari 6.000 game di katalog, berbagai acara olahraga, dan banyak permainan mini. Kami mendukung banyak sistem pembayaran dan sebagian besar kripto yang populer.',
        bonus: 'Bonus',
        bonuses: 'Bonus',
        onPage: 'Pada halaman',
        manyFreeBonuses: 'ada beberapa cara untuk mendapatkan uang untuk saldo Anda benar-benar gratis!',
        bonus1: 'Bonus pendaftaran. Setelah mendaftar di situs, Anda memiliki waktu 60 menit untuk memasukkan kode undangan dan mendapatkan hingga 10.00 RUB.',
        bonus2: 'Bonus pendaftaran. Setelah mendaftar di situs, Anda memiliki waktu 60 menit untuk memasukkan kode undangan dan mendapatkan hingga 10.00 RUB.',
        bonus3: 'Bonus pendaftaran. Setelah mendaftar di situs, Anda memiliki waktu 60 menit untuk memasukkan kode undangan dan mendapatkan hingga 10.00 RUB.',
        bonus4: 'Bonus pendaftaran. Setelah mendaftar di situs, Anda memiliki waktu 60 menit untuk memasukkan kode undangan dan mendapatkan hingga 10.00 RUB.',
        promocodes: 'Kode promo',
        inviteFriend: 'Undang teman',
        balanceRefilling: 'Pengisian saldo',
        wallet: 'Dompet',
        howToRefill: 'Bagaimana cara mengisi saldo?',
        commissions: 'Komisi',
        periodOfRefilling: 'Ketentuan pendaftaran',
        withdrawal: 'Penarikan',
        howToWithdraw: 'Bagaimana cara menarik dana?',
        periodOfWithdrawal: 'Timeline penarikan',
        clickToRefill: 'Klik pada butang untuk menambah baki',
        atRightCorner: 'di sudut kanan atas situs. Pilih metode pembayaran yang sesuai untuk Anda, masukkan jumlah deposit, dan klik Buka Pembayaran.',
        minimalRefilling: 'Pengisian minimum',
        commissionsOnPage: 'Komisi dari semua metode pembayaran tercantum pada halaman',
        clickToWithdraw: 'Klik tombol untuk menarik uang dari akun Anda',
        averageTimeWithdraw: 'Semua temuan diproses rata-rata satu jam dari saat aplikasi.',
        allTransactions: 'Semua transaksi diproses secara instan. Penundaan hanya dapat terjadi jika Anda memiliki masalah dengan metode pembayaran Anda mengisi saldo Anda.',
        inCaseOfProblems: 'Jika ada masalah, silakan hubungi layanan pelanggan kami dan memberikan informasi paling rinci tentang masalah Anda.',
        atRightCornerWithdraw: 'di sudut kanan atas situs. Pilih metode penarikan yang sesuai untuk Anda, masukkan jumlahnya, dan klik Buka Pembayaran.',
        minimalWithdrawal: 'Minimal penarikan',
        ifBigAmount: 'Jika jumlah penarikannya besar, penarikannya bisa sedikit lebih lama, biasanya sampai satu jam. Pada malam hari (00:00 pagi sampai 09:00 MSC), temuan dapat berlangsung selama beberapa jam, karena pada saat itu agregator pembayaran memproses permintaan penarikan lebih lama.',
        sometimes: 'Dalam kasus yang sangat jarang, penarikan bisa naik hingga 24 jam, karena pemuatan bank dan sebagainya. Jika uang tidak tiba setelah waktu itu, silakan hubungi layanan pelanggan kami dan berikan informasi paling rinci tentang masalah Anda.',
        checkCorrectPurse: 'Juga pastikan Anda menentukan rincian untuk output dengan benar. Untuk meyakinkan dompet Anda menunjuk ke penarikan, Anda perlu masuk ke riwayat transaksi Anda dan membuka rincian dengan mengklik transaksi yang Anda inginkan.'
    },
    interface: {
        months: {
            jan: 'jan.',
            feb: 'feb.',
            mar: 'mar.',
            apr: 'apr.',
            may: 'mai',
            jun: 'jun.',
            jul: 'jul.',
            aug: 'agu.',
            sep: 'sep.',
            oct: 'okt.',
            nov: 'nov.',
            dec: 'des.'
        },
        year: 'g.',
        confirmPasswordRecovery: 'Konfirmasikan pengaturan ulang kata sandi dengan mengikuti tautan yang dikirim ke email Anda',
        passwordWasChanged: 'Sandi berhasil diubah, Anda dapat masuk untuk menggunakan data baru',
        confirmEmail: 'Konfirmasi alamat email melalui tautan',
        emailWasConfirmed: 'Alamat email telah berhasil dikonfirmasi',
        accountLinked: 'Akun berhasil ditautkan',
        bonusGranted: 'Bonus yang diterima'
    },
    landing: {
        newGames: 'Permainan baru',
        topGames: 'Permainan populer',
        brillxSelect: 'Pilihan Brillx',
        daylyLiveRtp: 'Hidup RTP - 24 jam',
        brillxCasino: 'Brillx kasino',
        casinoInfo1: 'Periksa berbagai permainan menarik di sini di Brillx.GG, situs mesin slot yang dibuat khusus untuk kepuasan Anda. Ribuan mesin slot kelas satu tersedia untuk pemain di seluruh dunia. Apakah itu jackpot besar, slot poker atau mesin slot bertema yang Anda butuhkan, kami memiliki semua yang anda butuhkan!',
        casinoInfo2: 'Nikmati kemudahan check-in cepat dengan mengklik tombol check-in sekarang! Nikmati saat-saat ketika Anda bermain mesin slot uang riil dari penyedia game terkemuka.',
        casinoInfo3: 'Kasino kami dengan pembayaran cepat dan pilihan besar dari segala macam variasi mesin slot bekerja 24/7 untuk Anda - hanya memilih permainan dan mulai bermain!',
        casinoInfo4: 'Bermain slot secara online',
        casinoInfo5: 'Kami tidak pernah merusak kesenangan Anda, sehingga Anda dapat memainkan semua game kami tanpa mengunduh apa pun di komputer Anda.',
        casinoInfo6: 'Jelajahi ribuan slot, pilih permainan yang Anda suka, dan klik “PLAY” - sangat mudah!',
        casinoInfo7: 'Slot permainan kasino adalah salah satu permainan yang paling populer. Bukan hanya karena insentif besar yang bisa Anda dapatkan, tapi juga karena grafis yang menarik, musik yang bagus, dan efek visual yang menyenangkan secara keseluruhan dari permainan.',
        casinoInfo8: 'Anda bahkan dapat mencoba setiap mesin slot kami dengan beralih ke mode hiburan - fitur menakjubkan yang memungkinkan Anda memainkan semua mesin slot kami tanpa men-download dan tanpa Pendaftaran gratis! Anda dapat mengaksesnya melalui ponsel, komputer, atau tablet Anda. Temukan beberapa yang terbaik mesin slot hari ini bahwa Anda dapat bermain secara gratis tanpa perlu men-download, dan mengalami petualangan yang unik di slot kasino terbaik di seluruh dunia. Ambil tembakan terbaikmu hari ini, dan biarkan takdir memutuskan apa yang menanti Anda!',
        casinoInfo9: 'Apa yang kau tunggu? Hari ini bisa menjadi hari keberuntunganmu!',
        casinoInfo10: 'Ribuan mesin slot',
        casinoInfo11: 'Berikut adalah berbagai jenis mesin slot yang dapat Anda temukan di kasino kami:',
        casinoInfo12: '3 slot gulungan',
        casinoInfo13: '3 slot gulungan paling dikenal sebagai mesin slot klasik atau tradisional. Gulungan adalah garis vertikal yang dapat Anda temukan dalam permainan di mana simbol-simbol yang mengalir. Jika Anda sedang mencari sensasi, cobalah memainkan 3 mesin slot gulungan! Beberapa permainan Anda dapat bermain adalah Kitab Alkimia, Watford FC Slot dan banyak lainnya.',
        casinoInfo14: '5 slot gulungan',
        casinoInfo15: 'Dapatkan kesempatan yang lebih baik untuk memenangkan kombinasi dari memainkan slot 5 gulungan! paling 5 slot gulungan dibayarkan setiap kali 3, 4, atau 5 karakter jatuh pada gulungan. Anda juga bisa mendapatkan pengganda menang, putaran bonus game, atau bebas berputar tergantung pada permainan. Beberapa 5 permainan gulungan Anda dapat bermain adalah Viking Go Berzerk, Legacy of Mati, dan Itu Kaya.',
        casinoInfo16: 'Slot progresif',
        casinoInfo17: 'Slot progresif adalah tipu muslihat di antara semua jenis mesin slot. Ukuran jackpot meningkat sebagai permainan berlangsung. Ada jutaan besar menunggu Anda, dan Anda bisa menjadi salah satu orang terkaya sampai saat ini!',
        casinoInfo18: 'Roulette',
        casinoInfo19: 'Roulette adalah permainan yang bertaruh pada nomor individu di atas meja, warna, jenis nomor, atau kombinasi. Uji keberuntungan Anda dan menang jika apa yang Anda bertaruh pada hits roda. Bermain game roulette real-time besar kami sekarang!',
        casinoInfo20: 'Poker',
        casinoInfo21: 'Jika Anda akrab dengan poker di kasino biasa, Anda dapat mencoba memainkannya sekarang dengan memanfaatkan berbagai pilihan permainan video poker online kami. Bersiaplah untuk mengalami permainan yang tak terlupakan hari ini! Dengan animasi definisi tinggi dan putaran video spesial yang hebat, Anda pasti akan kembali untuk menang baru.',
        casinoInfo22: 'Baccarat',
        casinoInfo23: 'Baccarat adalah salah satu permainan paling populer di dunia. Kalahkan dealer dengan bertaruh di tangan yang paling dekat dengan 9. Juga, Anda juga bisa menang jika ada hasil imbang. Kedengarannya menarik, sungguh? Siapkan taruhan Anda sekarang dan bertaruh di pihak yang menang!',
        casinoInfo24: 'Blackjack',
        casinoInfo25: 'Dalam blackjack, Anda bisa mengalahkan dealer jika Anda memiliki tangan paling dekat dengan 21. Dapatkan kesempatan untuk menggandakan taruhan Anda ketika Anda bermain mesin slot blackjack megah kami!',
        gamesProviders: 'Penyedia Game',
        showMore: 'Tampilkan lebih',
        hideInfo: 'Sembunyikan informasi',
        allGames: 'Semua permainan',
        providers: 'Penyedia',
        new: 'Baru',
        tournaments: 'Bonus Buy',
        slots: 'Slot',
        roulette: 'Roulette',
        card: 'Kartu',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Mainkan', title: 'Live' },
    lobby: {
        providers: 'Penyedia',
        recent: 'Terkini',
        top: 'Populer',
        new: 'Baru',
        favorite: 'Favorit',
        nothingFound: 'Tidak ditemukan',
        play: 'PEMENTASAN',
        demo: 'DEMO',
        forExample: 'Sebagai contoh'
    },
    log: { date: 'Kencan', type: 'Operasi', amount: 'Jumlah', id: 'ID' },
    loyalty: {
        title: 'Program Loyalitas',
        description: 'Menerima cashback yang tergantung pada tingkat loyalitas pada setiap taruhan yang ditempatkan',
        cabinet: 'Kabinet',
        myCabinet: 'Kantor saya',
        payLog: 'Log Akrual',
        log: 'Logue',
        level: 'Tingkat',
        totalWins: 'Jumlah kemenangan',
        totalBets: 'Jumlah taruhan',
        availableBalance: 'Saldo yang tersedia',
        get: 'Ambil',
        cashback: 'Cashback',
        emptyHistory: 'Ceritanya hilang',
        operationID: 'ID Operasi',
        amount: 'Jumlah',
        date: 'Kencan',
        operationDate: 'Tanggal operasi',
        operationType: 'Operasi',
        balanceBefore: 'Saldo up',
        balanceAfter: 'Saldo setelah'
    },
    menu: {
        affProgramm: 'Mitra',
        fairGame: 'Bermain adil',
        free: 'Gratis',
        games: 'Permainan',
        lobby: 'Lobi',
        promo: 'Promo',
        loyaltyProgramm: 'Loyalitas',
        promotions: 'Saham',
        support: 'Helpdesk',
        settings: 'Pengaturan',
        history: 'Riwayat permainan',
        more: 'Lebih',
        profile: 'Profil',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Galat', success: 'Sukses' },
    profile: {
        wallet: 'Dompet',
        onSiteFrom: 'Di situs dengan',
        totalBets: 'Jumlah taruhan',
        betsCount: 'Taruhan',
        totalWins: 'Jumlah kemenangan',
        bestWin: 'Kemenangan terbaik',
        emptyHistory: 'Ceritanya hilang',
        balanceAfter: 'Saldo setelah'
    },
    partner: {
        maxCodeLength: 'Panjang kode maksimum:',
        minCodeLength: 'Panjang kode minimum:',
        maxNameLength: 'Panjang nama maksimum:',
        minNameLength: 'Panjang minimum nama:',
        symbols: 'karakter',
        confirmDeleteCampgain: 'Apakah Anda benar-benar ingin menghapus kampanye?',
        campgains: 'Kampanye',
        refresh: 'Meningkatkan',
        export: 'Ekspor',
        campgainName: 'Nama kampanye',
        affiliateLink: 'Tautan afiliasi',
        name: 'Judul',
        creationDate: 'Tanggal dibuat',
        clicks: 'Transisi',
        link: 'Ref.',
        manage: 'Manajemen',
        noCampgains: 'Tidak ada kampanye iklan aktif',
        namePlaceholder: 'Nama yang akan ditampilkan dalam panel',
        general: 'Umum',
        mainAffiliateLink: 'Tautan afiliasi utama',
        registrations: 'Registrasi',
        totalProfit: 'Keuntungan umum',
        availableBalance: 'Saldo yang tersedia',
        get: 'Ambil',
        emptyHistory: 'Ceritanya hilang',
        operationID: 'ID Operasi',
        amount: 'Jumlah',
        date: 'Kencan',
        operationDate: 'Tanggal operasi',
        operationType: 'Operasi',
        playerID: 'ID Pemain',
        balanceAfter: 'Saldo setelah',
        balanceBefore: 'Saldo up',
        log: 'Log Akrual',
        players: 'Pemain',
        registration: 'Registrasi',
        registrationDate: 'Tanggal pendaftaran',
        balance: 'Saldo',
        noPlayers: 'Tidak ada pemain tamu',
        totalBets: 'Jumlah taruhan',
        totalDeps: 'Jumlah setoran',
        totalWithdraws: 'Temuan jumlah',
        registrationSource: 'Sumber registrasi',
        mainLink: 'Tautan utama',
        player: 'Pemain',
        registrationsCount: 'Registrasi',
        bets: 'Mengintai',
        profit: 'Profit',
        stat: 'Statistik',
        totalStat: 'Statistik keseluruhan',
        partnerCabinet: 'Kantor Mitra',
        partnerID: 'ID Mitra'
    },
    privacy: {
        title: 'Kebijakan Privasi',
        description: 'Ketentuan Umum dan Informasi',
        redaction: 'Editorial oleh',
        content: ''
    },
    promo: {
        linkVk: 'Mengikat akun VK untuk mendapatkan bonus',
        completeAll: 'Sesuai dengan semua kondisi dan dapatkan',
        toBalanceForRepost: 'pada keseimbangan untuk repost!',
        link: 'Binding',
        join: 'Entri',
        allowNotifications: 'Izinkan pemberitahuan',
        make: 'Lakukan',
        toProfileVkAccount: 'ke profil akun VK.',
        toOurVkGroup: 'ke grup VK kami.',
        fromOurGroup: 'dari komunitas kami.',
        repostToYourPage: 'repost entri ke halamannya.',
        mustBePublic: 'Halaman VK Anda harus publik atau kami tidak akan dapat memeriksa repost.',
        dontDelete: 'Jika Anda menghapus posting lebih awal dari 2 minggu setelah repost, jumlah bonus akan dipotong dari saldo.',
        joinAndGet: 'Bergabunglah dengan grup dan dapatkan',
        toBalance: 'pada keseimbangan!',
        bonusGranted: 'Bonus berhasil diterima!',
        momentalBonus: 'Bergabunglah dengan grup VK kami dan dapatkan bonus instan pada saldo Anda! Profil VK harus bersifat publik, atau kami tidak akan dapat memeriksa langganan.',
        vkDoesntLinked: 'Akun VK Tidak Terikat',
        allowNotificationsAndGet: 'Izinkan pemberitahuan dari komunitas kami dan dapatkan',
        sendAnyMessage: 'Kirim pesan ke grup VK kami, yang memungkinkan pemberitahuan dikirim. Kejutan yang menyenangkan - secara berkala kami akan mengirim informasi tentang promosi dan berbagai bonus!',
        rollAndGetBonus: 'Cruise roulette Anda dan mendapatkan bonus!',
        rollOncePer: 'Memutar roulette sekali setiap 15 menit dan mendapatkan sampai',
        rollEveryDay: 'Putar roda tanpa batasan setiap hari!',
        untilNextTry: 'Sebelum usaha berikutnya dibiarkan',
        subscribeTg: 'Daftar ke saluran telegram kami untuk mendapatkan kode promo baru terlebih dahulu!',
        beFirst: 'Jadilah salah satu yang pertama mendapatkan kode promo baru, pelajari tentang bonus dan inovasi baru!',
        referrals: 'Referal',
        getPercent: 'Mengundang teman dan mendapatkan 40% manfaat kasino!',
        copyLink: 'Salin tautan',
        inviteFriend: 'Undang teman',
        getBonus: 'Dapatkan bonus',
        affiliateProgramm: 'Program Afiliasi',
        sendReview: 'Umpan balik pada proyek dalam kelompok kami dan dapatkan',
        bonusToDeposit: 'bonus deposit',
        upTo: 'upto',
        makeReviews: 'Tinggalkan umpan balik di grup VK kami, setelah moderator diverifikasi dan diposting, bonus akan diaktifkan secara otomatis di akun Anda.',
        makeReview: 'Masukan',
        ranks: 'Rangi',
        earnFromWin: 'Mendapatkan peringkat Anda akan menerima peningkatan cashback dari setiap kemenangan.',
        loyaltyProgramm: 'Program Loyalitas',
        writeMessage: 'Menulis pesan',
        goToGroup: 'Pergi ke grup',
        refreshing: 'Perbarui...',
        refreshStatus: 'Meningkatkan',
        roll: 'Putar',
        linkAccount: 'Mengikat akun',
        checking: 'Memeriksa...',
        get: 'Dapatkan'
    },
    promotions: {
        title: 'Saham & Penawaran',
        description: 'Jangan lewatkan kesempatan untuk mendapatkan bonus dalam saham kami saat ini',
        game: 'Permainan',
        minDeposit: 'Min. deposit',
        bonus: 'Bonus',
        addWager: 'Taruhan ekstra',
        validUntil: 'Bertindak sampai',
        activation: 'Aktivasi',
        get: 'Dapatkan',
        unlimit: 'tak terbatas',
        oneTime: 'satu kali',
        forEveryDeposit: 'untuk setiap deposit',
        permanentBonus: 'Bonus permanen',
        any: 'setiap'
    },
    responsibility: {
        title: 'Permainan yang bertanggung jawab',
        description: 'Tips dan Aturan Permainan Bertanggung Jawab',
        content: ''
    },
    settings: {
        incorrectEmail: 'Alamat email salah',
        bigFileSize: 'Ukuran file tidak boleh melebihi 5 MB.',
        genders: { man: 'Pria', female: 'Perempuan', other: 'Liyan' },
        requiredField: 'Bidang yang dibutuhkan',
        dataSuccessChanged: 'Data telah berhasil diperbarui',
        tfaLoginRequired: 'Atur login dan kata sandi untuk menyiapkan authenticator',
        general: 'Umum',
        login: 'Login',
        vkProfile: 'Profil VK',
        glProfile: 'Profil Google',
        okProfile: 'Profil Teman sekelas',
        fbProfile: 'Profil Facebook',
        security: 'Keselamatan',
        alterLogin: 'Tambahkan metode alternatif login dan kata sandi ke akun Anda.',
        newPassword: 'Kata sandi baru',
        repeatPassword: 'Ulangi kata sandi',
        saveActions: 'Simpan Tindakan',
        currentPassword: 'Kata sandi saat ini',
        tfa: 'Otentikasi Dua Faktor',
        addTfa: 'Lindungi akun Brillx Anda dengan keamanan ekstra',
        tfaSettings: 'Setelan 2FA',
        sessions: 'Sesi',
        device: 'Perangkat',
        region: 'Wilayah',
        ipAddress: 'Alamat IP',
        date: 'Kencan',
        session: 'Sesi',
        active: 'Aktif',
        closed: 'Tutup',
        verification: 'Verifikasi',
        verificationDescription1: ' Untuk meningkatkan tingkat layanan Anda, kami meminta untuk mengidentifikasi dokumen. Ini akan menjaga akun Anda aman jika dipulihkan, dan akan memberikan pengiriman hadiah fisik yang ditargetkan secara akurat.',
        verificationDescription2: 'Cara verifikasi yang dapat diterima adalah dengan memberikan foto identitas, SIM, atau paspor Anda. Setelah proses ini selesai, kami akan memberitahu Anda melalui email.',
        frontPhoto: 'Foto bagian depan dokumen identitas',
        selectFile: 'Pilih berkas',
        backPhoto: 'Foto belakang dokumen identitas',
        fileIsNotSelected: 'Berkas tidak dipilih',
        name: 'Nama',
        surname: 'Nama keluarga',
        birthday: 'Tanggal Lahir',
        gender: 'Gender',
        country: 'Negara',
        city: 'Kota',
        yourName: 'Nama Anda',
        yourSurname: 'Nama belakangmu',
        verifyStatus1: 'Dokumen sedang diperiksa...',
        verifyStatus2: 'Akun Diverifikasi',
        verifyStatus3: 'Kesalahan Pemeriksaan Dokumen',
        verifyDescription1: 'Dokumen saat ini ditinjau, segera setelah dokumen diverifikasi, akun Anda akan diverifikasi!',
        verifyDescription2: 'Data Anda telah diverifikasi dan dikonfirmasi oleh petugas keamanan.',
        verifyDescription3: 'Silahkan hubungi dukungan teknis untuk rincian.',
        registrationCity: 'Kota Pendaftaran',
        sendDocs: 'Kirim untuk pemeriksaan',
        affiliateCabinet: 'Kantor Mitra'
    },
    tos: {
        title: 'Perjanjian Pengguna',
        description: 'Syarat dan Aturan Penggunaan',
        redaction: 'Editorial oleh',
        content: ''
    },
    wallet: {
        deposit: 'Deposit',
        withdraw: 'Output',
        history: 'Sejarah',
        minDepositAmount: 'Minimum deposit melalui',
        minWithdrawAmount: 'Output minimum melalui',
        topMethods: 'Populer',
        allMethods: 'Semua metode',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Lainnya',
        success: 'Sukses',
        canceled: 'Dibatalkan',
        error: 'Galat',
        waiting: 'Menunggu',
        processing: 'Aplikasi sedang diproses',
        incorrectWalletValue: 'Nomor dompet salah dimasukkan',
        incorrectCardValue: 'Nomor kartu yang dimasukkan dengan benar',
        selectTemplate: 'Pilih template',
        withdrawCreated: 'Aplikasi pembayaran dibuat dan akan diproses dalam waktu 24 jam',
        withdrawCanceled: 'Tawaran pembayaran telah dibatalkan',
        firstWithdraw: 'Ini adalah kesimpulan pertama Anda',
        isDataCorrect: 'Apakah Anda yakin bahwa entri data sudah benar?',
        payMethod: 'Metode pembayaran',
        withdrawMethod: 'Metode penarikan',
        selectMethod: 'Pilih cara',
        selectOperator: 'Pilih operator',
        refillAmount: 'Pengisian',
        withdrawAmount: 'Jumlah penarikan',
        payTroubles: 'Jika Anda memiliki kesulitan membayar mencoba',
        payHere: 'Bayar di sini',
        pay: 'Pergi ke pembayaran',
        commission: 'Komisi',
        onePayLimit: 'Batas Pengisian Tunggal',
        oneWithdrawLimit: 'Batas output tunggal',
        emptyHistory: 'Ceritanya hilang',
        operationID: 'ID Operasi',
        deposits: 'Pengisian',
        withdraws: 'Temuan',
        txAmount: 'Jumlah transaksi',
        txDate: 'Tanggal operasi',
        purse: 'Skor',
        typePurse: 'Nomor akun',
        toGet: 'Untuk menerima',
        createWithdraw: 'Membuat aplikasi'
    },
    tfa: {
        tfa: 'Otentikasi Dua Faktor',
        typeYourPassword: 'Masukkan Kata Sandi Anda',
        enterPin: 'Masukkan 6 digit kode dari aplikasi',
        downloadApp: 'Unduh aplikasi',
        downloadAndInstall: 'Mengunduh dan menginstal',
        forYourPhone: 'untuk ponsel atau tablet',
        scanQR: 'memindai kode QR',
        scanQRWithCamera: 'Pindai QR di sebelah kiri dengan kamera ponsel',
        openWithLink: 'Buka dengan tautan',
        openInApp: 'Buka di aplikasi',
        tfaManualEnter: 'Tombol 2FA (input manual)'
    },
    pwa: {
        androidStep1: 'Buka browser Chrome',
        androidStep1Description: 'Jika Anda menggunakan browser lain, buka halaman ini di Chrome',
        androidStep2: 'Klik pada menu browser',
        androidStep2Description: 'Menu browser adalah elipsis yang terdapat di pojok kanan atas browser Anda',
        androidStep3: 'Pilih "Tambahkan ke Layar Utama"',
        androidStep3Description: 'Anda akan melihat jendela dengan informasi ("Tambahkan ke Layar Beranda"), di mana Anda harus memilih "Tambah", setelah itu aplikasi akan ditambahkan ke desktop perangkat Anda',
        androidAlert: 'Langkah mungkin berbeda tergantung pada versi browser',
        iosStep1: 'Buka browser Safari',
        iosStep1Description: 'Jika Anda menggunakan browser lain, buka halaman ini di Safari',
        iosStep2: 'Klik "Bagikan"',
        iosStep2Description: 'Klik tombol "Bagikan" di bagian bawah layar, setelah itu jendela pemilihan parameter akan terbuka',
        iosStep3: 'Pilih "Tambahkan ke Layar Utama"',
        iosStep3Description: 'Pilih "Tambahkan ke Layar Beranda" dan klik "Tambah"',
        alert: 'Langkah mungkin berbeda tergantung pada versi browser',
        installApp: 'Instal aplikasi Brillx',
        back: 'Kembali',
        app: 'Aplikasi seluler',
        installAppForMobile: 'Instal aplikasi Brillx baru yang nyaman untuk iOS dan Android',
        install: 'Pasang'
    },
    undefined: '???'
};