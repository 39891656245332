import { isMobile } from "react-device-detect";
import AuthModal from '../modules/modals/Auth';
import RegistrationModal from '../modules/modals/Registration';
import TFAAuthModal from '../modules/modals/TFAAuth';
import isEqual from 'lodash/isEqual';
import { isSafari } from 'react-device-detect';

export default new class Interface {
    getText(t, module, name, subname) {
        if (typeof t.state.lang.loaded[t.state.lang.current] === "undefined") {
            localStorage.setItem('interface.language', t.config.defaultLanguage);
            t.updateState(t, 'lang', t.config.defaultLanguage, 'current');
        }

        if (name === "content" && t.state.lang.current !== t.config.defaultLanguage)
            return t.state.lang.loaded[t.config.licenseLanguage][module] && t.state.lang.loaded[t.config.licenseLanguage][module][name] ? t.state.lang.loaded[t.config.licenseLanguage][module][name] : t.state.lang.loaded[t.config.licenseLanguage]['undefined'];
        if (typeof subname === "undefined")
            return t.state.lang.loaded[t.state.lang.current][module] && t.state.lang.loaded[t.state.lang.current][module][name] ? t.state.lang.loaded[t.state.lang.current][module][name] : t.state.lang.loaded[t.state.lang.current]['undefined'];
        else
            return t.state.lang.loaded[t.state.lang.current][module] && t.state.lang.loaded[t.state.lang.current][module][name] && t.state.lang.loaded[t.state.lang.current][module][name][subname] ? t.state.lang.loaded[t.state.lang.current][module][name][subname] : t.state.lang.loaded[t.state.lang.current]['undefined'];
    }

    requireAuth(t, type, redirect) {

        if (typeof redirect === "undefined" || !redirect)
            redirect = window.location.pathname.indexOf('/auth') === -1 ? window.location.pathname : '/';

        t.updateState(t, 'auth', redirect, 'redirect');
        localStorage.setItem('route.authRedirect', redirect);

        if (typeof type !== "undefined" && type)
            t.updateState(t, 'modal', { show: true, content: RegistrationModal, className: t.styles['authModal'] })
        else
            t.updateState(t, 'modal', { show: true, content: AuthModal, className: t.styles['authModal'] })
    }

    GoClickCode(t) {
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'campgains', method: 'click', data: { code: t.state.userRefer } });
    }

    PromoCheckStatus(t, type, subtype) {
        t.socket.emit('api', { type: 'get', class: 'promo', module: 'status', method: type, data: { authKey: t.state.authKey, type: subtype } });
    }

    PromoGetBonus(t, type, recaptchaToken, subtype) {
        t.socket.emit('api', { type: 'set', class: 'promo', module: 'get', method: type, data: { authKey: t.state.authKey, type: subtype, recaptchaToken } });
    }

    PromoCheckVkInfo(t, type) {
        t.socket.emit('api', { type: 'get', class: 'promo', module: 'info', method: type, data: { authKey: t.state.authKey } });
    }

    ProfileGetStats(t) {
        t.updateState(t, 'Profile', false, 'process');
        t.socket.emit('api', { type: 'get', class: 'profile', module: 'info', method: 'stat', data: { authKey: t.state.authKey } });
    }

    LoyaltyGetStats(t) {
        t.updateState(t, 'Loyalty', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'loyalty', module: 'info', method: 'stat', data: { authKey: t.state.authKey } });
    }

    LoyaltyGetProfit(t) {
        t.socket.emit('api', { type: 'set', class: 'loyalty', module: 'get', method: 'profit', data: { authKey: t.state.authKey } });
    }

    LoyaltyGetBalance(t) {
        t.socket.emit('api', { type: 'get', class: 'loyalty', module: 'info', method: 'balance', data: { authKey: t.state.authKey } });
    }

    PartnerGetProfit(t) {
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'get', method: 'profit', data: { authKey: t.state.authKey } });
    }

    PartnerGetBalance(t) {
        t.socket.emit('api', { type: 'get', class: 'partner', module: 'info', method: 'balance', data: { authKey: t.state.authKey } });
    }

    PartnerDeleteCampgain(t) {
        t.updateState(t, 'Partner', true, 'campgains', 'process');
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'campgains', method: 'delete', data: { authKey: t.state.authKey, code: t.state.Partner.campgains.delete } });
    }

    PartnerCreateCampgain(t) {
        t.updateState(t, 'Partner', true, 'campgains', 'process');
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'campgains', method: 'create', data: { authKey: t.state.authKey, name: t.state.form.partner.campgainName.data, code: t.state.form.partner.campgainCode.data } });
    }

    PartnerEditCampgain(t) {
        t.updateState(t, 'Partner', true, 'campgains', 'process');
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'campgains', method: 'edit', data: { authKey: t.state.authKey, currentCode: t.state.Partner.campgains.edit, name: t.state.form.partner.campgainName.data, code: t.state.form.partner.campgainCode.data } });
    }

    PartnerGetCampgains(t) {
        t.updateState(t, 'Partner', true, 'campgains', 'process');
        t.socket.emit('api', { type: 'get', class: 'partner', module: 'campgains', method: 'list', data: { authKey: t.state.authKey } });
    }

    PartnerGetStats(t, depth, code) {
        t.updateState(t, 'Partner', true, 'process');
        if (typeof code === "undefined")
            t.socket.emit('api', { type: 'get', class: 'partner', module: 'info', method: 'stat', data: { authKey: t.state.authKey, depth } });
        else
            t.socket.emit('api', { type: 'get', class: 'partner', module: 'info', method: 'stat', data: { authKey: t.state.authKey, depth, code } });
    }

    PartnerGetCode(t) {
        t.socket.emit('api', { type: 'get', class: 'partner', module: 'info', method: 'code', data: { authKey: t.state.authKey } });
    }

    PartnerSetCode(t) {
        t.socket.emit('api', { type: 'set', class: 'partner', module: 'info', method: 'code', data: { authKey: t.state.authKey, code: t.state.form.partner.code.data } });
    }

    PartnerGetUsersList(t) {
        t.updateState(t, 'Partner', true, 'usersList', 'process');
        t.socket.emit('api', { type: 'get', class: 'partner', module: 'info', method: 'users', data: { authKey: t.state.authKey, order: t.state.Partner.usersList.order, offset: t.state.Partner.usersList.currentPage } });
    }

    SettingsChangeName(t) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'name', data: { authKey: t.state.authKey, name: t.state.form.settings.name.data } });
    }

    SettingsGetRegData(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'regData', data: { authKey: t.state.authKey } });
    }

    SettingsGetVerifyData(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'verify', data: { authKey: t.state.authKey } });
    }

    SettingsSendVerifyData(t) {
        t.updateState(t, 'Settings', true, 'verifyProcess');
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'verify', data: { authKey: t.state.authKey, front: t.state.form.settings.verifyFront.data.b64, back: t.state.form.settings.verifyFront.data.b64, name: t.state.form.settings.verifyName.data, lastName: t.state.form.settings.verifyLastName.data, date: t.state.form.settings.verifyDate.data, gender: t.state.form.settings.verifyGender.data, country: t.state.form.settings.verifyCountry.data, city: t.state.form.settings.verifyCity.data } });
    }

    SettingsSetUserInfo(t) {
        t.updateState(t, 'Settings', true, 'securityProcess');
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'info', data: { authKey: t.state.authKey, login: t.state.form.settings.login.data, password: t.state.form.settings.password.data, passwordConfirm: t.state.form.settings.passwordConfirm.data, passwordCurrent: t.state.form.settings.passwordCurrent.data } });
    }

    SettingsSetEmail(t) {
        t.updateState(t, 'Settings', true, 'mailProcess');
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'email', data: { authKey: t.state.authKey, email: t.state.form.settings.email.data, lang: t.state.lang.current } });
    }

    ModalRecoveryPassword(t) {
        t.updateState(t, 'recovery', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'recovery', data: { email: t.state.form.recovery.email.data, lang: t.state.lang.current } });
    }

    ModalBonusCodeUse(t, recaptchaToken) {
        t.updateState(t, 'bonus', true, 'process');

        t.socket.emit('api', { type: 'set', class: 'promo', module: 'get', method: 'code', data: { authKey: t.state.authKey, code: t.state.form.bonus.code.data, recaptchaToken } });
    }

    SettingsGetTfa(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'tfa', data: { authKey: t.state.authKey } });
    }

    SettingsSetTfa(t) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'tfa', data: { authKey: t.state.authKey, password: t.state.form.settings.tfaPassword.data, secret: t.state.Settings.tfa.secret, pin: [t.state.form.settings.tfaPin1.data, t.state.form.settings.tfaPin2.data, t.state.form.settings.tfaPin3.data, t.state.form.settings.tfaPin4.data, t.state.form.settings.tfaPin5.data, t.state.form.settings.tfaPin6.data].join('') } });
    }

    GameGetStats(t, gameID) {
        t.socket.emit('api', { type: 'get', class: 'casino', module: 'user', method: 'stats', data: { authKey: t.state.authKey, gameID } });
    }

    LobbyGetCategoriesList(t) {
        t.socket.emit('api', { type: 'get', class: 'casino', module: 'category', method: 'list' });
    }

    LobbyGetProvidersList(t) {
        t.socket.emit('api', { type: 'get', class: 'casino', module: 'provider', method: 'list' });
    }

    LobbyGetCData(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'casino', data: { authKey: t.state.authKey } });
    }

    LobbyUpdateCData(t) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'casino', data: { authKey: t.state.authKey, recent: t.state.Lobby.recentGames, favorite: t.state.Lobby.favoriteGames } });
    }

    HeaderGetGamesList(t) {
        t.socket.emit('api', { type: 'get', class: 'casino', module: 'game', method: 'list', data: { providerID: 0 } });
    }

    HeaderGetBalance(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'balance', data: { authKey: t.state.authKey } });
    }

    HeaderGetInfo(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'info', data: { authKey: t.state.authKey } });
    }

    LoyaltyGetLog(t) {
        t.updateState(t, 'Loyalty', true, 'log', 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'log', data: { authKey: t.state.authKey, type: 6, offset: t.state.Loyalty.log.currentPage, order: t.state.Loyalty.log.order } });
    }

    PartnerGetLog(t) {
        t.updateState(t, 'Partner', true, 'log', 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'log', data: { authKey: t.state.authKey, type: 5, offset: t.state.Partner.log.currentPage, order: t.state.Partner.log.order } });
    }

    ProfileGetLog(t) {
        t.updateState(t, 'Log', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'log', data: { authKey: t.state.authKey, type: t.state.Log.type, offset: t.state.Log.currentPage, order: t.state.Log.order } });
    }

    WalletGetPayways(t) {
        t.updateState(t, 'Wallet', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'wallet', method: 'payways', data: { type: t.state.Wallet.module } });
    }

    WalletGetLog(t) {
        t.updateState(t, 'Wallet', true, 'log', 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'data', method: 'log', data: { authKey: t.state.authKey, type: t.state.Wallet.log.type, offset: t.state.Wallet.log.currentPage } });
    }

    WalletCreateDeposit(t) {
        t.updateState(t, 'Wallet', true, 'process');
        if (!t.state.form.wallet.deposit.error)
            t.socket.emit('api', { type: 'set', class: 'user', module: 'wallet', method: 'deposit', data: { authKey: t.state.authKey, type: t.state.Wallet.deposit.currentMethod, amount: t.state.form.wallet.deposit.data, origin: window.location.origin } });
    }

    WalletCheckCreateWithdraw(t, content) {
        // t.updateState(t, 'Log', true, 'process');
        if (!t.state.form.wallet.withdraw.error && !t.state.form.wallet.wallet.error) {
            let checked = false;
            t.state.Wallet.withdraw.templatesData.forEach(function (template) {
                if (template.wallet.split('+').join('').split(' ').join('').split('-').join('') === t.state.form.wallet.wallet.data.split('+').join('').split(' ').join('').split('-').join(''))
                    checked = true;
            });
            if (!checked)
                t.updateState(t, 'modalSecond', { show: true, content, className: t.styles['confirmWithdrawModal'], callback: this.WalletCreateWithdraw });
            else
                this.WalletCreateWithdraw(t);
        }

    }

    WalletCreateWithdraw(t) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'wallet', method: 'withdraw', data: { authKey: t.state.authKey, type: t.state.Wallet.withdraw.currentMethod, wallet: t.state.form.wallet.wallet.data, amount: t.state.form.wallet.withdraw.data } });
    }

    WalletGetTemplates(t) {
        t.socket.emit('api', { type: 'get', class: 'user', module: 'wallet', method: 'templates', data: { authKey: t.state.authKey } });
    }

    WalletCancelWithdraw(t, id) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'wallet', method: 'cancel', data: { id, authKey: t.state.authKey } });
    }

    LiveGetList(t) {
        if (t.state.Header.gamesList.length) {
            t.socket.emit('api', { type: 'get', class: 'casino', module: 'stat', method: 'live' });
        } else
            setTimeout(t.Interface.LiveGetList, t.config.timers.LiveBets.getListReload, t);
    }

    Ping(t) {
        t.socket.emit('api', { type: 'get', class: 'data', module: 'status', method: 'ping' });
    }

    handleSocketAPI(t) {
        let tt = this;

        t.socket.on('connect_failed', function () {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }

                caches.keys().then(function (names) {
                    for (let name of names)
                        caches.delete(name);
                });

                window.location = '/';
            });
        });

        t.socket.on("api", function (data) {

            if (typeof data.type !== "undefined" && typeof data.class !== "undefined" && typeof data.module !== "undefined" && typeof data.method !== "undefined")
                switch (data.type) {
                    case 'set':
                        switch (data.class) {
                            case 'promo':
                                switch (data.module) {
                                    case 'get':
                                        switch (data.method) {
                                            case 'code':
                                                if (data.success) {
                                                    if (data.data.gained) {
                                                        if (typeof data.data.amount !== "undefined")
                                                            tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'bonusGranted') + ' ' + tt.fixedFloat(data.data.amount) + tt.getCurrency(t), type: 'success', timer: 5 });
                                                        else
                                                            tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'bonusGranted'), type: 'success', timer: 5 });

                                                        t.updateState(t, 'modal', false, 'show');
                                                    } else
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.data.reason, type: 'error', timer: 5 });
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'bonus', false, 'process');
                                                break;
                                            case 'wheel':
                                                if (data.success) {
                                                    if (data.data.gained) {
                                                        data.data.sector = (data.data.sector / 14).toFixed(2);
                                                        t.updateState(t, 'Promo', 10, 'wheel', 'transition');
                                                        t.updateState(t, 'Promo', (1 - data.data.sector) * 360 + 360 * 7, 'wheel', 'rotate');

                                                        let date = tt.countdown(data.data.date);
                                                        if (!date)
                                                            t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                        else {
                                                            t.updateState(t, 'Promo', date, 'wheel', 'timer');
                                                            let countdownTimer = setInterval(() => {
                                                                let date = tt.countdown(data.data.date);
                                                                if (!date) {
                                                                    clearInterval(countdownTimer);
                                                                    t.updateState(t, 'Promo', false, 'wheel', 'timer');
                                                                    t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                                } else
                                                                    t.updateState(t, 'Promo', date, 'wheel', 'timer');
                                                            }, 1000);
                                                        }

                                                    } else {
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.data.reason, type: 'error', timer: 5 });
                                                        t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                    }
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                }
                                                break;
                                            case 'repost':
                                                if (data.success) {
                                                    if (data.data.gained)
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'bonusGranted'), type: 'success', timer: 5 });
                                                    else
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.data.reason, type: 'error', timer: 5 });
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                tt.PromoCheckStatus(t, 'repost', 0);
                                                break;
                                            case 'group':
                                                if (data.success) {
                                                    if (data.data.gained)
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'bonusGranted'), type: 'success', timer: 5 });
                                                    else
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.data.reason, type: 'error', timer: 5 });
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                tt.PromoCheckStatus(t, 'group', 0);
                                                break;
                                            case 'subscribe':
                                                if (data.success) {
                                                    if (data.data.gained)
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'bonusGranted'), type: 'success', timer: 5 });
                                                    else
                                                        tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.data.reason, type: 'error', timer: 5 });
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                tt.PromoCheckStatus(t, 'subscribe', 0);
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'loyalty':
                                switch (data.module) {
                                    case 'get':
                                        switch (data.method) {
                                            case 'profit':
                                                if (data.success) {
                                                    tt.HeaderGetBalance(t);
                                                    tt.LoyaltyGetBalance(t);
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'partner':
                                switch (data.module) {
                                    case 'get':
                                        switch (data.method) {
                                            case 'profit':
                                                if (data.success) {
                                                    tt.HeaderGetBalance(t);
                                                    tt.PartnerGetBalance(t);
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                    case 'campgains':
                                        switch (data.method) {
                                            case 'create':
                                                if (data.success) {
                                                    t.updateState(t, "form", false, 'partner', 'campgainCode', 'error');
                                                    t.updateState(t, "form", tt.randomString(t.config.limits.form.partner.campgains.code.default), 'partner', 'campgainCode', 'data');
                                                    t.updateState(t, "form", '', 'partner', 'campgainName', 'data');
                                                    t.updateState(t, 'Partner', !t.state.Partner.campgains.createOpen, 'campgains', 'createOpen');
                                                    tt.PartnerGetCampgains(t);
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Partner', false, 'campgains', 'process');
                                                }
                                                break;
                                            case 'edit':
                                                if (data.success) {
                                                    t.updateState(t, "form", false, 'partner', 'campgainCode', 'error');
                                                    t.updateState(t, "form", tt.randomString(t.config.limits.form.partner.campgains.code.default), 'partner', 'campgainCode', 'data');
                                                    t.updateState(t, "form", '', 'partner', 'campgainName', 'data');
                                                    t.updateState(t, 'Partner', !t.state.Partner.campgains.createOpen, 'campgains', 'createOpen');
                                                    t.updateState(t, 'Partner', false, 'campgains', 'edit');
                                                    tt.PartnerGetCampgains(t);
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Partner', false, 'campgains', 'process');
                                                }
                                                break;
                                            case 'delete':
                                                if (data.success)
                                                    tt.PartnerGetCampgains(t);
                                                else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Partner', false, 'campgains', 'process');
                                                }
                                                break;
                                        }
                                        break;
                                    case 'info':
                                        switch (data.method) {
                                            case 'code':
                                                if (!data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, "form", false, 'partner', 'code', 'error')
                                                    tt.PartnerGetCode(t);
                                                }

                                                t.updateState(t, "inputs", false, 'partner', 'code');
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'user':
                                switch (data.module) {
                                    case 'auth':
                                        switch (data.method) {
                                            case 'recovery':
                                                if (data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'passwordWasChanged'), type: 'success', timer: 5 });
                                                    t.updateState(t, 'modal', false, 'show');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'recovery', false, 'process');

                                                break;
                                        }
                                        break;
                                    case 'wallet':
                                        switch (data.method) {
                                            case 'deposit':
                                                if (data.success) {
                                                    if (isSafari) {
                                                        let a = document.createElement("a");
                                                        document.body.appendChild(a);
                                                        a.style = "display: none";
                                                        a.href = data.data;
                                                        a.click();
                                                        document.body.removeChild(a);
                                                    } else
                                                        window.open(data.data, '_blank');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Wallet', false, 'process');
                                                break;
                                            case 'withdraw':
                                                if (!data.success)
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'wallet', 'withdrawCreated'), type: 'success', timer: 5 });
                                                    t.updateState(t, 'Wallet', 3, 'module');
                                                    localStorage.setItem('wallet.module', 3);
                                                }
                                                break;
                                            case 'cancel':
                                                t.updateState(t, 'Wallet', false, 'log', 'cancelClicked')
                                                if (!data.success)
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'wallet', 'withdrawCanceled'), type: 'success', timer: 5 });
                                                    tt.WalletGetLog(t);
                                                }
                                                break;
                                        }
                                        break;
                                    case 'data':
                                        switch (data.method) {
                                            case 'tfa':
                                                if (data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: data.error, type: 'success', timer: 5 });
                                                    t.updateState(t, 'modal', false, 'show');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'form', { data: '', disabled: false }, 'settings', 'tfaPin1');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin2');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin3');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin4');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin5');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin6');
                                                t.updateState(t, 'form', { data: '', error: false }, 'settings', 'tfaPassword');
                                                break;
                                            case 'info':
                                                if (!data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Settings', false, 'securityProcess');
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'settings', 'dataSuccessChanged'), type: 'success', timer: 5 });
                                                    t.updateState(t, 'form', { data: '', error: false }, 'settings', 'login');
                                                    t.updateState(t, 'form', { data: '', error: false }, 'settings', 'password');
                                                    t.updateState(t, 'form', { data: '', error: false }, 'settings', 'passwordConfirm');
                                                    t.updateState(t, 'form', { data: '', error: false }, 'settings', 'passwordCurrent');
                                                    tt.HeaderGetInfo(t);
                                                }
                                                break;
                                            case 'casino':
                                                if (!data.success)
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'name':
                                                if (data.success) {
                                                    t.state.userData.settings.name = t.state.form.settings.name.data;
                                                    t.updateStateOBJ(t, 'userData', t.state.userData);
                                                    localStorage.setItem('user.data', JSON.stringify(t.state.userData));
                                                    t.updateState(t, 'form', { data: '', error: false }, 'settings', 'name');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'email':
                                                if (data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'confirmEmail'), type: 'success', timer: 5 });
                                                    tt.HeaderGetInfo(t);
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Settings', false, 'mailProcess');
                                                break;
                                            case 'confirm':
                                                if (data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'emailWasConfirmed'), type: 'success', timer: 5 });

                                                    if (t.state.userID)
                                                        tt.HeaderGetInfo(t);
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'confirm', false, 'token');
                                                break;
                                            case 'verify':
                                                if (data.success)
                                                    setTimeout(() => { tt.SettingsGetVerifyData(t) }, 500);
                                                else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'Settings', false, 'verifyProcess');
                                                }
                                                break;
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                    case 'get':
                        switch (data.class) {
                            case 'data':
                                switch (data.module) {
                                    case 'status':
                                        switch (data.method) {
                                            case 'ping':
                                                console.log("BRILLX CONNECTED");
                                                console.log('=======================')
                                                console.log("VERSION: " + data.data.version);
                                                console.log("LAST UPDATE: " + data.data.updated);

                                                if (window.location.origin.indexOf(':3000') === -1 && window.location.origin !== data.data.domain && data.data.domain !== "/") {
                                                    console.log("NEW DOMAIN FOUND: " + data.data.domain);
                                                    setTimeout(() => {
                                                        window.location.href = data.data.domain;
                                                    }, 2000);
                                                } else if (data.data.version != t.config.version) {
                                                    console.log("NEW VERSION " + data.data.version + " AVAILABLE, GONNA UPDATE FROM " + t.config.version);
                                                    navigator.serviceWorker.getRegistrations().then(function (registrations) {
                                                        for (let registration of registrations) {
                                                            registration.unregister();
                                                        }

                                                        caches.keys().then(function (names) {
                                                            for (let name of names)
                                                                caches.delete(name);
                                                        });

                                                        window.location = '/';
                                                    });
                                                } else
                                                    t.updateState(t, 'connected', true);

                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'promo':
                                switch (data.module) {
                                    case 'status':
                                        switch (data.method) {
                                            case 'wheel':
                                                if (data.success) {
                                                    if (data.data.available)
                                                        t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                    else {
                                                        t.updateState(t, 'Promo', false, 'wheel', 'available');
                                                        let date = tt.countdown(data.data.date);
                                                        if (!date)
                                                            t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                        else {
                                                            t.updateState(t, 'Promo', date, 'wheel', 'timer');
                                                            let countdownTimer = setInterval(() => {
                                                                let date = tt.countdown(data.data.date);
                                                                if (!date) {
                                                                    clearInterval(countdownTimer);
                                                                    t.updateState(t, 'Promo', false, 'wheel', 'timer');
                                                                    t.updateState(t, 'Promo', true, 'wheel', 'available');
                                                                } else
                                                                    t.updateState(t, 'Promo', date, 'wheel', 'timer');
                                                            }, 1000);
                                                        }
                                                    }
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'repost':
                                                if (data.success && !data.data.available)
                                                    t.updateState(t, 'Promo', true, data.method, 'gained');
                                                break;
                                            case 'group':
                                                if (data.success && !data.data.available)
                                                    t.updateState(t, 'Promo', true, data.method, 'gained');
                                                break;
                                            case 'subscribe':
                                                if (data.success && !data.data.available)
                                                    t.updateState(t, 'Promo', true, data.method, 'gained');
                                                break;
                                        }
                                        break;
                                    case 'info':
                                        switch (data.method) {
                                            case 'account':
                                                if (data.success)
                                                    t.updateState(t, 'Promo', data.data, 'vk', 'account');
                                                //else
                                                //    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', 0, 'infoLoaded');

                                                t.updateState(t, 'Promo', t.state.Promo.infoLoaded + 1, 'infoLoaded');

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', false, 'infoRefreshing');
                                                break;
                                            case 'group':
                                                if (data.success)
                                                    t.updateState(t, 'Promo', data.data, 'vk', 'group');
                                                //else
                                                //    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', 0, 'infoLoaded');

                                                t.updateState(t, 'Promo', t.state.Promo.infoLoaded + 1, 'infoLoaded');

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', false, 'infoRefreshing');
                                                break;
                                            case 'like':
                                                if (data.success)
                                                    t.updateState(t, 'Promo', data.data, 'vk', 'like');
                                                //else
                                                //    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', 0, 'infoLoaded');

                                                t.updateState(t, 'Promo', t.state.Promo.infoLoaded + 1, 'infoLoaded');

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', false, 'infoRefreshing');
                                                break;
                                            case 'repost':
                                                if (data.success)
                                                    t.updateState(t, 'Promo', data.data, 'vk', 'repost');
                                                //else
                                                //    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', 0, 'infoLoaded');

                                                t.updateState(t, 'Promo', t.state.Promo.infoLoaded + 1, 'infoLoaded');

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', false, 'infoRefreshing');
                                                break;
                                            case 'notifications':
                                                if (data.success)
                                                    t.updateState(t, 'Promo', data.data, 'vk', 'notifications');
                                                //else
                                                //    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', 0, 'infoLoaded');

                                                t.updateState(t, 'Promo', t.state.Promo.infoLoaded + 1, 'infoLoaded');

                                                if (t.state.Promo.infoLoaded === 5)
                                                    t.updateState(t, 'Promo', false, 'infoRefreshing');
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'loyalty':
                                switch (data.module) {
                                    case 'info':
                                        switch (data.method) {
                                            case 'balance':
                                                if (data.success)
                                                    t.updateState(t, 'Loyalty', data.data, 'balance');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'stat':
                                                if (data.success)
                                                    t.updateState(t, 'Loyalty', data.data, 'stat');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Loyalty', false, 'process');
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'profile':
                                switch (data.module) {
                                    case 'info':
                                        switch (data.method) {
                                            case 'stat':
                                                if (data.success)
                                                    t.updateState(t, 'Profile', data.data, 'stat');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Profile', false, 'process');
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'partner':
                                switch (data.module) {
                                    case 'campgains':
                                        switch (data.method) {
                                            case 'list':
                                                if (data.success)
                                                    t.updateState(t, 'Partner', data.data, 'campgains', 'list');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Partner', false, 'campgains', 'process');
                                                break;
                                        }
                                        break;
                                    case 'info':
                                        switch (data.method) {
                                            case 'balance':
                                                if (data.success)
                                                    t.updateState(t, 'Partner', data.data, 'balance');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'users':
                                                if (data.success) {
                                                    t.updateState(t, 'Partner', data.data.perPage, 'usersList', 'perPage');
                                                    t.updateState(t, 'Partner', data.data.currentPage, 'usersList', 'offset');
                                                    t.updateState(t, 'Partner', data.data.count, 'usersList', 'total');
                                                    t.updateState(t, 'Partner', data.data.data, 'usersList', 'data');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Partner', false, 'usersList', 'process');
                                                break;
                                            case 'code':
                                                if (data.success)
                                                    t.updateState(t, 'form', data.data, 'partner', 'code', 'data');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'stat':
                                                if (data.success)
                                                    t.updateState(t, 'Partner', data.data, 'stat');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Partner', false, 'process');
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'user':
                                switch (data.module) {
                                    case 'wallet':
                                        switch (data.method) {
                                            case 'payways':
                                                t.updateState(t, 'Wallet', false, 'process')
                                                if (!data.success)
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                else {
                                                    let cryptoMethods = [], mobileMethods = [];

                                                    data.data.forEach((method) => {
                                                        if (method.crypto)
                                                            cryptoMethods.push(method.method);
                                                        else if (method.mobile)
                                                            mobileMethods.push(method.method);
                                                    });

                                                    t.updateState(t, 'Wallet', data.data, 'payWays');
                                                    t.updateState(t, 'Wallet', cryptoMethods, 'payWaysCrypto');
                                                    t.updateState(t, 'Wallet', mobileMethods, 'payWaysMobile');

                                                    if (t.state.Wallet.module === 1)
                                                        t.updateState(t, 'Wallet', data.data[0].method, 'deposit', 'currentMethod');
                                                    else
                                                        t.updateState(t, 'Wallet', data.data[0].method, 'withdraw', 'currentMethod');
                                                }
                                                break;
                                            case 'templates':
                                                if (data.success)
                                                    t.updateState(t, 'Wallet', data.data.data, 'withdraw', 'templatesData');

                                                break;
                                        }
                                        break;
                                    case 'auth':
                                        switch (data.method) {
                                            case 'check':
                                                if (!data.success) {
                                                    localStorage.removeItem('user.key');
                                                    localStorage.removeItem('user.id');
                                                    t.updateState(t, 'authKey', 0);
                                                    t.updateState(t, 'userID', 0);
                                                    window.location.href = '/';
                                                } else {
                                                    localStorage.setItem('user.id', data.data);
                                                    t.updateState(t, 'userID', data.data);


                                                    window.Chatra('setIntegrationData', {
                                                        uid: data.data
                                                    });

                                                    if (t.state.userID && !t.state.userData)
                                                        tt.HeaderGetInfo(t);
                                                }
                                                break;
                                            case 'login':
                                                if (data.success && typeof data.data !== "undefined" && data.data) {
                                                    if (data.data.indexOf('2fa_') === -1) {
                                                        localStorage.setItem('user.key', data.data);
                                                        t.updateState(t, 'authKey', data.data);
                                                        tt.checkAuth(t);
                                                        tt.HeaderGetBalance(t);

                                                        t.updateState(t, 'Header', true, 'balanceDiffLock');
                                                        setTimeout(() => t.updateState(t, 'Header', false, 'balanceDiffLock'), 5000);

                                                        t.updateState(t, 'modal', false, 'show');
                                                    } else {
                                                        t.updateState(t, 'authTempKey', data.data);
                                                        t.updateState(t, 'modal', false, 'show');
                                                        t.updateState(t, 'modal', { show: true, content: TFAAuthModal, className: t.styles['tfaModal'] })
                                                    }
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'auth', false, 'process');
                                                break;
                                            case 'linkSocialNetwork':
                                                if (data.success) {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'accountLinked'), type: 'success', timer: 5 });
                                                    t.updateState(t, 'auth', false, 'link');
                                                    tt.HeaderGetInfo(t);
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'mainRedirect', true);
                                                }
                                                break;
                                            case 'loginSocialNetwork':
                                                if (data.success && typeof data.data !== "undefined" && data.data) {
                                                    if (data.data.indexOf('2fa_') === -1) {
                                                        localStorage.setItem('user.key', data.data);
                                                        t.updateState(t, 'authKey', data.data);
                                                        tt.checkAuth(t);
                                                        tt.HeaderGetBalance(t);

                                                        t.updateState(t, 'Header', true, 'balanceDiffLock');
                                                        setTimeout(() => t.updateState(t, 'Header', false, 'balanceDiffLock'), 5000);

                                                        t.updateState(t, 'modal', false, 'show');
                                                    } else {
                                                        t.updateState(t, 'authTempKey', data.data);
                                                        t.updateState(t, 'modal', false, 'show');
                                                        t.updateState(t, 'modal', { show: true, content: TFAAuthModal, className: t.styles['authModal'] })
                                                    }
                                                } else {
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                    t.updateState(t, 'mainRedirect', true);
                                                }
                                                break;
                                            case 'tfa':
                                                if (data.success && typeof data.data !== "undefined" && data.data) {
                                                    t.updateState(t, 'authTempKey', data.data);
                                                    localStorage.setItem('user.key', data.data);
                                                    t.updateState(t, 'authKey', data.data);
                                                    tt.checkAuth(t);
                                                    t.updateState(t, 'modal', false, 'show');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'form', { data: '', disabled: false }, 'settings', 'tfaPin1');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin2');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin3');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin4');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin5');
                                                t.updateState(t, 'form', { data: '', disabled: true }, 'settings', 'tfaPin6');

                                                if (t.state.inputs.auth.tfaPin)
                                                    t.state.inputs.auth.tfaPin.focus();

                                                t.updateState(t, 'auth', false, 'process');
                                                break;
                                            case 'registration':
                                                if (data.success && typeof data.data !== "undefined" && data.data) {
                                                    localStorage.setItem('user.key', data.data);
                                                    t.updateState(t, 'authKey', data.data);
                                                    tt.checkAuth(t);
                                                    t.updateState(t, 'modal', false, 'show');
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'auth', 'accountCreated'), type: 'success', timer: 5 });
                                                } else if (!data.success && typeof data.error !== "undefined" && data.error)
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'auth', false, 'process');
                                                break;
                                            case 'recovery':
                                                if (data.success) {
                                                    t.updateState(t, 'modalSecond', false, 'show');
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'success'), message: t.Interface.getText(t, 'interface', 'confirmPasswordRecovery'), type: 'success', timer: 5 });
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'recovery', false, 'process');
                                                break;
                                        }
                                        break;
                                    case 'data':
                                        switch (data.method) {
                                            case 'balance':
                                                if (data.success) {
                                                    t.updateState(t, 'Header', data.data - t.state.Header.balance, 'balanceDiff');
                                                    t.updateState(t, 'Header', data.data, 'balance');
                                                    localStorage.setItem('user.balance', data.data);

                                                    setTimeout(() => t.updateState(t, 'Header', 0, 'balanceDiff'), 1000);
                                                }
                                                break;
                                            case 'tfa':
                                                if (data.success)
                                                    t.updateState(t, 'Settings', data.data, 'tfa');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'info':
                                                if (data.success && typeof data.data !== "undefined") {
                                                    t.updateStateOBJ(t, 'userData', data.data);
                                                    localStorage.setItem('user.data', JSON.stringify(data.data));
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Settings', false, 'securityProcess');
                                                break;
                                            case 'log':
                                                if (data.success) {
                                                    if (data.data.type < 3) {
                                                        data.data.data.forEach(function (item) {
                                                            if (typeof item.data !== "undefined" && item.type == 99) {
                                                                let gameData = tt.getGameByID(t, item.data);
                                                                if (gameData)
                                                                    item.gameName = gameData.params.name;
                                                            }
                                                        });

                                                        t.updateState(t, 'Log', data.data.perPage, 'perPage');
                                                        t.updateState(t, 'Log', data.data.currentPage, 'offset');
                                                        t.updateState(t, 'Log', data.data.count, 'total');
                                                        t.updateState(t, 'Log', data.data.data, 'data');
                                                    } else if (data.data.type === 3 || data.data.type === 4) {
                                                        t.updateState(t, 'Wallet', data.data.perPage, 'log', 'perPage');
                                                        t.updateState(t, 'Wallet', data.data.currentPage, 'log', 'offset');
                                                        t.updateState(t, 'Wallet', data.data.count, 'log', 'total');
                                                        t.updateState(t, 'Wallet', data.data.data, 'log', 'data');
                                                    } else if (data.data.type === 5) {
                                                        data.data.data.forEach(function (item) {
                                                            if (typeof item.data !== "undefined" && item.type == 99) {
                                                                let gameData = tt.getGameByID(t, item.data);
                                                                if (gameData)
                                                                    item.gameName = gameData.params.name;
                                                            }
                                                        });

                                                        t.updateState(t, 'Partner', data.data.perPage, 'log', 'perPage');
                                                        t.updateState(t, 'Partner', data.data.currentPage, 'log', 'offset');
                                                        t.updateState(t, 'Partner', data.data.count, 'log', 'total');
                                                        t.updateState(t, 'Partner', data.data.data, 'log', 'data');
                                                    } else if (data.data.type === 6) {
                                                        data.data.data.forEach(function (item) {
                                                            if (typeof item.data !== "undefined" && item.type == 99) {
                                                                let gameData = tt.getGameByID(t, item.data);
                                                                if (gameData)
                                                                    item.gameName = gameData.params.name;
                                                            }
                                                        });

                                                        t.updateState(t, 'Loyalty', data.data.perPage, 'log', 'perPage');
                                                        t.updateState(t, 'Loyalty', data.data.currentPage, 'log', 'offset');
                                                        t.updateState(t, 'Loyalty', data.data.count, 'log', 'total');
                                                        t.updateState(t, 'Loyalty', data.data.data, 'log', 'data');
                                                    }
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Log', false, 'process');
                                                t.updateState(t, 'Wallet', false, 'log', 'process');
                                                t.updateState(t, 'Partner', false, 'log', 'process');
                                                t.updateState(t, 'Loyalty', false, 'log', 'process');
                                                break;
                                            case 'regData':
                                                if (data.success)
                                                    t.updateState(t, 'Settings', JSON.parse(data.data).last, 'regData');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'verify':
                                                if (data.success)
                                                    t.updateState(t, 'Settings', data.data, 'verify');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });

                                                t.updateState(t, 'Settings', false, 'verifyProcess');
                                                break;
                                            case 'casino':
                                                if (data.success) {
                                                    t.updateState(t, 'Lobby', data.data.recent, 'recentGames');
                                                    t.updateState(t, 'Lobby', data.data.favorite, 'favoriteGames');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                }
                                break;
                            case 'casino':
                                switch (data.module) {
                                    case 'category':
                                        switch (data.method) {
                                            case 'list':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    t.updateState(t, 'Lobby', data.data.data, 'categories');
                                                    localStorage.setItem('categories.list', JSON.stringify(data.data.data));
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                    case 'game':
                                        switch (data.method) {
                                            case 'list':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    t.updateState(t, 'Header', data.data.data, 'gamesList');
                                                    data.data.data.forEach(function (item) {
                                                        if (!tt.inArray(item.providerID, t.config.casino.lockedProviders)) {
                                                            if (t.state.Landing.newGames.length < t.config.limits.Landing.newGames && typeof item.params.new !== "undefined" && item.params.new)
                                                                t.state.Landing.newGames.push(item);

                                                            //if ((!t.state.Lobby.providerSelected || t.state.Lobby.providerSelected === item.providerID) && (!t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.state.Lobby.categorySelected))) && (!t.state.Lobby.filter || (t.state.Lobby.filter === 2 && item.params.top) || (t.state.Lobby.filter === 3 && item.params.new) || (t.state.Lobby.filter === 1 && t.Interface.inArray(item.id, t.state.Lobby.recentGames)) || (t.state.Lobby.filter === 4 && t.Interface.inArray(item.id, this.props.t.state.Lobby.favoriteGames))))
                                                            //    t.state.Lobby.gamesList.push(item);

                                                            if (t.state.Lobby.loadedGames.length < t.config.limits.Lobby.gamesPerPage && (!t.state.Lobby.providerSelected || t.state.Lobby.providerSelected === item.providerID) && (!t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.state.Lobby.categorySelected))) && (!t.state.Lobby.search || item.params.name.toUpperCase().indexOf(t.state.Lobby.search.toUpperCase()) !== -1) && (!t.state.Lobby.filter || (t.state.Lobby.filter === 2 && item.params.top) || (t.state.Lobby.filter === 3 && item.params.new) || (t.state.Lobby.filter === 1 && t.Interface.inArray(item.id, t.state.Lobby.recentGames)) || (t.state.Lobby.filter === 4 && t.Interface.inArray(item.id, t.state.Lobby.favoriteGames))))
                                                                t.state.Lobby.loadedGames.push(item);
                                                            else if (!t.state.Lobby.hasMore && (!t.state.Lobby.providerSelected || t.state.Lobby.providerSelected === item.providerID) && (!t.state.Lobby.categorySelected || item.categoryID.split(',').some((category => category === t.state.Lobby.categorySelected))) && (!t.state.Lobby.search || item.params.name.toUpperCase().indexOf(t.state.Lobby.search.toUpperCase()) !== -1) && (!t.state.Lobby.filter || (t.state.Lobby.filter === 2 && item.params.top) || (t.state.Lobby.filter === 3 && item.params.new) || (t.state.Lobby.filter === 1 && t.Interface.inArray(item.id, t.state.Lobby.recentGames)) || (t.state.Lobby.filter === 4 && t.Interface.inArray(item.id, t.state.Lobby.favoriteGames))))
                                                                t.updateState(t, 'Lobby', true, 'hasMore');
                                                        }
                                                    });

                                                    t.updateState(t, 'Landing', t.state.Landing.newGames, 'newGames');
                                                    //t.updateState(t, 'Lobby', t.state.Lobby.gamesList, 'gamesList');
                                                    t.updateState(t, 'Lobby', t.state.Lobby.loadedGames, 'loadedGames');


                                                    localStorage.setItem('games.list', JSON.stringify(t.state.Lobby.gamesList));
                                                    localStorage.setItem('games.new', JSON.stringify(t.state.Landing.newGames));
                                                    localStorage.setItem('games.loaded', JSON.stringify(t.state.Lobby.loadedGames));
                                                    localStorage.setItem('games.updated', +new Date());
                                                    // if (t.state.Log.data.length)
                                                    //   tt.LogGetLog(t);
                                                }
                                                break;
                                            case 'info':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    t.socket.emit('api', { type: 'get', class: 'casino', module: 'provider', method: 'info', data: { providerID: data.data.data.providerID } });
                                                    t.updateState(t, 'Game', data.data.data, 'data');
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                    case 'provider':
                                        switch (data.method) {
                                            case 'info':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    t.updateState(t, 'Game', data.data.data, 'providerData');
                                                    tt.loadGameSession(t);
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'list':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    let providersList = [];

                                                    data.data.data.forEach(function (provider) {
                                                        if (!tt.inArray(provider.id, t.config.casino.lockedProviders))
                                                            providersList.push(provider);
                                                    });

                                                    t.updateState(t, 'Lobby', providersList, 'providers');
                                                    localStorage.setItem('providers.list', JSON.stringify(providersList));
                                                } else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                    case 'session':
                                        switch (data.method) {
                                            case 'demo':
                                                if (typeof data.data !== "undefined" && data.success)
                                                    t.updateState(t, 'Game', data.data, 'iframe');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                            case 'real':
                                                if (typeof data.data !== "undefined" && data.success)
                                                    t.updateState(t, 'Game', data.data, 'iframe');
                                                else
                                                    tt.createNoty(t, { title: t.Interface.getText(t, 'noty', 'error'), message: data.error, type: 'error', timer: 5 });
                                                break;
                                        }
                                        break;
                                    case 'stat':
                                        switch (data.method) {
                                            case 'live':
                                                if (typeof data.data !== "undefined" && data.data.success) {
                                                    data.data.data.forEach(function (item, i) {
                                                        if (!t.state.LiveBets.loaded.some((currentItem) => (item.id === currentItem))) {
                                                            if (t.state.LiveBets.liveBets.length > t.config.limits.LiveBets.liveBets * 2)
                                                                t.state.LiveBets.liveBets = t.state.LiveBets.liveBets.slice(t.config.limits.LiveBets.liveBets);

                                                            let gameData = tt.getGameByID(t, item.gameID);
                                                            if (gameData) {
                                                                item.gameName = gameData.params.name;
                                                                item.poster = gameData.params.poster;
                                                                if (t.state.LiveBets.liveBets.length <= t.config.limits.LiveBets.liveBets)
                                                                    t.state.LiveBets.liveBets.push(item);
                                                                else
                                                                    setTimeout(function () { t.state.LiveBets.liveBets.push(item); t.updateState(t, 'LiveBets', t.state.LiveBets.liveBets, 'liveBets'); }, i * 3000);
                                                            }
                                                        }
                                                    });

                                                    t.state.LiveBets.loaded = data.data.data.map((item) => (item.id));
                                                    t.updateState(t, 'LiveBets', t.state.LiveBets.liveBets, 'liveBets');
                                                }
                                                break;
                                        }
                                        break;
                                    case 'user':
                                        switch (data.method) {
                                            case 'stats':
                                                if (data.success)
                                                    t.updateState(t, 'Game', data.data, 'stats');
                                                break;
                                        }
                                        break;
                                }
                                break;
                        }
                        break;
                }
        });
    }

    doLogIn(t, recaptchaToken) {
        t.updateState(t, 'auth', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'login', data: { login: t.state.form.auth.login.data, password: t.state.form.auth.password.data, recaptchaToken } });
    }

    doConfirmEmail(t, token) {
        t.socket.emit('api', { type: 'set', class: 'user', module: 'data', method: 'confirm', data: { token } });
    }

    doRecoverySet(t, recaptchaToken) {
        t.updateState(t, 'recovery', true, 'process');
        t.socket.emit('api', { type: 'set', class: 'user', module: 'auth', method: 'recovery', data: { password: t.state.form.recovery.password.data, passwordConfirm: t.state.form.recovery.passwordConfirm.data, token: t.state.recovery.token, recaptchaToken } });
    }

    doSocialNetworkLogin(t, type, token) {
        if (!token)
            switch (type) {
                case 'vk':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'fb':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', scope: 'email', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'ok':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'token', scope: 'VALUABLE_ACCESS', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'gl':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', scope: 'openid', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
            }
        else
            t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'loginSocialNetwork', data: { type, token, redirectUri: [window.location.origin, 'auth', type].join('/'), code: t.state.userRefer } });
    }

    doSocialNetworkLink(t, type, token) {
        if (!token)
            switch (type) {
                case 'vk':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'fb':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', scope: 'email', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'ok':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'token', scope: 'VALUABLE_ACCESS', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
                case 'gl':
                    window.location.href = t.config.auth[type].redirectUri + '?' + this.serialize({ client_id: t.config.auth[type].client_id, response_type: 'code', scope: 'openid', redirect_uri: [window.location.origin, 'auth', type].join('/') });
                    break;
            }
        else
            t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'linkSocialNetwork', data: { authKey: t.state.authKey, type, token, redirectUri: [window.location.origin, 'auth', type].join('/') } });
    }
    doTfaLogin(t) {
        t.updateState(t, 'auth', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'tfa', data: { authKey: t.state.authTempKey, pin: [t.state.form.settings.tfaPin1.data, t.state.form.settings.tfaPin2.data, t.state.form.settings.tfaPin3.data, t.state.form.settings.tfaPin4.data, t.state.form.settings.tfaPin5.data, t.state.form.settings.tfaPin6.data].join('') } });
    }

    doRegistration(t, recaptchaToken) {
        t.updateState(t, 'auth', true, 'process');
        t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'registration', data: { login: t.state.form.registration.login.data, password: t.state.form.registration.password.data, passwordConfirm: t.state.form.registration.passwordConfirm.data, code: t.state.userRefer, recaptchaToken } });
    }

    doLogOut(t) {
        t.updateState(t, 'connected', false);

        localStorage.removeItem('user.key');
        localStorage.removeItem('user.id');
        localStorage.removeItem('user.data');
        localStorage.removeItem('user.balance');

        t.updateState(t, 'authKey', 0);
        t.updateState(t, 'userID', 0);
        t.updateState(t, 'userData', 0);
        t.updateState(t, 'Header', 0, 'balance');

        window.location.href = '/';
    }

    checkAuth(t) {
        if (t.state.authKey)
            t.socket.emit('api', { type: 'get', class: 'user', module: 'auth', method: 'check', data: { authKey: t.state.authKey } });
    }

    loadGameInfo(t) {
        if (t.state.Game.id)
            t.socket.emit('api', { type: 'get', class: 'casino', module: 'game', method: 'info', data: { gameID: t.state.Game.id } });
    }

    loadGameSession(t) {
        t.socket.emit('api', { type: 'get', class: 'casino', module: 'session', method: t.state.Game.mode, data: { authKey: t.state.authKey, gameData: t.state.Game.data, providerData: t.state.Game.providerData, lang: t.state.lang.current, device: isMobile ? 'mobile' : 'desktop', lobbyUrl: [window.location.origin, 'lobby'].join('/') } });
    }

    checkIsNeedUpdate(compare, wcompare) {
        let needUpdate = false;
        compare.forEach(function (el, i) {
            if (!isEqual(compare, wcompare))
                needUpdate = true;
        });

        return needUpdate;
    }

    checkIsNeedUpdateA(compare, wcompare) {
        let needUpdate = false;
        compare.forEach(function (el, i) {
            if (!isEqual(compare, wcompare)) {
                needUpdate = true;
            }
        });

        return needUpdate;
    }

    getGameProviderByID(t, id) {
        let findedItem = false;
        if (t.state.Lobby.providers.length)
            t.state.Lobby.providers.forEach(function (item) {
                if (item.id === id)
                    findedItem = item;
            });

        return findedItem;
    }

    getGameByID(t, id) {
        let findedItem = false;
        if (t.state.Header.gamesList.length)
            t.state.Header.gamesList.forEach(function (item) {
                if (item.id === id)
                    findedItem = item;
            });

        return findedItem;
    }

    serialize(obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                if (typeof obj[p] === "object") {
                    for (let a in obj[p])
                        if (obj[p].hasOwnProperty(a)) {
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p][a]));
                        }
                } else
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    getCurrency(t) {
        return ' ' + t.config.currency.symbols[t.config.currency.current];
    }

    fixedFloat(value, float) {
        if (isNaN(value))
            value = 0;

        if (typeof float !== "undefined" && float)
            return parseFloat((value / 100).toFixed(2));
        else
            return (value / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
    }

    getOperationByType(item, t) {
        switch (item.type) {
            case 1:
                return this.getText(t, 'wallet', 'deposit');
            case 2:
                return this.getText(t, 'wallet', 'withdraw');
            case 7:
                return this.getText(t, 'bonus', 'title');
            case 8:
                return 'Free Wheel';
            case 9:
                return 'VK ' + this.getText(t, 'promotions', 'bonus');
            case 10:
                return this.getText(t, 'bonus', 'affiliateProgramm');
            case 25:
                return this.getText(t, 'menu', 'loyaltyProgramm');
            case 99:
                if (typeof item.gameName !== "undefined")
                    return item.gameName;
                else
                    return this.getText(t, 'promotions', 'game');
            default:
                return this.getText(t, 'wallet', 'other');
        }
    }

    countdown(dateTo) {
        let now = new Date();
        dateTo = new Date(dateTo * 1000 + 15 * 60000);

        if (dateTo <= now)
            return false;
        else {
            let seconds = Math.ceil((dateTo - now) / 1000);
            let hours = parseInt(seconds / 3600);
            seconds -= hours * 3600;

            if (hours < 10) hours = '0' + hours;

            let minutes = parseInt(seconds / 60);
            seconds -= minutes * 60;

            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;

            return { hours, minutes, seconds }
        }
    }

    localeDate(timestamp, dateOnly, t) {
        let date = new Date(timestamp * 1000);
        let months = [t.Interface.getText(t, 'interface', 'months', 'jan'), t.Interface.getText(t, 'interface', 'months', 'feb'), t.Interface.getText(t, 'interface', 'months', 'feb'), t.Interface.getText(t, 'interface', 'months', 'apr'), t.Interface.getText(t, 'interface', 'months', 'may'), t.Interface.getText(t, 'interface', 'months', 'jun'), t.Interface.getText(t, 'interface', 'months', 'jul'), t.Interface.getText(t, 'interface', 'months', 'aug'), t.Interface.getText(t, 'interface', 'months', 'sep'), t.Interface.getText(t, 'interface', 'months', 'oct'), t.Interface.getText(t, 'interface', 'months', 'nov'), t.Interface.getText(t, 'interface', 'months', 'dec')];
        let year = date.getFullYear();
        let month = months[date.getMonth()];
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

        if (typeof dateOnly !== "undefined" && dateOnly) {
            return day + ' ' + month + ' ' + year + t.Interface.getText(t, 'interface', 'year');
        } else
            return day + ' ' + month + ' ' + year + t.Interface.getText(t, 'interface', 'year') + ', ' + hour + ':' + min;
    }

    inArray(value, array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i] == value) return true;
        }
        return false;
    }

    randomString(length) {
        let chars = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
        let str = '';
        for (let i = 0; i < length; i++) {
            let pos = Math.floor(Math.random() * chars.length);
            str += chars.substring(pos, pos + 1);
        }
        return str;
    }

    randomInteger(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    }

    getFormattedWallet(wallet, type, t) {
        let formatedString = '', stringArr = typeof wallet !== "undefined" ? wallet.split(' ').join('').split('') : [];
        if (type !== "bitcoin" && type !== "payeer" && type !== "advcash" && type !== "usdt") {
            stringArr.forEach(function (number, i) {
                if (!isFinite(number))
                    stringArr.splice(i, 1);
            });

            if (type === "card")
                stringArr.forEach(function (number, i) {
                    if (i && !(i % 4))
                        formatedString += ' ' + number;
                    else
                        formatedString += number;
                });
            else if ((type === "qiwi" || type === "mts" || type === "megafon" || type === "beeline" || type === "tele2") && stringArr.length >= 11) {
                if (stringArr[0] === '7' && stringArr[1] !== '7')
                    formatedString = '+' + stringArr[0] + ' ' + stringArr.slice(1, 4).join('') + ' ' + stringArr.slice(4).join('')
                else
                    formatedString = '+' + stringArr[0] + stringArr[1] + ' ' + stringArr.slice(2, 5).join('') + ' ' + stringArr.slice(5).join('')
            } else
                stringArr.forEach(function (number, i) {
                    formatedString += number;
                });
        }

        if (formatedString.split(' ').join('').length < t.config.limits.form.wallet.format[type])
            return wallet;
        else
            return formatedString;
    }

    isFormCorrect(t, type, callback, params) {
        params = params || {};

        let tt = this;

        Object.keys(t.state.form[type]).forEach(function (key) {
            tt.checkForm(t, type, key);
        });

        if (!Object.keys(t.state.form[type]).map(function (key) { return t.state.form[type][key].error }).some(function (item) { return !item ? false : true }))
            callback(t, params.recaptchaToken || '');
    }

    isFormCorrectSome(t, type, callback, params) {
        params = params || [];

        let tt = this;

        Object.keys(t.state.form[type]).forEach(function (key) {
            if (tt.inArray(key, params))
                tt.checkForm(t, type, key);
        });

        if (!Object.keys(t.state.form[type]).map(function (key) { return t.state.form[type][key].error }).some(function (item) { return !item ? false : true }))
            callback(t);
    }

    clearForm(t, type) {
        Object.keys(t.state.form[type]).forEach(function (key) {
            t.updateState(t, 'form', { data: t.state.form[type][key].data, error: false }, type, key);
        });
    }

    getPaymentLimits(t, payWay, type) {
        let min = 0, max = 0;

        t.state.Wallet.payWays.forEach((method) => {
            if (method.method === payWay) {
                min = type === "deposit" ? method.min.split(',')[0] : method.min.split(',')[1];
                max = type === "deposit" ? method.max.split(',')[0] : method.max.split(',')[1];
            }
        });

        return { min: parseInt(min), max: parseInt(max) };
    }

    getPaywayImage(t, payWay) {
        let img = "";

        t.state.Wallet.payWays.forEach((method) => {
            if (method.method === payWay)
                img = method.img;

        });

        return img;
    }

    checkForm(t, type, value, subvalue, data) {
        switch (type) {
            case 'registration':
                switch (value) {
                    case 'login':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minLoginLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxLoginLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'password':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'passwordConfirm':
                        if (t.state.form[type][value].data !== t.state.form[type].password.data)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'passwordsMustBeSame') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                }
            case 'auth':
                switch (value) {
                    case 'login':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minLoginLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxLoginLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'password':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                }
                break;
            case 'partner':
                switch (value) {
                    case 'code':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'minCodeLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'maxCodeLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'campgainCode':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type].campgains.code.more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'minCodeLength') + ' ' + t.config.limits.form[type].campgains.code.more + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type].campgains.code.less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'maxCodeLength') + ' ' + t.config.limits.form[type].campgains.code.less + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'campgainName':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type].campgains.name.more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'minNameLength') + ' ' + t.config.limits.form[type].campgains.name.more + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type].campgains.name.less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'maxNameLength') + ' ' + t.config.limits.form[type].campgains.name.less + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                }
                break;
            case 'recovery':
                switch (value) {
                    case 'email':
                        let rxp = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
                        if (t.state.form[type][value].data.length && !rxp.test(t.state.form[type][value].data))
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'settings', 'incorrectEmail') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'password':
                        if (t.state.form[type][value].data.length < t.config.limits.form.auth.password.more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form.auth.password.more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form.auth.password.less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form.auth.password.less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'passwordConfirm':
                        if (t.state.form[type][value].data !== t.state.form[type].password.data)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'passwordsMustBeSame') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                }
                break;
            case 'bonus':
                switch (value) {
                    case 'code':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'minCodeLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'partner', 'maxCodeLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'partner', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                }
                break;
            case 'settings':
                switch (value) {
                    case 'name':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minLoginLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxLoginLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'email':
                        let rxp = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
                        if (t.state.form[type][value].data.length && !rxp.test(t.state.form[type][value].data))
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'settings', 'incorrectEmail') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'login':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minLoginLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxLoginLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'password':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type][value].more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form[type][value].more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type][value].less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form[type][value].less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'passwordConfirm':
                        if (t.state.form[type][value].data !== t.state.form[type].password.data)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'passwordsMustBeSame') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'passwordCurrent':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type].password.more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form[type].password.more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type].password.less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form[type].password.less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'tfaPassword':
                        if (t.state.form[type][value].data.length < t.config.limits.form[type].password.more)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'minPasswordLength') + ' ' + t.config.limits.form[type].password.more + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else if (t.state.form[type][value].data.length > t.config.limits.form[type].password.less)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'auth', 'maxPasswordLength') + ' ' + t.config.limits.form[type].password.less + ' ' + t.Interface.getText(t, 'auth', 'symbols') }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'verify':
                        let filled = true;

                        if (!t.state.form[type].verifyFront.data)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyFront.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyFront');

                        if (!t.state.form[type].verifyBack.data)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyBack.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyBack');

                        if (!t.state.form[type].verifyName.data.length)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyName.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyName');

                        if (!t.state.form[type].verifyLastName.data.length)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyLastName.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyLastName');

                        if (!t.state.form[type].verifyDate.data.length)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyDate.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyDate');

                        if (!t.state.form[type].verifyCity.data.length)
                            t.updateState(t, 'form', { data: t.state.form[type].verifyCity.data, error: t.Interface.getText(t, 'settings', 'requiredField') }, type, 'verifyCity');

                        Object.keys(t.state.form[type]).forEach(function (key) {
                            if (key.indexOf('verify') !== -1 && t.state.form[type][key].error)
                                filled = false;
                        });

                        t.updateState(t, 'Settings', filled, 'verifyFormFilled');
                        break;
                    case 'tfaPin':
                        if (t.state.form[type][value + subvalue].data.length) {
                            if (subvalue < 6) {
                                subvalue += 1;
                                t.updateState(t, 'form', { data: '', disabled: false }, type, 'tfaPin' + subvalue, undefined, function () {
                                    data[subvalue].focus();
                                });
                            } else {
                                data[subvalue].blur();
                                if (t.state.form.settings.tfaPassword.data.length && !t.state.form.settings.tfaPassword.error && !t.state.authTempKey)
                                    this.SettingsSetTfa(t);
                                else
                                    this.doTfaLogin(t)
                            }
                        } else {
                            if (subvalue > 1) {
                                t.updateState(t, 'form', { data: '', disabled: false }, type, 'tfaPin' + subvalue, undefined, function () {
                                    data[subvalue - 1].focus();
                                });
                            } else
                                data[1].focus();

                        }
                        break;
                }
                break;
            case 'wallet':
                switch (value) {
                    case 'deposit':
                        if (t.state.form[type][value].data === "" || !isFinite(t.state.form[type][value].data) || parseInt(t.state.form[type][value].data) < this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).min)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'wallet', 'minDepositAmount') + ' ' + t.Interface.getText(t, 'wallet', t.state.Wallet[value].currentMethod) + ': ' + this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).min + ' ' + t.config.currency.symbols[t.config.currency.current] }, type, value);
                        else if (t.state.form[type][value].data === "" || !isFinite(t.state.form[type][value].data) || parseInt(t.state.form[type][value].data) > this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).max)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'wallet', 'maxDepositAmount') + ' ' + t.Interface.getText(t, 'wallet', t.state.Wallet[value].currentMethod) + ': ' + this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).max + ' ' + t.config.currency.symbols[t.config.currency.current] }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'withdraw':
                        if (t.state.form[type][value].data === "" || !isFinite(t.state.form[type][value].data) || parseInt(t.state.form[type][value].data) < this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).min)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'wallet', 'minWithdrawAmount') + ' ' + t.Interface.getText(t, 'wallet', t.state.Wallet[value].currentMethod) + ': ' + this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).min + ' ' + t.config.currency.symbols[t.config.currency.current] }, type, value);
                        else if (t.state.form[type][value].data === "" || !isFinite(t.state.form[type][value].data) || parseInt(t.state.form[type][value].data) > this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).max)
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: t.Interface.getText(t, 'wallet', 'maxWithdrawAmount') + ' ' + t.Interface.getText(t, 'wallet', t.state.Wallet[value].currentMethod) + ': ' + this.getPaymentLimits(t, t.state.Wallet[value].currentMethod, value).max + ' ' + t.config.currency.symbols[t.config.currency.current] }, type, value);
                        else
                            t.updateState(t, 'form', { data: t.state.form[type][value].data, error: false }, type, value);
                        break;
                    case 'wallet':
                        let formatedString = '', error = false, stringArr = t.state.form[type][value].data.split(' ').join('').split('');

                        if (t.state.Wallet.withdraw.currentMethod !== "bitcoin" && t.state.Wallet.withdraw.currentMethod !== "payeer" && t.state.Wallet.withdraw.currentMethod !== "advcash" && t.state.Wallet.withdraw.currentMethod !== "usdt") {
                            stringArr.forEach(function (number, i) {
                                if (!isFinite(number))
                                    stringArr.splice(i, 1);
                            });

                            if (t.state.Wallet.withdraw.currentMethod === "card")
                                stringArr.forEach(function (number, i) {
                                    if (i && !(i % 4))
                                        formatedString += ' ' + number;
                                    else
                                        formatedString += number;
                                });
                            else if ((t.state.Wallet.withdraw.currentMethod === "qiwi" || t.state.Wallet.withdraw.currentMethod === "mts" || t.state.Wallet.withdraw.currentMethod === "megafon" || t.state.Wallet.withdraw.currentMethod === "beeline" || t.state.Wallet.withdraw.currentMethod === "tele2") && stringArr.length >= 11) {
                                if (stringArr[0] === '7' && stringArr[1] !== '7')
                                    formatedString = '+' + stringArr[0] + ' ' + stringArr.slice(1, 4).join('') + ' ' + stringArr.slice(4).join('')
                                else
                                    formatedString = '+' + stringArr[0] + stringArr[1] + ' ' + stringArr.slice(2, 5).join('') + ' ' + stringArr.slice(5).join('')
                            } else
                                stringArr.forEach(function (number, i) {
                                    formatedString += number;
                                });
                        } else
                            formatedString = stringArr.join('');

                        if (formatedString.split(' ').join('').length < t.config.limits.form.wallet.format[t.state.Wallet.withdraw.currentMethod])
                            if (t.state.Wallet.withdraw.currentMethod === "card")
                                error = t.Interface.getText(t, 'wallet', 'incorrectCardValue');
                            else
                                error = t.Interface.getText(t, 'wallet', 'incorrectWalletValue');

                        if (error)
                            t.updateState(t, 'form', { data: formatedString, error }, type, value);
                        else
                            t.updateState(t, 'form', { data: formatedString, error: false }, type, value);
                        break;
                }
                break;

        }
    }

    removeNoty(t, id) {
        t.state.noty.data.forEach(function (item, i) {
            if (item.id === id) {
                t.state.noty.data.splice(i, 1);
                t.updateState(t, 'noty', t.state.noty.data, 'data');
            }
        });

    }

    closeNoty(t, id) {
        let tt = this;
        t.state.noty.data.forEach(function (item, i) {
            if (item.id === id) {
                t.state.noty.data[i].visible = false;
                t.updateState(t, 'noty', t.state.noty.data, 'data');
                setTimeout(t.Interface.removeNoty, 300, t, id);
            }
        });
    }

    createNoty(t, params) {
        let id = t.state.noty.currentID++;
        t.state.noty.data.push({ id, visible: true, type: params.type, title: params.title, message: params.message });
        t.updateState(t, 'noty', t.state.noty);
        setTimeout(this.closeNoty, params.timer * 1000, t, id);
    }
}