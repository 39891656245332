module.exports = {
    system: { code: 'pt', name: 'Portugués' },
    aml: {
        title: 'Lucha contra el blanqueo de dinero',
        description: 'Control de seguridad',
        content: ''
    },
    auth: {
        accountCreated: 'Cuenta registrada',
        agreeText: 'Confirmo que tengo 18 años y he leído',
        agreeTos: 'términos de servicio',
        alreadyRegistered: '¿Ya está registrado?',
        byLogin: 'Por inicio de sesión y contraseña',
        bySocialNetworks: 'A través de redes sociales',
        createAccount: 'Crear una cuenta',
        forgotPassword: '¿Olvidaste tu contraseña?',
        loading: 'Cargando...',
        login: 'Inicio de sesión',
        loginTitle: 'Autorización del sitio',
        maxLoginLength: 'Duración máxima de inicio de sesión:',
        maxPasswordLength: 'Longitud máxima de la contraseña:',
        minLoginLength: 'Duración mínima de inicio de sesión:',
        minPasswordLength: 'Longitud mínima de la contraseña:',
        passwordConfirmation: 'Repetir contraseña',
        passwordsMustBeSame: 'Las contraseñas deben coincidir',
        pleaseWait: 'Espere, por favor...',
        registration: 'Inscripción',
        registrationTitle: 'Regístrate in situ',
        symbols: 'personajes',
        yourLogin: 'Tu inicio de sesión',
        yourPassword: 'Tu contraseña',
        yourEmail: 'Tu correo electrónico',
        logoutConfirm: '¿De verdad quieres salir?',
        passwordRecovery: 'Restablecer contraseña',
        newPassword: 'Nueva contraseña',
        copied: 'Copiado',
        logout: 'Fuera'
    },
    basic: {
        yes: 'Sim',
        no: 'Nop',
        confirm: 'Afirmar',
        confirmed: 'Votado',
        cancel: 'Derogando',
        sending: 'Enviando...',
        sent: 'Enviar',
        save: 'Guardar',
        create: 'Crear',
        or: 'o',
        activate: 'Activar',
        deactivate: 'Desactivar',
        prevPage: 'Página anterior',
        nextPage: 'Página siguiente',
        of: 'fuera',
        balance: 'Equilibrio',
        copy: 'Copia',
        copied: 'Copiado'
    },
    bonus: {
        permanentBonus: 'Bonificación permanente',
        tenPercent: '¡10% por cada depósito!',
        more: 'Leer más',
        fastRegistration: 'Registro rápido',
        noVerification: 'sin verificación',
        fastWithdraws: 'Pagos rápidos',
        highLimits: 'límites altos',
        loyaltyProgramm: 'Programa de fidelización',
        winCashback: '¡Hasta un 1,2% en cada victoria!',
        levelSystem: 'Sistema de nivel',
        bigPercents: 'Porcentajes grandes',
        payForWin: 'Paga por la victoria',
        butNoBets: 'no para apuestas',
        affiliateProgramm: 'Programa de afiliados',
        join: 'Unirse',
        revenueShare: '40% de participación en los ingresos',
        anytimeWithdraw: 'salida en cualquier momento',
        affiliateCabinet: 'Gabinete afiliado',
        detailStat: 'estadísticas detalladas',
        title: 'Código Promocional',
        promoEveryDay: 'Códigos promocionais todos os dias em nosso grupo VK',
        enterPromoCode: 'Insira o código promocional'
    },
    fair: {
        title: 'Garantía de licencia y honestidad',
        description: 'Garantizamos juego limpio para todos',
        fairDescription1: 'Brillx Casino entiende lo importante que es crear un entorno de seguridad para que los usuarios confíen en la honestidad de nuestros juegos.',
        fairDescription2: 'Por lo tanto, en nuestro casino, utilizamos juegos proporcionados por las principales empresas que desarrollan software para casinos en línea. Entre ellas empresas como',
        fairDescription3: 'Como miembro fundador de eCOGRA e IGC, Microgaming es un líder por delante de otros fabricantes de juegos de casino, estableciendo los más altos estándares en la industria del juego en general, especialmente por la honestidad de los juegos online, la elección de los operadores más responsables y la protección de los intereses de los jugadores.',
        andOther: 'y otros',
        also: 'También ofrecemos juegos creados por la empresa',
        defaultCasino: 'Casino online ordinario',
        brillxCasino: 'Casino de control de honestidad Brillx',
        defaultCasinoDescription1: 'Haces una apuesta escrita en el servidor del casino',
        defaultCasinoDescription2: 'Sus datos se recopilan y almacenan en los servidores del casino.',
        defaultCasinoDescription3: 'Comprobar la honestidad del juego es imposible: todos los algoritmos se graban y guardan en el lado del casino.',
        defaultCasinoDescription4: 'Para obtener una victoria, debe pasar por un largo procedimiento de autorización',
        defaultCasinoDescription5: 'Solvencia del casino en cuestión, ya que su saldo no se publica en ninguna parte',
        brillxCasinoDescription1: 'Una copia de los datos de los proveedores de apuestas de jugadores se guarda en la cadena de bloques y no se puede cambiar',
        brillxCasinoDescription2: 'Las estadísticas del juego para humanos se cifran y registran en blockchain desvinculadas a los datos personales de los jugadores',
        brillxCasinoDescription3: 'El control de la integridad se realiza con tecnología blockchain',
        brillxCasinoDescription4: 'Los retiros requieren autorización estándar KYC, sin cargo por transferencia',
        brillxCasinoDescription5: 'Informes abiertos y enorme pool para pagos de jugadores'
    },
    game: {
        loading: 'Cargando',
        lobby: 'Vestíbulo',
        fullScreen: 'Pantalla completa',
        deposit: 'Depósito',
        betsAmount: 'Valor da aposta',
        totalRTP: 'Total RTP',
        averageBet: 'Aposta média',
        topWin: 'Top win'
    },
    help: {
        title: 'Ayuda y soporte',
        description: 'Respuestas a las preguntas más comunes y comunicación con el servicio de atención al cliente.',
        connectTypes: 'Métodos para comunicarse con nosotros',
        operatorsOnline: 'Operadores online las 24 horas del día',
        supportLive: 'Soporte (en vivo)',
        onlineChat: 'Chat en vivo',
        averageTimeChat: 'Tiempo medio de respuesta: 10 minutos',
        supportEmail: 'Servicio al cliente (correo electrónico)',
        averageTimeEmail: 'Tiempo medio de respuesta: 1 día',
        generalQuestions: 'Cuestiones generales',
        aboutSite: 'Acerca del sitio',
        aboutSiteContent: 'Brillx es un casino online con licencias y nombre internacionales. Ofrecemos más de 6.000 juegos en el catálogo, una amplia gama de eventos deportivos y muchos minijuegos. Admite muchos sistemas de pago y la mayoría de las criptomonedas populares.',
        bonus: 'Bonificación',
        bonuses: 'Bonificaciones',
        onPage: 'En la página',
        manyFreeBonuses: 'hay varias formas de obtener dinero para su saldo absolutamente gratis!',
        bonus1: 'Bonificación de inscripción. Después de registrarte en el sitio, tienes 60 minutos para introducir el código de invitación y obtener hasta 10,00 RUB.',
        bonus2: 'Bonificación de inscripción. Después de registrarte en el sitio, tienes 60 minutos para introducir el código de invitación y obtener hasta 10,00 RUB.',
        bonus3: 'Bonificación de inscripción. Después de registrarte en el sitio, tienes 60 minutos para introducir el código de invitación y obtener hasta 10,00 RUB.',
        bonus4: 'Bonificación de inscripción. Después de registrarte en el sitio, tienes 60 minutos para introducir el código de invitación y obtener hasta 10,00 RUB.',
        promocodes: 'Códigos promocionales',
        inviteFriend: 'Invita a un amigo',
        balanceRefilling: 'Reabastecimiento de saldo',
        wallet: 'Billetera',
        howToRefill: '¿Cómo repongo el saldo?',
        commissions: 'Comisión',
        periodOfRefilling: 'Condiciones de matriculación',
        withdrawal: 'Retiros',
        howToWithdraw: '¿Cómo retiro fondos?',
        periodOfWithdrawal: 'Cronología de retirada',
        clickToRefill: 'Haz clic en el botón para reponer el saldo',
        atRightCorner: 'en la esquina superior derecha del sitio. Selecciona el método de pago que te convenga, introduce el importe del depósito y haz clic en Ir al pago.',
        minimalRefilling: 'Reabastecimiento mínimo',
        commissionsOnPage: 'Las comisiones de todos los métodos de pago se enumeran en la página',
        clickToWithdraw: 'Haz clic en el botón para retirar dinero de tu cuenta',
        averageTimeWithdraw: 'Todos los resultados se procesan en un promedio de una hora desde el momento de la solicitud.',
        allTransactions: 'Todas las transacciones se procesan instantáneamente. Los retrasos solo pueden producirse si tienes problemas con el método de pago en el que estás reabasteciendo tu saldo.',
        inCaseOfProblems: 'Si hay algún problema, póngase en contacto con nuestro servicio de atención al cliente y proporcione la información más detallada sobre su problema.',
        atRightCornerWithdraw: 'en la esquina superior derecha del sitio. Selecciona el método de retiro que te resulte más conveniente, introduce el importe y haz clic en Ir al pago.',
        minimalWithdrawal: 'Retirada mínima',
        ifBigAmount: 'Si el monto de la retirada es grande, la retirada puede durar un poco más, generalmente hasta una hora. Por la noche (de 00:00 a 09:00 MSC), los hallazgos pueden durar varias horas, momento en el que el agregador de pagos procesa las solicitudes de retirada durante más tiempo.',
        sometimes: 'En casos muy raros, el retiro puede durar hasta 24 horas, debido a las cargas bancarias, etc. Si el dinero no llega después de ese plazo, póngase en contacto con nuestro servicio de atención al cliente y proporcione la información más detallada sobre su problema.',
        checkCorrectPurse: 'Asegúrese también de especificar correctamente los detalles de la salida. Para tranquilizar el monedero que apuntó a la retirada, debe ingresar a su historial de transacciones y abrir los detalles haciendo clic en la transacción que desea.'
    },
    interface: {
        months: {
            jan: 'ene.',
            feb: 'feb.',
            mar: 'mar.',
            apr: 'abr.',
            may: 'mai',
            jun: 'jun.',
            jul: 'jul.',
            aug: 'ago.',
            sep: 'sep.',
            oct: 'oct.',
            nov: 'nov.',
            dec: 'dic.'
        },
        year: 'a.',
        confirmPasswordRecovery: 'Confirma el restablecimiento de la contraseña siguiendo el enlace enviado a tu correo electrónico',
        passwordWasChanged: 'La contraseña se ha cambiado correctamente, puede iniciar sesión para usar datos nuevos',
        confirmEmail: 'Confirmar dirección de correo electrónico por enlace',
        emailWasConfirmed: 'La dirección de correo electrónico se ha confirmado correctamente',
        accountLinked: 'La cuenta se ha vinculado correctamente',
        bonusGranted: 'Bono recibido'
    },
    landing: {
        newGames: 'Juegos nuevos',
        topGames: 'Juegos populares',
        brillxSelect: 'Elección de Brillx',
        daylyLiveRtp: 'RTP en directo - 24 horas',
        brillxCasino: 'Casino Brillx',
        casinoInfo1: 'Echa un vistazo a una amplia gama de juegos emocionantes aquí en Brillx.GG, un sitio de máquinas tragamonedas hecho a medida para tu satisfacción. Miles de máquinas tragamonedas de primera clase están disponibles para jugadores de todo el mundo. Ya sean los enormes botes, tragamonedas de póquer o máquinas tragamonedas temáticas que necesitas, ¡tenemos todo lo que necesitas!',
        casinoInfo2: 'Disfrute de la facilidad de hacer un check-in rápido haciendo clic en el botón de facturación ahora. Disfruta de momentos en los que juegas máquinas tragamonedas con dinero real de los principales proveedores de juegos.',
        casinoInfo3: 'Nuestro casino con pagos rápidos y una gran selección de todo tipo de variaciones de máquinas tragamonedas funciona las 24 horas del día, los 7 días de la semana, ¡solo elige un juego y comienza a jugar!',
        casinoInfo4: 'Juega a tragamonedas online',
        casinoInfo5: 'Nunca arruinamos tu diversión, así que puedes jugar a todos nuestros juegos sin descargar nada en tu ordenador.',
        casinoInfo6: 'Explora miles de tragamonedas, selecciona el juego que te guste y haz clic en «JUGAR», ¡es muy fácil!',
        casinoInfo7: 'Los juegos de tragamonedas de casino son uno de los juegos más populares. No solo por los enormes incentivos que puedes obtener, sino también por los gráficos emocionantes, la buena música y el agradable efecto visual del juego.',
        casinoInfo8: 'Incluso puedes probar cada una de nuestras máquinas tragamonedas cambiando al modo de entretenimiento, ¡una increíble función que te permite jugar a todas nuestras máquinas tragamonedas sin descargar y sin registro es gratis! Puedes acceder a él a través de tu teléfono móvil, ordenador o tablet. Descubre hoy algunas de las mejores máquinas tragamonedas que puedes jugar gratis sin necesidad de descargar y vive una aventura única en las mejores tragamonedas de casino del mundo. ¡Toma tu mejor oportunidad hoy y deja que el destino decida lo que te espera!',
        casinoInfo9: '¿Qué esperas? ¡Hoy podría ser tu día de suerte!',
        casinoInfo10: 'Miles de máquinas tragamonedas',
        casinoInfo11: 'Estos son los diferentes tipos de máquinas tragamonedas que puedes encontrar en nuestro casino:',
        casinoInfo12: '3 ranuras de carrete',
        casinoInfo13: 'Las tragamonedas de 3 carretes son más conocidas como máquinas tragamonedas clásicas o tradicionales. Los carretes son líneas verticales que puedes encontrar en el juego donde los símbolos están en cascada. Si buscas emoción, prueba a jugar en una máquina tragamonedas de 3 rodillos. Algunos de los juegos que puedes jugar son Book of Alchemy, Watford FC Slot y muchos otros.',
        casinoInfo14: '5 ranuras de carrete',
        casinoInfo15: "¡Obtén más posibilidades de ganar combinaciones jugando a una tragamonedas de 5 tambores! La mayoría de las ranuras de 5 carretes se pagan cada vez que 3, 4 o 5 caracteres caen sobre los rodillos. También puedes obtener multiplicadores de ganancias, rondas de juegos de bonificación o giros gratis según el juego. Algunos de los juegos de 5 rodillos que puedes jugar son Vikings Go Berzerk, Legacy of Dead y Tthat's Rich.",
        casinoInfo16: 'Ranuras progresivas',
        casinoInfo17: 'Las tragamonedas progresivas son una truca entre todos los tipos de máquinas tragamonedas. El tamaño del jackpot aumenta a medida que avanza el juego. ¡Hay muchos millones esperándote, y podrías convertirte en una de las personas más ricas hasta la fecha!',
        casinoInfo18: 'Ruleta',
        casinoInfo19: 'La ruleta es un juego que apuesta por números individuales en una mesa, color, tipo de número o combinaciones. Pon a prueba tu suerte y gana si lo que apostas golpea el volante. ¡Juega ahora mismo a nuestros fantásticos juegos de ruleta en tiempo real!',
        casinoInfo20: 'Poker',
        casinoInfo21: 'Si estás familiarizado con el poker en un casino normal, puedes probar a jugar ahora mismo aprovechando nuestra amplia selección de juegos de video póker online. ¡Prepárate para experimentar un juego inolvidable hoy mismo! Con animación de alta definición y excelentes rondas de videos especiales, definitivamente volverás para obtener una nueva victoria.',
        casinoInfo22: 'Bacará',
        casinoInfo23: 'El baccarat es uno de los juegos más populares del mundo. Derrota al crupier apostando en la mano más cercana a 9. Además, también puedes ganar si hay un empate. Suena emocionante, ¿en serio? ¡Prepara tus apuestas ahora y apuesta por el lado ganador!',
        casinoInfo24: 'Veintiuna',
        casinoInfo25: 'En el blackjack, puedes vencer al crupier si tienes la mano más cercana a 21. ¡Obtén la oportunidad de duplicar tu apuesta cuando juegues a nuestras majestuosas máquinas tragamonedas de blackjack!',
        gamesProviders: 'Proveedores de juegos',
        showMore: 'Mostrar más',
        hideInfo: 'Ocultar información',
        allGames: 'Todos los juegos',
        providers: 'Proveedores',
        new: 'Nuevo',
        tournaments: 'Bonus Buy',
        slots: 'Ranuras',
        roulette: 'Ruleta',
        card: 'Tarjetas',
        videopoker: 'Instant Win',
        lottery: 'Jackpot'
    },
    live: { demo: 'Demo', play: 'Jugar', title: 'Live' },
    lobby: {
        providers: 'Proveedores',
        recent: 'Recientes',
        top: 'Popular',
        new: 'Nuevo',
        favorite: 'Favoritos',
        nothingFound: 'No se encontró nada',
        play: 'JUGAR',
        demo: 'DEMO',
        forExample: 'Por ejemplo,'
    },
    log: { date: 'Fecha', type: 'Operación', amount: 'Cantidad', id: 'ID' },
    loyalty: {
        title: 'Programa de fidelización',
        description: 'Reciba un reembolso que depende del nivel de lealtad de cada apuesta realizada',
        cabinet: 'Gabinete',
        myCabinet: 'Mi oficina',
        payLog: 'Registro de devengo',
        log: 'Logue',
        level: 'Nivel',
        totalWins: 'Importe ganador',
        totalBets: 'Importe de apuesta',
        availableBalance: 'Saldo disponible',
        get: 'Llevar',
        cashback: 'Cashback',
        emptyHistory: 'Falta la historia',
        operationID: 'ID de operación',
        amount: 'Cantidad',
        date: 'Fecha',
        operationDate: 'Fecha de operación',
        operationType: 'Operación',
        balanceBefore: 'Equilibrio',
        balanceAfter: 'Balance después'
    },
    menu: {
        affProgramm: 'Socios',
        fairGame: 'Juego limpio',
        free: 'Libre',
        games: 'Juegos',
        lobby: 'Vestíbulo',
        promo: 'Promoción',
        loyaltyProgramm: 'Lealtad',
        promotions: 'Acciones',
        support: 'Mesa de ayuda',
        settings: 'Configuración',
        history: 'Historial de juegos',
        more: 'Más',
        profile: 'Perfil',
        bonusCode: 'Promo-code'
    },
    noty: { error: 'Error', success: 'Exitoso' },
    profile: {
        wallet: 'Billetera',
        onSiteFrom: 'En el sitio con',
        totalBets: 'Importe de apuesta',
        betsCount: 'Apuestas',
        totalWins: 'Importe ganador',
        bestWin: 'Mejor victoria',
        emptyHistory: 'Falta la historia',
        balanceAfter: 'Balance después'
    },
    partner: {
        maxCodeLength: 'Longitud máxima del código:',
        minCodeLength: 'Longitud mínima del código:',
        maxNameLength: 'Longitud máxima del nombre:',
        minNameLength: 'Longitud mínima del nombre:',
        symbols: 'personajes',
        confirmDeleteCampgain: '¿De verdad quieres eliminar la campaña?',
        campgains: 'Campañas',
        refresh: 'Actualizar',
        export: 'Exportar',
        campgainName: 'Nombre de la campaña',
        affiliateLink: 'Enlace afiliado',
        name: 'Título',
        creationDate: 'Fecha de creación',
        clicks: 'Transiciones',
        link: 'Ref.',
        manage: 'Gestión',
        noCampgains: 'No hay campañas publicitarias activas',
        namePlaceholder: 'Nombre que se va a mostrar en el panel',
        general: 'General',
        mainAffiliateLink: 'Enlace de afiliado principal',
        registrations: 'Inscripciones',
        totalProfit: 'Beneficio común',
        availableBalance: 'Saldo disponible',
        get: 'Llevar',
        emptyHistory: 'Falta la historia',
        operationID: 'ID de operación',
        amount: 'Cantidad',
        date: 'Fecha',
        operationDate: 'Fecha de operación',
        operationType: 'Operación',
        playerID: 'ID de jugador',
        balanceAfter: 'Balance después',
        balanceBefore: 'Equilibrio',
        log: 'Registro de devengo',
        players: 'Jugadores',
        registration: 'Inscripción',
        registrationDate: 'Fecha de inscripción',
        balance: 'Equilibrio',
        noPlayers: 'Sin jugadores invitados',
        totalBets: 'Importe de apuesta',
        totalDeps: 'Importe del depósito',
        totalWithdraws: 'Suma de conclusiones',
        registrationSource: 'Fuente de registro',
        mainLink: 'Enlace principal',
        player: 'Jugador',
        registrationsCount: 'Inscripción',
        bets: 'Staking',
        profit: 'Beneficio',
        stat: 'Estadísticas',
        totalStat: 'Estadísticas generales',
        partnerCabinet: 'Oficina de socios',
        partnerID: 'ID de socio'
    },
    privacy: {
        title: 'Política de privacidad',
        description: 'Disposiciones generales e información',
        redaction: 'Editorial de',
        content: ''
    },
    promo: {
        linkVk: 'Vincular una cuenta de VK para obtener bonificaciones',
        completeAll: 'Ajuste todas las condiciones y obtenga',
        toBalanceForRepost: '¡en balance para volver a publicar!',
        link: 'Fijaciones',
        join: 'Entrada',
        allowNotifications: 'Permitir notificaciones',
        make: 'Do',
        toProfileVkAccount: 'al perfil de cuenta de VK.',
        toOurVkGroup: 'a nuestro grupo VK.',
        fromOurGroup: 'de nuestra comunidad.',
        repostToYourPage: 'vuelve a publicar la entrada en su página.',
        mustBePublic: 'Tu página de VK debe ser pública o no podremos comprobar si hay una nueva publicación.',
        dontDelete: 'Si eliminas una publicación antes de 2 semanas después de la republicación, el importe del bono se deducirá del saldo.',
        joinAndGet: 'Únete a un grupo y consigue',
        toBalance: '¡en equilibrio!',
        bonusGranted: '¡El bono recibido correctamente!',
        momentalBonus: 'Únete a nuestro grupo de VK y obtén una bonificación instantánea en tu saldo. El perfil de VK debe ser público o no podremos comprobar si hay una suscripción.',
        vkDoesntLinked: 'Cuenta de VK no vinculada',
        allowNotificationsAndGet: 'Permitir notificaciones de nuestra comunidad y obtener',
        sendAnyMessage: 'Envía cualquier mensaje a nuestro grupo VK, permitiendo enviar notificaciones. Una buena sorpresa: periódicamente enviaremos información sobre promociones y varios bonos.',
        rollAndGetBonus: '¡Navega por tu ruleta y consigue bonificaciones!',
        rollOncePer: 'Gire la ruleta una vez cada 15 minutos y llegue hasta',
        rollEveryDay: '¡Gira el volante sin restricciones todos los días!',
        untilNextTry: 'Antes de que quede el siguiente intento',
        subscribeTg: '¡Regístrate en nuestro canal de telegramas para obtener los nuevos códigos promocionales primero!',
        beFirst: '¡Sé uno de los primeros en obtener un nuevo código promocional, aprende sobre nuevas bonificaciones e innovaciones!',
        referrals: 'Derivaciones',
        getPercent: '¡Invita a amigos y gana el 40% de los beneficios del casino!',
        copyLink: 'Copiar el enlace',
        inviteFriend: 'Invita a un amigo',
        getBonus: 'Consigue un bono',
        affiliateProgramm: 'Programa de afiliados',
        sendReview: 'Alimenta los comentarios sobre el proyecto en nuestro grupo y obtén',
        bonusToDeposit: 'bono de depósito',
        upTo: 'hasta',
        makeReviews: 'Deja comentarios en nuestro grupo VK, una vez que el moderador haya sido verificado y publicado, el bono se activará automáticamente en tu cuenta.',
        makeReview: 'Retroalimentación',
        ranks: 'Rangi',
        earnFromWin: 'Ganando rangos recibirás un mayor reembolso por cada victoria.',
        loyaltyProgramm: 'Programa de fidelización',
        writeMessage: 'Escribe un mensaje',
        goToGroup: 'Ir al grupo',
        refreshing: 'Actualización...',
        refreshStatus: 'Actualizar',
        roll: 'Girar',
        linkAccount: 'Vincular una cuenta',
        checking: 'Comprobando...',
        get: 'Conseguir'
    },
    promotions: {
        title: 'Acciones y ofertas',
        description: 'No pierdas la oportunidad de obtener un bono en nuestras acciones actuales',
        game: 'Juego',
        minDeposit: 'Depósito mín.',
        bonus: 'Bonificación',
        addWager: 'Apuesta extra',
        validUntil: 'Actúa hasta',
        activation: 'Activación',
        get: 'Conseguir',
        unlimit: 'ilimitado',
        oneTime: 'una sola vez',
        forEveryDeposit: 'por cada depósito',
        permanentBonus: 'Bonificación permanente',
        any: 'alguma'
    },
    responsibility: {
        title: 'Juego responsable',
        description: 'Consejos y reglas de juego responsable',
        content: ''
    },
    settings: {
        incorrectEmail: 'Dirección de correo electrónico incorrecta',
        bigFileSize: 'El tamaño del archivo no debe superar los 5 MB.',
        genders: { man: 'De los hombres', female: 'Mujer', other: 'Otro' },
        requiredField: 'Campo obligatorio',
        dataSuccessChanged: 'Los datos se han actualizado correctamente',
        tfaLoginRequired: 'Establecer nombre de usuario y contraseña para configurar el autenticador',
        general: 'General',
        login: 'Inicio de sesión',
        vkProfile: 'Perfil de VK',
        glProfile: 'Perfil de Google',
        okProfile: 'Compañeros de clase de perfil',
        fbProfile: 'Perfil de Facebook',
        security: 'Seguridad',
        alterLogin: 'Añade un método alternativo de inicio de sesión y contraseña a tu cuenta.',
        newPassword: 'Nueva contraseña',
        repeatPassword: 'Repetir contraseña',
        saveActions: 'Guardar acciones',
        currentPassword: 'Contraseña',
        tfa: 'Autenticación bifactor',
        addTfa: 'Proteja su cuenta de Brillx con seguridad adicional',
        tfaSettings: 'Configuración de 2FA',
        sessions: 'Sesiones',
        device: 'Dispositivo',
        region: 'Región',
        ipAddress: 'Dirección IP',
        date: 'Fecha',
        session: 'Sesión',
        active: 'Activo',
        closed: 'Cerrado',
        verification: 'Verificación',
        verificationDescription1: ' Para mejorar su nivel de servicio, solicitamos documentos de identificación. Esto mantendrá su cuenta segura si se restaura y proporcionará una entrega precisa y específica de regalos físicos.',
        verificationDescription2: 'Las formas aceptables de verificación es proporcionar una foto de su documento de identidad, licencia de conducir o pasaporte. Una vez finalizado este proceso, se lo notificaremos por correo electrónico.',
        frontPhoto: 'Foto del anverso del documento de identidad',
        selectFile: 'Seleccionar archivo',
        backPhoto: 'Foto posterior del documento de identidad',
        fileIsNotSelected: 'Archivo no seleccionado',
        name: 'Nombre',
        surname: 'Apellidos',
        birthday: 'Fecha de nacimiento',
        gender: 'Género',
        country: 'País',
        city: 'Ciudad',
        yourName: 'Tu nombre',
        yourSurname: 'Tu apellido',
        verifyStatus1: 'Se están revisando los documentos...',
        verifyStatus2: 'Cuenta verificada',
        verifyStatus3: 'Error de comprobación de documentos',
        verifyDescription1: 'Los documentos están siendo revisados, tan pronto como se verifiquen los documentos, su cuenta será verificada.',
        verifyDescription2: 'Sus datos han sido verificados y confirmados por el personal de seguridad.',
        verifyDescription3: 'Póngase en contacto con el soporte técnico para obtener detalles.',
        registrationCity: 'Ciudad de registro',
        sendDocs: 'Presentar para inspección',
        affiliateCabinet: 'Oficina de socios'
    },
    tos: {
        title: 'Acuerdo de usuario',
        description: 'Términos y reglas de uso',
        redaction: 'Editorial de',
        content: ''
    },
    wallet: {
        deposit: 'Depósito',
        withdraw: 'Salida',
        history: 'Historia',
        minDepositAmount: 'Depósito mínimo mediante',
        minWithdrawAmount: 'Salida mínima a través',
        topMethods: 'Popular',
        allMethods: 'Todos los métodos',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'Otro',
        success: 'Exitoso',
        canceled: 'Cancelado',
        error: 'Error',
        waiting: 'Esperando',
        processing: 'La solicitud se está tramitando',
        incorrectWalletValue: 'Número de billetera introducido incorrectamente',
        incorrectCardValue: 'Número de tarjeta introducido incorrectamente',
        selectTemplate: 'Elegir plantilla',
        withdrawCreated: 'Se crea la solicitud de pago y se procesará en un plazo de 24 horas',
        withdrawCanceled: 'Se ha cancelado la puja de pago',
        firstWithdraw: 'Esta es tu primera conclusión',
        isDataCorrect: '¿Confía en que la entrada de datos es correcta?',
        payMethod: 'Método de pago',
        withdrawMethod: 'Método de retirada',
        selectMethod: 'Elige una forma',
        selectOperator: 'Seleccionar operador',
        refillAmount: 'Reabastecimiento',
        withdrawAmount: 'Importe de retiro',
        payTroubles: 'Si tienes problemas para pagar prueba',
        payHere: 'Paga aquí',
        pay: 'Ir al pago',
        commission: 'Comisión',
        onePayLimit: 'Límite de reabastecimiento único',
        oneWithdrawLimit: 'Límite de salida única',
        emptyHistory: 'Falta la historia',
        operationID: 'ID de operación',
        deposits: 'Reabastecimientos',
        withdraws: 'Hallazgos',
        txAmount: 'Importe de transacción',
        txDate: 'Fecha de operación',
        purse: 'Puntuación',
        typePurse: 'Número de cuenta',
        toGet: 'Para recibir',
        createWithdraw: 'Crear una aplicación'
    },
    tfa: {
        tfa: 'Autenticación bifactor',
        typeYourPassword: 'Introduzca su contraseña',
        enterPin: 'Introduce un código de 6 dígitos desde la aplicación',
        downloadApp: 'Descargue la aplicación',
        downloadAndInstall: 'Descargar e instalar',
        forYourPhone: 'para tu teléfono o tablet',
        scanQR: 'escanear código QR',
        scanQRWithCamera: 'Escanea el QR de la izquierda con la cámara de tu teléfono',
        openWithLink: 'Abrir por enlace',
        openInApp: 'Abrir en aplicación',
        tfaManualEnter: 'Tecla 2FA (entrada manual)'
    },
    pwa: {
        androidStep1: 'Abra o navegador Chrome',
        androidStep1Description: 'Se você estiver em qualquer outro navegador, abra esta página no Chrome',
        androidStep2: 'Clique no menu do navegador',
        androidStep2Description: 'O menu do navegador são as reticências encontradas no canto superior direito do seu navegador',
        androidStep3: 'Selecione "Adicionar à tela inicial"',
        androidStep3Description: 'Você verá uma janela com informações ("Adicionar à tela inicial"), na qual deve selecionar "Adicionar", após a qual o aplicativo será adicionado à área de trabalho do seu dispositivo',
        androidAlert: 'As etapas podem variar dependendo da versão do navegador',
        iosStep1: 'Abra o navegador Safari',
        iosStep1Description: 'Se você estiver em qualquer outro navegador, abra esta página no Safari',
        iosStep2: 'Clique em "Compartilhar"',
        iosStep2Description: 'Clique no botão "Compartilhar" na parte inferior da tela, após o qual a janela de seleção de parâmetros será aberta',
        iosStep3: 'Selecione "Adicionar à tela inicial"',
        iosStep3Description: 'Selecione "Adicionar à tela inicial" e clique em "Adicionar"',
        alert: 'As etapas podem variar dependendo da versão do navegador',
        installApp: 'Instalar aplicativo Brillx',
        back: 'Voltar',
        app: 'Aplicativo móvel',
        installAppForMobile: 'Instale o novo aplicativo Brillx conveniente para iOS e Android',
        install: 'Instalar'
    },
    undefined: '???'
};