import React from 'react';
import IconStatic from '../components/IconStatic';

import WalletModal from '../modules/modals/Wallet';

export default class Help extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);

        this.props.t.updateState(this.props.t, 'location', 'help');
        document.title = this.props.t.Interface.getText(this.props.t, 'menu', 'support') + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, 'location', '/');
    }

    shouldComponentUpdate() {
        let needUpdate = false;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width, this.props.t.state.Help.open], [this.pState.width, this.pState.Help.open]);
    }

    showMore(id) {
        if (typeof this.props.t.state.Help.open[id] === "undefined" || !this.props.t.state.Help.open[id])
            this.props.t.state.Help.open[id] = true;
        else
            this.props.t.state.Help.open[id] = false;

        this.props.t.updateState(this.props.t, 'Help', this.props.t.state.Help.open, 'open');
    }

    getCollapses() {
        return [
            {
                title: this.props.t.Interface.getText(this.props.t, 'help', 'generalQuestions'), methods: [
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'aboutSite'), content: this.props.t.Interface.getText(this.props.t, 'help', 'aboutSiteContent') },
                    {
                        title: this.props.t.Interface.getText(this.props.t, 'help', 'bonuses'), content: <><p>{this.props.t.Interface.getText(this.props.t, 'help', 'onPage')} <a onClick={() => { this.props.t.state.userID ? this.props.t.doRoute('/promo') : this.props.t.Interface.requireAuth(this.props.t, false, '/promo') }} href="#">{this.props.t.Interface.getText(this.props.t, 'help', 'bonuses')}</a> {this.props.t.Interface.getText(this.props.t, 'help', 'manyFreeBonuses')}</p>
                            <p><strong className={this.props.t.styles['green']}>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus')} №1</strong> <br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus1')}</p>
                            <p><strong className={this.props.t.styles['green']}>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus')} №2</strong> <br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus2')}</p>
                            <p><strong className={this.props.t.styles['green']}>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus')} №3 - {this.props.t.Interface.getText(this.props.t, 'help', 'promocodes')}</strong> <br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus3')}</p>
                            <p><strong className={this.props.t.styles['green']}>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus')} №4 - {this.props.t.Interface.getText(this.props.t, 'help', 'inviteFriend')}</strong> <br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'bonus4')}</p></>
                    }
                ]
            },
            {
                title: this.props.t.Interface.getText(this.props.t, 'help', 'balanceRefilling'), methods: [
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'howToRefill'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'clickToRefill')} <a href="#" onClick={() => { this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/help') }}>{this.props.t.Interface.getText(this.props.t, 'help', 'wallet')}</a> {this.props.t.Interface.getText(this.props.t, 'help', 'atRightCorner')}<br></br><br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'minimalRefilling')}: {this.props.t.config.wallet.deposit.min}  <IconStatic t={this.props.t} name='rub' /></> },
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'commissions'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'commissionsOnPage')} <a href="#" onClick={() => { this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/help') }}>{this.props.t.Interface.getText(this.props.t, 'help', 'wallet')}</a> </> },
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'periodOfRefilling'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'allTransactions')} <br></br><br></br> {this.props.t.Interface.getText(this.props.t, 'help', 'inCaseOfProblems')} </> }
                ]
            },
            {
                title: this.props.t.Interface.getText(this.props.t, 'help', 'withdrawal'), methods: [
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'howToWithdraw'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'clickToWithdraw')} <a href="#" onClick={() => { this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/help') }}>{this.props.t.Interface.getText(this.props.t, 'help', 'wallet')}</a> {this.props.t.Interface.getText(this.props.t, 'help', 'atRightCornerWithdraw')} <br></br><br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'minimalWithdrawal')}: {this.props.t.config.wallet.withdraw.min}  <IconStatic t={this.props.t} name='rub' /></> },
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'commissions'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'commissionsOnPage')} <a href="#" onClick={() => { this.props.t.state.userID ? this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) : this.props.t.Interface.requireAuth(this.props.t, false, '/help') }}>{this.props.t.Interface.getText(this.props.t, 'help', 'wallet')}</a> </> },
                    { title: this.props.t.Interface.getText(this.props.t, 'help', 'periodOfWithdrawal'), content: <> {this.props.t.Interface.getText(this.props.t, 'help', 'averageTimeWithdraw')} <br></br><br></br> {this.props.t.Interface.getText(this.props.t, 'help', 'ifBigAmount')} <br></br><br></br> {this.props.t.Interface.getText(this.props.t, 'help', 'sometimes')} <br></br><br></br>{this.props.t.Interface.getText(this.props.t, 'help', 'checkCorrectPurse')}</> }
                ]
            }
        ];
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['helpContainer']}>
                        <div className={this.props.t.styles['headerBlock']}><div className={this.props.t.styles['caption']}><h1>{this.props.t.Interface.getText(this.props.t, 'help', 'title')}</h1></div><div className={this.props.t.styles['info']}>{this.props.t.Interface.getText(this.props.t, 'help', 'description')}</div></div>
                        <div className={this.props.t.styles['helpBlock']}>
                            <div className={this.props.t.styles['caption']}>
                                <span>{this.props.t.Interface.getText(this.props.t, 'help', 'connectTypes')}</span>
                                <div className={this.props.t.styles['badge']}>
                                    {this.props.t.Interface.getText(this.props.t, 'help', 'operatorsOnline')}
                                </div>
                            </div>
                            <div className={this.props.t.styles['feedback']}>
                                <div className={this.props.t.styles['item']}>
                                    <div className={this.props.t.styles['feedbackContent']}>
                                        <div className={this.props.t.styles['feedbackIcon']}>
                                            <IconStatic t={this.props.t} name={"support"} />
                                        </div>
                                        <div className={this.props.t.styles['textWrapper']}>
                                            <div className={this.props.t.styles['title']}>
                                                {this.props.t.Interface.getText(this.props.t, 'help', 'supportLive')}
                                            </div><button onClick={() => { window.Chatra('openChat', true) }} type="button">{this.props.t.Interface.getText(this.props.t, 'help', 'onlineChat')}</button>
                                            <div>
                                                {this.props.t.Interface.getText(this.props.t, 'help', 'averageTimeChat')}
                                            </div>
                                        </div><button onClick={() => { window.Chatra('openChat', true) }} className={this.props.t.styles['arrow']} type="button"><IconStatic t={this.props.t} name={"left"} /></button>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['item']}>
                                    <div className={this.props.t.styles['feedbackContent']}>
                                        <div className={this.props.t.styles['feedbackIcon']}>
                                            <IconStatic t={this.props.t} name={"envelope"} />
                                        </div>
                                        <div className={this.props.t.styles['textWrapper']}>
                                            <div className={this.props.t.styles['title']}>
                                                {this.props.t.Interface.getText(this.props.t, 'help', 'supportEmail')}
                                            </div><a href={this.props.t.config.social.mail.link} rel="noreferrer noopener nofollow" target="_blank">{this.props.t.config.social.mail.name}</a>
                                            <div>
                                                {this.props.t.Interface.getText(this.props.t, 'help', 'averageTimeEmail')}
                                            </div>
                                        </div><a className={this.props.t.styles['arrow']} href={this.props.t.config.social.mail.link} rel="noreferrer noopener nofollow" target="_blank"><IconStatic t={this.props.t} name={"left"} /></a>
                                    </div>
                                </div>
                            </div>

                            {this.getCollapses().map((item, i) =>
                                <React.Fragment key={i}>
                                    <div className={this.props.t.styles['caption']}>
                                        {item.title}
                                    </div>
                                    {item.methods.map((method, j) =>
                                        <div key={j} onClick={() => { this.showMore(i + '-' + j) }} className={[this.props.t.styles['collapseBlock'], typeof this.props.t.state.Help.open[i + '-' + j] !== "undefined" && this.props.t.state.Help.open[i + '-' + j] ? this.props.t.styles['open'] : ''].join(' ')}>
                                            <div className={this.props.t.styles['top']}>
                                                <button className={[this.props.t.styles['btn'], this.props.t.styles['btnTransparent']].join(' ')} type="button">{method.title}</button>
                                                <div className={this.props.t.styles['after']}>
                                                    <IconStatic t={this.props.t} name={"down"} />
                                                </div>
                                            </div>
                                            <div className={[this.props.t.styles['collapse'], typeof this.props.t.state.Help.open[i + '-' + j] !== "undefined" && this.props.t.state.Help.open[i + '-' + j] ? this.props.t.styles['open'] : ''].join(' ')}>
                                                <div className={this.props.t.styles['collapseContent']}>
                                                    {method.content}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}