import React from 'react';
import IconStatic from '../../components/IconStatic';


import SimpleBar from 'simplebar-react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Tooltip } from 'reactstrap';

import Loader from '../../assets/svg/loader.svg';

import 'simplebar/dist/simplebar.min.css';

class WalletDeposit extends React.Component {

    componentDidMount() {
        this.props.t.Interface.WalletGetPayways(this.props.t);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "form", { data: this.props.t.config.wallet.deposit.defaultValue, error: false }, "wallet", "deposit");
    }

    render() {
        return (
            <>
                {this.props.t.state.width > 900 ?
                    <div className={this.props.t.styles['contentLeft']}>
                        <div className={[this.props.t.styles['label'], this.props.t.styles['simpleLabel']].join(' ')}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'payMethod')}
                        </div>

                        <SimpleBar style={{ maxHeight: '400px' }}>
                            {this.props.t.state.Wallet.process ? <span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span> :
                                this.props.t.state.Wallet.payWays.map((method, index) => (
                                    !method.crypto && !method.mobile ?
                                        <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'deposit', 'currentMethod'), this.props.t.state.form.wallet.deposit.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : this.props.t.state.form.wallet.deposit.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={[this.props.t.styles['simpleMethod'], this.props.t.state.Wallet.deposit.currentMethod === method.method || (method.method === "mobile" && this.props.t.Interface.inArray(this.props.t.state.Wallet.deposit.currentMethod, this.props.t.state.Wallet.payWaysMobile)) || (method.method === "crypto" && this.props.t.Interface.inArray(this.props.t.state.Wallet.deposit.currentMethod, this.props.t.state.Wallet.payWaysCrypto)) ? this.props.t.styles['active'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['image']}><img alt="" draggable="false" src={method.img}></img>
                                            </div>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}</button> : <></>
                                ))}
                        </SimpleBar>
                    </div> : <></>}

                <div className={[this.props.t.styles['contentRight'], this.props.t.state.width <= 900 ? this.props.t.styles['mobile'] : ''].join(' ')}>
                    <div className={this.props.t.styles['contentWrap']}>
                        {this.props.t.state.width <= 900 ?
                            <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                <div className={this.props.t.styles['label']}>
                                    {this.props.t.Interface.getText(this.props.t, 'wallet', 'selectMethod')}
                                </div>
                                <div className={this.props.t.styles['walletContentWrapper']}>
                                    <div className={this.props.t.styles['dropdown']}>
                                        <button onClick={() => this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.deposit.open, 'deposit', 'open')} className={[this.props.t.styles['btn'], this.props.t.state.Wallet.deposit.open ? this.props.t.styles['open'] : ''].join(' ')} type="button">
                                            <div className={this.props.t.styles['btnContent']}>
                                                <div className={this.props.t.styles['selectedMethod']}>
                                                    <img alt="" draggable="false" src={this.props.t.Interface.getPaywayImage(this.props.t, this.props.t.state.Wallet.deposit.currentMethod)}></img>{this.props.t.state.Wallet.deposit.currentMethod ? this.props.t.Interface.getText(this.props.t, 'wallet', this.props.t.state.Wallet.deposit.currentMethod.replace(/[0-9]/g, '')) : this.props.t.Interface.getText(this.props.t, 'wallet', 'selectMethod')} {this.props.t.state.Wallet.deposit.currentMethod && this.props.t.state.Wallet.deposit.currentMethod.match(/\d+/) !== null && this.props.t.state.Wallet.deposit.currentMethod.match(/\d+/)[0] !== "" ? '№' + this.props.t.state.Wallet.deposit.currentMethod.match(/\d+/)[0] : ''}
                                                </div>
                                                <div className={[this.props.t.styles['opener'], this.props.t.state.Wallet.deposit.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                                    <IconStatic t={this.props.t} name='down' />
                                                </div>
                                            </div></button>
                                        <div className={[this.props.t.styles['menu'], this.props.t.state.Wallet.deposit.open ? this.props.t.styles['open'] : ''].join(' ')}>
                                            <PerfectScrollbar className={this.props.t.styles['scrollBar']}>
                                                <div className={this.props.t.styles['methodsBlock']}>
                                                    <div className={this.props.t.styles['blockName']}>
                                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'topMethods')}
                                                    </div>
                                                    <div className={this.props.t.styles['blockItems']}>
                                                        {this.props.t.state.Wallet.payWays.slice(0, 4).map((method, index) => (
                                                            !method.crypto && !method.mobile ?
                                                                <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'deposit', 'currentMethod'), this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.deposit.open, 'deposit', 'open'), this.props.t.state.form.wallet.deposit.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : this.props.t.state.form.wallet.deposit.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={this.props.t.styles['item']} type="button">
                                                                    <div className={this.props.t.styles['itemContent']}>
                                                                        <img alt="" draggable="false" src={method.img}></img>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}
                                                                        <div className={this.props.t.styles['comission']}>0%</div>
                                                                    </div></button> : <></>
                                                        ))}
                                                    </div>

                                                    <div className={this.props.t.styles['blockName']}>
                                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'allMethods')}
                                                    </div>
                                                    <div className={this.props.t.styles['blockItems']}>

                                                        {this.props.t.state.Wallet.payWays.slice(4).map((method, index) => (
                                                            !method.crypto && !method.mobile ?
                                                                <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method === "mobile" ? this.props.t.state.Wallet.payWaysMobile[0] : method.method === "crypto" ? this.props.t.state.Wallet.payWaysCrypto[0] : method.method, 'deposit', 'currentMethod'), this.props.t.updateState(this.props.t, 'Wallet', !this.props.t.state.Wallet.deposit.open, 'deposit', 'open'), this.props.t.state.form.wallet.deposit.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : this.props.t.state.form.wallet.deposit.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={this.props.t.styles['item']} type="button">
                                                                    <div className={this.props.t.styles['itemContent']}>
                                                                        <img alt="" draggable="false" src={method.img}></img>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method.replace(/[0-9]/g, ''))} {method.method.match(/\d+/) !== null && method.method.match(/\d+/)[0] !== "" ? '№' + method.method.match(/\d+/)[0] : ''}
                                                                        <div className={this.props.t.styles['comission']}>0%</div>
                                                                    </div></button> : <></>
                                                        ))}
                                                    </div>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <></>}

                        {this.props.t.state.Wallet.deposit.currentMethod === "mobile" || this.props.t.state.Wallet.deposit.currentMethod === "crypto" || this.props.t.Interface.inArray(this.props.t.state.Wallet.deposit.currentMethod, this.props.t.state.Wallet.payWaysMobile) || this.props.t.Interface.inArray(this.props.t.state.Wallet.deposit.currentMethod, this.props.t.state.Wallet.payWaysCrypto) ?
                            <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                                <div className={this.props.t.styles['label']}>
                                    {this.props.t.Interface.getText(this.props.t, 'wallet', 'selectOperator')}
                                </div>
                                <div className={this.props.t.styles['walletContentWrapper']}>
                                    <div className={this.props.t.styles['subButtons']}>
                                        {this.props.t.state.Wallet.deposit.currentMethod === "mobile" || this.props.t.Interface.inArray(this.props.t.state.Wallet.deposit.currentMethod, this.props.t.state.Wallet.payWaysMobile) ?
                                            this.props.t.state.Wallet.payWays.map((method, index) => (
                                                method.mobile ?
                                                    <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method, 'deposit', 'currentMethod'), this.props.t.state.form.wallet.deposit.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : this.props.t.state.form.wallet.deposit.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={this.props.t.state.Wallet.deposit.currentMethod === method.method ? this.props.t.styles['active'] : ''} type="button">
                                                        <img alt="" src={method.img}></img><span>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method)}</span></button> : <></>
                                            )) :
                                            this.props.t.state.Wallet.payWays.map((method, index) => (
                                                method.crypto ?
                                                    <button key={index} onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', method.method, 'deposit', 'currentMethod'), this.props.t.state.form.wallet.deposit.data < this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').min, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : this.props.t.state.form.wallet.deposit.data > this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max ? this.props.t.updateState(this.props.t, 'form', { data: this.props.t.Interface.getPaymentLimits(this.props.t, method.method, 'deposit').max, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit') : {}, this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={this.props.t.state.Wallet.deposit.currentMethod === method.method ? this.props.t.styles['active'] : ''} type="button">
                                                        <img alt="" src={method.img}></img><span>{this.props.t.Interface.getText(this.props.t, 'wallet', method.method)}</span></button> : <></>
                                            ))}
                                    </div>
                                </div>
                            </div> : <></>}
                        <div className={[this.props.t.styles['formWithLabel'], this.props.t.styles['hasWrap']].join(' ')}>
                            <div className={this.props.t.styles['label']}>
                                {this.props.t.Interface.getText(this.props.t, 'wallet', 'refillAmount')}
                            </div>
                            <div className={this.props.t.styles['walletContentWrapper']}>
                                <div className={this.props.t.styles['inputButtons']}>

                                    <div className={this.props.t.styles['formField']}>
                                        <input onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'wallet', 'deposit')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'wallet', 'deposit') }} id="inputDeposit" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.styles['inputWithIcon'], this.props.t.state.form.wallet.deposit.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="amount" type="number" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.wallet.deposit.error }, 'wallet', 'deposit'); this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit') }} value={this.props.t.state.form.wallet.deposit.data}></input>
                                        <Tooltip isOpen={(this.props.t.state.width <= 900 || (this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show)) && !this.props.t.state.inputs.wallet.deposit && this.props.t.state.form.wallet.deposit.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputDeposit">
                                            {this.props.t.state.form.wallet.deposit.error}
                                        </Tooltip>
                                        <div className={this.props.t.styles['inputIcon']}>
                                            <IconStatic t={this.props.t} name='rub' />
                                        </div>
                                    </div>

                                </div>
                                <div className={this.props.t.styles['buttonsContainer']}>
                                    {this.props.t.config.wallet.buttons.map((button, index) => (
                                        <div key={index} className={this.props.t.styles['btnItem']}>
                                            <button onClick={() => (this.props.t.updateState(this.props.t, 'Wallet', this.props.t.state.Wallet.deposit.amountButton === button ? false : button, 'deposit', 'amountButton'), this.props.t.updateState(this.props.t, 'form', button, 'wallet', 'deposit', 'data'), this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'))} className={this.props.t.state.Wallet.deposit.amountButton === button ? this.props.t.styles['active'] : ''} type="button"><span className={this.props.t.styles['value']}>{button} <IconStatic t={this.props.t} name='rub' /></span></button>
                                        </div>
                                    ))}
                                </div>
                                <div className={this.props.t.styles['formSubmit']}>
                                    <button disabled={this.props.t.state.Wallet.process} onClick={() => { this.props.t.Interface.checkForm(this.props.t, 'wallet', 'deposit'); !this.props.t.state.form.wallet.deposit.error && this.props.t.Interface.WalletCreateDeposit(this.props.t) }}> {this.props.t.state.Wallet.process ? this.props.t.Interface.getText(this.props.t, 'auth', 'loading') : this.props.t.Interface.getText(this.props.t, 'wallet', 'pay')}</button>
                                </div>
                                {false && this.props.t.state.Wallet.deposit.currentMethod === "card" &&
                                    <div className={this.props.t.styles['fallBackCard']}>
                                        {this.props.t.Interface.getText(this.props.t, 'wallet', 'payTroubles')}
                                        <div className={this.props.t.styles['fallBackCardSubmit']}>
                                            <button> {this.props.t.Interface.getText(this.props.t, 'wallet', 'payHere')}</button>
                                        </div>
                                    </div>}
                            </div>
                        </div >
                    </div >
                    <div className={this.props.t.styles['contentFooter']}>
                        <div className={this.props.t.styles['comission']}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'commission')}: <span className={this.props.t.bstyles['text-white']}>{this.props.t.config.wallet.deposit.comission}%</span>
                        </div>{this.props.t.state.width > 900 && <>&nbsp;/&nbsp;</>}
                        <div className={this.props.t.styles['details']}>
                            {this.props.t.Interface.getText(this.props.t, 'wallet', 'onePayLimit')}: <span className={this.props.t.styles['info']}>&nbsp;{this.props.t.config.wallet.deposit.min}  <IconStatic t={this.props.t} name='rub' />&nbsp;−&nbsp;{this.props.t.config.wallet.deposit.max}  <IconStatic t={this.props.t} name='rub' /></span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WalletDeposit;