import React from 'react';
import Svg from '../components/Svg';
import IconStatic from '../components/IconStatic';

import QIWI from '../assets/png/PaySystems/qiwi.png';
import Card from '../assets/png/PaySystems/card.png';
import YM from '../assets/png/PaySystems/ym.png';
import Payeer from '../assets/png/PaySystems/payeer.png';
import Bitcoin from '../assets/png/PaySystems/bitcoin.png';
import USDT from '../assets/png/PaySystems/usdt.png';
import MTS from '../assets/png/PaySystems/mts.png';
import Megafon from '../assets/png/PaySystems/megafon.png';
import Tele2 from '../assets/png/PaySystems/tele2.png';
import Beeline from '../assets/png/PaySystems/beeline.png';

const paySystems = [QIWI, Card, YM, Payeer, Bitcoin, USDT, MTS, Megafon, Tele2, Beeline];

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    componentDidMount() {
        this.lState = window.location.pathname;
    }

    shouldComponentUpdate() {
        let needUpdate = window.location.pathname !== this.lState;
        let needUpdate2 = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.width, this.props.t.state.isWallet], [this.pState.width, this.pState.isWallet]);

        if (needUpdate)
            this.lState = window.location.pathname;

        if (needUpdate2)
            this.pState = this.props.t.cloneDeep(this.props.t.state);
        
        return needUpdate || needUpdate2;
    }

    render() {
        return (!this.props.t.state.isWallet && this.props.t.state.width <= 900) || this.props.t.state.width > 900 ? (<div className={[this.props.t.styles['footer'], window.location.pathname === '/' ? this.props.t.styles['landingMode'] : ''].join(' ')}>
            {window.location.pathname === "/" ?
                (<div className={this.props.t.styles['paySystems']}>
                    {paySystems.map((img, index) => (
                        <div key={index} className={this.props.t.styles['paySystemImage']}>
                            <img src={img} />
                        </div>
                    ))}

                </div>) : (<></>)}
            <div className={this.props.t.styles['footerContent']}>
                <div className={this.props.t.styles['footerLeft']}>
                    <div className={this.props.t.styles['footerCopyright']}>Copyright © 2021 {this.props.t.config.name}. All rights reserved.</div>
                    <ul className={[this.props.t.styles['footerNav'], this.props.t.styles['footerSocial']].join(' ')}>
                        <li>
                            <a href={this.props.t.config.social.vk.link} rel="noopener noreferrer nofollow" target="_blank">
                                <IconStatic t={this.props.t} name={"vk"} />
                                <span>{this.props.t.config.social.vk.name}</span></a>
                        </li>
                        <li>
                            <a href={this.props.t.config.social.tg.link} rel="noopener noreferrer nofollow" target="_blank">
                                <IconStatic t={this.props.t} name={"telegram"} />
                                <span>{this.props.t.config.social.tg.name}</span></a>
                        </li>
                        <li>
                            <a href={this.props.t.config.social.mail.link} rel="noopener noreferrer nofollow" target="_blank">
                                <IconStatic t={this.props.t} name={"envelope"} />
                                <span>{this.props.t.config.social.mail.name}</span></a>
                        </li>
                    </ul>
                    <ul className={this.props.t.styles['footerNav']}>
                        <li>
                            <a href="#" onClick={() => { this.props.t.doRoute('/responsibility') }}>Responsible Gaming Policy</a>
                        </li>
                        <li>
                            <a href="#" onClick={() => { this.props.t.doRoute('/tos') }}>Terms and Conditions</a>
                        </li>
                        <li>
                            <a href="#" onClick={() => { this.props.t.doRoute('/privacy') }}>Privacy policy</a>
                        </li>
                        <li>
                            <a href="#" onClick={() => { this.props.t.doRoute('/aml') }}>AML</a>
                        </li>
                        <li>
                            {this.props.t.config.version}
                        </li>
                    </ul>
                </div>
                <div className={this.props.t.styles['footerRight']}>
                    <div className={this.props.t.styles['footerName']}>{this.props.t.config.ltd}</div>
                    <div className={this.props.t.styles['footerLogos']}>
                        <div className={this.props.t.styles['footerItem']}>
                            <a href="#" onClick={() => { this.props.t.doRoute('/fair') }}>
                                <Svg className={this.props.t.styles['footerLicense']} t={this.props.t} name="license" />
                            </a>
                        </div>
                        <div className={this.props.t.styles['footerItem']}>
                            <a href="#" onClick={() => { this.props.t.doRoute('/responsibility') }}>
                                <Svg className={this.props.t.styles['footerCert']} t={this.props.t} name="aware-18-plus" />
                            </a>
                        </div>
                        <div className={this.props.t.styles['footerItem']}>
                            <a href="#" onClick={() => { this.props.t.doRoute('/') }}>
                                <img src={this.props.t.logo}></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>) : <></>
    }
}
