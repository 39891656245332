import React from 'react';
import IconStatic from '../components/IconStatic';

import WalletModal from '../modules/modals/Wallet';

import { Redirect, withRouter, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Dimensions } from 'react-native';

const window = Dimensions.get('window');

class Game extends React.Component {
    constructor(props) {
        super(props);
        this.props.t.updateState(this.props.t, 'Game', this.props.match.params.mode, 'mode');
        this.props.t.updateState(this.props.t, 'Game', this.props.match.params.id, 'id')
        this.props.t.updateState(this.props.t, 'Game', false, 'data');
        this.props.t.updateState(this.props.t, 'Game', false, 'providerData');
        this.props.t.updateState(this.props.t, 'Game', false, 'iframe');
        this.loadStats = false;
    }

    state = {
        iframeOffset: 0,
        width: window.width,
        height: window.height
    }

    componentDidMount() {
        this.props.t.Interface.loadGameInfo(this.props.t);

        this.setState({ width: Dimensions.get('window').width, height: Dimensions.get('window').height });
        Dimensions.addEventListener('change', this.updateDimensions)

        this.updateDimensionsIframe();

        this.props.t.updateState(this.props.t, 'location', 'game');

        if (this.props.t.state.userID && !this.props.t.state.Lobby.recentGames.length && !this.props.t.state.Lobby.favoriteGames.length) {
            this.props.t.Interface.LobbyGetCData(this.props.t);
            setTimeout(() => { this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.recentGames.concat(this.props.match.params.id), 'recentGames'); this.props.t.Interface.LobbyUpdateCData(this.props.t) }, 5000);
        } else if (this.props.t.state.userID) {
            this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.recentGames.concat(this.props.match.params.id), 'recentGames');
            this.props.t.Interface.LobbyUpdateCData(this.props.t);
        }

        this.props.t.Interface.GameGetStats(this.props.t, this.props.t.state.Game.id);
        this.loadStats = setInterval(() => { this.props.t.Interface.GameGetStats(this.props.t, this.props.t.state.Game.id) }, this.props.t.config.timers.Game.loadStats * 1000);
        this.props.t.Interface.Ping(this.props.t);
    }

    updateDimensions = (e) => {
        const { width, height } = e.window;
        this.setState({ width, height });
        this.updateDimensionsIframe();
    }

    componentDidUpdate() {
        if (this.props.match.params.mode !== this.props.t.state.Game.mode || this.props.match.params.id !== this.props.t.state.Game.id) {
            this.props.t.updateState(this.props.t, 'Game', { bets: 0, win: 0, top: 0, count: 0 }, 'stats');
            this.props.t.updateState(this.props.t, 'Game', this.props.match.params.mode, 'mode');
            this.props.t.updateState(this.props.t, 'Game', this.props.match.params.id, 'id')
            this.props.t.updateState(this.props.t, 'Game', false, 'data');
            this.props.t.updateState(this.props.t, 'Game', false, 'providerData');
            this.props.t.updateState(this.props.t, 'Game', false, 'iframe');
            
            this.props.t.Interface.loadGameInfo(this.props.t);
        }

        let title = this.props.t.state.Game.data && typeof this.props.t.state.Game.data.params !== "undefined" ? this.props.t.state.Game.data.params.name : this.props.t.Interface.getText(this.props.t, 'game', 'loading');
        document.title = title + ' - ' + this.props.t.config.name;
    }

    componentWillUnmount() {
        Dimensions.removeEventListener('change', this.updateDimensions);
        this.props.t.updateState(this.props.t, 'location', '/');
        clearInterval(this.loadStats);
        this.props.t.updateState(this.props.t, 'Game', { bets: 0, win: 0, top: 0, count: 0 }, 'stats');
    }

    updateDimensionsIframe = () => {
        clearInterval(this.resizeDelay);
        this.resizeDelay = setTimeout(() => {
            let headerOffset = typeof document.getElementById('header') !== "undefined" ? document.getElementById('header').offsetHeight : 0, controlsOffset = this.controls !== null && typeof this.controls !== "undefined" ? this.controls.offsetHeight : 0;
            this.setState({ iframeOffset: this.state.height - controlsOffset - headerOffset - 40 > 669 ? 669 : this.state.height - controlsOffset - headerOffset - 40 });
        }, 0);
    };

    iframeFullscreen() {
        this.iframe.requestFullscreen();
    }

    render() {
        return (
            <>
                {!this.props.t.state.Game.id || !this.props.t.state.Game.mode || (!this.props.t.state.userID && this.props.t.state.Game.mode !== "demo") ? <Redirect to="/" /> : <></>}
                <div className={this.props.t.styles['section']}>
                    <div className={this.props.t.styles['gameWrapper']}>
                        {!isMobile && this.state.width > 600 ? (
                            <>
                                <div ref={(controls) => (this.controls = controls)} className={this.props.t.styles['gameControls']}>
                                    <div className={this.props.t.styles['gameInfo']}>
                                        <span onClick={() => this.props.t.doRoute('/lobby')} className={this.props.t.styles['gameProvider']}>{this.props.t.Interface.getText(this.props.t, 'game', 'lobby')}</span>
                                        <IconStatic t={this.props.t} name="down" />
                                        {this.state.width > 991 ?
                                            (<><span onClick={() => this.props.t.doRoute(this.props.t.state.Game.providerData ? ('/lobby/' + this.props.t.state.Game.providerData.id + '/0') : '/lobby')} className={this.props.t.styles['gameProvider']}>{this.props.t.state.Game.providerData ? this.props.t.state.Game.providerData.name : this.props.t.Interface.getText(this.props.t, 'game', 'loading')}</span>
                                                <IconStatic t={this.props.t} name="down" /></>) : (<></>)}
                                        <span className={this.props.t.styles['gameName']}>{this.props.t.state.Game.data ? this.props.t.state.Game.data.params.name.slice(0, this.props.t.config.limits.Game.name) + (this.props.t.state.Game.data.params.name.length > this.props.t.config.limits.Game.name ? '...' : '') : this.props.t.Interface.getText(this.props.t, 'game', 'loading')}</span>
                                    </div>
                                    <div className={this.props.t.styles['gameButtons']}>
                                        <button onClick={() => this.iframeFullscreen()} className={[this.props.t.styles['btn'], this.props.t.styles['btn-light']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'game', 'fullScreen')}</button>
                                        <button onClick={() => { this.props.t.updateState(this.props.t, 'modal', { show: true, content: WalletModal, className: this.props.t.styles['walletModal'] }) }} className={[this.props.t.styles['btn'], this.props.t.styles['btnGreen']].join(' ')} type="button">{this.props.t.Interface.getText(this.props.t, 'game', 'deposit')}</button>
                                    </div>
                                </div>
                                <div className={this.props.t.styles['gameLogo']}><img alt={this.props.t.config.name} src={this.props.t.logo}></img></div>
                            </>
                        ) : (<></>)}
                        <div style={this.state.width > 900 && !isMobile ? { paddingBottom: this.state.iframeOffset + 'px' } : {}} className={this.props.t.styles['gameBlock']}>
                            {isMobile || this.state.width <= 600 ? (<>
                                {this.props.t.state.Game.mobileOverlay.open && <div className={this.props.t.styles['mobileOverlay']}>
                                    <div onClick={() => { !this.props.t.state.userID ? this.props.t.Interface.requireAuth(this.props.t, false, '/lobby') : this.props.t.Interface.inArray(this.props.t.state.Game.id, this.props.t.state.Lobby.favoriteGames) ? this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.favoriteGames.filter(gameID => gameID !== this.props.t.state.Game.id), 'favoriteGames') : this.props.t.updateState(this.props.t, 'Lobby', this.props.t.state.Lobby.favoriteGames.concat(this.props.t.state.Game.id), 'favoriteGames'); this.props.t.state.userID && this.props.t.Interface.LobbyUpdateCData(this.props.t) }} className={[this.props.t.styles['favorite'], this.props.t.Interface.inArray(this.props.t.state.Game.id, this.props.t.state.Lobby.favoriteGames) ? this.props.t.styles['active'] : ''].join(' ')}>
                                        <IconStatic t={this.props.t} name="heart" />
                                    </div>
                                    {this.props.t.state.Game.mode !== "demo" && this.props.t.state.Game.stats && <div className={this.props.t.styles['gameStats']}>
                                        <div className={this.props.t.styles['block']}>
                                            <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.bets)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                            <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'betsAmount')}</div>
                                        </div>
                                        <div className={this.props.t.styles['block']}>
                                            <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat((this.props.t.state.Game.stats.win / this.props.t.state.Game.stats.bets) * 10000)}%</div>
                                            <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'totalRTP')}</div>
                                        </div>
                                        <div className={this.props.t.styles['block']}>
                                            <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.bets / this.props.t.state.Game.stats.count)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                            <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'averageBet')}</div>
                                        </div>
                                        <div className={this.props.t.styles['block']}>
                                            <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.top)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                            <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'topWin')}</div>
                                        </div>
                                    </div>}
                                </div>}
                                <div className={this.props.t.styles['mobileContainer']}>
                                    <div className={this.props.t.styles['mobileBtn']}>
                                        <Link to="/lobby"><IconStatic t={this.props.t} name="logout" /></Link>
                                    </div>
                                    <div onClick={() => { this.props.t.updateState(this.props.t, 'Game', !this.props.t.state.Game.mobileOverlay.open, 'mobileOverlay', 'open') }} className={this.props.t.styles['mobileBtnStats']}>
                                        <IconStatic t={this.props.t} name="settings" />
                                    </div>
                                </div></>) : (<></>)}
                            <iframe id="gameFrame" ref={(iframe) => (this.iframe = iframe)} style={(this.state.width <= 900 && this.state.width > 600 && !isMobile) ? { height: (this.state.height - 50) + 'px', width: this.state.width } : isMobile || this.state.width <= 600 ? { height: this.state.height, width: this.state.width } : { height: '100%', width: '100%' }} src={this.props.t.state.Game.iframe || "/static/gameloader"} allowFullScreen={true}></iframe>
                        </div>
                        {this.props.t.state.Game.mode !== "demo" && !isMobile && this.props.t.state.Game.stats && this.state.width > 900 && <div className={this.props.t.styles['gameStats']}>
                            <div className={this.props.t.styles['block']}>
                                <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.bets)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'betsAmount')}</div>
                            </div>
                            <div className={this.props.t.styles['block']}>
                                <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat((this.props.t.state.Game.stats.win / this.props.t.state.Game.stats.bets) * 10000)}%</div>
                                <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'totalRTP')}</div>
                            </div>
                            <div className={this.props.t.styles['block']}>
                                <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.bets / this.props.t.state.Game.stats.count)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'averageBet')}</div>
                            </div>
                            <div className={this.props.t.styles['block']}>
                                <div className={this.props.t.styles['num']}>{this.props.t.Interface.fixedFloat(this.props.t.state.Game.stats.top)} {this.props.t.Interface.getCurrency(this.props.t)}</div>
                                <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'game', 'topWin')}</div>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Game);