module.exports = {
    system: { code: 'ja', name: '日本人' },
    aml: { title: 'マネーロンダリングへの対抗', description: '安全制御', content: '' },
    auth: {
        accountCreated: 'アカウント登録済み',
        agreeText: '私は18歳であることを確かめ、読んだことがある',
        agreeTos: 'サービス利用規約',
        alreadyRegistered: 'すでに登録済みですか',
        byLogin: 'ログインとパスワードで',
        bySocialNetworks: 'ソーシャルネットワーク経由',
        createAccount: 'アカウントを作成する',
        forgotPassword: 'パスワードをお忘れですか？',
        loading: 'ロード中...。',
        login: 'ログイン',
        loginTitle: 'サイト認可',
        maxLoginLength: 'ログインの最大長:',
        maxPasswordLength: 'パスワードの最大長:',
        minLoginLength: '最小ログイン長:',
        minPasswordLength: 'パスワードの最小長:',
        passwordConfirmation: 'パスワードを繰り返す',
        passwordsMustBeSame: 'パスワードは一致する必要があります',
        pleaseWait: 'お待ちください...',
        registration: '登録',
        registrationTitle: 'サイトに登録する',
        symbols: 'キャラクター',
        yourLogin: 'あなたのログイン',
        yourPassword: 'あなたのパスワード',
        yourEmail: 'あなたのメール',
        logoutConfirm: 'マジで出て行きたいのか？',
        passwordRecovery: 'パスワードのリセット',
        newPassword: '新しいパスワード',
        copied: 'コピー済み',
        logout: 'アウト'
    },
    basic: {
        yes: 'ダ',
        no: 'いや',
        confirm: '断言する',
        confirmed: 'Upvoted',
        cancel: '廃止',
        sending: '送信中...',
        sent: '送信します',
        save: '保存',
        create: '作成',
        or: 'または',
        activate: 'アクティブ化',
        deactivate: '非アクティブ化',
        prevPage: '前のページ',
        nextPage: '次のページ',
        of: 'アウト',
        balance: 'バランス',
        copy: 'コピー',
        copied: 'コピー済み'
    },
    bonus: {
        permanentBonus: '永久ボーナス',
        tenPercent: 'デポジットごとに10％！',
        more: 'もっと読む',
        fastRegistration: 'クイックチェックイン',
        noVerification: '検証なし',
        fastWithdraws: '迅速な支払い',
        highLimits: '高限度',
        loyaltyProgramm: 'ロイヤルティプログラム',
        winCashback: '各勝利で最大 1.2%！',
        levelSystem: 'レベルシステム',
        bigPercents: '大きなパーセンテージ',
        payForWin: '勝利のために支払う',
        butNoBets: 'ベットではない',
        affiliateProgramm: 'アフィリエイトプログラム',
        join: '参加する',
        revenueShare: '40% のレベニューシェア',
        anytimeWithdraw: 'いつでも出力する',
        affiliateCabinet: 'アフィリエイト内閣',
        detailStat: '詳細な統計',
        title: 'プロモーションコード',
        promoEveryDay: 'VK グループのプロモーションコード',
        enterPromoCode: 'プロモーションコードを入力してください'
    },
    fair: {
        title: 'ライセンスと誠実保証',
        description: '私たちは皆にフェアプレイを保証します。',
        fairDescription1: 'Brillx Casinoは、ユーザーがゲームの誠実さに自信を持てるように、セキュリティ環境を構築することがどれほど重要であるかを理解しています。',
        fairDescription2: 'したがって、私たちのカジノでは、オンラインカジノ用のソフトウェアを開発しているトップ企業が提供するゲームを使用しています。その中には、次のような企業があります。',
        fairDescription3: 'EcoGraとIGCの創設メンバーとして、マイクロゲーミングは他のカジノゲームメーカーに先んじるヘッドであり、ギャンブル業界全体で最高水準を設定している。特にオンラインゲームの誠実さ、最も責任あるオペレーターの選択、プレイヤーの利益の保護などのために。',
        andOther: '他',
        also: '同社が作成したゲームも提供しています。',
        defaultCasino: '普通のオンラインカジノ',
        brillxCasino: 'Brillx 正直コントロールカジノ',
        defaultCasinoDescription1: 'カジノサーバーに書き込まれる賭けをする',
        defaultCasinoDescription2: 'お客様のデータはカジノサーバーで収集され、保存されます。',
        defaultCasinoDescription3: 'ゲームの誠実さをチェックすることは不可能です。すべてのアルゴリズムが記録され、カジノ側で保存されます。',
        defaultCasinoDescription4: '勝利を得るには、長い認証手続きを経なければなりません',
        defaultCasinoDescription5: 'その残高はどこにも公表されていないため、問題のカジノのソルベンシー',
        brillxCasinoDescription1: 'プレイヤーベッティングプロバイダーからのデータのコピーはブロックチェーンに保存され、変更することはできません。',
        brillxCasinoDescription2: '人間のゲーム統計は暗号化され、ブロックチェーンに記録され、プレイヤーの個人データに解かれます。',
        brillxCasinoDescription3: '整合性管理はブロックチェーン技術で行われます',
        brillxCasinoDescription4: '引き出しにはKYC標準認証が必要です。振込手数料はかかりません。',
        brillxCasinoDescription5: 'オープンレポートとプレーヤーの支払いのための巨大なプール'
    },
    game: {
        loading: 'ロード中',
        lobby: 'ロビー',
        fullScreen: '全画面表示',
        deposit: '預金',
        betsAmount: '賭け金額',
        totalRTP: '合計RTP',
        averageBet: '平均ベット',
        topWin: 'トップウィン'
    },
    help: {
        title: 'ヘルプとサポート',
        description: '最も一般的な質問への回答とカスタマーサービスとのコミュニケーション。',
        connectTypes: '当社とのコミュニケーション方法',
        operatorsOnline: '24時間オンラインオペレータ',
        supportLive: 'サポート (ライブ)',
        onlineChat: 'ライブチャット',
        averageTimeChat: '平均応答時間:10 分',
        supportEmail: 'カスタマーサービス (Eメール)',
        averageTimeEmail: '平均応答時間:1 日',
        generalQuestions: '一般的な問題',
        aboutSite: 'サイトについて',
        aboutSiteContent: 'Brillx は、国際的なライセンスと名前を持つオンラインカジノです。カタログで6000以上のゲーム、幅広いスポーツイベント、ミニゲームを多数提供している。私たちは多くの支払いシステムと人気のある暗号通貨のほとんどをサポートしています。',
        bonus: 'ボーナス',
        bonuses: 'ボーナス',
        onPage: 'ページ上',
        manyFreeBonuses: 'あなたの残高のために絶対に無料でお金を得るにはいくつかの方法があります！',
        bonus1: '登録ボーナス。サイトに登録したら、60分以内に招待コードを入力し、最大10.00 RUBを取得します。',
        bonus2: '登録ボーナス。サイトに登録したら、60分以内に招待コードを入力し、最大10.00 RUBを取得します。',
        bonus3: '登録ボーナス。サイトに登録したら、60分以内に招待コードを入力し、最大10.00 RUBを取得します。',
        bonus4: '登録ボーナス。サイトに登録したら、60分以内に招待コードを入力し、最大10.00 RUBを取得します。',
        promocodes: 'プロモーションコード',
        inviteFriend: '友達を招待する',
        balanceRefilling: '残高補充',
        wallet: '財布',
        howToRefill: '残高を補充するにはどうしたらいいですか？',
        commissions: '手数料',
        periodOfRefilling: '登録条件',
        withdrawal: '引き出し',
        howToWithdraw: '資金を引き出すにはどうすればよいですか？',
        periodOfWithdrawal: '出金タイムライン',
        clickToRefill: 'ボタンをクリックして、残高を補充します',
        atRightCorner: 'サイトの右上隅にあります。都合の良い支払い方法を選択し、入金額を入力し、「支払いに移動」をクリックします。',
        minimalRefilling: '最小補充',
        commissionsOnPage: 'すべての支払い方法の手数料はページに記載されています。',
        clickToWithdraw: 'アカウントからお金を引き出すには、ボタンをクリックします。',
        averageTimeWithdraw: 'すべての知見は、申請時から平均1時間で処理されます。',
        allTransactions: 'すべてのトランザクションは即座に処理されます。遅延が発生するのは、残高を補充する支払い方法に問題がある場合にのみ発生します。',
        inCaseOfProblems: '問題がある場合は、カスタマーサービスに連絡し、問題に関する最も詳細な情報を提供してください。',
        atRightCornerWithdraw: 'サイトの右上隅にあります。都合の良い出金方法を選択し、金額を入力し、「支払いに移動」をクリックします。',
        minimalWithdrawal: '最低出金',
        ifBigAmount: '出金額が大きい場合、引き出しは少し長くなり、通常は1時間になります。夜間（午前00時から午前9時00分）、調査結果は数時間続くことがあり、その時点で支払いアグリゲータが引き出し要求をより長く処理します。',
        sometimes: '非常にまれなケースでは、銀行の負荷などにより、引き出しは24時間まで行くことがあります。それ以降にお金が届かない場合は、カスタマーサービスに連絡し、問題に関する最も詳細な情報を提供してください。',
        checkCorrectPurse: 'また、出力の詳細を正しく指定してください。出金を指し示したウォレットを安心させるには、取引履歴に入り、目的の取引をクリックして詳細を開く必要があります。'
    },
    interface: {
        months: {
            jan: '1月。',
            feb: '2月',
            mar: '3月。',
            apr: '4月。',
            may: 'まい',
            jun: 'じゅん。',
            jul: '7月。',
            aug: '8月。',
            sep: '9月',
            oct: '10月',
            nov: '11月',
            dec: '12月'
        },
        year: 'g。',
        confirmPasswordRecovery: 'メールに送信されたリンクに従って、パスワードのリセットを確認します。',
        passwordWasChanged: 'パスワードが正常に変更されました。ログインして新しいデータを使用できます',
        confirmEmail: 'リンクでメールアドレスを確認する',
        emailWasConfirmed: 'Eメールアドレスは正常に確認されました',
        accountLinked: 'アカウントは正常にリンクされました',
        bonusGranted: 'ボーナスが受け取った'
    },
    landing: {
        newGames: '新しいゲーム',
        topGames: '人気ゲーム',
        brillxSelect: 'ブリルックスのチョイス',
        daylyLiveRtp: 'ライブRTP-24時間',
        brillxCasino: 'ブリルックスカジノ',
        casinoInfo1: 'お客様の満足のためにオーダーメイドのスロットマシンサイトであるBrillx.GGで、さまざまなエキサイティングなゲームをチェックしてください。世界中のプレイヤーに何千ものファーストクラスのスロットマシンが利用可能です。巨大なジャックポット、ポーカースロット、テーマのスロットマシンなど、必要なものがすべて揃っています！',
        casinoInfo2: '今すぐチェックイン] ボタンをクリックして、クイックチェックインを簡単に体験してください！大手ゲームプロバイダーのリアルマネースロットマシンをプレイする瞬間をお楽しみください。',
        casinoInfo3: '速い支払いとあらゆる種類のスロットマシンのバリエーションの巨大な選択を備えた私たちのカジノは、あなたのために24時間365日働きます-ゲームを選んでプレイを始めましょう！',
        casinoInfo4: 'オンラインでスロットをプレイ',
        casinoInfo5: '私たちはあなたの楽しみを台無しにすることはありませんので、コンピュータに何もダウンロードせずにすべてのゲームをプレイすることができます。',
        casinoInfo6: '何千ものスロットをブラウズし、好きなゲームを選択し、「PLAY」をクリックしてください。とても簡単です！',
        casinoInfo7: 'カジノスロットゲームは最も人気のあるゲームの1つです。あなたが得ることができる巨大なインセンティブのためだけでなく、エキサイティングなグラフィック、良い音楽、ゲームの全体的な楽しい視覚効果のためにも。',
        casinoInfo8: 'エンターテイメントモードに切り替えて、各スロットマシンを試すこともできます。ダウンロードせずに、登録なしですべてのスロットマシンをプレイできる素晴らしい機能は無料です！携帯電話、コンピュータ、またはタブレットからアクセスできます。ダウンロードする必要なく無料でプレイできる最高のスロットマシンのいくつかを発見し、世界中の最高のカジノスロットでユニークな冒険を体',
        casinoInfo9: '何を待ってるの？今日はあなたの幸運な日かもしれません！',
        casinoInfo10: '数千台のスロットマシン',
        casinoInfo11: '私たちのカジノで見つけることができるさまざまなタイプのスロットマシンは次のとおりです。',
        casinoInfo12: '3 リールスロット',
        casinoInfo13: '3つのリールスロットは、クラシックまたは伝統的なスロットマシンとして最もよく知られています。リールは、シンボルがカスケードされているゲームで見つけることができる垂直線です。スリルを探しているなら、3リールスロットマシンをプレイしてみてください！あなたがプレイできるゲームのいくつかは、Book of Alchemy、ワトフォードFCスロットなど多くのものです。',
        casinoInfo14: '5 リールスロット',
        casinoInfo15: '5リールスロットをプレイして、コンビネーションを獲得するチャンスを得ましょう！ほとんどの5つのリールスロットは、3、4、または5文字がリールに落ちるたびに支払われます。ゲームに応じて、勝利乗数、ボーナスゲームラウンド、またはフリースピンを取得することもできます。あなたがプレイできる5つのリールゲームのいくつかは、バイキングゴーベルザック、レガシーオブデッド、ザットズ・リッチです。',
        casinoInfo16: 'プログレッシブスロット',
        casinoInfo17: 'プログレッシブスロットは、すべてのタイプのスロットマシンの中で活用されています。ゲームの進行に伴ってジャックポットのサイズが大きくなります。何百万人ものがあなたを待っています。あなたは今日でも最も裕福な人々の一人になることができます！',
        casinoInfo18: 'ルーレット',
        casinoInfo19: 'ルーレットは、テーブル、色、数字の種類、または組み合わせの個々の数字を賭けるゲームです。運を試して、賭けたものがホイールに当たったら勝ちましょう。今すぐ素晴らしいリアルタイムルーレットゲームをプレイしましょう！',
        casinoInfo20: 'ポーカー',
        casinoInfo21: '通常のカジノでのポーカーに精通している場合は、オンラインビデオポーカーゲームの幅広いセレクションを活用して、今すぐプレイしてみることができます。今日忘れられないゲームを体験する準備をしてください！高精細アニメーションと素晴らしい特別なビデオラウンドで、あなたは間違いなく新しい勝利のために戻ってくるでしょう。',
        casinoInfo22: 'バカラ',
        casinoInfo23: 'バカラは世界で最も人気のあるゲームの1つです。9に最も近いハンドにベットしてディーラーを倒す。また、引き分けがあれば勝つこともできます。わくわく聞こえる、本当に？今すぐベットを準備して、勝利側に賭けてください！',
        casinoInfo24: 'ブラックジャック',
        casinoInfo25: 'ブラックジャックでは、21に最も近いハンドがあれば、ディーラーを倒すことができます。雄大なブラックジャックスロットマシンをプレイすると、ベットを2倍にするチャンスを手に入れよう！',
        gamesProviders: 'ゲームプロバイダー',
        showMore: 'もっと表示',
        hideInfo: '情報を隠す',
        allGames: 'すべてのゲーム',
        providers: 'プロバイダー',
        new: '新規',
        tournaments: 'トーナメント',
        slots: 'スロット',
        roulette: 'ルーレット',
        card: 'カード',
        videopoker: 'ビデオポーカー',
        lottery: '抽選'
    },
    live: { demo: 'デモ', play: '遊ぶ', title: 'Live' },
    lobby: {
        providers: 'プロバイダー',
        recent: '最近の',
        top: '人気',
        new: '新規',
        favorite: 'お気に入り',
        nothingFound: '何も見つかりませんでした',
        play: '遊びます',
        demo: 'デモ',
        forExample: 'たとえば'
    },
    log: { date: '日付', type: '作戦', amount: '金額', id: 'ID' },
    loyalty: {
        title: 'ロイヤルティプログラム',
        description: 'すべてのベットでロイヤルティレベルに依存するキャッシュバックを受け取る',
        cabinet: '内閣',
        myCabinet: 'マイオフィス',
        payLog: '発生主義ログ',
        log: 'ローグ',
        level: '階層',
        totalWins: '勝った金額',
        totalBets: 'ベット金額',
        availableBalance: '使用可能な残高',
        get: '持ち去る',
        cashback: 'キャッシュバック',
        emptyHistory: 'ストーリーが欠けている',
        operationID: 'オペレーション ID',
        amount: '金額',
        date: '日付',
        operationDate: '運用日',
        operationType: '作戦',
        balanceBefore: 'バランス・アップ',
        balanceAfter: '後のバランス'
    },
    menu: {
        affProgramm: 'パートナー',
        fairGame: 'フェアプレイ',
        free: '無料',
        games: 'ゲーム',
        lobby: 'ロビー',
        promo: 'プロモーション',
        loyaltyProgramm: '忠誠心',
        promotions: '株式',
        support: 'ヘルプデスク',
        settings: '[設定]',
        history: 'ゲーム履歴',
        more: 'もっと',
        profile: 'プロフィール',
        bonusCode: 'プロモーションコード'
    },
    noty: { error: 'エラー', success: '成功した' },
    profile: {
        wallet: '財布',
        onSiteFrom: 'オンサイトで',
        totalBets: 'ベット金額',
        betsCount: 'ベッティング',
        totalWins: '勝った金額',
        bestWin: 'ベストウィン',
        emptyHistory: 'ストーリーが欠けている',
        balanceAfter: '後のバランス'
    },
    partner: {
        maxCodeLength: '最大コード長:',
        minCodeLength: '最小コード長:',
        maxNameLength: '名前の最大長:',
        minNameLength: '名前の最小長:',
        symbols: 'キャラクター',
        confirmDeleteCampgain: '本当にキャンペーンを削除しますか？',
        campgains: 'キャンペーン',
        refresh: 'アップグレード',
        export: '[エクスポート]',
        campgainName: 'キャンペーン名',
        affiliateLink: 'アフィリエイトリンク',
        name: 'タイトル',
        creationDate: '作成日',
        clicks: '変遷表',
        link: '参照',
        manage: 'マネジメント',
        noCampgains: 'アクティブな広告キャンペーンはありません',
        namePlaceholder: 'パネルに表示する名前',
        general: '将軍',
        mainAffiliateLink: '主なアフィリエイトリンク',
        registrations: '登録',
        totalProfit: '普通利益',
        availableBalance: '使用可能な残高',
        get: '持ち去る',
        emptyHistory: 'ストーリーが欠けている',
        operationID: 'オペレーション ID',
        amount: '金額',
        date: '日付',
        operationDate: '運用日',
        operationType: '作戦',
        playerID: 'プレイヤーID',
        balanceAfter: '後のバランス',
        balanceBefore: 'バランス・アップ',
        log: '発生主義ログ',
        players: 'プレイヤー',
        registration: '登録',
        registrationDate: '登録日',
        balance: 'バランス',
        noPlayers: 'ゲストプレイヤーはいない',
        totalBets: 'ベット金額',
        totalDeps: '入金金額',
        totalWithdraws: '調査結果の合計',
        registrationSource: '登録元',
        mainLink: 'メインリンク',
        player: 'プレイヤー',
        registrationsCount: '登録',
        bets: 'ステーキング',
        profit: '利益',
        stat: '統計情報',
        totalStat: '全体的な統計',
        partnerCabinet: 'パートナーオフィス',
        partnerID: 'パートナー ID'
    },
    privacy: {
        title: 'プライバシーポリシー',
        description: '一般規定と情報',
        redaction: '編集者',
        content: ''
    },
    promo: {
        linkVk: 'VKアカウントをバインドしてボーナスを獲得する',
        completeAll: 'すべての条件に合ってゲット',
        toBalanceForRepost: '再投稿のためのバランス！',
        link: 'バインディング',
        join: 'エントリー',
        allowNotifications: '通知を許可する',
        make: 'やって',
        toProfileVkAccount: 'vK アカウントプロファイルに登録します。',
        toOurVkGroup: '私たちのVKグループに。',
        fromOurGroup: '私たちのコミュニティから。',
        repostToYourPage: 'エントリを自分のページに再投稿します。',
        mustBePublic: 'VKページは公開されている必要があります。そうしないと再投稿を確認できません。',
        dontDelete: '再投稿から2週間前に投稿を削除すると、ボーナス額は残高から差し引かれます。',
        joinAndGet: 'グループに参加してゲット',
        toBalance: 'バランス！',
        bonusGranted: 'ボーナスは正常に受け取りました！',
        momentalBonus: 'VKグループに参加して、残高にインスタントボーナスをゲットしよう！VKのプロフィールが公開されている必要があります。そうしないとサブスクリプションを確認できません。',
        vkDoesntLinked: 'VKアカウントが縛られていない',
        allowNotificationsAndGet: 'コミュニティからの通知を許可してゲット',
        sendAnyMessage: 'VKグループにメッセージを送信し、通知を送信できるようにします。素敵な驚き-定期的にプロモーションや様々なボーナスに関する情報を送信します！',
        rollAndGetBonus: 'ルーレットをクルーズしてボーナスをゲットしよう！',
        rollOncePer: '15分おきにルーレットをひねってまで',
        rollEveryDay: '毎日制限なくホイールを回転させよう！',
        untilNextTry: '次の試行が始まる前',
        subscribeTg: 'テレグラムチャンネルにサインアップして、新しいプロモーションコードを最初に手に入れよう！',
        beFirst: '新しいプロモーションコードを最初に手に入れ、新しいボーナスとイノベーションについて学びましょう！',
        referrals: '紹介者',
        getPercent: '友達を招待して、カジノの特典の40％を獲得しましょう！',
        copyLink: 'リンクをコピーする',
        inviteFriend: '友達を招待する',
        getBonus: 'ボーナスをゲット',
        affiliateProgramm: 'アフィリエイトプログラム',
        sendReview: 'グループのプロジェクトに関するフィードバックをフィードし、',
        bonusToDeposit: '入金ボーナス',
        upTo: 'まで',
        makeReviews: 'VKグループにフィードバックを残し、モデレーターが確認されて投稿された後、ボーナスはアカウント上で自動的に有効になります。',
        makeReview: 'フィードバック',
        ranks: 'ランギス',
        earnFromWin: 'ランクを獲得すると、勝利ごとにキャッシュバックが増加します。',
        loyaltyProgramm: 'ロイヤルティプログラム',
        writeMessage: 'メッセージを書く',
        goToGroup: 'グループに移動',
        refreshing: '更新...。',
        refreshStatus: 'アップグレード',
        roll: '[回転]',
        linkAccount: 'アカウントをバインドする',
        checking: 'チェック中...',
        get: 'ゲット'
    },
    promotions: {
        title: '株とオファー',
        description: '現在の株式でボーナスを獲得する機会をお見逃しなく',
        game: 'ゲーム',
        minDeposit: '最小デポジット',
        bonus: 'ボーナス',
        addWager: '追加賭け',
        validUntil: 'まで行動する',
        activation: 'アクティベーション',
        get: 'ゲット',
        unlimit: '無制限',
        oneTime: '1 回限る',
        forEveryDeposit: 'すべての入金について',
        permanentBonus: '永久ボーナス',
        any: 'どれか'
    },
    responsibility: { title: '責任あるゲーム', description: '責任あるゲームのヒントとルール', content: '' },
    settings: {
        incorrectEmail: 'Eメールアドレスが間違っている',
        bigFileSize: 'ファイルサイズは 5 MB を超えないようにしてください。',
        genders: { man: '男性用', female: '婦人向け', other: 'その他' },
        requiredField: '必須フィールド',
        dataSuccessChanged: 'データは正常に更新されました',
        tfaLoginRequired: 'ログインとパスワードを設定してオーセンティケータを設定する',
        general: '将軍',
        login: 'ログイン',
        vkProfile: 'VKのプロフィール',
        glProfile: 'グーグルのプロフィール',
        okProfile: 'プロフィールクラスメート',
        fbProfile: 'フェイスブックプロフィール',
        security: '安全性',
        alterLogin: 'アカウントにログインとパスワードの代替方法を追加します。',
        newPassword: '新しいパスワード',
        repeatPassword: 'パスワードを繰り返す',
        saveActions: 'アクションを保存',
        currentPassword: '現在のパスワード',
        tfa: '二要素認証',
        addTfa: '追加のセキュリティでBrillxアカウントを保護する',
        tfaSettings: '2FA設定',
        sessions: 'セッション',
        device: 'デバイス',
        region: '地域',
        ipAddress: 'IPアドレス',
        date: '日付',
        session: 'セッション',
        active: 'アクティブ',
        closed: '休館日',
        verification: '検証',
        verificationDescription1: ' サービスレベルを向上させるために、文書を特定するようお願いします。これにより、アカウントが復元された場合にアカウントの安全が保たれ、物理的なギフトのターゲットを絞った正確な配送が提供されます。',
        verificationDescription2: '許容できる検証方法は、身分証明書、運転免許証、またはパスポートの写真を提供することです。このプロセスの完了後、メールでお知らせします。',
        frontPhoto: '身分証明書の前面の写真',
        selectFile: 'ファイルを選択',
        backPhoto: '身分証明書のバック写真',
        fileIsNotSelected: 'ファイルが選択されていません',
        name: '名称',
        surname: '姓',
        birthday: '生年月日',
        gender: '性別',
        country: '国',
        city: '市内',
        yourName: 'お名前',
        yourSurname: 'お前の姓',
        verifyStatus1: 'ドキュメントがチェックされています...',
        verifyStatus2: 'アカウント検証済み',
        verifyStatus3: 'ドキュメントチェックエラー',
        verifyDescription1: 'ドキュメントは現在審査中です。ドキュメントが検証され次第、アカウントが検証されます。',
        verifyDescription2: 'お客様のデータはセキュリティ担当者によって検証され、確認されています。',
        verifyDescription3: '詳細については、テクニカルサポートにお問い合わせください。',
        registrationCity: '登録都市',
        sendDocs: '検査のために提出',
        affiliateCabinet: 'パートナーオフィス'
    },
    tos: {
        title: 'ユーザー契約',
        description: '利用規約と利用規則',
        redaction: '編集者',
        content: ''
    },
    wallet: {
        deposit: '預金',
        withdraw: '出力',
        history: '歴史',
        minDepositAmount: '経由する最低入金',
        minWithdrawAmount: '最小出力スルー',
        topMethods: '人気',
        allMethods: 'すべてのメソッド',
        qiwi: 'QIWI',
        ym: 'Yoomoney',
        card: 'Card',
        payeer: 'Payeer',
        crypto: 'Crypto',
        bitcoin: 'Bitcoin',
        usdt: 'Tether',
        mobile: 'Mobile',
        megafon: 'Megafon',
        mts: 'MTS',
        beeline: 'Beeline',
        tele2: 'Tele 2',
        pm: 'Perfect Money',
        terminal: 'Terminal (RF)',
        advcash: 'AdvCash',
        fk: 'FK Wallet',
        steam: 'Steam',
        litecoin: 'Litecoin',
        ethereum: 'Ethereum',
        bitcoin_cash: 'BCH',
        dash: 'Dash',
        dogecoin: 'Dogecoin',
        monero: 'Monero',
        zcash: 'ZCash',
        usdt_erc: 'ERC20',
        usdt_trc: 'TRC20',
        bnb: 'BNB',
        ripple: 'Ripple',
        waves: 'Waves',
        other: 'その他',
        success: '成功した',
        canceled: 'キャンセル済み',
        error: 'エラー',
        waiting: '待っている',
        processing: 'アプリケーションが処理中です',
        incorrectWalletValue: 'ウォレット番号が正しく入力されていません',
        incorrectCardValue: 'カード番号が正しく入力されていません',
        selectTemplate: 'テンプレートを選択',
        withdrawCreated: '支払い申請が作成され、24 時間以内に処理されます',
        withdrawCanceled: 'ペイアウト入札はキャンセルされました',
        firstWithdraw: 'これが最初の結論です',
        isDataCorrect: 'データ入力が正しいと確信していますか？',
        payMethod: '支払い方法',
        withdrawMethod: '出金方法',
        selectMethod: '方法を選ぶ',
        selectOperator: '演算子の選択',
        refillAmount: '補充',
        withdrawAmount: '出金額',
        payTroubles: 'お支払いに困った場合はお試しください',
        payHere: 'ここで支払う',
        pay: '支払いに行く',
        commission: '手数料',
        onePayLimit: '単一補充制限',
        oneWithdrawLimit: '単一出力制限',
        emptyHistory: 'ストーリーが欠けている',
        operationID: 'オペレーション ID',
        deposits: '補充',
        withdraws: '調査結果',
        txAmount: '取引金額',
        txDate: '運用日',
        purse: 'スコア',
        typePurse: 'アカウント番号',
        toGet: '受け取る',
        createWithdraw: 'アプリケーションを作成する'
    },
    tfa: {
        tfa: '二要素認証',
        typeYourPassword: 'パスワードを入力してください',
        enterPin: 'アプリから 6 桁のコードを入力してください',
        downloadApp: 'アプリをダウンロードする',
        downloadAndInstall: 'ダウンロードしてインストールする',
        forYourPhone: 'お使いの携帯電話またはタブレット用',
        scanQR: 'qRコードをスキャン',
        scanQRWithCamera: '携帯電話のカメラで左側の QR をスキャン',
        openWithLink: 'リンクで開く',
        openInApp: 'アプリで開く',
        tfaManualEnter: '2FA キー (手動入力)'
    },
    pwa: {
        androidStep1: 'Chromeブラウザを開く',
        androidStep1Description: '他のブラウザを使用している場合は、このページをChromeで開きます',
        androidStep2: 'ブラウザメニューをクリックします',
        androidStep2Description: 'ブラウザメニューは、ブラウザの右上隅にある省略記号です',
        androidStep3: '[ホーム画面に追加]を選択',
        androidStep3Description: '情報を含むウィンドウ（「ホーム画面に追加」）が表示されます。このウィンドウで「追加」を選択する必要があります。その後、アプリケーションがデバイスのデスクトップに追加されます',
        androidAlert: 'ブラウザのバージョンによって手順が異なる場合があります',
        iosStep1: 'Safariブラウザを開く',
        iosStep1Description: '他のブラウザを使用している場合は、Safariでこのページを開きます',
        iosStep2: '[共有]をクリックします',
        iosStep2Description: '画面下部の[共有]ボタンをクリックすると、パラメータ選択ウィンドウが開きます',
        iosStep3: '[ホーム画面に追加]を選択',
        iosStep3Description: '[ホーム画面に追加]を選択し、[追加]をクリックします',
        alert: 'ブラウザのバージョンによって手順が異なる場合があります',
        installApp: 'Brillxアプリをインストールします',
        back: 'バック',
        app: '「モバイルアプリケーション」',
        installAppForMobile: 'iOSおよびAndroid用の新しい便利なBrillxアプリをインストールします',
        インストール: 'インストール'
    },
    undefined: '???'
};