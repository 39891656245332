import React from 'react';
import StaticLobbyCategories from './static/LobbyCategories';

export default class Categories extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Lobby.categories, this.props.t.state.Lobby.categorySelected, this.props.t.state.lang], [this.pState.Lobby.categories, this.pState.Lobby.categorySelected, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                <div className={this.props.t.styles['categoriesList']}>
                    {!this.props.t.state.Lobby.categories.length ? (
                        <>
                            <StaticLobbyCategories t={this.props.t} />
                        </>
                    ) : this.props.t.state.Lobby.categories.map(category => (
                        <div key={category.id} className={this.props.t.styles['item']}>
                            <div className={this.props.t.styles['btnWrapper']}>
                                <button onClick={() => { this.props.t.state.Lobby.categorySelected === category.id ? this.props.t.doRoute('/lobby/' + !this.props.t.state.Lobby.providerSelected ? '0' : this.props.t.state.Lobby.providerSelected + '/0') : this.props.t.doRoute('/lobby/' + (!this.props.t.state.Lobby.providerSelected ? '0' : this.props.t.state.Lobby.providerSelected) + '/' + category.id) }} type="button" className={[this.props.t.styles['btn'], this.props.t.state.Lobby.categorySelected === category.id ? this.props.t.styles['active'] : ''].join(' ')}>{category.name}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

}