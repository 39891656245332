import React from 'react';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
    }
    
    shouldComponentUpdate() {
        return false
    }

    render() {
        return (
            <svg className={[this.props.t.styles['icon'], this.props.t.styles['icon-' + this.props.name]].join(' ')}><use xlinkHref={`${this.props.t.icons}#icon-` + this.props.name} /></svg>
        )
    }
}