import React from 'react';
import IconStatic from '../../components/IconStatic';
import Interface from '../../lib/Interface';
import PasswordRecovery from './PasswordRecovery';

import { Tooltip } from 'reactstrap';

import 'bootstrap';
import '../../styles/bootstrap/scss/bootstrap.module.scss';

class AuthModal extends React.Component {
	constructor(props) {
		super(props);
		this.captchaTimeout = false;
	}

	state = {
		recaptcha: {
			ready: false,
			token: false
		}
	};

	componentDidMount() {
		window.captchaOnLoad = this.captchaOnLoad.bind(this);

		const script = document.createElement("script");
		script.async = true;
		script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";
		this.recaptchaLoader.appendChild(script);
	}

	componentWillUnmount() {
		document.getElementById('g-recaptcha').parentNode.removeChild(document.getElementById('g-recaptcha'));
		clearTimeout(this.captchaTimeout);

		this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "auth", "login");
		this.props.t.updateState(this.props.t, "form", { data: '', error: false }, "auth", "password");
	}

	captchaOnLoad() {
		const widget = document.createElement('div');
		widget.id = 'g-recaptcha';
		widget.style.visibility = 'hidden';
		document.body.appendChild(widget);

		window.grecaptcha.render('g-recaptcha', {
			sitekey: '6Lfg4PAUAAAAABijupZzyCKqOoZ4JyHtQLXq_oa_',
			size: 'invisible'
		});

		window.grecaptcha.ready(() => {
			this.setState({ recaptcha: { token: this.state.recaptcha.token, ready: true } });
			this.getCaptcha(this);
			this.captchaTimeout = setTimeout(this.getCaptcha, 120000, this);
		})
	}

	getCaptcha(t) {
		if (t.state.recaptcha.ready)
			window.grecaptcha.execute({ action: 'user/auth/login' }).then(token => { t.setState({ recaptcha: { token, ready: t.state.recaptcha.ready } }); });
	}

	handleAuth(event) {
		if (event.key === "Enter") {
			Interface.isFormCorrect(this.props.t, 'auth', this.props.t.Interface.doLogIn, { recaptchaToken: this.state.recaptcha.token });
			this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } });
			this.getCaptcha(this);
		}
	}

	render() {
		return (

			<div className={this.props.t.styles['authContainer']}>
				{this.props.t.state.width > 900 && <div className={this.props.t.styles['authLeft']}></div>}
				<div className={this.props.t.styles['authContent']}>
					<div className={this.props.t.styles['authContentContainer']}>
						<div className={this.props.t.styles['authTabs']}>
							<button onClick={() => this.props.t.Interface.requireAuth(this.props.t, true)} type="button">{this.props.t.Interface.getText(this.props.t, 'auth', 'registration')}</button><button className={this.props.t.styles['active']} type="button" value="login">{this.props.t.Interface.getText(this.props.t, 'auth', 'login')}</button>
						</div>
						<div className={this.props.t.styles['socialContainer']}>
							<button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'vk', false)} className={this.props.t.styles['btnSocial']} type="button" value="vkontakte"><IconStatic t={this.props.t} name="vk" /></button>
							<button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'fb', false)} className={this.props.t.styles['btnSocial']} type="button" value="facebook"><IconStatic t={this.props.t} name="facebook" /></button>
							<button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'ok', false)} className={this.props.t.styles['btnSocial']} type="button" value="odnoklassniki"><IconStatic t={this.props.t} name="odnoklassniki" /></button>
							<button onClick={() => this.props.t.Interface.doSocialNetworkLogin(this.props.t, 'gl', false)} className={this.props.t.styles['btnSocial']} type="button" value="google"><IconStatic t={this.props.t} name="google" /></button>
						</div>
						<div className={this.props.t.styles['captionLine']}>
							<span>{this.props.t.Interface.getText(this.props.t, 'basic', 'or')}</span>
						</div>
						<div className={this.props.t.styles['authForm']}>
							<div className={this.props.t.styles['formRow']}>
								<div className={this.props.t.styles['formField']}>
									<input onKeyPress={(event) => { this.handleAuth(event) }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'auth', 'login')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'auth', 'login') }} id="inputLogin" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.auth.login.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="username" placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourLogin')} type="text" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.auth.login.error }, 'auth', 'login'); this.props.t.Interface.checkForm(this.props.t, 'auth', 'login') }} value={this.props.t.state.form.auth.login.data}></input>
									<Tooltip isOpen={this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show && !this.props.t.state.inputs.auth.login && this.props.t.state.form.auth.login.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputLogin">
										{this.props.t.state.form.auth.login.error}
									</Tooltip>
								</div>
							</div>
							<div className={this.props.t.styles['formRow']}>
								<div className={this.props.t.styles['formField']}>
									<input onKeyPress={(event) => { this.handleAuth(event) }} onFocus={() => this.props.t.updateState(this.props.t, "inputs", true, 'auth', 'password')} onBlur={() => { this.props.t.updateState(this.props.t, "inputs", false, 'auth', 'password') }} id="inputPassword" autocomplete="off" className={[this.props.t.styles['inputField'], this.props.t.state.form.auth.password.error ? this.props.t.styles['hasError'] : ''].join(' ')} name="password" placeholder={this.props.t.Interface.getText(this.props.t, 'auth', 'yourPassword')} type="password" onChange={a => { this.props.t.updateState(this.props.t, 'form', { data: a.target.value, error: this.props.t.state.form.auth.password.error }, 'auth', 'password'); this.props.t.Interface.checkForm(this.props.t, 'auth', 'password') }} value={this.props.t.state.form.auth.password.data}></input>
									<Tooltip isOpen={this.props.t.state.modal.show && this.props.t.state.modal.opened && !this.props.t.state.modalSecond.show && !this.props.t.state.inputs.auth.password && this.props.t.state.form.auth.password.error} className={this.props.t.styles['tooltipBox']} popperClassName={this.props.t.styles['error']} innerClassName={this.props.t.styles['inner']} arrowClassName={this.props.t.styles['arrow']} boundariesElement={'body'} cssModule={this.props.t.bstyles} placement="top-start" target="inputPassword">
										{this.props.t.state.form.auth.password.error}
									</Tooltip>
								</div>
							</div>
							<button onClick={() => { Interface.isFormCorrect(this.props.t, 'auth', this.props.t.Interface.doLogIn, { recaptchaToken: this.state.recaptcha.token }); this.setState({ recaptcha: { token: false, ready: this.state.recaptcha.ready } }); this.getCaptcha(this); }} className={[this.props.t.styles['btn'], this.props.t.styles['btnAuth']].join(' ')} type="submit" disabled={!this.props.t.state.auth.process ? false : true}>{!this.props.t.state.auth.process ? this.props.t.Interface.getText(this.props.t, 'auth', 'login') : this.props.t.Interface.getText(this.props.t, 'auth', 'loading')}</button>
							<div className={this.props.t.styles['confirmAge']}>
								{this.props.t.Interface.getText(this.props.t, 'auth', 'agreeText')} <a href="#" onClick={() => { this.props.t.doRoute('/tos'); this.props.t.updateState(this.props.t, 'modal', false, 'show'); }}>{this.props.t.Interface.getText(this.props.t, 'auth', 'agreeTos')}</a>
							</div>
						</div>
						<div className={this.props.t.styles['lostPassword']}>
							<button onClick={() => { this.props.t.updateState(this.props.t, 'modalSecond', { show: true, content: PasswordRecovery, className: this.props.t.styles['recoveryModal'] }) }} className={this.props.t.styles['btn']} type="button">{this.props.t.Interface.getText(this.props.t, 'auth', 'forgotPassword')}</button>
						</div>
					</div>
				</div>
				<div ref={div => (this.recaptchaLoader = div)}></div>
			</div>

		);
	}
}

export default AuthModal;
