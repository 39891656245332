import React from 'react';
import Icon from '../components/Icon';

import Loader from '../assets/svg/loader.svg';

import DiamondBlue from '../assets/svg/Loyalty/diamond_blue.svg';
import DiamondYellow from '../assets/svg/Loyalty/diamond_yellow.svg';
import DiamondOrange from '../assets/svg/Loyalty/diamond_orange.svg';
import DiamondGreen from '../assets/svg/Loyalty/diamond_green.svg';
import DiamondRed from '../assets/svg/Loyalty/diamond_red.svg';
import DiamondPurple from '../assets/svg/Loyalty/diamond_purple.svg';
import DiamondBrown from '../assets/svg/Loyalty/diamond_brown.svg';
import DiamondBlack from '../assets/svg/Loyalty/diamond_black.svg';

const Levels = [{ level: 1, percent: 0.15, img: DiamondBlue }, { level: 2, percent: 0.30, img: DiamondYellow }, { level: 3, percent: 0.45, img: DiamondOrange }, { level: 4, percent: 0.60, img: DiamondGreen }, { level: 5, percent: 0.75, img: DiamondRed }, { level: 6, percent: 0.90, img: DiamondPurple }, { level: 7, percent: 1.05, img: DiamondBrown }, { level: 8, percent: 1.2, img: DiamondBlack }];

export default class LoyaltyGeneral extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.pState = this.props.t.cloneDeep(this.props.t.state);
        if (this.props.t.state.userID) {
            this.props.t.updateState(this.props.t, 'Loyalty', false, 'stat');
            this.props.t.updateState(this.props.t, 'Loyalty', 0, 'balance');
            this.props.t.Interface.LoyaltyGetStats(this.props.t);
            this.props.t.Interface.LoyaltyGetBalance(this.props.t);
        }
    }

    shouldComponentUpdate() {
        let needUpdate = true;

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate || this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.Loyalty, this.props.t.state.width], [this.pState.Loyalty, this.pState.width]);
    }

    getUserLevel(points) {
        points = parseInt(points / 10000);

        let level = 0, used = 1;

        while (points >= used * 2) {
            level++;
            points -= used * 2;
            used *= 2;
        }

        return { level, points, used };
    }

    render() {
        return (
            <>
                {this.props.t.state.Loyalty.process || !this.props.t.state.Loyalty.stat ?
                    <div className={this.props.t.styles['loaderBlock']}><span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span></div>
                    : <>
                        <div className={this.props.t.styles['levelsBlock']}>
                            {this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level <= 2 ?
                                Levels.slice(0, this.props.t.state.width <= 900 ? 3 : 5).map((item, i) =>
                                    <React.Fragment key={item.level}>
                                        <div className={[this.props.t.styles['item'], item.level === this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level ? this.props.t.styles['current'] : ''].join(' ')}>
                                            <img src={item.img}></img>
                                            <div className={this.props.t.styles['expmeter']}>LVL {item.level}</div>
                                            <div className={this.props.t.styles['cashbackTitle']}> {this.props.t.Interface.getText(this.props.t, 'loyalty', 'cashback')}:</div>
                                            <div className={this.props.t.styles['cashbackValue']}>{item.percent}%</div>
                                        </div>
                                        {i !== (this.props.t.state.width <= 900 ? 2 : 4) && <div key={'a' + item.level} className={[this.props.t.styles['item'], this.props.t.styles['arrow']].join(' ')}><Icon t={this.props.t} name={"left"} /></div>}
                                    </React.Fragment>
                                ) : this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level >= 7 ?
                                    Levels.slice(this.props.t.state.width <= 900 ? -3 : -5).map((item, i) =>
                                        <React.Fragment key={item.level}>
                                            <div className={[this.props.t.styles['item'], item.level === this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level ? this.props.t.styles['current'] : ''].join(' ')}>
                                                <img src={item.img}></img>
                                                <div className={this.props.t.styles['expmeter']}>LVL {item.level}</div>
                                                <div className={this.props.t.styles['cashbackTitle']}> {this.props.t.Interface.getText(this.props.t, 'loyalty', 'cashback')}:</div>
                                                <div className={this.props.t.styles['cashbackValue']}>{item.percent}%</div>
                                            </div>
                                            {i !== (this.props.t.state.width <= 900 ? 2 : 4) && <div key={'a' + item.level} className={[this.props.t.styles['item'], this.props.t.styles['arrow']].join(' ')}><Icon t={this.props.t} name={"left"} /></div>}
                                        </React.Fragment>
                                    ) : Levels.slice(this.props.t.state.width <= 900 ? this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level - 2 : this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level - 3, this.props.t.state.width <= 900 ? this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level + 1 : this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level + 2).map((item, i) =>
                                        <React.Fragment key={item.level}>
                                            <div className={[this.props.t.styles['item'], item.level === this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level ? this.props.t.styles['current'] : ''].join(' ')}>
                                                <img src={item.img}></img>
                                                <div className={this.props.t.styles['expmeter']}>LVL {item.level}</div>
                                                <div className={this.props.t.styles['cashbackTitle']}> {this.props.t.Interface.getText(this.props.t, 'loyalty', 'cashback')}:</div>
                                                <div className={this.props.t.styles['cashbackValue']}>{item.percent}%</div>
                                            </div>
                                            {i !== (this.props.t.state.width <= 900 ? 2 : 4) && <div key={'a' + item.level} className={[this.props.t.styles['item'], this.props.t.styles['arrow']].join(' ')}><Icon t={this.props.t} name={"left"} /></div>}
                                        </React.Fragment>
                                    )}
                        </div>
                        <div className={this.props.t.styles['statsForm']}>
                            <div className={this.props.t.styles['left']}>
                                <div className={this.props.t.styles['item']}>
                                    <div className={[this.props.t.styles['wrap'], this.props.t.styles['borderRight']].join(' ')}>
                                        <div className={this.props.t.styles['block']}>
                                            <Icon t={this.props.t} name='person' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.getUserLevel(this.props.t.state.Loyalty.stat.bets).level + ' (' + this.getUserLevel(this.props.t.state.Loyalty.stat.bets).points + '/' + this.getUserLevel(this.props.t.state.Loyalty.stat.bets).used * 2 + ')'}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'loyalty', 'level')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <Icon t={this.props.t} name='briefcase' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Loyalty.stat.wins) + this.props.t.Interface.getCurrency(this.props.t)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'loyalty', 'totalWins')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={[this.props.t.styles['item'], this.props.t.styles['borderTop']].join(' ')}>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <Icon t={this.props.t} name='star' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Loyalty.stat.bets) + this.props.t.Interface.getCurrency(this.props.t)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'loyalty', 'totalBets')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className={this.props.t.styles['right']}>
                                <div className={[this.props.t.styles['item'], this.props.t.styles['full']].join(' ')}>
                                    <div className={this.props.t.styles['wrap']}>
                                        <div className={this.props.t.styles['block']}>
                                            <Icon t={this.props.t} name='rub' />
                                            <div className={this.props.t.styles['num']}>
                                                {this.props.t.Interface.fixedFloat(this.props.t.state.Loyalty.balance)}
                                            </div>
                                            <div className={this.props.t.styles['text']}>
                                                {this.props.t.Interface.getText(this.props.t, 'loyalty', 'availableBalance')}
                                            </div>
                                            <span className={this.props.t.styles['btnBlock']}>
                                                <button onClick={() => { this.props.t.Interface.LoyaltyGetProfit(this.props.t) }} className={this.props.t.styles['btn']} disabled={this.props.t.state.Loyalty.balance > 0 ? false : true} type="button"><span id="withdraw-button"> {this.props.t.Interface.getText(this.props.t, 'loyalty', 'get')}</span></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </>}
            </>
        )
    }
}