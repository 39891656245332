import React from 'react';

class TFAAuthModal extends React.Component {
    constructor(props) {
        super(props);
        this.tfaPinsArray = [];
    }

    componentDidMount() {
        setTimeout(() => this.tfaPinsArray[1].focus(), 0);
        this.props.t.updateState(this.props.t, "inputs", this.tfaPinsArray[1], "auth", "tfaPin");
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    componentWillUnmount() {
        this.props.t.updateState(this.props.t, "inputs", false, "auth", "tfaPin");
        this.props.t.updateState(this.props.t, "authTempKey", false);
        this.props.t.updateState(this.props.t, 'mainRedirect', true)
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.form.settings.tfaPin1, this.props.t.state.form.settings.tfaPin2, this.props.t.state.form.settings.tfaPin3, this.props.t.state.form.settings.tfaPin4, this.props.t.state.form.settings.tfaPin5, this.props.t.state.form.settings.tfaPin6, this.props.t.state.auth.process, this.props.t.state.lang], [this.pState.form.settings.tfaPin1, this.pState.form.settings.tfaPin2, this.pState.form.settings.tfaPin3, this.pState.form.settings.tfaPin4, this.pState.form.settings.tfaPin5, this.pState.form.settings.tfaPin6, this.pState.auth.process, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    handlePinCode(code, pinNumber) {
        if (isFinite(String.fromCharCode(code))) {
            this.props.t.updateState(this.props.t, "form", String.fromCharCode(code), "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        } else if (isFinite(String.fromCharCode(code - 48))) {
            this.props.t.updateState(this.props.t, "form", String.fromCharCode(code - 48), "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        } else if (code === 8 || code === 127) {
            this.props.t.updateState(this.props.t, "form", '', "settings", "tfaPin" + pinNumber, "data");
            this.props.t.Interface.checkForm(this.props.t, "settings", "tfaPin", pinNumber, this.tfaPinsArray)
        }
    }

    render() {
        return (
            <div className={this.props.t.styles['tfaContainer']}>
                <div className={this.props.t.styles['tfaHeader']}>
                    {this.props.t.Interface.getText(this.props.t, 'tfa', 'tfa')}
                </div>
                <div className={this.props.t.styles['tfaWrapper']}>
                    <div className={this.props.t.styles['codeRow']}>
                        <div className={this.props.t.styles['tfaValidationWrapper']}>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[1] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 1)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin1.disabled} value={this.props.t.state.form.settings.tfaPin1.data}></input>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[2] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 2)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin2.disabled} value={this.props.t.state.form.settings.tfaPin2.data}></input>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[3] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 3)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin3.disabled} value={this.props.t.state.form.settings.tfaPin3.data}></input>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[4] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 4)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin4.disabled} value={this.props.t.state.form.settings.tfaPin4.data}></input>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[5] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 5)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin5.disabled} value={this.props.t.state.form.settings.tfaPin5.data}></input>
                            <input className={this.props.t.styles['inputField']} ref={(a) => this.tfaPinsArray[6] = a} onKeyDown={(ev) => this.handlePinCode(ev.keyCode, 6)} autocomplete="off" maxlength="1" placeholder="·" type="tel" disabled={this.props.t.state.form.settings.tfaPin6.disabled} value={this.props.t.state.form.settings.tfaPin6.data}></input>
                        </div>
                    </div>
                    <div className={this.props.t.styles['tfaBottom']}>
                        <button onClick={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }} className={[this.props.t.styles['btn'], this.props.t.styles['btnLight']].join(' ')} type="button"> {this.props.t.Interface.getText(this.props.t, 'basic', 'cancel')}</button>
                        <button disabled={!this.props.t.state.form.settings.tfaPin1.disabled && !this.props.t.state.form.settings.tfaPin2.disabled && !this.props.t.state.form.settings.tfaPin3.disabled && !this.props.t.state.form.settings.tfaPin4.disabled && !this.props.t.state.form.settings.tfaPin5.disabled && !this.props.t.state.form.settings.tfaPin6.disabled && this.props.t.state.form.settings.tfaPin1.data.length && this.props.t.state.form.settings.tfaPin2.data.length && this.props.t.state.form.settings.tfaPin3.data.length && this.props.t.state.form.settings.tfaPin4.data.length && this.props.t.state.form.settings.tfaPin5.data.length && this.props.t.state.form.settings.tfaPin6.data.length && !this.props.t.state.auth.process ? false : true} onClick={(ev) => this.props.t.Interface.doTfaLogin(this.props.t)} className={[this.props.t.styles['btn'], this.props.t.styles['btnAuth']].join(' ')} type="submit">{!this.props.t.state.auth.process ? this.props.t.Interface.getText(this.props.t, 'auth', 'login') : this.props.t.Interface.getText(this.props.t, 'auth', 'loading')}</button>
                    </div>
                </div>
                <div ref={div => (this.recaptchaLoader = div)}></div>
            </div>

        );
    }
}

export default TFAAuthModal;
