import React from 'react';

import Loader from '../assets/svg/loader.svg';

export default class Sessions extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.t.state.userID)
			this.props.t.Interface.SettingsGetRegData(this.props.t);
	}

	componentWillUnmount() {
		this.props.t.updateState(this.props.t, 'Settings', [], 'regData');
	}

	render() {
		return (
			<>
				<div className={this.props.t.styles['caption']}>
					{this.props.t.Interface.getText(this.props.t, 'settings', 'sessions')}
				</div>
				<div className={this.props.t.styles['withPager']}>
					<div className={[this.props.t.styles['list'], typeof this.props.t.state.Settings.regData === "undefined" || !this.props.t.state.Settings.regData.length ? this.props.t.styles['isLoader'] : ''].join('')}>
						{
							typeof this.props.t.state.Settings.regData === "undefined" || !this.props.t.state.Settings.regData.length ?
								<span className={[this.props.t.styles['loader'], this.props.t.styles['loaderHalf'], this.props.t.styles['loaderCentred']].join(' ')}><img src={Loader} className={this.props.t.styles['rotating']} /></span>
								:
								<div className={this.props.t.styles['responsive']}>
									<table data-count="5">
										<thead>
											<tr>
												<th>{this.props.t.Interface.getText(this.props.t, 'settings', 'device')}</th>
												<th>{this.props.t.Interface.getText(this.props.t, 'settings', 'region')}</th>
												<th>{this.props.t.Interface.getText(this.props.t, 'settings', 'ipAddress')}</th>
												<th>{this.props.t.Interface.getText(this.props.t, 'settings', 'date')}</th>
												<th>{this.props.t.Interface.getText(this.props.t, 'settings', 'session')}</th>
											</tr>
										</thead>
										<tbody>
											{typeof this.props.t.state.Settings.regData !== "undefined" && this.props.t.state.Settings.regData.map((session, index) =>
												<tr key={index}>
													<td>{session.browser} ({session.os})</td>
													<td>{session.country}, {session.region}</td>
													<td>{session.ip}</td>
													<td>{this.props.t.Interface.localeDate(parseInt(session.date / 1000), false, this.props.t)}</td>
													<td>
														<div className={this.props.t.state.authKey !== session.auth_key ? this.props.t.styles['expired'] : this.props.t.styles['active']}>
															{this.props.t.state.authKey === session.auth_key ? this.props.t.Interface.getText(this.props.t, 'settings', 'active') : this.props.t.Interface.getText(this.props.t, 'settings', 'closed')}
														</div>
													</td>
												</tr>)}
										</tbody>
									</table>
								</div>
						}
					</div>
				</div>
			</>
		)
	}

}