import React from 'react';
import { Redirect, withRouter } from "react-router-dom";
import IconStatic from '../components/IconStatic';

import 'bootstrap';
import '../styles/bootstrap/scss/bootstrap.module.scss';
import { Modal } from 'reactstrap';

class ModalBox extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.pState = this.props.t.cloneDeep(this.props.t.state);
	}

	shouldComponentUpdate() {
		return true;
	}

	render() {
		if (this.props.t.state.width > 900 && this.props.t.state.modal.className === this.props.t.styles['walletModal'] && !this.props.t.state.Wallet.openInModal)
			this.props.t.updateState(this.props.t, 'Wallet', true, 'openInModal');

		if (this.props.t.state.modal.className !== this.props.t.styles['walletModal'] || this.props.t.state.width > 900 || !this.props.t.state.modal.show)
			return (
				<Modal onOpened={() => { setTimeout(() => { this.props.t.updateState(this.props.t, 'modal', true, 'opened') }, 100) }} contentClassName={this.props.t.styles['modal-content']} modalClassName={[this.props.t.styles['modal'], this.props.t.styles['fade'], this.props.t.styles['show']].join(' ')} className={[this.props.t.styles['modal-dialog'], this.props.t.state.modal.className, this.props.t.styles['modal-dialog-centered']].join(' ')} isOpen={this.props.t.state.modal.show} cssModule={this.props.t.bstyles} toggle={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }}>

					{this.props.t.state.modal.className !== this.props.t.styles['walletModal'] ?
						<button onClick={() => { this.props.t.updateState(this.props.t, 'modal', false, 'show') }} className={this.props.t.styles['modal-close']} type="button"><IconStatic t={this.props.t} name="close" /></button>
						: <></>}

					{this.props.t.state.modal.content ?
						<this.props.t.state.modal.content t={this.props.t} />
						: (<></>)
					}
				</Modal>
			);
		else {

			if (window.location.pathname !== "/wallet" && window.location.pathname !== this.props.t.state.Wallet.prevLocation)
				this.props.t.updateState(this.props.t, 'Wallet', window.location.pathname, 'prevLocation');

			return (
				<Redirect to="/wallet" />
			);
		}
	}
}

export default withRouter(ModalBox);
