import React from 'react';

class StaticLobbyProviders extends React.Component {
    constructor(props) {
        super(props);
        this.staticItems = [];

        for (let i = this.props.t.config.static.lobby.providers; i > 0; i--) {
            this.staticItems.push(true)
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            this.staticItems.map((id, index) => (
                <div key={index} className={this.props.t.styles['item']}>
                    <div className={this.props.t.styles['btnWrapper']}>
                        <button type="button" className={this.props.t.styles['btn']}></button>
                    </div>
                </div>
            ))
        )
    }
}

export default StaticLobbyProviders;