import React from 'react';

import { Spinner } from 'reactstrap';
import { Redirect } from "react-router-dom";

export default class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.token = typeof document.location.pathname.split('/')[2] !== "undefined" ? document.location.pathname.split('/')[2] : false;

        this.props.t.updateState(this.props.t, 'confirm', this.token, 'token');
    }

    componentDidMount() {
        if (this.token)
            this.props.t.Interface.doConfirmEmail(this.props.t, this.token);
        this.pState = this.props.t.cloneDeep(this.props.t.state);
    }

    shouldComponentUpdate() {
        let needUpdate = this.props.t.Interface.checkIsNeedUpdate([this.props.t.state.confirm.token, this.props.t.state.mainRedirect, this.props.t.state.confirm, this.props.t.state.lang], [this.pState.confirm.token, this.pState.mainRedirect, this.pState.confirm, this.pState.lang]);

        if (needUpdate)
            this.pState = this.props.t.cloneDeep(this.props.t.state);

        return needUpdate;
    }

    render() {
        return (
            <>
                {!this.props.t.state.confirm.token || this.props.t.state.mainRedirect ? (this.props.t.updateState(this.props.t, 'mainRedirect', false), <Redirect from="/*" to="/" />) : <></>}
                <div className={this.props.t.styles['confirmSection']}>
                    <div className={this.props.t.styles['block']}>
                        <span className={this.props.t.styles['loader']}>
                            <Spinner cssModule={this.props.t.bstyles} style={{
                                width: '5rem',
                                height: '5rem',
                                color: '#4986f5'
                            }} />
                        </span>
                        <div className={this.props.t.styles['text']}>{this.props.t.Interface.getText(this.props.t, 'auth', 'pleaseWait')}</div>
                    </div>
                </div>
            </>
        )
    }
}